import { validateNumberInput } from '@/atoms/inputs/utils';
import InlineTextField from '../InlineTextField';

const InlineNumberField = ({
  absolute = true,
  precision = 0,
  inputProps = {},
  preventNonNumeric = true,
  ...props
}) => {
  const numberInputProps = {
    ...inputProps,
    type: 'number',
    preventNonNumeric,
    onKeyDown: validateNumberInput(absolute, precision),
  };

  return <InlineTextField {...props} inputProps={numberInputProps} />;
};

export default InlineNumberField;
