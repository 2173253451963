/**
 * @param {Object[]} collection
 * @param {String} key
 * @param {String} value
 * @returns {any[]}
 */
export function mapValueBy(collection, key, value) {
  const mapped = new Map();

  collection?.forEach((item) => {
    mapped.set(item[key], item[value]);
  });

  return mapped;
}

/**
 * @param {Object[]} collection
 * @param {String} key
 * @returns {any[]}
 */
export function mapIdBy(collection, key) {
  return mapValueBy(collection, key, 'uuid');
}
