import { useState, useEffect } from 'react';
import { useQuery } from '@pkg/graphql';
import { Collections } from '@pkg/utils';

const SEARCH_PEOPLE = /* GraphQL */ `
  query SearchPeople($search: String, $filters: SearchFilters) {
    users(query: $search, filters: $filters) {
      data {
        uuid
        first_name
        last_name
        name
        avatar
      }
    }
  }
`;

/**
 * @param {Object} search
 * @param {Object} options
 * @param {Array} [options.exclude]
 * @param {Boolean} [options.enabled]
 */
export default function useSearch(search, options = {}) {
  const enabled = options.enabled ?? true;
  const exclude = options.exclude ?? [];
  const filters = options.filters ?? {};

  const query = [SEARCH_PEOPLE, { search, filters }];
  const [results, setResults] = useState([]);
  const { data, isLoading, ...state } = useQuery({ query, enabled });

  useEffect(() => {
    if (!isLoading && Boolean(data?.users)) {
      const people = data.users.data || [];
      const unique = Object.values(Collections.keyById(people));

      const excludeSet = new Set(exclude);
      const excluded = unique.filter(({ uuid }) => !excludeSet.has(uuid));
      setResults(excluded);
    }
  }, [data?.users, exclude]);

  return {
    results,
    isLoading,
    ...state,
  };
}
