import { EntityPluralName, EntityType } from '@/shared/enums';

export default function determineEntityChange({
  metrics,
  entityCount,
  entityType,
}) {
  const metricType =
    EntityPluralName[entityType]?.toLowerCase() ??
    EntityPluralName[EntityType.ROLE]?.toLowerCase();

  const entityCountDifference = metrics[1][metricType] - metrics[0][metricType];

  if (entityCountDifference !== 0) {
    return {
      label: entityCountDifference > 0 ? 'Added' : 'Removed',
      value: entityCountDifference,
    };
  }

  return {
    label: 'Changed',
    value: entityCount,
  };
}
