export default function renderCircle(
  node,
  context,
  toolTipEvents,
  chartEvents
) {
  const { classes, color } = context;

  return node
    .append('circle')
    .classed('PackedCircleChart--circle', true)
    .classed(classes.circle.join(' '), true)
    .attr('r', ({ value }) => value)
    .style('fill', color)
    .style('transition', 'fill 500ms')
    .style('cursor', 'pointer')
    .on('mouseover', () => {
      toolTipEvents.onMouseOver();
      chartEvents?.onMouseOver();
    })
    .on('mousemove', toolTipEvents.onMouseMove)
    .on('mouseleave', () => {
      toolTipEvents.onMouseLeave();
      chartEvents?.onMouseLeave();
    })
    .on('click', chartEvents?.onClick);
}
