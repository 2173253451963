import env from '@beam-australia/react-env';

export default class Analytics {
  static setup(analytics) {
    console.debug('Analytics.setup', analytics);
    analytics.SNIPPET_VERSION = '4.15.3';
    analytics._writeKey = env('SEGMENT_WRITE_KEY');
    analytics.load(env('SEGMENT_WRITE_KEY'));
    analytics.page();
  }

  static identify(uuid, properties = {}) {
    console.debug('Analytics.identify', { uuid, properties });
    requestAnimationFrame(() => {
      window.analytics?.identify(uuid, properties);
    });
  }

  static track(name, properties = {}) {
    console.debug('Analytics.track', { name, properties });
    requestAnimationFrame(() => {
      window.analytics?.track(name, properties);
    });
  }
}
