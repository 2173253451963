import { PropertyType } from '@/lib/enums';

export default function getPopulated(library, properties) {
  return properties
    .filter((property) => library.has(property.key))
    .map(({ key, value }) => {
      const libraryProperty = library.get(key);

      const name =
        libraryProperty.type === PropertyType.TEXT
          ? value
          : libraryProperty.options.find((option) => option.uuid === value)
              .value;
      return {
        id: key,
        name,
        label: libraryProperty.name,
      };
    });
}
