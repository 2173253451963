export const TagFragment = /* GraphQL */ `
  fragment TagFragment on Tag {
    uuid
    category_uuid
    name
    description
    color
    taggables
    order
    is_disabled
    is_system
  }
`;

export const TagCategoryFragment = /* GraphQL */ `
  fragment TagCategoryFragment on TagCategory {
    uuid
    name
    color
    taggables
    order
    is_system
  }
`;

export const TaggingFragment = /* GraphQL */ `
  fragment TaggingFragment on Organisation {
    tags {
      ...TagFragment
    }
    tagCategories {
      ...TagCategoryFragment
    }
  }

  ${TagFragment}
  ${TagCategoryFragment}
`;
