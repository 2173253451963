import { Size } from '@/atoms/enums';
import Typography from '@/components/Typography';
import ParagraphSizeProps from './utils/ParagraphSizeProps';

const Paragraph = ({ children, color, overrideStyles, size = Size.MEDIUM }) => {
  const sizeProps = ParagraphSizeProps[size];

  return (
    <Typography
      sx={{
        ...sizeProps,
        color: color ?? 'inherit',
        mb: 1,
        fontWeight: 400,
        ...overrideStyles,
      }}
    >
      {children}
    </Typography>
  );
};

export default Paragraph;
