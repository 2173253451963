import * as d3 from 'd3';
import { Position } from '@/lib/enums';
import { SubtitleColor } from '@/lib/theme/dashboardColors';
import { palettes } from '@/lib/theme/tokens';

const getLabels = ({ position, labels }) => {
  switch (position) {
    case Position.TOP_LEFT:
      return { x: labels.right.title, y: labels.bottom.title };
    case Position.TOP_RIGHT:
      return { x: labels.left.title, y: labels.bottom.title };
    case Position.BOTTOM_RIGHT:
      return { x: labels.left.title, y: labels.top.title };
    case Position.BOTTOM_LEFT:
      return { x: labels.right.title, y: labels.top.title };
  }
};

const getLabelCoordinates = (position, width, height) => {
  const xLabel = { x: 0, y: 0, anchor: 'start' };
  const yLabel = { x: 0, y: 0, anchor: 'start' };
  const buffer = 15;

  switch (position) {
    case Position.TOP_LEFT:
      xLabel.y = buffer;
      xLabel.x = width;
      xLabel.anchor = 'end';
      yLabel.y = height - 5;
      yLabel.anchor = 'start';
      break;
    case Position.TOP_RIGHT:
      xLabel.y = buffer;
      xLabel.x = 0;
      xLabel.anchor = 'start';
      yLabel.y = height - 5;
      yLabel.x = width;
      yLabel.anchor = 'end';
      break;
    case Position.BOTTOM_RIGHT:
      xLabel.y = height - 5;
      yLabel.x = width;
      yLabel.y = buffer;
      yLabel.anchor = 'end';
      break;
    case Position.BOTTOM_LEFT:
      xLabel.y = height - 5;
      xLabel.x = width;
      xLabel.anchor = 'end';
      yLabel.x = 0;
      yLabel.y = buffer;
      yLabel.anchor = 'start';
      break;
    default:
  }

  return { xLabel, yLabel };
};

const getAxisCoordinates = ({
  height,
  position,
  width,
  yLabelElement,
  xLabelElement,
}) => {
  const xAxisCoords = { x: 0, y: 0, marker: 'marker-start' };
  const yAxisCoords = { x: 0, y: 0, marker: 'marker-start' };
  const buffer = 10;

  switch (position) {
    case Position.TOP_LEFT:
      xAxisCoords.x = buffer;
      xAxisCoords.y = buffer;
      xAxisCoords.marker = 'marker-end';
      yAxisCoords.x = buffer;
      yAxisCoords.y = buffer;
      yAxisCoords.marker = 'marker-end';
      break;
    case Position.TOP_RIGHT:
      xAxisCoords.x = xLabelElement.width + 15;
      xAxisCoords.y = buffer;
      yAxisCoords.x = width - 5;
      yAxisCoords.y = buffer;
      yAxisCoords.marker = 'marker-end';
      break;
    case Position.BOTTOM_RIGHT:
      xAxisCoords.x = xLabelElement.width + 15;
      xAxisCoords.y = height - buffer;
      yAxisCoords.x = width - 5;
      yAxisCoords.y = yLabelElement.height + buffer;
      break;
    case Position.BOTTOM_LEFT:
      xAxisCoords.x = buffer;
      xAxisCoords.y = height - buffer;
      xAxisCoords.marker = 'marker-end';
      yAxisCoords.x = buffer;
      yAxisCoords.y = yLabelElement.height + buffer;
      break;
    default:
  }

  return { xAxisCoords, yAxisCoords };
};

export default function renderCornerAxis(svg, properties) {
  const { classes, height, xOffset, position, width } = properties;

  const labels = getLabels({ position, labels: properties.labels });

  if (!width || !height) {
    return;
  }

  const color = palettes.brand.purple.tints[2];

  svg
    .append('defs')
    .append('marker')
    .attr('id', 'arrow')
    .attr('viewBox', [0, 0, 20, 20])
    .attr('refX', 5)
    .attr('refY', 5)
    .attr('markerWidth', 10)
    .attr('markerHeight', 10)
    .attr('orient', 'auto-start-reverse')
    .append('path')
    .attr(
      'd',
      d3.line()([
        [0, 0],
        [0, 10],
        [10, 5],
      ])
    )
    .attr('fill', color)
    .attr('stroke', color);

  const { xLabel, yLabel } = getLabelCoordinates(
    position,
    width,
    height,
    xOffset
  );

  const yLabelId = `${position}--y-label`;
  const xLabelId = `${position}--x-label`;

  // Y label.
  svg
    .append('text')
    .attr('id', yLabelId)
    .classed('Chart--axis-label', true)
    .classed(classes.axisLabel.join(' '), true)
    .attr('y', yLabel.y)
    .attr('x', yLabel.x)
    .attr('fill', SubtitleColor.text)
    .style('text-anchor', yLabel.anchor)
    .text(labels.y);
  // X label.
  svg
    .append('text')
    .attr('id', xLabelId)
    .classed('Chart--axis-label', true)
    .classed(classes.axisLabel.join(' '), true)
    .attr('y', xLabel.y)
    .attr('x', xLabel.x)
    .attr('fill', SubtitleColor.text)
    .style('text-anchor', xLabel.anchor)
    .text(labels.x);

  const yLabelElement = d3
    .select(`#${yLabelId}`)
    .node()
    ?.getBoundingClientRect();
  const xLabelElement = d3
    .select(`#${xLabelId}`)
    .node()
    ?.getBoundingClientRect();

  const xScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, width - xLabelElement?.width - 20]);
  const yScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, height - yLabelElement?.height - 20]);

  const xAxis = d3.axisBottom(xScale).tickSizeOuter(0).ticks(0);
  const yAxis = d3.axisLeft(yScale).tickSizeOuter(0).ticks(0);

  const { xAxisCoords, yAxisCoords } = getAxisCoordinates({
    height,
    position,
    width,
    yLabelElement,
    xLabelElement,
  });

  // X axis line.
  svg
    .append('g')
    .attr('id', 'center-axis-x')
    .call(xAxis)
    .attr('transform', `translate(${xAxisCoords.x}, ${xAxisCoords.y})`);

  svg
    .select(`#center-axis-x path.domain`)
    .attr('stroke', color)
    .attr('stroke-width', '2')
    .attr(xAxisCoords.marker, 'url(#arrow)');

  // Y axis line.
  svg
    .append('g')
    .attr('id', 'center-axis-y')
    .call(yAxis)
    .attr('transform', `translate(${yAxisCoords.x}, ${yAxisCoords.y})`);

  svg
    .select(`#center-axis-y path.domain`)
    .attr('stroke', color)
    .attr('stroke-width', '2')
    .attr(yAxisCoords.marker, 'url(#arrow)');
}
