import MuiDivider from '@mui/material/Divider';
import DividerColor from './DividerColor';

const Divider = ({
  orientation,
  overrideStyles,
  color = 'primary',
  colorProps,
}) => {
  const colors = colorProps ?? DividerColor[color];

  return (
    <MuiDivider
      sx={{
        ...colors,
        ...overrideStyles,
      }}
      orientation={orientation}
      flexItem={true}
    />
  );
};

export default Divider;
