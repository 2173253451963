import { useLive as useLiveActivities } from './activities';
import { useLive as useLiveFlowTemplates } from './flowTemplates';
import { useLive as useLiveProperties } from './properties';
import { useLive as useLiveSkills } from './skills';

const LibraryObserver = () => {
  useLiveActivities();
  useLiveFlowTemplates();
  useLiveProperties();
  useLiveSkills();
};

export default LibraryObserver;
