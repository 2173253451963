const HeadingSize = Object.freeze({
  h1: {
    fontSize: '2.75rem',
    fontWeight: 600,
    lineHeight: '3.25rem',
  },
  h2: {
    fontSize: '1.75rem',
    lineHeight: '2.25rem',
  },
  h3: {
    fontSize: '1.25rem',
    lineHeight: '1.625rem',
  },
  h4: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  h5: {
    fontSize: '0.925rem',
    lineHeight: '1.25rem',
  },
  h6: {
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
  },
});

export default HeadingSize;
