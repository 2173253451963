import { useMutation } from '@pkg/graphql';

const PUBLISH_DESIGN = /* GraphQL */ `
  mutation PublishDesign($input: PublishDesignInput!) {
    publishDesign(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function usePublish() {
  const { mutateAsync } = useMutation({
    mutation: PUBLISH_DESIGN,
  });

  /**
   * @param {String} design_uuid
   * @returns {Promise}
   */
  function publish(design_uuid) {
    return mutateAsync({ design_uuid });
  }

  return publish;
}
