/**
 * Creates a default map for expanded chart items.
 *
 * @param {Set}
 *
 * @return {Map}
 */
export default function defaultExpanded(expandedSet) {
  if (!expandedSet) {
    return new Map();
  }

  return new Map([...expandedSet].map((id) => [id, 0]));
}
