import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

export const RESEND_INVITE = /* GraphQL */ `
  mutation ResendInvite($input: InviteUpdateInput!) {
    resendInvite(input: $input)
  }
`;

export default function useResendInvite() {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync } = useMutation({
    mutation: RESEND_INVITE,
  });

  /**
   * @param {String} email
   */
  async function resendInvite(email) {
    const { data } = mutateAsync({ email });
    enqueueSnackbar(`Resending invite to "${email}".`);
    return data?.resendInvite;
  }

  return resendInvite;
}
