import { useCallback } from 'react';
import { useMutation } from '@pkg/graphql';

const REMOVE_SUPPORTER = /* GraphQL */ `
  mutation RemoveSupporter($input: RemoveGroupSupporterInput!) {
    removeGroupSupporter(input: $input) {
      uuid
      people
    }
  }
`;

/**
 * @param {any[]} query
 * @return {Function}
 */
export default function useRemoveSupporters(query) {
  const { mutateAsync } = useMutation({
    mutation: REMOVE_SUPPORTER,
    invalidateQuery: query,
  });

  /**
   * @param {String} design_uuid
   * @param {String} group_uuid
   * @param {String} user_uuid
   * @returns {Object|null}
   */
  function removeGroupSupporter(design_uuid, group_uuid, user_uuid) {
    return mutateAsync({
      design_uuid,
      group_uuid,
      user_uuid,
    });
  }

  return useCallback(removeGroupSupporter, [mutateAsync]);
}
