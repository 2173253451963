import { useMutation } from '@pkg/graphql';

const CREATE_TEMPLATE = /* GraphQL */ `
  mutation CreateTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useCreate() {
  const { mutateAsync } = useMutation({
    mutation: CREATE_TEMPLATE,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Object} input
   * @param {String} input.type
   * @param {Object} input.snapshot
   * @returns {Promise}
   */
  async function createTemplate(organisation_uuid, { type, snapshot }) {
    return mutateAsync({ organisation_uuid, type, snapshot });
  }

  return createTemplate;
}
