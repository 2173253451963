import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearch } from '@/shared/hooks';
import { formatTagsAsOptions } from '@/shared/utils/tags';
import { Tags } from '@pkg/entities';
import { DesignEntity } from '@/lib/enums';

export const TagContext = createContext();
export const useTagContext = () => useContext(TagContext);

const TagProvider = ({ children }) => {
  const ordered = Tags.useStore((state) => state.ordered);
  const tagMap = Tags.useStore((state) => state.tags?.map);
  const tagList = Tags.useStore((state) => state.tags?.list);
  const tagIndex = Tags.useStore((state) => state.index);
  const [entityTagMap, setEntityTagMap] = useState(new Map());

  const searchTags = useSearch({
    searchList: tagList,
    searchMap: tagMap,
    searchIndex: tagIndex,
  });

  const search = (searchTerm, validationFn) => {
    return searchTags({ searchTerm, validationFn });
  };

  // @ToDo work out a better way to restrict setting tag options.
  useEffect(() => {
    if (!ordered) {
      return;
    }

    const activityTags = formatTagsAsOptions(ordered, DesignEntity.ACTIVITY);
    const roleTags = formatTagsAsOptions(ordered, DesignEntity.ROLE);
    const groupTags = formatTagsAsOptions(ordered, DesignEntity.GROUP);

    setEntityTagMap(
      new Map([
        [DesignEntity.ACTIVITY, activityTags],
        [DesignEntity.GROUP, groupTags],
        [DesignEntity.ROLE, roleTags],
      ])
    );
  }, [ordered]);

  const context = useMemo(() => {
    return {
      entityTagMap,
      tagMap,
      search,
    };
  }, [entityTagMap, tagMap]);

  return <TagContext.Provider value={context}>{children}</TagContext.Provider>;
};

export default TagProvider;
