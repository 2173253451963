import { ChipType, DesignType } from '@/lib/enums';
import Box from '@/components/Box';
import entityChipColors from './utils/entityChipColors';

const EntityChip = ({ type }) => {
  const label = ChipType.label(type);
  const colors = entityChipColors(type);
  return (
    <Box
      sx={{
        backgroundColor: colors?.background,
        border:
          type === DesignType.LIVE ? `1px solid ${colors?.border}` : 'none',
        borderRadius: type === DesignType.LIVE ? '12px' : '2px',
        boxShadow:
          type === DesignType.LIVE ? '0 1px 1px rgba(0,0,0,0.03)' : 'none',
        color: colors?.font,
        display: 'inline-block',
        fontSize: '0.7rem',
        fontWeight: 700,
        padding: '0 8px',
        verticalAlign: 'top',
      }}
    >
      {label}
    </Box>
  );
};

export default EntityChip;
