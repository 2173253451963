import { TagChip } from '@/atoms/chips';
import { Label } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const TagChips = ({ expanded, onClick, onDelete, tags, tooltipPlacement }) => {
  return tags?.length > 0 ? (
    <Grid
      container
      direction="row"
      flexWrap="wrap"
      spacing={0.5}
      justifyContent="flex-start"
    >
      {tags.map((tag) => {
        // @ToDo: Figure out why we occasionally load an array of undefined
        // tags.
        if (!tag) {
          return null;
        }

        return (
          <Grid item key={tag.uuid} sx={{ lineHeight: '0.75rem' }}>
            <TagChip
              color={tag.color}
              description={tag.description}
              expanded={expanded}
              id={tag.uuid}
              name={tag.name}
              onClick={onClick}
              onDelete={onDelete}
              key={tag.uuid}
              tooltipPlacement={tooltipPlacement}
            />
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Box sx={{ px: expanded ? 0.5 : 0 }}>
      <Label>No tags assigned</Label>
    </Box>
  );
};

export default TagChips;
