import { memo } from 'react';
import { styled } from '@mui/material/styles';
import Fade from '@/components/Fade';

const Root = styled('div')({
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: 352,
});

const Content = ({ children }) => {
  return (
    <Fade in>
      <Root>{children}</Root>
    </Fade>
  );
};

export default memo(Content);
