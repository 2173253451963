import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {Object} input.template
 * @param {String} input.group_uuid
 * @param {String} input.parent_uuid
 * @param {String} input.new_uuid
 * @returns {Object}
 */
export default function insert(
  snapshot,
  { template, group_uuid, parent_uuid, new_uuid, roleCount = 1 }
) {
  const entities = snapshot.entities;
  const now = Date.now();

  const group = group_uuid ? { uuid: group_uuid, updated_at: now } : null;
  const roles = group
    ? Collections.where(entities.roles, 'group_uuid', group.uuid)
    : [];

  const templateRole = template.snapshot.entities.roles[0];
  const templateActivities = template.snapshot.entities.activities || [];

  const role = {
    uuid: new_uuid,
    group_uuid,
    parent_uuid,
    user_uuid: null,
    title: templateRole.title,
    order: roles.length,
    fte: templateRole.fte,
    properties: [],
    skills: [],
    tags: [],
    created_at: now,
    updated_at: now,
  };

  const activities = [];

  let newRoles = Array.from({ length: roleCount }, () => role);

  if (roleCount > 1) {
    newRoles = newRoles.map((role) => {
      return {
        ...role,
        uuid: shortuuid.generate(),
      };
    });
  }

  newRoles.forEach((role) => {
    templateActivities.forEach((activity) => {
      activities.push({
        uuid: shortuuid.generate(),
        library_uuid: activity.library_uuid,
        owner_uuid: role.uuid,
        owner_type: activity.owner_type,
        hours: activity.hours || 1,
        properties: activity.properties || [],
        tags: activity.tags || [],
        order: activity.order,
        created_at: now,
        updated_at: now,
      });
    });
  });

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        create: newRoles,
      },
      activities: {
        create: activities,
      },
    },
  };

  return mutation;
}
