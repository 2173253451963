import { TagChip } from '@/atoms/chips';

const TagCell = (props) => {
  if (!props.value) {
    return null;
  }

  const tags = Array.isArray(props.value) ? props.value : [props.value];

  return (
    tags && (
      <>
        {tags.map(
          (tag) =>
            tag?.id && (
              <div
                key={tag.id}
                style={{ display: 'inline-block', marginRight: '4px' }}
              >
                <TagChip
                  color={tag.color}
                  expanded={true}
                  id={tag.id}
                  name={tag.name}
                />
              </div>
            )
        )}
      </>
    )
  );
};

export default TagCell;
