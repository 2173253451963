/**
 * @param {Object[]} collection
 * @param {String} key
 * @return {Object}
 */
export function groupBy(collection, key) {
  const keyed = {};

  collection.forEach((item) => {
    if (!Object.hasOwn(keyed, item[key])) {
      keyed[item[key] ?? null] = [];
    }

    keyed[item[key] ?? null].push(item);
  });

  return keyed;
}
