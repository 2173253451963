/**
 * @param {Object[]} collection
 * @param {String} key
 * @param {String} value
 * @returns {any[]}
 */
export function keyValueBy(collection, key, value) {
  const keyed = {};

  collection?.forEach((item) => {
    keyed[item[key]] = item[value];
  });

  return keyed;
}
