import { useMemo } from 'react';
import { useStore } from '@pkg/auth';
import { useQuery } from '@pkg/graphql';

export const GET_PEOPLE_SKILLS = /* GraphQL */ `
  query LibrarySkillMap {
    librarySkillMap {
      uuid
      people {
        uuid
        level
      }
    }
  }
`;

export default function usePeopleSkills() {
  const { isAuthenticated } = useStore();
  const { data, dataUpdatedAt } = useQuery({
    enabled: isAuthenticated,
    query: [GET_PEOPLE_SKILLS],
  });

  return useMemo(() => {
    const peopleSkills = new Map();
    data?.librarySkillMap.forEach((map) => {
      peopleSkills.set(
        map.uuid,
        new Map(map.people.map((person) => [person.uuid, person.level]))
      );
    });

    return peopleSkills;
  }, [dataUpdatedAt]);
}
