import { DesignEntity, Visibility } from '@/lib/enums';
import {
  formatDesignData,
  formatGroupData,
  formatRoleData,
  getEntities,
} from './formatTableData';

/**
 * Takes an array of scenario maps and converts them into tabular data.
 *
 * @param {Array}
 * @return {Object}
 */
export default function formatTagRoleTableData({
  showBudget,
  entityType,
  propertyMap,
  scenarioMaps,
  skillMap,
  tagMap,
}) {
  const rows = [];

  scenarioMaps?.forEach((scenarioMap) => {
    const roles = scenarioMap.get(DesignEntity.ROLE);
    const groupList = scenarioMap.get(DesignEntity.GROUP);
    const organisationHours = [...groupList.values()].reduce((prev, next) => {
      return (prev += next.__total_metrics.hours);
    }, 0);

    roles.forEach((role) => {
      if (role.__visibility === Visibility.NONE) {
        return;
      }

      const { design, group, groupLead, person } = getEntities({
        role,
        scenarioMap,
      });

      const rowData = {
        ...formatRoleData({
          showBudget,
          person,
          propertyMap,
          role,
          tagMap,
          skillMap,
        }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      };

      if (!rowData[entityType]?.tags?.length) {
        rows.push(rowData);
        return;
      }

      // Add a row for each tag in the activity.
      rowData[entityType].tags.forEach((tag) => {
        rows.push({
          ...rowData,
          tag,
        });
      });
    });
  });

  return rows;
}
