import { useMemo } from 'react';
import { TagCell, TagFilter } from '@/molecules/tableElements';
import { getTagDimensionEntityType } from '@/organisms/tables/utils';
import { useTagContext } from '@/shared/providers';
import { DesignEntity } from '@/lib/enums';

const useTagColumnDefs = (dimension) => {
  const { activityTagOptions, roleTagOptions, groupTagOptions } =
    useTagContext();

  return useMemo(() => {
    const entityType = getTagDimensionEntityType(dimension);

    let tagOptions = activityTagOptions;

    if (entityType === DesignEntity.ROLE) {
      tagOptions = roleTagOptions;
    }

    if (entityType === DesignEntity.GROUP) {
      tagOptions = groupTagOptions;
    }

    return [
      {
        chartDataType: 'category',
        cellDataType: 'object',
        cellRenderer: TagCell,
        field: 'tag',
        filter: TagFilter,
        filterParams: {
          title: 'Tag filter',
          values: tagOptions,
        },
        valueGetter: ({ data }) => {
          const tagName = data?.tag?.name ?? 'No tag';

          return {
            ...data?.tag,
            toString: () => tagName,
          };
        },
      },
    ];
  }, [activityTagOptions, roleTagOptions, groupTagOptions, dimension]);
};

export default useTagColumnDefs;
