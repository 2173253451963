import { core, brand } from '@/lib/theme/tokens/palettes';

export default function formatRoleMetrics(metrics) {
  const { activities, roles } = metrics;
  const { count, hours } = activities;
  const { fte } = roles;

  return {
    activityCount: {
      label: count.raw > 1 || count.raw < 1 ? 'Activities' : 'Activity',
      colors: [core.white, brand.yellow.main],
      values: [count.comparison, count.primary],
      percentage: count.percentage,
      raw: count.raw,
    },
    activityHours: {
      label: hours.raw > 1 || hours.raw < 1 ? 'Hours' : 'Hours',
      colors: [core.white, brand.yellow.main],
      values: [hours.comparison, hours.primary],
      percentage: hours.percentage,
      raw: hours.raw,
    },
    fte: {
      label: 'Fte',
      colors: [core.white, brand.yellow.main],
      values: [fte.comparison, fte.primary],
      percentage: fte.percentage,
      raw: fte.raw,
    },
  };
}
