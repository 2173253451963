import { LinkButton } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { OutlineCard } from '@/organisms/cards';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import Box from '@/components/Box';

const CompleteOnboardingCard = ({ flow }) => {
  const { scenario } = useScenarioContext();
  const { me } = Auth.useStore();

  return (
    <OutlineCard>
      <Box sx={{ textAlign: 'center', my: 8, px: 4 }}>
        <Heading variant="h1">Hi {me?.first_name}!</Heading>
        <Paragraph size={Size.LARGE} overrideStyles={{ mt: 4 }}>
          It looks like you have started onboarding for your
          <span style={{ fontWeight: 600 }}> {scenario?.entity?.title} </span>
          role.
        </Paragraph>
        <Paragraph size={Size.LARGE} overrideStyles={{ mt: 2, mb: 6 }}>
          Once you've completed onboarding, you can return here to further
          explore your role and find some interesting insights!
        </Paragraph>
        <Stack alignItems="center">
          <LinkButton
            color="secondary"
            fullWidth={false}
            label="Continue onboarding"
            path={`/f/${flow.uuid}`}
            size={Size.LARGE}
          />
        </Stack>
      </Box>
    </OutlineCard>
  );
};

export default CompleteOnboardingCard;
