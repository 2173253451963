import { useEffect, useRef } from 'react';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';

const MatrixPercentageTitle = ({
  isMuted,
  title,
  subtitle,
  percentage,
  position,
  titleHeight,
  onUpdateHeight,
}) => {
  const ref = useRef();

  useEffect(() => {
    if (!ref?.current?.offsetHeight) {
      return;
    }

    const height = ref.current.offsetHeight;

    onUpdateHeight(height);
  }, [ref?.current?.offsetHeight]);

  return (
    <Stack
      direction="row"
      alignItems={position === Position.TOP ? 'flex-start' : 'flex-end'}
      minHeight={titleHeight}
      width="100%"
      sx={{ textAlign: 'left' }}
      p={1}
      ref={ref}
    >
      <Box>
        <Heading variant="h4" overrideStyles={{ fontWeight: 600 }}>
          {percentage < 1 ? `<1` : percentage}%
        </Heading>
        {title && (
          <Paragraph
            size={Size.X_SMALL}
            overrideStyles={{
              fontWeight: 400,
              mb: subtitle ? 0.5 : 0,
            }}
          >
            {title}
          </Paragraph>
        )}
        {subtitle && (
          <Paragraph
            size={Size.X_SMALL}
            overrideStyles={{
              mb: 0,
            }}
          >
            {subtitle}
          </Paragraph>
        )}
      </Box>
    </Stack>
  );
};

export default MatrixPercentageTitle;
