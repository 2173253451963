import { Avatar } from '@/atoms/avatars';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const HeadingVariant = Object.freeze({
  [Size.X_SMALL]: 'h5',
  [Size.SMALL]: 'h4',
  [Size.MEDIUM]: 'h3',
  [Size.LARGE]: 'h2',
});

const PersonSummary = ({ avatar, person, onClick, size = Size.SMALL }) => {
  const handleClick = (event) => {
    onClick?.(event, person);
  };

  const variant = HeadingVariant[size];

  const name = person?.first_name
    ? `${person.first_name} ${person.last_name}`
    : person.name;

  return (
    person && (
      <Stack direction="row" alignItems="center" onClick={handleClick}>
        <Avatar src={avatar} alt={name} size={size} />
        <Box ml={1}>
          <Heading variant={variant}>{name}</Heading>
        </Box>
      </Stack>
    )
  );
};

export default PersonSummary;
