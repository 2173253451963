import { RouteLevel } from '@/lib/enums';

/**
 * @param {Object} parts
 * @param {String} [parts.designId]
 * @param {String} [parts.revisionId]
 * @param {Object} [parts.level]
 * @param {String} parts.level.type
 * @param {String} parts.level.uuid
 * @param {String} [parts.hash]
 * @param {URLSearchParams} [parts.params]
 * @return {String}
 */
export default function designParts(parts = {}) {
  const { designId, revisionId, level, hash, params } = parts;
  const path = `/d/${designId ?? 'main'}/${revisionId ?? 'latest'}`;
  const searchParams = params ? new URLSearchParams(params).toString() : null;

  const levelPart = RouteLevel[level?.type];
  if (!levelPart) {
    return searchParams ? `${path}?${searchParams}` : path;
  }

  let route = `${path}/${levelPart}/${level.uuid}`;

  if (searchParams) {
    route += `?${searchParams}`;
  }

  if (hash) {
    const items = Object.entries(hash).map(([key, value]) => `${key}=${value}`);
    route += `#${items.join(';')}`;
  }

  return route;
}
