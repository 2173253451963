import { Information, InformationParagraph } from '@/molecules/information';

const LayerDistributionChartInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        This chart presents Manager roles by layer.
      </InformationParagraph>
      <InformationParagraph>
        Select from the 'Layer by' dropdown to present the data in the preferred
        format.
      </InformationParagraph>
      Select the preferred 'Metric Type' from the dropdown to present the chart
      in Sppan, FTE or budget (if permitted).
      <InformationParagraph>
        Click on a seegment in the chart to reveal further information about the
        Manager.
      </InformationParagraph>
    </Information>
  );
};

export default LayerDistributionChartInformation;
