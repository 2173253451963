import { Base as BaseColor } from '@/atoms/colors';
import { TextInput } from '@/atoms/inputs';
import Stack from '@mui/material/Stack';

const FilterSearch = ({ onSearch, placeholder }) => {
  return (
    <Stack
      direction="row"
      py={1}
      px={1}
      sx={{
        boxShadow: `inset 0 -2px 0 ${BaseColor.border.grey}`,
      }}
    >
      <TextInput
        autoFocus={true}
        fullWidth
        color="primary"
        debounce={300}
        initialValue={''}
        placeholder={placeholder}
        variant="naked"
        onChange={onSearch}
      />
    </Stack>
  );
};

export default FilterSearch;
