import { useClient } from '@pkg/client';

export const LOGOUT_USER = /* GraphQL */ `
  mutation Logout($uuid: UUID!) {
    logout(uuid: $uuid) {
      uuid
    }
  }
`;

export default function useLogoutEvent(uuid) {
  const client = useClient();

  async function triggerLogout() {
    return await client.graphql({
      query: LOGOUT_USER,
      variables: { uuid },
    });
  }

  return triggerLogout;
}
