import { useCallback } from 'react';

const useSearch = ({
  searchList,
  searchIndex,
  searchMap,
  limit = 10,
  ignoreTerm,
}) => {
  const search = useCallback(
    ({ searchTerm, filterResult, searchObject }) => {
      const index = searchObject?.index ?? searchIndex;
      const map = searchObject?.map ?? searchMap;
      const list = searchObject?.list ?? searchList;

      if (!searchTerm) {
        return list.slice(0, limit);
      }

      const existing = new Set();
      const results = [];
      let count = 0;

      index.search(searchTerm).forEach((id) => {
        const item = map.get(id);
        const label =
          item?.name ?? item?.title ?? item?.description ?? item.value;

        if (label === ignoreTerm) {
          return;
        }

        if (filterResult && !filterResult(item)) {
          return;
        }

        existing.add(label);

        if (count < limit) {
          results.push(item);
          count++;
        }
      });

      return results.length > 0 ? results : [];
    },
    [searchList, searchIndex, searchMap]
  );

  return search;
};

export default useSearch;
