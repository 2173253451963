import { useEffect, useMemo } from 'react';
import { TextInput } from '@/atoms/inputs';
import { validateNumberInput } from '@/atoms/inputs/utils';
import { DateFilter } from '@/molecules/filterElements';
import { SelectFilter, MultiSelectFilter } from '@/organisms/filters';
import {
  FilterType,
  FilterProperty,
  ComparisonOperator as Comparison,
} from '@/organisms/filters/enums';
import {
  ComparatorMap,
  getFilterPropertyType,
} from '@/organisms/filters/utils';
import Stack from '@mui/material/Stack';

const booleanOptions = [
  {
    id: 'True',
    label: 'True',
    value: true,
  },
  {
    id: 'False',
    label: 'False',
    value: false,
  },
];

/**
 * Builds a filter rule using a comparison operator and a filter type.
 */
const FilterRule = ({ condition, onChange }) => {
  const entity = condition?.get('entity');
  const property = condition?.get('property');
  const comparisonType = condition?.get('type');
  const comparator = condition?.get('comparator');
  const placeholder = `Enter ${entity?.label?.toLowerCase()} ${property?.label?.toLowerCase()}`;
  const propertyType = getFilterPropertyType(entity?.key, property?.key);

  /**
   * Determines which comparison select options should be displayed.
   */
  const comparatorOptions = useMemo(() => {
    const options = [...ComparatorMap.get(comparisonType).entries()].map(
      ([key, option]) => {
        return {
          key,
          ...option,
        };
      }
    );

    // Strip the unnecessary options from the multi-select if we're searching
    // working with people options.
    const personProperty =
      propertyType === FilterProperty.ASSIGNEE ||
      propertyType === FilterProperty.LEAD;

    if (personProperty) {
      return options.filter((option) => {
        return (
          option.key === Comparison?.INCLUDE_ANY ||
          option.key === Comparison?.EXCLUDE_ANY
        );
      });
    }

    return options;
  }, [comparisonType]);

  // We want to ensure the default selected comparator gets stored on the
  // condition when we load the filter rule.
  useEffect(() => {
    if (!comparator && comparatorOptions?.length) {
      onChange?.('comparator', comparatorOptions[0]);
    }
  }, [comparator, comparatorOptions]);

  return (
    <Stack direction="row" spacing={0.5}>
      {comparator && (
        <SelectFilter
          id="comparator"
          value={comparator}
          label="Select a comparison type"
          onChange={onChange}
          optionId="key"
          optionLabel="label"
          options={comparatorOptions}
          emptyText="No options available"
        />
      )}
      {comparisonType === FilterType.BOOLEAN && (
        <SelectFilter
          id="comparator"
          value={booleanOptions.find(
            ({ value }) => value === condition.get('value')
          )}
          label={'Select value'}
          onChange={(event, { value }) => onChange?.('value', value)}
          optionId="id"
          optionLabel="label"
          options={booleanOptions}
          emptyText="No options available"
        />
      )}
      {comparisonType === FilterType.TEXT && (
        <TextInput
          autoFocus={true}
          color="primary"
          debounce={300}
          initialValue={condition?.get('value')}
          onChange={(event, value) => onChange?.('value', value)}
          placeholder={placeholder}
          width={220}
        />
      )}
      {comparisonType === FilterType.NUMBER && (
        <TextInput
          autoFocus={true}
          color="primary"
          debounce={300}
          initialValue={condition?.get('value')}
          inputProps={{
            type: 'number',
            onKeyDown: validateNumberInput(true, 1),
          }}
          onChange={(event, value) => onChange?.('value', value)}
          placeholder={0}
          width={80}
        />
      )}
      {comparisonType === FilterType.DATE && (
        <DateFilter
          value={condition?.get('value')}
          id="value"
          onChange={onChange}
        />
      )}
      {comparisonType === FilterType.MULTI_SELECT && (
        <MultiSelectFilter
          entity={condition?.get('entity')?.key}
          property={condition?.get('property')?.key}
          propertyId={condition?.get('propertyId')?.uuid}
          value={condition?.get('value')}
          id="value"
          onChange={onChange}
        />
      )}
    </Stack>
  );
};

export default FilterRule;
