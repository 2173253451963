import { createContext, useContext, useMemo } from 'react';
import { useSearch } from '@/shared/hooks';
import { Properties } from '@pkg/entities/library';
import usePropertiesIndex from './usePropertiesIndex';

export const PropertyContext = createContext();
export const usePropertyContext = () => useContext(PropertyContext);

const PropertyProvider = ({ children }) => {
  const propertyMap = Properties.useStore((state) => state.mapped);
  const propertyList = Properties.useStore((state) => state.list);
  const propertyIndex = Properties.useStore((state) => state.index);
  const { updatePropertyIndex, getProperty } = usePropertiesIndex();

  const search = useSearch({
    list: propertyList,
    map: propertyMap,
    searchIndex: propertyIndex,
  });

  const searchPropertyNames = (searchTerm) => {
    const searchObject = {
      list: propertyList,
      map: propertyMap,
      searchIndex: propertyIndex,
    };

    return search({ searchTerm, searchObject });
  };

  const searchPropertyValues = (id, searchTerm) => {
    const property = getProperty(id);

    if (!property) {
      return;
    }

    const searchObject = {
      list: property.list,
      map: property.map,
      index: property.index,
    };

    return search({ searchTerm, searchObject });
  };

  const updatePropertyIndexes = (map) => {
    [...map].forEach(([id, set]) => {
      const property = propertyMap.get(id);

      if (!property) {
        return;
      }

      const mappedValues = new Map(
        [...set].map((value) => {
          const name =
            property?.type === 'DROPDOWN'
              ? property?.map?.get(value)?.name
              : value;
          return [value, { uuid: value, name }];
        })
      );

      updatePropertyIndex(id, mappedValues);
    });
  };

  const context = useMemo(() => {
    return {
      getProperty,
      propertyList,
      propertyMap,
      searchPropertyNames,
      searchPropertyValues,
      updatePropertyIndexes,
    };
  }, [propertyList, propertyMap]);

  return (
    <PropertyContext.Provider value={context}>
      {children}
    </PropertyContext.Provider>
  );
};

export default PropertyProvider;
