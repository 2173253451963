import {
  PlanActions,
  PlanActivities,
  PlanRoles,
  PlanTeams,
} from '@/organisms/plans';
import PlanTab from '../enums/PlanTab';

const PlanList = ({ id }) => {
  switch (id) {
    case PlanTab.ACTIVITIES:
      return <PlanActivities />;
    case PlanTab.ROLES:
      return <PlanRoles />;
    case PlanTab.TEAMS:
      return <PlanTeams />;
    default:
      return <PlanActions />;
  }
};

export default PlanList;
