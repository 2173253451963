import useMutate from './useMutate';

/**
 * @returns {Object}
 */
export default function useRemoveRole() {
  const mutate = useMutate();

  /**
   * @param {String} design_uuid
   * @param {String} group_uuid
   * @param {String} role_uuid
   * @returns {Promise}
   */
  async function remove(design_uuid, group_uuid, role_uuid) {
    const now = Date.now();

    const mutation = {
      created_at: now,
      entities: {
        groups: {
          update: [{ uuid: group_uuid, updated_at: now }],
        },
        roles: {
          remove: [role_uuid],
        },
      },
    };

    return await mutate(design_uuid, mutation);
  }

  return remove;
}
