import TagCategory from '@/components/DashboardContainer/enums/TagCategory';
import aggregateEntity from './aggregateEntity';
import getEntityProps from './getEntityProps';

export default function mapTagEntityItems({
  activity,
  group,
  entities,
  entityMetrics,
  map,
  order,
  orderIndex,
  person,
  remainingSet,
  role,
  tags,
  tagMap,
}) {
  const remainingOrder = order.slice(orderIndex);

  tags.forEach((tagId) => {
    let updatedMap = map;
    const tag =
      tagId === TagCategory.NOT_TAGGED
        ? {
            uuid: tagId,
            name: 'Not tagged',
            color: 'GREY_LIGHT',
          }
        : tagMap.get(tagId);

    // If the tag doesn't exist in the library we want to exit here as a
    // safety precaution.
    if (!tag) {
      return;
    }

    // Add the remaining order for each tag.
    remainingOrder.forEach((entityType, index) => {
      const nextEntity = remainingOrder[index + 1];

      const props = getEntityProps({
        activity,
        entityType,
        group,
        role,
        person,
        tag,
      });

      updatedMap = aggregateEntity({
        ...props,
        entities,
        existingMetrics: entityMetrics,
        map: updatedMap,
        nextEntity,
        remainingSet,
        type: entityType,
      });
    });
  });

  return map;
}
