import { useEffect, useState } from 'react';
import { PlanEntityTypeCard } from '@/organisms/cards';
import {
  EmptyPlanList,
  mapAggregateRoles,
  PlanListSkeleton,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import { Sort } from '@pkg/utils';

const PlanRoles = () => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const roles = mapAggregateRoles({ scenario, comparisonScenario });

    if (!roles) {
      return;
    }

    setRoles(
      [...roles.entries()]
        .map(([id, role]) => {
          return {
            ...role,
          };
        })
        .sort(Sort.alpha('props.title'))
    );
    setLoading(false);
  }, [scenario?.details?.hash, comparisonScenario?.details?.hash]);

  return loading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {roles.length > 0 ? (
        <Grid container spacing={2}>
          {roles.map((role) => (
            <Grid item xs={12} key={role.props.title}>
              <PlanEntityTypeCard
                entityType={EntityType.ROLE}
                metrics={role.metrics}
                entityCount={role[EntityType.ROLE]?.size || 0}
                entityMap={role[EntityType.ROLE]}
                title={role.props.title}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyPlanList
          message={`No role changes have been found in this scenario.`}
        />
      )}
    </>
  );
};

export default PlanRoles;
