import { memo, useEffect, useState } from 'react';
import Text from './Text';

const DEFAULT_STEP = 0.25;

function roundHours(num) {
  return num !== null
    ? Math.round(num * (1 / DEFAULT_STEP)) / (1 / DEFAULT_STEP)
    : '';
}

const HoursInput = ({ onBlur, helperText, ...props }) => {
  const [value, setValue] = useState(props.value);

  const handleBlur = (event, value) => {
    const blurred = roundHours(value);
    onBlur?.(event, blurred);
    setValue(blurred);
  };

  const handleChange = (_, value) => {
    setValue(value);
  };

  useEffect(() => {
    setValue(roundHours(props.value));
  }, [props.value]);

  return (
    <Text
      {...props}
      disableClear
      helperText={helperText}
      inputProps={{ min: '0', max: '999', step: DEFAULT_STEP }}
      initialValue={props.value}
      onBlur={handleBlur}
      onChange={handleChange}
      type="number"
      value={value}
    />
  );
};

HoursInput.propTypes = Text.propTypes;

export default memo(HoursInput);
