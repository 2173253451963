import { MetricProperty } from '@/atoms/enums';
import { EntityMetric } from '@/lib/enums';
import defaultMetricList from './defaultMetricList';

export default function metricListFromObject(
  metrics,
  metricList = defaultMetricList,
  showBudget
) {
  if (!metricList?.length) {
    return;
  }

  return metricList
    .map((metric) => {
      // Don't allow budget data to be shown unless allowed.
      if (!showBudget && metric === EntityMetric.BUDGET) {
        return;
      }
      const value = metrics?.[metric];
      if (typeof value === 'undefined' || value === null) {
        return;
      }
      const label = MetricProperty[metric].label;
      return {
        label: value > 1 ? label.plural : label.singular,
        value,
        formatOptions: MetricProperty[metric].formatOptions,
      };
    })
    .filter((metric) => metric);
}
