import { useEffect, useState } from 'react';

export default function useLocalState(state) {
  const [localState, setLocalState] = useState(state);

  useEffect(() => {
    setLocalState(state);
  }, [JSON.stringify(state)]);

  return [localState, setLocalState];
}
