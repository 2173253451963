import { useEffect, useState } from 'react';
import { useDatabase } from '@pkg/database';
import { Designs } from '@pkg/entities';
import publishToRole from '@pkg/entities/designs/mutations/reducers/roles/publishToRole';
import { Action } from '@/lib/enums';

/**
 * Provides publishing actions for scenarios.
 */
const usePublishScenario = ({ access, design, scenario, snapshot }) => {
  const mutate = Designs.mutations.useMutate();
  const database = useDatabase();
  const mainDesign = Designs.useStore((state) => state.main);
  const mutations = Designs.useStore((state) => state.mutations);

  const [canPublish, setCanPublish] = useState(false);

  // Sets the publishing permission.
  useEffect(() => {
    if (!scenario?.entity?.uuid || !access.isReady) {
      return;
    }

    const canPublish = access?.can(
      {
        uuid: scenario?.entity?.uuid,
        __type: scenario?.entity?.__type,
      },
      null,
      Action.PUBLISH
    );

    setCanPublish(canPublish);
  }, [scenario?.entity?.uuid, access?.isReady]);

  const createInput = ({ liveSnapshot, scenarioSnapshot, title, uuid }) => {
    const { created_at, name, objectives, entities, __hash } = scenarioSnapshot;

    const from = {
      snapshot: { created_at, name, objectives, entities, __hash },
      uuid: uuid,
    };
    const to = {
      snapshot: liveSnapshot,
      title,
      uuid,
    };

    return structuredClone({ from, to });
  };

  /**
   * Publishes a role from one design (scenario) over a role with the same
   * uuid on the main design.
   *
   * @param {String} designId
   * @param {String} roleTitle
   * @param {String} roleId
   *
   * @return {Object}
   */
  const publish = async ({ title, uuid, message }) => {
    if (!canPublish) {
      return;
    }

    const latest = await database.designRevisions.get(mainDesign.latest.uuid);

    if (!mainDesign || !latest || !snapshot) {
      return;
    }

    const input = createInput({
      liveSnapshot: latest?.snapshot,
      scenarioSnapshot: snapshot,
      title,
      uuid,
    });

    const { mutation, role: published } = publishToRole(input);
    await mutate(mainDesign.uuid, mutation);
    await mutations.archiveScenario(design.uuid, message);

    return published;
  };

  return {
    canPublish,
    publish,
  };
};

export default usePublishScenario;
