import { DesignEntity } from '@/lib/enums';

export default function iterateHierarchy({ keyed, id, type, map }) {
  const plural = DesignEntity.toPlural(type);
  const entity = keyed.entities?.[plural][id];

  if (!entity) {
    return;
  }

  map.get(type).set(id, {
    ...entity,
    __type: type,
  });

  const entityChildren = [];

  entity.__children?.forEach((type, id) => {
    const items = iterateHierarchy({
      keyed,
      id,
      map,
      type,
    });

    if (!items) {
      return;
    }

    entityChildren.push(items);
  });

  return {
    uuid: entity.uuid,
    type,
    children: entityChildren,
  };
}
