import { useMemo } from 'react';
import {
  sumPercentages,
  sumUnique,
  sumUniquePercentages,
} from '@/organisms/tables/utils';

const useAggregateFunctions = () => {
  return useMemo(() => {
    return {
      sum: (params) => {
        const value = params.rowNode.getValueFromValueService(params.column);

        // Switch to the percentages sum function if we have a percentage
        // present.
        if (Object.prototype.hasOwnProperty.call(value, 'numerator')) {
          return value?.colId
            ? sumUniquePercentages(params)
            : sumPercentages(params);
        }

        // Switch to the unique sum if we have a colId present.
        if (value.colId) {
          return sumUnique(params);
        }

        const { values } = params;

        return values.reduce((prev, next) => {
          if (typeof next === 'number') {
            return (prev += next);
          }
          return prev;
        }, 0);
      },
    };
  }, []);
};

export default useAggregateFunctions;
