import { produce } from 'immer';
import { useCallback, useMemo } from 'react';
import { useLocalState } from '@pkg/hooks';
import { Collections } from '@pkg/utils';
import { get } from '@pkg/utils/objects';
import * as Reducers from './reducers';
import useMerged from './useMerged';

/**
 * @param {Object} templates
 * @param {Function} onMutate
 * @returns {Array}
 */
export default function useTemplates(templates, onMutate = () => {}) {
  const keyed = useMemo(() => Collections.keyById(templates), [templates]);
  const [state, setState] = useLocalState(keyed);
  const merged = useMerged(state);

  const handler = async (action, input) => {
    const template = get(Reducers, action)(merged, input);
    if (template) {
      switch (action) {
        case 'roles.create':
        case 'roles.duplicate':
          setState(
            produce(state, (draft) => {
              draft[template.uuid] = template;
            })
          );
          await onMutate('create', template);
          break;
        case 'roles.remove':
          setState(
            produce(state, (draft) => {
              delete draft[template.uuid];
            })
          );
          await onMutate('remove', template);
          break;
        default:
          setState(
            produce(state, (draft) => {
              draft[template.uuid] = {
                ...draft[template.uuid],
                ...template,
              };
            })
          );
          await onMutate('update', template);
          break;
      }
    }
  };

  const dispatch = useCallback(handler, [merged, onMutate]);
  return [merged, dispatch];
}
