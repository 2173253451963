import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';

const ARCHIVE_DESIGN = /* GraphQL */ `
  mutation ArchiveScenario($input: ArchiveScenarioInput!) {
    archiveScenario(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useArchiveScenario() {
  const database = useDatabase();
  const { remove } = useStore();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({ mutation: ARCHIVE_DESIGN });

  /**
   * @param {String} id
   * @returns {Promise}
   */
  async function removeScenario(id) {
    remove(id);
    await Promise.all([
      database.designs.delete(id),
      database.designRevisions.where('design.uuid').equals(id).delete(),
    ]);
  }

  /**
   * @param {String} design_uuid
   * @returns {Promise}
   */
  async function archiveScenario(
    design_uuid,
    archiveMessage = 'Archiving your scenario'
  ) {
    enqueueSnackbar(archiveMessage);
    const result = await mutateAsync({ design_uuid });
    await removeScenario(design_uuid);
    return result;
  }

  return useCallback(archiveScenario, [mutateAsync]);
}
