import { cloneElement, useEffect, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import {
  PeopleSelect,
  PropertySelect,
  RoleSelect,
  SkillSelect,
  TagSelect,
} from '@/molecules/filterElements';
import { Popover } from '@/molecules/modals';
import { FilterOptions } from '@/organisms/filters';
import { FilterProperty } from '@/organisms/filters/enums';
import {
  buildFilterValueLabel,
  getFilterEntityType,
  getFilterPropertyType,
} from '@/organisms/filters/utils';
import { Position } from '@/lib/enums';
import FilterFooter from './FilterFooter';

/**
 * Determine the filter component to be rendered.
 *
 * @param {String} propertyType
 *
 * @return {React.Component}
 */
const filterComponent = (propertyType, propertyId) => {
  if (!propertyType) {
    return;
  }

  switch (propertyType) {
    case FilterProperty.ASSIGNEE:
    case FilterProperty.LEAD:
      return <PeopleSelect />;
    case FilterProperty.PROPERTY:
      return <PropertySelect propertyId={propertyId} />;
    case FilterProperty.ROLE_UUID:
      return <RoleSelect categorised />;
    case FilterProperty.SKILL:
      return <SkillSelect />;
    case FilterProperty.TAG:
      return <TagSelect categorised />;
    default:
  }
};

const MultiSelectFilter = ({
  entity,
  property,
  propertyId,
  value,
  id,
  onChange,
}) => {
  const [entityType, setEntityType] = useState();
  const [propertyType, setPropertyType] = useState();
  const Component = filterComponent(propertyType, propertyId);
  const [buttonLabel, setButtonLabel] = useState();

  const handleClear = () => {
    onChange?.(id, null);
  };

  useEffect(() => {
    if (!entity || !property) {
      return;
    }

    setEntityType(getFilterEntityType(entity, property));
    setPropertyType(getFilterPropertyType(entity, property));
  }, [entity, property]);

  useEffect(() => {
    setButtonLabel(buildFilterValueLabel(propertyType, value));
  }, [value, propertyType]);

  return (
    <Popover
      Component={
        <Button
          color={value?.length > 0 ? 'light-blue' : 'secondary'}
          size={Size.MEDIUM}
          label={buttonLabel}
        />
      }
      position={Position.BOTTOM_LEFT}
    >
      {Component && (
        <FilterOptions
          FooterComponent={<FilterFooter propertyType={propertyType} />}
          onClear={handleClear}
        >
          {cloneElement(Component, {
            entityType,
            value,
            onChange: (event, value) => onChange(id, value),
          })}
        </FilterOptions>
      )}
    </Popover>
  );
};

export default MultiSelectFilter;
