/**
 * @param {String} value
 * @returns {String}
 */
export default function pluralise(value) {
  if (value.slice(-1) === 'y') {
    return `${value.slice(0, value.length - 1)}ies`;
  }

  return `${value}s`;
}
