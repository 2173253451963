/**
 * @param {Object[]} collection
 * @param {String} key
 * @param {any} value
 *
 * @return {Object}
 */
export function where(collection, key, value) {
  if (!collection) {
    return collection;
  }

  if (!collection?.length) {
    return [];
  }

  const list = collection.filter((item) => item[key] === value);
  return structuredClone(list);
}

/**
 * @param {Object[]} collection
 * @param {String[]} groupId
 * @return {Object[]}
 */
export function whereGroup(collection, groupId) {
  return where(collection, 'group_uuid', groupId);
}

/**
 * @param {Object[]} collection
 * @param {String[]} ownerId
 * @return {Object[]}
 */
export function whereOwner(collection, ownerId) {
  return where(collection, 'owner_uuid', ownerId);
}

/**
 * @param {Object[]} collection
 * @param {String[]} parentId
 * @return {Object[]}
 */
export function whereParent(collection, parentId) {
  return where(collection, 'parent_uuid', parentId);
}
