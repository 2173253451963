import { Snapshots } from '@pkg/entities';
import { DesignScope } from '@/lib/enums';
import useCreateScenario from './useCreateScenario';

export default function usePrioritize(designId, snapshot) {
  const createScenario = useCreateScenario();

  /**
   * @param {String} scenarioId
   * @param {String} groupId
   * @param {String} name
   * @param {String} goal
   * @param {DesignScope} scope
   * @param {Object|String} input
   * @param {String} [input.groupId]
   * @param {String[]} [input.roleIds]
   * @return {Promise}
   */
  function prioritize(scenarioId, name, goal, scope, input) {
    const fromId = scope === DesignScope.ROLE ? input : input.groupId;
    const roleIds = scope === DesignScope.ROLE ? [input] : input.roleIds;
    const mutation = Snapshots.reducers.roles.prioritize(snapshot, roleIds);
    if (scope === DesignScope.ROLE) {
      delete mutation.entities.groups;
    }

    return createScenario({
      uuid: scenarioId,
      scope,
      name,
      goal,
      mutation,
      from: {
        design_uuid: designId,
        entity_uuid: fromId,
      },
    });
  }

  return prioritize;
}
