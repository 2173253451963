import { Collections, Colors } from '@pkg/utils';

/**
 * @param {Object} me
 * @param {Object[]} users
 * @return {Object[]}
 */
export default function filterUsers(me, users) {
  const unique = Collections.uniqueById(users);
  return unique
    .filter((user) => user.uuid !== me?.uuid)
    .map((user) => ({
      ...user,
      __color: Colors.seeded(user?.first_name)[600],
    }));
}
