import { useEffect, useState } from 'react';
import { wholeNumber } from '@pkg/utils/inputs';
import Text from './Text';

const IntegerInput = ({ max, onBlur, onChange, ...props }) => {
  const [value, setValue] = useState(wholeNumber.format(props.value));

  const handleBlur = (event, value) => {
    const blurred = wholeNumber.blur(value, { max });
    onBlur?.(event, blurred);
    setValue(wholeNumber.format(blurred));
  };

  const handleChange = (event, value) => {
    const changed = wholeNumber.change(value);
    onChange?.(event, changed);
    setValue(changed);
  };

  useEffect(() => {
    setValue(wholeNumber.format(props.value));
  }, [props.value]);

  return (
    <Text
      {...props}
      disableClear
      initialValue={props.value}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
    />
  );
};

IntegerInput.propTypes = Text.propTypes;

export default IntegerInput;
