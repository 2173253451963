import env from '@beam-australia/react-env';

const { TrackJS } = process.browser
  ? require('trackjs')
  : require('trackjs-node');

export const install = () => {
  if (!TrackJS.isInstalled() && env('TRACKJS_API_KEY')) {
    const options = {
      token: env('TRACKJS_API_KEY'),
      application: `beamible-${env('PLATFORM_ENV')}`,
    };

    TrackJS.install(options);
  }
};

export const track = (event) => {
  TrackJS.track(event);
};

export const identify = (userId) => {
  TrackJS.configure({ userId });
};

install(); // call once at run time for default install

const Track = {
  track,
  identify,
  install,
};
export default Track;
