import { Size } from '@/atoms/enums';

const RelativeSize = {
  smaller(size, factor = 1) {
    const sizes = Object.entries(Size);
    const index = sizes.findIndex(([_, value]) => value === size);
    const newIndex = index - factor < 0 ? 0 : index - factor;
    return sizes[newIndex];
  },
  larger(size, factor = 1) {
    const sizes = Size.entries();
    const index = sizes.findIndex(([_, value]) => value === size);
    const newIndex =
      index + factor >= sizes.length ? sizes.length - 1 : index + factor;
    return sizes[newIndex];
  },
};

export default RelativeSize;
