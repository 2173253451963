import { useLibrary } from '@pkg/entities/library';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';

const useCreatePrompt = () => {
  const snapshot = useDesignStore((state) => state.snapshot);
  const {
    tags,
    activities: activityLibrary,
    flowTemplates,
    properties,
    skills,
  } = useLibrary();
  const { messages } = useMessages();
  const lastMessages = messages.slice(-8);

  const createPrompt = async ({ basePrompt, userText, stream = false }) => {
    const {
      roles = [],
      activities = [],
      groups = [],
    } = snapshot.entities || {};
    return await basePrompt({
      stream,
      userText,
      snapshot,
      tags,
      activityLibrary,
      flowTemplates,
      lastMessages,
      properties,
      roles,
      skills,
    });
  };

  return createPrompt;
};

export default useCreatePrompt;
