import openai from '../client';
import activityUpdate from '../tools/reducers/activities/update';
import roleAdd from '../tools/reducers/roles/add';

const YAML = require('json-to-pretty-yaml');

/*  
@todo - inject prompts people use often
*/

/*
groups:
  - name: "Engineering"
    objective: "Engineers are responsible for building things"
    roles: 
      - uuid: "role-uuid-a"
        user_uuid: "user-uuid-a"
        title: "Role title A"
        fte: 1.0
        budget: 100000
        activities:
          - uuid: "activity-uuid-a"
            owner_uuid: "role-uuid-a"
            description: "Activity A"
            hours: 12
            tags:
              - a-uuid
              - b-uuid
              - c-uuid
          - uuid: "activity-uuid-b"
            owner_uuid: "role-uuid-b"
            description: "Activity B"
            hours: 1
            tags:
              - d-uuid
              - e-uuid
              - f-uuid

*/

const mutationsPrompt = async ({
  userText,
  snapshot,
  tags,
  properties,
  lastMessages,
}) => {
  const { activities, groups, roles } = snapshot.entities;
  console.log('blue', {
    userText,
    groups,
    roles,
    activities,
    tags,
    properties,
    lastMessages,
  });
  const mutationPrompt = `
    You are an organizational programming bot, there is a javascript app that creates and updates organisational designs. You are also knowledgeable about underlying issues inside an organization and has the data to improve productivity and energizing work. 

      This is the GraphQL shape of a group 
      type Group {
        uuid: UUID!
        lead_uuid: UUID
        group_uuid: UUID
        name: String!
        objective: String
        tags: [UUID!]
        properties: [KeyValuePair!]
        order: Int
        created_at: Float!
        updated_at: Float!
      }

      This is the GraphQL shape of a role 
      type Role {
        uuid: UUID!
        parent_uuid: UUID
        group_uuid: UUID
        user_uuid: UUID
        title: String!
        fte: Float
        budget: Int
        skills: [Skill!]
        tags: [UUID!]
        created_at: Float!
        updated_at: Float!
      }

      This is the GraphQL shape of an activity
      type Activity {
        uuid: UUID!
        library_uuid: UUID!
        owner_uuid: UUID!
        hours: Float!
        tags: [UUID!]
        created_at: Float!
        updated_at: Float!
        __description: String
      }


      Here is the current organizational structure:

      ${YAML.stringify({
        groups: groups.slice(0, 30).map((group) => ({
          name: group.name,
          objective: group.objective,
          roles: roles
            .filter((role) => role.group_uuid === group.uuid)
            .map((role) => ({
              uuid: role.uuid,
              user_uuid: role.user_uuid,
              title: role.title,
              fte: role.fte,
              budget: role.budget,
              activities: activities
                .filter((activity) => activity.owner_uuid === role.uuid)
                .map((activity) => ({
                  uuid: activity.uuid,
                  owner_uuid: activity.owner_uuid,
                  description: activity.__description,
                  hours: activity.hours,
                  tags: activity.tags || [],
                })),
            })),
        })),
      })}

      Here is a list of tags;

      ${YAML.stringify(
        tags.tags.list.slice(0, 30).map((tag) => ({
          uuid: tag.uuid,
          name: tag.name,
          description: tag.description,
        }))
      )}

      Here are the methods you can invoke per entity. 

      Rules:
      - Only invoke a function once per entity. You can invoke a function multiple times if you need to across other entities. 

      Roles:
      - add: create a role
        - If you are adding multiple of the same role, pass a value to the total key
      - update: update a role

          Last messages;
          ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

          The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.
      Just use industry standard values for assumptions if the user does not specify them such as budget and fte. just keep the group_uuid null

      User request: ${userText}
    `;
  console.log({ mutationPrompt });
  const prompt = {
    model: openai('gpt-4-turbo'),
    tools: {
      role_add: roleAdd,
      activity_update: activityUpdate,
    },

    prompt: mutationPrompt,
  };

  return prompt;
};

export default mutationsPrompt;
