const DimensionType = Object.freeze({
  ACTIVITY: 'Activity',
  ACTIVITY_TAGS_ACTIVITY: 'Activity tags',
  ROLE: 'Role',
  ROLE_TAGS_ACTIVITY: 'Role tags by activity',
  ROLE_TAGS_ROLE: 'Role tags',
  ROLE_SKILLS: 'Role skills',
  PEOPLE_SKILLS: 'People skills',
  GROUP: 'Team',
  GROUP_TAGS_ACTIVITY: 'Team tags by activity',
  GROUP_TAGS_GROUP: 'Team tags',
});

export default DimensionType;
