import { People } from '@pkg/entities';

/**
 * @param {Object} me
 * @param {Object} permissions
 * @returns {Boolean}
 */
export default function edit(me, permissions) {
  return People.can(me, permissions.organisation.write);
}
