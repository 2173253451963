import { useState } from 'react';
import { styled } from '@mui/material/styles';
import config from '@/lib/config';
import Checkbox from '../Checkbox';
import ConsentDialog from './ConsentDialog';

const Label = styled('span')({
  label: {
    fontSize: '0.875rem',
  },
});

const ConsentStatement = (props) => {
  const [open, setOpen] = useState(false);

  const handleOpen = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  const termsLink = (
    <a href={config.LINKS.TERMS} target="_blank" rel="noreferrer">
      Terms and Conditions
    </a>
  );

  const privacyLink = (
    <a href="#" onClick={handleOpen}>
      Privacy Collection Notice
    </a>
  );

  const consentLabel = (
    <Label>
      I agree to the {termsLink} and {privacyLink} (and confirm that I have the
      authority to agree to these documents on behalf of the Licensee).
    </Label>
  );

  return (
    <>
      <Checkbox
        name="consent"
        {...props}
        data-testid="consent-chkbx"
        label={consentLabel}
      />
      <ConsentDialog open={open} onClose={() => setOpen(false)} />
    </>
  );
};

export default ConsentStatement;
