import env from '@beam-australia/react-env';
import design from './designParts';

/**
 * @param {Object} parts
 * @return {String}
 */
export default function direct(parts = {}) {
  return env('WEB_HOST') + design(parts);
}
