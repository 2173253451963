import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { CircleBackgroundIcon } from '@/atoms/icons';
import AddIcon from '@mui/icons-material/AddRounded';

const AddFilterButton = ({ label, onClick }) => {
  return (
    <Button
      color="light-blue"
      variant="naked"
      label={label}
      fullWidth={false}
      onClick={onClick}
      size={Size.MEDIUM}
      startIcon={
        <CircleBackgroundIcon
          color="light-blue"
          size={Size.X_SMALL}
          icon={<AddIcon />}
        />
      }
    />
  );
};

export default AddFilterButton;
