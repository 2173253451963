import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { TagChip } from '@/atoms/chips';
import FilterType from '@/molecules/tableElements/enums/FilterType';
import { useTagContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

const TagFilter = forwardRef(function tagFilter(props, ref) {
  const { tagMap } = useTagContext();

  const [selectedMap, setSelectedMap] = useState(new Map());
  const [filterType, setFilterType] = useState(FilterType.CONTAINS_ANY);

  const handleSelectTag = (value) => {
    const tag = tagMap.get(value);
    const selected = new Map(selectedMap);

    if (!tag?.name) {
      return;
    }

    if (selected.has(value)) {
      selected.delete(value);
    } else {
      selected.set(value, tag.name);
    }

    setSelectedMap(selected);
  };

  const handleFilterTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilterType(value);
  };

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return selectedMap.size > 0;
      },
      doesFilterPass(params) {
        const columnValue = params.node.getValueFromValueService(props.column);

        if (!columnValue) {
          return false;
        }

        const tags = Array.isArray(columnValue) ? columnValue : [columnValue];

        switch (filterType) {
          case FilterType.ONLY_CONTAINS:
            if (tags.length !== selectedMap.size) {
              return false;
            }

            for (let i = 0; i < tags.length; i++) {
              if (!selectedMap.has(tags[i].id)) {
                return false;
              }
            }
            return true;
          case FilterType.NOT_CONTAINS:
            for (let i = 0; i < tags.length; i++) {
              if (selectedMap.has(tags[i].id)) {
                return false;
              }
            }
            return true;
          case FilterType.CONTAINS_ALL:
            const tempMap = new Map(selectedMap);
            for (let i = 0; i < tags.length; i++) {
              if (tempMap.has(tags[i].id)) {
                tempMap.delete(tags[i].id);
              }

              if (!tempMap.size) {
                return true;
              }
            }
            return false;
          // Contains any.
          default:
            for (let i = 0; i < tags.length; i++) {
              if (selectedMap.has(tags[i].id)) {
                return true;
              }
            }
            return false;
        }
      },
      getModel() {
        if (!selectedMap.size) {
          return undefined;
        }

        return {
          state: {
            selectedMap,
            filterType,
          },
        };
      },
      setModel(model) {
        if (model === null) {
          setSelectedMap(new Map());
          setFilterType(FilterType.CONTAINS_ANY);
        } else {
          setSelectedMap(model.state.selectedMap);
          setFilterType(model.state.filterType);
        }
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [selectedMap, filterType]);

  return (
    tagMap.size && (
      <Box p={1}>
        <Box pt={1}>
          <FormControl size="small">
            <InputLabel id="filter-type">Filter type</InputLabel>
            <Select
              value={filterType}
              labelId="filter-type"
              label="Filter type"
              onChange={handleFilterTypeChange}
              size="sm"
            >
              <MenuItem value={FilterType.CONTAINS_ANY}>
                {FilterType.CONTAINS_ANY}
              </MenuItem>
              <MenuItem value={FilterType.CONTAINS_ALL}>
                {FilterType.CONTAINS_ALL}
              </MenuItem>
              <MenuItem value={FilterType.ONLY_CONTAINS}>
                {FilterType.ONLY_CONTAINS}
              </MenuItem>
              <MenuItem value={FilterType.NOT_CONTAINS}>
                {FilterType.NOT_CONTAINS}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {props.values.map((category) => (
          <>
            <h5 style={{ marginBottom: '8px' }}>{category.name}</h5>
            {category.options.map((tag) => (
              <Stack
                key={tag.id}
                alignItems="center"
                direction="row"
                mb={1}
                spacing={1}
              >
                <input
                  type="checkbox"
                  name={tag.properties.name}
                  onChange={() => handleSelectTag(tag.id)}
                  checked={selectedMap.has(tag.id)}
                />
                <TagChip
                  color={tag.properties.color}
                  expanded={true}
                  id={tag.id}
                  name={tag.properties.name}
                  onClick={() => handleSelectTag(tag.id)}
                />
              </Stack>
            ))}
          </>
        ))}
      </Box>
    )
  );
});

export default TagFilter;
