import { useEffect, useState } from 'react';
import { PlanEntityTypeCard } from '@/organisms/cards';
import {
  EmptyPlanList,
  mapAggregateActivities,
  PlanListSkeleton,
} from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import { Sort } from '@pkg/utils';

const PlanActivities = () => {
  const { comparisonScenario, scenario } = useScenarioContext();
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const activities = mapAggregateActivities({ scenario, comparisonScenario });

    if (!activities) {
      return;
    }

    setActivities(
      [...activities.entries()]
        .map(([id, activity]) => {
          return {
            ...activity,
          };
        })
        .sort(Sort.alpha('props.title'))
    );
    setLoading(false);
  }, [scenario?.details?.hash, comparisonScenario?.details?.hash]);

  return loading ? (
    <PlanListSkeleton />
  ) : (
    <>
      {activities.length > 0 ? (
        <Grid container spacing={2}>
          {activities.map((activity) => (
            <Grid item xs={12} key={activity.props.title}>
              <PlanEntityTypeCard
                entityType={EntityType.ACTIVITY}
                metrics={activity.metrics}
                entityCount={activity[EntityType.ACTIVITY]?.size || 0}
                entityMap={activity[EntityType.ACTIVITY]}
                title={activity.props.title}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyPlanList
          message={`No activity changes have been found in this scenario.`}
        />
      )}
    </>
  );
};

export default PlanActivities;
