export default function sumUnique(params) {
  const { values } = params;

  // This id set is necessary for creating unique ids for pivot group columns
  // and tracking their unique values.
  const idSet = new Set();

  const value = values.reduce((previous, next) => {
    if (next === null || typeof next === 'undefined' || !next) {
      return previous;
    }

    // Only add this value if it's unique.
    if (idSet.has(next.colId)) {
      return previous;
    } else {
      idSet.add(next.colId);
    }

    if (typeof next === 'number') {
      return (previous += next);
    } else {
      return (previous += next.value);
    }
  }, 0);

  const colId = idSet?.size ? [...idSet].join('-') : 'base';

  return {
    colId: colId,
    value,
    toString: () => (isNaN(value) ? null : `${value?.toFixed(2)}`),
  };
}
