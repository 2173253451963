import { useListen, useObservable } from '@pkg/broadcasting';
import useFetch from './useFetch';

export default function useNotify() {
  const { fetch } = useFetch();
  const channelType = 'PrivateChannel';
  const channelName = 'Access.notify';
  const listeners = [
    {
      event: '.access.updated',
      context: 'access',
      callback: fetch,
    },
  ];

  const notify = useObservable(channelName, listeners);
  useListen({ channelName, channelType }, notify);
}
