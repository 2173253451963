import { useMemo } from 'react';
import Productivity from '@pkg/insights/Productivity';
import WorkHealth from '@pkg/insights/WorkHealth';
import { Collections } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import * as color from '@/lib/theme/tokens/color';

export default function useInsights(snapshot, uuid) {
  return useMemo(() => {
    const group = Collections.findById(snapshot.entities.groups, uuid);
    const groupRoles = Collections.where(
      snapshot.entities.roles,
      'group_uuid',
      uuid
    );

    const insights = [];

    insights.push({
      title: 'Productivity',
      labels: ['Strategic\nImportance', 'Administrative', 'Internal\nMeetings'],
      breakdownLabels: [
        'Strategic\nImportance',
        'Administrative',
        'Internal\nMeetings',
      ],
      summary: [
        {
          color: color.core.blue,
          data: Productivity.getEntityResults(group),
          label: 'Team',
        },
        {
          color: color.core.navy,
          data: Productivity.getEntityResults(snapshot),
          label: 'Organisation',
        },
      ],
      breakdown: groupRoles.map((role) => ({
        uuid: role.uuid,
        type: DesignEntity.ROLE,
        data: Productivity.getEntityResults(role),
      })),
    });

    insights.push({
      title: 'Work Health',
      labels: ['Energising', 'Average\nWorkload', 'Over\nCapacity'],
      breakdownLabels: ['Energising', 'Average\nWorkload'],
      summary: [
        {
          color: color.core.yellow,
          data: WorkHealth.getEntityResults(group),
          label: 'Team',
        },
        {
          color: color.core.pink,
          data: WorkHealth.getEntityResults(snapshot),
          label: 'Organisation',
        },
      ],
      breakdown: groupRoles.map((role) => ({
        uuid: role.uuid,
        type: DesignEntity.ROLE,
        data: WorkHealth.getEntityResults(role, 'percent', [
          'energising',
          'workload',
        ]),
      })),
    });

    return insights;
  }, [snapshot, uuid]);
}
