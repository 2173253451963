/**
 * @param {Array} values
 * @return {number}
 */
export default function mean(values, round = 1) {
  if (!values?.length) {
    return 0;
  }

  const total = values.reduce((prev, next) => {
    if (isNaN(prev)) {
      return 0;
    }
    if (isNaN(next)) {
      return prev;
    }

    return prev + next;
  });

  return Math.abs((total / values.length) * round) / round;
}
