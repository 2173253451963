import {
  cloneElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import useDesignSetup from '@/components/DesignContainer/hooks/useDesignSetup';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import NotFound from '@/components/NotFound';

export const ActiveDesignContext = createContext();
export const useActiveDesignContext = () => useContext(ActiveDesignContext);

const ActiveDesignProvider = ({
  children,
  designId,
  level,
  revisionId,
  isRestricted,
}) => {
  if (isRestricted) {
    return (
      <NotFound
        title="Whoops, this page does not exist."
        message={<span>Sorry, we can't find what you were looking for.</span>}
      />
    );
  }

  useDesignSetup(designId, level, revisionId);
  const design = useDesignStore((state) => state.design);
  const isAuthError = useDesignStore((state) => state.isAuthError);
  const errorState = useDesignStore((state) => state.isError);
  const loadingState = useDesignStore((state) => state.isLoading);
  const readyState = useDesignStore((state) => state.isReady);
  const isCurrentDesign = design?.uuid === designId;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(null);

  // Set the error state independently of the loading state.
  useEffect(() => {
    if (errorState || isAuthError) {
      setIsError(true);
      return;
    }
    setIsError(null);
  }, [errorState, isAuthError]);

  // Determines if we're still loading a design.
  useEffect(() => {
    if ((!loadingState && readyState && isCurrentDesign) || isError) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
  }, [loadingState, readyState, isCurrentDesign, isError]);

  const context = useMemo(() => {
    return {
      isLoading,
      isError,
    };
  }, [isLoading, isError]);

  return (
    <ActiveDesignContext.Provider value={context}>
      {cloneElement(children, { isLoading, isError })}
    </ActiveDesignContext.Provider>
  );
};

export default ActiveDesignProvider;
