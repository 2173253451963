import * as Mutations from '../mutations';

/**
 * @param {any[]} query
 */
export default function useMutations() {
  return {
    create: Mutations.useCreate(),
  };
}
