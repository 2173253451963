import { brand, core } from '@/lib/theme/tokens/palettes';

const ListColors = Object.freeze({
  primary: {
    normal: {
      backgroundColor: brand._navy.main,
      color: core.white,
      borderColor: core.white,
    },
    active: {
      backgroundColor: core.white,
      color: brand._navy.main,
      borderColor: core.white,
    },
    completed: {
      backgroundColor: brand.yellow.main,
      color: brand._navy.main,
      borderColor: brand.yellow.main,
    },
  },
  secondary: {
    normal: {
      backgroundColor: core.white,
      color: brand.blue.main,
      borderColor: brand.blue.main,
    },
    active: {
      backgroundColor: brand.blue.main,
      color: core.white,
      borderColor: brand.blue.main,
    },
    completed: {
      backgroundColor: brand.blue.main,
      color: core.white,
      borderColor: brand.blue.main,
    },
  },
});

export default ListColors;
