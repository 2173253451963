import format from './format';

/**
 * @param {number} value
 * @param {object} options // Intl.NumberFormat options
 * @return {string}
 */
export default function currency(value, options = {}) {
  if (isNaN(value) || value === null || value === '') {
    return null;
  }

  const formattedValue = format(value, {
    style: 'decimal',
    ...options,
  });

  return `$${formattedValue}`;
}
