import { Button } from '@/atoms/buttons';
import { EventName, EventProperty } from '@/shared/enums';
import Stack from '@mui/material/Stack';

const WizardNavigation = ({
  activeStep,
  isLoadingNext,
  nextLabel = 'Next',
  onPrevious,
  onNext,
  previousLabel = 'Previous',
  stepCount,
  stepIndex,
  title,
}) => {
  const handleNext = async (event) => {
    await onNext?.(event);
  };
  const isFinalStep = stepIndex === stepCount - 1;
  const eventName = isFinalStep
    ? EventName.SURVEY_COMPLETED
    : EventName.SURVEY_NEXT;
  const stepName = isFinalStep ? 'Review your role' : title;

  return (
    <Stack
      justifyContent="flex-start"
      direction="row"
      spacing={2}
      width="100%"
      sx={{
        py: 2,
      }}
    >
      {onPrevious && (
        <Button
          label={previousLabel}
          onClick={onPrevious}
          color="secondary"
          size="large"
          variant="naked"
        />
      )}
      {onNext && (
        <Button
          dataTestid="onboard-next"
          label={nextLabel}
          loading={isLoadingNext}
          onClick={handleNext}
          color="secondary"
          size="large"
          track={{
            name: eventName,
            properties: {
              [EventProperty.ENTRY]: 'Survey',
              [EventProperty.STEP_COUNT]: stepCount - 1,
              [EventProperty.STEP]: stepIndex,
              [EventProperty.STEP_NAME]: stepName,
            },
          }}
        />
      )}
    </Stack>
  );
};

export default WizardNavigation;
