import { Collections, Arr } from '@pkg/utils';
import reduceProperties from '../reduceProperties';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.name]
 * @param {String} [input.objective]
 * @param {Array} [input.tags]
 * @returns {Object}
 */
export default function update(
  snapshot,
  { uuid, name, objective, tags, properties }
) {
  const entities = snapshot.entities;
  const now = Date.now();

  let isDifferent = false;
  const group = Collections.findById(entities.groups, uuid);
  const groupInput = {
    uuid,
    updated_at: now,
  };

  if (name !== undefined && group.name !== name) {
    groupInput.name = name;
    isDifferent = true;
  }

  if (objective !== undefined && group.objective !== objective) {
    groupInput.objective = objective;
    isDifferent = true;
  }

  if (Array.isArray(tags) && Arr.diff(group.tags, tags).length > 0) {
    groupInput.tags = tags;
    isDifferent = true;
  }

  /** @todo convert to its own reducer and explicitly create/update/remove */
  if (Array.isArray(properties)) {
    const propertiesInput = reduceProperties(group, properties);

    if (propertiesInput) {
      groupInput.properties = propertiesInput;
      isDifferent = true;
    }
  }

  if (!isDifferent) {
    return null;
  }

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [groupInput],
      },
    },
  };

  return mutation;
}
