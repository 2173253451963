import { useCallback, useEffect, useState } from 'react';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';

const GET_ACTIVITIES = /* GraphQL */ `
  query GetActivities {
    me {
      organisation {
        activities {
          uuid
          description
        }
      }
    }
  }
`;

export default function useRefresh() {
  const client = useClient();
  const database = useDatabase();
  const [isRefreshing, setRefreshing] = useState(false);

  const refresh = useCallback(() => {
    if (!isRefreshing) {
      setRefreshing(true);
    }
  }, []);

  const handleRefresh = async () => {
    const { data } = await client.graphql({ query: GET_ACTIVITIES });

    if (!data?.me) {
      setRefreshing(false);
      return;
    }

    const newActivities = data.me.organisation.activities ?? [];
    const newActivitiesSet = new Set(newActivities.map(({ uuid }) => uuid));
    const activities = await database.activities.toArray();

    const removedActivities = activities
      .filter(({ uuid }) => !newActivitiesSet.has(uuid))
      .map(({ uuid }) => uuid);

    const promises = [
      database.hash.put({ key: 'activities', updated_at: Date.now() }),
    ];

    if (removedActivities.length) {
      promises.push(database.activities.bulkDelete(removedActivities));
    }

    if (newActivities.length) {
      promises.push(database.activities.bulkPut(newActivities));
    }

    await Promise.all(promises);
    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return {
    refresh,
    isRefreshing,
  };
}
