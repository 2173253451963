import { Collections } from '@pkg/utils';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String[]} input.ids
 * @param {Boolean} input.active
 * @returns {Object}
 */
export default function toggle(snapshot, { ids, active }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        update: [],
      },
    },
  };

  const activities = structuredClone(entities.activities);
  const keyed = Collections.keyById(activities);

  const touchedGroups = new Set();
  const touchedRoles = new Set();

  ids.forEach((id) => {
    if (!Object.hasOwn(keyed, id)) {
      return;
    }

    const activity = keyed[id];
    if (activity.owner_type === ActivityOwner.ROLE) {
      touchedRoles.add(activity.owner_uuid);
    } else {
      touchedGroups.add(activity.owner_uuid);
    }

    mutation.entities.activities.update.push({
      uuid: activity.uuid,
      disabled_at: active ? null : now,
      updated_at: now,
    });
  });

  touchedRoles.forEach((id) => {
    mutation.entities.roles.update.push({ uuid: id, updated_at: now });
  });

  touchedGroups.forEach((id) => {
    mutation.entities.groups.update.push({ uuid: id, updated_at: now });
  });

  return mutation;
}
