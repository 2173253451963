import { EntityType } from '@/shared/enums';

/**
 * Returns the group a role belongs to.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function getGroupFromRole({
  entityItem,
  entityType,
  role,
  snapshotEntityMap,
}) {
  if (entityType === EntityType.ROLE) {
    return snapshotEntityMap.get(EntityType.GROUP)?.get(role.group_uuid);
  }
  if (entityType === EntityType.ACTIVITY) {
    return role && !role.isUnallocated
      ? snapshotEntityMap.get(EntityType.GROUP).get(role.group_uuid)
      : snapshotEntityMap.get(EntityType.GROUP).get(entityItem.owner_uuid);
  }
}
