/**
 * Returns a label for the requested entity.
 *
 * @param {String} id
 * @param {String} type
 * @param {Map} entityMap
 *
 * @return {String}
 */
export default function getLabel(id, type, entityMap) {
  const entity = entityMap.get(type)?.get(id);

  if (!entity) {
    return 'No label';
  }

  return entity.name ?? entity.title;
}
