import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { percent } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';
import { QuadrantChart } from '@/components/Charts';
import formatQuadrantActivities from './formatQuadrantActivities';

/**
 * @param {Object} quadrantMap
 * @param {Number} totalHours
 *
 * @return {Object}
 */
const mapDataset = (quadrantMap, totalHours) => {
  const dataset = new Map();
  quadrantMap.forEach((quadrant, key) => {
    if (key === DesignEntity.ACTIVITY) {
      return;
    }

    const data = formatQuadrantActivities(quadrant, totalHours);

    dataset.set(key, {
      color: quadrant.color,
      title: quadrant.title,
      percentage: percent(quadrant.__totalHours, totalHours),
      data,
    });
  });

  return dataset;
};

const QuadrantsChart = ({ axisLabels, id, quadrantMap, selectQuadrant }) => {
  const [totalActivityHours, setTotalActivityHours] = useState();
  const [quadrants, setQuadrants] = useState();

  useEffect(() => {
    if (!quadrantMap) {
      return;
    }

    const totalHours = quadrantMap.get(DesignEntity.ACTIVITY)?.__totalHours;
    setTotalActivityHours(totalHours);
    setQuadrants(mapDataset(quadrantMap, totalHours));
  }, [quadrantMap]);

  return (
    Boolean(quadrantMap) &&
    Boolean(totalActivityHours) && (
      <Grid container spacing={1}>
        {Boolean(quadrants) && (
          <Grid item xs={12}>
            <QuadrantChart
              axisLabels={axisLabels}
              id={id}
              quadrants={quadrants}
              selectQuadrant={selectQuadrant}
              sx={{ width: '100%', height: 520 }}
            />
          </Grid>
        )}
      </Grid>
    )
  );
};

export default QuadrantsChart;
