import { Position } from '@/lib/enums';

export default function renderQuadrantLabel(svg, context) {
  const { canvasXOffset, classes, height, label, padding, position, width } =
    context;

  const x0 =
    position === Position.TOP_RIGHT || position === Position.BOTTOM_RIGHT
      ? context.x0
      : 0;

  const y0 = context.y0;

  const xTextPosition =
    position === Position.TOP_LEFT || position === Position.BOTTOM_LEFT
      ? x0 + padding / 2
      : x0 + width + canvasXOffset - padding / 4;
  const xTextAnchor =
    position === Position.TOP_LEFT || position === Position.BOTTOM_LEFT
      ? 'start'
      : 'end';

  const yTextPosition =
    position === Position.TOP_LEFT || position === Position.TOP_RIGHT
      ? y0 + padding / 2
      : y0 + height - padding;

  const quadrantLabel = svg
    .select(`#packed-circle-group--${position}`)
    .append('g');

  quadrantLabel
    .append('text')
    .classed('QuadrantLabel--text', true)
    .classed(classes.label.join(' '), true)
    .attr('y', yTextPosition)
    .attr('x', xTextPosition)
    .style('font-size', '1rem')
    .style('font-weight', 500)
    .style('text-anchor', xTextAnchor)
    .text(label.value);

  quadrantLabel
    .append('text')
    .classed('QuadrantLabel--text', true)
    .classed(classes.label.join(' '), true)
    .attr('y', yTextPosition + 18)
    .attr('x', xTextPosition)
    .style('text-anchor', xTextAnchor)
    .text(label.title);

  if (label.subtitle) {
    quadrantLabel
      .append('text')
      .classed('QuadrantLabel--text', true)
      .classed(classes.label.join(' '), true)
      .attr('y', yTextPosition + 36)
      .attr('x', xTextPosition)
      .style('text-anchor', xTextAnchor)
      .text(label.subtitle);
  }
}
