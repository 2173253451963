import { DesignEntity } from '@/lib/enums';
import Select from './Select';

export const OPTIONS = [
  { label: 'Teams', value: DesignEntity.GROUP },
  { label: 'Roles', value: DesignEntity.ROLE },
  { label: 'Activities', value: DesignEntity.ACTIVITY },
  { label: 'People', value: DesignEntity.PERSON },
];

const EntitiesInput = (props) => (
  <Select
    label="Filter types"
    multiple
    options={OPTIONS}
    placeholder="Choose types..."
    {...props}
  />
);

export default EntitiesInput;
