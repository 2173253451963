import { Subtitle } from '@/atoms/typography';
import { StackChartBar } from '@/molecules/chartElements';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Stack from '@mui/material/Stack';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';
import RelatedActivityEntities from '@/components/DashboardContainer/organisms/RelatedActivityEntities';
import setGroupedActivitiesColor from '@/components/DashboardContainer/utils/setGroupedActivitiesColor';

const QuadrantActivityListChart = ({
  activities,
  showAllActivities = true,
  initialActivityCount,
  quadrantMap,
  activeQuadrant,
  unallocatedActivities,
}) => {
  const { scenario } = useScenarioContext();
  const entityType = scenario?.details?.__type;
  const interactive = scenario?.entity?.__type === DesignEntity.GROUP;
  const showUnallocated =
    activeQuadrant === DesignEntity.ACTIVITY && showAllActivities;

  const groupedActivitiesColor = setGroupedActivitiesColor(
    scenario?.entity?.__type
  );

  return (
    Boolean(activities?.length > 0) && (
      <Stack spacing={2}>
        <Box>
          {activities.map(
            (activity, index) =>
              (showAllActivities || index < initialActivityCount) && (
                <Box key={activity.uuid} sx={{ mt: index === 0 ? 0 : 1.5 }}>
                  <RelatedActivityEntities
                    entity={activity}
                    interactive={interactive}
                  >
                    <StackChartBar
                      colors={groupedActivitiesColor}
                      interactive={interactive}
                      title={activity.__description}
                      numerator={activity.__aggregateHours}
                      showNumerator
                      numeratorLabel="Hours"
                      precision={
                        entityType === DesignEntity.ORGANISATION ? 1 : 0
                      }
                      denominator={
                        quadrantMap.get(DesignEntity.ACTIVITY)?.__totalHours
                      }
                    />
                  </RelatedActivityEntities>
                </Box>
              )
          )}
        </Box>
        {Boolean(unallocatedActivities?.length > 0) && showUnallocated && (
          <Box>
            <Subtitle>Unallocated activities</Subtitle>
            {unallocatedActivities.map((activity, index) => (
              <Box key={activity.uuid} sx={{ mt: index === 0 ? 0 : 1.5 }}>
                <StackChartBar
                  title={activity.description ?? activity.__description}
                  numerator={activity.__aggregateHours}
                  denominator={
                    quadrantMap.get(DesignEntity.ACTIVITY)?.__totalHours
                  }
                />
              </Box>
            ))}
          </Box>
        )}
      </Stack>
    )
  );
};

export default QuadrantActivityListChart;
