import PropTypes from 'prop-types';
import * as Auth from '@pkg/auth';
import { IntegrationType } from '@/lib/enums';
import { Select } from '@/components/Inputs';

const EntitySourceInput = ({ value = [], ...props }) => {
  const { organisation } = Auth.useStore();
  const { integrations } = organisation;

  const options = integrations
    .filter(({ type }) => type === IntegrationType.TICKETING)
    .map(({ name, uuid }) => ({
      color: 'grey',
      label: name,
      value: uuid,
    }));

  options.splice(0, 0, {
    color: 'grey',
    label: 'None',
    value: null,
  });

  return <Select {...props} fullWidth multiple options={options} />;
};

EntitySourceInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.array,
};

export default EntitySourceInput;
