import { produce } from 'immer';
import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @returns {Array}
 */
export default function update(snapshot, { uuid, ...input }) {
  const now = Date.now();
  const entities = snapshot.entities;
  const activity = Collections.findById(entities.activities, uuid);
  const updatedActivity = {
    ...activity,
    ...input,
    updated_at: now,
  };

  const role = Collections.findById(entities.roles, activity.owner_uuid);
  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    role.uuid
  );

  const mutated = produce(snapshot, (scenario) => {
    scenario.entities = {
      activities: Collections.updateById(roleActivities, updatedActivity),
      roles: [{ ...role, updated_at: now }],
    };
  });

  return {
    uuid: role.__template_uuid,
    snapshot: mutated,
  };
}
