import * as d3 from 'd3';

const MARGIN = 0.2;

const BinaryDonutChart = ({ colors, height, id, data, width }) => {
  const minSize = Math.min(width, height);
  const radius = minSize / 2;

  const donut = d3
    .pie()
    .sort(null)
    .value((d) => d);

  const donutData = donut(data);

  const arc = d3
    .arc()
    .innerRadius(radius * 0.6)
    .outerRadius(radius * 0.95)
    .cornerRadius(2)
    .padAngle(0.05);

  const first = arc(donutData[0]);

  return (
    <svg
      id={id}
      width={width}
      height={height}
      viewBox={`${-width / 2} ${-height / 2} ${width} ${height}`}
    >
      <g id={`${id}-donut-chart`}>
        {donutData.map((d, index) => (
          <path key={`item-${index}`} d={arc(d)} fill={colors[index]} />
        ))}
      </g>
    </svg>
  );
};

export default BinaryDonutChart;
