import { useCallback } from 'react';
import { Obj } from '@pkg/utils';
import useStore from './useStore';

const DEFAULT_SETTINGS = {
  diagram: {
    properties: [],
    roleMetricsVisible: false,
  },
};

const settingsKey = 'displaySettings';

export default function useDisplaySettings() {
  const { me } = useStore();

  const loadSettings = () => {
    let settings = localStorage.getItem(settingsKey);

    settings = settings
      ? JSON.parse(settings)
      : { [me.uuid]: DEFAULT_SETTINGS };

    if (!settings[me.uuid]) {
      return {
        ...settings,
        [me.uuid]: DEFAULT_SETTINGS,
      };
    }

    return settings;
  };

  const getSettings = useCallback(
    (path) => {
      const settings = loadSettings();

      return Obj.get(settings, `${me.uuid}.${path}`);
    },
    [me.uuid]
  );

  const storeSettings = useCallback(
    (settings) => {
      const updated = {
        [me.uuid]: settings,
      };

      localStorage.setItem(settingsKey, JSON.stringify(updated));
    },
    [me.uuid]
  );

  const applyProperties = useCallback(
    (properties) => {
      const settings = structuredClone(loadSettings());

      settings[me.uuid].diagram.properties = properties;
      return settings[me.uuid];
    },
    [storeSettings]
  );

  const applyRoleMetrics = useCallback(
    (visible) => {
      const settings = structuredClone(loadSettings());

      settings[me.uuid].diagram.roleMetricsVisible = visible;
      return settings[me.uuid];
    },
    [storeSettings]
  );

  const apply = {
    properties: applyProperties,
    roleMetrics: applyRoleMetrics,
  };

  const settings = loadSettings();

  return {
    settings: settings[me.uuid],
    getSettings,
    storeSettings,
    apply,
  };
}
