import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useMutation } from '@pkg/graphql';

export const CREATE_MANY_FLOWS = /* GraphQL */ `
  mutation CreateManyFlows($input: CreateManyFlowsInput!) {
    createManyFlows(input: $input) {
      uuid
    }
  }
`;

export default function useCreateMany() {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: CREATE_MANY_FLOWS,
  });

  /**
   * @param {Object} input
   * @param {String} input.campaignId
   * @param {String} input.designId
   * @param {String} input.flowTemplateId
   * @param {String[]} input.roleIds
   * @returns {Promise}
   */
  function createMany({ campaignId, designId, flowTemplateId, roleIds }) {
    enqueueSnackbar(`Inviting ${roleIds.length} roles`);

    return mutateAsync({
      campaign_id: campaignId ?? null,
      design_uuid: designId ?? null,
      flow_template_uuid: flowTemplateId ?? null,
      role_uuids: roleIds,
    });
  }

  return useCallback(createMany, []);
}
