import { useMemo } from 'react';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import { filterUserEntities } from '@/shared/utils';
import * as Auth from '@pkg/auth';
import { Sort } from '@pkg/utils';
import { DesignType } from '@/lib/enums';

const useUserRelatedEntities = (
  { liveDesignsOnly } = { liveDesignsOnly: false }
) => {
  const { me } = Auth.useStore();
  const designs = useDesignsContext();

  return useMemo(() => {
    if (designs?.isLoading || !designs?.designs?.size) {
      return {
        designs: null,
        groups: null,
        roles: null,
      };
    }

    if (Boolean(designs.designs.size > 0) && me?.uuid) {
      // Filter out scenarios if we only want live designs.
      const allowedDesigns = liveDesignsOnly
        ? new Map(
            [...designs.designs.entries()].filter(([id, { design }]) => {
              return design.type === DesignType.LIVE;
            })
          )
        : designs.designs;

      const entities = filterUserEntities(
        allowedDesigns,
        designs?.supporterMap,
        me.uuid
      );

      const isScenarioSort = Sort.bool('__isScenario', true);

      return {
        designs: Array.from(entities?.designs.values()).sort(isScenarioSort),
        groups: Array.from(entities?.groups.values()).sort(isScenarioSort),
        roles: Array.from(entities?.roles.values()).sort(isScenarioSort),
      };
    }
  }, [designs?.designs, designs?.supporterMap, me?.uuid, designs?.isLoading]);
};

export default useUserRelatedEntities;
