import { DesignEntity, Relationship } from '@/lib/enums';
import { getManagedEntitiesForRole } from '../get/managed';

export default function deriveNew(myRoles, supportedGroups, entity, snapshot) {
  const plural = DesignEntity.toPlural(entity.__type);

  const updatedSnapshot = structuredClone(snapshot);
  updatedSnapshot.entities[plural] = [
    ...updatedSnapshot.entities[plural],
    entity,
  ];

  const managed = {
    [DesignEntity.GROUP]: new Set(),
    [DesignEntity.ROLE]: new Set(),
    [DesignEntity.PERSON]: new Set(),
    [DesignEntity.ACTIVITY]: new Set(),
  };

  for (const role of myRoles) {
    if (role.is_manager) {
      getManagedEntitiesForRole(managed, updatedSnapshot, role.uuid);
    }
  }

  // managed entity
  if (managed[entity.__type].has(entity.uuid)) {
    return Relationship.MANAGER;
  }

  // direct role assignee relationship
  if (
    entity.__type === DesignEntity.ROLE &&
    myRoles.some((r) => r.uuid === entity.uuid)
  ) {
    return Relationship.ASSIGNEE;
  }

  // assignee activity
  if (
    entity.__type === DesignEntity.ACTIVITY &&
    myRoles.some((r) => r.uuid === entity.owner_uuid)
  ) {
    return Relationship.ASSIGNEE;
  }

  // supported entities
  if (supportedGroups.has(entity.uuid) === Relationship.SUPPORTER) {
    return Relationship.SUPPORTER;
  }

  if (
    entity.__type === DesignEntity.ROLE &&
    supportedGroups.has(entity.group_uuid)
  ) {
    return Relationship.SUPPORTER;
  }

  const supportedRoles = snapshot.entities.roles.filter((r) =>
    supportedGroups.has(r.group_uuid)
  );

  if (entity.__type === DesignEntity.ACTIVITY) {
    if (
      entity.owner_type === DesignEntity.GROUP &&
      supportedGroups.has(entity.owner_uuid)
    ) {
      return Relationship.SUPPORTER;
    } else if (
      entity.owner_type === DesignEntity.ROLE &&
      supportedRoles.some((r) => r.uuid === entity.owner_uuid)
    ) {
      return Relationship.SUPPORTER;
    }
  }

  return Relationship.NONE;
}
