import { useCallback } from 'react';
import { Obj } from '@pkg/utils';

const getPath = (policy) => {
  const { level, entityType, relationship, property, action } = policy;
  const path = [level, entityType, relationship, action];

  if (property) {
    path.splice(3, 0, property);
  }

  return path.join('.');
};

/**
 * @param {Object} matrix
 * @returns {Function}
 */
export default function useLookup(matrix) {
  /**
   * @param {Object[]} policies
   * @param {String} policies[].level
   * @param {String} policies[].entityType
   * @param {String} policies[].relationship
   * @param {String} policies[].property
   * @param {String} policies[].action
   * @returns {Boolean}
   */
  const lookup = (policies) => {
    for (const policy of policies) {
      if (!matrix) {
        return false;
      }

      const path = getPath(policy);
      const allow = Obj.get(matrix, path);

      if (!allow || allow === '-') {
        console.debug(`Policies.lookup.invalid: ${path}`);
        return false;
      }

      if (allow !== 'true') {
        return false;
      }
    }

    return true;
  };

  return useCallback(lookup, [matrix]);
}
