import { useMemo } from 'react';
import { Sort } from '@pkg/utils';
import config from '@/lib/config';

export default function useMerged(templates) {
  return useMemo(() => {
    if (!templates) {
      return null;
    }

    console.time('Templates.useMerged');
    const merged = structuredClone(config.DEFAULT_SNAPSHOT);
    const templateIds = Object.keys(templates).sort(Sort.uuid());

    templateIds.forEach((uuid) => {
      const template = templates[uuid];
      const entities = structuredClone(template.snapshot.entities);

      merged.entities.roles.push({
        ...entities.roles[0],
        __enabled: template.enabled ?? true,
        __template_uuid: uuid,
      });

      entities.activities.forEach((activity) => {
        merged.entities.activities.push(activity);
      });
    });

    return merged;
  }, [templates]);
}
