import { MetricProperty } from '@/atoms/enums';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { stackColor } from '@/organisms/charts';
import { EntityType } from '@/shared/enums';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';
import CenteredSegment from './CenteredSegment';

const SEGMENT_MARGIN = 8;

/**
 * Calculates the size of the stack segments and returns the array of segments.
 *
 * @param {Object}
 *
 * @return {Array}
 */
const buildSegments = ({
  focused,
  metric,
  metricTotals,
  row,
  showStack,
  xScale,
  width,
}) => {
  const baseMetric = metric === EntityMetric.SPAN ? 'managedSpan' : metric;
  // Calculate the total and average margin that should exist between bars.
  const stackCount = row.stackData.length;
  const totalMargin =
    stackCount > 1 && showStack ? (stackCount - 1) * SEGMENT_MARGIN : 0;
  const averageMargin = stackCount > 1 ? totalMargin / stackCount : 0;
  // Calculate the total bar width and subtract the total margin because it will
  // be used to separate the bars.
  const rowWidth = xScale(row?.data?.[baseMetric]) - totalMargin;
  // The beginning x position needs to be centered based on the total width of
  // the chart, the width of the row and removing the total margin we accounted
  // for.
  let x = (width - rowWidth - totalMargin) / 2;

  const total = metricTotals[baseMetric];
  const focusedRow = !focused || focused === row.id;

  if (!showStack) {
    const rowType =
      metric === EntityMetric.SPAN || metric === EntityMetric.AVERAGE_SPAN
        ? EntityType.MANAGER
        : row.type;

    return [
      {
        ...row,
        width: rowWidth,
        color: stackColor(
          percent(row.data[baseMetric], total),
          focusedRow ? rowType : 'INACTIVE'
        ),
        xPosition: x,
      },
    ];
  }

  const segmentData = row.stackData.map((item, index) => {
    const focusedSegment = focusedRow || focused === item.id;
    const colorType = focusedSegment ? item.type : 'INACTIVE';
    const value = item?.data?.[baseMetric] ?? 0;
    const percentage = percent(value, total);
    const color = stackColor(percentage, colorType);
    // The width of each bar should be the bar width minus the average margin
    // amount. This allows us to create the gaps between the bars.
    const barWidth = xScale(item.data[baseMetric]) - averageMargin;
    // We set the current bar x position and then update the x variable for the
    // next bar.
    const xPosition = x;
    x = xPosition + barWidth + averageMargin;

    return {
      ...item,
      width: barWidth,
      color,
      xPosition,
    };
  });

  return segmentData;
};

const CenteredStackBar = ({
  barHeight,
  chartWidth,
  focused,
  labelWidth,
  metric,
  metricTotals,
  onClick,
  row,
  showStack,
  width,
  xScale,
  yPosition,
}) => {
  const segments = buildSegments({
    chartWidth,
    focused,
    labelWidth,
    metric,
    metricTotals,
    row,
    showStack,
    xScale,
    width,
  });

  const baseMetric = metric === EntityMetric.SPAN ? 'managedSpan' : metric;
  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  const labelPosition = firstSegment?.xPosition - labelWidth;
  const metricPosition = lastSegment?.xPosition + lastSegment?.width;
  const metricLabel =
    MetricProperty[metric].label.short || MetricProperty[metric].label.singular;

  const value =
    metric === EntityMetric.PERCENTAGE && row.data[baseMetric] > 0
      ? row.data[metric] / 100
      : row.data[metric];

  const formatOptions = MetricProperty[metric]?.formatOptions;
  const displayValue = formatOptions
    ? format(value, {
        ...formatOptions,
        notation: 'compact',
      })
    : row.data[metric];

  return (
    <g className="centered-bar">
      <foreignObject
        x={labelPosition}
        y={yPosition}
        width={labelWidth}
        height={barHeight}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          height={barHeight}
        >
          <Paragraph
            size={Size.MEDIUM}
            overrideStyles={{
              pr: 2,
              mb: 0,
              pt: 0.5,
            }}
          >
            {row.label}
          </Paragraph>
        </Stack>
      </foreignObject>
      <foreignObject
        x={metricPosition}
        y={yPosition}
        width={labelWidth}
        height={barHeight}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          height={barHeight}
          sx={{ pl: 2 }}
        >
          <div>
            <Paragraph
              size={Size.MEDIUM}
              overrideStyles={{
                textAlign: 'center',
                lineHeight: '1rem',
                fontWeight: 600,
                mb: 0,
                pt: 0.5,
              }}
            >
              {displayValue}
            </Paragraph>
            {metric !== EntityMetric.PERCENTAGE && (
              <Paragraph
                size={Size.X_SMALL}
                overrideStyles={{
                  mb: 0,
                  textAlign: 'center',
                  textTransform: 'uppercase',
                }}
              >
                {metricLabel}
              </Paragraph>
            )}
          </div>
        </Stack>
      </foreignObject>
      <g className="segments-row">
        {segments.map((segment) => (
          <CenteredSegment
            color={segment.color}
            height={barHeight}
            key={segment.id}
            metric={metric}
            metricTotals={metricTotals}
            onClick={onClick}
            row={segment}
            width={segment.width}
            xPosition={segment.xPosition}
            yPosition={yPosition}
          />
        ))}
      </g>
    </g>
  );
};

export default CenteredStackBar;
