import reduce from './reducers/roles/copy';
import useMutate from './useMutate';

/**
 * @returns {Object}
 */
export default function useCopyRole() {
  const mutate = useMutate();

  /**
   * @param {Object} role
   * @param {Object} options
   * @param {Boolean} options.copyTags
   * @param {Object} options.target
   * @param {String} options.title
   * @returns {Promise}
   */
  function copyRole(design_uuid, role, activities, options) {
    const mutation = reduce(role, activities, options);
    return mutate(design_uuid, mutation);
  }

  return copyRole;
}
