import { useEffect, useState } from 'react';
import { Size } from '@/atoms/enums';
import { CheckboxOptions } from '@/atoms/inputs';
import { Paragraph } from '@/atoms/typography';
import { FilterSearch } from '@/molecules/filterElements';
import { useRoleContext } from '@/shared/providers';
import Box from '@mui/material/Box';

/** Builds a list of tag options respecting the allowed tags list and sorts
 * the results.
 *
 * @param {Map} tagOptions
 * @param {Set} selectedIds
 * @param {Set} allowedTags
 *
 * @return {Array}
 */
const sortRoleOptions = (list, selectedValues) => {
  if (!list.length) {
    return;
  }

  const selected = new Set(
    selectedValues?.length ? selectedValues.map(({ id }) => id) : []
  );

  const options = list.map(({ hashId, ids, title }) => ({
    id: hashId,
    title: `${title} (${ids.size})`,
    ids,
  }));

  if (selectedValues?.length) {
    options.sort((a, b) => {
      const aSelected = selected.has(a.id);
      const bSelected = selected.has(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });
  }

  return options;
};

const RoleSelect = ({ value, onChange }) => {
  const { roleList, roleMap, search } = useRoleContext();
  const [options, setOptions] = useState();

  const handleChange = (event, ids) => {
    const values = ids.map((id) => {
      const role = roleMap?.get(id);

      return {
        id,
        label: role?.title,
        ids: role?.ids ?? [],
      };
    });

    onChange(event, values);
  };

  const handleSearch = (event, term) => {
    if (!term) {
      setOptions(sortRoleOptions(roleList, value));
      return;
    }

    const results = search({ searchTerm: term });

    if (!results?.length) {
      setOptions([]);
      return;
    }

    setOptions(sortRoleOptions(results, value));
  };

  // This effect sets up the default list of options sorted with checked tags
  // at the start of the list.
  useEffect(() => {
    if (!roleList?.length || options) {
      return;
    }

    setOptions(sortRoleOptions(roleList, value));
  }, [JSON.stringify(roleList, value)]);

  return options ? (
    <>
      <FilterSearch onSearch={handleSearch} placeholder="Search for roles" />
      <Box sx={{ maxHeight: 340, overflowY: 'auto' }}>
        {options?.length > 0 ? (
          <CheckboxOptions
            onChange={handleChange}
            options={options}
            optionLabel="title"
            initialSelectedIds={value?.map(({ id }) => id)}
          />
        ) : (
          <Box p={2}>
            <Paragraph
              size={Size.SMALL}
              overrideStyles={{ textAlign: 'center' }}
            >
              No roles were found matching that search term
            </Paragraph>
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Box p={2}>
      <Paragraph size={Size.SMALL} overrideStyles={{ textAlign: 'center' }}>
        No roles have been selected.
      </Paragraph>
    </Box>
  );
};

export default RoleSelect;
