import { Size } from '@/atoms/enums';

const NumberBadgeSize = Object.freeze({
  [Size.X_SMALL]: {
    borderRadius: '0.7rem',
    fontSize: '0.7rem',
    fontWeight: 500,
    height: '1.15rem',
    lineHeight: '1.15rem',
    width: '1.15rem',
  },
  [Size.SMALL]: {
    borderRadius: '0.75rem',
    fontSize: '0.75rem',
    fontWeight: 500,
    height: '1.2rem',
    width: '1.2rem',
    lineHeight: '1.2rem',
  },
  [Size.MEDIUM]: {
    borderRadius: '0.825rem',
    fontSize: '0.825rem',
    fontWeight: 500,
    height: '1.5rem',
    lineHeight: '1.5rem',
    width: '1.5rem',
  },
  [Size.LARGE]: {
    borderRadius: '0.925rem',
    fontSize: '0.925rem',
    fontWeight: 500,
    height: '1.75rem',
    lineHeight: '1.75rem',
    width: '1.75rem',
  },
});

export default NumberBadgeSize;
