import { Size } from '@/atoms/enums';
import { MetricProperty } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { RowColors } from '@/organisms/charts';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const MIN_WIDTH = 4;
const BAR_ORIGIN_OFFSET = 50;
const BAR_PADDING_Y = 16;
const BAR_HEIGHT_COMPARISON_DIFFERENCE = 8;
const COMPARISON_BAR_HEIGHT = 8;
const COMPARISON_BAR_Y_OFFSET = 26;

const getMetricValue = ({ isPercentageMetric, metric, row, scaleMetric }) => {
  if (isPercentageMetric) {
    const metricData = row.data[metric];
    return metricData > 0 ? metricData / 100 : 0;
  }

  return row.data[scaleMetric];
};

const HorizontalBar = ({
  barHeight,
  isPercentageMetric,
  row,
  metric,
  xChartPosition,
  xPosition,
  yPosition,
  xComparison,
}) => {
  const width =
    xPosition - BAR_ORIGIN_OFFSET > MIN_WIDTH
      ? xPosition - BAR_ORIGIN_OFFSET
      : MIN_WIDTH;
  const comparisonWidth =
    xComparison - BAR_ORIGIN_OFFSET > MIN_WIDTH
      ? xComparison - BAR_ORIGIN_OFFSET
      : MIN_WIDTH;
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE ? EntityMetric.HOURS : metric;
  const hasComparison = xComparison > 0;
  const comparisonColor = row.comparison?.color;

  const adjustedBarHeight = hasComparison
    ? barHeight - BAR_HEIGHT_COMPARISON_DIFFERENCE
    : barHeight;

  const value = getMetricValue({
    isPercentageMetric,
    metric,
    row,
    scaleMetric,
  });

  const formatOptions = MetricProperty[metric]?.formatOptions;
  const displayValue = formatOptions
    ? format(value, {
        ...formatOptions,
        notation: 'compact',
      })
    : row.data[metric];

  const colors = RowColors[row.type].bar;

  return (
    <g className="horizontal-bar">
      <rect
        width={width}
        height={adjustedBarHeight - BAR_PADDING_Y - 2}
        rx={4}
        ry={4}
        x={xChartPosition}
        y={yPosition + 10}
        fill={colors.borderColor}
      />
      <rect
        width={width}
        height={adjustedBarHeight - BAR_PADDING_Y}
        rx={3}
        ry={3}
        x={xChartPosition}
        y={yPosition + 6}
        fill={colors.backgroundColor}
      />
      {hasComparison && (
        <rect
          width={comparisonWidth}
          height={COMPARISON_BAR_HEIGHT}
          rx={4}
          ry={4}
          x={xChartPosition}
          y={yPosition + COMPARISON_BAR_Y_OFFSET}
          fill={comparisonColor?.fill}
        />
      )}
      <foreignObject
        x={xChartPosition + width + 8}
        y={yPosition}
        width={60}
        height={barHeight}
      >
        <Stack direction="row" alignItems="center" height={barHeight}>
          <Paragraph
            size={Size.SMALL}
            overrideStyles={{
              mb: 0,
              fontWeight: 600,
            }}
          >
            {displayValue}
          </Paragraph>
        </Stack>
      </foreignObject>
    </g>
  );
};

export default HorizontalBar;
