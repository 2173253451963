import PropTypes from 'prop-types';
import AvatarGroup from './AvatarGroup';

const PresenceAvatars = ({ users = 5, max = 5, ...props }) => {
  if (users?.length < 1) {
    return null;
  }

  return <AvatarGroup {...props} max={max} users={users} />;
};

PresenceAvatars.propTypes = {
  users: PropTypes.array,
  max: PropTypes.number,
};

export default PresenceAvatars;
