import { styled } from '@mui/material/styles';
import { useLogout } from '@pkg/auth';
import { brand, core, grey } from '@/lib/theme/tokens/palettes';
import Logo from './Logo';
import Typography from './Typography';

const Root = styled('div')({
  backgroundColor: brand._navy.tints[0],
  position: 'relative',
  height: '100vh',
  width: '100vw',
});

const Content = styled('div')({
  backgroundColor: core.white,
  borderRadius: 8,
  boxShadow: `1px 1px 4px 0 ${grey.colors[100]}`,
  left: '50%',
  padding: 24,
  position: 'absolute',
  textAlign: 'center',
  top: 96,
  transform: 'translateX(-50%)',
  width: 352,
});

const AccountDisabled = () => {
  const { logout } = useLogout();
  return (
    <Root>
      <Content>
        <Logo type="full" sx={{ margin: 'auto', marginBottom: 1 }} />
        <Typography variant="subtitle1" sx={{ mb: 1, color: brand.coral.main }}>
          Account disabled
        </Typography>
        <Typography variant="body2" sx={{ mb: 1 }}>
          This account is disabled. Please consult your administrator for more
          information.
        </Typography>
        <Typography variant="body2">
          <a href="#" onClick={logout} style={{ textDecoration: 'underline' }}>
            Logout
          </a>
        </Typography>
      </Content>
    </Root>
  );
};

export default AccountDisabled;
