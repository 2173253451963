import { useSnackbar } from 'notistack';
import { createContext, useMemo, useState } from 'react';
import { useAccessToken } from '@pkg/auth/AccessTokenProvider';
import { useEcho } from '@pkg/broadcasting';
import Sdk from '@pkg/sdk';
import Client from './Client';
import ErrorNotification from './ErrorNotification';
import prepareErrorMessage from './utils/prepareErrorMessage';

export const ClientContext = createContext();

const Provider = ({ children, host }) => {
  const { socketId } = useEcho();
  const { accessToken } = useAccessToken();
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  if (error) {
    const message = prepareErrorMessage(error);
    enqueueSnackbar(message.description, {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center',
      },
      persist: true,
      content: (key) => <ErrorNotification id={key} message={message} />,
    });
  }

  const onError = (error) => {
    setError(error);
  };

  const client = useMemo(() => {
    const headers = {
      'X-Socket-Id': socketId,
      ...Sdk.getHeaders(accessToken),
    };
    return new Client(host, headers, onError);
  }, [host, socketId, accessToken]);

  return (
    <ClientContext.Provider value={client}>{children}</ClientContext.Provider>
  );
};

export default Provider;
