import { MatrixPreset } from '@/shared/enums';
import { BeamibleTag } from '@/lib/enums';

const MatrixPresets = Object.freeze({
  [MatrixPreset.PRIORITISATION]: {
    ids: [BeamibleTag.STRATEGIC_IMPORTANCE, BeamibleTag.ENERGISING],
    titles: {
      NONE: 'Low importance / Low energy',
      [BeamibleTag.STRATEGIC_IMPORTANCE]: 'High importance / Low energy',
      [BeamibleTag.ENERGISING]: 'High energy / Low importance',
      ALL: 'High importance / High energy',
    },
  },
  [MatrixPreset.ALIGNMENT]: {
    ids: [BeamibleTag.CRITICAL_WORK, BeamibleTag.STRATEGIC_IMPORTANCE],
    titles: {
      NONE: 'Aligned',
      [BeamibleTag.CRITICAL_WORK]: 'Misaligned',
      [BeamibleTag.STRATEGIC_IMPORTANCE]: 'Misaligned',
      ALL: 'Aligned',
    },
    subtitles: {
      NONE: 'This work is not important',
      [BeamibleTag.CRITICAL_WORK]:
        'Leader believes this work is important but team does not',
      [BeamibleTag.STRATEGIC_IMPORTANCE]:
        'Team believes this work is important but leader does not',
      ALL: 'This work is important',
    },
  },
});

export default MatrixPresets;
