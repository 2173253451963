import { cloneElement } from 'react';
import { Size } from '@/atoms/enums';
import { Colors } from '@/atoms/icons';
import Stack from '@mui/material/Stack';
import SquareBackgroundIconSize from './utils/SquareBackgroundIconSize';

const SquareBackgroundIcon = ({
  color = 'primary',
  colorProps,
  icon,
  size = Size.MEDIUM,
}) => {
  const colors = colorProps ? colorProps : Colors.circleBackgroundIcon[color];

  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      size={size}
      sx={{
        ...SquareBackgroundIconSize[size].background,
        ...colors,
        boxShadow: `inset 0 0 0 1px ${colors.borderColor}`,
      }}
    >
      {cloneElement(icon, {
        sx: {
          ...SquareBackgroundIconSize[size].icon,
          verticalAlign: 'middle',
        },
      })}
    </Stack>
  );
};

export default SquareBackgroundIcon;
