import * as Colors from '@/atoms/colors';
import Typography from '@/components/Typography';
import HeadingSize from './HeadingSize';

const Heading = ({ children, color, variant = 'h4', overrideStyles = {} }) => {
  const sizeProps = HeadingSize[variant];
  const headingColor = Colors.Base.font[color];

  return (
    <Typography
      variant={variant}
      sx={{
        fontWeight: 400,
        my: 0,
        color: headingColor,
        ...sizeProps,
        ...overrideStyles,
      }}
    >
      {children}
    </Typography>
  );
};

export default Heading;
