import { Size } from '@/atoms/enums';

const IconButtonSize = Object.freeze({
  [Size.SMALL]: {
    fontSize: '1rem',
  },
  [Size.MEDIUM]: {
    fontSize: '1.25rem',
  },
  [Size.LARGE]: {
    fontSize: '1.5rem',
  },
  [Size.X_LARGE]: {
    fontSize: '2rem',
  },
});

export default IconButtonSize;
