import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { OutlineCard } from '@/organisms/cards';
import { TagListOverview } from '@/organisms/tags';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const TagListCard = ({
  title,
  isCondensedView = false,
  combineTagCategories,
  showCustomTags,
  visibleTagSet,
  useComparison = false,
}) => {
  const [selectedTag, setSelectedTag] = useState(null);

  const handleSelectTag = (tag) => {
    setSelectedTag(tag);
  };

  return (
    <OutlineCard
      title={title}
      headerAction={
        selectedTag &&
        !isCondensedView && (
          <Button
            onClick={() => handleSelectTag()}
            label="Close tag summary"
            startIcon={<ArrowBackIcon />}
            color="primary"
            variant="text"
          />
        )
      }
      margin={0}
      padding={0}
    >
      <TagListOverview
        isCondensedView={isCondensedView}
        combineTagCategories={combineTagCategories}
        initialSelectedTag={selectedTag}
        showCustomTags={showCustomTags}
        onSelectTag={handleSelectTag}
        visibleTagSet={visibleTagSet}
        useComparison={useComparison}
      />
    </OutlineCard>
  );
};

export default TagListCard;
