import { Colors } from '@pkg/utils';
import { ChipType } from '@/lib/enums';

export default function entityChipColors(entityType) {
  const color = Colors.entity(entityType);
  const colors = {
    background: color.tints[1],
    border: color.main,
    font: color.main,
    highlight: color.tints[1],
  };

  if (entityType === ChipType.HISTORICAL) {
    colors.background = color.tints[4];
    colors.border = color.shades[4];
    colors.font = color.shades[4];
    colors.highlight = color.tints[1];
  }

  if (entityType === ChipType.ROLE) {
    colors.highlight = color.tints[2];
  }

  if (entityType === ChipType.SCENARIO) {
    colors.font = color.shades[3];
    colors.highlight = color.tints[0];
  }

  if (entityType === ChipType.ORGANISATION) {
    colors.highlight = color.tints[2];
  }

  return colors;
}
