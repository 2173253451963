import aggregateActivity from '@/shared/utils/aggregateActivity';
import { DesignEntity, Position } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';

/**
 * Maps each quadrant with it's respective title and default values.
 * @return {Object}
 */
const buildQuadrantMap = (quadrants) => {
  const __totalHours = 0;

  const quadrantMap = new Map([
    [
      DesignEntity.ACTIVITY,
      {
        activities: new Map(),
        __totalHours,
      },
    ],
  ]);

  Object.keys(quadrants.labels).forEach((key) => {
    quadrantMap.set(key, {
      title: quadrants.labels[key],
      color: quadrants.colors[key] ?? brand.blue.main,
      activities: new Map(),
      __totalHours,
    });
  });

  return quadrantMap;
};

/**
 * @param {Object}
 * @return {Quadrant}
 */
const getQuadrantType = (activity, firstTag, secondTag) => {
  const hasFirstTag = activity.__tags_set.has(firstTag);
  const hasSecondTag = activity.__tags_set.has(secondTag);

  if (hasFirstTag && hasSecondTag) {
    return Position.TOP_RIGHT;
  }

  if (hasFirstTag) {
    return Position.TOP_LEFT;
  }

  if (hasSecondTag) {
    return Position.BOTTOM_RIGHT;
  }

  return Position.BOTTOM_LEFT;
};

/**
 * @param {Object}
 * @return {Object}
 */
export default function mapActivityQuadrants({ scenario, matrix }) {
  const activityList = scenario?.relationships.get(DesignEntity.ACTIVITY);

  // The quadrant map stores minimal aggregated activity data for each quadrant,
  // and a complete map of all activities.
  const quadrantMap = buildQuadrantMap(matrix);
  const firstTag = matrix.tags[0];
  const secondTag = matrix.tags[1];

  activityList.forEach((activity) => {
    if (
      Boolean(activity.disabled_at) ||
      activity.owner_type !== DesignEntity.ROLE
    ) {
      return;
    }

    quadrantMap.get(DesignEntity.ACTIVITY).__totalHours += activity.hours;

    const existingActivity = quadrantMap
      .get(DesignEntity.ACTIVITY)
      ?.activities?.get(activity.library_uuid);

    quadrantMap.get(DesignEntity.ACTIVITY)?.activities?.set(
      activity.library_uuid,
      aggregateActivity({
        activity,
        entity: activity,
        existing: existingActivity,
        role: activity.owner_uuid,
      })
    );

    const quadrantType = getQuadrantType(activity, firstTag, secondTag);
    const quadrant = quadrantMap.get(quadrantType);

    const existingQuadrantActivity = quadrant?.activities?.get(
      activity.library_uuid
    );

    quadrant.__totalHours += activity.hours;
    quadrant?.activities?.set(
      activity.library_uuid,
      aggregateActivity({
        activity,
        entity: activity,
        existing: existingQuadrantActivity,
        role: activity.owner_uuid,
        storeEntity: false,
      })
    );
  });

  return quadrantMap;
}
