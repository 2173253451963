import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { TagDimensions } from '@/molecules/chartElements';
import { OutlineCard } from '@/organisms/cards';
import { D3Chart, HorizontalBarChart } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { ScopeType } from '@/shared/enums';
import { useInsightsContext } from '@/shared/providers';
import { useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';

const TagSummary = ({ limit = 6, showComparison = false }) => {
  const { filter } = useInsightsContext();
  const { selectScope } = useViewModeContext();
  const [dimension, setDimension] = useState(TagDimensions[EntityType.TAG]);
  const sort = {
    metric: 'hours',
    type: 'DESC',
  };
  const { chartData, metricTotals } = useAggregateChartData({
    dimension,
    filter,
    sort,
    useComparison: showComparison,
  });

  const hasActivities = metricTotals?.activities > 0;

  const handleClick = () => {
    selectScope(ScopeType.TAGS);
  };

  return (
    chartData?.length > 0 && (
      <OutlineCard
        headerAction={
          hasActivities && (
            <Button
              color="secondary"
              label="View all"
              onClick={handleClick}
              size={Size.X_SMALL}
            />
          )
        }
        padding={0}
        margin={0}
        title="How work is categorised"
      >
        <Box textAlign="center">
          {hasActivities ? (
            <D3Chart
              hideBorder
              height={216}
              id="tag-activity-insights"
              noScrollX
              noScrollY
              restrictHeight
            >
              <HorizontalBarChart
                initialData={chartData.slice(0, limit)}
                labelWidthPercentage={0.5}
                metric="percentage"
                metricTotals={metricTotals}
                sort={sort}
                title="Activity category"
              />
            </D3Chart>
          ) : (
            <EmptyInsights
              height={216}
              message="No tagged activities were found."
              size={Size.SMALL}
            />
          )}
        </Box>
      </OutlineCard>
    )
  );
};

export default TagSummary;
