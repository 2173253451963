import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import { Database } from '@pkg/database';
import { Collections } from '@pkg/utils';
import { DesignType } from '@/lib/enums';
import useMutations from './useMutations';
import usePrune from './usePrune';
import useRefresh from './useRefresh';
import useStore from './useStore';

export default function useLive() {
  const { isAuthenticated, isLoading } = Auth.useStore();
  const { refresh, isRefreshing, isStale } = useRefresh();
  const { set, reset } = useStore();
  const mutations = useMutations();

  const designs = Database.useLive('designs');

  usePrune(Collections.pluck(designs, 'uuid'));

  const dependencies = [
    JSON.stringify(designs),
    mutations,
    isAuthenticated,
    isLoading,
    isRefreshing,
    isStale,
  ];

  useEffect(() => {
    if (isLoading || isRefreshing) {
      return;
    }

    if (!isAuthenticated) {
      set({ designs, mutations: {}, isLoading: false });
      return;
    }

    if (isStale) {
      reset();
      refresh();
      return;
    }

    if (!designs) {
      return;
    }

    // invalid IndexedDB state, multiple live designs
    if (designs.filter(({ type }) => type === DesignType.LIVE).length > 1) {
      reset();
      refresh();
    }

    set({ designs, mutations, isLoading: false });
  }, dependencies);
}
