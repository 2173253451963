import { Obj } from '@pkg/utils';
import { deriveProps as deriveDesign } from '../designs';

/**
 * @param {Object} library
 * @param {Object} organisation
 * @return {Object}
 */
export default function deriveProps(library, organisation) {
  if (Obj.isEmpty(organisation)) {
    return organisation;
  }

  const design = deriveDesign(library, organisation.design ?? {});

  return {
    ...organisation,
    design,
    __metrics: design.__metrics,
    __percentage: design.__percentage,

    /** @deprecated */
    __total_metrics: design.__total_metrics,
    __visible_metrics: design.__visible_metrics,
  };
}
