import { EntityType } from '@/shared/enums';

export default function excludeActivity({ activity, filter, group, role }) {
  const ids = filter?.ids;
  if (!ids || ids?.has('ALL')) {
    return;
  }

  switch (filter.entity) {
    case EntityType.GROUP:
      if (ids.has(group?.uuid)) {
        return;
      }

      if (filter.options?.hideNestedEntities) {
        return !ids.has(group?.__parent?.uuid);
      }

      const groups = group?.__above?.[EntityType.GROUP];

      if (!groups) {
        return true;
      }

      return groups && ![...groups].some((id) => [...filter.ids].includes(id));

    case EntityType.ROLE:
      return !ids.has(role?.uuid);

    case EntityType.MANAGER:
      if (ids.has(role?.uuid)) {
        return;
      }

      if (filter.options?.hideNestedEntities) {
        return !ids.has(role.__manager);
      }

      const managers = role?.__above?.[EntityType.MANAGER];
      return (
        managers && ![...managers].some((id) => [...filter.ids].includes(id))
      );

    case EntityType.ACTIVITY:
      return !ids.has(activity?.uuid);

    case EntityType.TAG:
      let include = false;
      activity.tags.forEach((id) => {
        if (ids.has(id)) {
          include = true;
        }
      });
      return !include;
    default:
  }
}
