import { useMemo } from 'react';
import deriveProps from './deriveProps';

/**
 * @param {Object} tags
 * @return {Object}
 */
export default function useDerivedProps(tags, categories) {
  return useMemo(() => {
    return deriveProps(tags, categories);
  }, [tags]);
}
