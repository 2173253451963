const ROLE_SET = Object.freeze({
  byId: {},
  byRole: {},
});

/**
 * Retrieve RBAC roles for the current user.
 * The return object is keyed by entity type (e.g. DESIGN)
 * with each value being an object with two keys:
 * - byId: a map of entity ids to role levels
 * - byRole: a map of role levels to entity ids
 *
 * Example: roles.DESIGN.byId['123'] === 'admin'
 *
 * @param {Object} me
 * @return {Object}
 */
export default function getRoles(me) {
  const roles = {};

  for (const context in me?.access?.roles) {
    me.access.roles[context].forEach((role) => {
      const [lowerType, level, id] = role.split('.');
      const type = lowerType.toUpperCase();

      if (!Object.hasOwn(roles, type)) {
        roles[type] = structuredClone(ROLE_SET);
      }

      if (!Object.hasOwn(roles[type].byRole, level)) {
        roles[type].byRole[level] = new Set();
      }

      roles[type].byId[id] = level;
      roles[type].byRole[level].add(id);
    });
  }

  return roles;
}
