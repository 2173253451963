import { TagChip } from '@/atoms/chips';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { ChartTooltip, CircleMetric } from '@/molecules/chartElements';
import { RowColors } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import Stack from '@mui/material/Stack';

const BarLabel = ({
  expanded,
  labelHeight,
  metric,
  metricTotals,
  row,
  width,
  yPosition,
}) => {
  const showSelected =
    (row.type === ChartRowType.ACTIVITY ||
      row.type === ChartRowType.ROLE ||
      row.type === ChartRowType.GROUP) &&
    row.expanded;
  const colors = RowColors[row.type];
  const labelColors = colors.label;
  const selectedColor = colors.group.backgroundColor;
  const person =
    row.type === ChartRowType.PERSON
      ? {
          avatar: row.data.avatar,
          name: row.data.label,
        }
      : null;

  return (
    <>
      {showSelected && (
        <>
          <circle
            cx={width - (labelHeight - 1) / 2}
            cy={yPosition + labelHeight / 2}
            r={labelHeight / 2}
            fill={selectedColor}
          />
          <rect
            width={labelHeight}
            height={labelHeight * expanded}
            x={width - labelHeight + 0.5}
            y={yPosition + labelHeight / 2}
            fill={selectedColor}
          />
          <circle
            cx={width - (labelHeight - 1) / 2}
            cy={yPosition + labelHeight * expanded + labelHeight / 2}
            r={labelHeight / 2}
            fill={selectedColor}
          />
        </>
      )}
      <ChartTooltip
        item={row}
        maxWidth={500}
        metric={metric}
        metricTotals={metricTotals}
        minWidth={200}
      >
        <foreignObject
          height={labelHeight}
          width={width - 8}
          x={8}
          y={yPosition}
        >
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="flex-end"
            height={labelHeight}
            spacing={1}
            sx={{
              color: BaseColors.font.primary,
            }}
          >
            {row.type === ChartRowType.TAG ? (
              <TagChip
                color={row.props.color}
                name={row.label}
                id={row.id}
                expanded={true}
              />
            ) : (
              <Paragraph
                size={Size.SMALL}
                overrideStyles={{
                  textAlign: 'right',
                  mb: 0,
                  width: width - labelHeight - 8,
                  textWrap: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {row.label}
              </Paragraph>
            )}
            <CircleMetric
              labelHeight={labelHeight}
              colors={labelColors}
              person={person}
              value={row.data.count}
            />
          </Stack>
        </foreignObject>
      </ChartTooltip>
    </>
  );
};

const VerticalLabels = ({
  expanded,
  includeCounts = true,
  labelHeight,
  metric,
  metricTotals,
  row,
  width,
  yPosition,
}) => {
  return (
    <g className="vertical-label">
      <BarLabel
        expanded={expanded}
        labelHeight={labelHeight}
        metric={metric}
        metricTotals={metricTotals}
        row={row}
        yPosition={yPosition}
        width={width}
      />
    </g>
  );
};

export default VerticalLabels;
