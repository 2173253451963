import { styled } from '@mui/material/styles';
import Image from '@/components/Image';

const Root = styled(Image)(({ width }) => ({
  display: 'block',
  width,
}));

const Logo = ({ type = 'icon', width = 130, ...props }) => {
  const types = {
    full: '/beam-logo.png',
    icon: '/beam-icon.png',
  };

  return (
    <Root
      src={types[type]}
      alt="Beam Australia logo"
      width={width}
      {...props}
    />
  );
};

export default Logo;
