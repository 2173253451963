import { createContext, useContext, useMemo } from 'react';
import { useSearch } from '@/shared/hooks';
import { Roles } from '@pkg/entities/library';

export const RoleContext = createContext();
export const useRoleContext = () => useContext(RoleContext);

const RoleProvider = ({ children }) => {
  const roleMap = Roles.useStore((state) => state.map);
  const roleList = Roles.useStore((state) => state.list);
  const roleIndex = Roles.useStore((state) => state.index);
  const setRoles = Roles.useStore((state) => state.setRoles);

  const searchRoles = useSearch({
    searchList: roleList,
    searchMap: roleMap,
    searchIndex: roleIndex,
  });

  const search = (searchTerm, validationFn) => {
    return searchRoles(searchTerm, validationFn);
  };

  /**
   * Updates the role index with the provided role list.
   *
   * @param {Array}
   */
  const updateRoleIndexes = (roles) => {
    setRoles(roles);
  };

  const context = useMemo(() => {
    return {
      search,
      roleList,
      roleMap,
      updateRoleIndexes,
    };
  }, [roleList, roleMap]);

  return (
    <RoleContext.Provider value={context}>{children}</RoleContext.Provider>
  );
};

export default RoleProvider;
