import { Position } from '@/lib/enums';

const ChartAlignment = Object.freeze({
  [Position.BOTTOM_LEFT]: Position.TOP_RIGHT,
  [Position.BOTTOM_RIGHT]: Position.TOP_LEFT,
  [Position.TOP_LEFT]: Position.BOTTOM_RIGHT,
  [Position.TOP_RIGHT]: Position.BOTTOM_LEFT,
});

export default ChartAlignment;
