import { useCallback } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { useDispatchContext } from '@/shared/providers';
import AddIcon from '@mui/icons-material/AddCircleOutlineRounded';
import Button from '@mui/material/Button';
import shortuuid from '@pkg/uuid';

const AddFieldButton = ({ action, label, fields }) => {
  const { canEdit, handleDispatch } = useDispatchContext();

  const handleClick = useCallback(() => {
    if (handleDispatch && fields) {
      handleDispatch({
        action,
        properties: fields.map((field) => ({
          ...field,
          new_uuid: shortuuid.generate(),
        })),
      });
    }
  }, [action, label, fields]);

  return (
    canEdit && (
      <Button
        disableElevation
        fullWidth={true}
        startIcon={<AddIcon />}
        onClick={handleClick}
        variant="text"
        sx={{
          backgroundColor: 'transparent',
          color: BaseColors.font.primary,
          border: `2px dotted ${BaseColors.border.tertiary}`,
          borderRadius: '8px',
          py: 2,
        }}
      >
        {label}
      </Button>
    )
  );
};

export default AddFieldButton;
