/**
 * Builds the view and scope parameters to be used for shallow updates.
 *
 * @param { Object }
 *
 * @return {Object}
 */
export default function buildSearchParams({
  id,
  query,
  scope,
  scopeEntity,
  view,
}) {
  const { parts } = query;

  // We should always have a view and parts.
  const params = {
    query: {
      parts,
      view,
    },
  };

  if (scope) {
    params.query.scope = scope;
  }

  if (id) {
    params.query[scopeEntity] = id;
  }

  return params;
}
