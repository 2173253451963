import { Num } from '@pkg/utils';

const RelationshipKeys = Object.freeze(['managed', 'self', 'span']);
const GroupKeys = Object.freeze(['total', 'visible']);
const MetricKeys = Object.freeze(['hours', 'fte', 'budget']);

/**
 * @param {Object} metrics
 * @param {Boolean} [hasRelationships]
 * @returns {Object}
 */
export default function roundFloatMetrics(metrics, hasRelationships = true) {
  if (hasRelationships) {
    RelationshipKeys.forEach((relationship) => {
      GroupKeys.forEach((group) => {
        MetricKeys.forEach((metric) => {
          const value = metrics[relationship][group][metric];
          metrics[relationship][group][metric] = Num.roundFloat(value);
        });
      });
    });
  } else {
    GroupKeys.forEach((group) => {
      MetricKeys.forEach((metric) => {
        const value = metrics[group][metric];
        metrics[group][metric] = Num.roundFloat(value);
      });
    });
  }

  return metrics;
}
