import { Size } from '@/atoms/enums';

const TabSize = Object.freeze({
  [Size.SMALL]: {
    lineHeight: '1.25rem',
    fontSize: '0.925rem',
  },
  [Size.MEDIUM]: {
    lineHeight: '2rem',
  },
  [Size.LARGE]: {
    lineHeight: '2.5rem',
    fontSize: '1.5rem',
  },
});

export default TabSize;
