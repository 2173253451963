import { useEffect, useState } from 'react';
import { useDatabase } from '@pkg/database';

export default function useHydratedDesign(design) {
  const [hydrated, setHydrated] = useState(null);
  const database = useDatabase();

  useEffect(() => {
    async function hydrate() {
      if (!design?.latest.uuid) {
        setHydrated(null);
        return;
      }

      const hydrated = structuredClone(design);
      const latest = await database.designRevisions.get(design.latest.uuid);
      hydrated.latest = latest;
      setHydrated(hydrated);
    }

    hydrate();
  }, [design?.latest.uuid]);

  return Object.freeze(hydrated);
}
