/**
 * @param {Object[]} collection
 * @param {String} key
 *
 * @return {Object}
 */
export function mapBy(collection, key) {
  const map = new Map();

  collection?.forEach((item) => {
    map.set(item[key], item);
  });

  return map;
}

/**
 * @param {Object[]} collection
 * @param {String} collection[].uuid
 *
 * @return {Object}
 */
export function mapById(collection) {
  return mapBy(collection, 'uuid');
}
