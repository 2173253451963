import SnapshotMetricGroup from '@pkg/entities/snapshots/enums/SnapshotMetricGroup';

export default function defaultMetrics(comparisonScenario) {
  const comparisonMetrics = comparisonScenario?.entity?.__total_metrics || {
    ...SnapshotMetricGroup,
  };

  return [
    {
      ...SnapshotMetricGroup,
      moved: 0,
      affectedRoles: 0,
      ...comparisonMetrics,
    },
    {
      ...SnapshotMetricGroup,
      moved: 0,
      affectedRoles: 0,
      ...comparisonMetrics,
    },
  ];
}
