import { useEffect, useState } from 'react';
import { useClient } from '@pkg/client';
import { Database, useDatabase } from '@pkg/database';
import { useLocalState } from '@pkg/hooks';
import { Routes, Str } from '@pkg/utils';
import { DesignEntity, DesignOwner } from '@/lib/enums';
import { DesignFragment } from './fragments';

const AVAILABLE_DESIGNS = /* GraphQL */ `
  query AvailableDesigns {
    me {
      organisation {
        design {
          ...DesignFragment
        }
      }
      scenarios {
        ...DesignFragment
      }
      sharedScenarios {
        ...DesignFragment
      }
    }
  }

  ${DesignFragment}
`;

export default function useRefresh() {
  const client = useClient();
  const database = useDatabase();

  const [isRefreshing, setRefreshing] = useState(false);
  const [isStale, setStale] = useLocalState(Database.useStaleHash('designs'));

  const refresh = () => {
    setRefreshing(true);
  };

  const handleRefresh = async () => {
    const { data } = await client.graphql({ query: AVAILABLE_DESIGNS });

    if (!data?.me) {
      setRefreshing(false);
      return;
    }

    const designs = [data.me.organisation.design]
      .concat(data.me.scenarios)
      .concat(data.me.sharedScenarios);

    const revisions = designs.map(({ latest, ...design }, index) => {
      const snapshot = JSON.parse(latest.snapshotString);
      snapshot.__hash = Str.hash(latest.snapshotString);

      latest.snapshot = snapshot;
      latest.loaded_at = Date.now();
      delete latest.snapshotString;

      designs[index].latest = { uuid: latest.uuid };
      designs[index].__pathname = Routes.build.design(design, snapshot);
      designs[index].__type = design.is_scenario
        ? DesignEntity.SCENARIO
        : DesignEntity.DESIGN;

      designs[index].owner.__type = designs[index].is_scenario
        ? DesignOwner.PERSON
        : DesignOwner.ORGANISATION;

      return latest;
    });

    await database.designs.clear();
    await Promise.all([
      database.designs.bulkPut(designs),
      database.designRevisions.bulkPut(revisions),
      database.hash.put({ key: 'designs', updated_at: Date.now() }),
    ]);

    setStale(false);
    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return { refresh, isRefreshing, isStale };
}
