import shortuuid from '@pkg/uuid';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.roleIds
 * @param {Object} input.template
 * @returns {Object}
 */
export default function patch(snapshot, { roleIds, template }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: { update: [] },
      roles: { update: [] },
      activities: { create: [], remove: [] },
    },
  };

  const roleIdsSet = new Set(roleIds);
  const roles = entities.roles.filter((role) => roleIdsSet.has(role.uuid));
  const templateActivities = template.snapshot.entities.activities || [];
  const touchedGroups = new Set();

  entities.activities.forEach((activity) => {
    if (roleIdsSet.has(activity.owner_uuid)) {
      mutation.entities.activities.remove.push(activity.uuid);
    }
  });

  roles.forEach((role) => {
    mutation.entities.roles.update.push({ uuid: role.uuid, updated_at: now });
    if (role.group_uuid) {
      touchedGroups.add(role.group_uuid);
    }

    templateActivities.forEach((template) => {
      mutation.entities.activities.create.push({
        ...structuredClone(template),
        uuid: shortuuid.generate(),
        owner_uuid: role.uuid,
        created_at: now,
        updated_at: now,
      });
    });
  });

  if (mutation.entities.activities.create.length === 0) {
    delete mutation.entities.activities.create;
  }

  if (mutation.entities.activities.remove.length === 0) {
    delete mutation.entities.activities.update;
  }

  if (Object.keys(mutation.entities.activities).length === 0) {
    return null;
  }

  touchedGroups.forEach((uuid) => {
    mutation.entities.groups.update.push({ uuid, updated_at: now });
  });

  return mutation;
}
