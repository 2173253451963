import { useMutation } from '@pkg/graphql';

const MUTATE_ACCESS = /* GraphQL */ `
  mutation MutateAccess($input: MutateAccessInput!) {
    mutateAccess(input: $input) {
      uuid
    }
  }
`;

export default function useMutateAccess(query) {
  const { mutateAsync } = useMutation({
    mutation: MUTATE_ACCESS,
    invalidateQuery: query,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Array} users
   * @param {Object} input
   * @param {Object} input.roles
   * @param {Object} input.permissions
   * @returns {Object|null}
   */
  function mutateAccess(organisation_uuid, users, { roles, permissions }) {
    return mutateAsync({
      organisation_uuid,
      users,
      roles,
      permissions,
    });
  }

  return mutateAccess;
}
