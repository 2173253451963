import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

export const CANCEL_INVITE = /* GraphQL */ `
  mutation CancelInvite($input: InviteUpdateInput!) {
    cancelInvite(input: $input)
  }
`;

export default function useCancelInvite(query) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync } = useMutation({
    mutation: CANCEL_INVITE,
    invalidateQuery: query,
  });

  /**
   * @param {String} email
   */
  async function cancelInvite(email) {
    const { data } = mutateAsync({ email });
    enqueueSnackbar(`Canceling invite to "${email}".`);
    return data?.cancelInvite;
  }

  return cancelInvite;
}
