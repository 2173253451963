import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import { Property } from '@/lib/enums';
import handlePropertiesInput from '../handlePropertiesInput';

/**
 * @param {Object} input
 * @param {Object} input.from
 * @param {Object} input.from.snapshot
 * @param {String} input.from.uuid
 * @param {Object} input.to
 * @param {Object} input.to.snapshot
 * @param {String} input.to.title
 * @param {String} input.to.uuid
 * @returns {Object}
 */
export default function publishToRole({ from, to }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        create: [],
        remove: [],
      },
    },
  };

  // update role
  const keyedFrom = Snapshots.utils.getKeyed(from.snapshot);
  const keyedTo = Snapshots.utils.getKeyed(to.snapshot);

  const target = keyedTo.entities.roles[to.uuid];
  const role = keyedFrom.entities.roles[from.uuid];

  if (role.title !== to.title) {
    role.title = to.title;
  }

  role.uuid = target.uuid;
  role.external_id = target.external_id;
  role.group_uuid = target.group_uuid;
  role.parent_uuid = target.parent_uuid;
  role.updated_at = target.updated_at;
  delete role.created_at;

  const roleInput = structuredClone(role);

  // only include properties that have changed
  const required = new Set(['uuid', 'updated_at']);
  for (const property in roleInput) {
    if (required.has(property)) {
      continue;
    }

    // make sure budget is never null
    if (property === Property.BUDGET && role[property] === null) {
      delete roleInput[property];
      continue;
    }

    // for budget cast both values to Number as they can be strings
    if (
      property === Property.BUDGET &&
      Number(role[property]) === Number(target[property])
    ) {
      delete roleInput[property];
      continue;
    }

    if (property === Property.PROPERTIES) {
      roleInput[property] = handlePropertiesInput(
        role[property],
        target[property]
      );

      if (!roleInput[property]) {
        delete roleInput[property];
      }
    }

    if (role[property] === target[property]) {
      delete roleInput[property];
    }
  }

  mutation.entities.roles.update.push(roleInput);

  // remove existing activities
  to.snapshot.entities.activities.forEach(({ uuid, owner_uuid }) => {
    if (role.uuid === owner_uuid) {
      mutation.entities.activities.remove.push(uuid);
      delete keyedTo.entities.activities[uuid];
    }
  });

  // create new activities
  const activitiesFrom = Collections.where(
    from.snapshot.entities.activities,
    'owner_uuid',
    from.uuid
  );

  activitiesFrom.forEach((activity) => {
    activity.owner_uuid = role.uuid;
    if (Object.hasOwn(keyedTo.entities.activities, activity.uuid)) {
      activity.uuid = shortuuid.generate();
    }

    mutation.entities.activities.create.push(activity);
  });

  // clear empty mutations
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (!mutation.entities[type][action].length) {
        delete mutation.entities[type][action];
      }
    });
  });

  return { mutation, role };
}
