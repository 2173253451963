import Insight from '../templates/Insight';
import Energising from './Energising';
import OverCapacity from './OverCapacity';
import Workload from './Workload';

class WorkHealth extends Insight {
  benchmark() {
    return 'Good';
  }
}

export default new WorkHealth('WorkHealth', [
  Energising,
  Workload,
  OverCapacity,
]);
