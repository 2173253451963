import LinkButton from '@/components/LinkButton';
import NotFound from '@/components/NotFound';

const DesignNotFound = ({ isAuthenticated }) => {
  return (
    <NotFound
      title="Design not found"
      message={
        <span>
          Sorry, we can't find the design you were looking for.
          <br />
          It may not exist or was entered incorrectly.
        </span>
      }
    >
      {isAuthenticated && (
        <LinkButton variant="primary" size="small" href="/" sx={{ mt: 1 }}>
          Go back to your dashboard
        </LinkButton>
      )}
    </NotFound>
  );
};

export default DesignNotFound;
