import MarchingBlocks from './MarchingBlocks';

const AppLoading = ({ style = {} }) => (
  <MarchingBlocks
    style={{
      display: 'block',
      margin: 'auto',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      ...style,
    }}
  />
);

export default AppLoading;
