import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

const TRANSFER_OWNERSHIP = /* GraphQL */ `
  mutation TransferOwnership($input: TransferOwnershipInput!) {
    transferOwnership(input: $input) {
      uuid
      owner {
        uuid
        first_name
        last_name
        email
      }
    }
  }
`;

/**
 * @param {any[]} query
 */
export default function useTransferOwnership(query) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync } = useMutation({
    mutation: TRANSFER_OWNERSHIP,
    invalidateQuery: query,
  });

  /**
   * @param {String} organisation_uuid
   * @param {String} user_uuid
   */
  async function transfer(organisation_uuid, user_uuid) {
    const state = await mutateAsync({ organisation_uuid, user_uuid });

    enqueueSnackbar('Transferred ownership!');

    return state;
  }

  return transfer;
}
