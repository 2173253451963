import { Collections, Sort } from '@pkg/utils';
import reorder from './reorder';

/**
 * @param {Object} state
 * @param {Object[]} state.tags
 * @param {Object[]} state.categories
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.order
 * @returns {Object}
 */
export default function move(
  { tags, categories },
  { uuid, category_uuid, order }
) {
  const tag = tags.map.get(uuid);

  if (tag.is_system) {
    return null;
  }

  if (tag.category_uuid === category_uuid) {
    return reorder({ tags, categories }, { uuid, order });
  }

  const event = {
    tags: {
      update: [{ uuid, category_uuid, order }],
    },
  };

  const sourceTags = Collections.where(
    tags.list,
    'category_uuid',
    tag.category_uuid
  ).filter(({ is_system }) => !is_system);
  sourceTags.sort(Sort.order());

  const destinationTags = Collections.where(
    tags.list,
    'category_uuid',
    category_uuid
  ).filter(({ is_system }) => !is_system);
  destinationTags.sort(Sort.order());

  const fromIndex = sourceTags.findIndex((tag) => tag.uuid === uuid);
  sourceTags.splice(fromIndex, 1);
  sourceTags.forEach(({ uuid, order }, index) => {
    if (order !== index) {
      event.tags.update.push({ uuid, order: index });
    }
  });

  destinationTags.splice(order, 0, tag);
  destinationTags.forEach(({ uuid, order }, index) => {
    if (uuid !== tag.uuid && order !== index) {
      event.tags.update.push({ uuid, order: index });
    }
  });

  return event;
}
