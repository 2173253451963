import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function movedRolesAction({
  actionMap,
  comparisonScenario,
  originalRole,
  role,
  scenario,
}) {
  const metrics = {
    ...role.__metrics.self.visible,
  };

  const existingAction = actionMap?.get(ActionType.MOVE_ROLES);

  const action = existingAction ?? {
    entity: EntityType.ROLE,
    metrics: defaultMetrics(comparisonScenario),
    type: ActionType.MOVE_ROLES,
    [EntityType.ROLE]: new Map(),
    [EntityType.MANAGER]: new Set(),
    [EntityType.GROUP]: new Set(),
  };

  const roleProperties = {
    metrics,
    props: {},
  };

  if (role.__manager !== originalRole.__manager) {
    if (role.__manager) {
      action[EntityType.MANAGER].add(role.__manager);
    }
    if (originalRole.__manager) {
      action[EntityType.MANAGER].add(originalRole.__manager);
    }
    roleProperties.props.manager = {
      from: originalRole.__manager,
      to: role.__manager,
    };
  }

  if (role.group_uuid !== originalRole.group_uuid) {
    if (role.group_uuid) {
      action[EntityType.GROUP].add(role.group_uuid);
    }
    if (originalRole.group_uuid) {
      action[EntityType.GROUP].add(originalRole.group_uuid);
    }
    roleProperties.props.group = {
      from: originalRole.group_uuid,
      to: role.group_uuid,
    };
  }

  action[EntityType.ROLE].set(role.uuid, roleProperties);
  action.metrics[1].moved += 1;

  const roleCount = action[EntityType.ROLE].size;
  const groupCount = action[EntityType.GROUP].size;
  const managerCount = action[EntityType.MANAGER].size;

  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const groupLabel = entityLabel(EntityType.GROUP, groupCount !== 1);
  const managerLabel = entityLabel(EntityType.MANAGER, managerCount !== 1);
  const verb = roleCount === 1 ? 'has' : 'have';

  action.title = `${roleCount} ${roleLabel} ${verb} been moved, impacting`;

  if (managerCount > 0) {
    action.title = `${action.title} ${managerCount} ${managerLabel} ${groupCount > 0 ? 'and' : ''}`;
  }

  if (groupCount > 0) {
    action.title = `${action.title} ${groupCount} ${groupLabel}`;
  }

  return action;
}
