import { memo } from 'react';
import { Size } from '@/atoms/enums';
import { Menu, MenuItem } from '@/molecules/menus';
import { DispatchProvider } from '@/shared/providers';

// import { Property } from '@/lib/enums';

const ActivityMenu = ({ disabled, id, isEditable, roleId }) => {
  const activeTitle = disabled ? 'Make active' : 'Make inactive';

  return (
    <Menu size={Size.MEDIUM}>
      <DispatchProvider
        action="activities.toggle"
        isEditable={isEditable}
        uuid={id}
      >
        <MenuItem
          action="activities.toggle"
          properties={{ active: disabled ? true : false, ids: [id] }}
        >
          {activeTitle}
        </MenuItem>
      </DispatchProvider>
      <DispatchProvider
        action="activities.archive"
        isEditable={isEditable}
        uuid={roleId}
      >
        <MenuItem
          danger
          action="activities.archive"
          confirmation={{
            title: 'Archive activity',
            content: 'Are you sure you want to archive this activity?',
          }}
          properties={[id]}
        >
          Archive
        </MenuItem>
      </DispatchProvider>
    </Menu>
  );
};

export default memo(ActivityMenu, (prev, next) => {
  return prev.id === next.id && prev.disabled === next.disabled;
});
