import { DesignEntity, Visibility } from '@/lib/enums';
import { formatDesignData, formatGroupData } from './formatTableData';

/**
 * Takes an array of scenario maps and converts them into tabular data.
 *
 * @param {Array}
 * @return {Object}
 */
export default function formatTagGroupTableData({
  showBudget,
  entityType,
  propertyMap,
  scenarioMaps,
  tagMap,
}) {
  const rows = [];

  scenarioMaps?.forEach((scenarioMap) => {
    const groups = scenarioMap.get(DesignEntity.GROUP);

    const organisationHours = [...groups.values()].reduce((prev, next) => {
      return (prev += next.__total_metrics.hours);
    }, 0);

    groups.forEach((group) => {
      if (group.__visibility === Visibility.NONE) {
        return;
      }
      const groupLead = scenarioMap
        ?.get(DesignEntity.PERSON)
        ?.get(group?.lead_uuid);

      const design = scenarioMap?.get(DesignEntity.ORGANISATION);

      const rowData = {
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      };

      if (!rowData[entityType]?.tags?.length) {
        rows.push(rowData);
        return;
      }

      // Add a row for each tag in the activity.
      rowData[entityType].tags.forEach((tag) => {
        rows.push({
          ...rowData,
          tag,
        });
      });
    });
  });

  return rows;
}
