import { Fragment, useEffect, useState } from 'react';
import { Label } from '@/atoms/typography';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MultiCheckboxOption from './MultiCheckboxOption';

const convertValuesToMap = (values) => {
  if (!values?.length) {
    return new Map();
  }

  return new Map(
    values.map(({ id, value }) => {
      return [id, new Set(value)];
    })
  );
};

const convertMapToArray = (map) => {
  if (!map?.size) {
    return [];
  }
  return [...map.entries()].map(([id, value]) => {
    return {
      id,
      value: [...value],
    };
  });
};

const MultiCheckboxOptions = ({
  categorised,
  color = 'primary',
  colorProps,
  label,
  optionId = 'id',
  options,
  optionLabel,
  OptionComponent,
  onChange,
  initialSelected,
}) => {
  const [selected, setSelected] = useState(convertValuesToMap(initialSelected));

  const handleChange = (event, option, subOption) => {
    if (!option || !subOption) {
      return;
    }

    const updatedValue = new Map(selected);
    const updatedOption = new Set(updatedValue?.get(option) ?? []);

    if (!updatedOption?.size) {
      updatedValue.set(option, new Set([subOption]));
      setSelected(updatedValue);
      onChange(event, convertMapToArray(updatedValue));

      return;
    }

    if (updatedOption.has(subOption)) {
      updatedOption?.delete(subOption);
    } else {
      updatedOption.add(subOption);
    }

    if (updatedOption.size > 0) {
      updatedValue.set(option, updatedOption);
    } else {
      updatedValue.delete(option);
    }

    setSelected(updatedValue);
    onChange(event, convertMapToArray(updatedValue));
  };

  // Ensure we update selections when receiving updates from upstream.
  useEffect(() => {
    const initial = JSON.stringify(initialSelected);
    const current = JSON.stringify(convertMapToArray(selected));
    if (initial != current) {
      setSelected(convertValuesToMap(initialSelected));
    }
  }, [JSON.stringify(initialSelected)]);

  return (
    <Box>
      {label && <Label>{label}</Label>}
      {categorised
        ? options.map((category) => (
            <Fragment key={category[optionId]}>
              {category.categorise && (
                <Stack p={1}>
                  <Heading variant="h6">{category.name}</Heading>
                </Stack>
              )}
              {category?.options.map((option) => (
                <MultiCheckboxOption
                  key={`${option[optionId]}`}
                  option={option}
                  color={color}
                  colorProps={colorProps}
                  onChange={handleChange}
                  OptionComponent={OptionComponent}
                  optionLabel={optionLabel}
                  value={selected?.get(option[optionId])}
                />
              ))}
            </Fragment>
          ))
        : options.map((option) => (
            <MultiCheckboxOption
              key={`${option[optionId]}`}
              option={option}
              color={color}
              colorProps={colorProps}
              onChange={handleChange}
              OptionComponent={OptionComponent}
              optionLabel={optionLabel}
              value={selected?.get(option[optionId])}
            />
          ))}
    </Box>
  );
};

export default MultiCheckboxOptions;
