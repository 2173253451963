/**
 * @param {Number[]} range
 * @param {Number} value
 * @return {Number}
 */
export default function relativePercent(range, value) {
  const isPositive = range[0] < range[1];

  if (isPositive ? value <= range[0] : value >= range[0]) {
    return 0;
  }

  if (isPositive ? value >= range[1] : value <= range[1]) {
    return 1;
  }

  const rangeDelta = range[1] - range[0];
  const valueDelta = isPositive ? value - range[0] : range[1] - value;

  return valueDelta / rangeDelta;
}

/**
 * Returns a score based on a provided set of range functions.
 * @param {Object} range
 * @param {Number} value
 * @return {Number}
 */
export function rangePercent(range, value) {
  switch (true) {
    case range.LOW(value):
      return 20;
    case range.MEDIUM(value):
      return 50;
    case range.HIGH(value):
      return 100;
    default:
      return 50;
  }
}
