import { useRef, useState } from 'react';
import { Variant } from '@/atoms/enums';
import { TextSelect } from '@/atoms/inputs';
import { useOutsideClick } from '@/shared/hooks';
import Box from '@mui/material/Box';

const SearchField = ({
  autoFocus,
  disableClearable = true,
  filterOptions,
  initialValue,
  label,
  maxHeight = 240,
  noOptionsText = 'No results were found',
  onChange = () => {},
  OptionComponent,
  options,
  optionLabel,
  placeholder = 'Search...',
  size,
  variant = Variant.OUTLINE,
}) => {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef();

  const handleFocus = () => {
    if (options.length > 0) {
      setShowOptions(true);
    }
  };

  const handleChange = (event, value) => {
    if (!value) {
      return;
    }

    onChange?.(event, value);
    setShowOptions(false);
  };

  const handleBlur = () => {
    setShowOptions(false);
  };

  const handleKeyDown = (event) => {
    const blur =
      (event.key === 'Tab' && event.shiftKey === true) ||
      event.key === 'Escape';

    if (blur) {
      event.stopPropagation();
      handleBlur();
      return;
    }

    if (!showOptions) {
      setShowOptions(true);
    }
  };

  useOutsideClick({ ref, onClick: handleBlur });

  return (
    <Box ref={ref}>
      <TextSelect
        autoFocus={autoFocus}
        canAddNew={false}
        color="primary"
        disableClearable={disableClearable}
        filterOptions={filterOptions}
        options={options}
        label={label}
        maxHeight={maxHeight}
        noOptionsText={noOptionsText}
        onFocus={handleFocus}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        OptionComponent={OptionComponent}
        optionLabel={optionLabel}
        placeholder={placeholder}
        showOptions={showOptions}
        size={size}
        initialValue={initialValue}
        variant={variant}
      />
    </Box>
  );
};

export default SearchField;
