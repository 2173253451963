import { memo, useEffect, useState } from 'react';
import { decimal } from '@pkg/utils/inputs';
import config from '@/lib/config';
import Text from './Text';

const FTEInput = ({ onBlur, onChange, ...props }) => {
  const [value, setValue] = useState(decimal.format(props.value));

  const handleBlur = (event, value) => {
    const blurred = decimal.blur(value, { max: config.MAX_FTE });
    onBlur?.(event, blurred);
    setValue(decimal.format(blurred));
  };

  const handleChange = (event, value) => {
    const changed = decimal.change(value);
    onChange?.(event, changed);
    setValue(changed);
  };

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <Text
      {...props}
      disableClear
      initialValue={decimal.format(props.value)}
      inputProps={{
        'data-testid': 'FTE',
        min: 0,
        type: 'number',
      }}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
    />
  );
};

FTEInput.propTypes = Text.propTypes;
export default memo(FTEInput);
