import { Information, InformationParagraph } from '@/molecules/information';

const PropertyStackChartInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        This chart presents Properties by Role.
      </InformationParagraph>
      <InformationParagraph>
        Select from the 'Property by' dropdown to present the data in the
        preferred format.
      </InformationParagraph>
      <InformationParagraph>
        Select the preferred 'Metric Type' from the dropdown to present the
        chart in Percentage, Hours, FTE or Budget (if permitted).
      </InformationParagraph>
      <InformationParagraph>
        Click on a segment in the chart to reveal further information about the
        Property.
      </InformationParagraph>
    </Information>
  );
};

export default PropertyStackChartInformation;
