import { useMemo } from 'react';
import * as Organisations from '@pkg/access/organisations';
import * as Policies from '@pkg/access/policies';
import * as Auth from '@pkg/auth';
import * as Get from '../get';
import * as relate from '../relationships';
import checkActions from './checkActions';

/**
 * @param {Object[]} designs
 * @param {Object[]} actions
 * @param {String} actions.entityType
 * @param {String} actions.property
 * @param {String} actions.action
 * @returns {Object}
 */
export default function useAccess(designs, actions) {
  const me = Auth.useStore((state) => state.me);
  const organisationId = Auth.useStore((state) => state.organisation?.uuid);

  const { lookup, isLoading } = Policies.usePolicies();
  const designIds = useMemo(
    () => designs?.map((design) => design.uuid) ?? [],
    [designs]
  );

  const checks = {};

  if (!isLoading && designs) {
    console.time('Access.bulk.useAccess');

    const level = Organisations.get.policyLevel(me, organisationId);
    const organisationPermissions =
      Organisations.get.permissions(organisationId);

    designs.forEach((design) => {
      const permissions = {
        ...Get.permissions(design.uuid),
        ...organisationPermissions,
      };
      const model = Get.model(me, permissions, design.is_scenario, false);

      if (!model.edit) {
        checks[design.uuid] = false;
        return;
      }

      const requiredTypes = Array.from(
        actions.reduce((acc, action) => {
          acc.add(action.entityType);
          return acc;
        }, new Set())
      );

      const relationships = relate.bulk(me, design, requiredTypes);
      checks[design.uuid] = checkActions(
        actions,
        level,
        design,
        lookup,
        relationships
      );
    });

    console.timeEnd('Access.bulk.useAccess');
  }

  return useMemo(() => checks, [designIds, actions, isLoading]);
}
