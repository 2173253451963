import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import { Database } from '@pkg/database';
import useRefresh from './useRefresh';
import useStore from './useStore';

export default function useLive() {
  const { isAuthenticated, isLoading, hasLimitedAccess } = Auth.useStore();
  const { refresh, isRefreshing } = useRefresh();
  const { set, setLoading } = useStore();

  const isStale = Database.useStaleHash('tags');
  const [tags, categories] = Database.useLiveTables(['tags', 'tagCategories']);

  useEffect(() => {
    if (isLoading || isRefreshing) {
      return;
    }

    if (!isAuthenticated && !hasLimitedAccess) {
      set({ tags: [], categories: [] });
      setLoading(false);
      return;
    }

    if (isStale) {
      refresh();
      return;
    }

    if (!tags || !categories) {
      return;
    }

    set({ tags, categories });
    setLoading(false);
  }, [
    JSON.stringify(tags),
    JSON.stringify(categories),
    isAuthenticated,
    isLoading,
    isRefreshing,
    isStale,
  ]);
}
