import { useMemo } from 'react';
import Productivity from '@pkg/insights/Productivity';
import WorkHealth from '@pkg/insights/WorkHealth';
import { DesignEntity } from '@/lib/enums';
import * as color from '@/lib/theme/tokens/color';

export default function useInsights(snapshot) {
  return useMemo(() => {
    const insights = [];

    insights.push({
      title: 'Productivity',
      labels: ['Strategic\nImportance', 'Administrative', 'Internal\nMeetings'],
      breakdownLabels: [
        'Strategic\nImportance',
        'Administrative',
        'Internal\nMeetings',
      ],
      summary: [
        {
          color: color.core.blue,
          data: Productivity.getEntityResults(snapshot),
          label: 'Organisation',
        },
      ],
      breakdown: snapshot.entities.groups.map((group) => ({
        uuid: group.uuid,
        type: DesignEntity.GROUP,
        data: Productivity.getEntityResults(group),
      })),
    });

    insights.push({
      title: 'Work Health',
      labels: ['Energising', 'Average\nWorkload', 'Over\nCapacity'],
      breakdownLabels: ['Energising', 'Average\nWorkload', 'Over\nCapacity'],
      summary: [
        {
          color: color.core.yellow,
          data: WorkHealth.getEntityResults(snapshot),
          label: 'Organisation',
        },
      ],
      breakdown: snapshot.entities.groups.map((group) => ({
        uuid: group.uuid,
        type: DesignEntity.GROUP,
        data: WorkHealth.getEntityResults(group),
      })),
    });

    return insights;
  }, [snapshot]);
}
