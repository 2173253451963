import { EntityMetric } from '@/lib/enums';

const METRIC_LIST = [
  EntityMetric.BUDGET,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.ACTIVITIES,
  EntityMetric.ROLES,
  EntityMetric.GROUPS,
];

export default function getSummaryMetricList({
  comparisonMetrics,
  scenarioMetrics,
  showBudget,
}) {
  if (!scenarioMetrics || !comparisonMetrics) {
    return [];
  }
  return METRIC_LIST.filter((metric) => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      return false;
    }
    if (metric === EntityMetric.BUDGET) {
      return true;
    }
    return comparisonMetrics[metric] !== scenarioMetrics[metric];
  });
}
