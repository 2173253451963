import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearch } from '@/shared/hooks';
import { Skills } from '@pkg/entities/library';

export const SkillContext = createContext();
export const useSkillContext = () => useContext(SkillContext);

const SkillProvider = ({ children }) => {
  const skillMap = Skills.useStore((state) => state.map);
  const skillList = Skills.useStore((state) => state.list);
  const skillIndex = Skills.useStore((state) => state.index);
  const peopleSkills = Skills.useStore((state) => state.people);
  const [peopleSkillMap, setPeopleSkillMap] = useState(new Map());

  const searchSkills = useSearch({
    searchList: skillList,
    searchMap: skillMap,
    searchIndex: skillIndex,
  });

  const search = (searchTerm, validationFn) => {
    return searchSkills(searchTerm, validationFn);
  };

  useEffect(() => {
    if (!peopleSkills.size) {
      setPeopleSkillMap(new Map());
    }

    const peopleSkillMap = new Map();

    peopleSkills.forEach((people, skill) => {
      people.forEach((skillLevel, person) => {
        const personMap = peopleSkillMap.get(person);

        if (personMap) {
          personMap.set(skill, skillLevel);
          return;
        }

        peopleSkillMap.set(person, new Map([[skill, skillLevel]]));
      });
    });

    setPeopleSkillMap(peopleSkillMap);
  }, [peopleSkills]);

  const context = useMemo(() => {
    return {
      peopleSkillMap,
      search,
      skillList,
      skillMap,
    };
  }, [peopleSkillMap, skillList, skillMap]);

  return (
    <SkillContext.Provider value={context}>{children}</SkillContext.Provider>
  );
};

export default SkillProvider;
