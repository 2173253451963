import * as Arr from '../arrays';

/**
 * @param {Object} a
 * @param {Object} b
 * @param {String[]} [keys]
 * @returns {String[]}
 */
export default function diff(a, b, keys) {
  const _keys = keys
    ? structuredClone(keys)
    : Array.from(new Set(Object.keys(a).concat(Object.keys(b))));

  return _keys.filter((key) => {
    if (Array.isArray(a[key]) && Array.isArray(b[key])) {
      const isPrimitivesA = a[key].every((i) => typeof i !== 'object');
      const isPrimitivesB = b[key].every((i) => typeof i !== 'object');

      if (isPrimitivesA && isPrimitivesB) {
        return Boolean(Arr.diff(a[key], b[key]).length);
      } else {
        return Boolean(Arr.objectDiff(a[key], b[key]).length);
      }
    }

    return a[key] !== b[key];
  });
}
