import { BeamibleTag } from '@/lib/enums';

/**
 * Checks if the tags provided are the same used for the alignment matrix.
 *
 * @param {Map} tags
 *
 * @return {Boolean}
 */
export default function isAlignmentMatrix(tags) {
  return (
    tags?.has(BeamibleTag.CRITICAL_WORK) &&
    tags?.has(BeamibleTag.STRATEGIC_IMPORTANCE)
  );
}
