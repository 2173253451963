import { useRouter } from 'next/router';
import UpdateIcon from '@mui/icons-material/Update';
import Back from '@pkg/icons/Back';
import { Routes } from '@pkg/utils';
import { DesignLevel, Action as ActionType } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { ActionGroup, Action } from '@/components/Toolbar';

const AuthenticatedToolbar = ({ presence }) => {
  const router = useRouter();
  const access = useDesignStore((state) => state.access);
  const isHistorical = useDesignStore((state) => state.isHistorical);
  const level = useDesignStore((state) => state.level);
  const onRestore = useDesignStore((state) => state.actions.restore);
  let canRestore = useDesignStore((state) => state.access?.model.restore);

  if (isHistorical && level.type !== DesignLevel.ORGANISATION) {
    const { uuid, type: __type } = level;
    canRestore =
      canRestore ||
      access.can({ uuid, __type }, null, ActionType.PUBLISH, {
        useLatest: true,
      });
  }

  const handleBacktoLatest = () => {
    const { designId } = Routes.getDesignParts(router.asPath);
    Routes.pushDesignParts(router, { designId, revisionId: 'latest' });
  };

  return (
    <>
      {isHistorical && (
        <ActionGroup>
          <Action
            onClick={handleBacktoLatest} /** @todo restore */
            color="primary"
            label="Back to latest"
            startIcon={<Back />}
            variant="secondary"
          />
          <Action
            disabled={!canRestore}
            label="Restore this version"
            startIcon={<UpdateIcon />}
            dialogProps={{
              title: 'Restore selected version',
              content:
                'After restoring you may still rollback to the current version.',
              confirm: 'Restore',
              cancel: 'Cancel',
              onConfirm: onRestore,
            }}
          />
        </ActionGroup>
      )}
    </>
  );
};

export default AuthenticatedToolbar;
