import { useCallback, useMemo } from 'react';

export const ProductTour = Object.freeze({
  ONBOARDING: 412152,
});

export default function useIntercom() {
  /**
   * Invoke an intercom tour
   * @param {String} id
   * @returns {void}
   */
  const tourHandler = (id) => {
    try {
      window?.Intercom('startTour', id);
    } catch (error) {
      console.error(error);
    }
  };

  const tour = useCallback(tourHandler, []);
  return useMemo(() => ({ tour }), [tour]);
}
