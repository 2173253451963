import { InsightTable } from '@/organisms/tables';
import { StoreType } from '@/shared/enums';
import {
  ActiveDesignProvider,
  ScenarioProvider,
  TagProvider,
  useCanSwitchViewModes,
} from '@/shared/providers';
import { useAccess } from '@pkg/access/organisations';
import * as Auth from '@pkg/auth';

const TableLayout = ({ designId, level, revisionId }) => {
  const access = useAccess();
  const organisation = Auth.useStore((state) => state.organisation);
  const isRestricted = !organisation.is_restricted && !access.model.manage;
  const canSwitchViewModes = useCanSwitchViewModes();

  return (
    <ActiveDesignProvider
      designId={designId}
      level={level}
      revisionId={revisionId}
      isRestricted={!canSwitchViewModes}
    >
      <TagProvider>
        <ScenarioProvider
          designId={designId}
          level={level}
          storeType={StoreType.DESIGN}
        >
          <div style={{ marginLeft: '118px', marginRight: '16px' }}>
            <InsightTable />
          </div>
        </ScenarioProvider>
      </TagProvider>
    </ActiveDesignProvider>
  );
};

export default TableLayout;
