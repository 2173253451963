import { Collections } from '@pkg/utils';

/**
 * @deprecated these should already be explicit actions
 *
 * @param {Object} entity
 * @param {Object[]} entity.properties
 * @param {Object[]} input
 * @returns {Object}
 */
export default function reduceProperties({ properties }, input) {
  if (!properties.length && !input.length) {
    return null;
  }

  const mutation = {
    create: [],
    remove: [],
    update: [],
  };

  const current = Collections.keyBy(properties);
  const target = Collections.keyBy(input);
  let isDifferent = false;

  input.forEach(({ key, value }) => {
    // create
    if (!Object.hasOwn(current, key)) {
      mutation.create.push({ key, value });
      isDifferent = true;
      return;
    }

    // update
    if (Object.hasOwn(current, key) && current[key].value !== value) {
      mutation.update.push({ key, value });
      isDifferent = true;
      return;
    }
  });

  properties.forEach(({ key }) => {
    // remove
    if (!Object.hasOwn(target, key)) {
      mutation.remove.push(key);
      isDifferent = true;
      return;
    }
  });

  if (!isDifferent) {
    return null;
  }

  Object.keys(mutation).forEach((action) => {
    if (mutation[action].length === 0) {
      delete mutation[action];
    }
  });

  return mutation;
}
