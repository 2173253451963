import { Collections } from '@pkg/utils';

/**
 * @param {Object} state
 * @param {Object[]} state.tags
 * @param {Object} input
 * @param {String} input.uuid
 * @returns {Object}
 */
export default function remove({ tags }, { uuid }) {
  const categoryTags = Collections.where(tags.list, 'category_uuid', uuid);
  const primaryTagsLen = Collections.where(
    tags.list,
    'category_uuid',
    null
  ).filter(({ is_system }) => !is_system).length;

  const event = {
    categories: {
      remove: [uuid],
    },
    tags: {
      update: categoryTags.map(({ uuid, order }) => ({
        uuid,
        category_uuid: null,
        order: order + primaryTagsLen,
      })),
    },
  };

  return event;
}
