/**
 * Calculates padding based on activity configuration.
 */
export default function calculateButtonPadding({
  showMenu,
  showRemove,
  showComparison,
}) {
  let padding = 0;

  if (showRemove) {
    padding += 28;
  }

  if (showMenu) {
    padding += 32;
  }

  if (showComparison) {
    padding += 146;
  }

  return padding;
}
