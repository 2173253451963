import { Size } from '@/atoms/enums';
import { ListBulletSizeProps, ListColors } from '@/atoms/lists';
import Box from '@mui/material/Box';

const ListBulletPoint = ({
  active,
  color = 'primary',
  completed,
  size = Size.MEDIUM,
}) => {
  const colorProps =
    ListColors[color][active || completed ? 'active' : 'normal'];

  const sizeProps = ListBulletSizeProps[size];

  return (
    <Box
      sx={{
        ...colorProps,
        ...sizeProps,
        textAlign: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        transition: 'all 300ms',
      }}
    />
  );
};

export default ListBulletPoint;
