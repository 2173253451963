import { brand, core } from '@/lib/theme/tokens/palettes';

const Select = Object.freeze({
  option: {
    primary: {
      normal: {
        backgroundColor: core.white,
        borderColor: '#E0E6F0',
      },
      hover: {
        backgroundColor: '#EEF3FB',
        borderColor: '#E0E6F0',
      },
      focus: {
        backgroundColor: '#EEF3FB',
        borderColor: '#E0E6F0',
      },
    },
    secondary: {
      normal: {
        backgroundColor: core.white,
        borderColor: '#E0E6F0',
      },
      hover: {
        backgroundColor: brand.blue.tints[0],
      },
      focus: {
        backgroundColor: brand.blue.tints[0],
        borderColor: brand.blue.tints[2],
      },
    },
  },
});

export default Select;
