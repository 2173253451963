import * as Colors from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const TabVariant = Object.freeze({
  folder: {
    normal: {
      backgroundColor: Colors.Base.background.secondary,
      boxShadow: `inset 0 -1px 0 0 ${Colors.Base.border.primary}`,
    },
    selected: {
      backgroundColor: core.white,
      boxShadow: `inset 1px 0 0 ${Colors.Base.border.primary}, inset 0 1px 0 0 ${Colors.Base.border.primary}, inset -1px 0 0 ${Colors.Base.border.primary}`,
    },
  },
  primary: {
    normal: {
      backgroundColor: 'transparent',
    },
    selected: {
      backgroundColor: brand.lightBlue.main,
      borderTopRightRadius: 2,
      borderTopLeftRadius: 2,
    },
  },
});

export default TabVariant;
