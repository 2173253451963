const Design = (props) => (
  <svg width="35" height="28" {...props} viewBox="0 0 26 15" fill="none">
    <path
      d="M10.7884 2.42286C10.7884 2.11358 11.0391 1.86286 11.3484 1.86286H16.7438C17.0531 1.86286 17.3038 2.11358 17.3038 2.42286C17.3038 2.73214 17.0531 2.98286 16.7438 2.98286H11.3484C11.0391 2.98286 10.7884 2.73214 10.7884 2.42286Z"
      fill="currentColor"
    />
    <path
      d="M9.18523 3.15596C9.59011 3.15596 9.91833 2.82774 9.91833 2.42286C9.91833 2.01798 9.59011 1.68976 9.18523 1.68976C8.78035 1.68976 8.45213 2.01798 8.45213 2.42286C8.45213 2.82774 8.78035 3.15596 9.18523 3.15596Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.34449 1.2442C7.34449 0.691917 7.79221 0.244202 8.34449 0.244202H18.0333C18.5856 0.244202 19.0333 0.691917 19.0333 1.2442V6.3694C19.0333 6.92168 18.5856 7.3694 18.0333 7.3694H8.34449C7.79221 7.3694 7.34449 6.92168 7.34449 6.3694V1.2442ZM8.34449 0.994202H18.0333C18.1714 0.994202 18.2833 1.10613 18.2833 1.2442V6.3694C18.2833 6.50747 18.1714 6.6194 18.0333 6.6194H8.34449C8.20642 6.6194 8.09449 6.50747 8.09449 6.3694V1.2442C8.09449 1.10613 8.20642 0.994202 8.34449 0.994202Z"
      fill="currentColor"
    />
    <path
      d="M4.39895 10.6293C4.08967 10.6293 3.83895 10.88 3.83895 11.1893C3.83895 11.4986 4.08967 11.7493 4.39895 11.7493H9.79435C10.1036 11.7493 10.3543 11.4986 10.3543 11.1893C10.3543 10.88 10.1036 10.6293 9.79435 10.6293H4.39895Z"
      fill="currentColor"
    />
    <path
      d="M2.96885 11.1893C2.96885 11.5942 2.64063 11.9224 2.23575 11.9224C1.83087 11.9224 1.50266 11.5942 1.50266 11.1893C1.50266 10.7844 1.83087 10.4562 2.23575 10.4562C2.64063 10.4562 2.96885 10.7844 2.96885 11.1893Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.39502 9.01062C0.842735 9.01062 0.39502 9.45833 0.39502 10.0106V15.1358C0.39502 15.6881 0.842736 16.1358 1.39502 16.1358H11.0839C11.6362 16.1358 12.0839 15.6881 12.0839 15.1358V10.0106C12.0839 9.45834 11.6362 9.01062 11.0839 9.01062H1.39502ZM11.0839 9.76062H1.39502C1.25695 9.76062 1.14502 9.87255 1.14502 10.0106V15.1358C1.14502 15.2739 1.25695 15.3858 1.39502 15.3858H11.0839C11.2219 15.3858 11.3339 15.2739 11.3339 15.1358V10.0106C11.3339 9.87255 11.2219 9.76062 11.0839 9.76062Z"
      fill="currentColor"
    />
    <path
      d="M17.7379 11.1893C17.7379 10.88 17.9886 10.6293 18.2979 10.6293H23.6933C24.0026 10.6293 24.2533 10.88 24.2533 11.1893C24.2533 11.4986 24.0026 11.7493 23.6933 11.7493H18.2979C17.9886 11.7493 17.7379 11.4986 17.7379 11.1893Z"
      fill="currentColor"
    />
    <path
      d="M16.1347 11.9224C16.5396 11.9224 16.8678 11.5942 16.8678 11.1893C16.8678 10.7844 16.5396 10.4562 16.1347 10.4562C15.7298 10.4562 15.4016 10.7844 15.4016 11.1893C15.4016 11.5942 15.7298 11.9224 16.1347 11.9224Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.294 9.01062C14.7417 9.01062 14.294 9.45833 14.294 10.0106V15.1358C14.294 15.6881 14.7417 16.1358 15.294 16.1358H24.9828C25.5351 16.1358 25.9828 15.6881 25.9828 15.1358V10.0106C25.9828 9.45834 25.5351 9.01062 24.9828 9.01062H15.294ZM24.9828 9.76062H15.294C15.1559 9.76062 15.044 9.87255 15.044 10.0106V15.1358C15.044 15.2739 15.1559 15.3858 15.294 15.3858H24.9828C25.1209 15.3858 25.2328 15.2739 25.2328 15.1358V10.0106C25.2328 9.87255 25.1209 9.76062 24.9828 9.76062Z"
      fill="currentColor"
    />
  </svg>
);
export default Design;
