import { useEffect } from 'react';
import useMutate from './actions/mutate';
import useRestore from './actions/restore';
import useDesignStore from './useDesignStore';

export default function useActions() {
  const designId = useDesignStore((state) => state.design?.uuid);
  const initial = useDesignStore((state) => state.initial);
  const level = useDesignStore((state) => state.level);
  const revision = useDesignStore((state) => state.revision);
  const setActions = useDesignStore((state) => state.setActions);
  const setFocus = useDesignStore((state) => state.setFocus);

  const mutate = useMutate(designId, level?.type);
  useEffect(() => {
    setActions({ mutate });
  }, [mutate]);

  const restore = useRestore(revision, level);
  useEffect(() => {
    setActions({ restore });
  }, [restore]);

  useEffect(() => {
    setActions({ home: () => setFocus(initial) });
  }, [initial]);
}
