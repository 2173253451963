export const TIMEFRAME = Object.freeze({
  DAYS: 0,
  WEEKS: 1,
  MONTHS: 2,
  YEARS: 3,
});

const calculateMonthsAgo = ({
  collectionMonth,
  collectionYear,
  month,
  year,
}) => {
  const monthAdjustment = (year - collectionYear) * 12;

  return month + monthAdjustment - collectionMonth;
};

/**
 * @param {Object} collection
 * @param {String}
 * @return {Object}
 */
export default function groupByDateResolution(collection, property) {
  const date = new Date();
  const month = date.getMonth();
  const year = date.getYear();

  const dateResolution = {
    [TIMEFRAME.DAYS]: {},
    [TIMEFRAME.WEEKS]: {},
    [TIMEFRAME.MONTHS]: {},
    [TIMEFRAME.YEARS]: {},
  };

  collection.forEach((item) => {
    const collectionDate = new Date(item[property]);
    const collectionMonth = collectionDate.getMonth();
    const collectionYear = collectionDate.getYear();

    const daysAgo = Math.ceil((date - collectionDate) / (1000 * 60 * 60 * 24));
    const weeksAgo = Math.floor(
      (date - collectionDate) / (1000 * 60 * 60 * 24 * 7)
    );

    const yearsAgo = Math.floor(daysAgo / 365);

    const monthsAgo = calculateMonthsAgo({
      collectionMonth,
      collectionYear,
      month,
      year,
    });

    if (item.is_latest) {
      dateResolution[TIMEFRAME.DAYS][daysAgo] = item;
      return;
    }

    if (daysAgo < 7 && !dateResolution[TIMEFRAME.DAYS][daysAgo]) {
      dateResolution[TIMEFRAME.DAYS][daysAgo] = item;
    }

    if (
      weeksAgo > 0 &&
      weeksAgo < 4 &&
      !dateResolution[TIMEFRAME.WEEKS][weeksAgo]
    ) {
      dateResolution[TIMEFRAME.WEEKS][weeksAgo] = item;
    }

    if (
      monthsAgo > 0 &&
      monthsAgo < 11 &&
      !dateResolution[TIMEFRAME.MONTHS][monthsAgo]
    ) {
      dateResolution[TIMEFRAME.MONTHS][monthsAgo] = item;
    }

    if (yearsAgo > 0 && !dateResolution[TIMEFRAME.YEARS][yearsAgo]) {
      dateResolution[TIMEFRAME.YEARS][yearsAgo] = item;
    }
  });

  return dateResolution;
}
