const pattern = /[^\d]+/g;

/**
 * @param {String} value
 * @param {Object} options
 * @param {Number} options.max
 * @returns {Number}
 */
export function blur(value, options = {}) {
  value = parseInt(change(value)) || 0;

  if (Number.isFinite(options.max)) {
    value = Math.min(value, options.max);
  }

  return value;
}

/**
 * @param {String} value
 * @returns {String}
 */
export function change(value) {
  if (!value) {
    return '';
  }

  return value.replace(pattern, '');
}

/**
 * @param {Number} value
 * @returns {String}
 */
export function format(value) {
  return String(value);
}
