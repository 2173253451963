import { useMemo } from 'react';
import * as Auth from '@pkg/auth';
import { Designs } from '@pkg/entities';
import { Obj } from '@pkg/utils';
import * as Organisations from '../organisations';
import * as Policies from '../policies';
import * as Can from './can';
import * as Get from './get';
import * as Use from './use';
import useCheckActions from './useCheckActions';
import useRelationships from './useRelationships';

export const DEFAULT_ACCESS = Object.freeze({
  isReady: false,
  isRestricted: true,
  can: () => false,
  canMany: () => false,
  canCreate: () => false,
  features: {},
  model: {
    budget: false, // can see budgets
    edit: false, // can modify design
    manage: false, // can manage organisation
    publish: false, // can publish the current scenario design
    restore: false, // can restore to the current historical event
  },
});

/**
 * @param {Object} design
 * @param {Object} [snapshot]
 * @param {Boolean} [isHistorical]
 * @returns {Object}
 */
export default function useAccess(design, snapshot, isHistorical = true) {
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const organisation = Auth.useStore((state) => state.organisation);
  const me = Auth.useStore((state) => state.me);
  const assignedFeatures = Auth.useStore((state) => state.features);

  const organisationId = organisation?.uuid;
  const designId = design?.uuid;
  const isScenario = design?.is_scenario ?? false;

  const main = Designs.useStore((state) => state.main);
  const hydrated = Designs.useHydratedDesign(main);
  const mainSnapshot = hydrated?.latest?.snapshot;
  const combinedSnapshot = Use.combined(snapshot, mainSnapshot, design?.scope);

  const { lookup, isLoading } = Policies.usePolicies();
  const level = Organisations.get.policyLevel(me, organisationId);
  const relationships = useRelationships(me, design, hydrated, snapshot);

  const features = Organisations.get.features(assignedFeatures);
  const canCreate = Can.useCreate(
    me,
    level,
    isHistorical,
    combinedSnapshot,
    lookup
  );
  const canGroupTheseRoles = Can.useGroupRoles(
    me,
    level,
    isHistorical,
    relationships
  );

  return useMemo(() => {
    if (!isAuthenticated) {
      return { ...DEFAULT_ACCESS, isReady: true };
    }

    const permissions = {
      ...Get.permissions(designId),
      ...Organisations.get.permissions(organisationId),
    };

    const isReady = !isLoading && !Obj.isEmpty(relationships.current);
    const model = Get.model(me, permissions, isScenario, isHistorical);
    const check = useCheckActions(level, isScenario, lookup, relationships);
    const { one, many } = Can.useAct(model, check, isHistorical);

    return Object.freeze({
      isReady,
      isRestricted: organisation.is_restricted,
      can: one,
      canMany: many,
      canCreate,
      canGroupTheseRoles,
      features,
      model,
    });
  }, [lookup, relationships]);
}
