/**
 * @param {Array} a
 * @param {Array} b
 * @return {Array}
 */
export default function diff(a, b) {
  const diff = new Set();

  a.forEach((i) => {
    if (!b.includes(i)) {
      diff.add(i);
    }
  });

  b.forEach((i) => {
    if (!a.includes(i)) {
      diff.add(i);
    }
  });

  return Array.from(diff);
}
