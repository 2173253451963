/**
 * Returns a list of entity IDs that match the given name
 * @param {Object} user
 * @param {String} context
 * @param {String} name
 * @returns {Array}
 */
export default function getEntitiesByRoleName(user, context, name) {
  if (!Array.isArray(user?.access?.roles[context])) {
    return [];
  }

  return user.access.roles[context].map((role) => {
    if (role.startsWith(name)) {
      return role.split('.').pop();
    }
  });
}
