import aggregateActivity from '@/shared/utils/aggregateActivity';
import { DesignEntity, TagColor } from '@/lib/enums';
import TagCategory from '@/components/DashboardContainer/enums/TagCategory';

const aggregateTag = ({ snapshotEntityMap, existing, activity }) => {
  const role = activity?.owner_uuid
    ? snapshotEntityMap?.get(DesignEntity.ROLE).get(activity?.owner_uuid)
    : null;

  const rolePercentage =
    role && role?.__metrics ? activity.hours / role.__metrics?.hours : 0;

  const budget = role && rolePercentage > 0 ? rolePercentage * role.budget : 0;
  const fte = role && rolePercentage > 0 ? rolePercentage * role.fte : 0;

  const group = role
    ? snapshotEntityMap?.get(DesignEntity.GROUP).get(role?.group_uuid)
    : null;

  if (existing) {
    existing[DesignEntity.ACTIVITY]?.set(
      activity.library_uuid,
      aggregateActivity({
        activity,
        entity: activity,
        existing: existing[DesignEntity.ACTIVITY].get(activity.library_uuid),
        group,
        role,
        uuid: activity.uuid,
        storeEntity: false,
      })
    );

    if (group) {
      existing[DesignEntity.GROUP].set(
        group.uuid,
        aggregateActivity({
          activity,
          entity: group,
          existing: existing[DesignEntity.GROUP].get(group?.uuid),
          group,
          role,
          uuid: group.uuid,
          storeEntity: false,
        })
      );
    }

    return {
      ...existing,
      __aggregateHours: existing.__aggregateHours + activity.hours,
      __aggregateBudget: existing.__aggregateBudget + budget,
      __aggregateFTE: existing.__aggregateFTE + fte,
    };
  }

  const groupMap = new Map();

  if (group) {
    groupMap.set(
      group.uuid,
      aggregateActivity({
        activity,
        entity: group,
        group,
        role,
        uuid: group.uuid,
        storeEntity: false,
      })
    );
  }

  return {
    __aggregateHours: activity.hours,
    __aggregateBudget: budget,
    __aggregateFTE: fte,
    [DesignEntity.ACTIVITY]: new Map([
      [
        activity.library_uuid,
        aggregateActivity({
          activity,
          entity: activity,
          group,
          role,
          uuid: activity.uuid,
          storeEntity: false,
        }),
      ],
    ]),
    [DesignEntity.GROUP]: groupMap,
  };
};

const mapActivitiesToTag = (activityList, snapshotEntityMap) => {
  const activityTagMap = {
    tags: new Map(),
    inverseTags: new Map(),
    __totalHours: 0,
  };

  activityList.forEach((activity) => {
    if (activity.owner_type !== DesignEntity.ROLE) {
      return;
    }

    // Increment the total activity hours.
    activityTagMap.__totalHours += activity.hours;

    const tags =
      activity.tags?.length > 0 ? activity.tags : [TagCategory.NOT_TAGGED];

    tags.forEach((tag) => {
      const existingTag = activityTagMap.tags.get(tag);

      activityTagMap.tags.set(
        tag,
        aggregateTag({
          snapshotEntityMap,
          existing: existingTag,
          activity,
        })
      );
    });
  });

  return activityTagMap;
};

const mapCategory = ({ categoryId, tagMap, tagList, name }) => {
  const categoryMap = {
    name,
    tags: new Map(),
  };

  if (categoryId === TagCategory.NOT_TAGGED) {
    const noTags = tagMap.tags.get(TagCategory.NOT_TAGGED);

    if (!noTags || noTags[DesignEntity.ACTIVITY].size === 0) {
      return;
    }

    categoryMap.tags.set(TagCategory.NOT_TAGGED, {
      ...noTags,
      uuid: TagCategory.NOT_TAGGED,
      categoryId,
      name: 'Not tagged',
      color: TagColor.PINK_REGULAR,
    });

    return categoryMap;
  }

  tagList.forEach((tagItem) => {
    if (!tagItem.uuid || tagItem.is_disabled) {
      return;
    }

    const tag = tagMap.tags.get(tagItem.uuid);

    if (!tag) {
      return;
    }

    categoryMap.tags.set(tagItem.uuid, {
      ...tag,
      uuid: tagItem.uuid,
      categoryId,
      name: tagItem.name,
      color: tagItem.color,
      description: tagItem.description,
      order: tagItem.order,
    });
  });

  if (categoryMap.tags.size) {
    return categoryMap;
  }
};

const mapTagsToTagCategory = (tagMap, orderedTags) => {
  const tagCategoryMap = {
    totalHours: tagMap.__totalHours,
    category: new Map(),
  };

  // Beamible tags.
  tagCategoryMap.category.set(
    TagCategory.SYSTEM,
    mapCategory({
      categoryId: TagCategory.SYSTEM,
      name: 'Beamible',
      tagMap,
      tagList: Object.values(orderedTags?.system?.primary?.tags),
    })
  );

  // Primary tags.
  tagCategoryMap.category.set(
    TagCategory.PRIMARY,
    mapCategory({
      categoryId: TagCategory.PRIMARY,
      name: orderedTags.custom?.primary?.name,
      tagMap,
      tagList: Object.entries(orderedTags?.custom?.primary?.tags)
        .filter(([key]) => key !== '__order')
        .map(([, values]) => values),
    })
  );

  // Not tagged.
  tagCategoryMap.category.set(
    TagCategory.NOT_TAGGED,
    mapCategory({
      categoryId: TagCategory.NOT_TAGGED,
      name: 'Not tagged',
      tagMap,
    })
  );

  // Custom tags.
  Object.entries(orderedTags?.custom)
    .filter(([key]) => key !== '__order' && key !== 'primary')
    .forEach(([key, categoryItem]) => {
      const category = mapCategory({
        categoryId: key,
        name: categoryItem.name,
        tagMap,
        tagList: Object.entries(categoryItem.tags)
          .filter(([key]) => key !== '__order')
          .map(([, values]) => values),
      });

      // We only receive a category object if activities were tagged with this
      // tags found inside this category.
      if (!category) {
        return;
      }

      tagCategoryMap.category.set(key, category);
    });

  return tagCategoryMap;
};

export default function mapActivitiesByTag(
  activityList,
  snapshotEntityMap,
  orderedTags
) {
  const tagMap = mapActivitiesToTag(activityList, snapshotEntityMap);

  const tagCategoryMap = mapTagsToTagCategory(tagMap, orderedTags);

  return tagCategoryMap;
}
