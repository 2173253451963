import { ClientError } from '@/lib/enums';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import Objective from './Objective';
import Skeleton from './Skeleton';
import Title from './Title';

const Heading = () => {
  const error = useDesignStore((state) => state.error);
  const isHistorical = useDesignStore((state) => state.isHistorical);
  const isReady = useDesignStore((state) => state.isReady);
  const isScenario = useDesignStore((state) => state.isScenario);
  const level = useDesignStore((state) => state.level);
  const snapshot = useDesignStore((state) => state.snapshot);

  if (!isReady) {
    return <Skeleton />;
  }

  if (error === ClientError.NOT_AUTHORIZED) {
    return <Title>Not authorized</Title>;
  }

  if (error === ClientError.NOT_FOUND) {
    return <Title>Not found</Title>;
  }

  return (
    <>
      <Title isScenario={isScenario} isHistorical={isHistorical} level={level}>
        {snapshot?.name ?? ''}
      </Title>
      <Objective>{snapshot?.objective ?? ''}</Objective>
    </>
  );
};

export default Heading;
