import { ChipType } from '@/lib/enums';
import { palettes } from '@/lib/theme/tokens';

/**
 * @param {ChipType} type
 * @returns {Object}
 */
export default function entity(type) {
  switch (type) {
    case ChipType.ACTIVITY:
      return palettes.brand.grey;
    case ChipType.HISTORICAL:
      return palettes.brand.blue;
    case ChipType.GROUP:
      return palettes.brand.blue;
    case ChipType.LEAD:
    case ChipType.MANAGER:
      return palettes.brand.purple;
    case ChipType.LIVE:
      return palettes.brand.green;
    case ChipType.ORGANISATION:
      return palettes.brand.coral;
    case ChipType.ROLE:
      return palettes.brand.teal;
    case ChipType.SCENARIO:
      return palettes.brand.yellow;
    case ChipType.SUPPORTER:
      return palettes.brand.orange;
    default:
      return palettes.brand._navy;
  }
}
