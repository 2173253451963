import { useMemo } from 'react';
import * as Library from '../library';
import deriveProps from './deriveProps';

/**
 * @param {Object|Object[]} organisations
 * @param {Object} [options]
 * @return {Object|Object[]}
 */
export default function useDerivedProps(organisations, options = {}) {
  const library = Library.useLibrary();
  return useMemo(() => {
    const list = Array.isArray(organisations) ? organisations : [organisations];
    return list.map((o) => deriveProps(library, o, options));
  }, [library, organisations, options]);
}
