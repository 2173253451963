import { useSnackbar } from 'notistack';
import useMutate from './useMutate';

/**
 * @returns {Object}
 */
export default function useRemoveGroup(query) {
  const { enqueueSnackbar } = useSnackbar();
  const mutate = useMutate(query);

  /**
   * @param {Object} design_uuid
   * @param {Object} group_uuid
   * @param {String} name
   * @returns {Promise}
   */
  function removeRole(design_uuid, group_uuid, name) {
    enqueueSnackbar(`Removing "${name}".`);
    const now = Date.now();

    return mutate(design_uuid, {
      created_at: now,
      entities: {
        groups: {
          remove: [group_uuid],
        },
      },
    });
  }

  return removeRole;
}
