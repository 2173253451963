import Head from 'next/head';
import PropTypes from 'prop-types';

const MetaTitle = ({ title }) => {
  if (!title) {
    return null;
  }
  return (
    <Head>
      <title>{title} | Beamible</title>
    </Head>
  );
};

MetaTitle.propTypes = {
  title: PropTypes.string,
};

export default MetaTitle;
