import { EntityType } from '@/shared/enums';

export default function getPropertiesFromEntity({
  activity,
  entityType,
  group,
  role,
}) {
  if (entityType === EntityType.ACTIVITY) {
    return activity?.properties?.length ? activity.properties : [];
  }
  if (entityType === EntityType.ROLE) {
    return role?.properties?.length ? role.properties : [];
  }
  if (entityType === EntityType.GROUP) {
    return group?.properties?.length ? group.properties : [];
  }
}
