import { percent } from '@pkg/utils/numbers';

export default function sumUniquePercentages(params) {
  const { values } = params;

  // This id set is necessary for creating unique ids for pivot group columns
  // and tracking their unique values.
  const idSet = new Set();

  let denominator = 0;
  let numerator = 0;

  values.forEach((value) => {
    if (!idSet.has(value.colId)) {
      idSet.add(value.colId);
      denominator += value.denominator;
    }

    numerator += value.numerator;
  });

  const colId = idSet?.size ? [...idSet].join('-') : 'base';

  const percentage =
    !numerator || !denominator ? 0 : percent(numerator, denominator, 7);

  return {
    colId,
    numerator,
    denominator,
    percentage,
    toString: () => (percentage ? `${percentage.toFixed(2)}%` : '0%'),
  };
}
