import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Switch from '@mui/core/SwitchUnstyled';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import { color } from '@/lib/theme/tokens';

const ControlLabel = styled(FormControlLabel)({
  display: 'flex',
  flexDirection: 'row',
  margin: 0,
  '& .MuiFormControlLabel-label': {
    fontSize: 14,
    fontWeight: 500,
    marginLeft: 12,
  },
});

const Root = styled('div')({
  position: 'relative',
  width: 32,
  height: 16,
});

const Track = styled('div')(({ ownerState }) => ({
  backgroundColor: ownerState.checked
    ? color.core.blue
    : color.primary.grey[200],
  display: 'block',
  borderRadius: 14,
  height: '100%',
  transition: 'background 0.1s',
  width: '100%',
  zIndex: 0,
}));

const Thumb = styled('div')(({ ownerState }) => ({
  backgroundColor: color.core.white,
  display: 'block',
  borderRadius: 12,
  height: 12,
  left: ownerState.checked ? 18 : 2,
  top: 2,
  transition: 'left 0.1s',
  position: 'absolute',
  width: 12,
  zIndex: 1,
}));

const Input = styled('input')({
  backgroundColor: 'transparent',
  border: 'none',
  height: '100%',
  opacity: 0,
  position: 'absolute',
  width: '100%',
  zIndex: -1,
});

const SwitchInput = ({
  label = '',
  onChange,
  isLabelTop = false,
  sx,
  ...props
}) => {
  const [checked, setChecked] = useState(props.checked ?? false);

  const handleChange = (event) => {
    const checked = event.target.checked;
    setChecked(checked);
    onChange?.(event, checked);
  };

  useEffect(() => {
    setChecked(props.value);
  }, [props.checked]);

  let switchLabel = '';
  if (!isLabelTop) {
    switchLabel = label;
  }

  return (
    <FormControl sx={sx}>
      {isLabelTop && <FormLabel component="legend">{label}</FormLabel>}
      <ControlLabel
        {...props}
        control={
          <Switch
            aria-label={`${label} switch`}
            checked={checked}
            components={{ Root, Track, Thumb, Input }}
            tabIndex={0}
            onChange={handleChange}
          />
        }
        label={switchLabel}
      />
    </FormControl>
  );
};

SwitchInput.propTypes = {
  isLabelTop: PropTypes.bool,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

export default SwitchInput;
