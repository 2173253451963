import { useState, useEffect } from 'react';
import { entityChart } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import { useAggregateEntities } from '@/shared/hooks';

const useAggregateChartData = ({
  entityType,
  excludeSeparators,
  excludeUnmanaged,
  includeEmptySpans,
  includeStack,
  dimension,
  filter,
  sort,
  useComparison,
}) => {
  const [chartData, setChartData] = useState();
  const [metricTotals, setMetricTotals] = useState();

  const { entities: aggregateEntities, comparisonColor } = useAggregateEntities(
    {
      entityType,
      excludeUnmanaged,
      includeEmptySpans,
      order: dimension.order,
      filter,
      sort,
      useComparison,
    }
  );

  const entities = aggregateEntities?.[0];

  useEffect(() => {
    if (!entities?.metrics) {
      setChartData([]);
      return;
    }

    const chartData = entityChart({
      excludeSeparators,
      entities,
      includeStack,
      sort,
    });

    if (useComparison) {
      const entities = aggregateEntities[1];

      const comparisonChartData = entityChart({
        excludeSeparators,
        entities,
        includeStack,
        sort,
        returnMap: true,
      });

      // @todo move this correlation of primary to comparison data earlier
      // useAggregateEntities / mapEntities could return objects with comparison datasets included
      chartData.forEach((row) => {
        if (row.type === ChartRowType.TITLE) {
          return;
        }

        // find matching row in comparison data
        const comparisonRow = comparisonChartData.get(row.id);
        row.comparison = comparisonRow
          ? {
              ...comparisonRow,
              difference:
                (row.data.percentage - comparisonRow?.data.percentage) /
                row.data.percentage,
              color: comparisonColor,
            }
          : { difference: null };
      });
    }

    setChartData(chartData);
    setMetricTotals(entities.metrics);
  }, [entities?.metrics, JSON.stringify(sort)]);

  return {
    chartData,
    metricTotals,
  };
};

export default useAggregateChartData;
