import Link from 'next/link';
import Stack from '@mui/material/Stack';
import { palettes, color } from '@/lib/theme/tokens';
import Box from '@/components/Box';
import Typography from '@/components/Typography';

const DEFAULT_COLORS = Object.freeze({
  primary: {
    font: color.core.white,
    border: 'transparent',
    background: palettes.brand.blue.shades[6],
    highlight: {
      background: palettes.brand.blue.shades[5],
      border: 'transparent',
      font: color.core.white,
    },
  },
  secondary: {
    font: palettes.brand.blue.shades[3],
    border: 'transparent',
    background: palettes.brand.blue.tints[1],
    highlight: {
      font: palettes.brand.blue.shades[5],
      border: 'transparent',
      background: palettes.brand.blue.tints[3],
    },
  },
  tertiary: {
    font: color.text.dark,
    border: 'transparent',
    background: 'transparent',
    highlight: {
      font: color.text.dark,
      border: 'transparent',
      background: color.primary.greyNew[100],
    },
  },
});

const LinkButton = ({
  colors,
  dataTestId,
  endIcon,
  path,
  startIcon,
  title,
  variant = 'primary',
}) => {
  const color = colors ? colors : DEFAULT_COLORS[variant];

  return (
    <Link data-test-id={dataTestId} href={path}>
      <Stack
        direction="row"
        sx={{
          color: color.font,
          background: color.background,
          boxShadow: color.border,
          borderRadius: '4px',
          px: 2,
          py: 1,
          transition: 'color 300ms, background-color 300ms, boxShadow 300ms',
          '&:hover': {
            color: color.highlight.font,
            background: color.highlight.background,
            boxShadow: color.highlight.border,
          },
        }}
      >
        {Boolean(startIcon) && (
          <Box
            sx={{
              backgroundColor: color.background,
              color: color.font,
              lineHeight: '1.6rem',
              mr: 1,
              width: '23px',
              height: '23px',
            }}
          >
            {startIcon}
          </Box>
        )}
        <Typography
          variant="span"
          sx={{
            fontSize: '0.875rem',
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        {Boolean(endIcon) && (
          <Box
            sx={{
              backgroundColor: color.font,
              color: color.background,
              borderRadius: 80,
              lineHeight: '1.6rem',
              ml: 1,
              width: '23px',
              height: '23px',
            }}
          >
            {endIcon}
          </Box>
        )}
      </Stack>
    </Link>
  );
};

export default LinkButton;
