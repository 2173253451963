import { D3Chart, VerticalStackChart } from '@/organisms/charts';

const ActivityStackedBarChart = ({
  chartData,
  fullscreen,
  metric,
  metricTotals,
  sort,
}) => {
  return (
    <D3Chart height={fullscreen ? 500 : 460} id="property-insights" noScrollY>
      <VerticalStackChart
        initialData={chartData}
        metric={metric}
        metricTotals={metricTotals}
        sort={sort}
      />
    </D3Chart>
  );
};

export default ActivityStackedBarChart;
