import { useEffect, useMemo, useState } from 'react';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import collaboratorAccessOptions from '@/shared/utils/collaboratorAccessOptions';
import * as Auth from '@pkg/auth';
import { Designs, People } from '@pkg/entities';
import { Action, Property } from '@/lib/enums';

/**
 * Provides functions to add and remove collaborators from the design.
 */
const useCollaborators = () => {
  const { me } = Auth.useStore();
  const { scenario, access } = useScenarioContext();
  const mainDesign = Designs.useStore((state) => state.main);
  const { map: scenarioDesigns } = Designs.useStore((state) => state.scenarios);
  const [collaborators, setCollaborators] = useState([]);
  const onAdd = Designs.mutations.useAddCollaborator();
  const onRemove = Designs.mutations.useRemoveCollaborator();
  const design =
    mainDesign?.uuid === scenario?.details?.designId
      ? mainDesign
      : scenarioDesigns?.get(scenario?.details?.designId);
  const [collaboratorOptionMap, setCollaboratorOptionMap] = useState(new Map());
  const [collaboratorOptions, setCollaboratorOptions] = useState([]);

  const permission = useMemo(() => {
    if (!scenario?.entity || !design) {
      return;
    }

    const { uuid, __type } = scenario.entity;

    // We need to add the design uuid to the latest property object for
    // permissions to be correctly determined.
    const accessDesign = { ...design };
    accessDesign.latest = {
      ...accessDesign.latest,
      design: { uuid: design.uuid },
    };

    return {
      view:
        accessDesign?.is_scenario &&
        access.can(accessDesign, Property.COLLABORATORS, Action.READ),
      edit:
        accessDesign?.is_scenario &&
        access.can(accessDesign, Property.COLLABORATORS, Action.EDIT),
      publish: access.can({ uuid, __type }, null, Action.PUBLISH, {
        useMain: true,
      }),
      owner: me?.uuid === accessDesign?.owner?.uuid,
    };
  }, [JSON.stringify(design?.collaborators)]);

  // Sets the collaborators and their access type when the design collaborators
  // are updated.
  useEffect(() => {
    const scenarioCollaborators = scenario?.details?.collaborators;
    if (!scenarioCollaborators || !collaboratorOptionMap.size) {
      setCollaborators([]);
      return;
    }

    const collaborators = scenarioCollaborators.map((collaborator) => {
      const accessKey = People.access.getRoleByUuid(
        collaborator,
        'design',
        scenario?.details?.designId
      );

      return {
        ...collaborator,
        accessType: collaboratorOptionMap.get(accessKey),
      };
    });

    setCollaborators(collaborators);
  }, [
    JSON.stringify(scenario?.details?.collaborators),
    JSON.stringify(collaboratorOptions),
  ]);

  // Sets the collaborator access type options based on the design id.
  useEffect(() => {
    if (!scenario?.details?.designId) {
      return;
    }

    const optionMap = collaboratorAccessOptions(scenario.details?.designId);
    const options = [];

    optionMap.forEach((option) => {
      if (option.value === 'design.noAccess') {
        return;
      }
      options.push(option);
    });

    setCollaboratorOptionMap(optionMap);
    setCollaboratorOptions(options);
  }, [scenario?.details?.designId]);

  /**
   * Adds a person to the list of design collaborators.
   *
   * @param {Object} person
   * @param {Object} option
   */
  const addCollaborator = async (person, option) => {
    if (!person) {
      return;
    }

    const designId = scenario?.details?.designId;

    const level = {
      uuid: scenario?.entity?.uuid,
      type: scenario?.entity?.__type,
    };

    await onAdd(
      designId,
      person.uuid,
      option.value,
      access.model.manage,
      level
    );
  };

  /**
   * Removes a person from the list of collaborators.
   *
   * @param {Object} person
   */
  const removeCollaborator = (person) => {
    if (!person) {
      return;
    }

    onRemove(scenario?.details?.designId, person.uuid);
  };

  /**
   * Updates the person's collaborator access.
   *
   * @param {Object} person
   * @param {Object} option
   */
  const updateCollaborator = (person, option) => {
    if (option.value === 'design.noAccess') {
      removeCollaborator(person);
      return;
    }

    addCollaborator(person, option);
  };

  return {
    collaborators,
    collaboratorOptionMap,
    collaboratorOptions,
    addCollaborator,
    permission,
    removeCollaborator,
    updateCollaborator,
  };
};

export default useCollaborators;
