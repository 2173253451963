import can from '../can';

/**
 * @param {Object} user
 * @param {Object} permissions
 * @returns {Object}
 */
export default function canMany(user, permissions) {
  const access = {};

  Object.entries(permissions).forEach(([key, permission]) => {
    access[key] = can(user, permission);
  });

  return access;
}
