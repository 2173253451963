import * as Colors from '@/atoms/colors';
import Typography from '@/components/Typography';

const Subtitle = ({ children, color = 'primary', overrideStyles }) => {
  return (
    <Typography
      variant="body1"
      sx={{
        color: Colors.Base.font[color],
        fontSize: '0.875rem',
        fontWeight: 600,
        lineHeight: '1rem',
        textTransform: 'uppercase',
        margin: '16px 0',
        ...overrideStyles,
      }}
    >
      {children}
    </Typography>
  );
};

export default Subtitle;
