import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';

export default function updatedManagerAction({
  comparisonScenario,
  originalRole,
  role,
}) {
  const action = {
    type: ActionType.UPDATED_MANAGER,
    [EntityType.ROLE]: new Map(),
    metrics: defaultMetrics(comparisonScenario),
  };

  const roleProperties = {};

  if (role.__manager !== originalRole.__manager) {
    roleProperties.manager = {
      from: originalRole.__manager,
      to: role.__manager,
    };
  }
  if (role.group_uuid !== originalRole.group_uuid) {
    roleProperties.group = {
      from: originalRole.group_uuid,
      to: role.group_uuid,
    };
  }

  action[EntityType.ROLE].set(role.uuid, { props: roleProperties });
  action.metrics[1].moved += 1;

  return action;
}
