import { TaggableType } from '@/lib/enums';

const TYPES = ['system', 'custom'];

export const DEFAULT_OPTIONS = Object.freeze({
  [TaggableType.GROUP]: new Array(),
  [TaggableType.ROLE]: new Array(),
  [TaggableType.ACTIVITY]: new Array(),
  '*': new Array(),
});

/**
 * Get group label
 */
const getGroup = (name, isSystem) => {
  if (isSystem && name === 'Primary') {
    return 'Beamible';
  }

  if (isSystem) {
    return `Beamible - ${name}`;
  }

  return name;
};

/**
 * @param {Object} ordered
 */
export default function getOptions(ordered) {
  console.time('Tags.getOptions');
  const options = structuredClone(DEFAULT_OPTIONS);

  TYPES.forEach((key) => {
    const isSystem = key === 'system';
    const categories = ordered[key];

    categories.__order.forEach((key) => {
      const category = categories[key];

      category.tags.__order.forEach((key) => {
        const tag = structuredClone(category.tags[key]);
        tag.__group = getGroup(category.name, isSystem);

        options['*'].push(tag);
        tag.taggables.forEach((taggable) => {
          options[taggable].push(tag);
        });
      });
    });
  });

  console.timeEnd('Tags.getOptions');
  return options;
}
