import { useCallback } from 'react';
import { PolicyLevel, Relationship } from '@/lib/enums';

export default function useGroupRoles(me, level, isHistorical, relationships) {
  return useCallback(
    (roles) => {
      if (isHistorical) {
        return false;
      }

      // assume admins can create any groups
      if (level === PolicyLevel.ADMIN) {
        return true;
      }

      const isManager = Object.values(relationships?.current ?? {}).some(
        (r) => r === Relationship.MANAGER
      );

      if (!isManager) {
        return false;
      }

      for (const role of roles) {
        const relationship = relationships?.current[role.uuid];
        if (relationship !== Relationship.MANAGER) {
          return false;
        }
      }

      return true;
    },
    [me, isHistorical, relationships]
  );
}
