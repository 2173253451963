import { forwardRef } from 'react';
import { Colors } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { CircleBackgroundIcon } from '@/atoms/icons';
import IconButton from '@mui/material/IconButton';
import InformationIcon from '@pkg/icons/Information';

const InfoButton = forwardRef(function InfoButton(
  {
    color = 'light-blue',
    colorProps,
    onClick,
    Icon = <InformationIcon />,
    size = Size.SMALL,
  },
  ref
) {
  const colors = colorProps ?? Colors.button[color].contained.normal;

  return (
    <>
      <IconButton
        aria-label="More information"
        onClick={onClick}
        sx={{
          backgroundColor: 'transparent',
          opacity: 1,
          '&:hover': {
            backgroundColor: 'transparent',
            opacity: 0.8,
          },
        }}
        ref={ref}
      >
        <CircleBackgroundIcon colorProps={colors} icon={Icon} size={size} />
      </IconButton>
    </>
  );
});

export default InfoButton;
