import { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Text from './Text';

const PasswordInput = (props) => {
  const [visible, setVisible] = useState(false);

  return (
    <Text
      {...props}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => setVisible(!visible)}
            size="small"
            tabIndex={-1}
            variant="tertiary"
          >
            {visible ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      type={visible ? 'text' : 'password'}
    />
  );
};

PasswordInput.propTypes = Text.propTypes;

export default PasswordInput;
