import Skeleton from '@mui/material/Skeleton';

const HeadingSkeleton = () => (
  <>
    <Skeleton sx={{ mt: -1 }} height={32} width={256} />
    <Skeleton sx={{ mt: -0.5 }} height={16} width={192} />
  </>
);

export default HeadingSkeleton;
