import { useMemo } from 'react';
import { useQuery } from '@pkg/graphql';

const GET_TEMPLATES = /* GraphQL */ `
  query GetTemplates {
    me {
      organisation {
        templates {
          uuid
          type
          enabled
          snapshot {
            entities {
              roles {
                uuid
                title
                fte
                tags
              }
              activities {
                uuid
                library_uuid
                owner_uuid
                owner_type
                order
                hours
                tags
                updated_at
                created_at
              }
            }
          }
        }
      }
    }
  }
`;

const QUERY = Object.freeze([GET_TEMPLATES]);

export default function useTemplateQuery() {
  const { data, dataUpdatedAt } = useQuery({ query: QUERY });
  return useMemo(() => data?.me.organisation.templates, [dataUpdatedAt]);
}
