import { get } from '@pkg/utils/objects';

/**
 * @param {String} property
 * @return {Function}
 */
export default function alpha(property) {
  return (a, b) =>
    get(a, property, '')
      .toString()
      .localeCompare(get(b, property, ''))
      .toString();
}
