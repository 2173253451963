import { useMutation } from '@pkg/graphql';

const UPDATE_TEMPLATE = /* GraphQL */ `
  mutation UpdateTemplate($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_TEMPLATE,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Object} input
   * @param {String} input.uuid
   * @param {Object} input.snapshot
   * @param {Boolean} input.enabled
   * @returns {Promise}
   */
  async function updateTemplate(
    organisation_uuid,
    { uuid, snapshot, enabled }
  ) {
    return mutateAsync({ organisation_uuid, uuid, snapshot, enabled });
  }

  return updateTemplate;
}
