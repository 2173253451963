import { useEffect, useState } from 'react';
import { EntityType } from '@/shared/enums';
import { ScopeType } from '@/shared/enums';
import {
  usePropertyContext,
  useScenarioContext,
  useTagContext,
} from '@/shared/providers';
import { mapEntities } from '@/shared/utils';

const getFilterIds = (filter) => {
  return filter?.ids?.size ? [...filter.ids] : [];
};

const useAggregateEntities = ({
  entityType = EntityType.ACTIVITY,
  excludeUnmanaged,
  filter,
  includeEmptySpans,
  matrix,
  order,
  useComparison,
}) => {
  const {
    scenario,
    snapshotEntityMap,
    comparisonScenario,
    comparisonScenarioEntityMap,
  } = useScenarioContext();
  const { tagMap } = useTagContext();
  const { propertyMap } = usePropertyContext();

  const [entities, setEntities] = useState();

  const scopeFilter = filter[ScopeType.TAGS] ?? filter[ScopeType.ACTIVITIES];
  const ids = getFilterIds(scopeFilter);

  useEffect(() => {
    if (!scenario?.entity || !tagMap.size) {
      return;
    }

    const primaryEntities = mapEntities({
      entityType,
      excludeUnmanaged,
      filter: scopeFilter,
      includeEmptySpans,
      matrix,
      order,
      propertyMap,
      relationshipMap: scenario?.relationships,
      snapshotEntityMap,
      tagMap,
    });

    const entities = [primaryEntities];

    if (useComparison) {
      const comparisonEntities = mapEntities({
        entityType,
        excludeUnmanaged,
        filter: scopeFilter,
        matrix,
        order,
        propertyMap,
        relationshipMap: comparisonScenario?.relationships,
        snapshotEntityMap: comparisonScenarioEntityMap,
        tagMap,
      });

      entities.push(comparisonEntities);
    }

    setEntities(entities);
  }, [
    scenario?.entity?.uuid,
    scenario?.entity?.__uuid,
    scenario?.entity?.updated_at,
    useComparison,
    JSON.stringify(scenario?.entity?.__visible_metrics),
    JSON.stringify(matrix),
    tagMap,
    order,
    JSON.stringify(scopeFilter),
    JSON.stringify(ids),
  ]);

  return {
    entities,
    comparisonColor: comparisonScenario?.comparisonColor,
  };
};

export default useAggregateEntities;
