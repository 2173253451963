import { useMemo } from 'react';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const VerticalDate = ({ timestamp }) => {
  const { day, month } = useMemo(() => {
    const date = new Date(timestamp);
    const day = `0${date.getDate()}`.slice(-2);
    const month = date.toLocaleString('default', {
      month: 'short',
    });

    return { day, month };
  }, [timestamp]);

  return (
    <Stack alignItems="center" py={0.5} px={1}>
      <Paragraph
        size={Size.LARGE}
        overrideStyles={{
          fontWeight: 600,
          lineHeight: '1.25rem',
          mb: 0.25,
        }}
      >
        {day}
      </Paragraph>
      <Paragraph
        size={Size.X_SMALL}
        overrideStyles={{
          fontWeight: 500,
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
          mb: 0,
          textTransform: 'uppercase',
        }}
      >
        {month}
      </Paragraph>
    </Stack>
  );
};

export default VerticalDate;
