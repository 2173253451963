import { useMemo } from 'react';
import { useClasses } from '@pkg/hooks';
import { SubtitleColor } from '@/lib/theme/dashboardColors';
import { grey, brand } from '@/lib/theme/tokens/palettes';

const generalStyles = {
  root: {
    backgroundColor: '#FFF',
    overflow: 'none',
    position: 'relative',
  },
  svg: {
    position: 'relative',
    zIndex: 0,
  },
  label: {
    fill: SubtitleColor.text,
    fontSize: '0.8rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    pointerEvents: 'none',
  },
  group: {},
  circle: {
    transition: 'fill 300ms, opacity 500ms',
  },
  axisLabel: {
    fontSize: '1rem',
    fontWeight: 400,
  },
};

const selectBoxStyles = {
  root: {
    borderRadius: '4px',
    opacity: 0,
    transition: 'opacity 500ms',
    fill: brand.blue.main,
  },
  text: {
    fontWeight: 400,
    fontSize: '1.25rem',
    pointerEvents: 'none',
  },
  transition: 'fill 300ms',
  visible: {
    opacity: 1,
  },
};

const tooltipStyles = {
  root: {
    backgroundColor: grey.colors[900],
    borderRadius: 4,
    color: grey.colors[100],
    display: 'none',
    opacity: 0,
    padding: '4px 8px',
    position: 'absolute',
    transition: 'opacity 0.2s',
    zIndex: 1,
  },
  content: {
    margin: 0,
    padding: 0,
  },
  visible: {
    opacity: 1,
    display: 'block',
  },
};

export default function useStyles(custom) {
  const general = useClasses(generalStyles);
  const selectBox = useClasses(selectBoxStyles);
  const tooltip = useClasses(tooltipStyles);

  return useMemo(() => {
    const classes = {};
    Object.keys(general).forEach((key) => {
      classes[key] = [general[key], custom[key] ?? ''].filter(Boolean);
    });

    classes.selectBox = {};
    Object.keys(selectBox).map((key) => {
      classes.selectBox[key] = [
        selectBox[key],
        custom?.selectBox?.[key] ?? '',
      ].filter(Boolean);
    });

    classes.tooltip = {};
    Object.keys(tooltip).map((key) => {
      classes.tooltip[key] = [
        tooltip[key],
        custom?.tooltip?.[key] ?? '',
      ].filter(Boolean);
    });

    return classes;
  }, [general, selectBox, tooltip, custom]);
}
