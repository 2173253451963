import { EntityType } from '@/shared/enums';

const tagSummaryDimension = {
  id: `${EntityType.TAG}`,
  label: 'TAG',
  order: [EntityType.TAG],
};

const organisationTagDimensions = [
  {
    id: `${EntityType.GROUP}:${EntityType.ROLE}:${EntityType.ACTIVITY}:${EntityType.PERSON}`,
    label: 'Team > Role > Activity > Person',
    order: [
      EntityType.TAG,
      EntityType.GROUP,
      EntityType.ROLE,
      EntityType.ACTIVITY,
      EntityType.PERSON,
    ],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.PERSON}:${EntityType.ACTIVITY}`,
    label: 'Role > Person > Activity',
    order: [
      EntityType.TAG,
      EntityType.ROLE,
      EntityType.PERSON,
      EntityType.ACTIVITY,
    ],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.ACTIVITY}`,
    label: 'Role > Activity > Person',
    order: [
      EntityType.TAG,
      EntityType.ROLE,
      EntityType.ACTIVITY,
      EntityType.PERSON,
    ],
  },
  {
    id: `${EntityType.ACTIVITY}:${EntityType.ROLE}:${EntityType.GROUP}:${EntityType.PERSON}`,
    label: 'Activity > Role > Team > Person',
    order: [
      EntityType.TAG,
      EntityType.ACTIVITY,
      EntityType.ROLE,
      EntityType.GROUP,
      EntityType.PERSON,
    ],
  },
  {
    id: `${EntityType.ACTIVITY}:${EntityType.GROUP}:${EntityType.ROLE}`,
    label: 'Activity > Team > Role > Person',
    order: [
      EntityType.TAG,
      EntityType.ACTIVITY,
      EntityType.GROUP,
      EntityType.ROLE,
      EntityType.PERSON,
    ],
  },
];

const roleTagDimensions = [
  {
    id: `${EntityType.ROLE}`,
    label: 'Activity > Role',
    order: [EntityType.TAG, EntityType.ACTIVITY, EntityType.ROLE],
  },
];

const TagDimensions = {
  [EntityType.GROUP]: organisationTagDimensions,
  [EntityType.MANAGER]: organisationTagDimensions,
  [EntityType.ORGANISATION]: organisationTagDimensions,
  [EntityType.ROLE]: roleTagDimensions,
  [EntityType.TAG]: tagSummaryDimension,
};

export default TagDimensions;
