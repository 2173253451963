import { Children, cloneElement, useState } from 'react';
import { MenuButton } from '@/atoms/buttons';
import MuiMenu from '@mui/material/Menu';

const Menu = ({ children, Component, onDispatch, size }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {Component ? (
        cloneElement(Component, { onClick: handleClick })
      ) : (
        <MenuButton size={size} onClick={handleClick} />
      )}
      <MuiMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {Children.map(children, (child) => {
          if (child) {
            return cloneElement(child, {
              onClose: handleClose,
              onDispatch: onDispatch,
            });
          }
        })}
      </MuiMenu>
    </>
  );
};

export default Menu;
