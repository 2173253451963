import FlexSearch from 'flexsearch';
import { create } from 'zustand';
import { Obj } from '@pkg/utils';
import deriveProps from './deriveProps';
import getOptions, { DEFAULT_OPTIONS } from './get/options';
import getOrdered, { DEFAULT_ORDERED } from './get/ordered';

const InitialIndex = new FlexSearch.Index({
  tokenize: 'forward',
  context: true,
});

const INITIAL_STATE = Object.freeze({
  index: InitialIndex,
  isLoading: true,
  options: DEFAULT_OPTIONS,
  ordered: DEFAULT_ORDERED,
  categories: {
    list: [],
    map: new Map(),
  },
  tags: {
    list: [],
    map: new Map(),
  },
});

/**
 * @param {Index} index
 * @param {Map} mapped
 * @returns {Index}
 */
const updateIndex = (index, map) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!map.has(key)) {
        index.remove(key);
      }
    }
  }

  map.forEach(({ name }, key) => {
    if (!index.register?.[key]) {
      index.add(key, name);
    }
  });

  return index;
};

/**
 * @param {Object[]} tags
 * @param {Object[]} categories
 * @returns {Object}
 */
export const storeTags = (tags, categories, index = InitialIndex) => {
  const derived = deriveProps(tags, categories);
  const result = {
    categories: {
      list: derived.categories,
      map: new Map(),
    },
    tags: {
      list: derived.tags,
      map: new Map(),
    },
  };

  derived.categories.forEach((category) => {
    result.categories.map.set(category.uuid, category);
  });

  derived.tags.forEach((tag) => {
    result.tags.map.set(tag.uuid, tag);
  });

  result.ordered = getOrdered(result.tags.list, result.categories.list);
  result.options = getOptions(result.ordered);
  result.index = updateIndex(index, result.tags.map);

  return result;
};

/**
 * @type {Function}
 * @param {Function} [getter]
 * @returns {any}
 */
const useStore = create((set) => ({
  ...INITIAL_STATE,

  /**
   * Reset all state
   */
  reset: () => {
    console.debug('TagsStore.reset');
    return set(INITIAL_STATE);
  },

  /**
   * Set any state
   */
  set: ({ tags, categories }) =>
    set(({ index }) => storeTags(tags, categories, index)),

  /**
   * Set loading state
   */
  setLoading: (isLoading) => {
    console.debug('TagsStore.setLoading', isLoading);
    return set({ isLoading });
  },
}));

export default useStore;
