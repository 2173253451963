import { Paragraph } from '@/atoms/typography';

const InformationParagraph = ({ children }) => {
  return (
    <Paragraph size="small" overrideStyles={{ lineHeight: '1.25rem', mb: 1.5 }}>
      {children}
    </Paragraph>
  );
};

export default InformationParagraph;
