/**
 * @param {Object} object
 * @param {String} path
 * @param {any|undefined} fallback
 * @returns {any|undefined}
 */
export default function get(object, path, fallback) {
  const keys = path.split('.');
  if (keys.length === 0) {
    return fallback;
  }

  let value = object;
  for (let index = 0; index < keys.length; index++) {
    const key = keys[index];
    value = value[key];

    if (value === undefined || value === null) {
      if (index !== keys.length - 1) {
        return fallback;
      }

      break;
    }
  }

  return value === undefined ? fallback : value;
}
