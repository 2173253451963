import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';

const REMOVE_FLOW_TEMPLATE = /* GraphQL */ `
  mutation RemoveFlowTemplate($input: RemoveFlowTemplateInput!) {
    removeFlowTemplate(input: $input)
  }
`;

/**
 * @return {Function}
 */
export default function useRemove() {
  const { mutateAsync } = useMutation({ mutation: REMOVE_FLOW_TEMPLATE });

  const database = useDatabase();
  const { remove } = useStore();

  /**
   * Optimistically remove flow template from storage
   * @todo handle failure
   */
  async function optimisticRemove(flowTemplateId) {
    remove(flowTemplateId);
    await database.flowTemplates.delete(flowTemplateId);
  }

  /**
   * @param {String} flowTemplateId
   * @returns {Promise}
   */
  async function removeFlowTemplate(flowTemplateId) {
    await optimisticRemove(flowTemplateId);
    return mutateAsync({ uuid: flowTemplateId });
  }

  return useCallback(removeFlowTemplate, [mutateAsync, database, remove]);
}
