import * as Colors from '@/atoms/colors';
import Grow from '@mui/material/Grow';
import { styled } from '@mui/styles';
import Box from '@/components/Box';
import Typography from '@/components/Typography';

const CardContainer = styled('div')({
  borderRadius: 4,
  backgroundColor: Colors.Card.fill.backgroundColor,
  color: Colors.Card.fill.color,
  padding: 16,
});

const FilledCard = ({ title, children }) => {
  return (
    <Grow in={true} timeout={500}>
      <Box>
        {Boolean(title) && (
          <Typography
            variant="h5"
            sx={{
              fontWeight: 300,
              fontSize: '1.2rem',
              mb: 1,
            }}
          >
            {title}
          </Typography>
        )}
        <CardContainer>{children}</CardContainer>
      </Box>
    </Grow>
  );
};

export default FilledCard;
