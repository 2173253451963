import { useListen } from '@pkg/broadcasting';
import useObservable from '@pkg/broadcasting/useObservable';

export default function useNotify(uuid, listeners = []) {
  const channelType = 'PrivateChannel';
  let channelName;

  if (uuid) {
    channelName = `User.notify.${uuid}`;
  }

  const notify = useObservable(channelName, listeners);

  const handleEvent = (event, data) => {
    notify(event, data);
  };

  useListen({ channelName, channelType }, handleEvent);
}
