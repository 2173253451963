import Stack from '@mui/material/Stack';
import { ChipType } from '@/lib/enums';
import EntityChip from '@/components/EntityChip/EntityChip';
import Typography from '@/components/Typography';

const Title = ({ children, isScenario, isHistorical, level }) => (
  <Stack direction="row" alignItems="center" spacing={1} sx={{ mb: 0.5 }}>
    <Typography
      component="h1"
      sx={{ fontSize: '1.125rem', lineHeight: '1.25rem' }}
    >
      {children}
    </Typography>
    <EntityChip type={level?.type} />

    {isScenario && <EntityChip type={ChipType.SCENARIO} />}
    {isHistorical && <EntityChip type={ChipType.HISTORICAL} />}
  </Stack>
);

export default Title;
