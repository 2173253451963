import * as Can from '../can';

/**
 * @param {Object} me
 * @param {Object} permissions
 * @returns {Object}
 */
export default function getModel(me, permissions) {
  return {
    budget: Can.budget(me, permissions),
    edit: Can.edit(me, permissions),
    manage: Can.manage(me, permissions),
  };
}
