import Box from '@/components/Box';
import Image from '@/components/Image';
import Typography from '@/components/Typography';

const DEFAULT_TITLE = 'Page not found.';
const DEFAULT_MESSAGE = (
  <span>
    Sorry, we can't find the page you were looking for.
    <br />
    It may not exist or was entered incorrectly.
  </span>
);

const NotFound = ({
  title = DEFAULT_TITLE,
  message = DEFAULT_MESSAGE,
  children,
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    width="100%"
  >
    <Image
      alt="Page not found"
      src="/infographics/not-found.png"
      sx={{
        maxWidth: 320,
        display: 'block',
      }}
    />
    <Typography color="primary" variant="h4" align="center" gutterBottom>
      {title}
    </Typography>
    <Typography variant="subtitle1" align="center" gutterBottom>
      {message}
    </Typography>
    {children}
  </Box>
);

export default NotFound;
