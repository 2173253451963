import { useCallback } from 'react';
import publishToManager from './reducers/managers/publishExisting';
import useMutate from './useMutate';

export default function usePublishManager() {
  const mutate = useMutate();

  /**
   * @param {String} designId
   * @param {Object} input
   * @param {Object} input.from
   * @param {Object} input.from.snapshot
   * @param {String} input.from.uuid
   * @param {Object} input.to
   * @param {Object} input.to.snapshot
   * @param {String} input.to.uuid
   * @returns {Promise}
   */
  async function publishManager(designId, input) {
    const mutation = publishToManager(input);
    return await mutate(designId, mutation);
  }

  return useCallback(publishManager, [mutate]);
}
