import { InfoButton } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Popover } from '@/molecules/modals';
import Box from '@mui/material/Box';
import { Position } from '@/lib/enums';
import { brand, core } from '@/lib/theme/tokens/palettes';

const InformationTip = ({
  children,
  position = Position.TOP,
  maxWidth = 480,
  size = Size.X_SMALL,
}) => {
  return (
    <Popover
      Component={
        <InfoButton
          size={size}
          colorProps={{
            color: core.white,
            backgroundColor: brand.yellow.main,
          }}
        />
      }
      position={position}
    >
      <Box
        px={2}
        py={2}
        maxWidth={maxWidth}
        sx={{
          boxShadow: `inset 4px 0 0 ${brand.yellow.main}`,
        }}
      >
        {children}
      </Box>
    </Popover>
  );
};

export default InformationTip;
