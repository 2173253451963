import Link from 'next/link';
import { Tooltip } from '@/molecules/modals';
import AccountTreeIcon from '@mui/icons-material/AccountTreeSharp';
import CampaignIcon from '@mui/icons-material/Campaign';
import BookIcon from '@mui/icons-material/CollectionsBookmark';
import HomeIcon from '@mui/icons-material/HomeSharp';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import Stack from '@mui/material/Stack';
import { useAccess } from '@pkg/access/organisations';
import config from '@/lib/config';
import { Feature } from '@/lib/enums';

const PrimaryNavigationItems = ({ isAdmin }) => {
  const access = useAccess();
  const hasDesignFeature = access.features[Feature.ORGANISATION_DESIGN];

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Tooltip title="Home">
        <Link href="/dashboard">
          <HomeIcon />
        </Link>
      </Tooltip>
      {hasDesignFeature && (
        <Tooltip title="Organisation">
          <Link
            name="Diagram"
            href={{
              pathname: config.PATHS.DESIGN.main,
              query: { view: 'DIAGRAM' },
            }}
          >
            <AccountTreeIcon />
          </Link>
        </Tooltip>
      )}
      <Tooltip title="Scenarios">
        <Link href={config.PATHS.DESIGN.scenarios}>
          <SquareFootIcon />
        </Link>
      </Tooltip>
      {isAdmin && (
        <>
          <Tooltip title="Library">
            <Link href={config.PATHS.LIBRARY.index}>
              <BookIcon />
            </Link>
          </Tooltip>
          <Tooltip title="Campaigns">
            <Link href="/campaigns">
              <CampaignIcon />
            </Link>
          </Tooltip>
        </>
      )}
    </Stack>
  );
};

export default PrimaryNavigationItems;
