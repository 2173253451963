import { useCallback, useEffect, useState } from 'react';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import { TaggingFragment } from './fragments';

const GET_TAGS = /* GraphQL */ `
  query GetTags {
    me {
      organisation {
        ...TaggingFragment
      }
    }
  }

  ${TaggingFragment}
`;

export default function useRefresh() {
  const client = useClient();
  const database = useDatabase();
  const [isRefreshing, setRefreshing] = useState(false);

  const refresh = useCallback(() => {
    if (!isRefreshing) {
      setRefreshing(true);
    }
  }, []);

  const handleRefresh = async () => {
    const { data } = await client.graphql({ query: GET_TAGS });

    if (!data.me) {
      setRefreshing(false);
      return;
    }

    const newCategories = data.me.organisation.tagCategories ?? [];
    const newTags = data.me.organisation.tags ?? [];

    const newCategorySet = new Set(newCategories.map(({ uuid }) => uuid));
    const newTagSet = new Set(newTags.map(({ uuid }) => uuid));

    const [categories, tags] = await Promise.all([
      database.tagCategories.toArray(),
      database.tags.toArray(),
    ]);

    const removedCategories = [];
    categories.forEach(({ uuid }) => {
      if (!newCategorySet.has(uuid)) {
        removedCategories.push(uuid);
      }
    });

    const removedTags = [];
    tags.forEach(({ uuid }) => {
      if (!newTagSet.has(uuid)) {
        removedTags.push(uuid);
      }
    });

    await Promise.all([
      database.hash.put({ key: 'tags', updated_at: Date.now() }),
      database.tagCategories.bulkDelete(removedCategories),
      database.tagCategories.bulkPut(newCategories),
      database.tags.bulkDelete(removedTags),
      database.tags.bulkPut(newTags),
    ]);

    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return {
    refresh,
    isRefreshing,
  };
}
