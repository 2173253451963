import { cloneElement, useEffect, useRef, useState } from 'react';
import {
  AlignmentInformation,
  PrioritisationInformation,
} from '@/molecules/information';
import { OutlineCard } from '@/organisms/cards';
import {
  QuadrantActivityListChart,
  QuadrantItem,
  QuadrantsChart,
} from '@/organisms/charts';
import { ActivitySuggestions } from '@/organisms/insights';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Slide from '@mui/material/Slide';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';
import { Divider } from '@/components/ContextParts';
import Typography from '@/components/Typography';
import { MatrixType } from './matrix';
import useActivitiesByQuadrant from './useActivitiesByQuadrant';

const Information = ({ id, entityType }) => {
  let Component = <PrioritisationInformation />;

  switch (id) {
    case MatrixType.ALIGNMENT:
      Component = <AlignmentInformation />;
      break;
    default:
      Component = <PrioritisationInformation />;
  }

  return cloneElement(Component, { entityType });
};

const QuadrantActivitiesCard = ({ id, entityType, matrix, title }) => {
  const {
    activeQuadrant,
    activities,
    axisLabels,
    quadrantMap,
    selectQuadrant,
    unallocatedActivities,
  } = useActivitiesByQuadrant(matrix);
  const { scenario } = useScenarioContext();
  const outlineCardRef = useRef(null);
  const [suggestMode, setSuggestMode] = useState(false);

  const suggestionsAllowed =
    id === MatrixType.PRIORITY &&
    activeQuadrant === 'BOTTOM_LEFT' &&
    entityType === DesignEntity.GROUP;

  const handleBack = () => {
    selectQuadrant(null);
  };

  const handleViewSuggestion = () => {
    setSuggestMode(true);
  };

  useEffect(() => {
    if (scenario?.entity?.uuid) {
      selectQuadrant(null);
    }
  }, [scenario?.entity?.uuid]);

  return (
    Boolean(quadrantMap) && (
      <OutlineCard
        title={title}
        headerAction={
          activeQuadrant && (
            <Button onClick={handleBack} startIcon={<ArrowBackIcon />}>
              Back to quadrant
            </Button>
          )
        }
        information={
          <Information id={id} entityType={scenario?.entity?.__type} />
        }
      >
        <Box ref={outlineCardRef} sx={{ overflow: 'hidden' }}>
          {!activeQuadrant && (
            <Slide
              container={outlineCardRef.current}
              direction="right"
              in={!activeQuadrant}
              mountOnEnter
              timeout={400}
              unmountOnExit
            >
              <Box>
                <QuadrantsChart
                  activities={activities}
                  axisLabels={axisLabels}
                  id={id}
                  selectQuadrant={selectQuadrant}
                  activeQuadrant={activeQuadrant}
                  quadrantMap={quadrantMap}
                />
              </Box>
            </Slide>
          )}
          <Slide
            container={outlineCardRef.current}
            direction="left"
            in={Boolean(activeQuadrant)}
            mountOnEnter
            timeout={400}
            unmountOnExit
          >
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <QuadrantActivityListChart
                  activeQuadrant={activeQuadrant}
                  activities={activities}
                  quadrantMap={quadrantMap}
                  selectQuadrant={selectQuadrant}
                  showAllActivities={true}
                  unallocatedActivities={unallocatedActivities}
                />
              </Grid>
              <Grid item xs={6}>
                {!suggestMode && (
                  <>
                    <QuadrantItem
                      axisLabels={axisLabels}
                      position={activeQuadrant}
                      quadrant={quadrantMap.get(activeQuadrant)}
                      selected={true}
                      totalActivityHours={
                        quadrantMap.get(DesignEntity.ACTIVITY)?.__totalHours
                      }
                    />
                    {suggestionsAllowed && (
                      <>
                        <Divider />
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Button
                            variant="primary"
                            sx={{
                              mt: 2,
                              mb: 2,
                            }}
                            onClick={handleViewSuggestion}
                          >
                            View Suggestions (Beta)
                          </Button>
                          <Typography align="center">
                            Want to see how we can help you stop, reduce or
                            reallocate these activities?
                          </Typography>
                        </Box>
                      </>
                    )}
                  </>
                )}
                {suggestMode && (
                  <ActivitySuggestions
                    suggestMode={suggestMode}
                    setSuggestMode={setSuggestMode}
                  />
                )}
              </Grid>
            </Grid>
          </Slide>
        </Box>
      </OutlineCard>
    )
  );
};

export default QuadrantActivitiesCard;
