import { useEffect, useState, useMemo } from 'react';
import { ViewMode } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ModeOption from './ModeOption';
import ViewModeMap from './utils/ViewModeMap';

const ModeSelector = ({
  initial = ViewMode.DESIGN,
  modeMap = ViewModeMap,
  onClick,
}) => {
  const [selected, setSelected] = useState(initial);

  const options = useMemo(() => {
    return [...modeMap.values()].map((mode) => mode);
  }, [selected]);

  useEffect(() => {
    if (initial === selected) {
      return;
    }
    setSelected(initial);
  }, [initial]);

  const handleClick = (_, selectedModeId) => {
    setSelected(selectedModeId);
    onClick?.(selectedModeId);
  };

  return (
    options && (
      <Stack alignItems="center" direction="row" spacing={0.5}>
        {options.map((option) => (
          <Box key={option.label}>
            <ModeOption
              mode={option}
              onClick={handleClick}
              selected={selected}
            />
          </Box>
        ))}
      </Stack>
    )
  );
};

export default ModeSelector;
