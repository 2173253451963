import isObject from './isObject';

/**
 * @param {Object} value
 * @param {String[]} [keys]
 * @returns {Boolean}
 */
export default function includes(value, keys = []) {
  if (!isObject(value)) {
    return false;
  }

  return Object.keys(value).every((key) => keys.includes(key));
}
