import { DesignEntity, Visibility } from '@/lib/enums';
import {
  formatDesignData,
  formatGroupData,
  formatRoleData,
  getEntities,
} from './formatTableData';

/**
 * Takes an array of scenario maps and converts them into tabular data.
 *
 * @param {Array}
 * @return {Object}
 */
export default function formatRoleTableData({
  scenarioMaps,
  showBudget,
  propertyMap,
  skillMap,
  tagMap,
}) {
  const rows = [];

  scenarioMaps?.forEach((scenarioMap) => {
    const roles = scenarioMap.get(DesignEntity.ROLE);
    const groupList = [...scenarioMap.get(DesignEntity.GROUP).values()].filter(
      ({ __visibility }) => __visibility !== Visibility.NONE
    );
    const organisationHours = [...groupList.values()].reduce((prev, next) => {
      return (prev += next.__total_metrics.hours);
    }, 0);

    // We track the roles and groups so we can show entities that don't have
    // any activities associated with them.
    const groups = new Set(groupList.map(({ uuid }) => uuid));

    roles.forEach((role) => {
      if (role.__visibility === Visibility.NONE) {
        return;
      }

      const { design, group, groupLead, person } = getEntities({
        role,
        scenarioMap,
      });

      const rowData = {
        ...formatRoleData({
          showBudget,
          person,
          propertyMap,
          role,
          tagMap,
          skillMap,
        }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      };

      rows.push(rowData);
      groups.delete(group?.uuid);
    });

    // Add the groups that don't have any activities associated with them.
    groups.forEach((id) => {
      const group = scenarioMap?.get(DesignEntity.GROUP).get(id);
      const { design, groupLead } = getEntities({ group, scenarioMap });

      rows.push({
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      });
    });
  });

  return rows;
}
