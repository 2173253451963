import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {String[]} ids
 * @returns {Array}
 */
export default function remove(snapshot, ids) {
  const now = Date.now();

  const entities = snapshot.entities;
  const [activity] = Collections.whereInIds(entities.activities, ids);

  const role = Collections.findById(entities.roles, activity.owner_uuid);
  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    role.uuid
  );

  const index = roleActivities.findIndex(({ uuid }) => activity.uuid === uuid);
  roleActivities.splice(index, 1);

  const updatedSnapshot = {
    entities: {
      activities: roleActivities,
      roles: [{ ...role, updated_at: now }],
    },
  };

  return {
    uuid: role.__template_uuid,
    snapshot: updatedSnapshot,
  };
}
