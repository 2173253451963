const PresetType = Object.freeze({
  NONE: 'NONE',
  ACTIVITY: 'ACTIVITY',
  ACTIVITY_GROUP: 'ACTIVITY_GROUP',
  ROLE_SKILL: 'ROLE_SKILL',
  ROLE_GROUP: 'ROLE_GROUP',
  GROUP: 'GROUP',
  EXCLUDE_UNASSIGNED: 'EXCLUDE_UNASSIGNED',
  EXCLUDE_ASSIGNED: 'EXCLUDE_ASSIGNED',
});

export default PresetType;
