import { useMemo } from 'react';
import getReduced from './getReduced';

/**
 * @param {Object} snapshot
 * @param {Object} level
 * @returns {Object}
 */
export default function useReduced(snapshot, level) {
  return useMemo(() => {
    console.time('Snapshots.utils.useReduced');
    const reduced = getReduced(snapshot, level);
    console.timeEnd('Snapshots.utils.useReduced');
    return reduced;
  }, [snapshot, level]);
}
