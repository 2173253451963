import { MetricProperty } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { brand } from '@/lib/theme/tokens/palettes';

const VALUE_WIDTH = 110;

const BulkEditList = ({ entities, entityType, propertyType, height = 400 }) => {
  const formatOptions = MetricProperty[propertyType]?.formatOptions;

  return (
    <Box
      sx={{
        height,
        overflowY: 'auto',
        position: 'relative',
        boxShadow: `0 0 0 1px ${brand.grey.tints[2]}`,
        borderRadius: '4px',
      }}
    >
      <Stack
        direction="row"
        spacing={1}
        width="100%"
        justifyContent="space-between"
        px={2}
        py={1}
        sx={{
          position: 'sticky',
          left: 0,
          top: 0,
          backgroundColor: brand.lightBlue.tints[1],
          boxShadow: `0 1px 0 ${brand.lightBlue.tints[4]}, 0 1px 2px ${brand._navy.main}77`,
        }}
      >
        <Heading
          variant="h5"
          overrideStyles={{
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
        >
          {entityType}
        </Heading>
        <Stack direction="row" spacing={1}>
          <Box width={VALUE_WIDTH} sx={{ textAlign: 'center' }}>
            <Heading
              variant="h5"
              overrideStyles={{ textTransform: 'uppercase', fontWeight: 600 }}
            >
              Original
            </Heading>
          </Box>
          <Box width={VALUE_WIDTH} sx={{ textAlign: 'center' }}>
            <Heading
              variant="h5"
              overrideStyles={{ textTransform: 'uppercase', fontWeight: 600 }}
            >
              Updated
            </Heading>
          </Box>
        </Stack>
      </Stack>
      {entities.map((entity) => (
        <Stack
          direction="row"
          justifyContent="space-between"
          key={entity.id}
          spacing={1}
          width="100%"
          py={0.5}
          px={2}
          mb={0.5}
          sx={{
            '&:hover': {
              backgroundColor: brand.lightBlue.tints[1],
            },
          }}
        >
          <Paragraph overrideStyles={{ mb: 0 }}>{entity.description}</Paragraph>
          <Stack direction="row" spacing={1}>
            <Box width={VALUE_WIDTH} sx={{ textAlign: 'center' }}>
              <Heading variant="h4" overrideStyles={{ mb: 0 }}>
                {format(entity.oldValue, {
                  ...formatOptions,
                })}
              </Heading>
            </Box>
            <Box width={VALUE_WIDTH} sx={{ textAlign: 'center' }}>
              <Heading variant="h4" overrideStyles={{ fontWeight: 500, mb: 0 }}>
                {format(entity.newValue, {
                  ...formatOptions,
                })}
              </Heading>
            </Box>
          </Stack>
        </Stack>
      ))}
    </Box>
  );
};

export default BulkEditList;
