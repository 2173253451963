import * as Auth from '@pkg/auth';

const useCanSwitchViewModes = () => {
  const me = Auth.useStore((state) => state.me);
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const isRoleOnly = Auth.useStore((state) => state.me?.is_role_only);

  const canViewSwitcher = isAuthenticated && !isRoleOnly;

  return canViewSwitcher;
};

export default useCanSwitchViewModes;
