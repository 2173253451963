import { Arr } from '@pkg/utils';

/**
 * @param {Object} state
 * @param {Object[]} state.tags
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.name]
 * @param {String} [input.description]
 * @param {String} [input.color]
 * @returns {Array}
 */
export default function update({ tags }, input) {
  const { uuid, name, color, description, is_disabled, taggables } = input;

  const tag = tags.map.get(uuid);
  const isPrimary = ['primary', null].includes(tag.category_uuid);

  let isDifferent = false;
  const update = {
    uuid,
  };

  if (name && tag.name !== name) {
    update.name = name;
    isDifferent = true;
  }

  if (color && isPrimary && !tag.is_system && tag.color !== color) {
    update.color = color;
    isDifferent = true;
  }

  if (description && tag.description !== description) {
    update.description = description;
    isDifferent = true;
  }

  const isDisabledDefined = typeof is_disabled !== 'undefined';
  if (isDisabledDefined && tag.is_system && tag.is_disabled !== is_disabled) {
    update.is_disabled = is_disabled;
    isDifferent = true;
  }

  if (taggables && isPrimary) {
    if (Arr.diff(taggables, tag.taggables).length) {
      update.taggables = taggables;
      isDifferent = true;
    }
  }

  if (!isDifferent) {
    return null;
  }

  return {
    tags: {
      update: [update],
    },
  };
}
