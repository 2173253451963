import { useCallback } from 'react';
import { useClient } from '@pkg/client';
import { TaggingFragment } from '../fragments';

const MUTATE_TAGGING = /* GraphQL */ `
  mutation MutateTagging($input: MutateTaggingInput!) {
    mutateTagging(input: $input) {
      ...TaggingFragment
    }
  }

  ${TaggingFragment}
`;

/**
 * @param {any[]} query
 * @returns {Function}
 */
export default function useMutateTagging() {
  const client = useClient();

  /**
   * @param {String} organisation_uuid
   * @param {Object} mutation
   * @returns {Promise}
   */
  function mutate(organisation_uuid, mutation) {
    return client.graphql({
      query: MUTATE_TAGGING,
      variables: {
        input: {
          organisation_uuid,
          mutation,
        },
      },
    });
  }

  return useCallback(mutate, []);
}
