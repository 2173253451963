import { Num } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

const INITIAL_CACHE = { roleTotals: {}, groupTotals: {}, designTotal: 0 };

export default class TaggedActivity {
  cache = structuredClone(INITIAL_CACHE);
  name = 'metric';
  tag = '';

  constructor({ name, tag }) {
    this.name = name;
    this.tag = tag;
  }

  apply(entityType, entityData) {
    return this.applyToEntity[entityType](entityData, this.cache, this.tag);
  }

  reset() {
    this.cache = structuredClone(INITIAL_CACHE);
  }

  applyToEntity = {
    [DesignEntity.ACTIVITY]: function ({ activity }, cache, tag) {
      const hours = activity.tags?.includes(tag) ? activity.hours : 0;
      if (!Object.hasOwn(cache.roleTotals, activity.owner_uuid)) {
        cache.roleTotals[activity.owner_uuid] = 0;
      }
      cache.roleTotals[activity.owner_uuid] += hours;
      return {
        hours,
      };
    },
    [DesignEntity.ROLE]: function ({ role, roleMetrics }, cache) {
      const hours = cache.roleTotals[role.uuid] ?? 0;
      if (!Object.hasOwn(cache.groupTotals, role.group_uuid)) {
        cache.groupTotals[role.group_uuid] = 0;
      }
      cache.groupTotals[role.group_uuid] += hours;
      return {
        hours,
        percent: Num.percent(hours, roleMetrics.total.hours),
      };
    },
    [DesignEntity.GROUP]: function ({ group, groupMetrics }, cache) {
      const hours = cache.groupTotals[group.uuid] ?? 0;
      cache.designTotal += hours;
      return {
        hours,
        percent: Num.percent(hours, groupMetrics.total.hours),
      };
    },
    [DesignEntity.ORGANISATION]: function ({ snapshotMetrics }, cache) {
      return {
        hours: cache.designTotal,
        percent: Num.percent(cache.designTotal, snapshotMetrics.total.hours),
      };
    },
  };
}
