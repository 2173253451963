import { useRouter } from 'next/router';
import { forwardRef } from 'react';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { EntityType } from '@/shared/enums';
import { EventName, EventProperty } from '@/shared/enums';
import { useEntityHierarchyContext } from '@/shared/providers';
import { viewModeFromPath } from '@/shared/utils';
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Stack from '@mui/material/Stack';
import { Analytics } from '@pkg/analytics';
import * as Auth from '@pkg/auth';
import { Routes } from '@pkg/utils';
import { brand, core } from '@/lib/theme/tokens/palettes';

const getCurrentEntity = ({ entityMap, level, organisation }) => {
  if (!level) {
    return {
      title: organisation.name,
      type: EntityType.ORGANISATION,
    };
  }

  const entityType =
    level.type === EntityType.MANAGER ? EntityType.ROLE : level.type;

  const entity = entityMap?.get(entityType)?.get(level.uuid);

  if (!entity) {
    return;
  }
  const isManager = entity.is_manager;

  return {
    title: entity?.name ?? entity?.title,
    type: isManager ? EntityType.MANAGER : level.type,
  };
};

const PrimaryNavigationSelector = forwardRef(function PrimaryNavigationSelector(
  { onClick },
  ref
) {
  const router = useRouter();
  const { organisation } = Auth.useStore();
  const isDesignRoute = router?.asPath?.startsWith('/d/');
  const current = router.asPath;
  const parts = Routes.getDesignParts(current);
  const { entityMap } = useEntityHierarchyContext();

  const handleClick = (event) => {
    const viewMode = viewModeFromPath(current);

    Analytics.track(EventName.OPENED_ENTITY_SELECTOR, {
      [EventProperty.ENTRY]: 'Primary navigation',
      [EventProperty.VIEW_MODE]: viewMode,
    });

    onClick?.(event);
  };

  const entity = isDesignRoute
    ? getCurrentEntity({ entityMap, level: parts?.level, organisation })
    : null;

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      onClick={handleClick}
      ref={ref}
      spacing={4}
      p={0.5}
      sx={{
        borderRadius: '4px',
        backgroundColor: '#304C6E',
        minWidth: '400px',
        '&:hover': {
          cursor: 'pointer',
        },
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        {entity ? (
          <>
            <EntityChip type={entity.type} size={Size.X_SMALL} />
            <Paragraph
              size={Size.SMALL}
              overrideStyles={{
                color: core.white,
                fontWeight: 500,
                textShadow: '0 1px 1px rgba(0,0,0,0.2)',
              }}
            >
              {entity.title}
            </Paragraph>
          </>
        ) : (
          <Paragraph
            size={Size.SMALL}
            overrideStyles={{
              pl: 0.5,
              color: `${core.white}db`,
              fontWeight: 500,
              textShadow: '0 1px 1px rgba(0,0,0,0.2)',
            }}
          >
            Search for roles and teams
          </Paragraph>
        )}
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        spacing={0.25}
        pr={0.5}
        pl={1}
        sx={{
          borderRadius: '2px',
          backgroundColor: brand.yellow.main,
        }}
      >
        <Paragraph
          size={Size.SMALL}
          overrideStyles={{
            fontWeight: 500,
            fontSize: '0.825rem',
            height: '1.325rem',
            lineHeight: '1.325rem',
          }}
        >
          Go to
        </Paragraph>
        <ArrowDownIcon
          sx={{
            textAlign: 'center',
            verticalAlign: 'middle',
            height: '21px',
            width: '18px',
          }}
        />
      </Stack>
    </Stack>
  );
});

export default PrimaryNavigationSelector;
