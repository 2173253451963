import { Obj } from '@pkg/utils';

/**
 * @param {Object} tags
 * @return {Object}
 */
export default function deriveProps(tags, categories) {
  if (Obj.isEmpty(tags)) {
    return [];
  }

  const keyed = {};
  const derived = {
    categories: [],
    tags: [],
  };

  categories.map((item) => {
    const category = structuredClone(item);
    category.__taggable_set = new Set(item.taggables);
    derived.categories.push(category);
    keyed[category.uuid] = category;
  });

  tags.forEach((item) => {
    const tag = structuredClone(item);
    tag.__category = keyed[tag.category_uuid] ?? null;
    tag.__taggables_set = new Set(tag.taggables);
    derived.tags.push(tag);
  });

  return derived;
}
