import * as Mutations from '../mutations';

/**
 * @param {any[]} query
 */
export default function useMutations() {
  return {
    createIntegration: Mutations.createIntegration(),
    removeIntegration: Mutations.removeIntegration(),
  };
}
