import clsx from 'clsx';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';

const SPACING = 12;

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginLeft: 8,
    },
    '& > :first-of-type': {
      marginLeft: 0,
    },
  },
  left: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingLeft: SPACING,
    marginLeft: SPACING,
  },
  right: {
    borderRight: `1px solid ${theme.palette.divider}`,
    paddingRight: SPACING,
    marginRight: SPACING,
  },
}));

const ActionGroup = ({
  borderLeft: left = false,
  borderRight: right = false,
  children,
}) => {
  const classes = useStyles();

  if (!children) {
    return null;
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.left]: left,
        [classes.right]: right,
      })}
    >
      {children}
    </div>
  );
};

ActionGroup.propTypes = {
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
};

export default ActionGroup;
