/**
 * @param {String} type
 * @param {Object} draft
 * @param {String} input
 */
export default function update(type, draft, { uuid, ...input }) {
  const index = draft[type].list.findIndex((item) => item.uuid === uuid);
  const updated = { ...draft[type].list[index], ...input };

  draft[type].list[index] = updated;
  draft[type].map.set(uuid, updated);
}
