import { EntityType, ScopeType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import ActivityMenu from './ActivityMenu';

const InsightsMenu = ({ excludeHeight }) => {
  const { id, scope, scopeEntity, selectScopeEntity, selectId } =
    useViewModeContext();
  const { scenario } = useScenarioContext();
  const entityType = scenario?.entity?.__type;
  const entityId = scenario?.entity?.uuid;
  const isManager = scenario?.entity?.is_manager;

  const handleIdChange = (item) => {
    if (!item?.id) {
      return;
    }
    const optionEntity =
      entityType === EntityType.ROLE &&
      !isManager &&
      scope === ScopeType.ACTIVITIES
        ? EntityType.ACTIVITY
        : scopeEntity;

    selectId(optionEntity, item.id);
  };

  const handleEntityChange = (item) => {
    if (!item?.id) {
      return;
    }
    selectScopeEntity(item.id);
  };

  return (
    <ActivityMenu
      entityId={entityId}
      excludeHeight={excludeHeight}
      initialEntity={scopeEntity}
      onIdChange={handleIdChange}
      onEntityChange={handleEntityChange}
      scope={scope}
      scopeEntity={scopeEntity}
      selected={id}
    />
  );
};

export default InsightsMenu;
