import createMatrixMap from './createMatrixMap';

export default function createEntityMap({ matrix, metrics, order }) {
  if (matrix) {
    return createMatrixMap({
      matrix,
      metrics: { ...metrics },
      order,
    });
  }

  return {
    order,
    [order[0]]: new Map(),
    metrics: { ...metrics },
  };
}
