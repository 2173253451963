import { useEffect } from 'react';

/**
 * @param {PresenceChannel} channel
 *
 * @param {Object} presence
 * @param {Function} presence.setPeople
 *
 * @returns {undefined}
 */
export default function useEnter(channel, presence) {
  useEffect(() => {
    function handleEntering(user) {
      presence.setPeople((users) => {
        return [...users, user];
      });
    }

    if (channel) {
      channel.joining(handleEntering);
    }
  }, [channel]);
}
