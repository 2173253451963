import { Size } from '@/atoms/enums';
import { LabelSize } from '@/atoms/inputs/utils';
import InputLabel from '@mui/material/InputLabel';

const Label = ({ children, size = Size.MEDIUM }) => {
  const sizeProps = LabelSize[size];
  return (
    <InputLabel disableAnimation shrink={false} sx={{ ...sizeProps, mb: 0.5 }}>
      {children}
    </InputLabel>
  );
};

export default Label;
