import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import { Database } from '@pkg/database';
import useMutations from './useMutations';
import useRefresh from './useRefresh';
import useStore from './useStore';

export default function useLive() {
  const { isAuthenticated, isLoading } = Auth.useStore();
  const { refresh, isRefreshing } = useRefresh();
  const { setActivities, setLoading, setMutations } = useStore();
  const mutations = useMutations();

  const isStale = Database.useStaleHash('activities');
  const activities = Database.useLive('activities');

  useEffect(() => {
    if (isLoading || isRefreshing) {
      return;
    }

    if (!isAuthenticated) {
      setActivities([]);
      setLoading(false);
      return;
    }

    if (isStale) {
      refresh();
      return;
    }

    if (!activities) {
      return;
    }

    setActivities(activities);
    setLoading(false);
  }, [activities, isAuthenticated, isLoading, isRefreshing, isStale]);

  useEffect(() => {
    setMutations(isAuthenticated ? mutations : {});
  }, [isAuthenticated, mutations]);
}
