import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object}
 * @return {Object}
 */
const updateScenarioEntity = ({
  collection,
  design,
  entity,
  entityType,
  snapshotEntityMap,
}) => {
  const { uuid, is_scenario, isSupporter } = design;

  const scenario = {
    title: is_scenario ? design?.name : (entity.title ?? entity.name),
    __designId: uuid,
    __isScenario: is_scenario,
    __isSupporter: isSupporter,
  };

  const exists =
    entityType === DesignEntity.ORGANISATION
      ? collection.size > 0
      : collection.has(entity.uuid);

  if (exists) {
    const scenarioId =
      entityType === DesignEntity.ORGANISATION
        ? collection.keys().next().value
        : entity.uuid;

    const scenarioEntity = collection.get(scenarioId);

    scenarioEntity.__scenarios.set(uuid, scenario);

    collection.set(scenarioId, {
      ...scenarioEntity,
    });
  } else {
    const __scenarios = new Map();
    __scenarios.set(uuid, scenario);

    if (entityType === DesignEntity.ORGANISATION) {
      collection.set(uuid, {
        ...design.latest,
        __type: DesignEntity.ORGANISATION,
        __scenarios,
      });

      return collection;
    }

    if (entityType === DesignEntity.ROLE) {
      const groupEntity = snapshotEntityMap
        .get(DesignEntity.GROUP)
        ?.get(entity.group_uuid);

      collection.set(entity.uuid ?? entity.__uuid, {
        ...entity,
        __group: { uuid: groupEntity?.uuid, name: groupEntity?.name },
        __scenarios,
      });

      return collection;
    }

    collection.set(entity.uuid ?? entity.__uuid, {
      ...entity,
      __scenarios,
    });
  }

  return collection;
};

const DEFAULT_RELATIONS = {
  designs: new Map(),
  roles: new Map(),
  groups: new Map(),
};

/**
 * @param {Object} designs
 * @param {Object} supporterMap
 * @param {String} userId
 * @return {Object}
 */
export default function filterUserEntities(designs, supporterMap, userId) {
  const scenarios = structuredClone(DEFAULT_RELATIONS);

  Array.from(designs.values()).forEach((designData) => {
    const { design, snapshotEntityMap } = designData;

    Array.from(snapshotEntityMap.get(DesignEntity.ROLE).values()).forEach(
      (role) => {
        if (role.user_uuid !== userId) {
          return;
        }

        scenarios.roles = updateScenarioEntity({
          collection: scenarios.roles,
          entity: role,
          entityType: DesignEntity.ROLE,
          design,
          snapshotEntityMap,
        });

        if (role.is_manager) {
          role.__managed?.[DesignEntity.GROUP].forEach((groupId) => {
            const group = snapshotEntityMap
              .get(DesignEntity.GROUP)
              ?.get(groupId);

            if (!group) {
              return;
            }

            scenarios.groups = updateScenarioEntity({
              collection: scenarios.groups,
              entity: group,
              entityType: DesignEntity.GROUP,
              design,
            });
          });
        }
      }
    );

    Array.from(snapshotEntityMap.get(DesignEntity.GROUP).values()).forEach(
      (group) => {
        const isSupporter = supporterMap.get(group.uuid)?.has(userId);
        if (!isSupporter && group.lead_uuid !== userId) {
          return;
        }

        scenarios.groups = updateScenarioEntity({
          collection: scenarios.groups,
          entity: group,
          entityType: DesignEntity.GROUP,
          design,
        });
      }
    );

    const organisation = snapshotEntityMap.get(DesignEntity.ORGANISATION);

    // Don't add role and group scenarios at the design level.
    if (
      organisation.is_scenario &&
      organisation.scope !== DesignEntity.ORGANISATION
    ) {
      return;
    }

    scenarios.designs = updateScenarioEntity({
      collection: scenarios.designs,
      entity: design?.latest,
      entityType: DesignEntity.ORGANISATION,
      design,
    });
  });

  return scenarios;
}
