import { PercentageCircle } from '@/atoms/metrics';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import { percent } from '@pkg/utils/numbers';

const DoughnutChartCenterLabel = ({
  color,
  label,
  numerator,
  denominator,
  maxPercent,
  width,
}) => {
  const percentage = percent(numerator, denominator);
  return (
    <Box width={width} sx={{ position: 'relative', margin: '0 auto' }}>
      <PercentageCircle
        color={color}
        label="Hours per week"
        value={maxPercent && percentage > maxPercent ? maxPercent : percentage}
        options={{
          cutout: '90%',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -60%)',
          textAlign: 'center',
          width: '100%',
        }}
      >
        <Heading
          variant="h1"
          overrideStyles={{ fontWeight: 500, lineHeight: '3.5rem' }}
        >
          {numerator}
        </Heading>
        <Heading variant="h6">{label}</Heading>
      </Box>
    </Box>
  );
};

export default DoughnutChartCenterLabel;
