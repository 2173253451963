import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

/**
 * Adds new roles to the actions map.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function addedRolesAction({
  actionMap,
  comparisonScenario,
  role,
  scenario,
}) {
  const group = role
    ? scenario?.relationships.get(EntityType.GROUP)?.get(role.group_uuid)
    : null;

  const metrics = role.__metrics.self.visible;

  const existingAction = actionMap?.get(ActionType.ADD_ROLES);

  const action = existingAction ?? {
    entity: EntityType.ROLE,
    metrics: defaultMetrics(comparisonScenario),
    type: ActionType.ADD_ROLES,
    [EntityType.ROLE]: new Map(),
    [EntityType.GROUP]: new Set(),
  };

  action[EntityType.ROLE].set(role.uuid, { metrics });

  if (group) {
    action[EntityType.GROUP].add(group?.uuid);
  }

  const roleCount = action[EntityType.ROLE].size;
  const groupCount = action[EntityType.GROUP].size;

  action.metrics[1].activities += metrics.activities;
  action.metrics[1].budget += metrics.budget;
  action.metrics[1].fte += metrics.fte;
  action.metrics[1].hours += metrics.hours;

  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const groupLabel = entityLabel(EntityType.GROUP, groupCount !== 1);
  const verb = roleCount === 1 ? 'was' : 'were';
  const adverb = groupCount === 1 ? ', impacting' : ' across';

  action.title = `${roleCount} ${roleLabel} ${verb} added`;

  if (groupCount > 0) {
    action.title = `${action.title}${adverb} ${groupCount} ${groupLabel}`;
  }

  return action;
}
