import { Num } from '@pkg/utils';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';

const INITIAL_CACHE = { groupTotals: {}, designTotal: 0 };

class OverCapacity {
  cache = structuredClone(INITIAL_CACHE);
  name = 'OverCapacity';

  apply(entityType, entityData) {
    return this.applyToEntity[entityType](entityData, this.cache);
  }

  reset() {
    this.cache = structuredClone(INITIAL_CACHE);
  }

  applyToEntity = {
    [DesignEntity.ACTIVITY]: function () {
      return;
    },
    [DesignEntity.ROLE]: function ({ role, roleMetrics }, cache) {
      const actualHours = roleMetrics.total.hours ?? 0;
      const thresholdHours =
        config.FTE_HOURS_PER_WEEK *
        role.fte *
        config.INSIGHTS.CAPACITY_THRESHOLD;
      const overCapacity = actualHours - thresholdHours;
      const overCapacityPercent = Num.percent(overCapacity, thresholdHours);
      if (!Object.hasOwn(cache.groupTotals, role.group_uuid)) {
        cache.groupTotals[role.group_uuid] = 0;
      }
      cache.groupTotals[role.group_uuid] += overCapacity > 0 ? 1 : 0;

      return {
        hours: overCapacity,
        percent: overCapacityPercent > 0 ? overCapacityPercent : 0,
      };
    },
    [DesignEntity.GROUP]: function ({ group, groupMetrics }, cache) {
      const roleCount = groupMetrics.total.roles;
      const rolesOverCapacity = cache.groupTotals[group.uuid] ?? 0;

      cache.designTotal += rolesOverCapacity > 0 ? 1 : 0;

      return {
        count: rolesOverCapacity,
        percent: Num.percent(rolesOverCapacity, roleCount),
      };
    },
    [DesignEntity.ORGANISATION]: function ({ snapshotMetrics }, cache) {
      const groupCount = snapshotMetrics.total.groups;
      const groupsOverCapacity = cache.designTotal;

      return {
        count: groupsOverCapacity,
        percent: Num.percent(groupsOverCapacity, groupCount),
      };
    },
  };
}

export default new OverCapacity();
