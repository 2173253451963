import * as Colors from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const ToggleButtonColors = Object.freeze({
  primary: {
    normal: {
      backgroundColor: 'transparent',
      color: Colors.Base.font.tertiary,
      boxShadow: `inset 0 0 0 1px ${brand.grey.tints[1]}`,
    },
    hover: {
      backgroundColor: brand.grey.tints[1],
      boxShadow: `inset 0 0 0 1px ${brand.grey.tints[2]}`,
      color: Colors.Base.font.tertiary,
    },
    focus: {
      backgroundColor: brand._navy.tints[4],
      color: core.white,
    },
    selected: {
      backgroundColor: brand.grey.shades[0],
      boxShadow: `inset 0 0 0 1px ${brand.grey.shades[0]}`,
      color: core.white,
    },
    selectedHover: {
      backgroundColor: brand.grey.tints[5],
      boxShadow: `inset 0 0 0 1px ${brand.grey.tints[5]}`,
      color: core.white,
    },
  },
  secondary: {
    normal: {
      backgroundColor: brand.lightBlue.tints[4],
      color: Colors.Base.font.tertiary,
    },
    hover: {
      backgroundColor: brand.lightBlue.main,
      color: Colors.Base.font.tertiary,
    },
    focus: {
      backgroundColor: brand.lightBlue.main,
      color: Colors.Base.font.tertiary,
    },
    selected: {
      backgroundColor: brand.lightBlue.shades[4],
      color: core.white,
    },
    selectedHover: {
      backgroundColor: brand.lightBlue.shades[1],
      color: core.white,
    },
  },
});

export default ToggleButtonColors;
