import { Field } from 'formik';
import { CheckboxWithLabel } from 'formik-mui';

const Checkbox = ({ name = '', classes = {}, label = '', ...props }) => {
  return (
    <Field
      name={name}
      {...props}
      component={CheckboxWithLabel}
      type="checkbox"
      Label={{
        classes: classes,
        label: label,
      }}
    />
  );
};

export default Checkbox;
