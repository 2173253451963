import { brand, core } from '@/lib/theme/tokens/palettes';

const IconHeadingColors = Object.freeze({
  primary: {
    icon: {
      color: brand.teal.main,
      borderColor: `${brand.teal.tints[6]}70`,
      backgroundColor: core.white,
    },
  },
  secondary: {
    icon: {
      color: brand.blue.main,
      borderColor: brand.blue.tints[2],
      backgroundColor: core.white,
    },
  },
  tertiary: {
    icon: {
      color: brand.orange.main,
      borderColor: brand.orange.tints[2],
      backgroundColor: core.white,
    },
  },
});

export default IconHeadingColors;
