import { tool } from 'ai';
import { z } from 'zod';

const generateReport = tool({
  description: 'Asks for confirmation to generate a report',
  parameters: z.object({
    question: z
      .string()
      .describe(
        'Takes the users question and explains it in greater detail. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
    // make a zod array of strings
    keywords: z
      .array(z.string())
      .describe(
        'A list of keywords to generate a report on e.g. automation, ai, layers, business'
      ),
  }),
  execute: async ({ question, keywords }) => ({
    question,
    keywords,
  }),
});

export default generateReport;
