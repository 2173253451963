import { Checkbox } from '@/atoms/inputs';
import { SelectOption } from '@/atoms/inputs/Select';
import Stack from '@mui/material/Stack';

const MultiCheckboxOption = ({
  option,
  color,
  colorProps,
  onChange,
  OptionComponent,
  optionLabel,
  value,
}) => {
  return (
    <SelectOption color={color}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
      >
        <div style={{ maxWidth: 'calc(100% - 90px)' }}>
          {OptionComponent ? (
            OptionComponent(option)
          ) : (
            <>{option[optionLabel]}</>
          )}
        </div>
        <Stack direction="row" spacing={1} alignItems="center">
          {option?.options?.map((subOption) => (
            <Checkbox
              key={`${option.id}:${subOption}`}
              initialValue={value?.has?.(subOption)}
              onChange={(event) => onChange(event, option.id, subOption)}
              color={color}
              colorProps={colorProps?.[subOption]}
            />
          ))}
        </Stack>
      </Stack>
    </SelectOption>
  );
};

export default MultiCheckboxOption;
