import circleSegmentCoordinates from '../utils/segmentCircleCoordinates';

const SegmentCircles = ({
  circleCount,
  height,
  width,
  xPosition,
  yPosition,
}) => {
  // Retrieve an array of circle coordinates based on the circle count and the
  // size of the segment they should be contained within.
  const circles = circleSegmentCoordinates({
    circleCount,
    height,
    width,
    xPosition,
    yPosition,
  });

  return circles.map((circle) => (
    <circle
      key={circle.id}
      r={circle.r}
      cx={circle.cx}
      cy={circle.cy}
      fill="#FFF"
    />
  ));
};

export default SegmentCircles;
