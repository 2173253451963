/**
 * @param {Object[]} collection
 * @param {String} key
 *
 * @return {Object}
 */
export function keyBy(collection, key = 'key') {
  const keyed = {};

  collection?.forEach((item) => {
    keyed[item[key]] = item;
  });

  return keyed;
}

/**
 * @param {Object[]} collection
 * @param {String} collection[].uuid
 *
 * @return {Object}
 */
export function keyById(collection) {
  return keyBy(collection, 'uuid');
}
