/**
 * @param {String} value
 * @returns {String}
 */
export function blur(value, options = {}) {
  value = change(value);

  if (Number.isFinite(options.max)) {
    value = value.substring(0, options.max);
  }

  return value.trim();
}

/**
 * @param {String} value
 * @returns {String}
 */
export function change(value) {
  if (!value) {
    return '';
  }

  return value;
}
