import { InfoButton } from '@/atoms/buttons';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { Popover } from '@/molecules/modals';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';
import { brand, core } from '@/lib/theme/tokens/palettes';

const CardHeader = ({ action, information, title, isTitleAction }) => {
  const justifyContent =
    Boolean(title) && Boolean(action) && !isTitleAction
      ? 'space-between'
      : 'flex-start';

  return (
    (Boolean(title) || Boolean(action)) && (
      <>
        <Stack
          justifyContent={justifyContent}
          alignItems="center"
          direction="row"
          sx={{ px: 2 }}
          spacing={1}
        >
          {Boolean(title) && (
            <Stack alignItems="center" direction="row" py={1.5}>
              <Heading variant="h3" overrideStyles={{ fontSize: '1.1rem' }}>
                {title}
              </Heading>
              {Boolean(information) && (
                <Popover
                  Component={
                    <InfoButton
                      colorProps={{
                        backgroundColor: brand.yellow.main,
                        color: core.white,
                      }}
                      size={Size.X_SMALL}
                    />
                  }
                  position={Position.BOTTOM}
                >
                  <Box
                    pl={1.5}
                    pr={1}
                    pt={1}
                    pb={0.25}
                    sx={{
                      boxShadow: `inset 4px 0 0 0 ${brand.yellow.main}`,
                    }}
                  >
                    {information}
                  </Box>
                </Popover>
              )}
            </Stack>
          )}
          {Boolean(action) && <>{action}</>}
        </Stack>
        <Divider color="primary" />
      </>
    )
  );
};

export default CardHeader;
