import PropTypes from 'prop-types';
import Button from '@mui/lab/LoadingButton';
import CircularProgress from '@mui/material/CircularProgress';

Button.propTypes = {
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'naked',
    // remove these from everywhere
    'contained',
    'outlined',
  ]),
};

const LoadingButton = ({ disabled, loading = false, fullWidth, ...props }) => {
  return (
    <Button
      disabled={loading || disabled}
      loading={loading}
      loadingIndicator={
        <CircularProgress style={{ color: '#fff' }} size={16} />
      }
      fullWidth={fullWidth}
      {...props}
    />
  );
};

export default LoadingButton;
