import { Colors } from '@pkg/utils';

export default function setGroupedActivitiesColor(entityType) {
  const colors = Colors.entity(entityType);

  return {
    background: colors.tints[1],
    foreground: colors.main,
    border: colors.tints[1],
    highlight: {
      border: colors.tints[3],
      background: colors.tints[5],
      foreground: colors.shades[2],
    },
  };
}
