import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { Skills } from '@pkg/entities/library';
import Cancel from '@pkg/icons/Cancel';
import { Collections } from '@pkg/utils';
import { color } from '@/lib/theme/tokens';
import Button from '@/components/Button';
import Skill from '@/components/Skill';
import AddSkill from './AddSkill';
import SkillLegend from './SkillLegend';

const Badge = styled('span')({
  backgroundColor: color.primary.grey[200],
  borderRadius: 8,
  display: 'inline-block',
  fontSize: 10,
  fontWeight: 700,
  lineHeight: '16px',
  margin: 0,
  marginLeft: 4,
  padding: '0 5px',
  textAlign: 'center',
});

const Container = styled('div')({
  backgroundColor: color.primary.grey[100],
  borderRadius: 4,
  marginTop: 6,
  padding: 8,
});

const Header = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Label = styled('p')({
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '20px',
  margin: 0,
});

const Root = styled(FormControl)({
  flex: 1,
  width: '100%',
});

const SkillsContent = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  marginBottom: 8,
});

const StyledSkill = styled(Skill)({
  marginBottom: 4,
  marginRight: 4,
});

const removeDeleted = (value, keyed) => {
  return value.filter(({ uuid }) => keyed[uuid]);
};

const SkillsInput = ({
  allowDuplicateSkill = false,
  disabled,
  disableClear = false,
  label = 'Skills',
  onChange,
  readOnly,
  ...props
}) => {
  const { list } = Skills.useStore();
  const keyed = Collections.keyById(list);

  const [current, setCurrent] = useState(removeDeleted(props.value, keyed));
  const [selected, setSelected] = useState(null);
  const canClear = Boolean(current.length);

  const handleAdd = (event, skill) => {
    const updated = [
      ...current.filter(
        ({ uuid }) => allowDuplicateSkill || uuid !== skill.uuid
      ),
      skill,
    ];
    setCurrent(updated);
    setSelected(null);
    onChange?.(event, updated);
  };

  const handleSelect = (skill) => {
    setSelected(skill);
  };

  const handleRemove = (event, skill) => {
    event.preventDefault();
    event.stopPropagation();
    const changed = current.filter(({ uuid }) => uuid !== skill.uuid);
    setCurrent(changed);
    setSelected(null);
    onChange?.(event, changed);
  };

  const handleClear = (event) => {
    setCurrent([]);
    setSelected(null);
    onChange?.(event, []);
  };

  useEffect(() => {
    const current = removeDeleted(props.value, keyed);
    setCurrent(current);
  }, [JSON.stringify(props.value), JSON.stringify(keyed)]);

  return (
    <Root>
      <Header>
        <Label>
          {label}
          <Badge>{current.length}</Badge>
        </Label>
        {!readOnly && !disableClear && (
          <Button
            disabled={!canClear || disabled}
            onClick={handleClear}
            size="small"
            startIcon={<Cancel />}
            variant="text"
          >
            Clear
          </Button>
        )}
      </Header>
      <SkillLegend />
      {(Boolean(current.length) || !readOnly) && (
        <Container>
          {Boolean(current.length) && (
            <SkillsContent>
              {current.map((skill) => {
                if (!keyed[skill.uuid]) {
                  return null;
                }
                return (
                  <StyledSkill
                    key={skill.uuid}
                    canRemove={!readOnly && !disabled}
                    name={keyed[skill.uuid].name}
                    skill={skill}
                    onClick={handleSelect}
                    onRemove={handleRemove}
                  />
                );
              })}
            </SkillsContent>
          )}
          {!readOnly && (
            <AddSkill library={list} selected={selected} onAdd={handleAdd} />
          )}
        </Container>
      )}
    </Root>
  );
};

SkillsInput.propTypes = {
  disabled: PropTypes.bool,
  disableClear: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default memo(SkillsInput);
