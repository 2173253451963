import { Button } from '@/atoms/buttons';
import { Menu, MenuItem } from '@/molecules/menus';
import ExpandIcon from '@mui/icons-material/ExpandMoreRounded';

const ShareMenu = ({ removeOption, selected, options, onClick }) => {
  return (
    <Menu
      Component={
        <Button
          variant="text"
          color="primary"
          label={selected?.label}
          endIcon={<ExpandIcon />}
        />
      }
    >
      {options.map((option) => (
        <MenuItem key={option.value} onClick={() => onClick(option)}>
          {option.label}
        </MenuItem>
      ))}
      {removeOption && (
        <MenuItem
          key={removeOption.value}
          danger
          onClick={() => onClick(removeOption)}
        >
          {removeOption.label}
        </MenuItem>
      )}
    </Menu>
  );
};

export default ShareMenu;
