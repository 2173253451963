import { EntityName } from '@/shared/enums';
import { Designs } from '@pkg/entities';
import shortuuid from '@pkg/uuid';

const useCreateScenario = ({ designId, entity, scope }) => {
  const createScenario = Designs.mutations.useCreateScenario();

  const create = async ({ scenarioName, scenarioGoal }) => {
    const uuid = shortuuid.generate();
    const from = {
      design_uuid: designId,
      entity_uuid: entity.uuid,
    };

    const entityName = EntityName[entity?.__type]?.toLowerCase();
    const title = entity.title ?? entity.name;

    const name = scenarioName ?? `${title} ${entityName} scenario`;
    const goal = scenarioGoal ?? '';

    await createScenario({ uuid, name, goal, scope, from });

    return { entity, scenarioId: uuid, designId, scope };
  };

  return {
    create,
  };
};

export default useCreateScenario;
