import { SkillLevel } from '@/lib/enums';

// true if level a has a level great than or equal to b
function meets(a, b) {
  if (!a || !b) {
    return false;
  }

  const levels = Object.values(SkillLevel);

  return levels.indexOf(a) >= levels.indexOf(b);
}

// return true if level a is higher than level b
function isHigher(a, b) {
  const levels = Object.values(SkillLevel);

  return levels.indexOf(a) > levels.indexOf(b);
}

// return the level that is higher
function higher(a, b) {
  const levels = Object.values(SkillLevel);

  return levels.indexOf(a) > levels.indexOf(b) ? a : b;
}

export { meets, higher, isHigher };
