import { useEffect, useState } from 'react';
import {
  useCreateScenario,
  usePublishScenario,
  useStoredDesign,
} from '@/shared/hooks';
import { formatScenario } from '@/shared/utils';
import { DesignScope } from '@/lib/enums';

const useScenario = ({
  designId,
  existingScenario,
  forceRevision,
  level,
  storeType,
  includeNested = true,
}) => {
  const [scenario, setScenario] = useState();
  const [entity, setEntity] = useState(
    designId
      ? {
          designId,
          ...level,
        }
      : null
  );

  const { access, design, snapshot, snapshotEntityMap } = useStoredDesign({
    designId: entity?.designId,
    storeType,
    level,
    includeNested,
  });

  const { canPublish, publish } = usePublishScenario({
    access,
    design,
    scenario,
    snapshot,
  });

  const { create } = useCreateScenario({
    designId: entity?.designId,
    entity: scenario?.entity,
    scope: level?.type || DesignScope.ORGANISATION,
  });

  const selectScenario = (entity, designId) => {
    setEntity({
      type: entity.__type,
      uuid: entity.uuid ?? entity.__uuid,
      designId,
    });
  };

  useEffect(() => {
    if (!design || existingScenario) {
      return;
    }

    if (Boolean(entity?.uuid) && design.uuid === entity.designId) {
      setScenario(
        formatScenario({
          design,
          snapshot,
          snapshotEntityMap,
          entity,
          includeNested,
        })
      );
    }
  }, [
    design?.uuid,
    design?.collaborators,
    entity?.uuid,
    entity?.__uuid,
    includeNested,
    snapshot?.__hash,
    snapshotEntityMap,
    JSON.stringify(snapshot?.__visible_metrics),
  ]);

  return {
    access: existingScenario?.access ?? access,
    canPublish,
    create,
    publish,
    scenario: existingScenario?.scenario ?? scenario,
    selectScenario: existingScenario?.selectScenario ?? selectScenario,
    snapshotEntityMap: existingScenario?.snapshotEntityMap ?? snapshotEntityMap,
  };
};

export default useScenario;
