import { useCallback } from 'react';
import { useMutation } from '@pkg/graphql';

const ADD_SUPPORTER = /* GraphQL */ `
  mutation AddSupporter($input: AddGroupSupporterInput!) {
    addGroupSupporter(input: $input) {
      uuid
      people
    }
  }
`;

/**
 * @param {any[]} query
 * @return {Function}
 */
export default function useAddSupporter(query) {
  const { mutateAsync } = useMutation({
    mutation: ADD_SUPPORTER,
    invalidateQuery: query,
  });

  /**
   * @param {String} design_uuid
   * @param {String} group_uuid
   * @param {String} user_uuid
   * @returns {Object|null}
   */
  function addGroupSupporter(design_uuid, group_uuid, user_uuid) {
    return mutateAsync({
      design_uuid,
      group_uuid,
      user_uuid,
    });
  }

  return useCallback(addGroupSupporter, [mutateAsync]);
}
