import { percentageChange } from '@/shared/utils/calculations';
import { DesignEntity } from '@/lib/enums';

/**
 * @param {Map} activityMap
 * @return {Object}
 */
const calculateActivityMetrics = (activityMap) => {
  const values = {
    count: 0,
    hours: 0,
  };

  activityMap.forEach((activity) => {
    if (!activity.disabled_at) {
      values.count += 1;
      values.hours += activity?.hours ?? 0;
    }
  });

  return values;
};

/**
 * Calculates metric differences between a primary and comparison scenario.
 * @param {Object} primaryScenario
 * @param {Object} comparisonScenario
 *
 * @return {Object}
 */
export default function calculateComparisonMetrics(
  primaryScenario,
  comparisonScenario
) {
  const primaryMap = primaryScenario.relationships;
  const comparisonMap = comparisonScenario.relationships;

  const primary = {
    activities: calculateActivityMetrics(
      primaryMap?.get(DesignEntity.ACTIVITY)
    ),
    role: {
      fte: primaryScenario.entity?.fte,
    },
  };
  const comparison = {
    activities: calculateActivityMetrics(
      comparisonMap?.get(DesignEntity.ACTIVITY)
    ),
    role: {
      fte: comparisonScenario?.entity?.fte,
    },
  };

  return {
    activities: {
      count: {
        comparison: comparison.activities.count,
        percentage: percentageChange(
          comparison.activities.count,
          primary.activities.count
        ),
        primary: primary.activities.count,
        raw: primary.activities.count - comparison.activities.count,
      },
      hours: {
        comparison: comparison.activities.hours,
        percentage: percentageChange(
          comparison.activities.hours,
          primary.activities.hours
        ),
        primary: primary.activities.hours,
        raw: primary.activities.hours - comparison.activities.hours,
      },
    },
    roles: {
      fte: {
        comparison: comparison.role.fte,
        primary: primary.role.fte,
        raw: primary.role.fte - comparison.role.fte,
        percentage: percentageChange(comparison.role.fte, primary.role.fte),
      },
    },
  };
}
