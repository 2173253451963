import * as d3 from 'd3';
import { Position } from '@/lib/enums';
import { SubtitleColor } from '@/lib/theme/dashboardColors';

export default function renderQuadrantBox(id, group, context, selectQuadrant) {
  const {
    canvasWidth,
    canvasXOffset,
    classes,
    color,
    height,
    padding,
    position,
    width,
    y0,
  } = context;

  const x0 =
    position === Position.TOP_RIGHT || position === Position.BOTTOM_RIGHT
      ? context.x0
      : 0;

  const quadrantBox = d3
    .select(`#${id} #packed-circle-group--${position}`)
    .append('g')
    .attr('id', `quadrant-box--${position}`)
    .classed('PackedCircleChartSelectBox--root', true)
    .classed(classes.selectBox.root.join(' '), true);

  quadrantBox
    .append('rect')
    .attr('width', canvasWidth / 2 - padding / 4)
    .attr('height', height)
    .attr('x', x0)
    .attr('y', y0)
    .attr('rx', 8)
    .style('cursor', 'pointer');

  const xTextPosition =
    position === Position.TOP_LEFT || position === Position.BOTTOM_LEFT
      ? x0 + padding / 2
      : x0 + width + canvasXOffset - padding / 4;
  const xTextAnchor =
    position === Position.TOP_LEFT || position === Position.BOTTOM_LEFT
      ? 'start'
      : 'end';

  const yTextPosition =
    position === Position.TOP_LEFT || position === Position.TOP_RIGHT
      ? y0 + height / 2 + padding / 4
      : y0 + height / 2;

  quadrantBox
    .append('text')
    .classed('PackedCircleChartSelectBox--text', true)
    .classed(classes.selectBox.text.join(' '), true)
    .attr('y', yTextPosition)
    .attr('x', xTextPosition)
    .style('fill', '#FFFFFF')
    .text('Explore activities')
    .style('text-anchor', xTextAnchor);

  const circleGroup = d3.select(`#${id} #packed-circle-group--${position}`);
  quadrantBox.__events = {};

  quadrantBox.__events.onMouseOver = () => {
    circleGroup.selectAll('circle').style('fill', '#FFFFFF60');

    circleGroup.selectAll('.QuadrantLabel--text').style('fill', '#FFFFFF');

    quadrantBox
      .classed('PackedCircleChartSelectBox--visible', true)
      .classed(classes.selectBox.visible.join(' '), true);
  };

  quadrantBox.__events.onMouseLeave = () => {
    circleGroup.selectAll('circle').style('fill', color);

    circleGroup
      .selectAll('.QuadrantLabel--text')
      .style('fill', SubtitleColor.title);

    quadrantBox
      .classed('PackedCircleChartSelectBox--visible', false)
      .classed(classes.selectBox.visible.join(' '), false);
  };

  quadrantBox.__events.onClick = () => {
    selectQuadrant(position);
  };

  quadrantBox.on('mouseover', quadrantBox.__events.onMouseOver);
  quadrantBox.on('mouseleave', quadrantBox.__events.onMouseLeave);
  quadrantBox.on('click', quadrantBox.__events.onClick);

  return quadrantBox;
}
