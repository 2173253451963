import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Colors as InputColors } from '@/atoms/inputs';
import { TextSelect } from '@/atoms/inputs';
import { flatInputSizeProps } from '@/atoms/inputs/utils';
import { useDispatchContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Activities as Library } from '@pkg/entities/library';
import shortuuid from '@pkg/uuid';
import config from '@/lib/config';
import useActivitySearch from './hooks/useActivitySearch';

const MAX_LENGTH = config.VALUE_LENGTH.MAX.ACTIVITY_DESCRIPTION;
const MAX_POPOVER_HEIGHT = 240;

const InlineActivityField = ({
  action,
  color = 'primary',
  id,
  initialValue,
  name,
  size = Size.SMALL,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const createLibraryActivity = Library.mutations.useCreate();
  const { activities, searchActivities } = useActivitySearch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const [width, setWidth] = useState(0);
  const [selectedActivity, setSelectedActivity] = useState(initialValue);
  const { canEdit, handleDispatch } = useDispatchContext();
  const inputRef = useRef();

  const sizeProps = flatInputSizeProps(size);

  const handleClick = (event) => {
    if (!canEdit) {
      return;
    }
    setAnchorEl(event.currentTarget);
    setWidth(event.currentTarget?.offsetWidth + 32);
  };

  // @ToDo: Discuss if we should create activities when clicking away from the
  // select input field.
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handles the creation of new activities if they don't already exist and
  // calls the dispatch function.
  const handleChange = useCallback(
    async (_, { uuid, description }) => {
      const libraryId = uuid === 'new' ? shortuuid.generate() : uuid;

      setSelectedActivity({ uuid, description });

      if (!handleDispatch) {
        handleClose();
        return;
      }

      if (uuid === 'new') {
        await createLibraryActivity(libraryId, description);
      }

      handleDispatch?.({
        action,
        properties: {
          uuid: id,
          [name]: libraryId,
        },
      });

      handleClose();
    },
    [id]
  );

  const handleMouseLeave = useCallback(() => {
    if (canEdit) {
      setIsHover(false);
    }
  }, [canEdit]);

  const handleMouseEnter = useCallback(() => {
    if (canEdit) {
      setIsHover(true);
    }
  }, [canEdit]);

  useEffect(() => {
    if (selectedActivity?.uuid !== initialValue?.uuid) {
      setSelectedActivity(initialValue);
    }
  }, [initialValue?.uuid]);

  return (
    <>
      <Box position="relative">
        <Box
          onClick={handleClick}
          onMouseEnter={canEdit ? handleMouseEnter : null}
          onMouseLeave={canEdit ? handleMouseLeave : null}
          width="calc(100%)"
          sx={{
            ...sizeProps,
            borderRadius: '4px',
            color: BaseColors.font.primary,
            backgroundColor:
              canEdit && isHover
                ? InputColors.Input[color].hover.backgroundColor
                : 'transparent',
            cursor: canEdit && isHover ? 'pointer' : 'inherit',
            padding: '0 8px',
            position: 'relative',
            transition: 'box-shadow 100ms',
            zIndex: 1,
          }}
        >
          <Tooltip arrow title={selectedActivity?.description} placement="top">
            <Box
              onClick={handleClick}
              position="relative"
              textAlign="left"
              ref={inputRef}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {selectedActivity?.description}
              </Box>
            </Box>
          </Tooltip>
        </Box>
      </Box>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorReference={isLargeScreen ? 'anchorEl' : 'anchorPosition'}
          anchorPosition={{ top: 16, left: 16 }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id={`edit-select-${id}`}
          elevation={0}
          onClose={handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            borderRadius: '4px',
            minWidth: isLargeScreen ? width : '100%',
            maxWidth: isLargeScreen ? width : '100%',
            '& .MuiPaper-root': {
              position: isLargeScreen ? 'relative' : 'fixed',
              boxShadow: `0 2px 5px 1px ${InputColors.Input[color].normal.borderColor}, 0 0 0 1px ${InputColors.Input[color].normal.borderColor}`,
              borderRadius: '4px',
              maxHeight: isLargeScreen ? `${MAX_POPOVER_HEIGHT}px` : '100vh',
              height: isLargeScreen ? 'auto' : '100vh',
              width: isLargeScreen ? 'auto' : '100%',
              minWidth: isLargeScreen ? 'auto' : '100%',
              overflow: 'hidden',
            },
          }}
        >
          <Box minWidth="100%">
            <TextSelect
              addOption
              canAdd
              color={color}
              autoFocus={true}
              disableClearable={true}
              filterOptions={searchActivities}
              label="Select an activity or create one"
              maxLength={MAX_LENGTH}
              maxHeight={MAX_POPOVER_HEIGHT}
              onChange={handleChange}
              onClose={handleClose}
              optionLabel="description"
              options={activities}
              showOptions={true}
              size={Size.LARGE}
              showCloseButton={!isLargeScreen}
              initialValue={selectedActivity}
            />
          </Box>
        </Popover>
      )}
    </>
  );
};

export default memo(InlineActivityField, (prev, next) => {
  prev.id === next.id && prev.initialValue?.uuid === next.initialValue?.uuid;
});
