import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import { brand } from '@/lib/theme/tokens/palettes';
import useRenderChart from '../useRenderChart';
import renderChart from './renderChart';

const Root = styled('div')({});

const PackedCircleChart = ({
  alignment,
  axisLabels,
  classes,
  color = brand.blue.main,
  data,
  ...props
}) => {
  const chartRef = useRef();

  const properties = {
    alignment,
    axisLabels,
    chartRef,
    classes: classes ?? {},
    color,
    data,
  };

  useRenderChart(properties, renderChart);

  return <Root ref={chartRef} {...props} />;
};

export default PackedCircleChart;
