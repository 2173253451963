import { AnimatePresence } from 'framer-motion';
import PropTypes from 'prop-types';
import { useState } from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import Avatar from '../Avatar';
import Dialog from '../Dialog';

const PresenceAvatarGroup = ({ max, users = 5, ...props }) => {
  const [open, setDialog] = useState(false);

  if (users.length < 1) {
    return null;
  }

  return (
    <>
      <AnimatePresence>
        <AvatarGroup max={max} onClick={() => setDialog(true)}>
          {users.map((user) => (
            <Avatar key={user.uuid} user={user} {...props} />
          ))}
        </AvatarGroup>
      </AnimatePresence>
      <Dialog users={users} open={open} onClose={() => setDialog(false)} />
    </>
  );
};

PresenceAvatarGroup.propTypes = {
  users: PropTypes.array,
};

export default PresenceAvatarGroup;
