import { useCallback, useEffect, useState } from 'react';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';

const GET_PROPERTIES = /* GraphQL */ `
  query GetProperties {
    me {
      organisation {
        properties {
          uuid
          name
          type
          scope
          options {
            uuid
            value
            order
          }
        }
      }
    }
  }
`;

export default function useRefresh() {
  const client = useClient();
  const database = useDatabase();
  const [isRefreshing, setRefreshing] = useState(false);

  const refresh = useCallback(() => {
    if (!isRefreshing) {
      setRefreshing(true);
    }
  }, []);

  const handleRefresh = async () => {
    const { data } = await client.graphql({ query: GET_PROPERTIES });

    if (!data?.me) {
      setRefreshing(false);
      return;
    }

    const newProperties = data.me.organisation.properties ?? [];
    const newPropertiesSet = new Set(newProperties.map(({ uuid }) => uuid));
    const properties = await database.properties.toArray();

    const removedProperties = properties
      .filter(({ uuid }) => !newPropertiesSet.has(uuid))
      .map(({ uuid }) => uuid);

    const promises = [
      database.hash.put({ key: 'properties', updated_at: Date.now() }),
    ];

    if (removedProperties.length) {
      promises.push(database.properties.bulkDelete(removedProperties));
    }

    if (newProperties.length) {
      promises.push(database.properties.bulkPut(newProperties));
    }

    await Promise.all(promises);
    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return {
    refresh,
    isRefreshing,
  };
}
