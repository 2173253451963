import { useEffect, useState } from 'react';
import { Size } from '@/atoms/enums';
import { CheckboxOptions, SelectOptionsSkeleton } from '@/atoms/inputs';
import { Paragraph } from '@/atoms/typography';
import { FilterSearch } from '@/molecules/filterElements';
import { PersonSummary } from '@/molecules/summaries';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { useSearch, usePeopleQuery } from '@pkg/entities/people';

const UNASSIGNED_ID = 'unassigned';

const unassignedOption = {
  id: UNASSIGNED_ID,
  uuid: null,
  name: 'Unassigned',
};

const sortOptions = (list, selectedValues = []) => {
  if (!list.length) {
    return;
  }

  const selected = new Set(
    selectedValues?.length ? selectedValues.map(({ id }) => id) : []
  );

  if (selectedValues?.length) {
    list.sort((a, b) => {
      const aSelected = selected.has(a.uuid);
      const bSelected = selected.has(b.uuid);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });
  }

  return list;
};

const PeopleSelect = ({ value, onChange }) => {
  const [enabled, setEnabled] = useState(false);
  const [exclude] = useState([]);
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState();
  const [peopleMap, setPeopleMap] = useState(new Map());
  // We use this flag to prevent continual requests for the initial list of
  // people.
  const [hasLoadedInitial, setHasLoadedInitial] = useState(false);
  // We need to load the initial list of people from their ids.
  const { map: selectedPeopleMap, isLoading: isLoadingSelected } =
    usePeopleQuery(
      hasLoadedInitial
        ? []
        : value?.filter((id) => id === UNASSIGNED_ID).map(({ id }) => id) ?? []
    );

  const { results, isLoading } = useSearch(search, {
    enabled,
    exclude,
  });

  const handleChange = (event, ids) => {
    const values = ids.map((id) => {
      const person = peopleMap?.get(id);

      if (!person) {
        return;
      }

      return {
        id: person.id,
        uuid: person.uuid,
        label: person.name,
      };
    });
    onChange(event, values);
  };

  const handleSearch = (event, term) => {
    if (isLoading) {
      return;
    }
    setEnabled(true);
    setSearch(term);
  };

  // This effect is responsible for loading the initial list of selected people
  // into the available people map so we can show them at all times.
  useEffect(() => {
    if (!hasLoadedInitial && !isLoadingSelected) {
      const map = new Map([...peopleMap, ...selectedPeopleMap]);
      setPeopleMap(map);
      setEnabled(true);
      setHasLoadedInitial(true);
    }
  }, [selectedPeopleMap?.size]);

  // This effect is responsible for setting the options list and adds the
  // selected values to the top of the list when appropriate.
  useEffect(() => {
    // Add the results to the map if they don't exist.
    const map = new Map([...peopleMap, ...selectedPeopleMap]);
    const options = [
      ...results.map((result) => ({ ...result, id: result.uuid })),
    ];

    if (
      !search ||
      unassignedOption.name.toLowerCase().includes(search.toLowerCase())
    ) {
      options.unshift(unassignedOption);
    }

    if (options?.length) {
      options.forEach((person) => {
        map.set(person.id, person);
      });

      setPeopleMap(map);
    }

    if ((!search || !options?.length) && value?.length) {
      const existingOptions = new Set(
        options?.length ? options.map(({ id }) => id) : []
      );
      // If we don't have any options, we want to load the currently selected
      // people into the options list to be displayed by default.
      value?.forEach(({ id }) => {
        if (existingOptions.has(id)) {
          return;
        }

        const person = map?.get(id);
        if (!person) {
          return;
        }
        options.push(person);
      });
    }

    setOptions(sortOptions(options, value));
  }, [results, hasLoadedInitial]);

  return (
    <>
      <FilterSearch onSearch={handleSearch} placeholder="Search for People" />
      <Box sx={{ maxHeight: 340, overflowY: 'auto' }}>
        {isLoading || !hasLoadedInitial ? (
          <Fade in={true}>
            <Box>
              <SelectOptionsSkeleton />
            </Box>
          </Fade>
        ) : (
          <Fade in={true}>
            {options?.length > 0 ? (
              <Box>
                <CheckboxOptions
                  onChange={handleChange}
                  options={options}
                  optionId="id"
                  OptionComponent={(option) => (
                    <PersonSummary
                      avatar={option.avatar}
                      person={option}
                      size={Size.X_SMALL}
                    />
                  )}
                  initialSelectedIds={value?.map((item) => item.id)}
                />
              </Box>
            ) : (
              <Box p={2}>
                <Paragraph
                  size={Size.SMALL}
                  overrideStyles={{ textAlign: 'center' }}
                >
                  No people were found matching that search term
                </Paragraph>
              </Box>
            )}
          </Fade>
        )}
      </Box>
    </>
  );
};

export default PeopleSelect;
