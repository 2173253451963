import { useMemo } from 'react';
import { Collections } from '@pkg/utils';
import config from '@/lib/config';
import { color } from '@/lib/theme/tokens';

const COLOR = {
  MAIN: {
    PRODUCTIVITY: color.core.blue,
    WORK_HEALTH: color.core.yellow,
  },
  BENCHMARK: {
    PRODUCTIVITY: color.core.navy,
    WORK_HEALTH: color.core.pink,
  },
};

/**
 * @param {Object} insights
 * @param {String} uuid
 * @returns {Object}
 */
export default function useInsights(insights, uuid) {
  return useMemo(() => {
    if (!insights) {
      return null;
    }

    const role = Collections.findById(insights.roles, uuid);

    const group = Collections.findById(insights.groups, role.benchmark_uuid);
    return role.insights.map(({ key, summary }, index) => ({
      key,
      title: config.INSIGHTS.CATEGORIES[key],
      labels: summary.map(({ key }) => config.INSIGHTS.METRICS[key]),
      summary: [
        {
          color: COLOR.MAIN[key],
          data: summary.map(({ value }) => value),
          label: 'Role',
        },
        {
          color: COLOR.BENCHMARK[key],
          data: group.insights[index].summary.map(({ value }) => value),
          label: 'Team',
        },
      ],
    }));
  }, [JSON.stringify(insights), uuid]);
}
