import { EntityType } from '@/shared/enums';
import entityChart from './entityChart';

export default function matrixChart({
  entities,
  excludeSeparators,
  sort,
  subtitles,
  titles,
}) {
  if (!entities) {
    return;
  }

  const matrixMap = new Map();

  [...entities[EntityType.MATRIX].entries()].forEach(([id, entityItem]) => {
    const position = entityItem.props?.position;

    const entity = {
      ...entityItem,
      order: entities.order,
      metrics: entities.metrics,
    };

    matrixMap.set(position, {
      data: entityChart({
        entities: entity,
        excludeSeparators,
        sort,
      }),
      props: {
        id,
        type: entityItem.type,
        title: titles?.[id],
        subtitle: subtitles?.[id],
      },
      metrics: entityItem.metrics,
    });
  });

  return {
    entitySet: entities.entitySet,
    matrixMap,
    metrics: entities.metrics,
    order: entities.order,
  };
}
