import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

/**
 * @param {Function} callback
 * @param {Number} delay
 * @returns {Function}
 */
export default function useDebounce(callback, delay) {
  const debounced = useDebouncedCallback(callback, delay);
  useEffect(() => debounced.flush, [debounced]);
  return debounced;
}
