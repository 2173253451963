/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.id
 * @param {Int} input.order
 * @returns {Object}
 */
export default function reorder(snapshot, { id, order }) {
  const entities = snapshot.entities;
  const updated = entities.roles.find((role) => role.uuid === id);
  if (!updated?.group_uuid) {
    return null;
  }

  const rolesInGroup = entities.roles.filter(
    (role) => role.group_uuid === updated.group_uuid
  );
  const now = Date.now();

  rolesInGroup.sort((a, b) => a.order - b.order);
  rolesInGroup.splice(updated.order, 1);
  rolesInGroup.splice(order, 0, updated);

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [],
      },
    },
  };

  rolesInGroup.forEach((role, index) => {
    if (role.order === index) {
      return;
    }

    mutation.entities.roles.update.push({
      uuid: role.uuid,
      order: index,
      updated_at: now,
    });
  });

  if (mutation.entities.roles.update.length === 0) {
    return null;
  }

  return mutation;
}
