import { Size } from '@/atoms/enums';

const AvatarSize = Object.freeze({
  [Size.XX_SMALL]: {
    fontSize: '0.75rem',
    width: '18px',
    height: '18px',
  },
  [Size.X_SMALL]: {
    fontSize: '0.75rem',
    width: '22px',
    height: '22px',
  },
  [Size.SMALL]: {
    fontSize: '1rem',
    width: '28px',
    height: '28px',
  },
  [Size.MEDIUM]: {
    fontSize: '1rem',
    width: '36px',
    height: '36px',
  },
  [Size.LARGE]: {
    fontSize: '1rem',
    width: '56px',
    height: '56px',
  },
  [Size.X_LARGE]: {
    fontSize: '1rem',
    width: '64px',
    height: '64px',
  },
});

export default AvatarSize;
