import { useEffect, useState } from 'react';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { DesignEntity } from '@/lib/enums';

const useActivity = (activityId) => {
  const [activity, setActivity] = useState(null);
  const { snapshotEntityMap } = useScenarioContext();

  useEffect(() => {
    if (!snapshotEntityMap?.get(DesignEntity.ACTIVITY)?.has(activityId)) {
      return;
    }

    setActivity(snapshotEntityMap.get(DesignEntity.ACTIVITY)?.get(activityId));
  }, [
    snapshotEntityMap.get(DesignEntity.ACTIVITY)?.get(activityId)?.updated_at,
  ]);

  return activity;
};

export default useActivity;
