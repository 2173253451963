import { useEffect, useRef, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { TextMetricGroup } from '@/molecules/metrics';
import { ScenarioCard } from '@/organisms/cards';
import { useInsightMetrics } from '@/organisms/insights';
import { ScopeType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';
import InsightsMenu from './InsightsMenu';

const InsightsNavigation = () => {
  const ref = useRef();
  const [excludeHeight, setExcludeHeight] = useState(200);
  const { scenario } = useScenarioContext();

  const { metrics } = useInsightMetrics({
    metricList: [
      EntityMetric.GROUPS,
      EntityMetric.ROLES,
      EntityMetric.ACTIVITIES,
      EntityMetric.FTE,
      EntityMetric.BUDGET,
    ],
    filter: {
      [ScopeType.ACTIVITIES]: {
        ids: new Set(['ALL']),
      },
    },
    ids: new Set(['ALL']),
  });

  useEffect(() => {
    if (!ref?.current?.offsetHeight) {
      return;
    }

    const height = ref.current.offsetHeight;

    setExcludeHeight(height);
  }, [ref?.current?.offsetHeight]);

  return (
    <Stack spacing={2}>
      <Box ref={ref} key={`${scenario?.entity?.uuid}`}>
        <ScenarioCard
          allowLink={false}
          linkToDesign={false}
          size={Size.SMALL}
          showLastUpdated={false}
          showPerson
          showScenarioDetails={false}
          showChip={true}
        >
          {metrics?.length > 0 && (
            <Box py={1} sx={{ color: BaseColors.font.primary }}>
              <TextMetricGroup
                alignGroup="space-between"
                metrics={metrics?.[0] || null}
                removePadding={true}
                leftPadding={1}
                rightPadding={1}
                showDividers={false}
                size={Size.X_SMALL}
                spacing={1.5}
                alignMetrics="center"
              />
            </Box>
          )}
        </ScenarioCard>
      </Box>
      <InsightsMenu excludeHeight={excludeHeight} />
    </Stack>
  );
};

export default InsightsNavigation;
