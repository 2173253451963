import FlexSearch from 'flexsearch';
import { produce } from 'immer';
import { create } from 'zustand';
import { Collections, Obj, Sort } from '@pkg/utils';
import { SortOrder } from '@/lib/enums';

const InitialIndex = new FlexSearch.Index({
  tokenize: 'forward',
  context: true,
});

/**
 * @param {Index} index
 * @param {Map} mapped
 * @returns {Index}
 */
const updateIndex = (index, mapped) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!mapped.has(key)) {
        index.remove(key);
      }
    }
  }

  mapped.forEach(({ name }, key) => {
    if (!index.register?.[key]) {
      index.add(key, name);
    }
  });

  return index;
};

/**
 * @param {Object[]} list
 * @param {Index} index
 * @returns {Object}
 */
export const storeFlowTemplates = (list, index = InitialIndex) => {
  const mapped = new Map();
  const names = {
    default: new Map(),
    lower: new Map(),
  };

  const sorted = list.sort(Sort.date('created_at', SortOrder.ASC));
  sorted.forEach((item) => {
    const id = item.uuid;
    const name = item.name;

    mapped.set(id, item);
    names.default.set(name, id);
    names.lower.set(name.toLowerCase(), id);
  });

  return {
    names,
    index: updateIndex(index, mapped),
    list: sorted,
    mapped,
  };
};

/**
 * @type {Function}
 * @param {Function} [getter]
 * @returns {any}
 */
const useStore = create((set, get) => ({
  names: {
    default: new Map(),
    lower: new Map(),
  },
  list: [],
  index: InitialIndex,
  isLoading: true,
  mapped: new Map(),
  mutations: {},
  setLoading: (isLoading) => set(() => ({ isLoading })),
  setMutations: (mutations) => set(() => ({ mutations })),
  setFlowTemplates: (list) =>
    set(({ index }) => storeFlowTemplates(list, index)),

  /**
   * @param {Object} flow
   * @param {String} flow.uuid
   * @param {String} flow.name
   * @returns {void}
   */
  add: (properties) =>
    set(
      produce((state) => {
        const { uuid, name } = properties;
        state.names.default.set(uuid, name);
        state.names.lower.set(uuid, name.toLowerCase());
        state.index.add(uuid, name);
        state.list.push({ uuid, properties });
        state.list.sort(Sort.date('created_at', SortOrder.ASC));
        state.mapped.set(uuid, { uuid, ...properties });
      })
    ),

  /**
   * @param {String} id
   * @returns {void}
   */
  remove: (id) => {
    if (!get().mapped.has(id)) {
      return;
    }

    return set(
      produce((state) => {
        const index = Collections.indexById(state.list, id);

        state.index.remove(id);
        state.list.splice(index, 1);
        state.mapped.delete(id);
      })
    );
  },

  /**
   * Spread an update over an existing flow template
   * @param {String} id
   * @param {Object} update
   * @returns {void}
   */
  update: (id, update) =>
    set(
      produce((state) => {
        if (!state.mapped.has(id)) {
          return;
        }

        const flowTemplate = { ...state.mapped.get(id), ...update };
        const index = Collections.indexById(state.list, id);

        state.index.update(id, flowTemplate.name);
        state.list[index] = flowTemplate;
        state.mapped.set(id, flowTemplate);
      })
    ),

  /**
   * @param {String} name
   * @param {Boolean} strict
   * @returns {String|null}
   */
  getExisting: (name, strict = false) => {
    const map = get().names[strict ? 'default' : 'lower'];
    const value = strict ? name : name.toLowerCase();
    return map.get(value) ?? null;
  },
}));

export default useStore;
