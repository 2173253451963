import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';

const REMOVE_PROPERTY = /* GraphQL */ `
  mutation RemoveLibraryProperty($input: RemoveLibraryPropertyInput!) {
    removeLibraryProperty(input: $input)
  }
`;

/**
 * @return {Function}
 */
export default function useRemove() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({
    mutation: REMOVE_PROPERTY,
  });

  /**
   * @param {String} uuid
   * @returns {Promise}
   */
  function removeProperty(uuid) {
    database.properties.delete(uuid);
    return mutateAsync({ uuid });
  }

  return useCallback(removeProperty, [mutateAsync]);
}
