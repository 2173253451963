import { useMemo } from 'react';
import { related } from '../get';

/**
 * @param {Object} snapshot (keyed)
 * @returns {Object}
 */
export default function useRelatedEntities(snapshot) {
  return useMemo(() => {
    return related(snapshot?.entities);
  }, [snapshot?.__hash]);
}
