/**
 * @param {Number} value
 * @return {Number}
 */
export default function roundFloat(value) {
  const parsed = parseFloat(value);

  if (!Number.isFinite(parsed)) {
    return value;
  }

  return Math.round(parsed * 100) / 100;
}
