import design from './designParts';

/**
 * @param {string} link
 * @param {Object} [parts]
 * @return {String}
 */
export default function invite(link, parts) {
  const path = encodeURIComponent(design(parts).replace('/', ''));
  return `${link}${path}`;
}
