import { Size } from '@/atoms/enums';

/**
 * Returns style properties for input fields based on the size.
 * @param {String} size
 * @return {Object}
 */
export default function inputSizeProps(size) {
  const sizeProps = {
    fontSize: '0.875rem',
    fontWeight: 500,
    height: '1.75rem',
    lineHeight: '1.75rem',
  };

  switch (size) {
    case Size.SMALL:
      sizeProps.fontSize = '0.825rem';
      sizeProps.height = '1.75rem';
      sizeProps.lineHeight = '1.75rem';
      return sizeProps;
    case Size.LARGE:
      sizeProps.fontSize = '0.925rem';
      sizeProps.fontWeight = 400;
      sizeProps.height = '2.125rem';
      sizeProps.lineHeight = '2.125rem';
      return sizeProps;
    case Size.X_LARGE:
      sizeProps.fontSize = '0.9rem';
      sizeProps.height = '2.25rem';
      sizeProps.lineHeight = '2.25rem';
      return sizeProps;
    default:
      return sizeProps;
  }
}
