import { useMemo } from 'react';
import * as Library from '../library';
import deriveProps from './deriveProps';

/**
 * @param {Object|Object[]} people
 * @param {Object} [options]
 * @return {Object|Object[]}
 */
export default function useDerivedProps(people, options = {}) {
  const library = Library.useLibrary();
  return useMemo(() => {
    if (Array.isArray(people)) {
      return people.map((person) => deriveProps(library, person, options));
    }

    return deriveProps(library, people, options);
  }, [people, JSON.stringify(options)]);
}
