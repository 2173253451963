const BeamibleScoreColor = Object.freeze({
  HIGH: {
    foreground: '#61c26b',
    background: '#e8e8e8',
  },
  MEDIUM: {
    foreground: '#ffaf5c',
    background: '#e8e8e8',
  },
  LOW: {
    foreground: '#e35858',
    background: '#e8e8e8',
  },
});

export default BeamibleScoreColor;
