import { EntityType } from '@/shared/enums';

const organisationDimensions = [
  {
    id: `${EntityType.ROLE}:${EntityType.GROUP}:${EntityType.PERSON}`,
    label: 'Role > Team > Person',
    order: [EntityType.ROLE, EntityType.GROUP, EntityType.PERSON],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.PERSON}:${EntityType.GROUP}`,
    label: 'Role > Person > Team',
    order: [EntityType.ROLE, EntityType.PERSON, EntityType.GROUP],
  },
  {
    id: `${EntityType.GROUP}:${EntityType.ROLE}:${EntityType.PERSON}`,
    label: 'Team > Role > Person',
    order: [EntityType.GROUP, EntityType.ROLE, EntityType.PERSON],
  },
];

const roleDimensions = [
  {
    id: `${EntityType.ROLE}`,
    label: 'Role',
    order: [EntityType.ROLE],
  },
];

const ManagerDimensions = {
  [EntityType.GROUP]: organisationDimensions,
  [EntityType.MANAGER]: organisationDimensions,
  [EntityType.ORGANISATION]: organisationDimensions,
  [EntityType.ROLE]: roleDimensions,
};

export default ManagerDimensions;
