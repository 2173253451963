import { RouteLevel } from '@/lib/enums';

/**
 * @param {Object} router
 * @param {Object} parts
 * @param {String} [parts.designId]
 * @param {String} [parts.revisionId]
 * @param {Object} [parts.level]
 * @param {String} [parts.level.type]
 * @param {String} [parts.level.uuid]
 * @param {Object} [parts.hash]
 * @param {String} [parts.hash.dialog]
 * @param {String} [parts.hash.expand]
 * @param {String} [parts.hash.layout]
 */
export default function pushDesignParts(router, parts = {}) {
  const { designId = 'main', revisionId = 'latest', level, hash } = parts;
  const pattern = /\/d\/([0-9a-zA-Z]+)\/([0-9a-zA-Z]+)(\/.*)?/;
  let replacement = `/d/${designId ?? '$1'}/${revisionId ?? '$2'}`;

  switch (true) {
    case level === undefined:
      replacement += '$3';
      break;

    case Object.keys(RouteLevel).includes(level?.type):
      replacement += `/${RouteLevel[level.type]}/${level.uuid}`;
      break;
  }

  let route = router.asPath.replace(pattern, replacement);

  if (hash) {
    const items = Object.entries(hash).map(([key, value]) => `${key}=${value}`);
    route += `#${items.join(';')}`;
  }

  router.push(route);
}
