import { EntityType } from '@/shared/enums';

/**
 * Returns the manager for a given role.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function getManagerFromRole({ role, snapshotEntityMap }) {
  const unManaged = {
    title: 'No manager',
    uuid: 'noManager',
    isNotManaged: true,
  };

  const manager = snapshotEntityMap.get(EntityType.ROLE).get(role?.__manager);

  return manager ?? unManaged;
}
