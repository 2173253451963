import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function removedActivity({
  activity,
  comparisonScenario,
  role,
}) {
  const action = {
    type: ActionType.REMOVED_ACTIVITY,
    [EntityType.ACTIVITY]: new Map([
      [
        activity.uuid,
        {
          metrics: {
            hours: activity.hours,
          },
        },
      ],
    ]),
    metrics: defaultMetrics(comparisonScenario),
  };

  if (role) {
    action.props = {
      to: role.uuid,
    };
  }

  action.metrics[1].activities -= 1;
  action.metrics[1].hours -= activity.hours;

  return action;
}
