import { Checkbox } from '@/atoms/inputs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const CheckboxField = ({ children, initialValue, label, variant }) => {
  return (
    <Stack direction="row" space={1}>
      <Box>
        <Checkbox initialValue={initialValue} label={label} variant={variant} />
      </Box>
      <Box>{children}</Box>
    </Stack>
  );
};

export default CheckboxField;
