import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import LoadingButton from '@pkg/forms/LoadingButton';
import DialogAction from '@/components/DialogAction';

const ToolbarAction = ({
  label,
  href,
  onClick,
  className,
  dialogProps,
  ...props
}) => {
  const router = useRouter();

  function handleClick() {
    if (onClick) {
      return onClick();
    } else if (href) {
      return router.push(href);
    }
  }

  function Btn(parentProps) {
    return (
      <LoadingButton
        onClick={handleClick}
        size="small"
        variant="primary"
        {...parentProps}
        {...props}
        className={className}
      >
        {label}
      </LoadingButton>
    );
  }

  if (dialogProps) {
    return (
      <DialogAction {...dialogProps}>
        <Btn />
      </DialogAction>
    );
  }

  return <Btn />;
};

ToolbarAction.propTypes = {
  label: PropTypes.node,
  href: PropTypes.string,
  dialogProps: PropTypes.object,
};

export default ToolbarAction;
