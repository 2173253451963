import { EntityType } from '@/shared/enums';
import {
  useViewModeContext,
  useScenarioContext,
  usePropertyContext,
} from '@/shared/providers';
import Grid from '@mui/material/Grid';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import ActivitySegments from './ActivitySegments';
import PropertySegments from './PropertySegments';
import RolesByTeam from './RolesByTeam';

const RoleInsights = () => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { id, scope, scopeEntity, showBudget } = useViewModeContext();
  const { propertyMap } = usePropertyContext();
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;

  return (
    <Grid container spacing={2} pb={4}>
      <Grid item xs={12}>
        <InsightsTitle
          entityMap={snapshotEntityMap}
          id={id}
          scope={scope}
          scopeEntity={scopeEntity}
        />
      </Grid>
      {scenario && (
        <>
          <Grid item xs={12}>
            <RolesByTeam levelType={entityType} />
          </Grid>
          <Grid item xs={12}>
            <ActivitySegments levelType={entityType} />
          </Grid>
          <Grid item xs={12}>
            <PropertySegments levelType={entityType} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RoleInsights;
