import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { OutlineCard } from '@/organisms/cards';
import NavigateIcon from '@mui/icons-material/PlayCircleOutlineRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { brand } from '@/lib/theme/tokens/palettes';

const MenuOptionCard = ({ title, subtitle, featureIcon, navigationIcon }) => {
  return (
    <OutlineCard padding={0} margin={0}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        p={2}
        sx={{
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'box-shadow 300ms',
          '&:hover': {
            boxShadow: `inset 0 0 0 2px ${brand.yellow.tints[4]}`,
          },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2}>
          {featureIcon}
          <Box>
            {Boolean(title) && (
              <Heading
                variant="h3"
                overrideStyles={{ fontWeight: 600, mb: 0.5 }}
              >
                {title}
              </Heading>
            )}
            {Boolean(subtitle) && (
              <Paragraph size={Size.SMALL} overrideStyles={{ mb: 0 }}>
                {subtitle}
              </Paragraph>
            )}
          </Box>
        </Stack>
        <NavigateIcon sx={{ width: 38, height: 38 }} />
      </Stack>
    </OutlineCard>
  );
};

export default MenuOptionCard;
