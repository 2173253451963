import { useSnackbar } from 'notistack';
import { useDatabase } from '@pkg/database';
import { People } from '@pkg/entities';
import { useMutation } from '@pkg/graphql';
import { CollaboratorFragment } from '../fragments';
import useStore from '../useStore';

const ADD_COLLABORATOR = /* GraphQL */ `
  mutation AddCollaborator($input: AddCollaboratorInput!) {
    addCollaborator(input: $input) {
      is_public
      collaborators {
        ...CollaboratorFragment
      }
    }
  }

  ${CollaboratorFragment}
`;

export default function useAddCollaborator() {
  const { mutateAsync } = useMutation({ mutation: ADD_COLLABORATOR });
  const { enqueueSnackbar } = useSnackbar();

  const database = useDatabase();
  const { update } = useStore();

  /**
   * @param {Object} designId
   * @param {Object} userId
   * @param {String} role
   * @param {Bool} [invite]
   * @param {Object} [level]
   * @returns {Promise}
   */
  async function addCollaborator(designId, userId, role, invite, level) {
    if (invite) {
      enqueueSnackbar('Inviting person to collaborate.');
    }

    const input = { design_uuid: designId, user_uuid: userId, invite, level };
    input.auth_role = People.access.toEnum(role);

    const result = await mutateAsync(input);
    const sharing = result?.data?.addCollaborator;

    if (sharing) {
      update(designId, sharing);
      await database.designs.update(designId, sharing);
    }

    return result;
  }

  return addCollaborator;
}
