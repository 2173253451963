import { Colors } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

export default function entityChipColors(entityType) {
  const color = Colors.entity(entityType);
  const colors = {
    background: color.tints[1],
    border: color.main,
    font: color.main,
    highlight: color.tints[1],
  };

  if (entityType === DesignEntity.ROLE) {
    colors.highlight = color.tints[2];
  }

  if (entityType === DesignEntity.ORGANISATION) {
    colors.highlight = color.tints[2];
  }

  return colors;
}
