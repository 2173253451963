import { useQuery } from '@pkg/graphql';

const SUGGEST_TEMPLATE = /* GraphQL */ `
  query SuggestPriority(
    $designId: String!
    $entityId: String!
    $entityType: String!
  ) {
    suggestPriority(
      designId: $designId
      entityId: $entityId
      entityType: $entityType
    )
  }
`;

export default function useSuggestion(
  { designId, entityType, entityId },
  enabled
) {
  const query = [
    SUGGEST_TEMPLATE,
    {
      designId,
      entityType,
      entityId,
    },
  ];
  const { data, refetch, isLoading, isFetching, remove } = useQuery({
    query,
    enabled,
  });

  const suggestion =
    data?.suggestPriority || 'Unfortunately, we could not make a suggestion';

  return {
    suggestion,
    refetch,
    isLoading,
    isFetching,
    remove,
  };
}
