import { useMemo } from 'react';
import * as Mutations from './mutations';

/**
 * @param {any[]} query
 * @return {Object}
 */
export default function useMutations(query) {
  const mutations = Object.freeze({
    addCollaborator: Mutations.useAddCollaborator(query),
    archiveScenario: Mutations.useArchiveScenario(),
    createScenario: Mutations.useCreateScenario(),
    createGroup: Mutations.useCreateGroup(),
    copyRole: Mutations.useCopyRole(),
    mutate: Mutations.useMutate(query),
    publish: Mutations.usePublish(),
    publishGroup: Mutations.usePublishGroup(),
    publishRole: Mutations.usePublishRole(),
    removeCollaborator: Mutations.useRemoveCollaborator(query),
    removeRole: Mutations.useRemoveRole(),
    restore: Mutations.useRestore(query),
    update: Mutations.useUpdate(query),
  });

  return useMemo(() => mutations, [query]);
}
