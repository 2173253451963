import { useMemo } from 'react';
import getRelated from './getRelated';

/**
 * @param {Object} snapshot
 * @param {Number} [hash]
 * @returns {Object}
 */
export default function useRelated(snapshot) {
  return useMemo(() => {
    return getRelated(snapshot);
  }, [snapshot]);
}
