import { Collections, Sort } from '@pkg/utils';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {String[]} ids
 * @returns {Object}
 */
export default function archive(snapshot, ids) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        remove: [],
        update: [],
      },
    },
  };

  const keyed = Collections.keyById(structuredClone(entities.activities));
  const touchedGroups = new Set();
  const touchedRoles = new Set();

  ids.forEach((id) => {
    if (!Object.hasOwn(keyed, id)) {
      return;
    }

    const activity = keyed[id];

    if (activity.owner_type === ActivityOwner.GROUP) {
      touchedGroups.add(activity.owner_uuid);
    } else {
      touchedRoles.add(activity.owner_uuid);
    }

    mutation.entities.activities.remove.push(id);
    delete keyed[id];
  });

  const grouped = Collections.groupBy(Object.values(keyed), 'owner_uuid');
  Object.keys(grouped).forEach((id) => {
    grouped[id].sort(Sort.order()).forEach((activity, order) => {
      if (activity.order !== order) {
        mutation.entities.activities.update.push({
          uuid: activity.uuid,
          order,
          updated_at: now,
        });
      }
    });
  });

  touchedRoles.forEach((id) => {
    mutation.entities.roles.update.push({ uuid: id, updated_at: now });
  });

  touchedGroups.forEach((id) => {
    mutation.entities.groups.update.push({ uuid: id, updated_at: now });
  });

  return mutation;
}
