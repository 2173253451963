import { Heading } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import { brand } from '@/lib/theme/tokens/palettes';

const SummaryHeading = ({ title }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      px={2}
      sx={{
        backgroundColor: brand.grey.tints[0],
      }}
    >
      <Heading
        variant="h6"
        overrideStyles={{
          fontWeight: 600,
          fontSize: '0.825rem',
          lineHeight: '1.375rem',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </Heading>
    </Stack>
  );
};

export default SummaryHeading;
