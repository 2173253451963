import { Sort } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

const TYPE_INTERFACE = {
  __order: ['primary'],
  primary: {
    uuid: 'primary',
    name: 'Primary',
    tags: { __order: [] },
    taggables: new Set([
      DesignEntity.GROUP,
      DesignEntity.ROLE,
      DesignEntity.ACTIVITY,
    ]),
  },
};

export const DEFAULT_ORDERED = {
  system: structuredClone(TYPE_INTERFACE),
  custom: structuredClone(TYPE_INTERFACE),
  __order: ['system', 'custom'],
};

const getType = ({ is_system }) => {
  return is_system ? 'system' : 'custom';
};

/**
 * @param {Object[]} tags
 * @param {Object[]} categories
 * @param {Boolean} includeDisabled
 * @returns
 */
export default function getOrdered(tags, categories) {
  console.time('Tags.getOrdered');
  const ordered = categories.reduce((acc, category) => {
    const type = getType(category);
    acc[type].__order.push(category.uuid);
    acc[type][category.uuid] = {
      ...category,
      __taggables_set: new Set(category.taggables),
      tags: { __order: [] },
    };

    return acc;
  }, structuredClone(DEFAULT_ORDERED));

  tags.forEach((tag) => {
    const type = getType(tag);
    ordered[type][tag.category_uuid ?? 'primary'].tags.__order.push(tag.uuid);
    ordered[type][tag.category_uuid ?? 'primary'].tags[tag.uuid] = tag;
  });

  ordered.__order.forEach((type) => {
    ordered[type].__order.sort(Sort.tags(ordered[type]));
    Object.keys(ordered[type]).forEach((category) => {
      if (category === '__order') {
        return;
      }

      const tags = ordered[type][category].tags;
      ordered[type][category].tags.__order.sort(Sort.tags(tags));
    });
  });

  console.timeEnd('Tags.getOrdered');
  return ordered;
}
