import { Information, InformationParagraph } from '@/molecules/information';

const ActivityBarChartInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        This chart presents activities based on the relevant entity that you are
        currently viewing. Activities are listed from largest to smallest based
        on the metric selected.
      </InformationParagraph>
      <InformationParagraph>
        Select from the 'Activity by' dropdown to present the data in the
        preferred format.
      </InformationParagraph>
      <InformationParagraph>
        Select the preferred 'Metric Type' from the dropdown to present the
        chart in percentage, hours, FTE or budget (if permitted).
      </InformationParagraph>
      <InformationParagraph>
        Click on the activity circle on the chart to expand and view further
        information about the activity or entity.
      </InformationParagraph>
    </Information>
  );
};

export default ActivityBarChartInformation;
