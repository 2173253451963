import { useMemo } from 'react';
import { useQueries } from '@pkg/graphql';
import * as Library from '../library';
import deriveProps from './deriveProps';
import { GET_PERSON } from './useQuery';

export default function usePeopleQueries(ids = []) {
  const library = Library.useLibrary();
  const queries = useMemo(() => {
    return ids.map((uuid) => [GET_PERSON, { uuid }]);
  }, [JSON.stringify(ids)]);

  const { results } = useQueries({
    queries,
    enabled: Boolean(queries.length),
  });

  return useMemo(() => {
    const isLoading = false;
    const map = new Map();
    const list = [];

    results.forEach(({ isLoading, data }) => {
      if (isLoading) {
        isLoading = true;
        return;
      }

      if (!data?.user) {
        return;
      }

      const person = deriveProps(library, data.user);
      map.set(person.uuid, person);
      list.push(person);
    });

    return { map, list, isLoading };
  }, [JSON.stringify(results)]);
}
