import { useState } from 'react';
import { Skill as SkillColors } from '@/atoms/colors';
import { Orientation } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { ColorLegend } from '@/molecules/legends';
import { ucfirst } from '@pkg/utils/strings';
import { SkillLevel } from '@/lib/enums';

const SkillLegend = () => {
  const [skillItems] = useState(
    Object.keys(SkillLevel).map((key) => ({
      color: SkillColors[key]?.normal?.backgroundColor,
      label: ucfirst(key, true),
    }))
  );

  return (
    <>
      <Heading variant="h6" overrideStyles={{ mb: 1 }}>
        Skill level:
      </Heading>
      <ColorLegend items={skillItems} orientation={Orientation.HORIZONTAL} />
    </>
  );
};

export default SkillLegend;
