import { useEffect, useState } from 'react';
import { EntityType } from '@/shared/enums';
import { useEntityHierarchyContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import { useTreeViewApiRef } from '@mui/x-tree-view';
import { RichTreeView } from '@mui/x-tree-view/RichTreeView';
import EntityHierarchyItem from '../EntityHierarchyItem/EntityHierarchyItem';
import { getLabel, getParentIds } from './utils';

const HierarchyTree = ({ activeItem, onClose }) => {
  const apiRef = useTreeViewApiRef();
  const { entityMap, hierarchy } = useEntityHierarchyContext();
  const entityType =
    activeItem?.type === EntityType.MANAGER
      ? EntityType.ROLE
      : activeItem?.type;

  const [expandedItems, setExpandedItems] = useState(
    activeItem
      ? getParentIds({
          id: activeItem.uuid,
          entityType: entityType,
          entityMap,
          hierarchy,
        })
      : []
  );

  // Focuses on the currently active item in the hierarchy on load.
  useEffect(() => {
    if (!apiRef.current || !activeItem) {
      return;
    }

    apiRef.current.focusItem(null, `${entityType}:${activeItem?.uuid}`);
  }, [apiRef]);

  return (
    <Box p={1} pb={1.5}>
      <RichTreeView
        apiRef={apiRef}
        defaultExpandedItems={expandedItems}
        defaultSelectedItems={
          activeItem ? `${entityType}:${activeItem.uuid}` : null
        }
        items={hierarchy}
        slots={{
          item: EntityHierarchyItem,
        }}
        slotProps={{
          item: {
            onClose,
            activeId: activeItem?.uuid,
          },
        }}
        getItemId={({ uuid, type }) => `${type}:${uuid}`}
        getItemLabel={({ uuid, type }) => getLabel(uuid, type, entityMap)}
      />
    </Box>
  );
};

export default HierarchyTree;
