import { SkillLevel } from '@/lib/enums';
import { color, palettes } from '@/lib/theme/tokens';

export const LevelColors = {
  [SkillLevel.BASIC]: {
    color: palettes.brand.blue.tints[5],
    stroke: color.core.white,
  },
  [SkillLevel.INTERMEDIATE]: {
    color: palettes.brand.blue.shades[2],
    stroke: color.core.white,
  },
  [SkillLevel.ADVANCED]: {
    color: palettes.brand.blue.shades[4],
    stroke: color.core.white,
  },
};
