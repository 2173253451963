import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function updatedHours({
  actionMap,
  activity,
  comparisonScenario,
  scenario,
}) {
  const originalActivity = comparisonScenario.relationships
    .get(EntityType.ACTIVITY)
    ?.get(activity?.uuid);
  const role =
    activity.owner_type === EntityType.ROLE
      ? scenario.relationships.get(EntityType.ROLE)?.get(activity.owner_uuid)
      : null;
  const group = role
    ? scenario?.relationships.get(EntityType.GROUP)?.get(role.group_uuid)
    : scenario?.relationships.get(EntityType.GROUP)?.get(activity.owner_uuid);

  const metrics = {
    hours: activity.hours,
  };

  const existingAction = actionMap?.get(ActionType.UPDATE_HOURS);

  const action = existingAction ?? {
    entity: EntityType.ACTIVITY,
    metrics: defaultMetrics(comparisonScenario),
    type: ActionType.UPDATE_HOURS,
    [EntityType.ACTIVITY]: new Map(),
    [EntityType.ROLE]: new Set(),
    [EntityType.GROUP]: new Set(),
  };

  action[EntityType.ACTIVITY].set(activity.uuid, { metrics });

  if (role) {
    action[EntityType.ROLE].add(role.uuid);
  }
  if (group) {
    action[EntityType.GROUP].add(group.uuid);
  }

  const hoursChange = metrics.hours - originalActivity.hours;
  action.metrics[1].hours += hoursChange;

  const activityCount = action[EntityType.ACTIVITY].size;
  const roleCount = action[EntityType.ROLE].size;

  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const activityLabel = entityLabel(EntityType.ACTIVITY, activityCount !== 1);
  const verb = roleCount === 1 ? 'in' : 'across';

  action.title = `Hours were updated for ${activityCount} ${activityLabel} ${verb} ${roleCount} ${roleLabel}`;

  return action;
}
