import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';

const CREATE_ACTIVITY = /* GraphQL */ `
  mutation CreateLibraryActivity($input: CreateLibraryActivityInput!) {
    createLibraryActivity(input: $input) {
      uuid
      description
    }
  }
`;

export default function useCreate() {
  const database = useDatabase();
  const { add } = useStore();
  const { mutateAsync } = useMutation({ mutation: CREATE_ACTIVITY });

  /**
   * @param {String} uuid
   * @param {String} description
   * @returns {Promise}
   */
  async function createActivity(uuid, description) {
    const input = { uuid, description };
    add(input);
    await database.activities.put(input);
    return await mutateAsync(input);
  }

  return useCallback(createActivity, [mutateAsync]);
}
