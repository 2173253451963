import Link from 'next/link';
import { Avatar } from '@/atoms/avatars';
import { Tooltip } from '@/molecules/modals';
import BusinessIcon from '@mui/icons-material/Business';
import ExitIcon from '@mui/icons-material/ExitToApp';
import HelpIcon from '@mui/icons-material/LiveHelp';
import Stack from '@mui/material/Stack';
import { useAccess } from '@pkg/access/organisations';
import * as Auth from '@pkg/auth';
import config from '@/lib/config';
import { core } from '@/lib/theme/tokens/palettes';

const ProfileNavigation = () => {
  const { me, organisation } = Auth.useStore();
  const { logout } = Auth.useLogout();
  const access = useAccess();
  const hasAdminAccess = access.model.manage;

  const isRoleOnly = Auth.useStore((state) => state.me.is_role_only);
  const isRestricted = access.isRestricted;

  const showManagerRestricted = !isRestricted || (isRestricted && !isRoleOnly);
  const showAdminRestricted = !isRestricted || (isRestricted && hasAdminAccess);

  const handleOpenIntercom = () => {
    window.Intercom?.('show');
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      {showManagerRestricted && me && (
        <Tooltip title="My profile">
          <Link href={me.__pathname}>
            <Avatar
              name={me.__name}
              src={me.avatar}
              sizeProps={{
                width: '24px',
                height: '24px',
                fontSize: '0.75rem',
              }}
            />
          </Link>
        </Tooltip>
      )}
      {showAdminRestricted && (
        <Tooltip title={organisation?.name}>
          <Link href={config.PATHS.ACCOUNT.index}>
            <BusinessIcon />
          </Link>
        </Tooltip>
      )}
      {window.Intercom && (
        <Tooltip title="Help / FAQ">
          <Link href="" onClick={handleOpenIntercom}>
            <HelpIcon />
          </Link>
        </Tooltip>
      )}
      <Tooltip title="Sign out">
        <Stack
          onClick={handleLogout}
          sx={{
            color: core.white,
            cursor: 'pointer',
          }}
        >
          <ExitIcon />
        </Stack>
      </Tooltip>
    </Stack>
  );
};

export default ProfileNavigation;
