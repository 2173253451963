import { Collections } from '@pkg/utils';

/**
 * @param {Object} property
 * @param {Array} options
 */
export default function mutate(property, options) {
  const mutation = {
    create: [],
    update: [],
    remove: [],
  };

  const propertyOptions = structuredClone(property?.options ?? []);
  const currentOptions = new Map(propertyOptions.map((opt) => [opt.uuid, opt]));
  let isDifferent = false;

  options.forEach((option) => {
    const existing = currentOptions.get(option.uuid);

    if (!existing) {
      mutation.create.push(option);
      isDifferent = true;
      return;
    }

    if (option.value !== existing.value || option.order !== existing.order) {
      mutation.update.push(option);
      isDifferent = true;
    }
  });

  const keyedOptions = Collections.keyById(options);

  currentOptions.forEach((option) => {
    if (!keyedOptions[option.uuid]) {
      mutation.remove.push(option.uuid);
      isDifferent = true;
    }
  });

  if (!isDifferent) {
    return null;
  }

  return mutation;
}
