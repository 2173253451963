import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';

const UPDATE_SKILL = /* GraphQL */ `
  mutation UpdateLibrarySkill($input: UpdateLibrarySkillInput!) {
    updateLibrarySkill(input: $input) {
      uuid
      name
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const { mutateAsync } = useMutation({ mutation: UPDATE_SKILL });

  const database = useDatabase();
  const { update } = useStore();

  /**
   * Optimistically update skill in storage
   * @todo handle failure
   */
  async function optimisticUpdate(skillId, input) {
    update(skillId, input);
    await database.skills.update(skillId, input);
  }

  /**
   * @param {String} skillId
   * @param {Object} input
   * @param {String} input.name
   * @returns {Promise}
   */
  async function updateSkill(skillId, input) {
    await optimisticUpdate(skillId, input);
    return mutateAsync({ uuid: skillId, ...input });
  }

  return useCallback(updateSkill, [mutateAsync, database, update]);
}
