import InputLabel from '@mui/material/InputLabel';
import MuiToggleButton from '@mui/material/ToggleButton';
import MuiToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';
import { color } from '@/lib/theme/tokens';
import Box from '@/components/Box';

const IconToggle = styled(MuiToggleButton)({
  paddingTop: 5,
  paddingBottom: 5,
  '.MuiSvgIcon-root': {
    marginRight: 5,
  },
});

const ToggleButtonGroup = styled(MuiToggleButtonGroup)({
  backgroundColor: color.core.white,
});

const ToggleButton = ({ label, mode, options, onChange }) => {
  return (
    <Box>
      {Boolean(label) && <InputLabel>{label}</InputLabel>}
      {Boolean(options?.length) && (
        <ToggleButtonGroup
          color="primary"
          exclusive
          onChange={onChange}
          value={mode}
        >
          {options?.map((option) => (
            <IconToggle key={option.key} size="small" value={option.key}>
              {Boolean(option.icon) && <>{option.icon}</>}
              {Boolean(option.label) && <>{option.label}</>}
            </IconToggle>
          ))}
        </ToggleButtonGroup>
      )}
    </Box>
  );
};

export default ToggleButton;
