/**
 * @param {String} value
 * @returns {String}
 */
export default function initials(value = '') {
  const safeValue = String(value);
  const firstChar = safeValue.charAt(0);
  let lastChar = safeValue.charAt(safeValue.length - 1);

  if (safeValue.includes(' ')) {
    const parts = safeValue.split(' ');
    lastChar = parts[parts.length - 1].charAt(0);
  }

  return `${firstChar}${lastChar}`.toUpperCase();
}
