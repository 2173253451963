import { EntityType } from '@/shared/enums';
import config from '@/lib/config';

const getMetric = ({ activityPercentage, entityType, metric, roleMetrics }) => {
  if (entityType === EntityType.ACTIVITY) {
    return activityPercentage && roleMetrics
      ? activityPercentage * roleMetrics[metric]
      : 0;
  }

  return roleMetrics?.[metric] ?? 0;
};

/**
 * Returns a metrics object based on the entity type.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function getMetrics({
  activity,
  entityType,
  group,
  ignoreSpan,
  manager,
  role,
}) {
  const roleMetrics = role?.__metrics?.self?.visible;
  const groupMetrics = group?.__metrics?.self?.visible;

  const activityPercentage =
    activity && roleMetrics ? activity.hours / roleMetrics.hours : 0;

  const fteHours =
    roleMetrics && roleMetrics.fte > 0
      ? roleMetrics.fte * config.FTE_HOURS_PER_WEEK
      : 0;

  const hours =
    entityType === EntityType.ACTIVITY
      ? activity.hours
      : roleMetrics.hours || fteHours;

  // A span is added if this role has a manager. This value is accumulated
  // across the nested map.
  const span = entityType === EntityType.ROLE && role.__manager ? 1 : 0;
  // The managedSpan is all spans associed with a manager if they exist. The
  // managed span should be unique for a manger in the nested map.
  const managedSpan = manager?.__metrics
    ? manager.__metrics.span.visible.roles
    : 0;

  const budget = getMetric({
    activityPercentage,
    entityType,
    metric: 'budget',
    roleMetrics,
  });

  const fte = getMetric({
    activityPercentage,
    entityType,
    metric: 'fte',
    roleMetrics,
  });

  return {
    budget,
    fte,
    group: groupMetrics ? { ...groupMetrics } : null,
    hours,
    role: roleMetrics ? { ...roleMetrics } : null,
    span: role.__manager && !ignoreSpan ? 1 : 0,
    managedSpan,
  };
}
