import PropTypes from 'prop-types';
import { memo } from 'react';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { useLocalState } from '@pkg/hooks';
import Close from '@pkg/icons/Close';
import excludeProps from '@/lib/theme/excludeProps';
import { color } from '@/lib/theme/tokens';
import { grey } from '@/lib/theme/tokens/palettes';

const Root = styled(FormControl, {
  shouldForwardProp: excludeProps(['fullWidth']),
})(({ fullWidth }) => ({
  width: fullWidth ? '100%' : 'auto',
}));

const Flex = styled('div', {
  shouldForwardProp: excludeProps(['labelPosition']),
})(({ labelPosition }) => {
  const base = {
    display: 'flex',
    justifyContent: 'flex-end',
  };

  switch (labelPosition) {
    case 'above':
      return { ...base, flexDirection: 'column !important' };
    case 'left':
      return {
        ...base,
        alignItems: 'center',
        flexDirection: 'row !important',
      };
    case 'right':
      return {
        ...base,
        alignItems: 'center',
        flexDirection: 'row-reverse !important',
      };
  }
});

const Label = styled(FormLabel, {
  shouldForwardProp: excludeProps(['position']),
})(({ position }) => ({
  lineHeight: `${position === 'above' ? 20 : 3}px !important`,
  marginBottom: `${position === 'above' ? 2 : 0}px !important`,
  marginLeft: `${position === 'right' ? 8 : 0}px !important`,
  marginRight: `${position === 'left' ? 8 : 0}px !important`,
}));

const Input = styled(OutlinedInput)({
  '& input': {
    color: grey.colors[700],
    '&:disabled': {
      backgroundColor: grey.colors[50],
      color: grey.colors[300],
    },
    '&::placeholder': {
      color: grey.colors[300],
      opacity: 1,
    },
  },
});

const Error = styled('label')({
  color: color.system.destructive[300],
  fontSize: 12,
  fontWeight: 400,
});

const HelperText = styled(FormHelperText)({
  margin: '4px 8px 0 2px',
});

const TextInput = ({
  className,
  disableClear = false,
  error,
  fullWidth = false,
  helperText,
  initialValue,
  label,
  labelPosition = 'above',
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  readOnly = false,
  resetsOnEmpty = false,
  selectOnFocus = false,
  ...props
}) => {
  const [value, setValue] = useLocalState(props.value ?? '');

  const handleBlur = (event) => {
    let value = event.target.value;

    if (resetsOnEmpty && event.target.value === '') {
      value = initialValue ?? props.value;
    }

    setValue(value);
    onBlur?.(event, value);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    onChange?.(event, event.target.value);
  };

  const handleClear = (event) => {
    event.target.value = '';

    if (onBlur) {
      event.target.blur();
    } else {
      handleChange(event);
    }
  };

  const handleFocus = (event) => {
    if (selectOnFocus) {
      event.target.select();
    }

    onFocus?.(event);
  };

  const handleKeyUp = (event) => {
    switch (event.key) {
      case 'Enter':
        return event.target.blur();

      case 'Escape':
        event.target.value = props.value;
        return event.target.blur();
    }
  };

  return (
    <Root className={className} fullWidth={fullWidth}>
      <Flex labelPosition={labelPosition}>
        {label && (
          <Label
            htmlFor={`input-${name}`}
            position={labelPosition}
            error={Boolean(error)}
          >
            {label}
          </Label>
        )}
        <Input
          id={`input-${name}`}
          autoComplete="off"
          endAdornment={
            !readOnly &&
            !disableClear &&
            Boolean(value?.length) && (
              <InputAdornment position="end">
                <IconButton onClick={handleClear} edge="end" tabIndex={-1}>
                  <Close />
                </IconButton>
              </InputAdornment>
            )
          }
          inputProps={{
            ...props.inputProps,
            maxLength,
          }}
          {...props}
          error={Boolean(error)}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={handleFocus}
          onKeyUp={handleKeyUp}
          readOnly={readOnly}
          value={value}
        />
        {Boolean(helperText) && <HelperText>{helperText}</HelperText>}
      </Flex>
      {error && <Error>{error}</Error>}
    </Root>
  );
};

TextInput.propTypes = {
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  disableClear: PropTypes.bool,
  fullWidth: PropTypes.bool,
  initialValue: PropTypes.any,
  label: PropTypes.string,
  labelPosition: PropTypes.oneOf(['above', 'left', 'right']),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  resetsOnEmpty: PropTypes.bool,
  selectOnFocus: PropTypes.bool,
  value: PropTypes.any,
};

export default memo(TextInput);
