import { useEffect } from 'react';
import { Analytics } from '@pkg/analytics';
import { useClient } from '@pkg/client';
import { Database } from '@pkg/database';
import { People } from '@pkg/entities';
import Track from '@/lib/trackjs';
import useAuthentication from './useAuthentication';
import useRefresh from './useRefresh';
import useReset from './useReset';
import useStore, { ANONYMOUS_STATE } from './useStore';

export default function useLive() {
  const {
    isAuthenticated,
    isFronteggAuthenticated,
    isLoading,
    hasLimitedAccess,
    email,
  } = useAuthentication();

  const { refresh, isRefreshing } = useRefresh();
  const { reset, isResetting } = useReset();
  const { set } = useStore();
  const client = useClient();

  const isStaleHash = Database.useStaleHash('auth');
  const isStaleBuild = Database.useStaleBuild();
  const [me, organisation] = Database.useLive('auth', ['me', 'organisation']);

  useEffect(() => {
    if (isRefreshing || isResetting || isLoading) {
      return;
    }

    if (!isAuthenticated && !hasLimitedAccess) {
      set(ANONYMOUS_STATE);
      return;
    }

    if (!client.isAuthorized) {
      return;
    }

    if (isStaleBuild) {
      reset();
      return;
    }

    if (isStaleHash) {
      refresh();
      return;
    }

    if (me?.email && me.email !== email) {
      refresh();
      return;
    }

    if (!me) {
      return;
    }

    const isRegistered = Boolean(me.registered_at) || hasLimitedAccess;
    const validJwt = Boolean(me?.uuid);

    set({
      me: isRegistered ? me : null,
      organisation: isRegistered ? organisation : null,
      features: me?.features || [],
      isAuthenticated: isAuthenticated && validJwt,
      isFronteggAuthenticated,
      hasLimitedAccess,
      isLoading: false,
      isRegistered,
      isDisabled: Boolean(organisation?.is_disabled),
    });

    if (isRegistered) {
      const adminRole = `organisation.admin.${organisation.uuid}`;
      const isAdmin = People.hasRole(me, adminRole);
      Analytics.identify(me.uuid, { admin: isAdmin });
      Track.identify(me.uuid);
    }
  }, [
    JSON.stringify(me),
    JSON.stringify(organisation),
    isAuthenticated,
    isFronteggAuthenticated,
    isRefreshing,
    isStaleHash,
    isStaleBuild,
    isLoading,
    hasLimitedAccess,
    client.isAuthorized,
  ]);
}
