import Link from 'next/link';
import { OutlineCard } from '@/organisms/cards';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import Typography from '@/components/Typography';
import useQuickLinks from './useQuickLinks';

const QuickLinkCards = () => {
  const { me } = Auth.useStore();
  const quickLinks = useQuickLinks(me);

  return (
    <OutlineCard title="Links to help you get around">
      <Grid container justifyContent="space-between" spacing={2}>
        {quickLinks?.map((linkItem) => (
          <Grid item key={linkItem.href} sx={{ p: 2 }}>
            <Link href={linkItem.href}>
              <Stack alignItems="center" justifyContent="flex-end">
                {linkItem.icon}
                <Typography variant="body2">{linkItem.label}</Typography>
              </Stack>
            </Link>
          </Grid>
        ))}
      </Grid>
    </OutlineCard>
  );
};

export default QuickLinkCards;
