export const fontFamily = 'Poppins, sans-serif';

export const h1 = {
  fontFamily,
  fontSize: '2.5rem',
  fontWeight: 500,
  lineHeight: '3rem',
};

export const h2 = {
  fontFamily,
  fontSize: '2rem',
  fontWeight: 500,
  lineHeight: '2.5rem',
};

export const h3 = {
  fontFamily,
  fontSize: '1.75rem',
  fontWeight: 600,
  lineHeight: '2.25rem',
};

export const h4 = {
  fontFamily,
  fontSize: '1.5rem',
  fontWeight: 600,
  lineHeight: '2rem',
};

export const h5 = {
  fontFamily,
  fontSize: '1.25rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
};

export const h6 = {
  fontFamily,
  fontSize: '1.125rem',
  fontWeight: 600,
  lineHeight: '1.75rem',
};

export const subtitle1 = {
  fontFamily,
  fontSize: '1.125rem',
  fontWeight: 500,
  lineHeight: '1.75rem',
};

export const subtitle2 = {
  fontFamily,
  fontSize: '1rem',
  fontWeight: 500,
  lineHeight: '1.5rem',
};

export const body1 = {
  fontFamily,
  fontSize: '1rem',
  fontWeight: 400,
  lineHeight: '1.5rem',
};

export const body2 = {
  fontFamily,
  fontSize: '0.875rem',
  fontWeight: 400,
  lineHeight: '1.25rem',
  '& strong': {
    fontWeight: 600,
  },
};

export const button = {
  fontFamily,
  fontSize: '0.875rem',
  fontWeight: 500,
  lineHeight: '1rem',
  textTransform: 'none',
};

export const caption = {
  fontFamily,
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: '1rem',
};
