import { useEffect, useState } from 'react';
import { useTagContext } from '@/shared/providers';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { DesignEntity, Position } from '@/lib/enums';
import mapActivityQuadrants from './mapActivityQuadrants';

/**
 * Returns an array of sorted activities with data from the quadrant map.
 * @param {Array} activities
 * @param {Object} quadrantMap
 * @return {Array}
 */
const sortActivities = (activities, quadrantMap) => {
  return activities
    .sort(
      ([, activityA], [, activityB]) =>
        activityB.__aggregateHours - activityA.__aggregateHours
    )
    .map(([key, activityItem]) => {
      const activity = quadrantMap
        .get(DesignEntity.ACTIVITY)
        ?.activities?.get(key);
      return {
        ...activity,
        ...activityItem,
        uuid: key,
      };
    });
};

const useActvitiesByQuadrant = (matrix) => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { tagMap } = useTagContext();
  const [activities, setActivities] = useState();
  const [quadrantMap, setQuadrantMap] = useState();
  const [activeQuadrant, setActiveQuadrant] = useState(null);
  const firstTagName = tagMap?.get(matrix?.tags?.[0])?.name;
  const secondTagName = tagMap?.get(matrix?.tags?.[1])?.name;
  const axisLabels = {
    bottom: {
      title: `Not ${firstTagName}`,
      subtitle: matrix.labels?.[Position.BOTTOM]?.subtitle,
    },
    left: {
      title: `Not ${secondTagName}`,
      subtitle: matrix.labels?.[Position.LEFT]?.subtitle,
    },
    right: {
      title: secondTagName,
      subtitle: matrix.labels?.[Position.RIGHT]?.subtitle,
    },
    top: {
      title: firstTagName,
      subtitle: matrix.labels?.[Position.TOP]?.subtitle,
    },
  };

  // Sets the activeQuadrant property which triggers the useEffect to update
  // the activities array.
  const selectQuadrant = (quadrant) => {
    setActiveQuadrant(quadrant);
  };

  // Creates the quadrant map each time a scenario changes.
  useEffect(() => {
    if (!scenario?.entity) {
      return;
    }

    const quadrantMap = mapActivityQuadrants({
      design: snapshotEntityMap,
      scenario,
      matrix,
    });

    setQuadrantMap(quadrantMap);
  }, [scenario]);

  // Sets the activities array based on the quadrant map and the active quadrant
  // identifier.
  useEffect(() => {
    if (!quadrantMap || !activeQuadrant) {
      return;
    }

    setActivities(
      sortActivities(
        Array.from(quadrantMap.get(activeQuadrant)?.activities),
        quadrantMap
      )
    );
  }, [quadrantMap, activeQuadrant]);

  return {
    activeQuadrant,
    activities,
    axisLabels,
    selectQuadrant,
    quadrantMap,
  };
};

export default useActvitiesByQuadrant;
