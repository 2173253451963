import { sanitize } from 'dompurify';
import Alert from '@mui/material/Alert';

const FieldErrors = ({ ok = false, errors = [] }) => {
  if (ok === true) {
    return null;
  }
  return Object.keys(errors).map((key, index) => (
    <Alert severity="error" key={index}>
      <span
        data-testid="field-errors"
        dangerouslySetInnerHTML={{ __html: sanitize(errors[key]) }}
      />
    </Alert>
  ));
};

export default FieldErrors;
