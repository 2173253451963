import { Orientation, Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const ColorLegend = ({ items, orientation = Orientation.HORIZONTAL }) => {
  return (
    items?.length > 0 && (
      <Stack
        direction={orientation == Orientation.HORIZONTAL ? 'row' : 'column'}
        alignItems="center"
        spacing={2}
      >
        {items.map(({ color, label }) => (
          <Stack alignItems="center" direction="row" key={label} spacing={0.5}>
            <div
              style={{
                backgroundColor: color,
                borderRadius: '2px',
                width: '10px',
                height: '10px',
              }}
            />
            <Paragraph size={Size.SMALL} overrideStyles={{ mb: 0 }}>
              {label}
            </Paragraph>
          </Stack>
        ))}
      </Stack>
    )
  );
};

export default ColorLegend;
