import { useMemo } from 'react';
import {
  DimensionType,
  SkillCell,
  SkillFilter,
} from '@/molecules/tableElements';
import { useSkillContext } from '@/shared/providers';

const usePersonColumnDefs = (dimension) => {
  const { skillList } = useSkillContext();

  return useMemo(() => {
    return [
      {
        field: 'person.id',
        filter: 'agMultiColumnFilter',
        hide: true,
      },
      {
        cellDataType: 'category',
        field: 'person.name',
        filter: 'agMultiColumnFilter',
        width: 250,
      },
      dimension !== DimensionType.PEOPLE_SKILLS && {
        cellRenderer: SkillCell,
        chartDataType: 'category',
        field: 'person.skills',
        filter: SkillFilter,
        filterParams: {
          title: 'Person Skill filter',
          values: skillList,
        },
        keyCreator: ({ value }) => {
          return value?.map(
            ({ name, level }) => `${name} (${level?.toLowerCase()})`
          );
        },
        valueFormatter: ({ value }) => {
          if (!value) {
            return;
          }

          return value?.map(
            ({ name, level }) => `${name} (${level?.toLowerCase()})`
          );
        },
        width: 400,
      },
    ].filter((column) => column);
  }, [dimension]);
};

export default usePersonColumnDefs;
