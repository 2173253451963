import { Base as BaseColors } from '@/atoms/colors';
import { core } from '@/lib/theme/tokens/color';
import { brand, grey } from '@/lib/theme/tokens/palettes';

const ChipColor = Object.freeze({
  primary: {
    normal: {
      backgroundColor: brand._navy.main,
      color: core.white,
    },
    focus: {
      backgroundColor: brand._navy.tints[5],
      color: core.white,
    },
    hover: {
      backgroundColor: brand._navy.tints[5],
      color: core.white,
    },
  },
  secondary: {
    normal: {
      backgroundColor: brand.yellow.main,
      color: BaseColors.font.tertiary,
    },
    focus: {
      backgroundColor: brand.yellow.main,
      color: BaseColors.font.tertiary,
    },
    hover: {
      backgroundColor: brand.yellow.main,
      color: BaseColors.font.tertiary,
    },
  },
  tertiary: {
    normal: {
      backgroundColor: grey.colors[100],
      color: BaseColors.font.tertiary,
    },
    focus: {
      backgroundColor: grey.colors[300],
      color: BaseColors.font.tertiary,
    },
    hover: {
      backgroundColor: grey.colors[300],
      color: BaseColors.font.tertiary,
    },
  },
});

export default ChipColor;
