import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function updatedFTEAction({
  actionMap,
  comparisonScenario,
  role,
  scenario,
}) {
  const originalRole = comparisonScenario.relationships
    .get(EntityType.ROLE)
    ?.get(role.uuid);
  const group = role
    ? scenario?.relationships.get(EntityType.GROUP)?.get(role.group_uuid)
    : null;

  const metrics = {
    ...role.__metrics.self.visible,
  };

  const existingAction = actionMap?.get(ActionType.UPDATE_FTE);

  const action = existingAction ?? {
    entity: EntityType.ROLE,
    metrics: defaultMetrics(comparisonScenario),
    type: ActionType.UPDATE_FTE,
    [EntityType.ROLE]: new Map(),
    [EntityType.GROUP]: new Set(),
  };

  action[EntityType.ROLE].set(role.uuid, { metrics });

  if (group) {
    action[EntityType.GROUP].add(group.uuid);
  }

  const fteChange = metrics.fte - originalRole.__metrics.self.visible.fte;
  action.metrics[1].fte += fteChange;

  const roleCount = action[EntityType.ROLE].size;
  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);

  action.title = `FTE was updated for ${roleCount} ${roleLabel}`;

  return action;
}
