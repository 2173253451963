/**
 * @param {Object[]} collection
 * @param {String} key
 *
 * @return {Object}
 */
export function uniqueBy(collection, key) {
  const unique = new Map();

  collection?.forEach((item) => {
    if (!unique.has(item[key])) {
      unique.set(item[key], item);
    }
  });

  return Array.from(unique.values());
}

/**
 * @param {Object[]} collection
 * @param {String} collection[].uuid
 *
 * @return {Object}
 */
export function uniqueById(collection) {
  return uniqueBy(collection, 'uuid');
}
