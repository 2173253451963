export default function formatFlow(flow) {
  if (!flow) {
    return;
  }

  const { created_at, payload, uuid, status } = flow;

  const flowTemplate = flow?.campaign?.flowTemplate;

  if (!flowTemplate) {
    return;
  }

  const {
    description,
    disclaimer,
    is_onboarding: isOnboarding,
    name,
    settings,
    type,
  } = flowTemplate;

  return {
    createdAt: created_at,
    description,
    disclaimer,
    isOnboarding,
    name,
    payload,
    tags: new Set(settings.tags),
    showTags: new Set(settings.show_tags),
    status,
    type,
    uuid,
  };
}
