import { styled } from '@mui/material/styles';
import { color } from '@/lib/theme/tokens';
import Typography from '@/components/Typography';

export default styled(Typography)({
  color: color.primary.grey[500],
  fontSize: 12,
  lineHeight: 1,
  marginTop: 2,
});
