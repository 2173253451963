import { styled } from '@mui/material/styles';
import { ucfirst } from '@pkg/utils/strings';
import { SkillLevel } from '@/lib/enums';
import Box from '@/components/Box';
import Skill from '@/components/Skill';

const Root = styled(Box)({
  marginTop: '12px',
  marginBottom: '8px',
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
});

export default function SkillLegend() {
  return (
    <Root>
      {Object.entries(SkillLevel)
        .filter(([, value]) => typeof value === 'string')
        .map(([key, value]) => (
          <Skill
            key={`${key}:${value}`}
            name={ucfirst(value, true)}
            skill={{ uuid: key, level: key }}
          />
        ))}
    </Root>
  );
}
