const Plus = (props) => (
  <svg {...props} fill="none" height="1em" viewBox="0 0 24 24" width="1em">
    <path
      d="M12 6L12 18"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M18 12L6 12"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Plus;
