import { useEffect, useRef, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { ChipType } from '@/atoms/chips/enums';
import { Base as BaseColors } from '@/atoms/colors';
import { Colors as InputColors } from '@/atoms/inputs';
import useInput from '@/atoms/inputs/hooks/useInput';
import { Label } from '@/atoms/typography';
import { TagChips } from '@/molecules/tags';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/styles';
import excludeProps from '@/lib/theme/excludeProps';
import { core } from '@/lib/theme/tokens/palettes';
import ChipOptions from './ChipOptions';

const StyledSelectBox = styled(Box, {
  shouldForwardProp: excludeProps(['color']),
})(() => ({
  borderRadius: 4,
  backgroundColor: core.white,
  color: BaseColors.font.primary,
}));

const ChipSelect = ({
  categorised,
  chipType,
  color = 'secondary',
  label,
  maxHeight,
  onChange,
  onClose,
  options,
  selectedIds,
  showCloseButton,
}) => {
  const inputRef = useRef(null);
  const [scrollHeight, setScrollHeight] = useState();

  const { inputChange, value } = useInput({
    initialValue: selectedIds,
    onChange,
  });

  const [selected, setSelected] = useState([]);

  const handleAdd = (event, tagId) => {
    if (!value.includes(tagId)) {
      const updated = [...value, tagId];
      inputChange(event, updated);
    }
  };

  const handleRemove = (event, tagId) => {
    const updated = value.filter((id) => tagId !== id);
    inputChange(event, updated);
  };

  useEffect(() => {
    setSelected(value.map((id) => options?.map?.get(id)));
  }, [JSON.stringify(value)]);

  // Updates the scrollable height if a maxHeight is set.
  useEffect(() => {
    if (!inputRef?.current || !maxHeight) {
      return;
    }

    setScrollHeight(maxHeight - inputRef.current.clientHeight);
  }, [inputRef?.current, maxHeight]);

  return (
    <StyledSelectBox width="100%" color={color}>
      <Box
        p={1}
        sx={{
          boxShadow: `inset 0 -1px 0 ${InputColors.Input[color].normal.borderColor}`,
          backgroundColor: InputColors.Input[color].hover.backgroundColor,
        }}
        ref={inputRef}
      >
        <Stack direction="row">
          {chipType === ChipType.TAG && (
            <TagChips
              expanded={true}
              tags={selected}
              tooltipPlacement="top"
              onClick={handleRemove}
              onDelete={handleRemove}
            />
          )}
          {showCloseButton && (
            <Box>
              <Button
                color="primary"
                endIcon={<CloseIcon />}
                label="Close"
                onClick={onClose}
                variant="naked"
              />
            </Box>
          )}
        </Stack>
      </Box>
      <Box position="relative">
        <Box
          maxHeight={scrollHeight}
          width="100%"
          paddingBottom={0}
          sx={{ overflowY: 'scroll' }}
        >
          <Box paddingBottom={1}>
            {label && (
              <Box p={1}>
                <Label>{label}</Label>
              </Box>
            )}
            <ChipOptions
              categorised={categorised}
              options={options.list}
              exclude={value}
              chipType={chipType}
              onClick={handleAdd}
            />
          </Box>
        </Box>
      </Box>
    </StyledSelectBox>
  );
};

export default ChipSelect;
