import openai from '../client';

const YAML = require('json-to-pretty-yaml');

const reportsPrompt = async ({ userText, snapshot, lastMessages }) => {
  const { roles, groups, activities } = snapshot.entities;
  const prompt = {
    stream: true,
    model: openai('gpt-4-turbo'),
    tools: {},
    prompt: `
You are an organizational programming bot, there is a javascript app that creates and updates roless based off mutation off a certain shape. You are also knowledgeable about underlying issues inside an organization and has the data to improve productivity and energizing work.
  
  
  Please proceed with your analysis and report generation based on these instructions.
  
  Here is a list of groups, roles and activities that you can use to create and edit roles 

 
      ${YAML.stringify({
        groups: groups.slice(0, 30).map((group) => ({
          name: group.name,
          objective: group.objective,
          roles: roles
            .filter((role) => role.group_uuid === group.uuid)
            .map((role) => ({
              uuid: role.uuid,
              user_uuid: role.user_uuid,
              title: role.title,
              fte: role.fte,
              budget: role.budget,
              activities: activities
                .filter((activity) => activity.owner_uuid === role.uuid)
                .map((activity) => ({
                  uuid: activity.uuid,
                  owner_uuid: activity.owner_uuid,
                  description: activity.__description,
                  hours: activity.hours,
                  tags: activity.tags || [],
                })),
            })),
        })),
      })}

    
  The user wants you to generate a report, explain all your reasoning, give very pragmatic suggestions to what they should do, and give them a well structured response.
  
  Do not include uuids in the report.
  
  If they ask for a table, give them a table in Grutatxt format.

           Last messages;
          ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

          The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.
   
      User request: ${userText}
    `,
  };

  return prompt;
};

export default reportsPrompt;
