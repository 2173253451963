import useObservable from '@pkg/broadcasting/useObservable';
import getChannelName from './channelName';

export default function useSubscriber(
  designId,
  event,
  callback,
  context = 'designs'
) {
  const subscriber = {
    event,
    context,
    callback,
  };

  useObservable(getChannelName(designId), [subscriber]);
}
