import { EntityType } from '@/shared/enums';
import ColorSpectrum from './ColorSpectrum';

export default function stackColor(percentage, entityType = EntityType.ROLE) {
  const isSpan = entityType === EntityType.SPAN;
  const isLayer = entityType === EntityType.LAYER;

  const entity = isSpan
    ? EntityType.MANAGER
    : isLayer
      ? EntityType.ROLE
      : entityType;

  if (percentage >= 40) {
    return ColorSpectrum[entity][4];
  }
  if (percentage >= 30) {
    return ColorSpectrum[entity][3];
  }
  if (percentage >= 20) {
    return ColorSpectrum[entity][2];
  }
  if (percentage >= 10) {
    return ColorSpectrum[entity][1];
  }

  return ColorSpectrum[entity][0];
}
