import copy from 'fast-copy';

/**
 * @param {object} variables
 * @param {any} [variables.input]
 *
 * @param {object} cacheOperation
 * @param {string} cacheOperation.key
 * @param {any} cacheOperation.query
 * @param {function} cacheOperation.updater
 */
export default function onMutate(variables, { key, query, updater, client }) {
  client.cancelQueries(query);

  const previous = client.getQueryData(key);

  client.setQueryData(query, (cache) => {
    const cachedData = cache[key] ?? {};
    const immutable = copy(cachedData);
    const result = updater(immutable, variables);
    return {
      ...cache,
      [key]: result,
    };
  });

  return function rollbackSnapshot() {
    client.setQueryData(query, previous);
  };
}
