export default function columnLayout({
  activeTag,
  isCondensedView,
  isVertical,
}) {
  const columnProps = {
    columns: {
      xs: isVertical ? 4 : 6,
      lg: isVertical ? 2 : 3,
    },
    columnCount: 12,
  };

  if (isCondensedView) {
    columnProps.columns.xs = isVertical ? 6 : 12;
    columnProps.columns.lg = isVertical ? 3 : 4;
    return columnProps;
  }

  if (activeTag) {
    columnProps.columns.xs = isVertical ? 6 : 12;
    columnProps.columns.lg = isVertical ? 3 : 4;
  }

  return columnProps;
}
