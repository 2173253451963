import { useMemo } from 'react';
import { LinkButton } from '@/atoms/buttons';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { OutlineCard } from '@/organisms/cards';
import { ViewMode } from '@/shared/enums';
import { useDesignsContext } from '@/shared/providers';
import isTeamLead from '@/shared/utils/isTeamLead';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import { Colors } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import { ChipType } from '@/lib/enums';
import isSupporter from '@/components/DashboardContainer/utils/isSupporter';

const TeamCard = ({ group, viewMode = ViewMode.DIAGRAM }) => {
  const designs = useDesignsContext();
  const { me } = Auth.useStore();
  const supporter = isSupporter(group, designs?.supporterMap, me.uuid);
  const teamLead = isTeamLead(group, me.uuid);
  const color = Colors.entity(DesignEntity.GROUP);
  const chipType = teamLead
    ? ChipType.LEAD
    : supporter
      ? ChipType.SUPPORTER
      : null;

  const path = useMemo(() => {
    let designId;

    group.__scenarios.forEach((design, key) => {
      if (!designId || !design.__isScenario) {
        designId = key;
      }
    });

    return `/d/${designId}/latest/g/${group.uuid}?view=${viewMode}`;
  }, [group?.__scenarios]);

  return (
    <OutlineCard padding={0} margin={0}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        pr={2}
        sx={{
          overflow: 'hidden',
          borderRadius: '3px',
        }}
      >
        <Box
          p={1}
          pl={3}
          sx={{
            boxShadow: `inset 8px 0 0 ${color.main}`,
          }}
        >
          <EntityChip size={Size.SMALL} type={DesignEntity.GROUP} />
          <Heading
            variant="h2"
            overrideStyles={{
              fontWeight: 400,
              fontSize: '1.5rem',
              lineHeight: '1.75em',
            }}
          >
            {group.name}
          </Heading>
          {chipType && <EntityChip size={Size.SMALL} type={chipType} />}
        </Box>
        {path && (
          <LinkButton
            boldText
            color="secondary"
            label="View"
            size={Size.MEDIUM}
            path={path}
          />
        )}
      </Stack>
    </OutlineCard>
  );
};

export default TeamCard;
