import { OutlineCard } from '@/organisms/cards';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import Box from '@/components/Box';
import { LinkButton } from '@/components/DashboardContainer/atoms/buttons';
import Typography from '@/components/Typography';

const EmptyDashboardCard = () => {
  const { me, organisation } = Auth.useStore();
  const actionLink = `mailto:${organisation.owner.email}`;

  return (
    <OutlineCard>
      <Stack spacing={4} sx={{ minHeight: 'calc(100vh / 3)', my: 4, px: 8 }}>
        <Typography variant="h3" sx={{ textAlign: 'center' }}>
          Welcome to your dashboard {me.first_name}!
        </Typography>
        <Typography variant="body" sx={{ textAlign: 'center' }}>
          It appears that you haven't been assigned to any roles or teams. To
          request access, please contact your administrator{' '}
          <strong>
            {organisation.owner.first_name} {organisation.owner.last_name}
          </strong>{' '}
          using the button below.
        </Typography>
        <Box>
          <Stack direction="row" justifyContent="center">
            <LinkButton
              title={`Email ${organisation.owner.first_name} ${organisation.owner.last_name}`}
              path={actionLink}
            />
          </Stack>
          <Typography
            variant="subtitle2"
            sx={{
              fontSize: '0.875rem',
              mt: 2,
              fontWeight: 400,
              textAlign: 'center',
            }}
          >
            {organisation.owner.email}
          </Typography>
        </Box>
      </Stack>
    </OutlineCard>
  );
};

export default EmptyDashboardCard;
