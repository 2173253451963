import { formatDistance } from 'date-fns/formatDistance';
import { formatDistanceStrict } from 'date-fns/formatDistanceStrict';

const Relative = ({ date = '', addSuffix = true, strict = false }) => {
  if (!date) {
    return '';
  }

  const formatFn = strict ? formatDistanceStrict : formatDistance;
  return formatFn(new Date(date), new Date(), { addSuffix });
};

export default Relative;
