const DefaultCondition = new Map([
  ['entity', null],
  ['property', null],
  ['type', null],
  ['propertyId', null],
  ['comparator', null],
  ['value', null],
]);

export default DefaultCondition;
