import { Size } from '@/atoms/enums';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from './IconButton';

const MenuButton = ({ onClick, size = Size.SMALL }) => {
  return (
    <IconButton onClick={onClick} size={size}>
      <MoreVertIcon />
    </IconButton>
  );
};

export default MenuButton;
