import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { Routes } from '@pkg/utils';

const useEntityNavigation = () => {
  const router = useRouter();

  const navigateToEntity = useCallback(
    ({ type, uuid }) => {
      const current = router.asPath;
      const query = current.split('?')[1];
      const parts = Routes.getDesignParts(current);

      const route = Routes.build.designParts({
        ...parts,
        level: {
          type,
          uuid,
        },
      });

      router.push(`${route}?${query}`);
    },
    [router]
  );

  return {
    navigateToEntity,
  };
};

export default useEntityNavigation;
