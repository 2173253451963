import { Form } from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const CheckboxColorProps = Object.freeze({
  primary: {
    normal: {
      backgroundColor: Form.primary.normal.borderColor,
      color: 'transparent',
    },
    hover: {
      backgroundColor: Form.primary.hover.borderColor,
      color: core.white,
    },
    focus: {
      backgroundColor: Form.primary.focus.borderColor,
      color: core.white,
    },
  },
  secondary: {
    normal: {
      backgroundColor: brand.yellow.main,
      color: core.white,
    },
    hover: {
      backgroundColor: brand.yellow.tints[5],
      color: core.white,
    },
    focus: {
      backgroundColor: brand.yellow.tints[5],
      color: core.white,
    },
  },
  tertiary: {
    normal: {
      backgroundColor: brand.deepPurple.main,
      color: core.white,
    },
    hover: {
      backgroundColor: brand.deepPurple.tints[5],
      color: core.white,
    },
    focus: {
      backgroundColor: brand.deepPurple.tints[5],
      color: core.white,
    },
  },
});

export default CheckboxColorProps;
