import { useMutation } from '@pkg/graphql';

export const CREATE_INTEGRATION_IMPORT = /* GraphQL */ `
  mutation CreateIntegrationImport($input: CreateIntegrationImportInput!) {
    createIntegrationImport(input: $input) {
      uuid
      status
    }
  }
`;

export default function useCreateIntegrationImport() {
  const { mutateAsync } = useMutation({
    mutation: CREATE_INTEGRATION_IMPORT,
  });

  /**
   * @param {String} organisation_uuid
   * @param {String} integrationUuid
   * @param {Boolean} options.calculate_budgets
   */
  function createIntegrationImport(
    organisation_uuid,
    integrationUuid,
    options
  ) {
    return mutateAsync({
      organisation_uuid,
      integration_uuid: integrationUuid,
      options,
    });
  }

  return createIntegrationImport;
}
