import { useEffect } from 'react';
import {
  usePropertyContext,
  useRoleContext,
  useSkillContext,
} from '@/shared/providers';
import { Library, Snapshots } from '@pkg/entities';
import { ClientError } from '@/lib/enums';
import useDesignStore from './useDesignStore';

/**
 * @param {Object} snapshot
 * @param {Object} level
 * @returns {Object|ClientError}
 */
export default function useSnapshot() {
  const library = Library.useLibrary();
  const propertyContext = usePropertyContext();
  const roleContext = useRoleContext();
  const skillContext = useSkillContext();
  const skillMap = skillContext?.peopleSkillMap;

  const access = useDesignStore((state) => state.access);
  const conditions = useDesignStore((state) => state.conditions);
  const isLoading = useDesignStore((state) => state.isLoading);
  const lens = useDesignStore((state) => state.lens);
  const level = useDesignStore((state) => state.level);
  const mutate = useDesignStore((state) => state.actions.mutate);
  const set = useDesignStore((state) => state.set);
  const setActions = useDesignStore((state) => state.setActions);
  const snapshot = useDesignStore((state) => state.revision?.snapshot);
  const isHistorical = useDesignStore((state) => state.isHistorical);

  const reduced = Snapshots.utils.useReduced(snapshot, level);
  const [state, dispatch] = Snapshots.useSnapshot(
    reduced,
    mutate,
    isHistorical
  );

  useEffect(() => {
    setActions({ dispatch });
  }, [dispatch]);

  useEffect(() => {
    if (!access.isReady || isLoading || !state) {
      set({ snapshot: null, isReady: false });
      return;
    }

    if (state === ClientError.NOT_FOUND) {
      set({ error: state, snapshot: null, isReady: true });
      return;
    }

    /** @todo combine filters into a single `filterSnapshot` call */
    console.time('DesignContainer.useSnapshot.derive');
    const derived = Snapshots.deriveProps(library, state);
    const filtered = Snapshots.filters.apply(derived, conditions, skillMap);
    const calculated = Snapshots.filters.metrics(library, filtered);
    const tagged = Snapshots.deriveTags(calculated);
    const completed = Snapshots.deriveCompleteness(tagged);
    const overlayed = Snapshots.lenses.apply(completed, lens, library);
    const frozen = Object.freeze(overlayed);
    console.timeEnd('DesignContainer.useSnapshot.derive');

    /** @todo figure out what to do about these weird context injections */
    if (propertyContext?.updatePropertyIndexes) {
      console.time('DesignContainer.useSnapshot.propertyMap');
      const propertyMap = Snapshots.derivePropertyMap(frozen);
      propertyContext?.updatePropertyIndexes(propertyMap);
      console.timeEnd('DesignContainer.useSnapshot.propertyMap');
    }

    if (roleContext?.updateRoleIndexes) {
      console.time('DesignContainer.useSnapshot.roleMap');
      roleContext?.updateRoleIndexes(frozen?.entities?.roles);
      console.timeEnd('DesignContainer.useSnapshot.roleMap');
    }

    set({ snapshot: frozen, isReady: Boolean(frozen) });
  }, [
    access?.isReady,
    state?.__hash,
    isLoading,
    JSON.stringify(conditions),
    JSON.stringify(lens),
    JSON.stringify(level),
  ]);
}
