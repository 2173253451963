import { EntityType } from '@/shared/enums';
import updateActivity from './updateActivity';
import updateActivityType from './updateActivityType';

/**
 * Updates the activity map based on the provided action.
 *
 * @param {Object}
 */
export default function updateMap({
  action,
  activity,
  activityMap,
  comparisonScenario,
  role,
}) {
  // Update the activity type with the relevant metrics.
  activityMap.set(
    activity.__description,
    updateActivityType({
      action,
      activity,
      activityMap,
      comparisonScenario,
    })
  );

  // Get the activity type.
  const activityType = activityMap.get(activity.__description);

  // Update the activity.
  activityType[EntityType.ACTIVITY].set(
    activity.uuid,
    updateActivity({
      action,
      activity,
      role,
      activityType,
      comparisonScenario,
    })
  );
}
