import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@/components/Button';

const CardFooter = ({ actionText, onClick }) => {
  return (
    <Stack direction="row" justifyContent="flex-start">
      <Divider />
      <Button
        variant="flat"
        onClick={onClick}
        sx={{
          fontWeight: 500,
        }}
      >
        {actionText}
      </Button>
    </Stack>
  );
};

export default CardFooter;
