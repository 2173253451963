import { tool } from 'ai';
import { z } from 'zod';

const createMutation = tool({
  description: 'Create a javascript mutation for a role',
  parameters: z.object({
    mutation: z
      .string()
      .describe(
        'The mutation to be executed to manipulate roles. It can contain many mutations to change roles or activities'
      ),

    explanation: z
      .string()
      .describe(
        'An explanation of what is about to mutated. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ mutation, explanation }) => ({
    mutation,
    explanation,
  }),
});

export default createMutation;
