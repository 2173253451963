import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Button from '@/components/Button';
import { Select } from '@/components/Inputs';
import useSelectRevisions from '../hooks/useSelectRevisions';

const Option = styled(MenuItem)({
  paddingTop: 8,
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

const LOAD_MORE = 'LOAD_MORE';

/**
 * @param {Function} onLoadMore
 * @param {Boolean} isUpdating
 */
const LoadMoreOption = ({ onLoadMore, isUpdating }) => {
  return (
    <Option key={LOAD_MORE} value={LOAD_MORE}>
      {isUpdating ? (
        <CircularProgress color="inherit" size={20} />
      ) : (
        <Button size="small" variant="secondary" onClick={onLoadMore}>
          Load more
        </Button>
      )}
    </Option>
  );
};

const RevisionSelect = ({ onSelect, selectedDesign }) => {
  const { id: designId, entity } = selectedDesign ?? {};
  const { revisionList, isLoading, isUpdating, hasMore, loadMore } =
    useSelectRevisions(entity, designId);

  const [selected, setSelected] = useState('');
  const [options, setOptions] = useState();

  const hasSelectedDesign = Boolean(selectedDesign);
  const loadingHistory = hasSelectedDesign && isLoading;

  useEffect(() => {
    if (!hasSelectedDesign) {
      setSelected('');
    }
  }, [hasSelectedDesign]);

  useEffect(() => {
    if (!loadingHistory) {
      return;
    }
    setOptions([{ value: 'loading', label: 'Loading history...' }]);
    setSelected('loading');
  }, [loadingHistory]);

  useEffect(() => {
    if (!revisionList?.length) {
      return;
    }

    setSelected(revisionList[0].value);
    onSelect({
      designId,
      revisionId: revisionList[0].value,
      entity,
    });

    setOptions(revisionList);
  }, [JSON.stringify(revisionList)]);

  const handleChange = (event) => {
    const revisionId = event.target.value;

    setSelected(revisionId);
    onSelect({
      designId,
      revisionId,
      entity,
    });
  };

  return (
    <Select
      disabled={!hasSelectedDesign || loadingHistory}
      fullWidth
      options={
        hasMore
          ? options.concat(
              <LoadMoreOption
                key="LOAD_MORE"
                onLoadMore={loadMore}
                isUpdating={isUpdating}
              />
            )
          : options
      }
      onChange={handleChange}
      value={selected}
    />
  );
};

RevisionSelect.propTypes = {
  designId: PropTypes.string,
  onSelect: PropTypes.func,
};

export default RevisionSelect;
