import { Base as BaseColor } from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const SwitchColors = Object.freeze({
  primary: {
    normal: {
      color: BaseColor.font.primary,
      notchColor: brand.lightBlue.shades[2],
      trackColor: brand.lightBlue.shades[0],
    },
    selected: {
      color: BaseColor.font.primary,
      notchColor: brand.lightBlue.shades[4],
      trackColor: brand.lightBlue.shades[2],
    },
    disabled: {
      color: BaseColor.font.primary,
      notchColor: brand.grey.tints[5],
      trackColor: brand.grey.tints[2],
    },
  },
  manager: {
    normal: {
      color: 'inherit',
      notchColor: brand.teal.main,
      trackColor: brand.teal.tints[4],
    },
    selected: {
      color: 'inherit',
      trackColor: brand.purple.tints[4],
      notchColor: brand.purple.main,
    },
    disabled: {
      color: 'inherit',
      notchColor: brand.grey.tints[5],
      trackColor: brand.grey.tints[2],
    },
  },
  white: {
    normal: {
      color: core.white,
      notchColor: core.white,
      trackColor: `${core.white}66`,
    },
    selected: {
      color: brand.deepPurple.main,
      notchColor: brand.yellow.main,
      trackColor: `${core.white}66`,
    },
    disabled: {
      color: core.white,
      notchColor: brand.grey.tints[5],
      trackColor: brand.grey.tints[2],
    },
  },
});

export default SwitchColors;
