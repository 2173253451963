import * as d3 from 'd3';
import { useEffect } from 'react';
import { useDebounce } from '@pkg/hooks';
import useStyles from './useStyles';

export default function useRenderChart(chartProps, renderChart) {
  const { chartRef: ref, classes, data, ...rest } = chartProps;

  const composed = useStyles(classes);
  const debouncedRender = useDebounce(renderChart, 50);

  useEffect(() => {
    const chart = d3.select(ref.current);
    debouncedRender({
      ...rest,
      data,
      chart,
      classes: composed,
    });
  }, [composed, data]);
}
