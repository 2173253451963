import { useMemo } from 'react';
import useLookup from './useLookup';
import useMatrix from './useMatrix';
import useNotify from './useNotify';
import useReadRestrictions from './useReadRestrictions';

/**
 * @returns {Object}
 */
export default function usePolicies() {
  const { matrix, isLoading } = useMatrix();
  const readRestrictions = useReadRestrictions(matrix);
  const lookup = useLookup(matrix);
  useNotify();

  return useMemo(
    () => ({ readRestrictions, lookup, isLoading }),
    [readRestrictions, lookup, isLoading]
  );
}
