import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';
import createInput from './createInput';

const CREATE_PROPERTY = /* GraphQL */ `
  mutation CreateLibraryProperty($input: CreateLibraryPropertyInput!) {
    createLibraryProperty(input: $input) {
      uuid
      name
    }
  }
`;

export default function useCreate() {
  const database = useDatabase();
  const { add } = useStore();
  const { mutateAsync } = useMutation({ mutation: CREATE_PROPERTY });

  /**
   * @param {String} uuid
   * @param {String} name
   * @param {String} type
   * @param {String} scope
   * @param {Array} options
   * @returns {Promise}
   */
  async function createProperty(uuid, name, type, scope, options) {
    const data = { uuid, name, type, scope, options };
    add(data);
    await database.properties.put(data);

    const input = createInput(null, data);

    return await mutateAsync(input);
  }

  return useCallback(createProperty, [mutateAsync]);
}
