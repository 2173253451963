export default function renderSvg(chart, { classes, id, height, width }) {
  return chart
    .append('svg')
    .classed('PackedCircleChart--svg', true)
    .classed(classes.svg.join(' '), true)
    .attr('id', id)
    .attr('height', height)
    .attr('width', width)
    .attr('viewBox', `0 0 ${width} ${height}`);
}
