import * as Colors from '@/atoms/colors';
import { StoreType } from '@/shared/enums';
import ScenarioProvider from '@/shared/providers/ScenarioProvider';
import Grid from '@mui/material/Grid';
import RoleLayout from './RoleLayout';

export const ActiveRoleLayout = ({ role, comparison }) => {
  return (
    <ScenarioProvider
      designId={role.designId}
      level={role.level}
      storeType={StoreType.DESIGN}
      comparisonMeta={comparison}
    >
      <Grid
        container
        sx={{
          zIndex: 0,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{
            p: 2,
            backgroundColor: Colors.Base.background.secondary,
          }}
        >
          <RoleLayout />
        </Grid>
      </Grid>
    </ScenarioProvider>
  );
};

export default ActiveRoleLayout;
