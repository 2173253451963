import { DesignEntity, Relationship } from '@/lib/enums';
import toDesign from './utils/toDesign';

/**
 * For each entity type, the order of if statements determines the priority of the relationship.
 *
 * @param {Object} me
 * @param {Object} relatedEntities
 * @param {Object} managedEntities
 * @param {Object} roleSet
 * @returns {Function}
 */
export default function derive(me, relatedEntities, managedEntities, roleSet) {
  const relateDesign = (entity, type) => {
    const members = Array.from(relatedEntities[DesignEntity.PERSON].keys());

    return toDesign(me?.uuid, entity, type, roleSet, members);
  };

  const relateGroup = (entity) => {
    // manager
    if (managedEntities[DesignEntity.GROUP]?.has(entity.uuid)) {
      return Relationship.MANAGER;
    }

    // supporter
    if (
      roleSet[DesignEntity.GROUP]?.byId[entity.uuid] === Relationship.SUPPORTER
    ) {
      return Relationship.SUPPORTER;
    }

    return Relationship.NONE;
  };

  const relateRole = (entity) => {
    // manager
    if (managedEntities[DesignEntity.ROLE]?.has(entity.uuid)) {
      return Relationship.MANAGER;
    }

    // assignee
    if (entity.user_uuid === me.uuid) {
      return Relationship.ASSIGNEE;
    }

    // supporter
    if (entity.group_uuid) {
      const groupId = entity.group_uuid;
      if (
        roleSet[DesignEntity.GROUP]?.byId[groupId] === Relationship.SUPPORTER
      ) {
        return Relationship.SUPPORTER;
      }
    }

    return Relationship.NONE;
  };

  const relateActivity = (entity) => {
    // manager
    if (managedEntities[DesignEntity.ACTIVITY]?.has(entity.uuid)) {
      return Relationship.MANAGER;
    }

    // assignee
    if (
      entity.owner_type === DesignEntity.ROLE &&
      relatedEntities[DesignEntity.PERSON]
        .get(me?.uuid)
        ?.[DesignEntity.ROLE].includes(entity.owner_uuid)
    ) {
      return Relationship.ASSIGNEE;
    }

    // supporter
    const supportedGroups = roleSet?.GROUP?.byRole.supporter || new Set();
    for (const groupId of supportedGroups) {
      if (
        relatedEntities[DesignEntity.GROUP]
          .get(groupId)
          ?.[DesignEntity.ACTIVITY].includes(entity.uuid)
      ) {
        return Relationship.SUPPORTER;
      }
    }

    return Relationship.NONE;
  };

  const relatePerson = (entity) => {
    // self
    if (entity.uuid === me.uuid) {
      return Relationship.SELF;
    }

    // manager
    if (managedEntities[DesignEntity.PERSON]?.has(entity.uuid)) {
      return Relationship.MANAGER;
    }

    // supporter
    const supportedGroups = roleSet?.GROUP?.byRole.supporter || new Set();
    for (const groupId of supportedGroups) {
      if (
        relatedEntities[DesignEntity.GROUP]
          .get(groupId)
          ?.[DesignEntity.PERSON].includes(entity.uuid)
      ) {
        return Relationship.SUPPORTER;
      }
    }

    return Relationship.NONE;
  };

  /**
   * @param {Object} entity
   * @param {String} type
   * @returns {Boolean}
   */
  const relate = (entity, type) => {
    if (!me?.uuid) {
      return Relationship.NONE;
    }

    switch (type) {
      case DesignEntity.DESIGN:
      case DesignEntity.SCENARIO:
        return relateDesign(entity, type);

      case DesignEntity.GROUP:
        return relateGroup(entity);

      case DesignEntity.ROLE:
        return relateRole(entity);

      case DesignEntity.ACTIVITY:
        return relateActivity(entity);

      case DesignEntity.PERSON:
        return relatePerson(entity);
    }

    return Relationship.NONE;
  };

  return relate;
}
