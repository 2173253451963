/**
 * @param {String} string
 * @param {Boolean} lower
 * @returns {String}
 */
export default function ucfirst(string, lower = false) {
  if (!string) {
    return '';
  }

  let result = string.trim();
  if (lower) {
    result = result.toLowerCase();
  }

  return `${result[0].toUpperCase()}${result.slice(1)}`;
}
