import { Property, Relationship } from '@/lib/enums';
import { multipleEntityActions } from '../config';

/**
 * @param {Array} policies
 * @param {Object} relationships
 * @param {String} level
 * @param {Object} entity
 * @param {String} property
 * @param {String} action
 * @returns void
 */
export default function handleMultipleEntityActions(
  policies,
  relationships,
  level,
  entity,
  property,
  action
) {
  const entityType = entity.__type;
  const rule = multipleEntityActions.find(
    (item) =>
      item.entityType === entityType &&
      item.property === property?.replace('_uuid', '') &&
      item.action === action
  );

  if (!rule) {
    return;
  }

  const referencedEntityType =
    property === Property.OWNER ? entity.owner_type : rule.referencedEntityType;
  const referencedProperty = rule.referencedProperty;
  const referencedAction = rule.referencedAction;
  const referencedEntityId = entity[`${property}_uuid`];

  const relationship =
    relationships?.current[referencedEntityId] ?? Relationship.NONE;

  policies.push({
    level,
    relationship,
    entityType: referencedEntityType.toLowerCase(),
    property: referencedProperty,
    action: referencedAction,
  });
}
