import { DimensionType } from '@/molecules/tableElements';

export default function getTagDimensionEntityType(dimension) {
  if (dimension === DimensionType.ACTIVITY_TAGS_ACTIVITY) {
    return 'activity';
  }
  if (
    dimension === DimensionType.ROLE_TAGS_ACTIVITY ||
    dimension === DimensionType.ROLE_TAGS_ROLE
  ) {
    return 'role';
  }
  if (
    dimension === DimensionType.GROUP_TAGS_ACTIVITY ||
    dimension === DimensionType.GROUP_TAGS_GROUP
  ) {
    return 'team';
  }
}
