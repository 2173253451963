/**
 * @param {number} numerator
 * @param {number} denominator
 * @return {number}
 */
export default function percent(numerator, denominator, precision = 0) {
  const numeratorClean = Number(numerator);
  const denominatorClean = Number(denominator);

  if (denominatorClean === 0 && numeratorClean !== 0) {
    return 0;
  }

  if (numeratorClean === 0 && denominatorClean === 0) {
    return 100;
  }

  if (isNaN(numeratorClean) || isNaN(denominatorClean)) {
    return 0;
  }

  const percentage = (numeratorClean / denominatorClean) * 100;
  const factor = Math.pow(10, precision);

  return parseFloat(Math.round(percentage * factor) / factor);
}
