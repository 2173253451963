import config from '@/lib/config';

export default function reset(entities, visibility = config.VISIBILITY.FULL) {
  if (!entities) {
    return entities;
  }

  return Array.from(entities).map((entity) => {
    if (entity.visibility !== config.VISIBILITY.FULL) {
      return {
        ...entity,
        __visibility: visibility,
      };
    }

    return entity;
  });
}
