import { useCallback, useEffect, useMemo, useState } from 'react';
import { ChipType } from '@/atoms/chips/enums';
import { ChipSelect } from '@/atoms/inputs';
import { Colors as InputColors } from '@/atoms/inputs';
import { ActivityTags } from '@/molecules/tags';
import useTagList from '@/molecules/tags/useTagList';
import { useDispatchContext, useTagContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DesignEntity } from '@/lib/enums';

const MAX_POPOVER_HEIGHT = 240;

const InlineTagField = ({
  allowedTagSet,
  color = 'primary',
  expanded,
  id,
  initialTagIds,
  onUpdate,
  name,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { canEdit, handleDispatch } = useDispatchContext();
  const [selectedIds, setSelectedIds] = useState([]);
  const [isEditing, setIsEditing] = useState(true);
  const { tagList } = useTagList(selectedIds);

  const { entityTagMap, tagMap } = useTagContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [width, setWidth] = useState(0);

  const tagOptions = entityTagMap?.get(DesignEntity.ACTIVITY);

  const optionsList = useMemo(() => {
    if (!tagOptions?.length) {
      return [];
    }

    if (!allowedTagSet) {
      return tagOptions;
    }

    const list = [];

    tagOptions.forEach((category) => {
      const tagList = category.options.filter(({ id }) => {
        return allowedTagSet.has(id);
      });

      if (tagList.length > 0) {
        list.push(category);
      }
    });

    return list;
  }, [allowedTagSet, tagOptions]);

  // Update the selected tags if there has been a change received by the
  // parent component.
  useEffect(() => {
    setSelectedIds(initialTagIds);
  }, [JSON.stringify(initialTagIds)]);

  // Triggers editing mode of the chip select field.
  const handleClick = useCallback(
    (event) => {
      if (!canEdit || !optionsList.length) {
        return;
      }
      setIsEditing(!isEditing);
      setAnchorEl(event.currentTarget);
      setWidth(event.currentTarget?.offsetWidth + 32);
    },
    [canEdit, isEditing]
  );

  // Only dispatch changes when the user closes the editing mode.
  const handleClose = useCallback(() => {
    setAnchorEl(null);
    onUpdate?.(event, selectedIds);

    // Dispatch the updates.
    if (handleDispatch) {
      handleDispatch?.({
        properties: {
          uuid: id,
          [name]: selectedIds,
        },
      });
    }
  }, [id, selectedIds]);

  /**
   * @param {Object} event
   * @param {Array} changeValue
   */
  const handleChange = useCallback((event, ids) => {
    setSelectedIds(ids);
  });

  return (
    <>
      <Box onClick={handleClick}>
        <ActivityTags
          canEdit={canEdit && optionsList.length > 0}
          expanded={expanded}
          label="Tags"
          tags={tagList}
        />
      </Box>
      {anchorEl && (
        <Popover
          anchorEl={anchorEl}
          anchorReference={isLargeScreen ? 'anchorEl' : 'anchorPosition'}
          anchorPosition={{ top: '-16px', left: '-16px' }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          id={`edit-select-${id}`}
          elevation={0}
          onClose={handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          sx={{
            borderRadius: '4px',
            minWidth: isLargeScreen ? width : '100%',
            maxWidth: isLargeScreen ? width : '100%',
            '& .MuiPaper-root': {
              position: isLargeScreen ? 'relative' : 'fixed',
              boxShadow: `0 2px 5px 1px ${InputColors.Input[color].normal.borderColor}, 0 0 0 1px ${InputColors.Input[color].normal.borderColor}`,
              borderRadius: '4px',
              maxHeight: isLargeScreen ? `${MAX_POPOVER_HEIGHT}px` : '100vh',
              height: isLargeScreen ? 'auto' : '100vh',
              width: isLargeScreen ? 'auto' : '100%',
              minWidth: isLargeScreen ? 'auto' : '100%',
              overflow: 'hidden',
            },
          }}
        >
          <Box minWidth="100%">
            <ChipSelect
              categorised={true}
              color="primary"
              chipType={ChipType.TAG}
              id={`tag-select-${id}`}
              label="Select tags to be added to this activity"
              maxHeight={MAX_POPOVER_HEIGHT}
              onChange={handleChange}
              onClose={handleClose}
              options={{
                list: optionsList,
                map: tagMap,
              }}
              selectedIds={selectedIds}
              showCloseButton={!isLargeScreen}
            />
          </Box>
        </Popover>
      )}
    </>
  );
};

export default InlineTagField;
