import { DesignEntity, Visibility } from '@/lib/enums';

export default function formatByActivity({
  entityId,
  entityType,
  shouldIncludeNested,
  snapshotEntityMap,
}) {
  const groupMap = new Map();
  const roleMap = new Map();
  const activityMap = new Map();
  const personMap = new Map();

  const roleList = new Set([
    ...snapshotEntityMap.get(DesignEntity.ROLE).keys(),
  ]);
  const groupList = new Set([
    ...snapshotEntityMap.get(DesignEntity.GROUP).keys(),
  ]);

  // iterate activities to construct map
  Array.from(snapshotEntityMap.get(DesignEntity.ACTIVITY).values()).forEach(
    (activity) => {
      // filtered activities
      if (
        activity.__visibility === Visibility.NONE ||
        activity.hours === null
      ) {
        return;
      }

      // If this is an unallocated role we don't set a role.
      const isUnallocated = activity.owner_type === DesignEntity.GROUP;
      const role = isUnallocated
        ? null
        : snapshotEntityMap.get(DesignEntity.ROLE).get(activity.owner_uuid);

      const person = role
        ? snapshotEntityMap.get(DesignEntity.PERSON).get(role?.user_uuid)
        : null;

      const group = snapshotEntityMap
        .get(DesignEntity.GROUP)
        .get(isUnallocated ? activity.owner_uuid : role?.group_uuid);

      if (activity.owner_uuid === entityId || role?.group_uuid === entityId) {
        activityMap.set(activity.uuid, activity);

        if (role && !roleMap.has(role.uuid)) {
          roleMap.set(role.uuid, role);
          roleList.delete(role.uuid);
        }
        if (person && !personMap.has(person.uuid)) {
          personMap.set(person.uuid, person);
        }

        if (group && entityType === DesignEntity.GROUP) {
          if (!groupMap.has(group.uuid)) {
            groupMap.set(group.uuid, group);
            groupList.delete(group.uuid);
          }
        }
      }

      // If this is an unallocated activity in a group entity, include it and
      // exit.
      if (isUnallocated) {
        if (
          group &&
          (group.__path.has(entityId) || group.uuid === entityId) &&
          !groupMap.has(group.uuid)
        ) {
          groupMap.set(group.uuid, group);
          groupList.delete(group.uuid);
        }

        return;
      }

      // If we're not including nested entities we should exit at this point.
      if (!shouldIncludeNested) {
        return;
      }

      // We're here because the activity doesn't belong to the current entity
      // but we want to include nested roles and this checks if the activity
      // owner is included in the hierarchy of the scenario entity.
      if (role?.__path.has(entityId)) {
        activityMap.set(activity.uuid, activity);

        if (role && !roleMap.has(role.uuid)) {
          roleMap.set(role.uuid, role);
          roleList.delete(role.uuid);
        }
        if (person && !personMap.has(person.uuid)) {
          personMap.set(person.uuid, person);
        }
      }
    }
  );

  if (!shouldIncludeNested) {
    return {
      activityMap,
      groupMap,
      roleMap,
      personMap,
    };
  }

  [...roleList].forEach((id) => {
    const role = snapshotEntityMap.get(DesignEntity.ROLE).get(id);
    const person = role
      ? snapshotEntityMap.get(DesignEntity.PERSON).get(role?.user_uuid)
      : null;

    if (
      (role?.__path.has(entityId) || role.uuid === entityId) &&
      !roleMap.has(role.uuid)
    ) {
      roleMap.set(role.uuid, role);
      roleList.delete(role.uuid);

      if (person && !personMap.has(person.uuid)) {
        personMap.set(person.uuid, person);
      }
    }
  });

  [...groupList].forEach((id) => {
    const group = snapshotEntityMap.get(DesignEntity.GROUP).get(id);
    if (
      (group?.__path.has(entityId) || group.uuid === entityId) &&
      !groupMap.has(group.uuid)
    ) {
      groupMap.set(group.uuid, group);
      groupList.delete(group.uuid);
    }
  });

  return {
    activityMap,
    groupMap,
    roleMap,
    personMap,
  };
}
