import { createContext, useContext, useMemo } from 'react';
import useDerivedDesigns from '@/shared/hooks/useDerivedDesigns';
import { Tags } from '@pkg/entities';
import { Groups } from '@pkg/entities';

export const DesignsContext = createContext({
  designs: new Map(),
  isLoading: true,
  supporterMap: new Map(),
  tagMap: new Map(),
});

export const useDesignsContext = () => useContext(DesignsContext);

// Makes a list of hyrdated designs available downstream.
const DesignsProvider = ({ children, designIds }) => {
  const { tags } = Tags.useStore();

  const { supporterMap, isLoading: isLoadingSupporters } =
    Groups.useSupporterMap();

  const { designs, isLoading } = useDerivedDesigns(designIds);
  const context = useMemo(() => {
    return {
      designs,
      isLoading: isLoading || isLoadingSupporters,
      supporterMap,
      tagMap: tags.map,
    };
  }, [designs, isLoading, isLoadingSupporters, supporterMap, tags]);

  return (
    <DesignsContext.Provider value={context}>
      {children}
    </DesignsContext.Provider>
  );
};

export default DesignsProvider;
