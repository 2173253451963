import { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useClient, GraphQLError } from '@pkg/client';

/**
 * @param {object} params
 * @param {any[]} [params.query]
 *
 * @return {object}
 */
export default function useGraphQLQuery({ query, onError, ...options }) {
  const [isAuthError, setAuthError] = useState(false);
  const client = useClient();

  function handleErrors(error) {
    if (error instanceof GraphQLError && error.isAuthorizationError()) {
      setAuthError(true);
    }

    if (onError) {
      onError(error);
    }
  }

  async function fetcher() {
    const [mutation, variables] = query;
    const response = await client.graphql({ query: mutation, variables });
    return response.data;
  }

  const defaultOptions = {
    refetchOnWindowFocus: false,
  };

  const result = useQuery({
    queryKey: options.queryKey ?? query,
    queryFn: fetcher,
    onError: handleErrors,
    ...defaultOptions,
    ...options,
  });

  const queryClient = useQueryClient();
  const invalidate = () => {
    queryClient.invalidateQueries(options.queryKey ?? query);
  };

  return {
    invalidate,
    isAuthError,
    ...result,
  };
}
