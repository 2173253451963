import { DesignEntity, Visibility } from '@/lib/enums';
import {
  formatActivityData,
  formatDesignData,
  formatGroupData,
  formatRoleData,
  getEntities,
} from './formatTableData';

/**
 * Takes an array of scenario maps and converts them into tabular data.
 *
 * @param {Array}
 * @return {Object}
 */
export default function formatActivityTableData({
  scenarioMaps,
  showBudget,
  propertyMap,
  skillMap,
  tagMap,
}) {
  const rows = [];

  scenarioMaps?.forEach((scenarioMap) => {
    const activities = scenarioMap.get(DesignEntity.ACTIVITY);
    // Ensure we filter the roles and groups from the table.
    const roleList = [...scenarioMap.get(DesignEntity.ROLE).values()].filter(
      ({ __visibility }) => __visibility !== Visibility.NONE
    );
    const groupList = [...scenarioMap.get(DesignEntity.GROUP).values()].filter(
      ({ __visibility }) => __visibility !== Visibility.NONE
    );
    const organisationHours = groupList.reduce((prev, next) => {
      return (prev += next.__visible_metrics.hours);
    }, 0);

    // We track the roles and groups so we can show entities that don't have
    // any activities associated with them.
    const roles = new Set([roleList.map(({ uuid }) => uuid)]);
    const groups = new Set(groupList.map(({ uuid }) => uuid));

    activities.forEach((activity) => {
      if (activity.__visibility === Visibility.NONE) {
        return;
      }
      const { design, role, group, groupLead, person } = getEntities({
        activity,
        scenarioMap,
      });

      const rowData = {
        ...formatActivityData({
          activity,
          role,
          propertyMap,
          tagMap,
        }),
        ...formatRoleData({
          activity,
          showBudget,
          person,
          propertyMap,
          role,
          tagMap,
          skillMap,
        }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      };

      rows.push(rowData);
      roles.delete(role?.uuid);
      groups.delete(group?.uuid);
    });

    // Add the roles that don't have activities associated wth them.
    roles.forEach((id) => {
      const role = scenarioMap.get(DesignEntity.ROLE).get(id);
      const { design, group, groupLead, person } = getEntities({
        role,
        scenarioMap,
      });

      rows.push({
        ...formatRoleData({
          showBudget,
          role,
          person,
          propertyMap,
          tagMap,
          skillMap,
        }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      });
    });

    // Add the groups that don't have any activities associated with them.
    groups.forEach((id) => {
      const group = scenarioMap?.get(DesignEntity.GROUP).get(id);
      const { design, groupLead } = getEntities({ group, scenarioMap });

      rows.push({
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design, organisationHours),
      });
    });
  });

  return rows;
}
