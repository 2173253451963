import { useMemo } from 'react';
import * as Mutations from './mutations';

/**
 * @return {Object}
 */
export default function useMutations() {
  const create = Mutations.useCreate();
  const createMany = Mutations.useCreateMany();
  const merge = Mutations.useMerge();
  const update = Mutations.useUpdate();

  return useMemo(
    () => ({
      create,
      createMany,
      merge,
      update,
    }),
    [create, createMany, merge, update]
  );
}
