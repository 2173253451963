import openai from '../client';
import createFilters from '../tools/createFilters/createFilters';
import createMutation from '../tools/createMutation/createMutation';
import generateReport from '../tools/generateReport/generateReport';
import navigateTo from '../tools/navigateTo/navigateTo';

const YAML = require('json-to-pretty-yaml');

const mainPrompt = async ({
  userText,
  lastMessages,
  snapshot,
  tags,
  stream,
}) => {
  const { roles, groups, activities } = snapshot.entities;
  console.log({ lastMessages });

  const rolesOutput = roles.map((role) => {
    const group = groups.find((group) => group.uuid === role.group_uuid);
    return {
      role_title: role.title,
      group_uuid: role.group_uuid || '',
      group_name: group ? group.name : '',
      role_uuid: role.uuid,
      user_uuid: role.user_uuid,
      title: role.title,
      fte: role.fte,
      budget: role.budget,
      activities: activities
        .filter((activity) => activity.owner_uuid === role.uuid)
        .map((activity) => ({
          uuid: activity.uuid,
          owner_uuid: activity.owner_uuid,
          description: activity.__description,
          hours: activity.hours,
          tags: activity.tags || [],
        })),
    };
  });

  let prompt = `
          You are an organizational programming bot integrated into a JavaScript-based app that creates, updates, and optimizes roles through mutation of structured data models. Your purpose is to assist users in identifying inefficiencies, improving productivity, and designing roles that align with organizational goals.

          You have deep knowledge of workforce dynamics, including the challenges of workload distribution, employee engagement, and organizational structure. You also provide insights into the underlying issues within teams and suggest data-driven solutions to energize work and prevent burnout.

          Your responses must be clear, concise, and action-oriented, tailored to the user's specific context. Ensure that your advice aligns with Beamible's mission to enhance productivity while maintaining a healthy, engaging workplace environment.

`;

  if (stream) {
    prompt += `
          Here is the current organizational structure:

          ${YAML.stringify(rolesOutput)}
    
          Here is a list of tags the organisation uses;

          ${YAML.stringify(
            tags.tags.list.slice(0, 30).map((tag) => ({
              uuid: tag.uuid,
              name: tag.name,
              description: tag.description,
            }))
          )}
     
    `;
  } else {
    prompt += `Please choose the appropoiate tool to call. If the users request is not relevant to a tool, do not invoke a tool call. Instead, reply shortly with "I don't understand the question, can you rephrase it?"`;
  }

  prompt += `

  Last messages;
  ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

  The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.

  Users request: ${userText}
  `;

  const mainPrompt = {
    model: openai('gpt-4-turbo'),
    tools: {
      createMutation,
      generateReport,
      createFilters,
      navigateTo,
    },
    prompt,
  };

  return mainPrompt;
};

/*
# Rules
    
## Roles

Keep skills, properties, and tags in the mutation empty.

For dates, use the current epoch.

Choose an appropiate budget and fte for the role.

Mutations should be valid JSON.

Don't set a user_uuid.

You can create multiple roles in a single mutation if the user request it.

Generate a random uuid for the uuid.

If the user request to change who manages who, set the parent_uuid to the new manager role uuid. Select the parent_uuid from the supplied list of role uuids above.

If you are updating a role, select the uuid from the list based off the role name.

If updating a role, don't include fields that didn't change. And update the updated_at timestamp and remove the created_at.

Create a mutation based off the users request, if they are just asking for information, give them a well structured response, explain your reasoning, and suggest modern best practices.

## Reports

If there question is asking for information, generate a report based off the question. Do not answer the question directly, instead generate a report that answers the question.

If you get a question that doesn't make sense or is not relevant to organizational design. Just reply shortly with "I don't understand the question, can you rephrase it?"

Do not include keywords that aren't relevanr to the user's question

## Activities

If the user applies or removes tags, concat the original tags with the new tags.

When setting tags on activities or roles, make sure to use their uuid equivelent.

Tag mutations MUST be uuid's from the tag list above, do NOT use the name's.
*/

export default mainPrompt;
