import { Fragment } from 'react';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { PersonSummary } from '@/molecules/summaries';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

const PeopleList = ({ endComponent, people, size = Size.SMALL, onClick }) => {
  return (
    people.length > 0 && (
      <Grid container>
        {people.map((person, index) => (
          <Fragment key={person.uuid}>
            <Grid item xs={12} py={1}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <PersonSummary
                  avatar={person.avatar}
                  person={person}
                  onClick={onClick}
                  size={size}
                />
                {endComponent && endComponent(person)}
              </Stack>
            </Grid>
            {index < people.length - 1 && (
              <Divider overrideStyles={{ width: '100%' }} />
            )}
          </Fragment>
        ))}
      </Grid>
    )
  );
};

export default PeopleList;
