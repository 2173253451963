import { useCallback } from 'react';
import { useMutation } from '@pkg/graphql';

const UPDATE_FLOW = /* GraphQL */ `
  mutation UpdateFlow($input: UpdateFlowInput!) {
    updateFlow(input: $input) {
      uuid
      status
    }
  }
`;

/**
 * @param {any[]} query
 * @return {Function}
 */
export default function useUpdateFlow(query) {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_FLOW,
    invalidateQuery: query,
  });

  /**
   * @param {Object}
   * @returns {Object|null}
   */
  function updateFlow({ uuid, status }) {
    return mutateAsync({
      uuid,
      status,
    });
  }

  return useCallback(updateFlow, [mutateAsync]);
}
