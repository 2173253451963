import { useEffect, useRef, memo } from 'react';
import ReactMarkdown from 'react-markdown';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import * as Auth from '@pkg/auth';
import Action from './Action';

const Actor = memo(function Actor({ children, type }) {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <Avatar
        sx={{
          width: 32,
          height: 32,
          bgcolor: type === 'User' ? '#1976d2' : '#2e7d32',
        }}
      >
        {type === 'User' ? 'U' : 'B'}
      </Avatar>
      <span style={{ fontWeight: 'bold' }}>{children}</span>
    </div>
  );
});

const Message = memo(function Message({ msg }) {
  const messageRef = useRef(null);
  const { me, organisation } = Auth.useStore();

  console.log({ msg });
  return (
    <div className={`message ${msg.sender}`} style={{ marginBottom: '0.5rem' }}>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          alignItems: 'flex-start',
          marginBottom: '4px',
        }}
      >
        <Actor type={msg.sender}>
          {msg.sender === 'User' ? 'You: ' : 'Beamible: '}
        </Actor>
      </div>
      <div
        ref={messageRef}
        style={{
          backgroundColor: '#ffffff',
          padding: '0 12px',
          borderRadius: '4px',
          marginLeft: '40px',
          '& pre': {
            background: '#f6f8fa',
            padding: '0.75rem',
            borderRadius: '4px',
          },
          '& code': {
            background: '#f6f8fa',
            padding: '0.2rem 0.4rem',
            borderRadius: '3px',
          },
          '& p': { margin: '0.25rem 0' },
        }}
      >
        <ReactMarkdown>{msg.message}</ReactMarkdown>
      </div>

      {msg.actions && msg.actions.length > 0 && (
        <div
          style={{
            marginLeft: '46px',
            marginTop: '4px',
            marginBottom: '30px',
          }}
        >
          {msg.actions.map((action, index) => (
            <span key={index}>
              <Action action={action} />
            </span>
          ))}
        </div>
      )}
    </div>
  );
});

const ChatMessages = memo(function ChatMessages({ messages, thinking }) {
  const messagesEndRef = useRef(null);
  const lastMessageRef = useRef(null);
  const lastMessageLengthRef = useRef(0);
  const lastMessageCountRef = useRef(messages.length);

  useEffect(() => {
    const shouldScrollToBottom =
      messages.length !== lastMessageCountRef.current || // New message added
      (messages.length > 0 &&
        messages[messages.length - 1].message?.length !==
          lastMessageLengthRef.current); // Last message content changed

    if (shouldScrollToBottom) {
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      if (messages.length > 0) {
        lastMessageLengthRef.current =
          messages[messages.length - 1].message?.length || 0;
      }
      lastMessageCountRef.current = messages.length;
    }
  }, [messages]);

  return (
    <div
      className="chat-messages"
      style={{
        height: '100%',
        width: '100%',
        paddingBottom: '40px',
        marginBottom: '40px',
      }}
    >
      {messages.map((msg, index) => (
        <Message
          key={index}
          msg={msg}
          ref={index === messages.length - 1 ? lastMessageRef : null}
        />
      ))}
      {thinking && (
        <div className="message bot" style={{ marginBottom: '0.5rem' }}>
          <div
            style={{ display: 'flex', gap: '8px', alignItems: 'flex-start' }}
          >
            <Actor type="bot">Beamible: </Actor>
          </div>
          <div
            style={{
              marginLeft: '40px',
              display: 'flex',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <CircularProgress size={20} />
            <span>Thinking...</span>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  );
});

ChatMessages.displayName = 'ChatMessages';
Message.displayName = 'Message';
Actor.displayName = 'Actor';

export default ChatMessages;
