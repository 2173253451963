/**
 * @param {Array} value
 * @param {object} options // Intl.ListFormat options
 * @return {string}
 */
export default function toString(value, options = {}) {
  if (!Array.isArray(value)) {
    return null;
  }

  const formatter = new Intl.ListFormat('en', options);

  return formatter.format(value);
}
