import { EntityType } from '@/shared/enums';
import addActionByActionType from './addActionByActionType';

export default function mapAggregateActions({
  comparisonScenario,
  scenario,
  showBudget,
}) {
  if (!comparisonScenario) {
    return;
  }

  const actionMap = new Map();

  // Keep a record of the comparison entities.
  const originalActivityList = new Set(
    comparisonScenario.relationships?.get(EntityType.ACTIVITY)?.keys()
  );
  const originalRoleList = new Set(
    comparisonScenario.relationships?.get(EntityType.ROLE)?.keys()
  );
  const originalManagerList = new Set(
    [...originalRoleList].filter(
      (id) =>
        comparisonScenario.relationships?.get(EntityType.ROLE)?.get(id)
          .is_manager
    )
  );
  const originalGroupList = new Set(
    comparisonScenario.relationships?.get(EntityType.GROUP)?.keys()
  );
  // Keep a list of the scenario roles and groups to handle actions for roles
  // and groups without activities associated with them.
  const scenarioRoleList = new Set(
    scenario.relationships?.get(EntityType.ROLE)?.keys()
  );
  const scenarioGroupList = new Set(
    scenario.relationships?.get(EntityType.GROUP)?.keys()
  );

  // Add actions connected to the current activities in the scenario. This
  // should capture most actions.
  [...scenario.relationships.get(EntityType.ACTIVITY).values()].forEach(
    (activity) => {
      const originalActivity = comparisonScenario.relationships
        ?.get(EntityType.ACTIVITY)
        ?.get(activity.uuid);
      const role =
        activity.owner_type === EntityType.ROLE
          ? scenario.relationships
              .get(EntityType.ROLE)
              ?.get(activity.owner_uuid)
          : null;
      const originalRole = comparisonScenario.relationships
        .get(EntityType.ROLE)
        ?.get(role?.uuid);
      const group = role
        ? scenario?.relationships.get(EntityType.GROUP)?.get(role.group_uuid)
        : scenario?.relationships
            .get(EntityType.GROUP)
            ?.get(activity.owner_uuid);
      const originalGroup = group
        ? comparisonScenario.relationships
            .get(EntityType.GROUP)
            ?.get(group.uuid)
        : null;

      // Remove the entities from their lists so we restrict additional data
      // changes to those entities which haven't been accounted for.
      originalActivityList.delete(originalActivity?.uuid);
      originalRoleList.delete(originalRole?.uuid);
      originalGroupList.delete(originalGroup?.uuid);
      scenarioRoleList.delete(role?.uuid);
      scenarioGroupList.delete(group?.uuid);

      const actions = [];

      actions.push(
        ...addActionByActionType({
          activity,
          actionMap,
          comparisonScenario,
          group,
          originalActivity,
          originalGroup,
          originalRole,
          role,
          scenario,
          showBudget,
        })
      );

      for (let i = 0; i < actions?.length; i++) {
        const action = actions[i];
        actionMap.set(action.type, { ...action });
      }
    }
  );

  // Loop through the activities which are present in the comparison but not
  // in the scenario.
  [...originalActivityList].forEach((id) => {
    const originalActivity = comparisonScenario.relationships
      .get(EntityType.ACTIVITY)
      ?.get(id);
    const originalRole =
      originalActivity.owner_type === EntityType.ROLE
        ? comparisonScenario.relationships
            .get(EntityType.ROLE)
            ?.get(originalActivity?.owner_uuid)
        : null;
    const role = scenario?.relationships
      .get(EntityType.ROLE)
      ?.get(originalRole?.uuid);
    const originalGroup = originalRole
      ? comparisonScenario?.relationships
          .get(EntityType.GROUP)
          ?.get(originalRole.group_uuid)
      : scenario?.relationships
          .get(EntityType.GROUP)
          ?.get(originalActivity?.owner_uuid);
    const group = scenario?.relationships
      .get(EntityType.GROUP)
      ?.get(originalGroup?.uuid);

    if (!originalActivity) {
      return;
    }

    // Remove the entities from their lists so we restrict additional data
    // changes to those entities which haven't been accounted for.
    originalActivityList.delete(originalActivity?.uuid);
    originalRoleList.delete(originalRole?.uuid);
    originalGroupList.delete(originalGroup?.uuid);
    scenarioRoleList.delete(role?.uuid);
    scenarioGroupList.delete(group?.uuid);

    const actions = [];

    actions.push(
      ...addActionByActionType({
        actionMap,
        comparisonScenario,
        group,
        originalActivity,
        originalGroup,
        originalRole,
        role,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];
      actionMap.set(action.type, { ...action });
    }
  });

  // Loop through scenario roles that don't have any activities associated with
  // them.
  [...scenarioRoleList].forEach((id) => {
    const role = scenario.relationships.get(EntityType.ROLE)?.get(id);
    const originalRole = comparisonScenario.relationships
      .get(EntityType.ROLE)
      ?.get(id);
    const group = scenario?.relationships
      .get(EntityType.GROUP)
      ?.get(role?.group_uuid);
    const originalGroup = comparisonScenario?.relationships
      .get(EntityType.GROUP)
      ?.get(originalRole?.group_uuid);

    scenarioRoleList.delete(role?.uuid);

    if (!role) {
      return;
    }

    // Remove the entities from their lists so we restrict additional data
    // changes to those entities which haven't been accounted for.
    scenarioRoleList.delete(role?.uuid);
    originalRoleList.delete(originalRole?.uuid);
    originalGroupList.delete(originalGroup?.uuid);
    scenarioGroupList.delete(group?.uuid);

    const actions = [];

    actions.push(
      ...addActionByActionType({
        actionMap,
        comparisonScenario,
        group,
        originalGroup,
        originalRole,
        role,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];
      actionMap.set(action.type, { ...action });
    }
  });

  // Loop through any comparions roles that didn't have activities associated
  // with them.
  [...originalRoleList].forEach((id) => {
    const originalRole = comparisonScenario.relationships
      .get(EntityType.ROLE)
      ?.get(id);
    const group = scenario?.relationships
      .get(EntityType.GROUP)
      ?.get(originalRole?.group_uuid);
    const originalGroup = comparisonScenario?.relationships
      .get(EntityType.GROUP)
      ?.get(originalRole?.group_uuid);

    if (!originalRole) {
      return;
    }

    const actions = [];

    // Remove the entities from their lists so we restrict additional data
    // changes to those entities which haven't been accounted for.
    actions.push(
      ...addActionByActionType({
        actionMap,
        comparisonScenario,
        group,
        originalGroup,
        originalRole,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];
      actionMap.set(action.type, { ...action });
    }
  });

  // Loop through any groups which don't have roles associated with them.
  [...scenarioGroupList].forEach((id) => {
    const group = scenario?.relationships.get(EntityType.GROUP)?.get(id);
    const originalGroup = comparisonScenario?.relationships
      .get(EntityType.GROUP)
      ?.get(group.uuid);

    originalGroupList.delete(originalGroup?.uuid);
    scenarioGroupList.delete(group?.uuid);

    if (!group) {
      return;
    }

    const actions = [];
    actions.push(
      ...addActionByActionType({
        actionMap,
        comparisonScenario,
        group,
        originalGroup,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];
      actionMap.set(action.type, { ...action });
    }
  });

  // Loop through any comparison groups which didn't have roles associated with
  // them.
  [...originalGroupList].forEach((id) => {
    const originalGroup = comparisonScenario?.relationships
      .get(EntityType.GROUP)
      ?.get(id);
    const group = scenario?.relationships
      .get(EntityType.GROUP)
      ?.get(originalGroup?.uuid);

    if (!originalGroup) {
      return;
    }

    const actions = [];

    actions.push(
      ...addActionByActionType({
        actionMap,
        comparisonScenario,
        group,
        originalGroup,
        scenario,
        showBudget,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];
      actionMap.set(action.type, { ...action });
    }
  });

  return actionMap;
}
