import { getIn } from 'formik';
import Grid from '@mui/material/Grid';
import FieldErrors from './FieldErrors';
import StatusMessage from './StatusMessage';

const FormStatus = ({
  successMessage,
  errorMessage,
  status,
  errors = {},
  submitCount = 0,
  ...props
}) => {
  const hasFieldErrors = Object.keys(errors).length > 0;

  if (submitCount < 1 || !status) {
    return null;
  }

  return (
    <Grid {...props} data-testid="form-status">
      {hasFieldErrors === false && (
        <StatusMessage
          ok={getIn(status, 'ok')}
          code={getIn(status, 'code')}
          responseMessage={getIn(status, 'response.message')}
          successMessage={successMessage}
          errorMessage={errorMessage}
        />
      )}
      {hasFieldErrors === true && (
        <FieldErrors ok={getIn(status, 'ok')} errors={errors} />
      )}
    </Grid>
  );
};

export default FormStatus;
