const FilterType = Object.freeze({
  BOOLEAN: 'BOOLEAN',
  DATE: 'DATE',
  MULTI_SELECT: 'MULTI_SELECT',
  NUMBER: 'NUMBER',
  SELECT: 'SELECT',
  TEXT: 'TEXT',
});

export default FilterType;
