import config from '@/lib/config';

/**
 * @param {Object} keyed
 * @returns {Object}
 */
export default function getFlattened(keyed) {
  if (!keyed) {
    return keyed;
  }

  console.time('Snapshots.utils.getFlattened');
  const flattened = {
    ...keyed,
    entities: structuredClone(config.DEFAULT_SNAPSHOT.entities),
  };

  for (const plural in keyed.entities) {
    keyed.__keys[plural].forEach((id) => {
      const entity = keyed.entities[plural][id];
      flattened.entities[plural].push(entity);
    });
  }

  console.timeEnd('Snapshots.utils.getFlattened');
  return Object.freeze(flattened);
}
