import { useEffect, useRef, useState } from 'react';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { LayerDistributionChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';
import LayersDistributionChart from './LayersDistributionChart';

const MetricList = [
  EntityMetric.MANAGERS,
  EntityMetric.ROLES,
  EntityMetric.FTE,
  EntityMetric.BUDGET,
];

const LayersDistribution = ({
  dimensions,
  dimensionLabel,
  fullscreen,
  levelType,
  metricOptions,
  title,
}) => {
  const ref = useRef();
  const [metric, setMetric] = useState(metricOptions[0]);
  const [dimension, setDimension] = useState(dimensions[levelType]?.[0]);
  const [sort, setSort] = useState({
    metric: metricOptions[0],
    type: 'DESC',
    layers: true,
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();
  const [focusItem, setFocusItem] = useState();
  const [tableData, setTableData] = useState();
  const [metrics, setMetrics] = useState();

  const { chartData, metricTotals } = useAggregateChartData({
    entityType: EntityType.ROLE,
    excludeSeparators: true,
    dimension,
    filter,
    includeStack: true,
    sort,
  });

  const hasLayers = metricTotals?.count > 0;

  const handleItemFocus = (item) => {
    if (!item?.id || item.id === focusItem) {
      setFocusItem(null);
      setTableData(chartData);
      setMetrics(metricTotals);
      return;
    }
    const tableData = chartData.filter(({ id }) => id.startsWith(item.id));

    setTableData(tableData);
    setMetrics(item.data);
    setFocusItem(item.id);
  };

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  useEffect(() => {
    if (!focusItem) {
      setTableData(chartData);
      setMetrics(metricTotals);
      return;
    }
    const tableData = chartData.filter(({ id, data }) => {
      if (id === focusItem) {
        setMetrics(data);
      }
      return id.startsWith(focusItem);
    });
    setTableData(tableData);
    setMetrics(focusItem.data);
  }, [JSON.stringify(chartData)]);

  return (
    <ChartCard
      title={title}
      ControlsComponent={
        hasLayers && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={dimensions[levelType]}
              label={dimensionLabel}
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              options={metricOptions}
              onChange={handleMetricTypeChange}
            />
          </Stack>
        )
      }
      InfoComponent={<LayerDistributionChartInformation />}
    >
      {hasLayers ? (
        <LayersDistributionChart
          chartData={chartData}
          fullscreen={fullscreen}
          onItemFocus={handleItemFocus}
          metric={metric}
          metrics={metrics}
          metricList={MetricList}
          metricTotals={metricTotals}
          sort={sort}
          tableData={tableData}
        />
      ) : (
        <EmptyInsights message="No layers were found." />
      )}
    </ChartCard>
  );
};

export default LayersDistribution;
