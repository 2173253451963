import { useEffect, useState } from 'react';
import { useDebounce } from '@pkg/hooks';

const useInput = ({
  debounce,
  initialValue,
  maxLength,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  resetsOnEmpty,
}) => {
  const [value, setValue] = useState(initialValue);
  const [previousValue, setPreviousValue] = useState(initialValue);

  // Debounce requires an empty function should an onChange event not be
  // provided.
  const debounceChange = useDebounce(onChange ?? (() => {}), debounce);

  const inputChange = (event, changeValue) => {
    let value = changeValue ?? event.target.value;
    const shouldTrim = !isNaN(maxLength) && value?.length > maxLength;

    if (shouldTrim) {
      value = value?.substring(0, maxLength);
    }

    setValue(value);

    if (debounce) {
      debounceChange(event, value);
    } else {
      onChange?.(event, value);
    }
  };

  const inputBlur = (event, changeValue) => {
    const updatedValue = changeValue ?? event.target.value;
    if (resetsOnEmpty && value === '') {
      setValue(previousValue ?? '');
    }

    setValue(updatedValue);
    onBlur?.(event, event.target.value);
  };

  const inputFocus = (event) => {
    event.target.select?.();
    onFocus?.(event, event.target.value);
    setPreviousValue(value);
  };

  const inputKeyDown = (event) => {
    if (event.key === 'Enter') {
      setValue(previousValue ?? '');
    }

    onKeyDown?.(event, value);
  };

  // If the initialValue has changed and it's not the same as the input value,
  // we expect this to be an upstream change and should update the input value
  // accordingly.
  useEffect(() => {
    if (JSON.stringify(initialValue) !== JSON.stringify(value)) {
      setValue(initialValue);
    }
  }, [JSON.stringify(initialValue)]);

  return {
    inputBlur,
    inputChange,
    inputFocus,
    inputKeyDown,
    value,
  };
};

export default useInput;
