import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import createInput from './createInput';

const UPDATE_PROPERTY = /* GraphQL */ `
  mutation UpdateLibraryProperty($input: UpdateLibraryPropertyInput!) {
    updateLibraryProperty(input: $input) {
      uuid
      name
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({
    mutation: UPDATE_PROPERTY,
  });

  /**
   * @param {Object} property
   * @param {Object} input
   * @param {String} input.name
   * @param {Array} input.options
   * @returns {Promise}
   */
  function updateProperty(property, { name, options }) {
    const updateData = {
      ...(name !== undefined && { name }),
      ...(options !== undefined && { options }),
    };

    database.properties.update(property.uuid, updateData); // @todo rollback

    const input = createInput(property, updateData);

    return mutateAsync(input);
  }

  return useCallback(updateProperty, [mutateAsync]);
}
