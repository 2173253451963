import { ChartType } from '@/shared/enums';
import { EntityMetric } from '@/lib/enums';

export default function determineChartType(metric) {
  switch (metric) {
    case EntityMetric.GROUPS:
    case EntityMetric.ACTIVITIES:
      return ChartType.DONUT;
    case EntityMetric.MOVED:
      return ChartType.MOVED;
    default:
      return ChartType.BAR;
  }
}
