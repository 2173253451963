import { Information, InformationParagraph } from '@/molecules/information';

const ManagerSpanBarChartInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        This chart presents Spans by Manager.
      </InformationParagraph>
      <InformationParagraph>
        Select from the 'Manager Span by' dropdown to present the data in the
        preferred format.
      </InformationParagraph>
      <InformationParagraph>
        Select the preferred 'Metric Type' from the dropdown to present the
        chart in Span, FTE or Budget (if permitted).
      </InformationParagraph>
      <InformationParagraph>
        Click on a segment in the chart to reveal further information about the
        Manager.
      </InformationParagraph>
    </Information>
  );
};

export default ManagerSpanBarChartInformation;
