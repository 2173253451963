import Link from 'next/link';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { usePerson } from '@/shared/hooks';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/styles';
import { ChipType } from '@/lib/enums';
import Box from '@/components/Box';
import Typography from '@/components/Typography';
import { Static } from '@/components/UserAvatar';

const Avatar = styled(Static)(() => ({
  boxShadow: '0 0 0 2px rgba(255,255,255,1)',
}));

const AVATAR_SIZE = Object.freeze({
  small: '20px',
  medium: '40px',
  large: '60px',
});

const DEFAULT_COLORS = Object.freeze({
  main: {
    font: '#4d4d68',
    border: '#c5d9ec',
    highlight: '#FFF',
  },
  inverse: {
    font: '#FFFFFF',
    border: 'transparent',
    highlight: '#FFFFFF20',
  },
});

const PersonRoleSummary = ({
  entity,
  colors,
  size = 'medium',
  inverse,
  isTeamLead,
}) => {
  const title = isTeamLead ? 'Team lead' : entity?.title ?? 'Team member';
  const { person, path } = usePerson(entity, isTeamLead);

  const linkColors = colors
    ? colors
    : DEFAULT_COLORS[inverse ? 'inverse' : 'main'];

  return (
    path && (
      <Link href={path}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1.5}
          sx={{
            color: linkColors.font,
            padding: 1,
            borderRadius: '4px',
            transition: 'background-color 300ms, box-shadow 300ms',
            '&: hover': {
              backgroundColor: linkColors.highlight,
              boxShadow: `inset 0 0 0 2px ${linkColors.border}`,
            },
          }}
        >
          <Box>
            <Avatar user={person} size={AVATAR_SIZE[size]} />
          </Box>
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.3rem',
              }}
            >
              {person
                ? `${person.first_name} ${person.last_name}`
                : 'Unassigned'}
            </Typography>
            {!isTeamLead && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 400,
                  fontSize: '0.85rem',
                  lineHeight: '1.25rem',
                }}
              >
                {title}
              </Typography>
            )}
            {isTeamLead && (
              <Box>
                <EntityChip type={ChipType.LEAD} size={Size.X_SMALL} />
              </Box>
            )}
          </Stack>
        </Stack>
      </Link>
    )
  );
};

export default PersonRoleSummary;
