import reduce from '@pkg/entities/snapshots/utils/getReduced';
import { DesignEntity, DesignLevel } from '@/lib/enums';

export function getManagedEntitiesForRole(managed, snapshot, roleId) {
  const reduced = reduce(snapshot, {
    type: DesignLevel.MANAGER,
    uuid: roleId,
  });

  reduced.entities.people.forEach((p) =>
    managed[DesignEntity.PERSON].add(p.uuid)
  );
  reduced.entities.groups.forEach((g) =>
    managed[DesignEntity.GROUP].add(g.uuid)
  );
  reduced.entities.roles
    .filter((r) => r.uuid !== roleId)
    .forEach((r) => managed[DesignEntity.ROLE].add(r.uuid));
  reduced.entities.activities
    .filter((a) => a.owner_uuid !== roleId)
    .forEach((a) => managed[DesignEntity.ACTIVITY].add(a.uuid));
}

export default function getManagedEntities(snapshot, relations, userId) {
  const managed = {
    [DesignEntity.GROUP]: new Set(),
    [DesignEntity.ROLE]: new Set(),
    [DesignEntity.PERSON]: new Set(),
    [DesignEntity.ACTIVITY]: new Set(),
  };

  if (!snapshot || !relations || !userId) {
    return managed;
  }

  const assignedRoles =
    relations[DesignEntity.PERSON].get(userId)?.[DesignEntity.ROLE] ?? [];

  const managerRoles = assignedRoles.filter((roleId) => {
    const role = relations[DesignEntity.ROLE].get(roleId);
    return role?.is_manager;
  });

  for (const roleId of managerRoles) {
    getManagedEntitiesForRole(managed, snapshot, roleId);
  }

  return managed;
}
