/**
 * Rules to determine if the navigation should be restricted.
 *
 * @param {String}
 *
 * @return {Boolean}
 */
export default function restrictNavigation(path) {
  return path.startsWith('/f/');
}
