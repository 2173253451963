import { useMemo } from 'react';
import { DimensionType } from '@/molecules/tableElements';

const useSkillColumnDefs = (dimension) => {
  return useMemo(() => {
    if (!dimension) {
      return;
    }

    return [
      {
        field: 'skill.id',
        filter: 'agMultiColumnFilter',
        hide: true,
      },
      {
        field: 'skill.name',
        filter: 'agMultiColumnFilter',
        width: 250,
      },
      {
        field: 'skill.level',
      },
      dimension === DimensionType.PEOPLE_SKILLS && {
        field: 'skill.utilisation',
        filter: 'agMultiColumnFilter',
        width: 250,
      },
      dimension === DimensionType.ROLE_SKILLS && {
        field: 'skill.gap',
        filter: 'agMultiColumnFilter',
        width: 250,
      },
    ].filter((column) => column);
  }, [dimension]);
};

export default useSkillColumnDefs;
