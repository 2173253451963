import { Collections, Sort } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.new_uuid]
 * @returns {Object}
 */
export default function duplicate(snapshot, { uuid, new_uuid }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      activities: {
        create: [],
        update: [],
      },
    },
  };

  const entities = snapshot.entities;
  const activity = Collections.findById(entities.activities, uuid);
  const ownerId = activity.owner_uuid;

  const duplicate = {
    uuid: new_uuid ?? shortuuid.generate(),
    library_uuid: activity.library_uuid,
    owner_uuid: activity.owner_uuid,
    owner_type: activity.owner_type,
    order: activity.order + 1,
    hours: activity.hours,
    source_uuid: activity.source_uuid,
    source_type: activity.source_type,
    properties: activity.properties,
    tags: activity.tags,
    created_at: now,
    updated_at: now,
  };

  const ordered = structuredClone(
    Collections.where(entities.activities, 'owner_uuid', activity.owner_uuid)
  ).sort(Sort.order());

  ordered.splice(duplicate.order, 0, duplicate);
  ordered.forEach((activity, order) => {
    if (activity.order !== order) {
      mutation.entities.activities.update.push({
        uuid: activity.uuid,
        order,
        updated_at: now,
      });

      activity.order = order;
      activity.updated_at = now;
    }
  });

  if (activity.owner_type === ActivityOwner.GROUP) {
    mutation.entities.groups = { update: [{ uuid: ownerId, updated_at: now }] };
  }

  if (activity.owner_type === ActivityOwner.ROLE) {
    mutation.entities.roles = { update: [{ uuid: ownerId, updated_at: now }] };
  }

  mutation.entities.activities.create.push(duplicate);
  return mutation;
}
