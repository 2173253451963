import Link from 'next/link';
import { useRouter } from 'next/router';
import { Divider } from '@/atoms/dividers';
import { Popover } from '@/molecules/modals';
import { HierarchyTree } from '@/organisms/hierarchy';
import { EntityType } from '@/shared/enums';
import { useCanSwitchViewModes } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import hasRoleFor from '@pkg/entities/people/access/hasRoleFor';
import { Routes } from '@pkg/utils';
import { OrganisationRole, Position } from '@/lib/enums';
import { brand, core } from '@/lib/theme/tokens/palettes';
import Logo from '@/components/Logo';
import PrimaryNavigationItems from './PrimaryNavigationItems';
import PrimaryNavigationSelector from './PrimaryNavigationSelector';
import ProfileNavigation from './ProfileNavigation';

const PrimaryNavigationBar = () => {
  const router = useRouter();
  const me = Auth.useStore((state) => state.me);
  const { organisation } = Auth.useStore();
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const isRoleOnly = Auth.useStore((state) => state.me?.is_role_only);
  const isAdmin = hasRoleFor(me, OrganisationRole.ADMIN, organisation);
  const isRestrictedOrg = organisation?.is_restricted;
  const canSwitchViewModes = useCanSwitchViewModes();

  const current = router.asPath;
  const parts = Routes.getDesignParts(current);

  const restrictPrimaryItems =
    !isAuthenticated || (isRoleOnly && isRestrictedOrg);
  const level = parts?.level ?? {
    type: EntityType?.ORGANISATION,
    uuid: organisation?.uuid,
  };

  return (
    organisation && (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        py={1}
        px={2}
        sx={{
          backgroundColor: brand._navy.main,
        }}
        height="45px"
        width="100%"
      >
        <Stack alignItems="center" direction="row" spacing={2}>
          <Link href="/dashboard">
            <Logo type="icon" width={24} />
          </Link>
          {canSwitchViewModes && (
            <Popover
              color="secondary"
              position={Position.BOTTOM_LEFT}
              Component={<PrimaryNavigationSelector />}
            >
              <HierarchyTree activeItem={parts ? level : null} />
            </Popover>
          )}
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{
            '& a': {
              color: core.white,
              display: 'flex',
            },
          }}
        >
          {!restrictPrimaryItems && (
            <>
              <PrimaryNavigationItems isAdmin={isAdmin} />
              <Divider
                colorProps={{
                  borderColor: `${brand.lightBlue.shades[4]}44`,
                }}
                orientation="vertical"
              />
            </>
          )}
          <ProfileNavigation />
        </Stack>
      </Stack>
    )
  );
};

export default PrimaryNavigationBar;
