import { TableDimension, TablePresets } from '@/molecules/tableElements';
import { ScopeType } from '@/shared/enums';
import Stack from '@mui/material/Stack';

const TableControls = ({ gridRef, dimension, onDimensionChange, scope }) => {
  const showSelector = scope === ScopeType.TAGS || scope === ScopeType.SKILLS;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      height="70px"
      spacing={2}
    >
      {showSelector && (
        <TableDimension scope={scope} onDimensionChange={onDimensionChange} />
      )}
      <TablePresets gridRef={gridRef} dimension={dimension} />
    </Stack>
  );
};

export default TableControls;
