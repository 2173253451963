import { Sort } from '@pkg/utils';
import config from '@/lib/config';
import { AssociativeProperties, DesignAction, Property } from '@/lib/enums';
import map, { mapActions } from './map';
import trim from './trim';
import unmap, { unmapActions } from './unmap';

/**
 * Combine multiple mutations into a single one
 * @param {Object[]} mutations
 * @return {Object}
 */
export default function combine(mutations) {
  if (!mutations.length) {
    return null;
  }

  if (mutations.length === 1) {
    return Object.freeze(mutations[0]);
  }

  const sorted = mutations.sort(Sort.date('created_at')).reverse();
  const combined = structuredClone(map(config.DEFAULT_MUTATION));
  combined.created_at = sorted[0].created_at;

  sorted.forEach((mutation) => {
    const isNewer = mutation.created_at > combined.created_at;

    if (isNewer) {
      combined.created_at = mutation.created_at;
    }

    if (isNewer && mutation.name) {
      combined.name = mutation.name;
    }

    if (isNewer && mutation.objective) {
      combined.objective = mutation.objective;
    }

    if (mutation.entities) {
      for (const plural in mutation.entities) {
        for (const action in mutation.entities[plural]) {
          const payload = mutation.entities[plural][action];

          // combine unique calculation arrays
          if (action === 'calculate') {
            const existing = new Set(combined.entities[plural].calculate);
            const updated = new Set([...existing, ...payload]);
            combined.entities[plural].calculate = Array.from(updated);
          }

          // handle creates
          if (action === 'create') {
            payload.forEach((value) => {
              // check for existing removes and skip it
              if (combined.entities[plural].remove.has(value.uuid)) {
                return;
              }

              // override existing creates, or create newer
              const existing = combined.entities[plural].create.get(value.uuid);
              if (!existing || existing.created_at < value.created_at) {
                combined.entities[plural].create.set(value.uuid, value);
              }

              // remove any existing updates
              combined.entities[plural].update.delete(value.uuid);
            });
          }

          // handle updates
          if (action === 'update') {
            payload.forEach((value) => {
              // check for existing removes and skip it
              if (combined.entities[plural].remove.has(value.uuid)) {
                return;
              }

              // check for creates to merge into
              const create = combined.entities[plural].create.get(value.uuid);
              if (create && create.created_at < value.updated_at) {
                const merged = structuredClone(create);
                for (const property in value) {
                  if (property !== 'updated_at') {
                    merged[property] = value[property];
                  }
                }

                combined.entities[plural].create.set(value.uuid, merged);
                return;
              }

              // check for updates to merge into
              const update = combined.entities[plural].update.get(value.uuid);
              if (update && update.updated_at < value.updated_at) {
                const merged = structuredClone(update);
                for (const property in value) {
                  const isConflicting = Object.hasOwn(merged, property);
                  const isAssociative = AssociativeProperties.has(property);
                  const latest = value[property];

                  /** @todo align the keys */
                  const key = property === Property.PROPERTIES ? 'key' : 'uuid';

                  merged[property] =
                    isAssociative && isConflicting
                      ? combineAssociativeUpdate(merged[property], latest, key)
                      : latest;
                }

                combined.entities[plural].update.set(value.uuid, merged);
                return;
              }

              // add new update
              combined.entities[plural].update.set(value.uuid, value);
            });
          }

          // handle removes
          if (action === 'remove') {
            payload.forEach((id) => {
              if (!combined.entities[plural].create.has(id)) {
                combined.entities[plural].remove.add(id);
              }
              combined.entities[plural].create.delete(id);
              combined.entities[plural].update.delete(id);
            });
          }
        }
      }
    }
  });

  return trim(unmap(combined));
}

/**
 * Combine associative property updates
 * @param {Object} combined
 * @param {Object} latest
 * @param {String} key
 * @return {Object}
 */
function combineAssociativeUpdate(combined, latest, key) {
  const combinedMapped = mapActions(combined, key);

  Object.keys(latest).forEach((lower) => {
    const action = DesignAction.from(lower);

    // creates
    if (action === DesignAction.CREATE) {
      latest[lower].forEach((value) => {
        // add to and override existing creates
        combinedMapped.create.set(value[key], value);

        // convert removes into updates
        if (combinedMapped.remove.has(value[key])) {
          combinedMapped.update.set(value[key], value);
          combinedMapped.remove.delete(value[key]);
        }

        // skip updates
        if (combinedMapped.update.has(value[key])) {
          return;
        }
      });
    }

    // updates
    if (action === DesignAction.UPDATE) {
      latest[lower].forEach((value) => {
        // merge with existing creates
        if (combinedMapped.create.has(value[key])) {
          const existing = combinedMapped.create.get(value[key]);
          const merged = { ...existing, ...value };
          combinedMapped.create.set(value[key], merged);
        }

        // merge with existing updates
        if (combinedMapped.update.has(value[key])) {
          const existing = combinedMapped.update.get(value[key]);
          const merged = { ...existing, ...value };
          combinedMapped.update.set(value[key], merged);
        }

        // cancel removes
        if (combinedMapped.remove.has(value[key])) {
          combinedMapped.update.set(value[key], value);
          combinedMapped.remove.delete(value[key]);
        }
      });
    }

    // removes
    if (action === DesignAction.REMOVE) {
      latest[lower].forEach((value) => {
        // cancel any creates
        if (combinedMapped.create.has(value)) {
          return combinedMapped.create.delete(value);
        }

        // remove any updates
        if (combinedMapped.update.has(value)) {
          combinedMapped.update.delete(value);
        }

        combinedMapped.remove.add(value);
      });
    }
  });

  return unmapActions(combinedMapped);
}
