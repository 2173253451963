import { Size } from '@/atoms/enums';

const CheckboxSizeProps = Object.freeze({
  [Size.SMALL]: {
    height: '0.75rem',
    width: '0.75rem',
  },
  [Size.MEDIUM]: {
    height: '1.25rem',
    width: '1.25rem',
  },
  [Size.LARGE]: {
    height: '1.5rem',
    width: '1.5rem',
  },
  [Size.X_LARGE]: {
    height: '2.4rem',
    width: '2.4rem',
  },
});

export default CheckboxSizeProps;
