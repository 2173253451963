/**
 * Returns the x position of the circle based on it's column index.
 *
 * @param {Object}
 *
 * @return {Number}
 */
export default function circleXPosition({
  circleSquare,
  column,
  maxColumns,
  offset,
  rowCount,
  row,
  spacing,
  xPosition,
  width,
  margin,
}) {
  // Center align the circle if we only have a single column.
  if (maxColumns === 1) {
    return xPosition + width / 2;
  }

  return (
    xPosition + width - (column * circleSquare - circleSquare / 2) - offset / 2
  );
}
