import calculate from '@pkg/insights/calculations/productivity';
import derived from './derived';
import perform from './perform';

/**
 * @param {Object} params.snapshot
 * @param {Object} params.lens
 * @param {Map} params.tags
 * @returns
 */
export default function productivity(params) {
  const derivedFn = derived(calculate, params.lens.type);
  return perform(derivedFn)(params);
}
