import Button from '@mui/material/Button';
import { brand } from '@/lib/theme/tokens/palettes';

const AddButton = ({ fullWidth = true, label, onClick }) => {
  return (
    <Button
      disableElevation
      fullWidth={fullWidth}
      onClick={onClick}
      sx={{
        backgroundColor: 'transparent',
        border: `2px dotted ${brand.purple.tints[2]}`,
        py: 1,
      }}
    >
      {label}
    </Button>
  );
};

export default AddButton;
