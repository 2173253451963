import { useMemo } from 'react';
import * as Library from '../library';
import deriveProps from './deriveProps';

/**
 * @param {Object} design
 * @param {Object} options
 * @return {Object}
 */
export default function useDerivedProps(design, options = {}) {
  const library = Library.useLibrary();
  return useMemo(() => {
    return deriveProps(library, design, options);
  }, [library, design, options]);
}
