export const brand = Object.freeze({
  blue: {
    main: '#3698F1',
    shades: [
      '#248FF0',
      '#1185EE',
      '#0F7BDD',
      '#0D67B8',
      '#0A5293',
      '#083E6F',
      '#05294A',
      '#031525',
    ],
    tints: [
      '#EDF6FE',
      '#DBEDFC',
      '#B6DAFA',
      '#A4D0F9',
      '#92C7F7',
      '#7FBEF6',
      '#5BABF4',
      '#48A1F2',
    ],
  },
  coral: {
    main: '#FF6F61',
    shades: [
      '#FF5D4D',
      '#FF4B3A',
      '#FF3926',
      '#EA1500',
      '#AF1000',
      '#C31100',
      '#750A00',
      '#3A0500',
    ],
    tints: [
      '#FFFEFE',
      '#FFECEA',
      '#FFDAD7',
      '#FFC8C3',
      '#FFB7AF',
      '#FFA59C',
      '#FF9388',
      '#FF8175',
    ],
  },
  deepPurple: {
    main: '#5b5b89',
    shades: [
      '#53537d',
      '#4b4b71',
      '#444466',
      '#3c3c5a',
      '#34344e',
      '#2c2c42',
      '#242436',
      '#1c1c2b',
    ],
    tints: [
      '#efeff4',
      '#d7d7e5',
      '#c0c0d5',
      '#a8a8c5',
      '#9090b6',
      '#7979a6',
      '#6d6d9e',
      '#636395',
    ],
  },
  green: {
    main: '#00C794',
    shades: [
      '#00B385',
      '#00A077',
      '#008C68',
      '#00795A',
      '#00654B',
      '#00513C',
      '#003E2E',
      '#002A1F',
    ],
    tints: [
      '#EEFFFB',
      '#DBFFF6',
      '#C7FFF1',
      '#B3FFEC',
      '#8CFFE2',
      '#65FFD8',
      '#3EFFCD',
      '#16FFC3',
    ],
  },
  grey: {
    main: '#6a7d98',
    shades: [
      '#62738d',
      '#526075',
      '#4a576a',
      '#3a4453',
      '#323a47',
      '#2a313c',
      '#212730',
      '#191e25',
    ],
    tints: [
      '#f5f6f8',
      '#eaecf0',
      '#d3d8e0',
      '#c7ced8',
      '#b0bac8',
      '#a4b0c0',
      '#8d9bb0',
      '#8291a8',
    ],
  },
  lightBlue: {
    main: '#DEEAFF',
    shades: [
      '#cadeff',
      '#a3c5ff',
      '#90b8ff',
      '#7cacff',
      '#689fff',
      '#4186ff',
      '#1a6dff',
      '#0661ff',
    ],
    tints: [
      '#f8fbff',
      '#f5f9ff',
      '#f2f7ff',
      '#eef5ff',
      '#ebf2ff',
      '#e8f0ff',
      '#e5eeff',
      '#e1ecff',
    ],
  },
  orange: {
    main: '#ff7b00',
    shades: [
      '#eb7200',
      '#d86800',
      '#c45f00',
      '#b15500',
      '#9d4c00',
      '#763900',
      '#4e2600',
      '#271300',
    ],
    tints: [
      '#fff5eb',
      '#ffe1c4',
      '#ffd6b1',
      '#ffcc9d',
      '#ffb876',
      '#ffa44e',
      '#ff8f27',
      '#ff8514',
    ],
  },
  purple: {
    main: '#7D5FFF',
    shades: [
      '#3838A8',
      '#333399',
      '#2E2E8A',
      '#29297B',
      '#24246D',
      '#1F1F5E',
      '#161641',
      '#0C0C23',
    ],
    tints: [
      '#F6F6FC',
      '#E7E7F7',
      '#CACAED',
      '#ACACE3',
      '#8F8FDA',
      '#7272D0',
      '#5454C6',
      '#4545C1',
    ],
  },
  teal: {
    main: '#00B4C5',
    shades: [
      '#00A2B1',
      '#00909E',
      '#007E8A',
      '#006C77',
      '#005A63',
      '#00484F',
      '#002528',
      '#001314',
    ],
    tints: [
      '#D9FCFF',
      '#C5FAFF',
      '#9EF7FF',
      '#77F3FF',
      '#4FF0FF',
      '#14EBFF',
      '#00D8EC',
      '#00C6D9',
    ],
  },
  _navy: {
    main: '#0D2E4D',
    shades: [
      '#2174C2',
      '#1B60A1',
      '#164C7F',
      '#10385E',
      '#13426F',
      '#0D2E4D',
      '#0A243C',
      '#02060A',
    ],
    tints: [
      '#E7F1FB',
      '#D7E8F8',
      '#C6DEF5',
      '#A4CBF0',
      '#83B8EA',
      '#72AEE7',
      '#61A5E4',
      '#3F92DF',
    ],
  },
  navy: {
    colors: {
      50: '#E6EBEF',
      100: '#CBD3DB',
      200: '#B0BCC7',
      300: '#95A4B2',
      400: '#7A8D9E',
      500: '#5E758A',
      600: '#435D76',
      700: '#284661',
      800: '#0D2E4D',
      900: '#092035',
    },
    contrast: {
      50: 'dark',
      100: 'dark',
      200: 'dark',
      300: 'dark',
      400: 'dark',
      500: 'light',
      600: 'light',
      700: 'light',
      800: 'light',
      900: 'light',
    },
  },
  yellow: {
    main: '#facd02',
    shades: [
      '#e7bd02',
      '#d3ad02',
      '#c09d02',
      '#ac8d01',
      '#997d01',
      '#856d01',
      '#5e4d01',
      '#372d00',
    ],
    tints: [
      '#fff7d5',
      '#fef0ae',
      '#fee887',
      '#fee574',
      '#fee160',
      '#fede4d',
      '#fdda39',
      '#fdd626',
    ],
  },
});

export const core = Object.freeze({
  black: '#000000',
  white: '#FFFFFF',
});

export const system = Object.freeze({
  destructive: {
    50: '#FFF6F7',
    75: '#FFDBDF',
    300: '#BD090C',
    400: '#8D0609',
  },
  interactive: {
    300: '#195994',
    400: '#123F69',
  },
  success: {
    50: '#EFFAF1',
    75: '#CAEDD0',
    300: '#008024',
    400: '#004D16',
  },
});

export const status = Object.freeze({
  colors: {
    50: '#BD090C',
    100: '#DD3C13',
    200: '#FC6E1A',
    300: '#FC9313',
    400: '#FEA910',
    500: '#FBC111',
    600: '#F8D911',
    700: '#BCD31E',
    800: '#5EAA21',
    900: '#008024',
  },
  contrast: {
    50: 'light',
    100: 'light',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'dark',
    700: 'light',
    800: 'light',
    900: 'light',
  },
});

export const blue = Object.freeze({
  colors: {
    50: '#D1F2FB',
    100: '#A3EDFC',
    200: '#75E7FD',
    300: '#47E2FE',
    400: '#18DCFF',
    500: '#17C0EB',
    600: '#15AFD6',
    700: '#139FC3',
    800: '#1191B1',
    900: '#0F84A1',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'light',
    700: 'light',
    800: 'light',
    900: 'light',
  },
});

export const cyan = Object.freeze({
  colors: {
    50: '#E7F9F8',
    100: '#D6FAF7',
    200: '#C5FAF6',
    300: '#B4FAF5',
    400: '#A2FAF3',
    500: '#7EFFF5',
    600: '#73F3E9',
    700: '#67E6DC',
    800: '#5ED1C8',
    900: '#55BEB6',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'dark',
    700: 'dark',
    800: 'dark',
    900: 'dark',
  },
});

export const green = Object.freeze({
  colors: {
    50: '#D8F9E3',
    100: '#AFFBCA',
    200: '#85FCB1',
    300: '#5CFE98',
    400: '#32FF7E',
    500: '#36F179',
    600: '#3AE374',
    700: '#35CE69',
    800: '#30BB5F',
    900: '#2CAA56',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'dark',
    700: 'dark',
    800: 'dark',
    900: 'dark',
  },
});

export const grey = Object.freeze({
  colors: {
    50: '#FAFAFA',
    100: '#E0E1E2',
    200: '#C6C8CA',
    300: '#ACAFB2',
    400: '#929699',
    500: '#787D81',
    600: '#5E6469',
    700: '#444B51',
    800: '#3E444A',
    900: '#383E43',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'light',
    500: 'light',
    600: 'light',
    700: 'light',
    800: 'light',
    900: 'light',
  },
});

export const lilac = Object.freeze({
  colors: {
    50: '#F3E2FC',
    100: '#EED4FB',
    200: '#E8C5F9',
    300: '#E2B6F8',
    400: '#DCA7F6',
    500: '#D79BF4',
    600: '#CD84F1',
    700: '#C56CF0',
    800: '#A359C6',
    900: '#9451B4',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'light',
    700: 'light',
    800: 'light',
    900: 'light',
  },
});

export const orange = Object.freeze({
  colors: {
    50: '#FFECD1',
    100: '#FFE3BB',
    200: '#FFD9A4',
    300: '#FFCF8D',
    400: '#FFC576',
    500: '#FFAF40',
    600: '#FF9F1A',
    700: '#E89118',
    800: '#D38416',
    900: '#C07814',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'dark',
    700: 'dark',
    800: 'dark',
    900: 'dark',
  },
});

export const pink = Object.freeze({
  colors: {
    50: '#FFF1F1',
    100: '#FFE3E3',
    200: '#FFDCDC',
    300: '#FFD4D4',
    400: '#FFCCCC',
    500: '#FFC2C2',
    600: '#FFB8B8',
    700: '#E8A7A7',
    800: '#D39898',
    900: '#C08A8A',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'dark',
    700: 'dark',
    800: 'dark',
    900: 'dark',
  },
});

export const purple = Object.freeze({
  colors: {
    50: '#E3DEF9',
    100: '#C7BDF4',
    200: '#AA9BEE',
    300: '#9F8CF3',
    400: '#947DF7',
    500: '#7D5FFF',
    600: '#7158E2',
    700: '#6750CD',
    800: '#5E49BA',
    900: '#5542A9',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'light',
    600: 'light',
    700: 'light',
    800: 'light',
    900: 'light',
  },
});

export const red = Object.freeze({
  colors: {
    50: '#FFD7D7',
    100: '#FFC4C4',
    200: '#FFB0B0',
    300: '#FF8888',
    400: '#FF6B6B',
    500: '#FF4D4D',
    600: '#FF3838',
    700: '#E83333',
    800: '#D32E2E',
    900: '#C02A2A',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'light',
    700: 'light',
    800: 'light',
    900: 'light',
  },
});

export const yellow = Object.freeze({
  colors: {
    50: '#FFFCCC',
    100: '#FFFBB3',
    200: '#FFFA99',
    300: '#FFF980',
    400: '#FFF766',
    500: '#FFF633',
    600: '#FFF200',
    700: '#F4E700',
    800: '#E1D613',
    900: '#CDC200',
  },
  contrast: {
    50: 'dark',
    100: 'dark',
    200: 'dark',
    300: 'dark',
    400: 'dark',
    500: 'dark',
    600: 'dark',
    700: 'dark',
    800: 'dark',
    900: 'dark',
  },
});
