import Dexie from 'dexie';
import { useLiveQuery } from 'dexie-react-hooks';
import { useMemo } from 'react';
import env from '@beam-australia/react-env';
import useDatabase from './useDatabase';

const DATABASE = 'beamible';
const VERSION = 9;

class Database extends Dexie {
  constructor() {
    super(DATABASE);
    this.version(VERSION).stores({
      hash: '&key, updated_at',
      auth: '&key',
      build: '&key, id',

      // designs
      designs: '&uuid',
      designEvents: '&uuid, design.uuid',
      designRevisions: '&uuid, design.uuid',
      entities: '&uuid, type',

      // library
      activities: '&uuid',
      flowTemplates: '&uuid',
      properties: '&uuid',
      skills: '&uuid',
      tags: '&uuid, is_disabled',
      tagCategories: '&uuid',
    });
  }

  /**
   * @returns {Promise}
   */
  reset() {
    return Promise.all(this.tables.map((table) => table.clear()));
  }

  /**
   * @param {String} table
   * @param {String|String[]} [keys]
   * @param {Object} [options]
   * @param {any} [options.initialValue]
   * @param {Array} [options.dependencies]
   * @returns {Object|Object[]}
   */
  static useLive(table, keys, options = {}) {
    const database = useDatabase();

    const isGet = Boolean(keys);
    const isBulk = isGet && Array.isArray(keys);

    const getter = isGet ? (isBulk ? 'bulkGet' : 'get') : 'toArray';
    const querier = useMemo(() => {
      return () => database[table][getter](keys);
    }, [table, getter, JSON.stringify(keys)]);

    const initialValue = options.initialValue ?? isBulk ? [] : undefined;
    const dependencies = options.dependencies ?? [];
    dependencies.push(querier);

    return useLiveQuery(querier, dependencies, initialValue);
  }

  /**
   * @param {String[]} tables
   * @returns {Array[]}
   */
  static useLiveTables(tables) {
    const database = useDatabase();

    const [querier, initial] = useMemo(() => {
      const fn = async () =>
        await Promise.all(tables.map((table) => database[table].toArray()));

      return [fn, new Array(tables.length).fill([])];
    }, [JSON.stringify(tables)]);

    const dependencies = [querier, initial];
    return useLiveQuery(querier, dependencies, initial);
  }

  /**
   * @param {String} key
   * @returns {Boolean}
   */
  static useStaleHash(key) {
    const database = useDatabase();
    const hash = useLiveQuery(() => database.hash.get(key), [], null);

    return useMemo(() => {
      const minAge = Date.now() - 30 * 60 * 1000; // 30 minutes
      const isLoaded = hash !== null;
      return isLoaded && (!hash || hash.updated_at <= minAge);
    }, [hash]);
  }

  /**
   * @returns {Boolean}
   */
  static useStaleBuild() {
    const database = useDatabase();
    const isWindow = typeof window !== 'undefined';
    const codeBuildId = env('PLATFORM_VER')
      ? env('PLATFORM_VER')
      : 'development';
    const build = useLiveQuery(() => database.build.get('build'), [], null);

    return useMemo(() => {
      return build && build.id !== codeBuildId;
    }, [build?.id, isWindow]);
  }
}

export default Database;
