import { memo } from 'react';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';

const HeaderButton = styled(IconButton, {
  shouldForwardProp: excludeProps(['color']),
})(({ color }) => ({
  color: color.main,
  background: '#FFF',
  '&:hover': {
    backgroundColor: color.tints[3],
  },
}));

const HeaderButtonComponent = ({
  size = 'small',
  variant = 'secondary',
  ...props
}) => <HeaderButton size={size} variant={variant} {...props} />;

export default memo(HeaderButtonComponent);
