import { useEffect } from 'react';
import { useIdle as useBrowserIdle } from 'react-use';

const CLIENT_EVENT = 'idle';

const TIMERS = {
  IDLE: 15000,
  AWAY: 30000,
};

/**
 * @param {PresenceChannel} channel
 *
 * @param {Object} presence
 * @param {Object} presence.me
 * @param {Object} presence.users
 * @param {Function} presence.update
 *
 * @returns {undefined}
 */
export default function useIdle(channel, presence) {
  const isIdle = useBrowserIdle(TIMERS.IDLE);
  const isAway = useBrowserIdle(TIMERS.AWAY);
  const isEnabled = presence.users.length > 0 && Boolean(presence.me);

  function updateUsersStatus(user) {
    if (user.uuid.toString() !== presence.me.uuid.toString()) {
      presence.update(user);
    }
  }

  function broadcastMyStatus(data) {
    channel?.whisper(CLIENT_EVENT, data);
  }

  function listenForUsers() {
    channel?.listenForWhisper(CLIENT_EVENT, updateUsersStatus);
  }

  useEffect(() => {
    if (isEnabled) {
      listenForUsers();
    }
  }, [isEnabled]);

  useEffect(() => {
    if (isEnabled) {
      broadcastMyStatus({ uuid: presence.me?.uuid, isIdle, isAway });
    }
  }, [isIdle, isAway, isEnabled]);
}
