const EntityType = Object.freeze({
  ACTIVITY: 'ACTIVITY',
  DESIGN: 'DESIGN',
  GROUP: 'GROUP',
  LAYER: 'LAYER',
  LEAD: 'LEAD',
  LIBRARY_ACTIVITY: 'LIBRARY_ACTIVITY',
  LIVE: 'LIVE',
  MANAGER: 'MANAGER',
  MATRIX: 'MATRIX',
  ORGANISATION: 'ORGANISATION',
  ROLE: 'ROLE',
  SKILL: 'SKILL',
  SPAN: 'SPAN',
  TAG: 'TAG',
  PERSON: 'PERSON',
  PROPERTY: 'PROPERTY',
  PROPERTY_VALUE: 'PROPERTY_VALUE',
  SUPPORTER: 'SUPPORTER',
});

export default EntityType;
