import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import Stack from '@mui/material/Stack';

const ActivityMenuOption = ({ id }) => {
  return (
    <Stack direction="row" alignItems="center">
      <EntityChip plural size={Size.SMALL} type={id} />
    </Stack>
  );
};

export default ActivityMenuOption;
