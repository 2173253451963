/**
 * @param {Number} current
 * @param {Number} value
 * @return {Number}
 */
export default function add(current, value) {
  if (!Number.isFinite(value)) {
    return current;
  }

  if (!Number.isFinite(current)) {
    return value;
  }

  return current + value;
}
