/**
 * @param {Object} entity
 * @returns {String}
 */
export default function ownerProperty(entity) {
  switch (true) {
    case Object.hasOwn(entity, 'owner_uuid'):
      return 'owner_uuid';

    case Object.hasOwn(entity, 'group_uuid'):
      return 'group_uuid';

    default:
      return null;
  }
}
