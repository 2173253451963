import VerticalSegment from './VerticalSegment';
import { buildStackSegments } from './utils';

const VerticalStackBar = ({
  barWidth,
  chartHeight,
  fullStackChart,
  maxBarValue,
  metric,
  metricTotals,
  minBarSize = 8,
  onClick,
  padding = 8,
  row,
  xPosition,
  yScale,
}) => {
  const segments = buildStackSegments({
    chartHeight,
    fullStackChart,
    maxBarValue,
    metric,
    metricTotals,
    row,
    yScale,
  });

  return (
    <g
      className="horizontal-bar"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {segments.map((segment) => (
        <VerticalSegment
          key={segment.id}
          color={segment.color}
          metric={metric}
          metricTotals={metricTotals}
          row={segment}
          height={segment.height}
          width={barWidth - padding}
          xPosition={xPosition}
          yPosition={segment.yPosition}
        />
      ))}
    </g>
  );
};

export default VerticalStackBar;
