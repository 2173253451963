const ActionType = Object.freeze({
  // Activity
  ADDED_ACTIVITY: 'Added activity',
  REMOVED_ACTIVITY: 'Removed activity',
  // Activities
  ADD_ACTIVITIES: 'Added activities',
  REMOVE_ACTIVITIES: 'Removed activities',
  MOVE_ACTIVITIES: 'Moved activities',
  UPDATED_HOURS: 'Updated hours',
  // Role
  ADDED_ROLE: 'New role',
  UPDATED_MANAGER: 'Updated manager',
  REMOVED_ROLE: 'Removed role',
  UPDATED_FTE: 'Updated fte',
  UPDATED_BUDGET: 'Updated budget',
  // Roles
  ADD_ROLES: 'Added roles',
  REMOVE_ROLES: 'Removed roles',
  MOVE_ROLES: 'Moved roles',
  // Teams
  ADD_GROUPS: 'Added teams',
  REMOVE_GROUPS: 'Removed teams',
  MOVE_GROUPS: 'Moved teams',
  // UPDATES
  UPDATE_BUDGETS: 'Updated budgets',
  UPDATE_FTE: 'Updated FTE',
  UPDATE_HOURS: 'Updated Hours',
});

export default ActionType;
