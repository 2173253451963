import { StackChartBar } from '@/molecules/chartElements';
import Box from '@/components/Box';
import RelatedActivityEntities from '@/components/DashboardContainer/organisms/RelatedActivityEntities';

const TagActivityList = ({ color, entities, interactive, totalHours }) => {
  return (
    <Box>
      {entities.map((entity, index) => (
        <Box
          key={entity.uuid}
          sx={{
            mt: index === 0 ? 0 : 1.5,
          }}
        >
          <RelatedActivityEntities entity={entity} interactive={interactive}>
            <StackChartBar
              colors={color}
              interactive={interactive}
              title={entity.__description}
              numerator={entity.__aggregateHours}
              denominator={totalHours}
            />
          </RelatedActivityEntities>
        </Box>
      ))}
    </Box>
  );
};

export default TagActivityList;
