import { createContext, useContext, useMemo } from 'react';
import { useFlow } from '@/shared/hooks/flows';

export const FlowContext = createContext();
export const useFlowContext = () => useContext(FlowContext);

const FlowProvider = ({ uuid, children }) => {
  const { flow, isLoading } = useFlow({
    uuid,
  });

  const context = useMemo(() => {
    return {
      flow,
      isLoading,
    };
  }, [flow, isLoading]);

  return (
    <FlowContext.Provider value={context}>{children}</FlowContext.Provider>
  );
};

export default FlowProvider;
