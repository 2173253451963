import { cloneElement } from 'react';
import { Size } from '@/atoms/enums';
import { Colors } from '@/atoms/icons';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/styles';
import excludeProps from '@/lib/theme/excludeProps';
import CircleBackgroundIconSize from './utils/CircleBackgroundIconSize';

const StyledBox = styled(Stack, {
  shouldForwardProp: excludeProps(['backgroundColor', 'color', 'size']),
})(({ backgroundColor, color, size }) => ({
  ...CircleBackgroundIconSize[size].background,
  backgroundColor,
  color,
}));

const CircleBackgroundIcon = ({
  color = 'primary',
  colorProps,
  icon,
  size = Size.MEDIUM,
}) => {
  const colors = colorProps ? colorProps : Colors.circleBackgroundIcon[color];

  return (
    <StyledBox
      alignItems="center"
      justifyContent="center"
      backgroundColor={colors.backgroundColor}
      color={colors.color}
      size={size}
      sx={{ boxShadow: `inset 0 0 0 1px ${colors.borderColor}` }}
    >
      {cloneElement(icon, {
        sx: {
          ...CircleBackgroundIconSize[size].icon,
          verticalAlign: 'middle',
        },
      })}
    </StyledBox>
  );
};

export default CircleBackgroundIcon;
