const PivotTableIcon = (props) => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 25 15" fill="none">
    <path
      d="M3.43419 7.70256C3.43419 7.41279 3.6691 7.17787 3.95888 7.17787H20.7635C21.0533 7.17787 21.2882 7.41279 21.2882 7.70256C21.2882 7.99234 21.0533 8.22725 20.7635 8.22725H3.95888C3.6691 8.22725 3.43419 7.99234 3.43419 7.70256Z"
      fill="currentColor"
    />
    <path
      d="M3.95888 10.8507C3.6691 10.8507 3.43419 11.0856 3.43419 11.3754C3.43419 11.6652 3.6691 11.9001 3.95888 11.9001H20.7635C21.0533 11.9001 21.2882 11.6652 21.2882 11.3754C21.2882 11.0856 21.0533 10.8507 20.7635 10.8507H3.95888Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.29763 0.5C1.4692 0.5 0.79763 1.17157 0.79763 2V14.0926C0.79763 14.921 1.4692 15.5926 2.29763 15.5926H22.6396C23.468 15.5926 24.1396 14.921 24.1396 14.0926V2C24.1396 1.17157 23.468 0.5 22.6396 0.5H2.29763ZM1.79763 2C1.79763 1.72386 2.02149 1.5 2.29763 1.5H8.17325L8.17808 3.55444L1.79763 3.55444V2ZM1.79763 4.55444V14.0926C1.79763 14.3687 2.02149 14.5926 2.29763 14.5926H22.6396C22.9157 14.5926 23.1396 14.3687 23.1396 14.0926V4.55444L1.79763 4.55444ZM23.1396 3.55444V2C23.1396 1.72386 22.9157 1.5 22.6396 1.5H16.7339V3.55444L23.1396 3.55444ZM15.7339 3.55444V1.5H9.17326L9.17808 3.55444L15.7339 3.55444Z"
      fill="currentColor"
    />
  </svg>
);

export default PivotTableIcon;
