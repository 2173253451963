import { useEffect } from 'react';
import { useAccess as useDesignAccess } from '@pkg/access/designs';
import * as Auth from '@pkg/auth';
import useDesignStore from './useDesignStore';

export default function useAccess() {
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const design = useDesignStore((state) => state.design);
  const isError = useDesignStore((state) => state.isError);
  const isHistorical = useDesignStore((state) => state.isHistorical);
  const snapshot = useDesignStore((state) => state.revision?.snapshot);
  const set = useDesignStore((state) => state.set);
  const access = useDesignAccess(design, snapshot, isHistorical);

  useEffect(() => {
    set({ access });
  }, [access]);

  useEffect(() => {
    const isPublic = !isAuthenticated;
    const isReadOnly = isPublic || isError || isHistorical;
    set({ isPublic, isReadOnly });
  }, [isAuthenticated, isError, isHistorical]);
}
