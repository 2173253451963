import { Chip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { ViewMode } from '@/shared/enums';
import DesignIcon from '@pkg/icons/Design';
import InsightsIcon from '@pkg/icons/Insights';
import PivotTableIcon from '@pkg/icons/PivotTable';
import PlanIcon from '@pkg/icons/Plan';

const ViewModeMap = new Map([
  [
    ViewMode.INSIGHTS,
    {
      id: ViewMode.INSIGHTS,
      label: ViewMode.INSIGHTS,
      icon: <InsightsIcon width={34} height={22} />,
    },
  ],
  [
    ViewMode.DIAGRAM,
    {
      id: ViewMode.DIAGRAM,
      label: ViewMode.DIAGRAM,
      icon: <DesignIcon width={35} height={28} />,
    },
  ],
  [
    ViewMode.TABLE,
    {
      id: ViewMode.TABLE,
      label: 'Table',
      icon: <PivotTableIcon width={34} height={22} />,
      beta: <Chip color="primary" size={Size.X_SMALL} name="Beta" />,
    },
  ],
  [
    ViewMode.PLAN,
    {
      id: ViewMode.PLAN,
      label: 'Plan',
      icon: <PlanIcon width={34} height={26} />,
      beta: <Chip color="primary" size={Size.X_SMALL} name="Beta" />,
    },
  ],
]);

export default ViewModeMap;
