import { useEffect, useState } from 'react';
import { Select } from '@/atoms/inputs';
import { ScopeType } from '@/shared/enums';
import Box from '@mui/material/Box';
import dimensionOptions from './dimensionOptions';

const TableDimension = ({ scope, onDimensionChange }) => {
  const [options, setOptions] = useState();

  // This effect resets the dimension when switching between scopes.
  useEffect(() => {
    if (!scope) {
      return;
    }

    setOptions(dimensionOptions(scope));
  }, [scope]);

  const handleChange = (option) => {
    onDimensionChange?.(option.id);
  };

  const label = scope === ScopeType.TAGS ? 'Tag options' : 'Skill options';

  return (
    options?.length && (
      <Box>
        <Select
          label={label}
          initialValue={options[0]}
          minWidth={200}
          options={options}
          optionLabel="label"
          onChange={handleChange}
        />
      </Box>
    )
  );
};

export default TableDimension;
