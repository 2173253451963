import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { Designs } from '@pkg/entities';
import { Routes } from '@pkg/utils';
import { DesignLevel } from '@/lib/enums';
import useDesignStore from '../useDesignStore';

/**
 * @param {Object} revision
 * @param {String} revision.uuid
 * @param {Object} revision.design
 * @param {String} revision.design.uuid
 * @param {Object} level
 * @param {String} level.type
 * @param {String} level.uuid
 * @returns {Function}
 */
export default function useRestore(revision, level) {
  const onRestore = Designs.mutations.useRestore();
  const setFocus = useDesignStore((state) => state.setFocus);
  const router = useRouter();

  const designId = revision?.design.uuid;
  const revisionId = revision?.uuid;

  const handler = async () => {
    const groupId = level.type === DesignLevel.GROUP ? level.uuid : undefined;
    await onRestore(designId, revisionId, groupId);
    setFocus(level);
    Routes.pushDesignParts(router, { designId, revisionId: 'latest' });
  };

  return useCallback(handler, [revisionId]);
}
