import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import FilterType from '@/molecules/tableElements/enums/FilterType';
import { useSkillContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

const SkillFilter = forwardRef(function skillFilter(props, ref) {
  const { skillMap } = useSkillContext();

  const [selectedMap, setSelectedMap] = useState(new Map());
  const [filterType, setFilterType] = useState(FilterType.CONTAINS_ANY);

  const handleSelectSkill = (value) => {
    const skill = skillMap.get(value);
    const selected = new Map(selectedMap);

    if (!skill?.name) {
      return;
    }

    if (selected.has(value)) {
      selected.delete(value);
    } else {
      selected.set(value, skill.name);
    }

    setSelectedMap(selected);
  };

  const handleFilterTypeChange = (event) => {
    const {
      target: { value },
    } = event;

    setFilterType(value);
  };

  useImperativeHandle(ref, () => {
    return {
      isFilterActive() {
        return selectedMap.size > 0;
      },
      doesFilterPass(params) {
        const skills = params.node.getValueFromValueService(props.column);

        if (!skills?.length) {
          return false;
        }

        switch (filterType) {
          case FilterType.ONLY_CONTAINS:
            if (skills.length !== selectedMap.size) {
              return false;
            }

            for (let i = 0; i < skills.length; i++) {
              if (!selectedMap.has(skills[i].id)) {
                return false;
              }
            }
            return true;
          case FilterType.NOT_CONTAINS:
            for (let i = 0; i < skills.length; i++) {
              if (selectedMap.has(skills[i].id)) {
                return false;
              }
            }
            return true;
          // Contains all of the skills but may also contain others.
          case FilterType.CONTAINS_ALL:
            const tempMap = new Map(selectedMap);
            for (let i = 0; i < skills.length; i++) {
              if (tempMap.has(skills[i].id)) {
                tempMap.delete(skills[i].id);
              }

              if (!tempMap.size) {
                return true;
              }
            }
            return false;
          // Contains any.
          default:
            for (let i = 0; i < skills.length; i++) {
              return selectedMap.has(skills[i].id);
            }
        }
      },
      getModel() {
        if (!selectedMap.size) {
          return undefined;
        }

        return {
          state: {
            selectedMap,
            filterType,
          },
        };
      },
      setModel(model) {
        if (model === null) {
          setSelectedMap(new Map());
          setFilterType(FilterType.CONTAINS_ANY);
        } else {
          setSelectedMap(model.state.selectedMap);
          setFilterType(model.state.filterType);
        }
      },
    };
  });

  useEffect(() => {
    props.filterChangedCallback();
  }, [selectedMap, filterType]);

  return (
    skillMap.size && (
      <Box p={1}>
        <Box pt={1}>
          <FormControl size="small">
            <InputLabel id="filter-type">Filter type</InputLabel>
            <Select
              value={filterType}
              labelId="filter-type"
              label="Filter type"
              onChange={handleFilterTypeChange}
              size="sm"
            >
              <MenuItem value={FilterType.CONTAINS_ANY}>
                {FilterType.CONTAINS_ANY}
              </MenuItem>
              <MenuItem value={FilterType.CONTAINS_ALL}>
                {FilterType.CONTAINS_ALL}
              </MenuItem>
              <MenuItem value={FilterType.ONLY_CONTAINS}>
                {FilterType.ONLY_CONTAINS}
              </MenuItem>
              <MenuItem value={FilterType.NOT_CONTAINS}>
                {FilterType.NOT_CONTAINS}
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {props.values.map((skill) => (
          <Stack
            key={skill.uuid}
            alignItems="center"
            direction="row"
            mb={1}
            spacing={1}
          >
            <input
              type="checkbox"
              name={skill.name}
              onChange={() => handleSelectSkill(skill.uuid)}
              checked={selectedMap.has(skill.uuid)}
            />
            <div>{skill.name}</div>
          </Stack>
        ))}
      </Box>
    )
  );
});

export default SkillFilter;
