import { useEffect, useMemo, useState } from 'react';
import { Bool } from '@pkg/utils';
import { useListen } from './listeners';
import useFetch from './useFetch';
import useLatestPing from './useLatestPing';
import useStore from './useStore';

/**
 * @param {String} designId
 * @return {Object}
 */
export default function useDesign(designId) {
  const { main, scenarios, isLoading } = useStore();
  const ping = useLatestPing();
  const fetch = useFetch();
  useListen(designId);

  const [isLoaded, setLoaded] = useState();
  const [design, setDesign] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    ping(designId);
  }, [designId]);

  useEffect(() => {
    if (isLoading || !designId) {
      return;
    }

    if (main?.uuid === designId) {
      setLoaded(true);
      setDesign(main);
      return;
    }

    if (scenarios.map.has(designId)) {
      setLoaded(true);
      setDesign(scenarios.map.get(designId));
      return;
    }

    setLoaded(false);
    setDesign(null);
  }, [designId, main, scenarios, isLoading]);

  useEffect(() => {
    if (isLoaded === false) {
      fetch(designId).then(({ error, design }) => {
        setError(error ?? null);
        setDesign(design ?? null);
        setLoaded(Boolean(design));
      });
    }
  }, [isLoaded]);

  return useMemo(() => {
    const isError = Boolean(error);
    const isLoading = !Bool.is(isLoaded) && !isError;
    return { design, error, isError, isLoading };
  }, [design, error, isLoaded]);
}
