/**
 * Returns the person assigned to a role.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function getPersonFromRole({ role }) {
  return role?.__person
    ? {
        name: `${role.__person.first_name} ${role.__person.last_name}`,
        uuid: role.__person.uuid,
        avatar: role.__person.avatar,
      }
    : {
        name: 'Unassigned',
        uuid: 'unassigned',
        isUnassigned: true,
      };
}
