import { cloneElement } from 'react';
import { SkillLegend } from '@/molecules/filterElements';
import { FilterProperty } from '@/organisms/filters/enums';
import Box from '@mui/material/Box';

const setFooterComponent = (propertyType) => {
  switch (propertyType) {
    case FilterProperty.SKILL:
      return <SkillLegend />;
    default:
      return null;
  }
};

const FilterFooter = ({ propertyType }) => {
  const Component = setFooterComponent(propertyType);

  return (
    Boolean(Component) && (
      <Box px={1} mb={3}>
        {cloneElement(Component)}
      </Box>
    )
  );
};

export default FilterFooter;
