import { useEffect } from 'react';
import useChannel from '../useChannel';

/**
 * @param {Object} options
 * @param {String} [options.channelName]
 * @param {String} [options.channelType]
 * @param {Function} handleEvent
 */
export default function useListen({ channelName, channelType }, handleEvent) {
  const channel = useChannel({ channelName, channelType });

  useEffect(() => {
    if (channel) {
      channel.stopListeningToAll();
      channel.listenToAll(handleEvent);
    }
  }, [channel, channelName]);
}
