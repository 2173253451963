import { useState } from 'react';
import { Size } from '@/atoms/enums';
import { Switch } from '@/atoms/inputs';
import { useInsightsContext } from '@/shared/providers';

const NestedGroupSwitch = ({ readOnly, entity }) => {
  const { updateFilterOptions } = useInsightsContext();
  const [hideNestedEntities, setHideNestedEntities] = useState(false);

  const handleChange = () => {
    const value = event?.target.checked;
    updateFilterOptions?.({
      hideNestedEntities: value,
    });
    setHideNestedEntities(value);
  };

  return (
    <Switch
      checked={hideNestedEntities}
      readOnly={readOnly}
      label="Exclude sub-groups"
      labelPlacement="end"
      onClick={handleChange}
      size={Size.SMALL}
    />
  );
};

export default NestedGroupSwitch;
