import { Fragment, useEffect, useState } from 'react';
import { ChipType } from '@/atoms/chips/enums';
import { Label } from '@/atoms/typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import ChipOption from './ChipOption';

const ChipOptions = ({
  categorised = false,
  chipType = ChipType.TAG,
  color = 'secondary',
  exclude,
  options,
  onClick,
  label,
}) => {
  const [excludeSet, setExcludeSet] = useState(new Set(exclude ?? []));

  useEffect(() => {
    setExcludeSet(new Set(exclude ?? []));
  }, [JSON.stringify(exclude)]);

  return (
    <Box>
      {label && <Label>{label}</Label>}
      {categorised
        ? options.map((category) => (
            <Fragment key={category.id ?? category.name}>
              {category.categorise && (
                <ListSubheader>{category.name}</ListSubheader>
              )}
              {category?.options.map((option) => (
                <Fragment key={option.id}>
                  {!excludeSet.has(option.id) && (
                    <ChipOption
                      id={option.id}
                      color={color}
                      option={option.properties}
                      onClick={(event) => onClick(event, option.id)}
                      chipType={chipType}
                      tabIndex={0}
                    />
                  )}
                </Fragment>
              ))}
            </Fragment>
          ))
        : options.map((option) => (
            <Fragment key={option.id}>
              {!excludeSet.has(option.id) && (
                <ChipOption
                  id={option.id}
                  color={color}
                  option={option.properties}
                  onClick={(event) => onClick(event, option.id)}
                  chipType={chipType}
                  tabIndex={0}
                />
              )}
            </Fragment>
          ))}
    </Box>
  );
};

export default ChipOptions;
