import { Size } from '@/atoms/enums';

const ChipSize = Object.freeze({
  [Size.XX_SMALL]: {
    borderRadius: '2px',
    fontSize: '0.575rem',
    height: '0.925rem',
    lineHeight: '0.925rem',
    padding: '0 4px',
  },
  [Size.X_SMALL]: {
    borderRadius: '2px',
    fontSize: '0.7rem',
    height: '1.325rem',
    lineHeight: '1.325rem',
    padding: '0 6px',
  },
  [Size.SMALL]: {
    borderRadius: '2px',
    fontSize: '0.75rem',
    height: '1.25rem',
    lineHeight: '1.25rem',
    padding: '0 6px',
  },
  [Size.MEDIUM]: {
    borderRadius: '2px',
    fontSize: '0.825rem',
    height: '1.5rem',
    lineHeight: '1.5rem',
    padding: '0 6px',
  },
  [Size.LARGE]: {
    borderRadius: '3px',
    fontSize: '0.925rem',
    height: '1.75rem',
    lineHeight: '1.75rem',
    padding: '0 8px',
  },
});

export default ChipSize;
