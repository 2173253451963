import { useState, useCallback } from 'react';
import config from '@/lib/config';

/**
 * @param {Object} initialState
 * @return {object}
 */
export default function useStateFilters(initialState) {
  const INITIAL_STATE = initialState ?? config.DEFAULT_ENTITIES;
  const [conditions, setConditions] = useState(INITIAL_STATE);

  /**
   * @param {String} [key]
   */
  function clear(key) {
    if (key) {
      setConditions((state) => {
        delete state[key];
        return { ...state };
      });
    } else {
      setConditions(initialState);
    }
  }

  const memoizedUpdate = useCallback(setConditions, []);
  const memoizedClear = useCallback((key) => clear(key), []);

  return { conditions, update: memoizedUpdate, clear: memoizedClear };
}
