export default function getSummarised(tags, ids) {
  if (tags.list.length === 0 || !ids?.length) {
    return [];
  }

  const summarised = new Map();
  ids.forEach((id) => {
    const tag = tags.map.get(id);
    switch (true) {
      case !tag:
        return;
      case summarised.has(tag.category_uuid):
        summarised.get(tag.category_uuid).count += 1;
        return;
      default:
        summarised.set(tag.category_uuid ?? tag.name, {
          name: tag.__category?.name || tag.name,
          color: tag.color,
          count: tag.__category ? 1 : 0,
        });
    }
  });

  return Array.from(summarised.values());
}
