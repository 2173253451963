import { Feature } from '@/lib/enums';

/**
 * @param {array} assignedFeatures
 * @returns {Object}
 */
export default function getFeatures(assignedFeatures) {
  return Object.values(Feature).reduce((features, feature) => {
    features[feature] = assignedFeatures.includes(feature);
    return features;
  }, {});
}
