import { useRouter } from 'next/router';
import { Shadow } from '@/atoms/colors';
import { EventName, EventProperty } from '@/shared/enums';
import { viewModeFromPath } from '@/shared/utils';
import Stack from '@mui/material/Stack';
import {
  TreeItem2Content,
  TreeItem2IconContainer,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Icon } from '@mui/x-tree-view/TreeItem2Icon';
import { Analytics } from '@pkg/analytics';
import { Routes } from '@pkg/utils';
import { brand, core } from '@/lib/theme/tokens/palettes';
import HierarchyEntityCard from './HierarchyEntityCard';

const EntityHierarchyItemContent = ({
  getContentProps,
  getIconContainerProps,
  entityType,
  color,
  id,
  isActive,
  isRoot,
  label,
  onClose,
  status,
  hasChildren,
  user,
}) => {
  const router = useRouter();
  const contentProps = getContentProps();

  const handleClick = (event) => {
    if (event.detail === 2) {
      event.preventDefault();
      event.stopPropagation();
      handleNavigate();
      return;
    }
    contentProps.onClick(event);
  };

  const handleNavigate = () => {
    const current = router.asPath;
    const query = current.split('?')[1];
    const parts = Routes.getDesignParts(current);

    const viewMode = viewModeFromPath(current);

    const route = Routes.build.designParts({
      ...parts,
      level: {
        type: entityType,
        uuid: id,
      },
    });
    router.push(`${route}?${query}`);

    Analytics.track(EventName.NAVIGATED_TO_ENTITY, {
      [EventProperty.ENTRY]: 'Entity hierarchy selector',
      [EventProperty.VIEW_MODE]: viewMode,
    });
    onClose?.();
  };

  return (
    <TreeItem2Content
      {...contentProps}
      onClick={handleClick}
      sx={{
        boxShadow: `${Shadow.low}`,
        height: 46,
        py: 0,
        pl: 0.5,
        position: 'relative',
        minWidth: 300,
        borderRadius: '3px',
        backgroundColor: isActive ? color : core.white,
        mt: isRoot ? 0 : 1,
        '&:hover': {
          boxShadow: isActive
            ? Shadow.low
            : `${Shadow.low}, inset 0 0 0 1px ${brand.lightBlue.shades[2]}`,
          backgroundColor: isActive ? `${color}e3` : core.white,
          '& .view-button': {
            opacity: 1,
            pointerEvents: 'auto',
          },
        },
        '&::after': isRoot
          ? null
          : {
              display: 'block',
              height: 2,
              width: 13,
              position: 'absolute',
              left: -13,
              top: 'calc(50% - 1px)',
              content: '" "',
              backgroundColor: brand.lightBlue.shades[1],
            },
        '&::before': {
          display: 'block',
          width: status?.expandable ? 24 : 12,
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          content: '" "',
          backgroundColor: color,
          borderTopLeftRadius: '3px',
          borderBottomLeftRadius: '3px',
        },
        '& > .MuiStack-root': {
          py: 0,
          width: '100%',
          justifyContent: 'space-between',
        },
      }}
    >
      {hasChildren && (
        <TreeItem2IconContainer {...getIconContainerProps()}>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              color: core.white,
              position: 'relative',
            }}
          >
            <TreeItem2Icon
              status={status}
              sx={{
                height: 18,
                width: 18,
                color: core.white,
              }}
            />
          </Stack>
        </TreeItem2IconContainer>
      )}
      <HierarchyEntityCard
        entityType={entityType}
        id={id}
        isActive={isActive}
        label={label}
        onClick={handleNavigate}
        status={status}
        user={user}
      />
    </TreeItem2Content>
  );
};

export default EntityHierarchyItemContent;
