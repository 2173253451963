const PlanIcon = (props) => (
  <svg width="1em" height="1em" {...props} viewBox="0 0 22 19" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.13209 0.807556C3.30366 0.807556 2.63208 1.47913 2.63208 2.30756V3.40631H1.50864C0.956352 3.40631 0.508636 3.85403 0.508636 4.40631V14.9988C0.508636 15.5511 0.956352 15.9988 1.50864 15.9988H2.10471L2.10471 16.5742C2.1047 17.4027 2.77628 18.0742 3.60471 18.0742H20.4913C21.3197 18.0742 21.9913 17.4027 21.9913 16.5742V6.50487C21.9913 5.67645 21.3197 5.00488 20.4913 5.00487L19.9221 5.00486V4.40631C19.9221 3.85403 19.4744 3.40631 18.9221 3.40631H5.73083V2.30756C5.73083 1.47913 5.05925 0.807556 4.23082 0.807556H4.13209ZM4.73083 3.40631V2.30756C4.73083 2.03142 4.50697 1.80756 4.23082 1.80756H4.13209C3.85594 1.80756 3.63208 2.03142 3.63208 2.30756V3.40631H4.73083ZM2.63208 4.40631V5.02974C2.63208 5.85817 3.30365 6.52974 4.13208 6.52974H5.23083C5.50697 6.52974 5.73083 6.30589 5.73083 6.02974C5.73083 5.7536 5.50697 5.52974 5.23083 5.52974H4.13208C3.85594 5.52974 3.63208 5.30589 3.63208 5.02974V4.40631H18.9221V11.039L16.4674 11.039C15.64 11.039 14.9688 11.7089 14.9674 12.5363L14.9631 14.9988L1.50864 14.9988V4.40631H2.63208ZM16.4674 12.039L18.2702 12.039L15.9642 14.345L15.9674 12.5381C15.9679 12.2623 16.1916 12.039 16.4674 12.039ZM3.10471 15.9988L3.10471 16.5742C3.10471 16.8504 3.32856 17.0742 3.60471 17.0742H20.4913C20.7674 17.0742 20.9913 16.8504 20.9913 16.5742V6.50487C20.9913 6.22873 20.7674 6.00487 20.4913 6.00487L19.9221 6.00486V11.3871C19.9221 11.6523 19.8167 11.9067 19.6292 12.0942L16.0175 15.7059C15.8299 15.8935 15.5756 15.9988 15.3104 15.9988H3.10471Z"
      fill="currentColor"
    />
    <path
      d="M8.37895 6.02977C8.37895 5.73999 8.61386 5.50508 8.90364 5.50508H16.2493C16.539 5.50508 16.774 5.73999 16.774 6.02977C16.774 6.31955 16.539 6.55446 16.2493 6.55446H8.90364C8.61386 6.55446 8.37895 6.31955 8.37895 6.02977Z"
      fill="currentColor"
    />
    <path
      d="M5.75552 9.17785C5.75552 8.88807 5.99043 8.65316 6.2802 8.65316H16.2493C16.539 8.65316 16.774 8.88807 16.774 9.17785C16.774 9.46763 16.539 9.70254 16.2493 9.70254H6.2802C5.99043 9.70254 5.75552 9.46763 5.75552 9.17785Z"
      fill="currentColor"
    />
    <path
      d="M3.13208 12.326C3.13208 12.0362 3.36699 11.8013 3.65677 11.8013H13.6258C13.9156 11.8013 14.1505 12.0362 14.1505 12.326C14.1505 12.6158 13.9156 12.8507 13.6258 12.8507H3.65677C3.36699 12.8507 3.13208 12.6158 3.13208 12.326Z"
      fill="currentColor"
    />
    <path
      d="M3.13208 9.17785C3.13208 8.88807 3.36699 8.65316 3.65677 8.65316H4.18145C4.47123 8.65316 4.70614 8.88807 4.70614 9.17785C4.70614 9.46763 4.47123 9.70254 4.18145 9.70254H3.65677C3.36699 9.70254 3.13208 9.46763 3.13208 9.17785Z"
      fill="currentColor"
    />
  </svg>
);

export default PlanIcon;
