export default function filterShouldUpdate(existing, updated) {
  const existingComparator = existing?.get('comparator')?.key;
  const existingValue = existing?.get('value');
  const updatedComparator = updated?.get('comparator')?.key;
  const updatedValue = updated?.get('value');

  return (
    existingValue !== updatedValue || existingComparator !== updatedComparator
  );
}
