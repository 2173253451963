import { DesignEntity, Relationship } from '@/lib/enums';

/**
 * @param {String} userId
 * @param {Object} entity
 * @param {String} type
 * @param {Object} roles
 * @param {Object} members
 * @returns {String}
 */
export default function toDesign(userId, entity, type, roles, members) {
  if (!userId) {
    return Relationship.NONE;
  }

  const roleType = type === DesignEntity.SCENARIO ? DesignEntity.DESIGN : type;
  const relationship = roles[roleType]?.byId[entity.uuid];

  if (type === DesignEntity.SCENARIO) {
    switch (relationship) {
      case 'admin':
        return Relationship.OWNER;
      case 'editor':
        return Relationship.COLLABORATOR;
      case 'viewer':
        return Relationship.VIEWER;
      default:
        return Relationship.NONE;
    }
  }

  if (type === DesignEntity.DESIGN) {
    return members.includes(userId) ? Relationship.MEMBER : Relationship.NONE;
  }

  return relationship;
}
