import { Obj } from '@pkg/utils';

/**
 * Trim empty mutation keys
 * @param {Object} mutation
 * @return {Object}
 */
export default function trim(mutation) {
  if (!Object.hasOwn(mutation, 'entities')) {
    return mutation;
  }

  const trimmed = structuredClone(mutation);
  if (trimmed.name === null) {
    delete trimmed.name;
  }

  if (trimmed.objective === null) {
    delete trimmed.objective;
  }

  for (const plural in trimmed.entities) {
    for (const action in trimmed.entities[plural]) {
      if (!trimmed.entities[plural][action].length) {
        delete trimmed.entities[plural][action];
      }
    }

    if (Obj.isEmpty(trimmed.entities[plural])) {
      delete trimmed.entities[plural];
    }
  }

  if (Obj.isEmpty(trimmed.entities)) {
    delete trimmed.entities;
  }

  if (Object.keys(trimmed).length === 0) {
    return null;
  }

  if (Object.keys(trimmed).length === 1 && trimmed.created_at !== undefined) {
    return null;
  }

  return Object.freeze(trimmed);
}
