import { Colors } from '@/atoms/chips';
import { TagColor } from '@/lib/enums';

export default function tagColor({
  color = TagColor.GREY_REGULAR,
  state = 'active',
  property = null,
} = {}) {
  const currentColor = color || TagColor.GREY_REGULAR;
  const [name, variant] = currentColor.toLowerCase().split('_');

  const properties = Colors.tag[name][variant][state];
  return property ? properties.normal[property] : properties;
}
