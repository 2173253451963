const Move = (props) => (
  <svg width="33" height="55" {...props} viewBox="0 0 33 55" fill="none">
    <path
      d="M14.757 51.972C14.757 52.9349 13.5297 53.3415 12.9548 52.5691L0.333845 35.6119C-0.157154 34.9522 0.313682 34.0149 1.13604 34.0149H6.69721C7.2495 34.0149 7.69722 33.5672 7.69722 33.0149L7.69722 1.6405C7.69722 1.08822 8.14493 0.640503 8.69722 0.640503L13.757 0.640503C14.3093 0.640503 14.757 1.08822 14.757 1.6405L14.757 51.972Z"
      fill={props.primary ? props.primary : '#BFECF0'}
    />
    <path
      d="M17.5739 1.64418C17.5739 0.681305 18.8012 0.274704 19.3761 1.04711L31.9971 18.0043C32.4881 18.664 32.0172 19.6013 31.1949 19.6013L25.6337 19.6013C25.0814 19.6013 24.6337 20.0491 24.6337 20.6013L24.6337 51.9757C24.6337 52.528 24.186 52.9757 23.6337 52.9757H18.5739C18.0216 52.9757 17.5739 52.528 17.5739 51.9757L17.5739 1.64418Z"
      fill={props.secondary ? props.secondary : '#00B4C5'}
    />
  </svg>
);

export default Move;
