import { Obj } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

export const DEFAULT_PEOPLE_METRICS = Object.freeze({
  total: {
    roles: 0,
    activities: 0,
    hours: 0,
    fte: 0,
    budget: 0,
  },
  visible: {
    roles: 0,
    activities: 0,
    hours: 0,
    fte: 0,
    budget: 0,
  },
});

/**
 * @param {Object} library
 * @param {Object|Array<Object>} people
 * @param {Object}
 */
export default function deriveProps(library, people = []) {
  if (Obj.isEmpty(people)) {
    return people;
  }

  const isArray = Array.isArray(people);
  const derived = isArray ? people : [people];

  derived.forEach((person, index) => {
    derived[index] = {
      ...person,
      __total_metrics: structuredClone(DEFAULT_PEOPLE_METRICS),
      __name: `${person.first_name ?? ''} ${person.last_name ?? ''}`.trim(),
      __pathname: `/u/${person.uuid}`,
      __type: DesignEntity.PERSON,
    };
  });

  return isArray ? derived : derived[0] ?? null;
}
