import { useState } from 'react';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import { ClientError } from '@/lib/enums';

const GET_POLICY_MATRIX = /* GraphQL */ `
  query GetPolicyMatrix {
    policyMatrix
  }
`;

export default function useFetch() {
  const client = useClient();
  const database = useDatabase();
  const [isFetching, setFetching] = useState(false);
  const [error, setError] = useState(null);

  /**
   * @return {Object}
   */
  const fetch = async () => {
    if (isFetching) {
      return false;
    }

    setError(null);
    setFetching(true);

    try {
      const result = await client.graphql({
        query: GET_POLICY_MATRIX,
      });

      if (!result) {
        setError(ClientError.NOT_FOUND);
        setFetching(false);
        return;
      }

      const matrix = JSON.parse(result.data.policyMatrix);
      await database.auth.put({ key: 'policyMatrix', ...matrix });

      setError(null);
    } catch (error) {
      if (error.isAuthorizationError?.()) {
        setError(ClientError.NOT_AUTHORIZED);
      } else {
        setError(ClientError.NOT_FOUND);
      }
    }

    setFetching(false);
  };

  return { fetch, isFetching, error, isError: Boolean(error) };
}
