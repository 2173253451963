import NextIcon from '@mui/icons-material/NavigateNextRounded';
import Box from '@mui/material/Box';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import ListColors from './ListColors';

const TextListItem = ({ color = 'primary', item, onClick }) => {
  const handleClick = (event) => {
    onClick?.(event, item);
  };

  return (
    <MuiListItem sx={{ p: 0 }}>
      <ListItemButton
        sx={{
          borderRadius: '4px',
          px: 1,
          mb: 1,
          ...ListColors[color].normal,
          '&:hover': {
            ...ListColors[color].hover,
          },
          '&:focus': {
            ...ListColors[color].focus,
          },
        }}
        onClick={handleClick}
      >
        <Stack
          alignItems="center"
          borderRadius={1}
          direction="row"
          justifyContent="space-between"
          py={0.5}
          pl={0.5}
          width="100%"
        >
          <Stack direction="row" alignItems="center" width="calc(100% - 30px)">
            <Box
              sx={{
                maxWidth: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.label}
            </Box>
            {item.startIcon && <Box mx={1}>{item.startIcon}</Box>}
          </Stack>
          {onClick && <NextIcon />}
        </Stack>
      </ListItemButton>
    </MuiListItem>
  );
};

export default TextListItem;
