import { Collections } from '@pkg/utils';
import { Completeness, Lens, Visibility } from '@/lib/enums';
import { color, palettes } from '@/lib/theme/tokens';

const INSIGHT_LENSES = new Set([
  Lens.PRODUCTIVITY,
  Lens.WORK_HEALTH,
  Lens.OUTDATED_ROLES,
]);

/**
 * @param {Function} derivedFn
 * @returns Function
 */
export default function perform(derivedFn) {
  return ({ snapshot, lens }) => {
    const scenario = structuredClone(snapshot);
    const isInsightLens = INSIGHT_LENSES.has(lens.type);

    scenario.entities.roles.forEach((role) => {
      role.__lens = derivedFn([role]);

      if (isInsightLens && !role.__is_complete) {
        role.__lens.color = palettes.grey.colors[700];
        role.__lens.contrast = color.text[palettes.grey.contrast[700]];
        role.__lens.value = 'N/A';
      }

      if (role.__visibility === Visibility.NONE) {
        role.__lens.color = color.core.white;
        role.__lens.contrast = color.primary.greyNew[100];
      }
    });

    const groupedRoles = Collections.groupBy(
      scenario.entities.roles,
      'group_uuid'
    );

    scenario.entities.groups.forEach((group) => {
      const roles = groupedRoles[group.uuid] ?? [];

      if (lens.type === Lens.TAG) {
        group.__lens = derivedFn([group]);
      } else {
        group.__lens = derivedFn(roles);
      }

      if (isInsightLens && group.__completeness === Completeness.PARTIAL) {
        group.__lens.value = `*${group.__lens.value}`;
      }

      if (isInsightLens && group.__completeness === Completeness.NONE) {
        group.__lens.color = palettes.grey.colors[700];
        group.__lens.contrast = color.text[palettes.grey.contrast[700]];
        group.__lens.value = 'N/A';
      }

      if (group.__visibility === Visibility.NONE) {
        group.__lens.color = color.core.white;
        group.__lens.contrast = color.primary.greyNew[100];
      }
    });

    return scenario;
  };
}
