import { useEffect, useState } from 'react';
import { usePropertyContext } from '@/shared/providers';

const usePropertyOptions = (entity) => {
  const { propertyMap } = usePropertyContext();
  const [options, setOptions] = useState();

  useEffect(() => {
    if (!entity || !propertyMap?.size) {
      return;
    }

    const filteredOptions = [...propertyMap.values()]
      .filter(({ scope }) => {
        return scope === entity;
      })
      .map(({ uuid, name }) => ({ uuid, name }));

    setOptions(filteredOptions);
  }, [options, entity, propertyMap?.size]);

  return options;
};

export default usePropertyOptions;
