import { useCallback, useEffect } from 'react';
import * as Auth from '@pkg/auth';
import useAdditionalDesignData from './useAdditionalDesignData';
import useDesignStore from './useDesignStore';

const getDesignId = (designId, organisation) => {
  return designId === 'main' && organisation?.design
    ? organisation.design.uuid
    : designId;
};

export default function useComparison(comparisonIds = {}) {
  const { organisation } = Auth.useStore();
  const activeComparisonIndex = useDesignStore(
    (state) => state.activeComparisonIndex
  );
  const additional = useDesignStore((state) => state.additional);
  const set = useDesignStore((state) => state.set);
  const loadRevision = useAdditionalDesignData();

  const loadComparison = useCallback((id, revisionId, entity) => {
    const designId = getDesignId(id, organisation);
    loadRevision(designId, revisionId, entity, true);
  }, []);

  const revisionInStore = (id, revisionId) => {
    const designId = getDesignId(id, organisation);
    return additional.findIndex(
      (item) =>
        item.design.uuid === designId && item.revision.uuid === revisionId
    );
  };

  const setActiveComparison = (id, revisionId) => {
    const designId = getDesignId(id, organisation);
    const index = revisionInStore(designId, revisionId);
    if (index !== -1) {
      set({ activeComparisonIndex: index });
      return true;
    }
    return false;
  };

  const cancelComparison = () => {
    set({ activeComparisonIndex: null });
  };

  // load initial comparison if provided when hook is instantiated
  useEffect(() => {
    if (comparisonIds.designId && comparisonIds.revisionId) {
      loadComparison(
        comparisonIds.designId,
        comparisonIds.revisionId,
        comparisonIds.entity
      );
    }
  }, [comparisonIds.designId, comparisonIds.revisionId]);

  const activeComparison = additional[activeComparisonIndex];

  return {
    activeComparison,
    loadComparison,
    setActiveComparison,
    cancelComparison,
  };
}
