import { useMutation } from '@pkg/graphql';

export const CREATE_INTEGRATION = /* GraphQL */ `
  mutation CreateIntegration($input: CreateIntegrationInput!) {
    createIntegration(input: $input) {
      uuid
    }
  }
`;

export default function useCreateIntegration() {
  const { mutateAsync } = useMutation({
    mutation: CREATE_INTEGRATION,
  });

  /**
   * @param {String} public_token
   * @param {String} organisation_uuid
   */
  function createIntegration(public_token, organisation_uuid) {
    return mutateAsync({ public_token, organisation_uuid });
  }

  return createIntegration;
}
