import { memo } from 'react';
import Chart from '@/components/Chart';

const PercentageCircle = ({ color, label, options, value, onClick }) => {
  return (
    <Chart
      type="doughnut"
      onClick={onClick}
      data={{
        labels: [label],
        datasets: [
          {
            backgroundColor: [color, `${color}60`],
            borderWidth: 0,
            borderRadius: 4,
            data: [value, 100 - value],
          },
        ],
      }}
      options={{
        responsive: true,
        layout: {
          autoPadding: true,
        },
        ...options,
      }}
    />
  );
};

export default memo(PercentageCircle, (prev, next) => {
  return prev.value === next.value && prev.label === next.label;
});
