import { People } from '@pkg/entities';

/**
 * @param {Object} me
 * @param {Object} permissions
 * @param {Boolean} isScenario
 * @returns {Boolean}
 */
export default function publish(me, permissions, isScenario) {
  return (
    isScenario &&
    People.can(me, permissions.design.write) &&
    People.can(me, permissions.organisation.manage)
  );
}
