import { IconButton, Button } from '@/atoms/buttons';
import { useDispatchContext } from '@/shared/providers';
import DialogAction from '@/components/DialogAction';

const ConfirmationButton = ({
  action,
  color,
  colorProps,
  confirmation,
  endIcon,
  fullWidth,
  iconButton,
  label,
  onClick,
  properties,
  size,
  startIcon,
  variant,
}) => {
  const dispatch = useDispatchContext();

  const handleClick = () => {
    onClick?.(event);
    dispatch?.handleDispatch?.({ action, properties });
  };

  return confirmation ? (
    <DialogAction
      title={confirmation?.title}
      content={confirmation?.content}
      onConfirm={handleClick}
    >
      {iconButton ? (
        <IconButton
          color={color}
          onClick={handleClick}
          size={size}
          variant={variant}
        >
          {iconButton}
        </IconButton>
      ) : (
        <Button
          color={color}
          colorProps={colorProps}
          endIcon={endIcon}
          fullWidth={fullWidth}
          label={label}
          onClick={handleClick}
          size={size}
          startIcon={startIcon}
          variant={variant}
        />
      )}
    </DialogAction>
  ) : (
    <Button
      color={color}
      colorProps={colorProps}
      endIcon={endIcon}
      fullWidth={fullWidth}
      label={label}
      onClick={handleClick}
      size={size}
      startIcon={startIcon}
      variant={variant}
    />
  );
};

export default ConfirmationButton;
