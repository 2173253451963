import hasRole from './hasRole';

/**
 * @param {Object} user
 * @param {String} role
 * @param {String} entity.uuid
 * @returns
 */
export default function hasRoleFor(user, role, entity) {
  return hasRole(user, `${role}.${entity?.uuid}`);
}
