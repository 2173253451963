import { Chip, Utils } from '@/atoms/chips';
import { ChipType } from '@/lib/enums';

const EntityChip = ({
  type,
  expanded = true,
  fontWeight = 700,
  plural,
  ...props
}) => {
  const label = ChipType.label(type, plural);
  const chipColor = Utils.entityColor(type);

  return (
    <Chip
      colorProps={chipColor}
      expanded={expanded}
      fontWeight={fontWeight}
      name={label}
      {...props}
    />
  );
};

export default EntityChip;
