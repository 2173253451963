import { useState } from 'react';
import { Utils } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Checkbox } from '@/atoms/inputs';
import { ActivityCard } from '@/organisms/cards';
import { useDispatchContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const ActivityTagCheckboxField = ({
  activity,
  allowedTagSet,
  name,
  onUpdate,
  tag,
  settings,
}) => {
  const [initialValue] = useState(
    Boolean(activity?.tags.find((tagId) => tagId === tag.id))
  );

  const tagColor = Utils.tagColor({ color: tag.color });

  const dispatch = useDispatchContext();

  const handleChange = (event, value) => {
    const selectedIds = activity.tags.filter((tagId) => tagId !== tag.id);

    if (value) {
      selectedIds.push(tag.id);
    }

    onUpdate?.(event, selectedIds);

    // Dispatch the updates.
    if (dispatch?.handleDispatch) {
      dispatch.handleDispatch?.({
        properties: {
          uuid: activity.uuid,
          [name]: selectedIds,
        },
      });
    }
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <Box>
        <Checkbox
          inputProps={{ 'data-testid': 'onboard-tag-checkbox' }}
          colorProps={tagColor}
          initialValue={initialValue}
          label={tag.name}
          onChange={handleChange}
          size={Size.LARGE}
        />
      </Box>
      <Box width="calc(100% - 48px)">
        <ActivityCard
          key={`${activity.uuid}:${activity.library_uuid}`}
          allowedTagSet={allowedTagSet}
          id={activity.uuid}
          uuid={activity.uuid}
          comparisonHours={activity.comparisonHours}
          description={activity.description}
          disabled={activity.disabled_at}
          hours={activity.hours}
          entityType={activity.entityType}
          isCoreActivity={activity.isCoreActivity}
          settings={settings}
          metrics={activity.metrics}
          tags={activity.tags}
          updatedAt={activity.updated_at}
        />
      </Box>
    </Stack>
  );
};

export default ActivityTagCheckboxField;
