import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';
import { color, typography } from '@/lib/theme/tokens';

const GroupLabel = styled(ListSubheader)({
  ...typography.caption,
  backgroundColor: color.primary.grey[200],
  padding: '5px 10px',
  marginTop: 8,
  '&:first-of-type': {
    marginTop: 0,
  },
});

export default GroupLabel;
