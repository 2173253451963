const metricDifference = (metric, action) => {
  return action.metrics[1][metric] - action.metrics[0][metric];
};

export default function cumulateMetrics(action, metrics) {
  const activities = metricDifference('activities', action);
  const budget = metricDifference('budget', action);
  const fte = metricDifference('fte', action);
  const groups = metricDifference('groups', action);
  const hours = metricDifference('hours', action);
  const roles = metricDifference('roles', action);
  const moved = metricDifference('moved', action);

  metrics.activities += activities;
  metrics.budget += budget;
  metrics.fte += fte;
  metrics.groups += groups;
  metrics.hours += hours;
  metrics.roles += roles;
  metrics.moved += moved;

  return metrics;
}
