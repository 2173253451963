import { SelectOption } from '@/atoms/inputs/Select';

const TextOption = ({
  color,
  focusOption,
  handleFocus,
  option,
  optionId,
  optionLabel,
  OptionComponent,
  onClick,
}) => {
  return (
    <SelectOption
      key={option?.[optionId]}
      color={color}
      isFocus={
        option?.[optionId] === focusOption?.[optionId] ? 'isFocus' : null
      }
      onClick={(event) => onClick(event, option)}
      onFocus={(event) => handleFocus(event, option)}
      value={option}
    >
      {OptionComponent ? OptionComponent(option) : <>{option[optionLabel]}</>}
    </SelectOption>
  );
};

export default TextOption;
