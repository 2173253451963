import { useQuery } from '@pkg/graphql';
import useDerivedProps from './useDerivedProps';

export const GET_PERSON = /* GraphQL */ `
  query GetPerson($uuid: UUID!) {
    user(uuid: $uuid) {
      uuid
      name
      first_name
      last_name
      email
      avatar
      salary
      skills {
        uuid
        level
      }
      fte
      registered_at
    }
  }
`;

/**
 * @param {String} uuid
 */
export default function usePersonQuery(uuid) {
  const query = [GET_PERSON, { uuid }];
  const { data, ...rest } = useQuery({ query, enabled: Boolean(uuid) });
  const person = useDerivedProps(data?.user);

  return {
    person,
    ...rest,
  };
}
