import { EntityType } from '@/shared/enums';

/**
 * Returns a role from a given entity.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function getRoleFromEntity({
  entityItem,
  entityType,
  snapshotEntityMap,
}) {
  if (entityType === EntityType.ROLE) {
    return entityItem;
  }
  if (entityType === EntityType.ACTIVITY) {
    // If an activity owner isn't a role we assign an 'Unallocated' object.
    return entityItem.owner_type === EntityType.ROLE
      ? snapshotEntityMap.get(EntityType.ROLE).get(entityItem.owner_uuid)
      : {
          title: 'Unallocated',
          uuid: entityItem.owner_uuid,
          isUnallocated: true,
        };
  }
}
