import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

export default function aggregateOtherSegment({ bar, item, metric, row }) {
  const { data } = bar;
  const isPercentageMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE ||
    metric === EntityMetric.PERCENTAGE;
  const scaleMetric = isPercentageMetric ? EntityMetric.HOURS : metric;

  data.activities += item.data?.activities ?? 0;
  data.budget += item.data?.budget ?? 0;
  data.count += item.data?.count ?? 0;
  data.fte += item.data?.fte ?? 0;
  data.groups += item.data?.groups ?? 0;
  data.hours += item.data?.hours ?? 0;
  data.managers += item.data?.managers ?? 0;
  data.roles += item.data?.roles ?? 0;
  data.span += item.data?.span ?? 0;

  const percentage = percent(data?.[scaleMetric], row?.data?.[scaleMetric], 2);

  data.percentage = percentage;
  data.relativePercentage = percentage;

  return data;
}
