import { EntityType } from '@/shared/enums';

export default function aggregateEntity({
  entities,
  existingMetrics,
  props,
  remainingSet,
  id,
  map,
  nextEntity,
  type,
  uuid,
}) {
  const existing = map.get(id);

  const roleIncrement =
    (remainingSet.has(EntityType.ROLE) ||
      type === EntityType.ROLE ||
      type === EntityType.PERSON) &&
    entities?.role
      ? 1
      : 0;
  const groupIncrement =
    (remainingSet.has(EntityType.GROUP) || type === EntityType.GROUP) &&
    entities?.group
      ? 1
      : 0;
  const managerIncrement =
    (remainingSet.has(EntityType.MANAGER) ||
      remainingSet.has(EntityType.ROLE)) &&
    entities?.manager
      ? 1
      : 0;
  const activityIncrement =
    remainingSet.has(EntityType.ACTIVITY) && entities?.activity ? 1 : 0;

  const spanIncrement =
    typeof existingMetrics.managedSpan !== 'undefined' &&
    (remainingSet.has(EntityType.SPAN) || type === EntityType.SPAN);

  if (existing) {
    if (activityIncrement) {
      existing.activitySet.add(entities.activity);
    }
    if (roleIncrement && !existing.roleSet.has(entities.role)) {
      existing.metrics.roleHours += existingMetrics.role?.hours || 0;
      existing.roleSet.add(entities.role);
    }
    if (groupIncrement && !existing.groupSet.has(entities.group)) {
      existing.metrics.groupHours += existingMetrics.group?.hours || 0;
      existing.groupSet.add(entities.group);
    }
    if (managerIncrement) {
      existing.managerSet.add(entities.manager);
    }

    const metrics = {
      count: existing.metrics.count + 1,
      activities: existing.activitySet.size,
      groups: existing.groupSet.size,
      groupHours: existing.metrics.groupHours,
      managers: existing.managerSet.size,
      managedSpan: existing.metrics.managedSpan,
      roles: existing.roleSet.size,
      roleHours: existing.metrics.roleHours,
      span: existing.metrics.span + existingMetrics.span,
      hours: existing.metrics.hours + existingMetrics.hours,
      budget: existing.metrics.budget + existingMetrics.budget,
      fte: existing.metrics.fte + existingMetrics.fte,
    };

    metrics.averageSpan =
      metrics.span && metrics.managers ? metrics.span / metrics.managers : 0;

    if (
      spanIncrement &&
      !existing.managedSpanSet.has(existingMetrics.managedSpan)
    ) {
      metrics.managedSpan =
        existing.metrics.managedSpan + existingMetrics.managedSpan;
      existing.managedSpanSet.add(existingMetrics.managedSpan);
    }

    const entity = {
      ...existing,
      metrics,
    };

    if (uuid) {
      entity.entitySet = existing.entitySet.add(uuid);
    }

    map.set(id, entity);
  } else {
    const roleHours = roleIncrement ? (existingMetrics.role?.hours ?? 0) : 0;
    const groupHours = groupIncrement ? (existingMetrics.group?.hours ?? 0) : 0;

    const entityProps = {
      props,
      type,
      metrics: {
        activities: activityIncrement,
        averageSpan: managerIncrement > 0 ? existingMetrics.span : 0,
        count: 1,
        groupHours,
        groups: groupIncrement,
        managers: managerIncrement,
        managedSpan: existingMetrics.managedSpan || 0,
        roles: roleIncrement,
        span: existingMetrics.span,
        hours: existingMetrics.hours,
        budget: existingMetrics.budget,
        fte: existingMetrics.fte,
        roleHours,
      },
      activitySet: new Set(activityIncrement ? [entities.activity] : []),
      roleSet: new Set(roleIncrement ? [entities.role] : []),
      groupSet: new Set(groupIncrement ? [entities.group] : []),
      managerSet: new Set(managerIncrement ? [entities.manager] : []),
      managedSpanSet: new Set(
        spanIncrement ? [existingMetrics.managedSpan] : []
      ),
    };

    if (uuid) {
      entityProps.entitySet = new Set([uuid]);
    }

    if (nextEntity) {
      entityProps.childEntity = nextEntity;
      entityProps[nextEntity] = new Map();
    }

    map.set(id, entityProps);
  }

  if (nextEntity) {
    map = map.get(id)?.[nextEntity];
  }

  return map;
}
