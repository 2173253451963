import { EntityType } from '@/shared/enums';
import createEntityMap from './createEntityMap';
import createMatrixItemMap from './createMatrixItemMap';
import createNestedMap from './createNestedMap';
import excludeEntity from './excludeEntity';
import getGroupFromRole from './getGroupFromRole';
import getManagerFromRole from './getManagerFromRole';
import getMetrics from './getMetrics';
import getPersonFromRole from './getPersonFromRole';
import getPropertiesFromEntity from './getPropertiesFromEntity';
import getRoleFromEntity from './getRoleFromEntity';
import getTagsFromEntity from './getTagsFromEntity';

export default function mapEntities({
  entityType,
  excludeUnmanaged,
  filter,
  includeEmptySpans,
  includeUnallocated,
  matrix,
  order,
  propertyMap,
  relationshipMap,
  snapshotEntityMap,
  tagMap,
}) {
  const isManager = entityType === EntityType.MANAGER;
  const includesSpans = order.includes(EntityType.SPAN);
  const metrics = {
    count: 0,
    hours: 0,
    budget: 0,
    fte: 0,
    managedSpan: 0,
    roles: 0,
    span: 0,
    activities: 0,
    groups: 0,
    people: 0,
  };

  const entityMap = createEntityMap({
    matrix,
    metrics: { ...metrics },
    order,
  });

  const entitySets = new Map([
    [EntityType.ACTIVITY, new Set()],
    [EntityType.LIBRARY_ACTIVITY, new Set()],
    [EntityType.MANAGER, new Set()],
    [EntityType.ROLE, new Set()],
    [EntityType.GROUP, new Set()],
    [EntityType.PERSON, new Set()],
  ]);
  // A set of unique span counts across a layer.
  const managedSpanSet = new Set();

  relationshipMap.get(entityType)?.forEach((entityItem) => {
    // Get the relationships of the entities we're mapping.
    const activity = entityType === EntityType.ACTIVITY ? entityItem : null;
    const role = getRoleFromEntity({
      entityItem,
      entityType,
      snapshotEntityMap,
    });
    const manager = getManagerFromRole({ role, snapshotEntityMap });
    if (manager.isNotManaged && excludeUnmanaged) {
      return;
    }
    const group = getGroupFromRole({
      entityItem,
      entityType,
      role,
      snapshotEntityMap,
    });
    const person = getPersonFromRole({ role });

    // This is where we would filter.
    if (filter) {
      const shouldFilter = excludeEntity({
        activity,
        filter,
        group,
        role,
      });

      if (shouldFilter) {
        return;
      }
    }

    // Add the activity.
    if (activity) {
      entitySets.get(EntityType.ACTIVITY)?.add(activity.uuid);
      entitySets.get(EntityType.LIBRARY_ACTIVITY)?.add(activity.library_uuid);
    }

    // Add the group.
    if (group) {
      entitySets.get(EntityType.GROUP)?.add(group.uuid);
    }

    if (!role.isUnassigned) {
      entitySets.get(EntityType.ROLE)?.add(role.uuid);
    }

    if (role.is_manager) {
      entitySets.get(EntityType.MANAGER)?.add(role.uuid);
    }

    if (!person.isUnassigned) {
      entitySets.get(EntityType.PERSON)?.add(person.uuid);
    }

    if (manager?.__metrics) {
      managedSpanSet.add(manager.__metrics.span.visible.roles);
    }

    // Get the tags for this given entity.
    const tags = getTagsFromEntity({ activity, entityType, group, role });

    const properties = getPropertiesFromEntity({
      activity,
      entityType,
      group,
      role,
    });

    // Calculate the entity metrics.
    const entityMetrics = getMetrics({
      activity,
      entityType,
      group,
      role,
      manager,
    });

    // Exit early if this role is unallocated and we aren't including
    // unallocated roles;
    if (!includeUnallocated && role.isUnallocated) {
      return;
    }

    // Add the metrics to the entity map.
    if (!role.isUnallocated) {
      entityMap.metrics.budget += entityMetrics.budget;
      entityMap.metrics.count += 1;
      entityMap.metrics.fte += entityMetrics.fte;
      entityMap.metrics.hours += entityMetrics.hours;
      entityMap.metrics.span += entityMetrics.span;
    }

    const entities = {
      role: role?.uuid,
      manager: manager?.__metrics ? manager?.uuid : null,
      activity: activity?.uuid,
      group: group?.uuid,
    };

    // Here is where we build out the map for this entity.
    let map;

    // This is where we would handle the matrix map.
    if (matrix) {
      map = createMatrixItemMap({
        activity,
        entityMap,
        entityMetrics,
        group,
        order,
        role,
      });
    }

    if (!matrix) {
      map = entityMap[order[0]];
    }

    // If we are including spans in the entity map we need to make sure managers
    // that don't have any spans are included when the 'includeEmptySpans' flag
    // is true.
    if (
      includeEmptySpans &&
      includesSpans &&
      role.is_manager &&
      !role.__metrics.span.visible.roles
    ) {
      map = createNestedMap({
        activity,
        group,
        entities,
        entityMetrics: { ...entityMetrics, managedSpan: 0, span: 0 },
        entityType,
        includesSpans,
        manager: role,
        map,
        person,
        properties,
        propertyMap,
        role,
        tags,
        tagMap,
        order,
      });

      map = entityMap[order[0]];
    }

    // Adds a nested object to the entity map from the current entity items.
    map = createNestedMap({
      activity,
      group,
      entities,
      entityMetrics,
      entityType,
      includesSpans,
      manager,
      map,
      person,
      properties,
      propertyMap,
      role,
      tags,
      tagMap,
      order,
    });
  });

  entityMap.metrics.activities = entitySets.get(EntityType.ACTIVITY).size;
  entityMap.metrics.libraryActivities = entitySets.get(
    EntityType.LIBRARY_ACTIVITY
  ).size;
  entityMap.metrics.managers = entitySets.get(EntityType.MANAGER).size;
  entityMap.metrics.roles = entitySets.get(EntityType.ROLE).size;
  entityMap.metrics.groups = entitySets.get(EntityType.GROUP).size;
  entityMap.metrics.people = entitySets.get(EntityType.PERSON).size;
  // Calculates the cumulate unique span counts across a layer.
  entityMap.metrics.managedSpan = [...managedSpanSet].reduce(
    (a, b) => a + b,
    0
  );
  // We use the maximum span count as the top level for average span
  // percentages.
  entityMap.metrics.averageSpan = Math.max(...managedSpanSet);

  return entityMap;
}
