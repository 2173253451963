import { Orientation } from '@/atoms/enums';
import {
  D3Chart,
  HorizontalBarChart,
  VerticalBarChart,
} from '@/organisms/charts';

const BarChart = ({
  chartData,
  excludeId,
  fullscreen,
  onNavigate,
  id,
  metric,
  metricTotals,
  orientation = Orientation.HORIZONTAL,
  sort,
  title,
}) => {
  return (
    <D3Chart
      height={fullscreen ? 500 : 400}
      id={id}
      noScrollY={orientation === Orientation.VERTICAL}
      noScrollX={orientation === Orientation.HORIZONTAL}
      restrictHeight={orientation === Orientation.HORIZONTAL}
    >
      {orientation === Orientation.VERTICAL ? (
        <VerticalBarChart
          excludeId={excludeId}
          initialData={chartData}
          metric={metric}
          metricTotals={metricTotals}
          onNavigate={onNavigate}
          sort={sort}
          title={title}
        />
      ) : (
        <HorizontalBarChart
          excludeId={excludeId}
          initialData={chartData}
          metric={metric}
          metricTotals={metricTotals}
          onNavigate={onNavigate}
          sort={sort}
          title={title}
        />
      )}
    </D3Chart>
  );
};

export default BarChart;
