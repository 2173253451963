import { useEffect, useState } from 'react';
import { Avatar } from '@/atoms/avatars';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import { ChipType, DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';

const checkIsSupporter = (entityId, userId, supporterMap) => {
  return supporterMap?.get(entityId)?.has(userId);
};

const EntitySummary = ({
  entity,
  showPerson,
  fontWeight = 600,
  showChip = true,
  isLarge,
}) => {
  const { me } = Auth.useStore();
  const designs = useDesignsContext();
  const [isSupporter, setIsSupporter] = useState();
  const isManager = entity?.is_manager;
  const person = entity?.__person;

  useEffect(() => {
    if (!entity) {
      return;
    }
    setIsSupporter(
      checkIsSupporter(entity.uuid, me.uuid, designs?.supporterMap)
    );
  }, [entity?.uuid]);

  return (
    entity && (
      <Box>
        {showChip && (
          <Stack direction="row" spacing={1} pb={0.5}>
            <EntityChip
              type={isManager ? DesignEntity.MANAGER : entity.__type}
              size={isLarge ? Size.SMALL : Size.XX_SMALL}
            />
            {isSupporter && (
              <EntityChip
                type={ChipType.SUPPORTER}
                size={isLarge ? Size.SMALL : Size.XX_SMALL}
              />
            )}
          </Stack>
        )}
        <Stack alignItems="center" direction="row" spacing={1}>
          <Box>
            <Stack alignItems="center" direction="row" spacing={0.5}>
              <Heading
                variant={isLarge ? 'h3' : 'h4'}
                overrideStyles={{ mb: 0, fontWeight: 600 }}
              >
                {entity.title ?? entity.name}
              </Heading>
            </Stack>
            {showPerson && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.75}
                pt={0.5}
              >
                <Avatar
                  src={person?.avatar}
                  name={person?.first_name ?? 'Unassigned'}
                  showBorder
                  size={Size.XX_SMALL}
                />
                <Box>
                  <Heading variant="h6" overrideStyles={{ mb: 0, pt: 0.25 }}>
                    {person
                      ? `${person.first_name} ${person.last_name}`
                      : 'Unassigned'}
                  </Heading>
                </Box>
              </Stack>
            )}
          </Box>
        </Stack>
      </Box>
    )
  );
};

export default EntitySummary;
