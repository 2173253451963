import { memo, useCallback, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Colors as InputColors } from '@/atoms/inputs';
import { TextInput } from '@/atoms/inputs/Text';
import { flatInputSizeProps } from '@/atoms/inputs/utils';
import { useDispatchContext } from '@/shared/providers';
import Box from '@mui/material/Box';

const InlineTextField = ({
  action,
  color = 'secondary',
  displayFormatter,
  initialValue = '',
  inputProps = { type: 'text' },
  valueFormatter,
  fullWidth = true,
  name,
  onUpdate,
  placeholder = 'Add text...',
  size = Size.MEDIUM,
  textAlign = 'left',
  id,
}) => {
  const [text, setText] = useState(initialValue ?? placeholder);
  const [isEditing, setIsEditing] = useState(null);
  const [isHover, setIsHover] = useState(null);
  const { canEdit, handleDispatch } = useDispatchContext() || {};

  const sizeProps = flatInputSizeProps(size);

  const { onKeyDown, ...inputPropsRest } = inputProps;

  const handleClick = () => {
    if (!canEdit) {
      return;
    }
    setIsEditing(!isEditing);
  };

  // Restrict updating the snapshot to blur events.
  const handleBlur = useCallback(
    (event, value) => {
      setIsEditing(false);
      const updatedValue = displayFormatter
        ? displayFormatter(value || placeholder)
        : value || placeholder;

      setText(updatedValue);
      onUpdate?.(event, updatedValue);

      // Dispatch the updates.
      if (handleDispatch) {
        const formattedValue = valueFormatter ? valueFormatter(value) : value;
        handleDispatch?.({
          action,
          properties: {
            uuid: id,
            [name]: formattedValue,
          },
        });
      }
    },
    [id]
  );

  const handleKeyDown = (event, value) => {
    if (onKeyDown) {
      onKeyDown(event, value);
    }

    if (event.key === 'Enter') {
      handleBlur(event, value);
    }
  };

  const handleOnHover = useCallback(() => {
    if (!canEdit) {
      return;
    }
    setIsHover(true);
  }, [canEdit]);

  const handleOnMouseLeave = useCallback(() => {
    if (!canEdit) {
      return;
    }
    setIsHover(false);
  }, [canEdit]);

  return (
    <Box position="relative">
      <Box
        onMouseOver={handleOnHover}
        onMouseLeave={handleOnMouseLeave}
        width="calc(100%)"
        sx={{
          ...sizeProps,
          borderRadius: '4px',
          color: BaseColors.font.primary,
          backgroundColor:
            canEdit && isHover
              ? InputColors.Input[color].hover.backgroundColor
              : 'transparent',
          cursor: isEditing || !canEdit ? 'inherit' : 'pointer',
          padding: '0 4px',
          position: 'relative',
          transition: 'box-shadow 100ms',
          zIndex: 1,
        }}
      >
        {isEditing ? (
          <TextInput
            autoFocus={true}
            fullWidth={fullWidth}
            initialValue={text}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            inputProps={inputPropsRest}
            variant="flat"
            size={size}
            textAlign={textAlign}
          />
        ) : (
          <Box onClick={handleClick} position="relative" textAlign={textAlign}>
            <Box>{text}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default memo(InlineTextField, (prev, next) => {
  return prev.id === next.id && prev.initialValue === next.initialValue;
});
