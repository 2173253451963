/**
 * Converts empty arrays and strings to null;
 *
 * @param {Array|String} value
 *
 * @return {Array|String|Null}
 */
export default function convertEmptyToNull(value) {
  if (Array.isArray(value)) {
    return value?.length ? value : null;
  }
  return value;
}
