/**
 * @param {String} id
 * @param {Object} parents
 * @param {Set} skip
 */
export default function findNearestParent(id, parents, skip) {
  const parentId = parents[id] ?? null;

  if (id === parentId) {
    return parentId;
  }

  if (parentId && skip.has(parentId)) {
    return findNearestParent(parentId, parents, skip);
  }

  return parentId;
}
