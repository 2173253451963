import { Action, DesignEntity, Property } from '@/lib/enums';

const multipleEntityActions = [
  {
    entityType: DesignEntity.ROLE,
    property: Property.PARENT,
    action: Action.EDIT,
    referencedEntityType: DesignEntity.ROLE,
    referencedProperty: Property.TITLE,
    referencedAction: Action.EDIT,
  },
  {
    entityType: DesignEntity.ROLE,
    property: Property.GROUP,
    action: Action.EDIT,
    referencedEntityType: DesignEntity.GROUP,
    referencedProperty: Property.ROLES,
    referencedAction: Action.ADD,
  },
  {
    entityType: DesignEntity.GROUP,
    property: Property.GROUP,
    action: Action.EDIT,
    referencedEntityType: DesignEntity.GROUP,
    referencedProperty: Property.GROUP,
    referencedAction: Action.EDIT,
  },
  {
    entityType: DesignEntity.ACTIVITY,
    property: Property.OWNER,
    action: Action.EDIT,
    referencedEntityType: null,
    referencedProperty: Property.ACTIVITIES,
    referencedAction: Action.ADD,
  },
];

export { multipleEntityActions };
