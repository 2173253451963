import { createContext, useContext, useMemo, useState } from 'react';
import useFilter from './useFilter';

export const FilterContext = createContext();
export const useFilterContext = () => useContext(FilterContext);

const DefaultFilter = {
  comparator: 'AND',
  groups: [
    {
      comparator: 'AND',
      conditions: [],
    },
  ],
};

const FilterProvider = ({ children }) => {
  const [filter, setFilter] = useState(DefaultFilter);

  const applyFilter = useFilter();
  const changeFilter = (filters, merge = false) => {
    const newFilters = merge
      ? {
          ...filters,
          groups: [
            {
              ...filters.groups[0], // Assumes only one group
              conditions: mergeConditions(
                filter.groups[0].conditions,
                filters.groups[0].conditions
              ),
            },
          ],
        }
      : filters;

    setFilter(newFilters);
    applyFilter(newFilters);
  };

  const mergeConditions = (existingConditions, newConditions) => {
    const mergedMap = new Map();

    // Add existing conditions to the map
    existingConditions.forEach((condition) => {
      const key = generateConditionKey(condition);
      mergedMap.set(key, condition);
    });

    // Add new conditions to the map (overwriting existing ones with the same key)
    newConditions.forEach((condition) => {
      const key = generateConditionKey(condition);
      mergedMap.set(key, condition);
    });

    // Return merged conditions as an array
    return Array.from(mergedMap.values());
  };

  const generateConditionKey = (condition) => {
    const entityKey = condition.get('entity')?.key;
    const propertyKey = condition.get('property')?.key;
    return `${entityKey}:${propertyKey}`;
  };

  const context = useMemo(
    () => ({ changeFilter, filter }),
    [changeFilter, filter]
  );

  return (
    <FilterContext.Provider value={context}>{children}</FilterContext.Provider>
  );
};

export default FilterProvider;
