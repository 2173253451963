import { Base as BaseColors, Shadow } from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const TooltipColors = Object.freeze({
  primary: {
    backgroundColor: brand.blue.main,
    color: core.white,
  },
  secondary: {
    backgroundColor: core.white,
    // backgroundColor: brand.blue.main,
    // backgroundColor: '#2e4c6e',
    // backgroundColor: brand.yellow.main,
    color: BaseColors.font.primary,
    // color: core.white,
    boxShadow: `${Shadow.mid}, 0 1px 0 0 ${BaseColors.border.primary}77`,
  },
});

export default TooltipColors;
