import * as Mutations from './mutations';

/**
 * @param {any[]} query
 */
export default function useMutations(query) {
  return {
    mutateAccess: Mutations.useMutateAccess(query),
    transferOwnership: Mutations.useTransferOwnership(query),
    update: Mutations.useUpdate(query),
  };
}
