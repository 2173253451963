import InfoIcon from '@mui/icons-material/Info';
import { brand } from '@/lib/theme/tokens/palettes';
import Box from '@/components/Box';

const Information = ({ children }) => {
  return (
    <Box
      sx={{
        px: 1,
        pb: 2,
        maxWidth: '400px',
      }}
    >
      <Box sx={{ pt: 1, textAlign: 'left' }}>
        <InfoIcon
          sx={{
            color: brand.yellow.main,
          }}
        />
      </Box>
      {children}
    </Box>
  );
};

export default Information;
