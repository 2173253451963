import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import { ChipType } from '@/lib/enums';

const DesignOption = ({ label, scenarioType, isScenario }) => {
  const chipType = isScenario ? ChipType.SCENARIO : ChipType.LIVE;

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      sx={{
        overflow: 'hidden',
      }}
    >
      <EntityChip size={Size.SMALL} type={chipType} />
      {isScenario && <EntityChip size={Size.SMALL} type={scenarioType} />}
      <Paragraph
        size={Size.SMALL}
        overrideStyles={{
          textWrap: 'nowrap',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {label}
      </Paragraph>
    </Stack>
  );
};

export default DesignOption;
