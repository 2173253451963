import { memo } from 'react';
import styled from '@mui/system/styled';

const ConfirmContent = styled('div')({
  left: 32,
  position: 'absolute',
  top: '50%',
  textAlign: 'center',
  transform: 'translateY(-50%)',
  width: 288,
});

export default memo(ConfirmContent);
