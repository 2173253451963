import { Select } from '@/atoms/inputs';
import TagDimensions from './TagDimensions';

const DimensionSelect = ({
  onChange,
  dimensions = TagDimensions,
  label,
  width = 220,
}) => {
  return (
    <Select
      initialValue={dimensions[0]}
      label={label}
      onChange={onChange}
      options={dimensions}
      optionLabel="label"
      width={width}
    />
  );
};

export default DimensionSelect;
