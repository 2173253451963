import { Properties } from '@pkg/entities/library';

/**
 * @param {Object} property
 * @param {Object} data
 * @return {Object}
 */
export default function createInput(property, data) {
  const { options, ...rest } = data ?? {};
  const input = { uuid: property?.uuid, ...rest };

  const inputOptions = options ?? [];
  const mutatedOptions = Properties.options.mutate(property, inputOptions);
  input.options = mutatedOptions;

  return input;
}
