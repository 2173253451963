import { TagChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';

const MatrixTitle = ({ id, tags }) => {
  const [firstTag, secondTag] = [...tags.values()];

  return (
    <Box textAlign="left" py={1}>
      {id === 'NONE' && secondTag && (
        <Paragraph
          size={Size.SMALL}
          overrideStyles={{ mb: 0, lineHeight: '1.25rem' }}
        >
          Neither <span style={{ fontWeight: 600 }}>{firstTag.name}</span> nor{' '}
          <span style={{ fontWeight: 600 }}>{secondTag.name}</span>
        </Paragraph>
      )}
      {id === 'NONE' && !secondTag && (
        <Paragraph size={Size.SMALL} overrideStyles={{ mb: 0 }}>
          Not <span style={{ fontWeight: 600 }}>{firstTag.name}</span>
        </Paragraph>
      )}
      {id === firstTag?.uuid && (
        <TagChip
          id={firstTag.uuid}
          name={firstTag.name}
          color={firstTag.color}
          expanded={true}
        />
      )}
      {id === secondTag?.uuid && (
        <TagChip
          id={secondTag.uuid}
          name={secondTag.name}
          color={secondTag.color}
          expanded={true}
        />
      )}
      {id === 'ALL' && (
        <>
          <TagChip
            id={firstTag.uuid}
            name={firstTag.name}
            color={firstTag.color}
            expanded={true}
          />
          <span
            style={{
              fontSize: '0.875rem',
              lineHeight: '1.5rem',
              fontWeight: 600,
            }}
          >
            {' '}
            and{' '}
          </span>
          <TagChip
            id={secondTag.uuid}
            name={secondTag.name}
            color={secondTag.color}
            expanded={true}
          />
        </>
      )}
    </Box>
  );
};

export default MatrixTitle;
