import { useMemo } from 'react';
import Productivity from '@pkg/insights/Productivity';
import WorkHealth from '@pkg/insights/WorkHealth';
import { Collections } from '@pkg/utils';
import * as color from '@/lib/theme/tokens/color';

export default function useInsights(snapshot, uuid) {
  return useMemo(() => {
    const role = Collections.findById(snapshot.entities.roles, uuid);
    const group = Collections.findById(
      snapshot.entities.groups,
      role.group_uuid
    );

    const insights = [];

    insights.push({
      title: 'Productivity',
      labels: ['Strategic\nImportance', 'Administrative', 'Internal\nMeetings'],
      summary: [
        {
          color: color.core.blue,
          data: Productivity.getEntityResults(role),
          label: 'Role',
        },
        {
          color: color.core.navy,
          data: Productivity.getEntityResults(group),
          label: 'Team',
        },
      ],
    });

    insights.push({
      title: 'Work Health',
      labels: ['Energising', 'Average\nWorkload'],
      summary: [
        {
          color: color.core.yellow,
          data: WorkHealth.getEntityResults(role, 'percent', [
            'energising',
            'workload',
          ]),
          label: 'Role',
        },
        {
          color: color.core.pink,
          data: WorkHealth.getEntityResults(group, 'percent', [
            'energising',
            'workload',
          ]),
          label: 'Team',
        },
      ],
    });

    return insights;
  }, [snapshot, uuid]);
}
