import { Snapshots } from '@pkg/entities';
import shortuuid from '@pkg/uuid';
import config from '@/lib/config';

/**
 * @param {String} event
 * @param {Object} snapshot
 * @returns {Array}
 */
export default function create(_, { activities = [], new_uuid, title }) {
  const uuid = new_uuid ?? shortuuid.generate();
  const role = {
    uuid,
    title: title || config.DEFAULT_ROLE_TITLE,
    fte: config.DEFAULT_FTE,
    tags: [],
  };

  const newSnapshot = {
    entities: {
      groups: [],
      roles: [role],
      activities: [],
    },
  };

  const activityMutation = Snapshots.reducers.activities.add(
    newSnapshot,
    activities
  );

  newSnapshot.entities.activities = activityMutation.entities.activities.create;

  return {
    type: 'ROLE',
    snapshot: newSnapshot,
    enabled: true,
    uuid,
  };
}
