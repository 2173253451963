import { TagChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { brand } from '@/lib/theme/tokens/palettes';

const MatrixListItem = ({ item, onClick, position }) => {
  const properties = item.properties;

  const handleClick = () => {
    onClick?.(item, position);
  };

  return (
    <MuiListItem sx={{ p: 0 }}>
      <ListItemButton
        sx={{
          px: 1,
          m: 0.5,
          '&:hover': {
            backgroundColor: brand.lightBlue.tints[4],
          },
          '&:focus': {
            backgroundColor: brand.lightBlue.tints[4],
          },
        }}
        onClick={handleClick}
      >
        {properties && (
          <TagChip
            color={properties.color}
            expanded={true}
            id={item.id}
            name={properties.name}
          />
        )}
        {!properties && (
          <Paragraph size={Size.SMALL} overrideStyles={{ mb: 0 }}>
            {item.label}
          </Paragraph>
        )}
      </ListItemButton>
    </MuiListItem>
  );
};

export default MatrixListItem;
