import { FilterEntity, FilterProperty } from '@/organisms/filters/enums';

/**
 * Returns the filter entity from the entity or property provided.
 * @param {String} entity
 * @param {String} property
 *
 * @return {String};
 */
export default function getFilterEntityType(entity, property) {
  if (
    entity === FilterEntity.ACTIVITY ||
    property === FilterProperty.ACTIVITY
  ) {
    return FilterEntity.ACTIVITY;
  }
  if (entity === FilterEntity.ROLE || property === FilterProperty.ROLE) {
    return FilterEntity.ROLE;
  }
  if (entity === FilterEntity.GROUP || property === FilterProperty.GROUP) {
    return FilterEntity.GROUP;
  }
  if (entity === FilterEntity.PEOPLE || property === FilterProperty.PEOPLE) {
    return FilterEntity.PEOPLE;
  }
}
