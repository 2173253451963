import { useEffect, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { DateTime } from '@/atoms/inputs';

// This field is necessary to forward ref the attached input props to the button.
const ButtonField = ({ color, label, InputProps, onClick, size }) => {
  return (
    <Button
      ref={InputProps?.ref}
      color={color}
      label={label}
      onClick={onClick}
      size={size}
    />
  );
};

const DateFilter = ({ id, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const [label, setLabel] = useState();

  const handleClick = () => {
    setOpen?.((prev) => !prev);
  };

  const handleChange = (value) => {
    onChange?.(id, value);
  };

  useEffect(() => {
    if (value) {
      setLabel(
        value?.toLocaleString('en-GB', {
          weekday: 'short',
          day: 'numeric',
          month: 'short',
          year: '2-digit',
        })
      );
    } else {
      setLabel('Select date');
    }
  }, [value]);

  return (
    <DateTime
      onChange={handleChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      open={open}
      slots={{ field: ButtonField }}
      slotProps={{
        field: {
          onClick: handleClick,
          color: value ? 'light-blue' : 'secondary',
          label,
          size: Size.MEDIUM,
        },
      }}
      value={value}
    />
  );
};

export default DateFilter;
