import { useMutation } from '@pkg/graphql';
import { RevisionFragment } from '../fragments';
import useUpdateFromResult from './hooks/useUpdateFromResult';

const RESTORE_DESIGN = /* GraphQL */ `
  mutation RestoreDesign($input: RestoreDesignInput!) {
    restoreDesign(input: $input) {
      uuid
      latest {
        ...RevisionFragment
      }
    }
  }

  ${RevisionFragment}
`;

/**
 * @param {any[]} query
 * @returns {Function}
 */
export default function useRestore() {
  const { mutateAsync } = useMutation({ mutation: RESTORE_DESIGN });
  const update = useUpdateFromResult();

  /**
   * @param {String} design_uuid
   * @param {String} revision_uuid
   * @param {String} [group_uuid]
   * @returns {Promise}
   */
  async function restore(design_uuid, revision_uuid, group_uuid) {
    const input = { design_uuid, revision_uuid, group_uuid };
    const result = await mutateAsync(input);
    await update(result.data?.restoreDesign);
    return result;
  }

  return restore;
}
