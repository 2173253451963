import { EntityMetric } from '@/lib/enums';

const defaultMetricList = [
  EntityMetric.GROUPS,
  EntityMetric.ROLES,
  EntityMetric.ACTIVITIES,
  EntityMetric.HOURS,
  EntityMetric.FTE,
  EntityMetric.BUDGET,
];

export default defaultMetricList;
