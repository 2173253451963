import { useEffect, useState } from 'react';
import { mapDesign } from '@/shared/utils';
import { Designs, Library, Revisions } from '@pkg/entities';
import ComparisonColors from '@/lib/theme/comparisonColors';
import { ADDITIONAL_DESIGN } from './slices/data';
import useDesignStore from './useDesignStore';

/**
 * Loads a design and revision from provided ids and adds to 'additional' array
 * in the design store
 * @param {String} designId
 * @returns {Object}
 */
export default function useAdditionalDesignData() {
  const [designId, setDesignId] = useState(null);
  const [revisionId, setRevisionId] = useState(null);
  const [entity, setEntity] = useState(null);
  const [isActiveComparison, setIsActiveComparison] = useState(false);
  const [trigger, setTrigger] = useState(false);

  const library = Library.useLibrary();
  const set = useDesignStore((state) => state.set);
  const additional = useDesignStore((state) => state.additional);

  // get designs from IndexedDB
  const { hydrated, isHydrating } = Designs.useHydrated();

  // get design from hydrated matching designId
  const design =
    hydrated.main?.uuid === designId
      ? hydrated?.main
      : hydrated?.scenarios.map.get(designId);

  const latestId = design?.latest?.uuid;
  const fetchId = revisionId === 'latest' ? latestId : revisionId;

  const { revision, isLoading } = Revisions.useRevision(fetchId);

  useEffect(() => {
    if (isHydrating || isLoading || !design || !revision) {
      return;
    }

    const derivedRevision = Revisions.deriveProps(library, {
      ...revision,
      is_historical: revision.uuid !== latestId,
    });

    const additionalDesign = structuredClone(ADDITIONAL_DESIGN);
    additionalDesign.design = design;
    additionalDesign.revision = derivedRevision;
    additionalDesign.snapshot = derivedRevision?.snapshot;
    additionalDesign.snapshotEntityMap = mapDesign(
      design,
      derivedRevision?.snapshot
    );
    additionalDesign.entity = entity;
    additionalDesign.comparisonColor = ComparisonColors[design.type];

    const updated = additional.filter(
      (existing) => existing.revision?.uuid !== revision?.uuid
    );
    updated.push(additionalDesign);

    const storeUpdates = { additional: updated };
    if (isActiveComparison) {
      storeUpdates.activeComparisonIndex = updated.length - 1;
    }

    set(storeUpdates);
  }, [isHydrating, isLoading, revision?.uuid, trigger]);

  const revisionInStore = (designId, revisionId) => {
    return additional.findIndex(
      (item) =>
        item.design.uuid === designId && item.revision.uuid === revisionId
    );
  };

  const loadRevision = (
    newDesignId,
    newRevisionId,
    entity,
    isActiveComparison
  ) => {
    const index = revisionInStore(designId, revisionId);
    // if requested revision is already in store, set it as active
    if (index !== -1) {
      if (isActiveComparison) {
        set({ activeComparisonIndex: index });
      }
      return;
    }

    setDesignId(newDesignId);
    setRevisionId(newRevisionId);
    setEntity(entity);
    setIsActiveComparison(isActiveComparison);
    setTrigger((prev) => !prev);
  };

  return loadRevision;
}
