import { Size } from '@/atoms/enums';

const TextMetricSize = Object.freeze({
  [Size.XX_SMALL]: {
    value: {
      fontSize: '0.775rem',
      fontWeight: 700,
      lineHeight: '0.875rem',
    },
    label: {
      fontSize: '0.725rem',
      lineHeight: '0.875rem',
    },
  },
  [Size.X_SMALL]: {
    value: {
      fontSize: '0.875rem',
      fontWeight: 700,
      lineHeight: '1.05rem',
    },
    label: {
      fontSize: '0.825rem',
      lineHeight: '1.125rem',
    },
  },
  [Size.SMALL]: {
    value: {
      fontSize: '0.925rem',
      fontWeight: 700,
      lineHeight: '1.25rem',
    },
    label: {
      fontSize: '0.875rem',
      lineHeight: '1.125rem',
    },
  },
  [Size.MEDIUM]: {
    value: {
      fontSize: '1.25rem',
      fontWeight: 700,
      lineHeight: '1.625rem',
    },
    label: {
      fontSize: '0.925rem',
      lineHeight: '1.25rem',
    },
  },
  [Size.LARGE]: {
    value: {
      fontSize: '1.75rem',
      fontWeight: 700,
      lineHeight: '2.25rem',
    },
    label: {
      fontSize: '0.925rem',
      lineHeight: '1.25rem',
    },
  },
  [Size.X_LARGE]: {
    value: {
      fontSize: '2rem',
      fontWeight: 700,
      lineHeight: '2.25rem',
    },
    label: {
      fontSize: '0.925rem',
      lineHeight: '1.25rem',
    },
  },
});

export default TextMetricSize;
