import { Num, Obj } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';
import DefaultSnapshotMetrics from '../enums/DefaultSnapshotMetrics';
import make from '../make';
import { getFlattened, getHierarchy } from '../utils';
import activities from './activities';
import groups from './groups';
import people from './people';
import roles from './roles';
import roundFloatMetrics from './shared/roundFloatMetrics';

/**
 * @param {Object} snapshot
 * @param {Object} library
 * @return {Object}
 */
export default function deriveSnapshot(library, snapshot) {
  if (Obj.isEmpty(snapshot)) {
    return snapshot;
  }

  // setup
  console.groupCollapsed('Snapshots.deriveProps');
  console.time('Snapshots.deriveProps');
  const complete = make(snapshot);
  const derived = structuredClone(getHierarchy(complete));

  const metrics = new Map();
  metrics.set('*', structuredClone(DefaultSnapshotMetrics));

  // derive entities
  console.time('Snapshots.deriveProps.entities.derive');
  activities.derive(library, derived, metrics);
  roles.derive(library, derived, metrics);
  groups.derive(library, derived, metrics);
  people.derive(library, derived, metrics);
  console.timeEnd('Snapshots.deriveProps.entities.derive');

  // apply
  console.time('Snapshots.deriveProps.entities.apply');
  activities.apply(derived, metrics);
  roles.apply(derived, metrics);
  groups.apply(derived, metrics);
  people.apply(derived, metrics);
  console.timeEnd('Snapshots.deriveProps.entities.apply');

  // finalize
  const root = roundFloatMetrics(metrics.get('*'), false);
  derived.__metrics = root;
  derived.__percentage = null;
  derived.__type = DesignEntity.ORGANISATION;
  derived.__visibility = derived.__visibility ?? Visibility.FULL;

  if (Number.isFinite(root.total.hours)) {
    derived.__percentage = Num.percent(root.visible.hours, root.total.hours);
  }

  /** @deprecated */
  derived.__total_metrics = root.total;
  derived.__visible_metrics = root.visible;

  console.timeEnd('Snapshots.deriveProps');
  console.groupEnd('Snapshots.deriveProps');
  return getFlattened(derived);
}
