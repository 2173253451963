import { produce } from 'immer';

export const INITIAL_ACTIONS_STATE = Object.freeze({
  actions: {
    dispatch: null,
    export: null,
    home: null,
    mutate: null,
    restore: null,
    select: null,
  },
});

export default function createActionsSlice(set) {
  return {
    ...INITIAL_ACTIONS_STATE,
    setActions: (actions) =>
      set(
        produce((state) => {
          Object.entries(actions).forEach(([key, action]) => {
            state.actions[key] = action;
          });
        })
      ),
  };
}
