import InsightTab from './enums/InsightTab';

const InsightTabOptions = [
  {
    id: InsightTab.SUMMARY,
    label: 'Summary',
  },
  {
    id: InsightTab.CHANGELOG,
  },
];

export default InsightTabOptions;
