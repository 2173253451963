import { usePresence } from '@pkg/broadcasting';
import useDesignStore from '../../hooks/useDesignStore';

/**
 * @param {String} uuid
 * @returns {Object}
 */
export default function useDesignPresence() {
  const designId = useDesignStore((state) => state.design?.uuid);
  const channelName = designId ? `Design.presence.${designId}` : '';
  return usePresence({ channelName });
}
