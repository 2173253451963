import Workload from '@pkg/insights/WorkHealth/Workload';
import { Completeness } from '@/lib/enums';

export default function deriveCompleteness(snapshot) {
  if (!snapshot) {
    return null;
  }

  console.time('Snapshots.deriveCompleteness');
  const derived = { ...snapshot };
  const groupCompleted = new Map();

  // determine if roles are complete
  derived.entities.roles.forEach((role) => {
    const isTagged = Boolean(Object.keys(role.__tags).length);
    const workload = Workload.calculateWorkload(
      role.__total_metrics.hours,
      role.fte
    );
    role.__is_complete = isTagged && workload.percent >= 80;

    if (role.__is_complete) {
      const currentTotal = groupCompleted.get(role.group_uuid) ?? 0;
      groupCompleted.set(role.group_uuid, currentTotal + 1);
    }
  });

  // determine level of group completion
  derived.entities.groups.forEach((group) => {
    const completeRoles = groupCompleted.get(group.uuid) ?? 0;
    const totalRoles = group.__total_metrics.roles;

    if (completeRoles === 0) {
      group.__completeness = Completeness.NONE;
      return;
    }

    if (completeRoles !== totalRoles) {
      group.__completeness = Completeness.PARTIAL;
      return;
    }

    group.__completeness = Completeness.FULL;
  });

  console.timeEnd('Snapshots.deriveCompleteness');
  return derived;
}
