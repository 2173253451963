import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { ViewMode } from '@/shared/enums';
import { useUserRelatedEntities } from '@/shared/hooks';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import buildComparisonPath from '@/shared/utils/buildComparisonPath';
import { Routes } from '@pkg/utils';
import { ChipType, DesignEntity } from '@/lib/enums';

const useScenarioList = (entityType) => {
  const { scenario } = useScenarioContext();
  const scenarioEntities = useUserRelatedEntities();
  const entities =
    scenarioEntities[
      DesignEntity.toPlural(
        entityType === DesignEntity.ORGANISATION
          ? DesignEntity.DESIGN
          : entityType
      )
    ];
  const [scenarios, setScenarios] = useState();
  const router = useRouter();

  const routeToScenario = ({ entity, scenarioId, designId }) => {
    let path;

    if (scenarioId) {
      path = buildComparisonPath(entity.uuid, scenarioId, designId);
    } else {
      path = Routes.build.designParts({
        designId,
        level: {
          type: entity.__type,
          uuid: entity.uuid,
        },
        params: {
          view: ViewMode.DIAGRAM,
        },
      });
    }

    if (path) {
      router.push(path);
    }
  };

  useEffect(() => {
    if (!entities) {
      return;
    }

    const scenarios = [];

    const scenarioMap = entities.find(
      ({ uuid }) => uuid === scenario?.entity?.uuid
    )?.__scenarios;

    if (!scenarioMap) {
      setScenarios(scenarios);
      return;
    }

    const liveDesign = [...scenarioMap.values()].find(({ __isScenario }) => {
      return !__isScenario;
    });

    scenarioMap.forEach(({ __isScenario, __designId, name, title }) => {
      if (__designId === scenario?.details?.designId) {
        return;
      }

      const chipType = __isScenario ? ChipType.SCENARIO : ChipType.LIVE;

      scenarios.push({
        label: title ?? name,
        id: __designId,
        designId: liveDesign.__designId,
        scenario: {
          designId: __designId,
          level: {
            __type: entityType,
            uuid: scenario?.entity?.uuid,
          },
        },
        startIcon: !__isScenario ? (
          <EntityChip size={Size.SMALL} type={chipType} />
        ) : null,
      });
    });

    setScenarios(scenarios);
  }, [entities]);

  return {
    routeToScenario,
    scenarios,
  };
};

export default useScenarioList;
