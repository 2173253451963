import { GET_PEOPLE_SKILLS } from '@pkg/entities/library/skills/usePeopleSkills';
import { useMutation } from '@pkg/graphql';

const UPDATE_USER = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      uuid
    }
  }
`;

export default function useUpdate(query) {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_USER,
    invalidateQueries: [query, GET_PEOPLE_SKILLS],
  });

  /**
   * @param {String} uuid
   * @param {Object} input
   * @returns {Object|null}
   */
  async function update(uuid, input) {
    const { data } = await mutateAsync({ uuid, ...input });
    return data?.updateUser;
  }

  return update;
}
