import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const EmptyPlanList = ({ message }) => {
  return (
    <Stack alignItems="center" justifyContent="center" height="80%">
      <Paragraph size={Size.LARGE}>{message}</Paragraph>
    </Stack>
  );
};

export default EmptyPlanList;
