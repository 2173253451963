import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const MIN_HEIGHT = 4;

const Bar = ({ colors, height, orientation, percentage, value, width }) => {
  const isPositive = value ? value > 0 : percentage > 0;
  const foreground = height * (Math.abs(percentage) / 100);
  const foregroundHeight = foreground > MIN_HEIGHT ? foreground : MIN_HEIGHT;
  const background = height - foregroundHeight - 2;
  const backgroundHeight = background > MIN_HEIGHT ? background : MIN_HEIGHT;
  return (
    <Stack direction="column" height={height} width={width}>
      <Box
        borderRadius="2px"
        height={`${foregroundHeight}px`}
        sx={{
          backgroundColor: colors?.background
            ? isPositive
              ? colors?.foreground
              : colors.background
            : 'transparent',
        }}
        width={width}
      />
      <Box
        borderRadius="2px"
        height={`${backgroundHeight}px`}
        mt={0.25}
        sx={{
          backgroundColor: colors.background
            ? isPositive
              ? colors.background
              : colors.foreground
            : 'transparent',
        }}
        width={width}
      />
    </Stack>
  );
};

export default Bar;
