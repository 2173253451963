import { DimensionType, PresetType } from '@/molecules/tableElements/enums';
import AssignedRolesFilterPreset from './AssignedRolesFilterPreset';
import UnassignedRolesFilterPreset from './UnassignedRolesFilterPreset';

const ActivityFilterPresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'Reset filters',
    },
  ],
  [
    PresetType.EXCLUDE_UNASSIGNED,
    {
      label: 'Only show allocated activities',
      preset: AssignedRolesFilterPreset,
      defaultOption: true,
    },
  ],
  [
    PresetType.EXCLUDE_ASSIGNED,
    {
      label: 'Only show unallocated activities',
      preset: UnassignedRolesFilterPreset,
    },
  ],
]);

const FilterPresetMap = new Map([
  [DimensionType.ACTIVITY, ActivityFilterPresets],
  [DimensionType.ACTIVITY_TAGS_ACTIVITY, ActivityFilterPresets],
  [DimensionType.ROLE_TAGS_ACTIVITY, ActivityFilterPresets],
  [DimensionType.GROUP_TAGS_ACTIVITY, ActivityFilterPresets],
]);

export default FilterPresetMap;
