import { useCallback, useEffect, useState } from 'react';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';

const GET_SKILLS = /* GraphQL */ `
  query GetSkills {
    me {
      organisation {
        skills {
          uuid
          name
        }
      }
    }
  }
`;

export default function useRefresh() {
  const client = useClient();
  const database = useDatabase();
  const [isRefreshing, setRefreshing] = useState(false);

  const refresh = useCallback(() => {
    if (!isRefreshing) {
      setRefreshing(true);
    }
  }, []);

  const handleRefresh = async () => {
    const { data } = await client.graphql({ query: GET_SKILLS });

    if (!data?.me) {
      setRefreshing(false);
      return;
    }

    const newSkills = data.me.organisation.skills ?? [];
    const newSkillsSet = new Set(newSkills.map(({ uuid }) => uuid));
    const skills = await database.skills.toArray();

    const removedSkills = [];
    skills.forEach(({ uuid }) => {
      if (!newSkillsSet.has(uuid)) {
        removedSkills.push(uuid);
      }
    });

    await Promise.all([
      database.hash.put({ key: 'skills', updated_at: Date.now() }),
      database.skills.bulkDelete(removedSkills),
      database.skills.bulkPut(newSkills),
    ]);

    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return {
    refresh,
    isRefreshing,
  };
}
