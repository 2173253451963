import { useMemo } from 'react';
import { roles } from '../get';

/**
 * @param {Object} me
 * @param {Number} [hash]
 * @returns {Object}
 */
export default function useRoles(me, hash = null) {
  const dependency = hash ?? JSON.stringify(me?.access.roles);
  return useMemo(() => {
    return roles(me);
  }, [dependency]);
}
