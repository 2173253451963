import { useMutation } from '@pkg/graphql';

const DELETE_TEMPLATE = /* GraphQL */ `
  mutation DeleteTemplate($input: DeleteTemplateInput!) {
    deleteTemplate(input: $input) {
      uuid
    }
  }
`;

/**
 * @return {Function}
 */
export default function useDelete() {
  const { mutateAsync } = useMutation({
    mutation: DELETE_TEMPLATE,
  });

  /**
   * @param {String} organisation_uuid
   * @param {Object} input
   * @param {String} input.uuid
   * @returns {Promise}
   */
  async function deleteTemplate(organisation_uuid, { uuid }) {
    return mutateAsync({ organisation_uuid, uuid });
  }

  return deleteTemplate;
}
