import { Num } from '@pkg/utils';

const pattern = /[^\d.]+/g;

/**
 * @param {String} value
 * @param {Object} options
 * @param {Number} options.max
 * @returns {Float}
 */
export function blur(value, options = {}) {
  value = parseFloat(change(value)) || 0;
  value = Num.roundFloat(value);

  if (Number.isFinite(options.max)) {
    value = Math.min(value, options.max);
  }

  return value;
}

/**
 * @param {String} value
 * @returns {String}
 */
export function change(value) {
  if (!value) {
    return '';
  }

  return value.replace(pattern, '');
}

/**
 *
 * @param {Float} value
 * @param {Number} fractionDigits
 * @returns {String}
 */
export function format(value, fractionDigits = 2) {
  value = parseFloat(value);

  if (!Number.isFinite(value)) {
    return '';
  }

  return value.toFixed(fractionDigits);
}
