import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object[]} input
 * @returns {Array}
 */
export default function add(snapshot, activities) {
  const now = Date.now();

  const firstActivity = activities[0];

  const activityMutation = Snapshots.reducers.activities.add(
    snapshot,
    activities
  );

  const entities = snapshot.entities;
  const role = Collections.findById(entities.roles, firstActivity.owner_uuid);

  const newActivities = activityMutation.entities.activities.create;

  const roleActivities = Collections.where(
    entities.activities,
    'owner_uuid',
    role.uuid
  );

  const updatedSnapshot = {
    entities: {
      groups: [],
      roles: [{ ...role, updated_at: now }],
      activities: [...roleActivities, ...newActivities],
    },
  };

  return {
    uuid: role.__template_uuid,
    snapshot: updatedSnapshot,
  };
}
