/**
 * @param {Object} user
 * @param {String} context
 * @param {String} entityType
 * @param {String} relationship
 * @param {String} entityId
 * @returns {String}
 */
export default function getRole(
  user,
  context,
  entityType,
  relationship,
  entityId
) {
  return (
    user?.access.roles[context]?.find((role) => {
      return role === `${entityType.toLowerCase()}.${relationship}.${entityId}`;
    }) ?? null
  );
}
