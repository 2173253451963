import copy from 'fast-copy';
import { indexBy } from './indexBy';

function updateItem(collection, item, key) {
  const copied = copy(collection);
  const index = indexBy(copied, item[key], key);

  if (index === -1) {
    return copied;
  }

  copied[index] = {
    ...copied[index],
    ...item,
  };

  return copied;
}

/**
 * @param {Object[]} collection
 * @param {Object|Object[]} itemOrItems
 * @param {String} key
 * @return {Object[]}
 */
export function updateBy(collection, itemOrItems, key) {
  if (!collection) {
    return [];
  }

  if (Array.isArray(itemOrItems)) {
    let result = collection;

    itemOrItems.forEach((item) => {
      result = updateItem(result, item, key);
    });

    return result;
  }

  return updateItem(collection, itemOrItems, key);
}

/**
 * @param {Object[]} collection
 * @param {Object|Object[]} itemOrItems
 * @return {Object[]}
 */
export function updateById(collection, itemOrItems) {
  return updateBy(collection, itemOrItems, 'uuid');
}
