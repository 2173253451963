import { useRouter } from 'next/router';
import { Avatar } from '@/atoms/avatars';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { EventName, EventProperty } from '@/shared/enums';
import { EntityType } from '@/shared/enums';
import { useEntityNavigation } from '@/shared/hooks';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Analytics } from '@pkg/analytics';
import { brand } from '@/lib/theme/tokens/palettes';

const InsightMenuItem = ({
  allowDoubleClick,
  entityId,
  item,
  onClick,
  scopeEntity,
  selected,
}) => {
  const router = useRouter();
  const { navigateToEntity } = useEntityNavigation();
  const isSelected = selected === item.id;
  const canNavigate =
    item.id !== 'ALL' &&
    entityId !== item.id &&
    scopeEntity !== EntityType.ACTIVITY &&
    allowDoubleClick;

  const handleClick = (event) => {
    // Handle double click events to navigate to the menu item.
    if (event.detail === 2 && canNavigate) {
      handleNavigateToEntity();
      return;
    }

    onClick?.(item);
  };

  const handleNavigateToEntity = () => {
    navigateToEntity({
      type: scopeEntity,
      uuid: item.id,
    });
    Analytics.track(EventName.NAVIGATED_TO_ENTITY, {
      [EventProperty.ENTRY]: 'Insights menu',
      [EventProperty.VIEW_MODE]: 'Insights',
    });
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={handleClick}
      p={1}
      sx={{
        backgroundColor: isSelected ? brand.lightBlue.tints[4] : 'transparent',
        position: 'relative',
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: brand.lightBlue.tints[2],
          '& > .MuiBox-root': {
            opacity: 1,
          },
        },
      }}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        {item.person && (
          <Avatar
            src={item.person.avatar}
            name={item.person.first_name}
            size={Size.SMALL}
          />
        )}
        <Box>
          <Paragraph
            overrideStyles={{
              mb: 0.5,
              fontWeight: isSelected ? 600 : item.person ? 500 : 400,
            }}
            size={Size.SMALL}
          >
            {item.label}
          </Paragraph>
          {item.person && (
            <Paragraph size={Size.X_SMALL} overrideStyles={{ mb: 0 }}>
              {item.person.first_name} {item.person.last_name}
            </Paragraph>
          )}
        </Box>
      </Stack>
      {canNavigate && (
        <Box
          position="absolute"
          top="50%"
          right={0}
          px={0.5}
          sx={{
            backgroundColor: brand.lightBlue.tints[2],
            opacity: 0,
            transform: 'translateY(-50%)',
          }}
        >
          <Button
            boldText
            label="View"
            color="secondary"
            onClick={handleNavigateToEntity}
            size={Size.X_SMALL}
          />
        </Box>
      )}
    </Stack>
  );
};

export default InsightMenuItem;
