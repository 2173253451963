import { useEffect, useState } from 'react';
import { useDesignsContext } from '@/shared/providers/DesignsProvider';
import checkIsSupporter from '@/shared/utils/checkIsSupporter';
import * as Auth from '@pkg/auth';

const useIsSupporter = (entityId) => {
  const { me } = Auth.useStore();
  const { supporterMap } = useDesignsContext();

  const [isSupporter, setIsSupporter] = useState(null);

  useEffect(() => {
    if (!entityId || !supporterMap?.size || !me) {
      return;
    }

    setIsSupporter(checkIsSupporter(entityId, me.uuid, supporterMap));
  }, [entityId, supporterMap]);

  return isSupporter;
};

export default useIsSupporter;
