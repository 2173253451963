import { useState } from 'react';
import FormLabel from '@mui/material/FormLabel';
import { styled } from '@mui/material/styles';
import Button from '@/components/Button';

const Root = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  rowGap: '30px',
  justifyContent: 'center',
  minHeight: 230,
  maxHeight: '100%',
  '@media (max-width: 960px)': {
    height: '100%',
  },
});

const FileInput = ({ accept, onChange }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const handleChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      if (accept.includes(event.target.files[0].type)) {
        onChange(event.target.files[0]);
      } else {
        setErrorMessage('Please select a valid image file');
      }
    }
  };
  return (
    <Root>
      {errorMessage && (
        <div>
          <FormLabel error={true}>{errorMessage}</FormLabel>
        </div>
      )}
      <input
        accept={accept}
        style={{ display: 'none' }}
        id="avatar-file-input"
        type="file"
        onChange={handleChange}
      />
      <label htmlFor="avatar-file-input">
        <Button variant="outlined" component="span" color="primary">
          Select an image
        </Button>
      </label>
    </Root>
  );
};

export default FileInput;
