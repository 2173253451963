import { Num } from '@pkg/utils';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';

class Workload {
  name = 'Workload';

  apply(entityType, entityData) {
    return this.applyToEntity[entityType](entityData, this.calculateWorkload);
  }

  calculateWorkload(actualHours, fte) {
    const expectedHours = config.FTE_HOURS_PER_WEEK * fte;
    const workloadPercent = Num.percent(actualHours, expectedHours, 2);

    return {
      hours: actualHours - expectedHours,
      percent: workloadPercent,
    };
  }

  reset() {
    return;
  }

  applyToEntity = {
    [DesignEntity.ACTIVITY]: function () {
      return;
    },
    [DesignEntity.ROLE]: function ({ role, roleMetrics }, calculateWorkload) {
      const actualHours = roleMetrics.total.hours ?? 0;
      return calculateWorkload(actualHours, role.fte);
    },
    [DesignEntity.GROUP]: function ({ groupMetrics }, calculateWorkload) {
      const actualHours = groupMetrics.total.hours;
      return calculateWorkload(actualHours, groupMetrics.total.fte);
    },
    [DesignEntity.ORGANISATION]: function (
      { snapshotMetrics },
      calculateWorkload
    ) {
      const actualHours = snapshotMetrics.total.hours;
      return calculateWorkload(actualHours, snapshotMetrics.total.fte);
    },
  };
}

export default new Workload();
