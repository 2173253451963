export default function collaboratorAccessOptions(designId) {
  const accessOptionMap = new Map();

  if (!designId) {
    return accessOptionMap;
  }

  // Viewer access.
  const viewerKey = `design.viewer.${designId}`;
  accessOptionMap.set(viewerKey, {
    value: viewerKey,
    label: 'Can view',
  });

  // Editor acces.
  const editorKey = `design.editor.${designId}`;
  accessOptionMap.set(editorKey, {
    value: editorKey,
    label: 'Can edit',
  });

  // Remove collaborator.
  const noAccessKey = 'design.noAccess';
  accessOptionMap.set(noAccessKey, {
    value: noAccessKey,
    label: 'Remove access',
  });

  return accessOptionMap;
}
