import { DimensionType } from '@/molecules/tableElements';
import { DesignEntity, Visibility } from '@/lib/enums';
import {
  formatDesignData,
  formatGroupData,
  formatPersonData,
  formatRoleData,
  formatSkillData,
  getEntities,
} from './formatTableData';

/**
 * Returns rows for each person that isn't assigned to a role.
 * @param {Object}
 *
 * @return {Array}
 */
const addRemainingPeople = ({
  dimension,
  groupLeads,
  people,
  peopleSkillMap,
  propertyMap,
  skillMap,
  scenarioMap,
  tagMap,
}) => {
  const rows = [];

  people.forEach((uuid) => {
    const person = scenarioMap.get(DesignEntity.PERSON).get(uuid);
    if (person.__visibility === Visibility.NONE) {
      return;
    }
    const personSkills = peopleSkillMap.get(uuid);
    const design = scenarioMap?.get(DesignEntity.ORGANISATION);
    const groups = groupLeads.get(person.uuid)?.keys();

    const group = groups
      ? scenarioMap?.get(DesignEntity.GROUP)?.get([...groups][0])
      : undefined;

    const groupLead = scenarioMap
      .get(DesignEntity.PERSON)
      ?.get(group?.lead_uuid);

    // If the person doesn't have any skills we still want to record them
    // so they can show up as blank skills.
    if (!personSkills?.size) {
      rows.push({
        ...formatSkillData({ dimension }),
        ...formatPersonData({ person, skillMap }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design),
      });
      return;
    }

    personSkills.forEach((key) => {
      const skill = skillMap.get(key);
      const rowData = {
        ...formatSkillData({ dimension, skill }),
        ...formatPersonData({ person, skillMap }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design),
      };
      rows.push(rowData);
    });
  });

  return rows;
};

/**
 * Takes an array of scenario maps and converts them into tabular skill data.
 *
 * @param {Array}
 * @return {Object}
 */
export default function formatSkillTableData({
  showBudget,
  dimension,
  scenarioMaps,
  peopleSkillMap,
  propertyMap,
  skillMap,
  tagMap,
}) {
  const rows = [];

  // We maintain a list of people so we can add them if they aren't assigned to
  // a role. This also includes group leads.
  const peopleList = [];
  const groupLeads = new Map();

  scenarioMaps.forEach((scenarioMap) => {
    peopleList.push(...scenarioMap.get(DesignEntity.PERSON).keys());

    scenarioMap.get(DesignEntity.GROUP).forEach((group) => {
      if (group.__visibility === Visibility.NONE) {
        return;
      }
      if (group.lead_uuid) {
        const existing =
          groupLeads.get(group.lead_uuid)?.add(group.uuid) ??
          new Set([group.uuid]);

        groupLeads.set(group.lead_uuid, existing);
      }
    });
  });

  const people = new Set(peopleList);

  // We loop through each role in every scenario to extract skills associated
  // with each person in relation to their role.
  scenarioMaps?.forEach((scenarioMap) => {
    const roleList = [...scenarioMap.get(DesignEntity.ROLE).values()].filter(
      ({ __visibility }) => __visibility !== Visibility.NONE
    );
    const roles = new Set([roleList.map(({ uuid }) => uuid)]);

    roleList.forEach((role) => {
      const { design, group, groupLead, person } = getEntities({
        role,
        scenarioMap,
      });
      const roleSkills = role?.__skill_map;
      const personSkills = peopleSkillMap.get(role?.user_uuid);

      people.delete(person?.uuid);
      roles.delete(role?.uuid);

      if (!person && dimension === DimensionType.PEOPLE_SKILLS) {
        return;
      }

      // This determines whether we are basing our rows on the skills for
      // each person or the skills for each role.
      const skills =
        dimension === DimensionType.PEOPLE_SKILLS ? personSkills : roleSkills;

      let rowData = {
        ...formatRoleData({
          showBudget,
          person,
          role,
          propertyMap,
          tagMap,
          skillMap,
        }),
        ...formatGroupData({ group, groupLead, propertyMap, tagMap }),
        ...formatDesignData(design),
        ...formatPersonData({ person, personSkills, skillMap }),
      };

      // If the person doesn't have any skills we still want to record them
      // so they can show up as blank skills.
      if (!skills?.size) {
        rowData = {
          ...formatSkillData({ dimension, personSkills, roleSkills }),
          ...rowData,
        };

        rows.push(rowData);
        return;
      }

      skills.forEach((skillLevel, key) => {
        const skill = skillMap.get(key);
        rows.push({
          ...formatSkillData({ dimension, personSkills, roleSkills, skill }),
          ...rowData,
        });
      });
    });

    // If we are viewing people skills, we also want capture people that aren't
    // assigned to a role or might only be assigned as a group lead.
    if (dimension === DimensionType.PEOPLE_SKILLS) {
      const addedPeople = addRemainingPeople({
        dimension,
        groupLeads,
        people,
        peopleSkillMap,
        propertyMap,
        skillMap,
        tagMap,
        scenarioMap,
      });

      rows.push(...addedPeople);
    }
  });

  return rows;
}
