import * as d3 from 'd3';
import { SubtitleColor } from '@/lib/theme/dashboardColors';
import { palettes } from '@/lib/theme/tokens';

const CHART_BUFFER = 20;
const CHART_PADDING = 48;

export default function renderCenterAxis(svg, properties) {
  const { classes, height, labels, width, x0 = 0 } = properties;

  if (!width || !height) {
    return;
  }

  const color = palettes.brand.purple.tints[2];
  const xCenter = x0 + width / 2;

  svg
    .append('defs')
    .append('marker')
    .attr('id', 'arrow')
    .attr('viewBox', [0, 0, 20, 20])
    .attr('refX', 5)
    .attr('refY', 5)
    .attr('markerWidth', 10)
    .attr('markerHeight', 10)
    .attr('orient', 'auto-start-reverse')
    .append('path')
    .attr(
      'd',
      d3.line()([
        [0, 0],
        [0, 10],
        [10, 5],
      ])
    )
    .attr('fill', color)
    .attr('stroke', color);

  // Top label.
  svg
    .append('text')
    .attr('id', 'top-label')
    .classed('Chart--axis-label', true)
    .classed(classes.axisLabel.join(' '), true)
    .attr('y', CHART_BUFFER)
    .attr('x', xCenter)
    .attr('fill', SubtitleColor.text)
    .style('text-anchor', 'middle')
    .text(`${labels.top.title} ${labels.top.subtitle ?? ''}`);

  // Bottom label.
  svg
    .append('text')
    .attr('id', 'bottom-label')
    .classed('Chart--axis-label', true)
    .classed(classes.axisLabel.join(' '), true)
    .attr('y', height - CHART_BUFFER / 2)
    .attr('x', xCenter)
    .attr('fill', SubtitleColor.text)
    .style('text-anchor', 'middle')
    .text(`${labels.bottom.title} ${labels.bottom.subtitle ?? ''}`);

  // Left label.
  svg
    .append('foreignObject')
    .attr('id', 'left-label')
    .classed('Chart--axis-label', true)
    .classed(classes.axisLabel.join(' '), true)
    .attr('y', 0)
    .attr('x', x0 - width + CHART_BUFFER + CHART_PADDING)
    .attr('height', height)
    .attr('width', width - CHART_BUFFER - CHART_PADDING)
    .html(
      `<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-end; height: 100%; width: 100%"><div style="text-align: right; color: ${
        SubtitleColor.text
      }; line-height: 1.5rem;">${labels.left.title}<div>${
        labels.left.subtitle ?? ''
      }</div></div></div>`
    );

  // Right label.
  svg
    .append('foreignObject')
    .attr('id', 'right-label')
    .classed('Chart--axis-label', true)
    .classed(classes.axisLabel.join(' '), true)
    .attr('y', 0)
    .attr('x', x0 + width)
    .attr('height', height)
    .attr('width', width - CHART_BUFFER - CHART_PADDING)
    .html(
      `<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start; height: 100%; width: 100%"><div style="text-align: left; color: ${
        SubtitleColor.text
      }; line-height: 1.5rem;">${labels.right.title}<div>${
        labels.right.subtitle ?? ''
      }</div></div></div>`
    );

  const bottomLabel = d3
    .select('#bottom-label')
    .node()
    ?.getBoundingClientRect();
  const topLabel = d3.select('#top-label').node()?.getBoundingClientRect();

  const xScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([0, width - CHART_BUFFER * 2]);
  const yScale = d3
    .scaleLinear()
    .domain([0, 100])
    .range([
      0,
      height - topLabel.height - bottomLabel.height - CHART_BUFFER * 2,
    ]);

  const xAxis = d3.axisBottom(xScale).tickSizeOuter(0).ticks(0);
  const yAxis = d3.axisLeft(yScale).tickSizeOuter(0).ticks(0);

  // X axis line.
  svg
    .append('g')
    .attr('id', 'center-axis-x')
    .call(xAxis)
    .attr('transform', `translate(${x0 + CHART_BUFFER}, ${height / 2})`);

  svg
    .select(`#center-axis-x path.domain`)
    .attr('stroke', color)
    .attr('stroke-width', '2')
    .attr('marker-start', 'url(#arrow)')
    .attr('marker-end', 'url(#arrow)');

  // Y axis line.
  svg
    .append('g')
    .attr('id', 'center-axis-y')
    .call(yAxis)
    .attr(
      'transform',
      `translate(${xCenter}, ${topLabel.height + CHART_BUFFER})`
    );

  svg
    .select(`#center-axis-y path.domain`)
    .attr('stroke', color)
    .attr('stroke-width', '2')
    .attr('marker-start', 'url(#arrow)')
    .attr('marker-end', 'url(#arrow)');
}
