import { Arr } from '@pkg/utils';
import { TagColor } from '@/lib/enums';

/**
 * @param {Object[]} tags
 * @returns {String}
 */
export default function useNextColor(tags = []) {
  const colorKeys = Object.keys(TagColor);

  const remainingColors = colorKeys.filter(
    (color) => !tags.some((tag) => color === tag.color)
  );

  if (remainingColors.length > 0) {
    return Arr.rand(remainingColors);
  }

  return Arr.rand(colorKeys);
}
