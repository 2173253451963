import { useEffect, useState } from 'react';
import { CancelButton } from '@/atoms/buttons';
import { Button, InfoButton } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import {
  AlignmentInformation,
  PrioritisationInformation,
} from '@/molecules/information';
import { List } from '@/molecules/lists';
import { Popover } from '@/molecules/modals';
import { FilterOptions } from '@/organisms/filters';
import { EntityType } from '@/shared/enums';
import { MatrixPreset } from '@/shared/enums';
import { useTagContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';
import { brand, core } from '@/lib/theme/tokens/palettes';
import MatrixListItem from './MatrixListItem';
import MatrixPresets from './MatrixPresets';

/** Builds a list of tag options respecting the allowed tags list and sorts
 * the results.
 *
 * @param {Map} tagOptions
 * @param {Set} selectedIds
 * @param {Set} allowedTags
 *
 * @return {Array}
 */
const sortTagOptions = ({ tagOptions }) => {
  const sortedOptions = [];

  tagOptions.forEach(({ options }) => {
    if (!options?.length) {
      return;
    }
    sortedOptions.push(...options);
  });

  return sortedOptions;
};

const MatrixSelect = ({ lockedOption, categorised = true, onChange }) => {
  const entityType = EntityType.ACTIVITY;
  const { entityTagMap } = useTagContext();
  const tagOptions = entityTagMap?.get(entityType);
  const [options, setOptions] = useState(null);
  const [firstOption, setFirstOption] = useState(lockedOption ?? null);
  const [selected, setSelected] = useState(firstOption);
  const [secondOption, setSecondOption] = useState();
  const isMatrixPresetSelected =
    firstOption?.id === MatrixPreset.PRIORITISATION ||
    firstOption?.id === MatrixPreset.ALIGNMENT;
  const isLocked = Boolean(lockedOption);

  const handleChange = (value, position) => {
    if (position === 0) {
      setFirstOption(value);
      if (
        value.id === MatrixPreset.PRIORITISATION ||
        value.id === MatrixPreset.ALIGNMENT
      ) {
        const { ids, ...presetTitles } = MatrixPresets[value.id];
        onChange?.(ids, presetTitles);
        setSecondOption(null);
        return;
      }

      const updatedSelection = [value.id];

      if (secondOption) {
        updatedSelection.push(secondOption.id);
      }
      onChange?.(updatedSelection);

      return;
    }

    setSecondOption(value);
    onChange?.([firstOption.id, value.id]);
  };

  const handleClear = () => {
    setSecondOption(null);
    onChange?.([firstOption.id]);
  };

  useEffect(() => {
    if (!options?.length) {
      return;
    }

    if (!lockedOption) {
      setFirstOption(options[0]);
      setSelected(options[0]);
      return;
    }

    const firstOption = {
      id: lockedOption?.uuid,
      value: lockedOption?.uuid,
      properties: {
        color: lockedOption?.color,
        description: lockedOption?.description,
        name: lockedOption?.name,
      },
    };

    setFirstOption(firstOption);

    if (!selected) {
      setSelected(firstOption);
    }
  }, [lockedOption, options]);

  // This effect sets up the default list of options sorted with checked tags
  // at the start of the list.
  useEffect(() => {
    if (!tagOptions || options) {
      return;
    }

    const sortedOptions = sortTagOptions({ tagOptions });

    setOptions(sortedOptions);
  }, [tagOptions]);

  return (
    firstOption && (
      <Stack alignItems="center" direction="row" spacing={1}>
        {isLocked && (
          <Button
            color={firstOption ? 'light-blue' : 'secondary'}
            size={Size.MEDIUM}
            label={
              firstOption?.label ??
              firstOption?.properties?.name ??
              'Select a tag'
            }
          />
        )}
        {!isLocked && (
          <Popover
            Component={
              <Button
                color={firstOption ? 'light-blue' : 'secondary'}
                size={Size.MEDIUM}
                label={
                  firstOption?.label ??
                  firstOption?.properties?.name ??
                  'Select a tag'
                }
              />
            }
            position={Position.BOTTOM_LEFT}
          >
            <FilterOptions closeOnClick showClose={false}>
              {options?.length > 0 && (
                <List
                  onClick={handleChange}
                  items={[...options].filter(
                    ({ id }) => id !== secondOption?.id
                  )}
                  ListItem={<MatrixListItem position={0} />}
                  showDividers
                />
              )}
            </FilterOptions>
          </Popover>
        )}
        {isMatrixPresetSelected && (
          <Popover
            Component={
              <InfoButton
                colorProps={{
                  backgroundColor: brand.yellow.main,
                  color: core.white,
                }}
                size={Size.X_SMALL}
              />
            }
            position={Position.BOTTOM_LEFT}
          >
            <>
              {firstOption.id === MatrixPreset.PRIORITISATION && (
                <PrioritisationInformation />
              )}
              {firstOption.id === MatrixPreset.ALIGNMENT && (
                <AlignmentInformation />
              )}
            </>
          </Popover>
        )}
        {!isMatrixPresetSelected && (
          <>
            <Paragraph>and</Paragraph>
            <Popover
              Component={
                <Button
                  color={secondOption ? 'light-blue' : 'secondary'}
                  size={Size.MEDIUM}
                  label={
                    secondOption?.label ??
                    secondOption?.properties?.name ??
                    'Select another tag'
                  }
                />
              }
              position={Position.BOTTOM_LEFT}
            >
              <FilterOptions closeOnClick showClose={false}>
                {options?.length > 0 && (
                  <List
                    onClick={handleChange}
                    items={options.filter(({ id }) => id !== firstOption.id)}
                    ListItem={<MatrixListItem position={1} />}
                    showDividers
                  />
                )}
              </FilterOptions>
            </Popover>
            {secondOption && <CancelButton onClick={handleClear} />}
          </>
        )}
      </Stack>
    )
  );
};

export default MatrixSelect;
