import Chart from '@/components/Chart';
import useAggregateActivitiesChartData from './useAggregateActivitiesChartData';

const AggregateActivitiesChart = ({ initialActivityCount }) => {
  const chartData = useAggregateActivitiesChartData(initialActivityCount);
  if (!chartData) {
    return null;
  }

  return (
    <Chart
      type="doughnut"
      data={chartData}
      options={{
        aspectRatio: 1,
        cutout: '66%',
        responsive: true,
        layout: {
          autoPadding: true,
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
            ticks: {},
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            displayColors: true,
            callbacks: {
              title: () => '',
              label: (context) => context.label,
            },
          },
        },
      }}
    />
  );
};

export default AggregateActivitiesChart;
