import config from '@/lib/config';

/**
 * @param {String} prefix
 * @param {String} name
 * @returns {String}
 */
export default function prefix(prefix, name) {
  return name.startsWith(prefix) ||
    name.length + prefix.length > config.VALUE_LENGTH.MAX.DESIGN_NAME
    ? name
    : `${prefix} ${name}`;
}
