import { useEffect, useState } from 'react';
import { Designs } from '@pkg/entities';
import mapDesigns from '../utils/mapDesigns';

/**
 * @returns {Map}
 */
export default function useEntityMap() {
  const { hydrated, isHydrating } = Designs.useHydrated();
  const [mapped, setMapped] = useState(new Map());

  const handle = async () => {
    console.time('Insights.useEntityMap');
    const { main, scenarios } = hydrated;
    setMapped(mapDesigns(main, scenarios.list));
    console.timeEnd('Insights.useEntityMap');
  };

  useEffect(() => {
    if (!isHydrating) {
      handle();
    }
  }, [hydrated]);

  return mapped;
}
