import 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { defaultDimension, TableControls } from '@/molecules/tableElements';
import {
  useAggregateFunctions,
  useColumnDefs,
  useRowData,
} from '@/organisms/tables/hooks';
import { useViewModeContext } from '@/shared/providers';

const InsightTable = () => {
  const { scope } = useViewModeContext();
  const initialDimension = defaultDimension(scope);
  const [dimension, setDimension] = useState(initialDimension);
  const rowData = useRowData(dimension);
  const gridRef = useRef();
  const { columnDefs, updateColumnDefs } = useColumnDefs(initialDimension);
  const aggFuncs = useAggregateFunctions();

  const defaultColDef = useMemo(
    () => ({
      enableRowGroup: true,
      enablePivot: true,
      enableValue: true,
      resizable: true,
      sortable: true,
      filter: true,
    }),
    []
  );

  const sideBar = useMemo(
    () => ({
      toolPanels: ['filters', 'columns'],
      defaultToolPanel: 'columns',
    }),
    []
  );

  const statusBar = useMemo(
    () => ({
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' },
        { statusPanel: 'agAggregationComponent', align: 'left' },
      ],
    }),
    []
  );

  /**
   * Updates the metrics when filters are applied.
   */
  const handleFilterChanged = (params) => {
    const { api } = params;

    const model = api.getFilterModel();

    params.context.metrics = {};

    api.refreshCells({
      force: true,
      suppressFlash: true,
      columns: [
        'activity.sumHours',
        'activity.hoursPercentage',
        'activity.percentRoleHours',
        'activity.percentTeamHours',
        'activity.percentOrgHours',
        'activity.cost',
      ],
    });
  };

  /**
   * Updates metrics when columns are changed.
   */
  const handleDisplayedColumnsChanged = (params) => {
    const { api } = params;

    params.context.metrics = {};

    api.refreshCells({
      force: true,
      suppressFlash: true,
      columns: [
        'activity.sumHours',
        'activity.hoursPercentage',
        'activity.percentRoleHours',
        'activity.percentTeamHours',
        'activity.percentOrgHours',
        'activity.cost',
      ],
    });
  };

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
    updateColumnDefs(dimension, gridRef?.current?.api);
  };

  useEffect(() => {
    if (!scope) {
      return;
    }
    setDimension(defaultDimension(scope));
  }, [scope]);

  return (
    <div
      className="ag-theme-alpine"
      style={{ height: `calc(100vh - 178px)`, width: '100%' }}
    >
      <TableControls
        dimension={dimension}
        gridRef={gridRef?.current}
        onDimensionChange={handleDimensionChange}
        scope={scope}
      />
      <AgGridReact
        ref={gridRef}
        aggFuncs={aggFuncs}
        alwaysAggregateAtRootLevel
        defaultColDef={defaultColDef}
        enableCharts={true}
        enableRangeSelection={true}
        columnDefs={columnDefs}
        gridOptions={{
          context: {
            metrics: {
              'activity.hours': {
                total: 0,
              },
            },
          },
        }}
        groupIncludeTotalFooter={true}
        onDisplayedColumnsChanged={handleDisplayedColumnsChanged}
        onFilterChanged={handleFilterChanged}
        popupParent={document.body}
        rowData={rowData}
        rowGroupPanelShow="always"
        rowSelection="multiple"
        showOpenedGroup
        sideBar={sideBar}
        statusBar={statusBar}
        suppressAggFuncInHeader
      />
    </div>
  );
};

export default InsightTable;
