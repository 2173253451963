import { ChartTooltip } from '@/molecules/chartElements';
import { EntityMetric } from '@/lib/enums';
import SegmentCircles from './SegmentCircles';

const CenteredSegment = ({
  color,
  height,
  metric,
  metricTotals,
  onClick,
  row,
  width,
  xPosition,
  yPosition,
}) => {
  const roundedCorners = width > height ? height / 2 : width / 2;

  const circleCount = metric === EntityMetric.SPAN ? row.data.managers : null;

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.(row);
  };

  return (
    width > 0 && (
      <g className="centered-bar-segment" onClick={handleClick}>
        <rect
          height={height + 2}
          width={width}
          x={xPosition}
          y={yPosition}
          rx={roundedCorners}
          ry={roundedCorners}
          fill={color.borderColor}
        />
        <rect
          height={height}
          width={width}
          x={xPosition}
          y={yPosition}
          rx={roundedCorners}
          ry={roundedCorners}
          fill={color.backgroundColor}
        />
        <SegmentCircles
          circleCount={circleCount}
          height={height}
          width={width}
          xPosition={xPosition}
          yPosition={yPosition}
        />
        <ChartTooltip
          item={row}
          maxWidth={500}
          metric={metric}
          metricTotals={metricTotals}
          minWidth={200}
          position="top"
        >
          <foreignObject
            height={height}
            width={width}
            x={xPosition}
            y={yPosition}
          />
        </ChartTooltip>
      </g>
    )
  );
};

export default CenteredSegment;
