import * as Colors from '@/atoms/colors';
import Box from '@mui/material/Box';
import AppLoading from '@/components/AppLoading';
import * as Parts from '@/components/DesignContainer/parts';

export const DesignLayout = ({ children, isLoading, isError }) => {
  return (
    <>
      {isError && !isLoading && <Parts.Error />}
      {!isError && isLoading && <AppLoading />}
      {!isError && !isLoading && (
        <Parts.Layout>
          <Box
            sx={{
              color: Colors.Base.font.primary,
              backgroundColor: Colors.Base.background.secondary,
              height: 'calc(100vh - 78px)',
              display: 'flex',
              overflowY: 'auto',
            }}
          >
            {children}
          </Box>
        </Parts.Layout>
      )}
    </>
  );
};

export default DesignLayout;
