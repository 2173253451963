import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

const CREATE_USER = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      uuid
    }
  }
`;

export default function useCreate(query) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync } = useMutation({
    mutation: CREATE_USER,
    invalidateQuery: query,
  });

  /**
   * @param {String} organisation_uuid
   * @param {String} name
   * @returns {Object|null}
   */
  function createPerson(organisation_uuid, person) {
    enqueueSnackbar(`Adding ${person.first_name} to your organisation`);

    return mutateAsync({
      organisation_uuid,
      ...person,
    });
  }

  return createPerson;
}
