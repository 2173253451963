/**
 * @param {Object} entities
 * @param {Object[]} people
 * @returns
 */
export default function groupsForMembers(entities, people) {
  if (!entities) {
    return {};
  }

  if (!people) {
    people = entities.people;
  }

  const map = people.reduce((acc, { uuid }) => {
    acc[uuid] = [];
    return acc;
  }, {});

  for (const role of entities.roles) {
    if (Object.keys(map).includes(role.user_uuid)) {
      map[role.user_uuid].push(role.group_uuid);
    }
  }

  return map;
}
