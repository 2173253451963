import { brand } from '@/lib/theme/tokens/palettes';

const ProgressPercentageColors = Object.freeze({
  primary: {
    background: {
      normal: brand._navy.main,
    },
    foreground: {
      normal: brand._navy.main,
    },
  },
  secondary: {
    background: {
      normal: brand.blue.main,
    },
    foreground: {
      normal: brand.blue.tints[5],
    },
  },
});

export default ProgressPercentageColors;
