import * as Auth from '@pkg/auth';
import { useQuery } from '@pkg/graphql';

const FLOW_TYPES_QUERY = /* GraphQL */ `
  query FlowTemplates($uuid: UUID!) {
    flowTemplates(organisation_uuid: $uuid) {
      uuid
      description
      disclaimer
      created_at
      is_onboarding
      is_token_authenticated
      name
      settings {
        tags
      }
      type
    }
  }
`;

const useFlowTemplates = (enabled, orderFn) => {
  const { organisation } = Auth.useStore();
  const query = [FLOW_TYPES_QUERY, { uuid: organisation.uuid }];

  const { data, refetch, isLoading, isFetching, remove } = useQuery({
    query,
    enabled,
  });

  const flowTemplates = orderFn
    ? orderFn(data?.flowTemplates)
    : data?.flowTemplates;

  return {
    flowTemplates,
    refetch,
    isLoading,
    isFetching,
    remove,
  };
};

export default useFlowTemplates;
