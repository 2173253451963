import { useMemo } from 'react';
import App from '@/layouts/App';
import * as Auth from '@pkg/auth';
import useDesignStore from '../../hooks/useDesignStore';
import Heading from './Heading';
import * as Toolbars from './Toolbars';
import useBreadcrumbs from './useBreadcrumbs';
import usePresence from './usePresence';

const Layout = ({ children }) => {
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const hasLimitedAccess = Auth.useStore((state) => state.hasLimitedAccess);
  const snapshot = useDesignStore((state) => state.snapshot);

  const breadcrumbs = useBreadcrumbs();
  const presence = usePresence();
  const Toolbar = useMemo(() => {
    return isAuthenticated && !hasLimitedAccess
      ? Toolbars.Authenticated
      : Toolbars.Public;
  }, [isAuthenticated, hasLimitedAccess]);

  return (
    <App
      fullWidth
      data-testid="design"
      metaTitle={snapshot?.name}
      pageId="design"
      breadcrumbs={breadcrumbs}
      actions={<Toolbar presence={presence} />}
      heading={<Heading />}
    >
      {children}
    </App>
  );
};

export default Layout;
