import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import { Database } from '@pkg/database';
import useMutations from './useMutations';
import usePeopleSkills from './usePeopleSkills';
import useRefresh from './useRefresh';
import useStore from './useStore';

export default function useLive() {
  const { isAuthenticated, isLoading } = Auth.useStore();
  const { refresh, isRefreshing } = useRefresh();
  const { setSkills, setPeople, setLoading, setMutations } = useStore();
  const peopleSkills = usePeopleSkills();
  const mutations = useMutations();

  const isStale = Database.useStaleHash('skills');
  const skills = Database.useLive('skills');

  useEffect(() => {
    if (isLoading || isRefreshing) {
      return;
    }

    if (!isAuthenticated) {
      setSkills([]);
      setLoading(false);
      return;
    }

    if (isStale) {
      refresh();
      return;
    }

    if (!skills) {
      return;
    }

    setSkills(skills);
    setLoading(false);
  }, [skills, isAuthenticated, isLoading, isRefreshing, isStale]);

  useEffect(() => {
    if (!isAuthenticated) {
      setPeople(new Map());
    }

    setPeople(peopleSkills);
  }, [isAuthenticated, peopleSkills]);

  useEffect(() => {
    setMutations(isAuthenticated ? mutations : {});
  }, [isAuthenticated, mutations]);
}
