import { useEffect } from 'react';
import { EntityType } from '@/shared/enums';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { DesignLevel } from '@/lib/enums';
import Box from '@/components/Box';
import Typography from '@/components/Typography';
import ActiveComparisonCard from './ActiveComparisonCard';
import SelectComparison from './SelectComparison';

export default function ManageComparison() {
  const { scenario, comparisonScenario, loadComparison, cancelComparison } =
    useScenarioContext();
  const { selectId } = useViewModeContext();

  const hasActiveComparison = !!comparisonScenario;

  const handleCompare = ({ designId, revisionId }) => {
    // use primary scenario entity
    const entityType = scenario.entity.is_manager
      ? EntityType.MANAGER
      : scenario?.entity.__type;
    const entityDetails = {
      designId: scenario.details.designId,
      uuid: scenario.entity.uuid,
      type: entityType,
    };

    loadComparison(designId, revisionId, entityDetails);
  };

  useEffect(() => {
    // if a group or role scenario then default the navigation to the selected entity
    if (
      comparisonScenario?.entity &&
      comparisonScenario.entity.__type !== DesignLevel.ORGANISATION
    ) {
      selectId(
        comparisonScenario.entity.__type,
        comparisonScenario.entity.uuid
      );
    }
  }, [comparisonScenario?.entity?.uuid]);

  return (
    <Box>
      {hasActiveComparison && (
        <Stack direction="row" spacing={1}>
          <Typography variant="body2" sx={{ pt: 1 }}>
            Comparing to...
          </Typography>
          <ActiveComparisonCard
            scenario={comparisonScenario}
            onCancelComparison={cancelComparison}
          />
        </Stack>
      )}
      {!hasActiveComparison && (
        <SelectComparison current={scenario} onSelect={handleCompare} />
      )}
    </Box>
  );
}
