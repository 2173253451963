/**
 * @param {Object[]} collection
 * @param {String} identity
 * @param {String} key
 * @return {object}
 */
export function findBy(collection, identity, key) {
  if (!collection?.length) {
    return;
  }

  const result = collection.find(
    (i) => i[key]?.toString() === identity?.toString()
  );

  return result ? structuredClone(result) : null;
}

/**
 * @param {Object[]} collection
 * @param {String} uuid
 * @return {object}
 */
export function findById(collection, uuid) {
  return findBy(collection, uuid, 'uuid');
}
