import { useQueryClient } from 'react-query';
import useMutation from '../useMutation';
import onError from './onError';
import onMutate from './onMutate';
import onSettled from './onSettled';
import updater from './updater';

/**
 * @param {object} params
 * @param {string} params.mutation
 * @param {string} params.cacheKey
 * @param {any[]} params.cacheQuery
 * @param {function} [params.cacheUpdater]
 *
 * @return {object}
 */
export default function useOptimisticMutation({ mutation, ...options }) {
  const queryClient = useQueryClient();
  const { cacheKey, cacheQuery, cacheUpdater = updater, ...rest } = options;

  const cacheOperation = {
    key: cacheKey,
    query: cacheQuery,
    updater: cacheUpdater,
    client: queryClient,
  };

  return useMutation({
    mutation,
    invalidateQuery: cacheQuery,
    onMutate: (variables) => onMutate(variables, cacheOperation),
    onSettled: (response) => onSettled(response, cacheOperation),
    onError,
    ...rest,
  });
}
