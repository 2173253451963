import { Size } from '@/atoms/enums';

const SwitchSize = Object.freeze({
  [Size.SMALL]: {
    fontSize: '0.775rem',
    lineHeight: '1.325rem',
    fontWeight: 500,
  },
  [Size.MEDIUM]: {
    fontSize: '0.925rem',
    lineHeight: '1.75rem',
  },
  [Size.LARGE]: {
    fontSize: '1rem',
    lineHeight: '1.75rem',
  },
});

export default SwitchSize;
