export default function nameProperty(entity) {
  switch (true) {
    case Object.hasOwn(entity, 'title'):
      return 'title';
    case Object.hasOwn(entity, 'name'):
      return 'name';
    case Object.hasOwn(entity, '__description'):
      return '__description';
    default:
      return null;
  }
}
