const InsightsIcon = (props) => (
  <svg width="1em" height="1em" viewBox="0 -1 26 17" {...props} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.999985 0C0.4477 0 -1.52588e-05 0.447715 -1.52588e-05 1V14.9403C-1.52588e-05 15.4926 0.4477 15.9403 0.999984 15.9403H24.4286C24.9808 15.9403 25.4286 15.4926 25.4286 14.9403V1C25.4286 0.447715 24.9808 0 24.4286 0H0.999985ZM1.34667 0.806274C1.07053 0.806274 0.846672 1.03013 0.846672 1.30627V14.6353C0.846672 14.9114 1.07053 15.1353 1.34667 15.1353H24.096C24.3721 15.1353 24.596 14.9114 24.596 14.6353V1.30627C24.596 1.03013 24.3721 0.806274 24.096 0.806274H1.34667Z"
      fill="currentColor"
    />
    <path
      d="M3.24101 10.1941C3.24101 9.91054 3.47089 9.68066 3.75446 9.68066C4.03802 9.68066 4.2679 9.91054 4.2679 10.1941V13.6293C4.2679 13.9129 4.03802 14.1428 3.75446 14.1428C3.47089 14.1428 3.24101 13.9129 3.24101 13.6293V10.1941Z"
      fill="currentColor"
    />
    <path
      d="M5.80135 8.2101C5.80135 7.92653 6.03122 7.69666 6.31479 7.69666C6.59836 7.69666 6.82823 7.92653 6.82823 8.2101V13.6293C6.82823 13.9129 6.59836 14.1428 6.31479 14.1428C6.03122 14.1428 5.80135 13.9129 5.80135 13.6293V8.2101Z"
      fill="currentColor"
    />
    <path
      d="M8.36123 9.39528C8.36123 9.11171 8.59111 8.88184 8.87467 8.88184C9.15824 8.88184 9.38812 9.11171 9.38812 9.39528V13.6293C9.38812 13.9129 9.15824 14.1428 8.87467 14.1428C8.59111 14.1428 8.36123 13.9129 8.36123 13.6293V9.39528Z"
      fill="currentColor"
    />
    <path
      d="M10.9211 5.95149C10.9211 5.66793 11.151 5.43805 11.4346 5.43805C11.7181 5.43805 11.948 5.66793 11.948 5.95149V13.6293C11.948 13.9129 11.7181 14.1428 11.4346 14.1428C11.151 14.1428 10.9211 13.9129 10.9211 13.6293V5.95149Z"
      fill="currentColor"
    />
    <path
      d="M13.481 11.4367C13.481 11.1531 13.7109 10.9232 13.9944 10.9232C14.278 10.9232 14.5079 11.1531 14.5079 11.4367V13.6293C14.5079 13.9129 14.278 14.1428 13.9944 14.1428C13.7109 14.1428 13.481 13.9129 13.481 13.6293V11.4367Z"
      fill="currentColor"
    />
    <path
      d="M16.0409 7.56477C16.0409 7.28121 16.2708 7.05133 16.5543 7.05133C16.8379 7.05133 17.0678 7.28121 17.0678 7.56477V13.6293C17.0678 13.9129 16.8379 14.1428 16.5543 14.1428C16.2708 14.1428 16.0409 13.9129 16.0409 13.6293V7.56477Z"
      fill="currentColor"
    />
    <path
      d="M18.6008 4.39198C18.6008 4.10842 18.8306 3.87854 19.1142 3.87854C19.3978 3.87854 19.6276 4.10842 19.6276 4.39198V13.6293C19.6276 13.9129 19.3978 14.1428 19.1142 14.1428C18.8306 14.1428 18.6008 13.9129 18.6008 13.6293V4.39198Z"
      fill="currentColor"
    />
    <path
      d="M21.1606 9.12428C21.1606 8.84072 21.3905 8.61084 21.6741 8.61084C21.9577 8.61084 22.1875 8.84072 22.1875 9.12428V13.6293C22.1875 13.9129 21.9577 14.1428 21.6741 14.1428C21.3905 14.1428 21.1606 13.9129 21.1606 13.6293V9.12428Z"
      fill="currentColor"
    />
  </svg>
);

export default InsightsIcon;
