import Insight from '../templates/Insight';
import Administrative from './Administrative';
import InternalMeetings from './InternalMeetings';
import StrategicImportance from './StrategicImportance';

class Productivity extends Insight {
  benchmark() {
    return 'Good';
  }
}

export default new Productivity('Productivity', [
  StrategicImportance,
  Administrative,
  InternalMeetings,
]);
