import { OutlineCard } from '@/organisms/cards';
import { ScenarioList } from '@/organisms/scenarios';

const ScenarioListCard = ({ entityType, redirectToComparison }) => {
  return (
    <OutlineCard>
      <ScenarioList
        entityType={entityType}
        redirectToComparison={redirectToComparison}
      />
    </OutlineCard>
  );
};
export default ScenarioListCard;
