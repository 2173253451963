import { useState } from 'react';
import { RelatedEntityListChart } from '@/organisms/charts';
import Grow from '@mui/material/Grow';
import Box from '@/components/Box';

const RelatedActivityEntities = ({
  entity,
  entityType,
  colors,
  interactive = false,
  children,
}) => {
  const [showRelatedEntities, setShowRelatedEntities] = useState(false);

  const handleClick = () => {
    setShowRelatedEntities(!showRelatedEntities);
  };

  return (
    <>
      <Box width="100%" onClick={interactive ? handleClick : null}>
        {children}
      </Box>
      {showRelatedEntities && (
        <Grow in={true} timeout={500}>
          <Box sx={{ my: 2 }}>
            <RelatedEntityListChart
              entity={entity}
              entityType={entityType}
              colors={colors}
            />
          </Box>
        </Grow>
      )}
    </>
  );
};

export default RelatedActivityEntities;
