import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import Typography from '@mui/material/Typography';
import LabelSize from './LabelSize';

const Label = ({ children, overrideStyles, size = Size.SMALL }) => {
  const sizeProps = LabelSize[size];

  return (
    <Typography
      variant="body2"
      sx={{
        color: Colors.Base.font.tertiary,
        ...sizeProps,
        ...overrideStyles,
      }}
    >
      {children}
    </Typography>
  );
};

export default Label;
