import { tool } from 'ai';
import { z } from 'zod';

// add schemas that help out this tool

const navigateTo = tool({
  description: 'Navigate to a specific URL in the application',
  parameters: z.object({
    url: z.string().describe('The URL to navigate to'),
    explanation: z
      .string()
      .describe('Explanation of where this URL will navigate to'),
  }),
  execute: async ({ url, explanation }) => {
    // In a real application, this would use a router or navigation function
    // For now, we'll just return the URL and explanation
    // window.location.href = url;
    return {
      url,
      explanation,
    };
  },
});

export default navigateTo;
