import { Snapshots } from '@pkg/entities';
import { getReduced } from '@pkg/entities/snapshots/utils';
import { DesignLevel } from '@/lib/enums';
import handlePropertiesInput from '../handlePropertiesInput';

/**
 * @param {Object} from
 * @param {Object} from.snapshot
 * @param {String} from.uuid
 * @param {Object} to
 * @param {Object} to.snapshot
 * @param {String} to.uuid
 * @returns {Object}
 */
export default function publishTo({ from, to }) {
  const now = Date.now();

  const groupMutations = { create: [], update: [], remove: [] };
  const roleMutations = { create: [], update: [], remove: [] };
  const activityMutations = { create: [], update: [], remove: [] };

  const fromEntities = from.snapshot.entities;
  // "to" entities restricted to manager hierarchy
  const reducedTo = getReduced(to.snapshot, {
    type: DesignLevel.MANAGER,
    uuid: to.uuid,
  });
  const toEntities = reducedTo.entities;

  const toKeyed = Snapshots.utils.getKeyed(to.snapshot);
  const toSet = {
    groups: new Set(toEntities.groups.map(({ uuid }) => uuid)),
    roles: new Set(toEntities.roles.map(({ uuid }) => uuid)),
    activities: new Set(toEntities.activities.map(({ uuid }) => uuid)),
  };
  const fromSet = {
    groups: new Set(fromEntities.groups.map(({ uuid }) => uuid)),
    roles: new Set(fromEntities.roles.map(({ uuid }) => uuid)),
    activities: new Set(fromEntities.activities.map(({ uuid }) => uuid)),
  };

  const targetRootRole = toKeyed.entities.roles[to.uuid];

  for (const group of fromEntities.groups) {
    const isExisting = toSet.groups.has(group.uuid);

    // create new group
    if (!isExisting) {
      groupMutations.create.push(group);
      continue;
    }

    // update existing group
    const target = toKeyed.entities.groups[group.uuid];
    const update = {
      uuid: group.uuid,
      name: group.name,
      objective: group.objective,
      lead_uuid: group.lead_uuid,
      group_uuid: group.group_uuid || targetRootRole.group_uuid, // default to group that root is in
      properties: handlePropertiesInput(group.properties, target.properties),
      tags: group.tags,
      updated_at: now,
    };

    if (!update.properties) {
      delete update.properties;
    }
    groupMutations.update.push(update);
  }

  // remove groups
  toEntities.groups.forEach((group) => {
    if (!fromSet.groups.has(group.uuid)) {
      groupMutations.remove.push(group.uuid);
    }
  });

  for (const role of fromEntities.roles) {
    const isExisting = toSet.roles.has(role.uuid);

    // create new role
    if (!isExisting) {
      roleMutations.create.push(role);
      continue;
    }

    // update existing role
    const target = toKeyed.entities.roles[role.uuid];
    const update = {
      uuid: role.uuid,
      group_uuid: role.group_uuid || targetRootRole.group_uuid,
      parent_uuid: role.parent_uuid,
      user_uuid: role.user_uuid,
      title: role.title,
      fte: role.fte,
      budget: Number.isFinite(role.budget) ? role.budget : undefined,
      properties: handlePropertiesInput(role.properties, target.properties),
      skills: role.skills,
      tags: role.tags,
      order: role.order,
      updated_at: now,
    };

    // if root role then use existing root parent
    if (role.uuid === to.uuid) {
      update.parent_uuid = targetRootRole.parent_uuid;
    }

    if (!update.properties) {
      delete update.properties;
    }

    roleMutations.update.push(update);
  }

  // remove roles
  toEntities.roles.forEach((role) => {
    if (!fromSet.roles.has(role.uuid)) {
      roleMutations.remove.push(role.uuid);
    }
  });

  for (const activity of fromEntities.activities) {
    const uuid = activity.uuid;
    const isExisting = toSet.activities.has(uuid);

    // create new activity
    if (!isExisting) {
      activityMutations.create.push(activity);
      continue;
    }

    // update existing activity
    const target = toKeyed.entities.activities[uuid];
    const update = {
      uuid,
      library_uuid: activity.library_uuid,
      owner_uuid: activity.owner_uuid,
      owner_type: activity.owner_type,
      hours: activity.hours,
      properties: handlePropertiesInput(activity.properties, target.properties),
      tags: activity.tags,
      order: activity.order,
      updated_at: now,
    };

    if (!update.properties) {
      delete update.properties;
    }

    activityMutations.update.push(update);
  }

  // remove activities
  toEntities.activities.forEach((activity) => {
    if (!fromSet.activities.has(activity.uuid)) {
      activityMutations.remove.push(activity.uuid);
    }
  });

  return {
    created_at: now,
    entities: {
      groups: groupMutations,
      roles: roleMutations,
      activities: activityMutations,
    },
  };
}
