import { Label, Select } from '@/atoms/inputs';
import Box from '@mui/material/Box';

const SelectField = ({
  label,
  onChange,
  initialValue,
  optional,
  optionLabel,
  options,
  OptionComponent,
  size,
  width = 180,
}) => {
  return (
    <Box>
      <Label color="color" size={size}>
        {label}
        {optional && <span style={{ fontSize: '80%' }}> (optional)</span>}
      </Label>
      <Select
        initialValue={initialValue}
        options={options}
        optionLabel={optionLabel}
        OptionComponent={OptionComponent}
        onChange={onChange}
        size={size}
        width={width}
      />
    </Box>
  );
};

export default SelectField;
