import { useEffect, useState } from 'react';
import { EntitySummary, TeamSummary } from '@/molecules/summaries';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { useAccess } from '@pkg/access/organisations';
import { Routes } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import { Feature } from '@/lib/enums';
import { color } from '@/lib/theme/tokens';
import Box from '@/components/Box';
import { LinkButton } from '@/components/DashboardContainer/atoms/buttons';

const buttonColor = Object.freeze({
  font: color.core.white,
  border: 'transparent',
  background: 'inherit',
  highlight: {
    font: color.core.white,
    border: 'transparent',
    background: '#27527b',
  },
});

const TeamList = ({ allowLink = false }) => {
  const { scenario } = useScenarioContext();
  const [organisationPath, setOrganisationPath] = useState('');
  const groups = scenario?.relationships?.get(DesignEntity.GROUP);
  const access = useAccess();
  const canDesign = allowLink && access.features[Feature.ORGANISATION_DESIGN];

  useEffect(() => {
    if (!scenario?.entity || !canDesign) {
      return;
    }

    setOrganisationPath(
      Routes.build.designParts({
        designId: scenario.details?.designId,
        level: {
          type: DesignEntity.ORGANISATION,
          uuid: scenario.details?.designId,
        },
      })
    );
  }, [scenario?.entity?.uuid, scenario?.entity?.__uuid]);

  return (
    Boolean(scenario?.relationships) && (
      <Stack justifyContent="flex-start" alignItems="flex-start" sx={{ my: 1 }}>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Stack direction="row" width="100%" justifyContent="space-between">
            <EntitySummary entity={scenario.entity} />
            {canDesign && (
              <LinkButton
                path={organisationPath}
                title="VIEW ORGANISATION"
                colors={buttonColor}
                endIcon={<NavigateNextIcon sx={{ color: 'rgb(13, 46, 77)' }} />}
              />
            )}
          </Stack>
          <Divider
            sx={{
              mb: 3,
              mt: 2,
              backgroundColor: '#fff',
              borderColor: '#fff',
            }}
          />
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={1}
        >
          {Array.from(groups.values()).map((group) => (
            <Grid item xs={4} key={group.uuid}>
              <TeamSummary group={group} inverse={true} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    )
  );
};

export default TeamList;
