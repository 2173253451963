import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Permission from '@/shared/utils/permissions/Permission';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';

export const DispatchContext = createContext();
export const useDispatchContext = () => useContext(DispatchContext);

const DispatchProvider = ({ children, action, isEditable, name, uuid }) => {
  const actions = useDesignStore((state) => state.actions);
  const { access } = useScenarioContext();
  const [canEdit, setCanEdit] = useState();

  // Determines if the user can perform a dispatch based on their permissions.
  const handleCanEdit = (propertyName) => {
    if (!access?.can || !isEditable || !uuid) {
      return;
    }

    const permission = Permission[action];

    if (!permission?.__type) {
      return;
    }

    const entity = { uuid, __type: permission.__type };

    const property =
      permission?.property ?? permission?.properties.get(propertyName ?? name);

    if (!property) {
      return;
    }

    setCanEdit(access.can(entity, property, permission.action));
  };

  // Sets the state for users being able to dispatch an event.
  useEffect(() => {
    if (access?.isReady) {
      handleCanEdit(name);
    }
  }, [access?.isReady, action, isEditable, name]);

  // Performs a dispatch
  const handleDispatch = ({ action: dispatchAction, properties }) => {
    actions.dispatch(dispatchAction ?? action, properties);
  };

  const context = useMemo(() => {
    return { canEdit, handleDispatch };
  }, [actions, canEdit]);

  return (
    <DispatchContext.Provider value={context}>
      {children}
    </DispatchContext.Provider>
  );
};

export default DispatchProvider;
