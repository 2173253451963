import { useState, useEffect } from 'react';
import upload from '../../lib/upload';
import Dialog from '../Dialog';
import Image from '../Image';

const Avatar = ({
  src = '',
  defaultSrc = 'https://api.dicebear.com/5.x/avataaars/svg?seed=any&w=100&h=100&backgroundColor=00109e',
  alt = '',
  className,
  aspect = 1,
  size = 'lg',
  width = null,
  height = null,
  rounded = false,
  croppable = false,
  uploadable = false,
  endpoint = 'https://master.tus.io/files/',
  onChange = () => {},
  ...props
}) => {
  const initialImage = src ?? defaultSrc;

  const [image, setImage] = useState(initialImage);
  const [open, setopen] = useState(false);

  function handleToggleDialog() {
    setopen(!open);
  }

  async function handleUploadImage(blob) {
    let image = '';
    window.URL.revokeObjectURL(image);
    image = window.URL.createObjectURL(blob);
    setImage(image);
    const url = await upload(blob, endpoint);
    setImage(url);
    onChange(url);
  }

  useEffect(() => {
    if (src.length) {
      setImage(src);
    }
  }, [src]);

  return (
    <>
      <Image
        {...props}
        src={image}
        alt={alt}
        onClick={handleToggleDialog}
        className={className}
        size={size}
        rounded={rounded}
        width={width}
        height={height}
        uploadable={uploadable}
      />
      {uploadable && (
        <Dialog
          open={open}
          accept="image/jpeg,image/png"
          aspect={aspect}
          croppable={croppable}
          toggle={handleToggleDialog}
          onSave={handleUploadImage}
        />
      )}
    </>
  );
};

export default Avatar;
