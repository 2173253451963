import { VerticalDate } from '@/molecules/dates';
import NextIcon from '@mui/icons-material/NavigateNextRounded';
import Box from '@mui/material/Box';
import MuiListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import Colors from './DateListItemColors';

const DateListItem = ({ color = 'primary', item, onClick }) => {
  const handleClick = (event) => {
    onClick?.(event, item);
  };

  return (
    <MuiListItem sx={{ p: 0 }}>
      <ListItemButton
        onClick={handleClick}
        sx={{
          p: 0,
          mb: 1,
          '&:hover': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Stack direction="row" width="100%">
          {item.date && (
            <Box
              borderRadius={1}
              mr={1}
              py={0.5}
              px={0.75}
              sx={{
                ...Colors[color].normal,
                transition: 'box-shadow 200ms',
                '.MuiListItemButton-root:hover &': {
                  ...Colors[color].hover,
                },
              }}
            >
              <VerticalDate color={color} timestamp={item.date} />
            </Box>
          )}
          <Stack
            alignItems="center"
            borderRadius={1}
            direction="row"
            justifyContent="space-between"
            pl={2}
            pr={1}
            py={1}
            sx={{
              ...Colors[color].normal,
              maxWidth: 'calc(100% - 60px)',
              transition: 'box-shadow 200ms',
              '.MuiListItemButton-root:hover &': {
                ...Colors[color].hover,
              },
            }}
            width="100%"
          >
            <Box
              sx={{
                maxWidth: 'calc(100% - 30px)',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {item.label}
            </Box>
            {onClick && <NextIcon />}
          </Stack>
        </Stack>
      </ListItemButton>
    </MuiListItem>
  );
};

export default DateListItem;
