import { isEqual } from '../objects';

/**
 * @param {Array} arr1
 * @param {Array} arr2
 * @return {Array}
 */
export default function objectDiff(a, b) {
  const diff = new Set();

  a.forEach((i) => {
    b.some((j) => isEqual(i, j)) || diff.add(i);
  });

  b.forEach((i) => {
    a.some((j) => isEqual(i, j)) || diff.add(i);
  });

  return Array.from(diff);
}
