import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import { Obj } from '@pkg/utils';
import useStore from '../useStore';

const UPDATE_DESIGN = /* GraphQL */ `
  mutation UpdateDesign($input: UpdateDesignInput!) {
    updateDesign(input: $input) {
      uuid
    }
  }
`;

/**
 * @param {any[]} query
 * @return {Function}
 */
export default function useUpdate(query) {
  const database = useDatabase();
  const { update } = useStore();
  const { mutateAsync } = useMutation({
    mutation: UPDATE_DESIGN,
    invalidateQuery: query,
  });

  async function optimisticUpdate(designId, input) {
    const omitted = Obj.omitEmpty(input);
    omitted.updated_at = Date.now();

    update(designId, omitted);
    await database.designs.update(designId, omitted);
  }

  /**
   * @param {String} designId
   * @param {Object} input
   * @param {Boolean} input.is_public
   * @param {String} input.name
   * @param {String} input.goal
   * @param {String} input.notes
   * @returns {Promise}
   */
  async function updateDesign(designId, { name, goal, notes, is_public }) {
    await optimisticUpdate(designId, { name, goal, notes, is_public });
    return mutateAsync({ name, goal, notes, is_public, design_uuid: designId });
  }

  return updateDesign;
}
