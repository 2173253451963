import { Size } from '@/atoms/enums';

const PercentageChangeTextSize = Object.freeze({
  [Size.SMALL]: {
    variant: 'h5',
    container: {
      height: '1.25rem',
      width: '0.75rem',
    },
    iconContainer: {
      top: '-0.125rem',
      left: '-0.45rem',
      height: '1.5rem',
    },
    icon: {
      fontSize: '1.5rem',
      height: '1.5rem',
      width: '1.5rem',
    },
  },
  [Size.MEDIUM]: {
    variant: 'h4',
    container: {
      height: '1.5rem',
      width: '0.75rem',
    },
    iconContainer: {
      top: '-0.15rem',
      left: '-0.6rem',
      height: '1.5rem',
    },
    icon: {
      fontSize: '1.75rem',
      height: '1.75rem',
      width: '1.75rem',
    },
  },
  [Size.LARGE]: {
    variant: 'h3',
    container: {
      height: '1.625rem',
      width: '0.875rem',
    },
    iconContainer: {
      top: '-0.175rem',
      left: '-0.7rem',
      height: '2rem',
    },
    icon: {
      fontSize: '2rem',
      height: '2rem',
      width: '2rem',
    },
  },
});

export default PercentageChangeTextSize;
