import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import { CollaboratorFragment } from '../fragments';
import useStore from '../useStore';

const REMOVE_COLLABORATOR = /* GraphQL */ `
  mutation RemoveCollaborator($input: RemoveCollaboratorInput!) {
    removeCollaborator(input: $input) {
      is_public
      collaborators {
        ...CollaboratorFragment
      }
    }
  }

  ${CollaboratorFragment}
`;

export default function useRemoveCollaborator() {
  const { mutateAsync } = useMutation({ mutation: REMOVE_COLLABORATOR });

  const database = useDatabase();
  const { get, put, update } = useStore();

  /**
   * Optimistically remove collaborator from sharing
   */
  async function optimisticRemove(designId, userId) {
    const design = structuredClone(get(designId));
    design.collaborators = design.collaborators.filter(({ uuid }) => {
      return uuid !== userId;
    });

    put(design);
    await database.designs.put(design);
  }

  /**
   * @param {String} designId
   * @param {String} userId
   * @returns {Promise}
   */
  async function removeCollaborator(designId, userId) {
    await optimisticRemove(designId, userId);

    const input = { design_uuid: designId, user_uuid: userId };
    const result = await mutateAsync(input);
    const sharing = result?.data?.addCollaborator;

    if (sharing) {
      update(designId, sharing);
      await database.designs.update(designId, sharing);
    }

    return result;
  }

  return removeCollaborator;
}
