import { brand } from '@/lib/theme/tokens/palettes';

const InputColors = Object.freeze({
  primary: {
    normal: {
      color: '#42474B',
      borderColor: '#d1dbea',
      shadowColor: '#d1dbea',
    },
    hover: {
      backgroundColor: brand.blue.tints[0],
    },
    focus: {
      backgroundColor: brand.blue.tints[0],
    },
  },
  secondary: {
    normal: {
      color: brand.purple.main,
      borderColor: brand.yellow.main,
    },
    hover: {
      borderColor: brand.yellow.shades[1],
      backgroundColor: brand.blue.tints[0],
    },
    focus: {
      borderColor: brand.yellow.main,
      backgroundColor: brand.blue.tints[0],
    },
  },
});

export default InputColors;
