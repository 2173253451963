import { Collections, Sort } from '@pkg/utils';

/**
 * @param {Object} state
 * @param {Object[]} state.categories
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.order
 * @returns {Object}
 */
export default function reorder({ categories }, { uuid, order }) {
  const category = categories.map.get(uuid);

  if (category.is_system) {
    return null;
  }

  if (category.order === order) {
    return null;
  }

  const event = {
    categories: {
      update: [{ uuid, order }],
    },
  };

  const reordered = Collections.where(categories.list, 'is_system', false);
  reordered.sort(Sort.order());

  const index = reordered.findIndex((category) => category.uuid === uuid);
  reordered.splice(index, 1);
  reordered.splice(order, 0, category);
  reordered.forEach(({ uuid, order }, index) => {
    if (category.uuid !== uuid && order !== index) {
      event.categories.update.push({ uuid, order: index });
    }
  });

  return event;
}
