const Size = Object.freeze({
  XX_SMALL: 'xxSmall',
  X_SMALL: 'xSmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'xLarge',
});

export default Size;
