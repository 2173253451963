/**
 * Revert unmapped mutation to unkeyed one
 * @param {Object} mutation
 * @return {Object}
 */
export default function map(mutation) {
  if (!Object.hasOwn(mutation, 'entities')) {
    return mutation;
  }

  const unmapped = structuredClone(mutation);
  for (const plural in unmapped.entities) {
    unmapped.entities[plural] = unmapActions(unmapped.entities[plural]);
    if (!Object.keys(unmapped.entities[plural]).length) {
      delete unmapped.entities[plural];
    }
  }

  return Object.freeze(unmapped);
}

/**
 * @param {Object} actions
 * @param {Map} actions.create
 * @param {Map} actions.update
 * @param {Set} actions.remove
 * @returns {Object}
 */
export function unmapActions(actions) {
  const unmapped = {};

  Object.keys(actions).forEach((action) => {
    const values = actions[action].values?.() ?? actions[action];
    unmapped[action] = Array.from(values);
  });

  Object.keys(unmapped).forEach((action) => {
    if (!unmapped[action].length) {
      delete unmapped[action];
    }
  });

  return unmapped;
}
