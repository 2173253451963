import { Divider } from '@/atoms/dividers';
import { MetricProperty, Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { SummaryHeading } from '@/molecules/headings';
import { BeforeAfterMetric } from '@/molecules/metrics';
import { EntityType } from '@/shared/enums';
import { metricColor } from '@/shared/utils';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const determineChartType = (metric) => {
  switch (metric) {
    case EntityMetric.FTE:
      return 'donut';
    default:
      return 'bar';
  }
};

const PlanSummaryChange = ({ type = EntityType.ROLE, metric, metrics }) => {
  const previous = metrics?.[0]?.[metric];
  const next = metrics?.[1]?.[metric];
  const difference = next - previous;
  const isPositive = difference > 0;
  const percentage =
    previous === 0 && next !== 0
      ? 100
      : Math.abs(percent(difference, previous));
  const metricLabel = MetricProperty[metric].label.plural;
  const differenceValue = format(Math.abs(difference), {
    ...MetricProperty[metric].formatOptions,
    notation: 'compact',
  });
  const chartType = determineChartType(metric);

  const percentageLabel = difference > 0 ? 'Greater' : 'Less';
  const differenceLabel = difference > 0 ? 'Increase' : 'Decrease';

  const color = metricColor(metric, type);
  const colors = {
    foreground: color.primary,
    background: color.secondary,
  };

  return (
    <>
      <SummaryHeading title={metricLabel} />
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mt={1}
        spacing={1}
        px={2}
      >
        <BeforeAfterMetric
          chartType={chartType}
          colors={colors}
          metric={metric}
          metrics={metrics}
        />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="center"
          flexGrow={1}
          ml="-8px !important"
        >
          <Box
            width="calc(100% - 32px)"
            height={4}
            mt="3px"
            sx={{
              backgroundColor: color.primary,
              borderRadius: '2px',
            }}
          />
          <Box sx={{ transform: 'scale(0.4)', ml: '-8px' }}>
            <Box
              sx={{
                backgroundColor: color.primary,
                borderTopRightRadius: '25%',
                position: 'relative',
                height: '1rem',
                textAlign: 'left',
                transform: 'rotate(-90deg) skewX(-30deg) scale(1,.866)',
                width: '1rem',
                '&:before': {
                  backgroundColor: 'inherit',
                  borderTopRightRadius: '25%',
                  content: '""',
                  height: '1rem',
                  position: 'absolute',
                  transform:
                    'rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%)',
                  width: '1rem',
                },
                '&:after': {
                  backgroundColor: 'inherit',
                  borderTopRightRadius: '25%',
                  content: '""',
                  height: '1rem',
                  position: 'absolute',
                  transform:
                    'rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%)',
                  width: '1rem',
                },
              }}
            />
          </Box>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Box textAlign="right">
            <Heading
              variant="h3"
              overrideStyles={{
                fontWeight: 700,
              }}
            >
              {differenceValue}
            </Heading>
            <Paragraph
              size={Size.X_SMALL}
              overrideStyles={{
                mb: 0,
                textTransform: 'uppercase',
              }}
            >
              {differenceLabel}
            </Paragraph>
          </Box>
          <Divider orientation="vertical" />
          <Box textAlign="left">
            <Heading
              variant="h3"
              overrideStyles={{
                fontWeight: 700,
              }}
            >
              {percentage}%
            </Heading>
            <Paragraph
              size={Size.X_SMALL}
              overrideStyles={{
                mb: 0,
                textTransform: 'uppercase',
              }}
            >
              {percentageLabel}
            </Paragraph>
          </Box>
        </Stack>
      </Stack>
    </>
  );
};

export default PlanSummaryChange;
