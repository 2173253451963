import { cloneElement, Fragment, useEffect, useState } from 'react';
import { useDispatchContext } from '@/shared/providers';
import { DragDropContext, Droppable } from '@hello-pangea/dnd';
import DraggableLayout from './DraggableLayout';

const DragDropLayout = ({ component, items, createDispatchProperties }) => {
  const { canEdit, handleDispatch } = useDispatchContext();

  const [ordered, setOrdered] = useState(items);

  const handleDragEnd = ({ draggableId, source, destination }) => {
    if (!destination) {
      return;
    }

    if (
      source.droppableId !== destination?.droppableId ||
      source.index === destination.index
    ) {
      return;
    }

    const updatedItems = [...ordered];

    updatedItems.splice(source.index, 1);
    updatedItems.splice(destination.index, 0, ordered[source.index]);

    setOrdered(updatedItems);

    requestAnimationFrame(async () => {
      if (handleDispatch && createDispatchProperties) {
        const updatedIndex =
          destination.index > source.index
            ? destination.index + 1
            : destination.index;
        handleDispatch?.({
          properties: createDispatchProperties(
            draggableId,
            updatedIndex,
            ordered
          ),
        });
      }
    });
  };

  useEffect(() => {
    if (!items?.length) {
      return;
    }

    if (JSON.stringify(items) !== JSON.stringify(ordered)) {
      setOrdered(items);
    }
  }, [JSON.stringify(items)]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="DROPPABLES">
        {({ droppableProps, innerRef, placeholder }) => (
          <div ref={innerRef} {...droppableProps}>
            {ordered.map((item, index) => (
              <Fragment key={item.id}>
                <DraggableLayout
                  draggableId={item.id}
                  index={index}
                  order={item.order}
                  isDragDisabled={!canEdit}
                  zIndex={ordered.length - index}
                >
                  {cloneElement(component, { ...item, index })}
                </DraggableLayout>
              </Fragment>
            ))}
            {placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropLayout;
