import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const EmptyInsights = ({ height = 400, message, size = Size.LARGE }) => {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      height={height}
      sx={{
        boxShadow: `0 0 0 1px ${BaseColors.border.primary}`,
      }}
    >
      <Paragraph size={size}>{message}</Paragraph>
    </Stack>
  );
};

export default EmptyInsights;
