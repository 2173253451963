import { create } from 'zustand';

export const ANONYMOUS_STATE = {
  me: null,
  organisation: null,
  isAuthenticated: false,
  isDisabled: false,
  isFronteggAuthenticated: false,
  isLoading: false,
  isRegistered: false,
  hasLimitedAccess: false,
  features: [],
};

const useStore = create((set) => ({
  set: (state) => set(() => state),
  ...ANONYMOUS_STATE,
  isLoading: true,
}));

export default useStore;
