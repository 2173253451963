import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { ActivityDimensions, MatrixPresets } from '@/molecules/chartElements';
import { OutlineCard } from '@/organisms/cards';
import { SummaryMatrix, useMatrixChartData } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { MatrixPreset } from '@/shared/enums';
import { ScopeType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';

const PrioritisationSummary = ({ showComparison }) => {
  const { filter } = useInsightsContext();
  const { selectScope } = useViewModeContext();
  const [dimension] = useState(ActivityDimensions[EntityType.ACTIVITY]);
  const metric = 'hours';

  const prioritisationMatrix = MatrixPresets[MatrixPreset.PRIORITISATION];
  const [matrixObject, setMatrixObject] = useState({
    type: EntityType.TAG,
    items: prioritisationMatrix.ids.map((id) => {
      return { id };
    }),
    titles: prioritisationMatrix.titles,
  });

  const { chartData, metricMax, metricTotals, tags, comparisonColor } =
    useMatrixChartData({
      dimension,
      entityType: EntityType.ACTIVITY,
      matrix: matrixObject,
      metric,
      filter,
      sort: {
        metric,
        type: 'DESC',
      },
      showComparison,
    });

  const hasActivities = metricTotals?.activities > 0;

  const handleClick = () => {
    selectScope(ScopeType.PRIORITISATION);
  };

  return (
    chartData && (
      <OutlineCard
        headerAction={
          hasActivities && (
            <Button
              label="View all"
              color="secondary"
              onClick={handleClick}
              size={Size.X_SMALL}
            />
          )
        }
        justifyContent="flex-start"
        margin={0}
        title="What work is high value"
        showComparison={showComparison}
        comparisonColor={comparisonColor}
      >
        <Box textAlign="center" py={2}>
          {hasActivities ? (
            <SummaryMatrix
              entityType={dimension.order[0]}
              chartData={chartData}
              height={250}
              metric={metric}
              metricMax={metricMax}
              metricTotals={metricTotals}
              matrixPreset={MatrixPreset.PRIORITISATION}
              showEntitiesTable={false}
              tags={tags}
            />
          ) : (
            <EmptyInsights
              message="No activities were found."
              size={Size.SMALL}
            />
          )}
        </Box>
      </OutlineCard>
    )
  );
};

export default PrioritisationSummary;
