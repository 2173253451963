import { useCallback, useMemo } from 'react';
import { DimensionType } from '@/molecules/tableElements';
import {
  useActivityColumnDefs,
  useDesignColumnDefs,
  useGroupColumnDefs,
  usePersonColumnDefs,
  useRoleColumnDefs,
  useSkillColumnDefs,
  useTagColumnDefs,
} from '@/organisms/tables/hooks';
import { useViewModeContext } from '@/shared/providers';
import { useAccess } from '@pkg/access/organisations';

const useColumnDefs = (dimension) => {
  const access = useAccess();
  const { showBudget } = useViewModeContext();

  const activityColDefs = useActivityColumnDefs(showBudget);
  const designColDefs = useDesignColumnDefs({ dimension });
  const groupColDefs = useGroupColumnDefs(dimension);
  const personColDefs = usePersonColumnDefs(dimension);
  const roleColDefs = useRoleColumnDefs({ dimension, showBudget });
  const skillColDefs = useSkillColumnDefs(dimension);
  const tagColDefs = useTagColumnDefs(dimension);

  const getColumnDefinitions = useCallback(
    (dimension) => {
      const hasActivities =
        dimension === DimensionType.ACTIVITY ||
        dimension === DimensionType.ACTIVITY_TAGS_ACTIVITY ||
        dimension === DimensionType.ROLE_TAGS_ACTIVITY ||
        dimension === DimensionType.GROUP_TAGS_ACTIVITY;

      const hasTags =
        dimension === DimensionType.ACTIVITY_TAGS_ACTIVITY ||
        dimension === DimensionType.ROLE_TAGS_ACTIVITY ||
        dimension === DimensionType.ROLE_TAGS_ROLE ||
        dimension === DimensionType.GROUP_TAGS_ACTIVITY ||
        dimension === DimensionType.GROUP_TAGS_GROUP;

      const hasSkills =
        dimension === DimensionType.PEOPLE_SKILLS ||
        dimension === DimensionType.ROLE_SKILLS;

      const hasRole =
        dimension !== DimensionType.GROUP_TAGS_GROUP &&
        dimension !== DimensionType.GROUP;

      const columns = [];
      if (hasTags) {
        columns.push(...tagColDefs);
      }

      if (hasSkills) {
        columns.push(...skillColDefs);
      }

      if (hasActivities) {
        columns.push(...activityColDefs);
      }

      if (dimension === DimensionType.PEOPLE_SKILLS) {
        columns.push(...personColDefs);
      }

      if (hasRole) {
        columns.push(...roleColDefs);
      }

      columns.push(...groupColDefs);
      columns.push(...designColDefs);

      return columns;
    },
    [dimension]
  );

  const columnDefs = useMemo(() => {
    return getColumnDefinitions(dimension);
  }, [
    activityColDefs,
    designColDefs,
    dimension,
    groupColDefs,
    personColDefs,
    roleColDefs,
    skillColDefs,
    showBudget,
    tagColDefs,
  ]);

  const updateColumnDefs = (dimension, api) => {
    if (!api) {
      return;
    }

    const columnDefs = getColumnDefinitions(dimension);

    api.setGridOption('columnDefs', columnDefs);
  };

  return {
    columnDefs,
    updateColumnDefs,
  };
};

export default useColumnDefs;
