import { FillCard } from '@/organisms/cards';
import TeamMemberList from './TeamMemberList';

const TeamMemberListCard = ({ allowLink = false }) => {
  return (
    <FillCard>
      <TeamMemberList allowLink={allowLink} />
    </FillCard>
  );
};

export default TeamMemberListCard;
