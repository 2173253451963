import { cloneElement } from 'react';
import Box from '@mui/material/Box';
import { brand, core } from '@/lib/theme/tokens/palettes';

const FloatingBar = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: brand._navy.main,
        borderRadius: '8px',
        bottom: '24px',
        color: core.white,
        left: 'calc(14.5vw - ((100vw - 100%) / 2) + 56px)',
        py: 1,
        px: 2,
        position: 'fixed',
        zIndex: 2,
        width: 'calc(70vw - ((100vw - 100%) / 2) - 64px)',
      }}
    >
      <Box>{cloneElement(children)}</Box>
    </Box>
  );
};

export default FloatingBar;
