import { Colors } from '@pkg/utils';
import { ChipType } from '@/lib/enums';

export default function entityColor(entityType) {
  const color = Colors.entity(entityType);
  const colors = {
    normal: {
      backgroundColor: color.tints[1],
      color: color.main,
    },
    hover: {
      backgroundColor: color.tints[1],
      color: color.main,
    },
  };

  if (entityType === ChipType.ACTIVITY) {
    colors.normal = {
      backgroundColor: color.tints[1],
      color: color.shades[2],
    };
  }

  if (entityType === ChipType.HISTORICAL) {
    colors.normal = {
      backgroundColor: color.tints[4],
      color: color.shades[4],
    };
    colors.hover.backgroundColor = color.tints[1];
  }

  if (entityType === ChipType.MANAGER) {
    colors.normal = {
      backgroundColor: color.tints[1],
      color: color.tints[6],
    };
    colors.hover.backgroundColor = color.tints[1];
  }

  if (entityType === ChipType.ROLE) {
    colors.hover.backgroundColor = color.tints[2];
  }

  if (entityType === ChipType.SCENARIO) {
    colors.normal.color = color.shades[3];
    colors.hover.backgroundColor = color.tints[0];
  }

  if (entityType === ChipType.ORGANISATION) {
    colors.hover.backgroundColor = color.tints[2];
  }

  return colors;
}
