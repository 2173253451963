import { OrganisationPermission } from '@/lib/enums';

/**
 * @param {String} organisationId
 * @returns {Object}
 */
export default function getPermissions(organisationId) {
  return {
    organisation: {
      budget: `${OrganisationPermission.BUDGET}.${organisationId}`,
      manage: `${OrganisationPermission.MANAGE}.${organisationId}`,
    },
  };
}
