export default function combinedSnapshots(snapshot, baseSnapshot) {
  const combined = structuredClone(baseSnapshot);
  combined.__hash = snapshot.__hash;

  for (const plural in snapshot.entities) {
    const entities = snapshot.entities[plural];

    if (!combined.entities?.[plural]) {
      combined.entities[plural] = [];
    }

    entities.forEach((entity) => {
      const existing = combined.entities[plural].find(
        (e) => e.uuid === entity.uuid
      );

      if (existing) {
        combined.entities[plural].splice(
          combined.entities[plural].indexOf(existing),
          1,
          entity
        );
      } else {
        combined.entities[plural].push(entity);
      }
    });
  }

  return combined;
}
