import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';
import { DesignEntity } from '@/lib/enums';

export const CREATE_MANY_INVITES = /* GraphQL */ `
  mutation createManyInvites($input: [InviteUserInput!]!) {
    createManyInvites(input: $input)
  }
`;

export default function useCreateMany(query) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: CREATE_MANY_INVITES,
    invalidateQuery: query,
  });

  /**
   * @param {String} designId
   * @param {String} campaignId
   * @param {Object[]} roles
   * @param {String} roles[].userId
   * @param {String} roles[].id
   * @param {String} roles[].role // role name
   * @returns {Promise}
   */
  function createMany(designId, campaignId, roles) {
    const input = roles
      ? roles.map((role) => {
          const item = {
            uuid: role.userId,
          };
          if (role.id) {
            item.entity = {
              uuid: role.id,
              name: role.role,
              type: DesignEntity.ROLE,
            };
          }
          if (campaignId) {
            item.flow = { campaign_id: campaignId };
          }
          if (designId) {
            item.design_uuid = designId;
          }
          return item;
        })
      : [];

    const message =
      input.length === 1
        ? 'Inviting 1 person'
        : `Inviting ${input.length} people`;
    enqueueSnackbar(message);

    return mutateAsync(input);
  }

  return createMany;
}
