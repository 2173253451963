import { useFormikContext } from 'formik';
import LoadingButton from '../LoadingButton';

const SubmitButton = (props) => {
  const { isSubmitting } = useFormikContext();

  return <LoadingButton {...props} loading={isSubmitting} type="submit" />;
};

export default SubmitButton;
