import { Size } from '@/atoms/enums';

const ButtonSize = Object.freeze({
  [Size.X_SMALL]: {
    borderRadius: '2px',
    fontSize: '0.7rem',
    fontWeight: 500,
    height: '1.375rem',
    lineHeight: '1.375rem',
    minWidth: 'auto',
    padding: '0 6px',
  },
  [Size.SMALL]: {
    fontSize: '0.825rem',
    fontWeight: 500,
    height: '1.75rem',
    lineHeight: '1.75rem',
    padding: '0 8px',
    minWidth: 'auto',
  },
  [Size.MEDIUM]: {
    fontSize: '0.875rem',
    fontWeight: 400,
    height: '1.75rem',
    lineHeight: '1.75rem',
    minWidth: 'auto',
    padding: '0 8px',
  },
  [Size.LARGE]: {
    fontSize: '0.925rem',
    fontWeight: 400,
    height: '2.125rem',
    lineHeight: '2.125rem',
    padding: '0 8px',
  },
  [Size.X_LARGE]: {
    fontSize: '1rem',
    fontWeight: 400,
    height: '2.5rem',
    lineHeight: '2.5rem',
    padding: '0 12px',
  },
});

export default ButtonSize;
