import { useCallback } from 'react';
import shortuuid from '@pkg/uuid';
import { Action, DesignEntity, PolicyLevel } from '@/lib/enums';
import getRoles from '../get/roles';
import deriveNew from '../relationships/deriveNew';

function activityOwnerExists(activity, snapshot) {
  if (!activity.owner_type) {
    return false;
  }

  const ownerEntity = DesignEntity.toPlural(activity.owner_type);
  return snapshot.entities[ownerEntity].some(
    (entity) => entity.uuid === activity.owner_uuid
  );
}

/**
 *
 * @param {Object} me
 * @param {String} level
 * @param {Boolean} isHistorical
 * @param {Object} snapshot
 * @param {*} lookup
 * @returns
 */
export default function useCreate(me, level, isHistorical, snapshot, lookup) {
  return useCallback(
    (entity, altSnapshot = null) => {
      if (isHistorical) {
        return false;
      }

      // assume admins can create entities
      if (level === PolicyLevel.ADMIN) {
        return true;
      }

      const currentSnapshot = altSnapshot || snapshot;

      const roleSet = getRoles(me);
      const myRoles = currentSnapshot.entities.roles.filter(
        (r) => r.user_uuid === me.uuid
      );
      const isManager = myRoles.some((r) => r.is_manager);
      const supportedGroups = roleSet?.GROUP?.byRole.supporter || new Set();

      // if we don't know the entities properties yet, allow managers and supporters to create
      if (!entity || Object.values(entity).length < 2) {
        return (
          isManager || (supportedGroups.size > 0 && entity?.__type !== 'GROUP')
        );
      }

      // checking permission for an activity where the owner role isn't yet in the snapshot
      if (
        entity.__type === 'ACTIVITY' &&
        !activityOwnerExists(entity, currentSnapshot)
      ) {
        return false;
      }

      // add a temporary uuid to the proposed entity
      if (!entity.uuid) {
        entity.uuid = shortuuid.generate();
      }

      // otherwise check the relationship the user will have with the new entity
      const relationship = deriveNew(
        myRoles,
        supportedGroups,
        entity,
        currentSnapshot
      );

      return lookup([
        {
          level,
          relationship,
          entityType: entity.__type.toLowerCase(),
          property: null,
          action: Action.CREATE,
        },
      ]);
    },
    [me, isHistorical, snapshot?.__hash, lookup]
  );
}
