import { Colors } from '@/atoms/buttons';
import { ButtonSize } from '@/atoms/buttons/utils';
import { Size } from '@/atoms/enums';
import Box from '@mui/material/Box';

const ConditionalOperator = ({
  size = Size.MEDIUM,
  color = 'light-blue',
  variant = 'outline',
}) => {
  const textProps = ButtonSize[size];
  const colors = Colors.button[color][variant];
  return (
    <Box
      sx={{
        ...textProps,
        ...colors.normal,
      }}
    >
      AND
    </Box>
  );
};

export default ConditionalOperator;
