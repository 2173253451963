import { EntityType } from '@/shared/enums';

/**
 * Returns the entity type that has been use for the scope entity in the url.
 *
 * @param {Map} urlSearchParams
 *
 * @return {String}
 */
export default function selectedScopeEntity(urlSearchParams) {
  if (urlSearchParams.get(EntityType.ACTIVITY)) {
    return EntityType.ACTIVITY;
  }
  if (urlSearchParams.get(EntityType.ROLE)) {
    return EntityType.ROLE;
  }
  if (urlSearchParams.get(EntityType.GROUP)) {
    return EntityType.GROUP;
  }
  if (urlSearchParams.get(EntityType.TAG)) {
    return EntityType.TAG;
  }
}
