import { Collections, Sort } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.roleIds
 * @param {Object} input.template
 * @returns {Object}
 */
export default function patch(snapshot, { roleIds, template }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: { update: [] },
      roles: { update: [] },
      activities: { create: [], update: [] },
    },
  };

  const ownerActivities = Collections.groupBy(
    entities.activities,
    'owner_uuid'
  );
  const templateActivities = template.snapshot.entities.activities || [];
  templateActivities.sort(Sort.order());

  const roleIdsSet = new Set(roleIds);
  const roles = entities.roles.filter((role) => roleIdsSet.has(role.uuid));
  const touchedGroups = new Set();

  roles.forEach((role) => {
    mutation.entities.roles.update.push({
      uuid: role.uuid,
      updated_at: now,
    });

    if (role.group_uuid) {
      touchedGroups.add(role.group_uuid);
    }

    const activities = ownerActivities[role.uuid] || [];
    const libraryIds = new Set(Collections.pluck(activities, 'library_uuid'));

    const creating = [];
    templateActivities.forEach((template) => {
      if (libraryIds.has(template.library_uuid)) {
        activities.forEach((activity) => {
          if (activity.library_uuid !== template.library_uuid) {
            return;
          }

          const combined = new Set(activity.tags.concat(template.tags));
          if (combined.size !== activity.tags.length) {
            mutation.entities.activities.update.push({
              uuid: activity.uuid,
              tags: Array.from(combined),
              updated_at: now,
            });
          }
        });
      } else {
        const activity = structuredClone(template);
        activity.uuid = shortuuid.generate();
        activity.owner_uuid = role.uuid;
        activity.order = activities.length + creating.length;
        activity.created_at = now;
        activity.updated_at = now;
        creating.push(activity);
      }
    });

    mutation.entities.activities.create.push(...creating);
  });

  if (mutation.entities.activities.create.length === 0) {
    delete mutation.entities.activities.create;
  }

  if (mutation.entities.activities.update.length === 0) {
    delete mutation.entities.activities.update;
  }

  if (Object.keys(mutation.entities.activities).length === 0) {
    return null;
  }

  touchedGroups.forEach((uuid) => {
    mutation.entities.groups.update.push({ uuid, updated_at: now });
  });

  return mutation;
}
