import { DesignEntity } from '@/lib/enums';

/**
 * Returns a user access role by id
 * @param {Object} user
 * @param {String} context
 * @param {String} entityType
 * @param {String} entityId
 * @returns {String|undefined}
 */
export default function getRoleByUuid(user, context, entityId, entityType) {
  if (!entityType) {
    entityType = context;
  }
  if (entityType === DesignEntity.SCENARIO) {
    entityType = DesignEntity.DESIGN;
  }

  if (!Array.isArray(user?.access?.roles[context])) {
    return undefined;
  }

  return user.access.roles[context].find((role) => {
    return (
      role.startsWith(`${entityType.toLowerCase()}.`) &&
      role.endsWith(`.${entityId}`)
    );
  });
}
