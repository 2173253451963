import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';

const PlanTeams = () => {
  return (
    <Box p={2}>
      <Heading variant="h3">Plan teams</Heading>
    </Box>
  );
};

export default PlanTeams;
