import { styled } from '@mui/material/styles';
import config from '@/lib/config';

const StyledImage = styled('img')({});

const Image = (props) => {
  return <StyledImage {...props} src={`${config.CDN}/statics${props.src}`} />;
};

export default Image;
