import { DesignEntity } from '@/lib/enums';
import getKeyed from './getKeyed';

/**
 * @param {Object} snapshot
 * @returns {Object}
 */
export default function getRelated(snapshot) {
  const related = {
    [DesignEntity.GROUP]: new Map(),
    [DesignEntity.ROLE]: new Map(),
    [DesignEntity.ACTIVITY]: new Map(),
  };

  if (!snapshot) {
    return related;
  }

  console.time('Snapshots.utils.getRelated');
  const keyed = getKeyed(snapshot);

  (snapshot.entities.groups ?? []).forEach((group) => {
    related[DesignEntity.GROUP].set(group.uuid, {
      __self: group.uuid,
    });
  });

  snapshot.entities.roles.forEach((role) => {
    related[DesignEntity.ROLE].set(role.uuid, {
      [DesignEntity.PERSON]: role.user_uuid,
      [DesignEntity.GROUP]: role.group_uuid,
      __self: role.uuid,
    });
  });

  snapshot.entities.activities.forEach((activity) => {
    const ownerType = activity.owner_type;
    const ownerId = activity.owner_uuid;
    let groupId = null,
      roleId = null;

    if (ownerType === DesignEntity.GROUP) {
      groupId = ownerId;
    } else {
      groupId = keyed.entities.roles[ownerId].group_uuid;
      roleId = ownerId;
    }

    related[DesignEntity.ACTIVITY].set(activity.uuid, {
      [DesignEntity.GROUP]: groupId,
      [DesignEntity.ROLE]: roleId,
      __owner: ownerType,
      __self: activity.uuid,
    });
  });

  console.timeEnd('Snapshots.utils.getRelated');
  return Object.freeze(related);
}
