import { DimensionType, PresetType } from '@/molecules/tableElements/enums';
import {
  ActivityInsightsPreset,
  ActivityGroupInsightsPreset,
  ActivityTagGroupInsightsPreset,
  GroupTagGroupInsightsPreset,
  RoleInsightsPreset,
  RoleSkillInsightsPreset,
  RoleTagGroupInsightsPreset,
} from './';

const ActivityPresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'No Preset',
    },
  ],
  [
    PresetType.ACTIVITY,
    {
      label: 'Role',
      preset: ActivityInsightsPreset,
    },
  ],
  [
    PresetType.ACTIVITY_GROUP,
    {
      label: 'Team',
      preset: ActivityGroupInsightsPreset,
    },
  ],
]);

const ActivityTagPresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'No Preset',
    },
  ],
  [
    PresetType.ACTIVITY_GROUP,
    {
      label: 'Tag',
      preset: ActivityTagGroupInsightsPreset,
      defaultOption: true,
    },
  ],
]);

const RolePresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'No Preset',
    },
  ],
  [
    PresetType.ROLE_GROUP,
    {
      label: 'Role Title',
      preset: RoleInsightsPreset,
      defaultOption: true,
    },
  ],
]);

const RoleTagPresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'No Preset',
    },
  ],
  [
    PresetType.ROLE_GROUP,
    {
      label: 'Tag',
      preset: RoleTagGroupInsightsPreset,
      defaultOption: true,
    },
  ],
]);

const GroupTagPresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'No Preset',
    },
  ],
  [
    PresetType.ROLE_GROUP,
    {
      label: 'Tag',
      preset: GroupTagGroupInsightsPreset,
      defaultOption: true,
    },
  ],
]);

const RoleSkillPresets = new Map([
  [
    PresetType.NONE,
    {
      label: 'No Preset',
    },
  ],
  [
    PresetType.ROLE_SKILL,
    {
      label: 'Role',
      preset: RoleSkillInsightsPreset,
      defaultOption: true,
    },
  ],
]);

const DimensionPresetMap = new Map([
  [DimensionType.ACTIVITY, ActivityPresets],
  [DimensionType.ACTIVITY_TAGS_ACTIVITY, ActivityTagPresets],
  [DimensionType.ROLE, RolePresets],
  [DimensionType.ROLE_SKILLS, RoleSkillPresets],
  [DimensionType.ROLE_TAGS_ACTIVITY, ActivityTagPresets],
  [DimensionType.ROLE_TAGS_ROLE, RoleTagPresets],
  [DimensionType.GROUP_TAGS_ACTIVITY, ActivityTagPresets],
  [DimensionType.GROUP_TAGS_GROUP, GroupTagPresets],
]);

export default DimensionPresetMap;
