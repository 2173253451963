import { MetricProperty } from '@/atoms/enums';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { Bar } from '@/molecules/chartElements';
import { BinaryDonutChart } from '@/organisms/charts';
import { EntityPluralName, EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MoveIcon from '@pkg/icons/Move';
import { format } from '@pkg/utils/numbers';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const formatChartData = ({ chartType, colors, isPositive, values }) => {
  const previous = values[0];
  const next = values[1];

  switch (chartType) {
    case 'donut':
      return {
        values: isPositive
          ? [next - previous, previous]
          : [previous - next, next],
        colors: isPositive
          ? [colors.foreground, colors.background]
          : [colors.background, colors.foreground],
      };
    default:
      return {
        percentage:
          previous === 0 && next !== 0
            ? 100
            : percent(next - previous, previous),
        colors,
      };
  }
};

const getChangeLabel = (value, metric) => {
  if (metric === EntityMetric.MOVED) {
    return 'Moved';
  }

  if (value === 0) {
    return 'Change';
  }

  // Labels for adding and removing entities.
  if (
    metric === EntityMetric.ACTIVITIES ||
    metric === EntityMetric.ROLES ||
    metric === EntityMetric.GROUPS
  ) {
    return value > 0 ? 'Added' : 'Removed';
  }

  return value > 0 ? 'Increase' : 'Decrease';
};

const ChangeMetric = ({
  chartType = 'bar',
  colors,
  entityType = EntityType.ROLE,
  metric,
  metrics,
}) => {
  const previous = metrics?.[0]?.[metric];
  const next = metrics?.[1]?.[metric];
  const value = next - previous;
  const baseMetric =
    metric === EntityMetric.MOVED
      ? EntityPluralName[entityType]?.toLowerCase()
      : metric;
  const chartData = formatChartData({
    chartType,
    colors,
    isPositive: value > 0,
    values: [previous, next],
  });

  const metricLabel =
    Math.abs(value) === 1
      ? MetricProperty[baseMetric].label.singular
      : MetricProperty[baseMetric].label.plural;
  const metricValue = format(value >= 0 ? value : value * -1, {
    ...MetricProperty[metric].formatOptions,
    notation: 'compact',
  });
  const changeLabel = getChangeLabel(value, metric);

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Box
        sx={{ textAlign: 'right', flexGrow: 1 }}
        mr={chartType === 'donut' ? 1 : 2}
      >
        <Heading variant="h3" overrideStyles={{ fontWeight: 700 }}>
          {metricValue}
        </Heading>
        <Paragraph
          size={Size.X_SMALL}
          overrideStyles={{
            mb: 0,
            textTransform: 'uppercase',
            fontWeight: 600,
          }}
        >
          {metricLabel}
        </Paragraph>
        <Paragraph
          size={Size.X_SMALL}
          overrideStyles={{
            mb: 0,
            textTransform: 'uppercase',
          }}
        >
          {changeLabel}
        </Paragraph>
      </Box>
      {chartType === 'moved' && (
        <Box mr={1}>
          <MoveIcon primary={colors.background} secondary={colors.foreground} />
        </Box>
      )}
      {chartType === 'bar' && (
        <Box mr={1.5}>
          <Bar
            width={26}
            height={52}
            percentage={chartData.percentage}
            colors={chartData.colors}
            value={value}
          />
        </Box>
      )}
      {chartType === 'donut' && (
        <Box height={50}>
          <BinaryDonutChart
            colors={chartData.colors}
            height={50}
            id={`${metric}-donut-chart`}
            data={chartData.values}
            width={50}
          />
        </Box>
      )}
    </Stack>
  );
};

export default ChangeMetric;
