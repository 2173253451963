import entityChipColors from '@/shared/utils/entityChipColors';
import Stack from '@mui/material/Stack';
import { DesignType } from '@/lib/enums';
import Box from '@/components/Box';
import EntityChip from '@/components/EntityChip/EntityChip';
import Typography from '@/components/Typography';

const ScenarioNavigationItem = ({
  entityType,
  scenario,
  selected,
  onClick,
}) => {
  const entityColors = entityChipColors(entityType);

  const handleClick = () => {
    onClick(scenario);
  };

  return (
    <Box
      selected={selected ? 'selected' : ''}
      colors={entityColors}
      onClick={handleClick}
      sx={{
        cursor: 'pointer',
        padding: '4px 12px',
        background: selected ? `${entityColors.highlight}` : 'transparent',
        transition: 'background-color 300ms',
        '&:hover .selection-indicator': {
          scale: 1,
          backgroundColor: selected ? entityColors.border : 'transparent',
          boxShadow: `inset 0 0 0 2px  ${entityColors.border}`,
        },
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="flex-start"
        width="100%"
        sx={{
          '&:hover .scenario-title': {
            textDecoration: selected ? 'inherit' : 'underline',
          },
        }}
      >
        <Typography
          variant="p"
          sx={{
            color: 'inherit',
            fontWeight: selected ? 500 : 400,
            fontSize: '0.85rem',
            mr: 0.75,
          }}
        >
          {scenario.__isScenario
            ? `Scenario: ${scenario?.title}`
            : scenario?.title}
        </Typography>
        {!scenario.__isScenario && <EntityChip type={DesignType.LIVE} />}
      </Stack>
    </Box>
  );
};

export default ScenarioNavigationItem;
