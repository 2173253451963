import { sumColumnValues } from '@/organisms/tables/utils';

export default function uniqueValueColumn(params, valueColumn, uniqueIdColumn) {
  const { api, node } = params;
  const column = api.getColumn(valueColumn);
  const idColumn = api.getColumn(uniqueIdColumn);
  const colId = node.getValueFromValueService(idColumn) ?? 'unallocated';

  const value = sumColumnValues({
    node,
    column,
    idColumn,
    idSet: new Set(),
    total: 0,
  });

  return {
    colId,
    value,
    toString: () => (isNaN(value) ? null : `${value?.toFixed(2)}`),
  };
}
