import { RowColors, stackColor } from '@/organisms/charts';
import { EntityType } from '@/shared/enums';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';

const getColors = ({ metric, row }) => {
  if (!row?.isStackChild) {
    return RowColors[row.type]?.bar;
  }

  const value = row?.data?.[metric];
  const total = row?.parentData?.[metric];
  const colorType =
    row?.parentType === EntityType.PROPERTY ? undefined : row?.parentType;

  const percentage = percent(value, total);

  return stackColor(percentage, colorType);
};

const VerticalBar = ({
  barWidth,
  chartHeight,
  metric,
  minBarSize = 8,
  padding = 8,
  row,
  stackData,
  xPosition,
  yPosition,
}) => {
  const height = yPosition > minBarSize ? yPosition : minBarSize;
  const colors = getColors({
    metric: metric === EntityMetric.PERCENTAGE ? EntityMetric.HOURS : metric,
    row,
  });

  return (
    <g className="vertical-bar">
      <rect
        width={barWidth - padding}
        height={6}
        rx={3}
        ry={3}
        x={xPosition}
        y={chartHeight - 4}
        fill={colors.borderColor}
      />
      <rect
        width={barWidth - padding}
        height={height}
        rx={3}
        ry={3}
        x={xPosition}
        y={chartHeight - height}
        fill={colors.backgroundColor}
      />
    </g>
  );
};

export default VerticalBar;
