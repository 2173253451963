import { DesignEntity } from '@/lib/enums';

const groupMap = Object.freeze({
  [DesignEntity.ROLE]: [],
  [DesignEntity.ACTIVITY]: [],
  [DesignEntity.PERSON]: [],
});

const roleMap = Object.freeze({
  [DesignEntity.GROUP]: [],
  [DesignEntity.ACTIVITY]: [],
  is_manager: null,
});

const personMap = Object.freeze({
  [DesignEntity.ROLE]: [],
});

/**
 * Mapping:
 *  Roles to groups and activities, with a manager flag
 *  Groups to roles, unallocated activities and people
 *  People to roles
 * @param {Object} entities (keyed)
 * @returns {Map}
 */
export default function getRelatedEntities(entities) {
  const map = {
    [DesignEntity.GROUP]: new Map(),
    [DesignEntity.ROLE]: new Map(),
    [DesignEntity.PERSON]: new Map(),
  };

  if (!entities) {
    return map;
  }

  // groups
  Object.values(entities.groups).forEach((group) => {
    map[DesignEntity.GROUP].set(group.uuid, structuredClone(groupMap));
  });

  // roles
  Object.values(entities.roles).forEach((role) => {
    // add to GROUP relations
    if (role.group_uuid) {
      map[DesignEntity.GROUP]
        .get(role.group_uuid)
        ?.[DesignEntity.ROLE].push(role.uuid);

      if (role.user_uuid) {
        map[DesignEntity.GROUP]
          .get(role.group_uuid)
          ?.[DesignEntity.PERSON].push(role.user_uuid);
      }
    }

    // add to PERSON relations
    if (role.user_uuid) {
      if (!map[DesignEntity.PERSON].has(role.user_uuid)) {
        map[DesignEntity.PERSON].set(
          role.user_uuid,
          structuredClone(personMap)
        );
      }
      map[DesignEntity.PERSON]
        .get(role.user_uuid)
        ?.[DesignEntity.ROLE].push(role.uuid);
    }

    // add to ROLE relations
    const item = structuredClone(roleMap);
    if (role.group_uuid) {
      item[DesignEntity.GROUP].push(role.group_uuid);
    }
    item.is_manager = role.is_manager;
    map[DesignEntity.ROLE].set(role.uuid, item);
  });

  // activities
  Object.values(entities.activities).forEach((activity) => {
    // unallocated activities
    if (activity.owner_type === DesignEntity.GROUP) {
      map[DesignEntity.GROUP]
        .get(activity.owner_uuid)
        ?.[DesignEntity.ACTIVITY].push(activity.uuid);
      return;
    }

    // owner is a role
    const role = entities.roles[activity.owner_uuid];
    if (!role) {
      return;
    }

    // role activities
    map[DesignEntity.ROLE]
      .get(activity.owner_uuid)
      ?.[DesignEntity.ACTIVITY].push(activity.uuid);

    // if role has a group, add to group activities
    if (role?.group_uuid) {
      map[DesignEntity.GROUP]
        .get(role.group_uuid)
        ?.[DesignEntity.ACTIVITY].push(activity.uuid);
    }
  });

  return map;
}
