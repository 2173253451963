import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import shortuuid from '@pkg/uuid';
import { GET_PEOPLE_SKILLS } from '../usePeopleSkills';
import useStore from '../useStore';

const CREATE_SKILL = /* GraphQL */ `
  mutation CreateLibrarySkill($input: CreateLibrarySkillInput!) {
    createLibrarySkill(input: $input) {
      uuid
      name
    }
  }
`;

/**
 * @return {Function}
 */
export default function useCreate() {
  const { mutateAsync } = useMutation({
    invalidateQuery: GET_PEOPLE_SKILLS,
    mutation: CREATE_SKILL,
  });

  const database = useDatabase();
  const { put } = useStore();

  /**
   * Optimistically add a skill in storage
   * @todo handle failure
   */
  async function optimisticCreate(input) {
    put(input);
    await database.skills.put(input);
  }

  /**
   * @param {String} organisationId
   * @param {Object} input
   * @param {String} [input.uuid]
   * @param {String} input.name
   * @returns {Promise}
   */
  async function createSkill(organisationId, input) {
    if (!input.uuid) {
      input.uuid = shortuuid.generate();
    }

    await optimisticCreate(input);
    return mutateAsync({ organisation_uuid: organisationId, ...input });
  }

  return useCallback(createSkill, [mutateAsync]);
}
