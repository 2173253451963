import { useEffect, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Label } from '@/atoms/typography';
import { SelectField } from '@/molecules/formElements';
import { Popover } from '@/molecules/modals';
import DesignOption from '@/molecules/navigation/DesignSelector/DesignOption';
import { styled } from '@mui/material/styles';
import { Position } from '@/lib/enums';
import Box from '@/components/Box';
import useEntityMap from './hooks/useEntityMap';
import RevisionSelect from './parts/RevisionSelect';
import prepareDesignOptions from './utils/prepareDesignOptions';

const Root = styled(Box)({
  minWidth: 500,
  display: 'flex',
  flexDirection: 'column',
  gap: 16,
  backgroundColor: 'white',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  padding: 16,
});

export default function SelectComparison({ current, onSelect }) {
  const entityMap = useEntityMap();
  const options = prepareDesignOptions(entityMap, current);
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [selectedRevision, setSelectedRevision] = useState(null);

  const handleSelectDesign = (option) => {
    setSelectedDesign(option);
  };

  const handleSelectRevision = ({ designId, revisionId, entity }) => {
    setSelectedRevision({
      designId,
      revisionId,
      entity,
    });
  };

  const handleCompare = () => {
    onSelect(selectedRevision);
  };

  useEffect(() => {
    if (!options?.length) {
      return;
    }

    handleSelectDesign(options[0]);
  }, [options.length]);

  return (
    <Popover
      position={Position.BOTTOM}
      Component={<Button label="Compare" color="secondary" />}
    >
      <Root>
        <Label>Compare the current scenario with...</Label>
        <SelectField
          initialValue={selectedDesign}
          options={options}
          OptionComponent={<DesignOption />}
          onChange={handleSelectDesign}
          width="100%"
        />
        <RevisionSelect
          onSelect={handleSelectRevision}
          selectedDesign={selectedDesign}
        />
        <Box display="flex" justifyContent="flex-end" sx={{ mt: 1 }}>
          <Button color="secondary" label="Select" onClick={handleCompare} />
        </Box>
      </Root>
    </Popover>
  );
}
