/**
 * Simple unbiased shuffle algorithm (Fisher-Yates Shuffle).
 * @param {any[]} items
 * @return {Array}
 */
export default function shuffle(items) {
  let currentIndex = items.length;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    [items[currentIndex], items[randomIndex]] = [
      items[randomIndex],
      items[currentIndex],
    ];
  }

  return items;
}
