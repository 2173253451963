import { FlowStatus } from '@/shared/enums';
import { CampaignStatus } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';

const StatusColor = Object.freeze({
  [CampaignStatus.OPEN]: {
    color: brand.green.main,
    borderColor: brand.green.main,
  },
  [CampaignStatus.CLOSED]: {
    color: brand.orange.tints[6],
    borderColor: brand.orange.tints[4],
  },
  [CampaignStatus.INCOMPLETE]: {
    borderColor: brand.coral.tints[6],
    color: brand.coral.main,
  },
  [CampaignStatus.CANCELED]: {
    borderColor: brand.coral.tints[6],
    color: brand.coral.main,
  },
  [FlowStatus.IN_PROGRESS]: {
    color: brand.orange.tints[6],
    borderColor: brand.orange.tints[4],
  },
  [CampaignStatus.EXPIRED]: {
    borderColor: brand.grey.tints[5],
    color: brand.grey.tints[7],
  },
});

export default StatusColor;
