import { DoughnutChartCenterLabel } from '@/molecules/chartElements/Doughnut';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { fteToHours } from '@pkg/utils/numbers';
import { brand } from '@/lib/theme/tokens/palettes';

const RoleActivityHoursChart = () => {
  const scenario = useScenarioContext();

  const fteHours = fteToHours(scenario?.scenario?.entity?.fte);

  return (
    <DoughnutChartCenterLabel
      label="HOURS PER WEEK"
      numerator={scenario?.scenario?.entity?.__total_metrics?.hours}
      denominator={fteHours}
      maxPercent={100}
      color={brand.blue.main}
      width="160px"
    />
  );
};

export default RoleActivityHoursChart;
