import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';

const UPDATE_ACTIVITY = /* GraphQL */ `
  mutation UpdateLibraryActivity($input: UpdateLibraryActivityInput!) {
    updateLibraryActivity(input: $input) {
      uuid
      description
    }
  }
`;

/**
 * @return {Function}
 */
export default function useUpdate() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({
    mutation: UPDATE_ACTIVITY,
  });

  /**
   * @param {String} uuid
   * @param {String} description
   * @returns {Promise}
   */
  function updateActivity(uuid, description) {
    const input = { uuid, description };
    database.activities.put(input); // @todo rollback
    return mutateAsync(input);
  }

  return useCallback(updateActivity, [mutateAsync]);
}
