import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import tagColor from '@pkg/utils/colors/tag';
import { percent } from '@pkg/utils/numbers';
import excludeProps from '@/lib/theme/excludeProps';
import { typography } from '@/lib/theme/tokens';
import Chart from '@/components/Chart';
import Typography from '@/components/Typography';

const ChartContainer = styled('div')({
  position: 'relative',
  height: 80,
  width: 80,
});

const ChartLabel = styled(Typography, {
  shouldForwardProp: excludeProps(['color']),
})(({ color }) => ({
  ...typography.h5,
  color: color?.base?.outline ?? '#000',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
}));

const Summary = styled(Typography)({
  flex: 1,
  paddingLeft: 16,
});

const TagSummary = ({ tag, metrics, summary }) => {
  const percentage = percent(metrics.taggedHours, metrics.totalHours);
  const color = tagColor({ color: tag?.color });

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ width: '100%' }}
    >
      <ChartContainer>
        <ChartLabel color={color} variant="subtitle2">
          {percentage}%
        </ChartLabel>
        <Chart
          type="doughnut"
          data={{
            datasets: [
              {
                backgroundColor: color?.base?.fill
                  ? [color?.base?.fill, `${color.base.fill}1f`]
                  : ['#000', '#f3f3f3'],
                borderWidth: 0,
                data: [percentage, 100 - percentage],
              },
            ],
          }}
          options={{
            cutout: '75%',
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
            responsive: true,
          }}
        />
      </ChartContainer>
      <Summary>{summary}</Summary>
    </Stack>
  );
};

export default TagSummary;
