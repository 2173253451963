import { Lens } from '@/lib/enums';
import gap from './gap';
import outdatedRoles from './outdatedRoles';
import productivity from './productivity';
import tag from './tag';
import unutilised from './unutilised';
import workHealth from './workHealth';
import workload from './workload';

const CALCULATIONS = {
  [Lens.PRODUCTIVITY]: productivity,
  [Lens.TAG]: tag,
  [Lens.WORK_HEALTH]: workHealth,
  [Lens.WORKLOAD]: workload,
  [Lens.OUTDATED_ROLES]: outdatedRoles,
  [Lens.GAP]: gap,
  [Lens.UNUTILISED]: unutilised,
};

/**
 * @param {Object} snapshot
 * @param {Object} lens
 * @param {Object} tags
 * @returns {Object}
 */
export default function apply(snapshot, lens, library) {
  if (!lens?.type) {
    return snapshot;
  }

  if (!snapshot?.entities) {
    return snapshot;
  }

  const context = CALCULATIONS[lens.type] ?? null;

  if (!context) {
    return snapshot;
  }

  console.time('Snapshots.lenses.apply');

  const { skills, tags } = library;

  const result = context({
    snapshot,
    lens,
    peopleSkills: skills.people,
    tags: tags.tags,
  });

  console.timeEnd('Snapshots.lenses.apply');

  return result;
}
