import { useDispatchContext } from '@/shared/providers';
import Menu from './Menu';

const DispatchMenu = ({ children, size }) => {
  const { handleDispatch } = useDispatchContext();

  return (
    <Menu size={size} onDispatch={handleDispatch}>
      {children}
    </Menu>
  );
};

export default DispatchMenu;
