import PropTypes from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Button from '@/components/Button';
import DateTime from '@/components/DateTime';
import Dialog from '@/components/Dialog';
import * as UserAvatar from '@/components/UserAvatar';

const PresenceDialog = ({ users, open, onClose, ...props }) => {
  return (
    <Dialog onClose={onClose} open={open} {...props} fullWidth maxWidth="xs">
      <DialogTitle>Users currently online</DialogTitle>
      <DialogContent>
        <List>
          {users.map((user, index) => (
            <ListItem key={user.uuid} divider={users.length !== index + 1}>
              <ListItemAvatar>
                <UserAvatar.Static user={user} />
              </ListItemAvatar>
              <ListItemText
                primary={`${user.first_name} ${user.last_name}`}
                secondary={
                  <>
                    Here <DateTime.Relative date={user.present_at} />
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          Okay
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PresenceDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PresenceDialog;
