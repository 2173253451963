/**
 * @param {any[]} a
 * @param {any[]} b
 * @param {string} key
 * @return {any[]}
 */
export function diffBy(a, b, key) {
  return a.filter((a) => !b.some((b) => a[key] === b[key]));
}

/**
 * @param {any[]} a
 * @param {any[]} b
 * @return {any[]}
 */
export function diffByUuid(a, b) {
  return diffBy(a, b, 'uuid');
}
