import { useRef } from 'react';
import { styled } from '@mui/material/styles';
import useRenderChart from '../useRenderChart';
import renderChart from './renderChart';

const Root = styled('div')({});

const QuadrantChart = ({
  classes,
  id,
  axisLabels,
  quadrants,
  selectQuadrant,
  ...props
}) => {
  const chartRef = useRef();

  const properties = {
    axisLabels,
    chartRef,
    id,
    classes: classes ?? {},
    data: quadrants,
    selectQuadrant,
  };

  useRenderChart(properties, renderChart);

  return <Root ref={chartRef} {...props} />;
};

export default QuadrantChart;
