import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import Grid from '@mui/material/Grid';
import Box from '@/components/Box';
import TagDoughnut from './TagDoughnut';
import columnLayout from './utils/columnLayout';

const TagsByCategory = ({
  activeTag,
  comparison,
  isCondensedView,
  direction = 'column',
  onClick,
  tags,
  title,
  totalHours,
}) => {
  const handleClick = (categoryId, tagId) => {
    onClick(categoryId, tagId);
  };

  const size = isCondensedView ? Size.SMALL : Size.MEDIUM;

  const layout = columnLayout({
    isCondensedView,
    activeTag,
    isVertical: direction === 'column',
  });

  return (
    <Box>
      {title && (
        <Heading variant="h5" color={Colors.Base.font.primary}>
          {title}
        </Heading>
      )}
      {tags.length > 0 && (
        <Grid
          container
          columns={layout.columnCount}
          spacing={1}
          alignItems="stretch"
        >
          {tags.map((tag) => (
            <Grid item key={tag.uuid} sx={{ mb: 2 }} {...layout.columns}>
              <TagDoughnut
                comparison={comparison}
                direction={direction}
                onClick={handleClick}
                size={size}
                tag={tag}
                totalHours={totalHours}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default TagsByCategory;
