import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';
import useStore from '../useStore';

const REMOVE_SKILL = /* GraphQL */ `
  mutation RemoveLibrarySkill($input: RemoveLibrarySkillInput!) {
    removeLibrarySkill(input: $input)
  }
`;

/**
 * @return {Function}
 */
export default function useRemove() {
  const { mutateAsync } = useMutation({ mutation: REMOVE_SKILL });

  const database = useDatabase();
  const { remove } = useStore();

  /**
   * Optimistically remove skill from storage
   * @todo handle failure
   */
  async function optimisticRemove(skillId) {
    remove(skillId);
    await database.skills.delete(skillId);
  }

  /**
   * @param {String} skillId
   * @returns {Promise}
   */
  async function removeSkill(skillId) {
    await optimisticRemove(skillId);
    return mutateAsync({ uuid: skillId });
  }

  return useCallback(removeSkill, [mutateAsync, database, remove]);
}
