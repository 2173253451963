import { Colors } from '@pkg/utils';

/**
 * @param {Function} calculate
 * @param {String} type
 * @param {String} palette
 * @returns
 */
export default function derived(calculate, type) {
  return (entities) => {
    const { score, threshold, palette } = calculate(entities);
    const { color, contrast } = Colors.lens(palette ?? 'status', score);

    return {
      color,
      contrast,
      threshold,
      type,
      value: Math.round(score),
    };
  };
}
