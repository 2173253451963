import { useCallback } from 'react';
import { useMutation } from '@pkg/graphql';
import { RevisionFragment } from '../fragments';
import useUpdateFromResult from './hooks/useUpdateFromResult';

const MUTATE_DESIGN = /* GraphQL */ `
  mutation MutateDesign($input: MutateDesignInput!) {
    mutateDesign(input: $input) {
      uuid
      latest {
        ...RevisionFragment
      }
    }
  }

  ${RevisionFragment}
`;

export default function useMutate() {
  const { mutateAsync } = useMutation({ mutation: MUTATE_DESIGN });
  const update = useUpdateFromResult();

  /**
   * @param {String} design_uuid
   * @param {Object} mutation
   * @param {String} [revision_uuid]
   * @returns {Promise}
   */
  async function mutate(design_uuid, mutation, revision_uuid) {
    const result = await mutateAsync({ design_uuid, revision_uuid, mutation });
    await update(result.data?.mutateDesign);
    return result;
  }

  return useCallback(mutate, [mutateAsync, update]);
}
