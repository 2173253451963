import { useState } from 'react';
import { brand } from '@/lib/theme/tokens/palettes';
import CenteredStackBar from '../CenteredStackBar';

const CenteredRow = ({
  barHeight,
  chartWidth,
  focused,
  labelWidth,
  metric,
  metricTotals,
  onClick,
  row,
  showStack,
  topMargin,
  width,
  xScale,
  yScale,
}) => {
  const yPosition = yScale(row.id) + topMargin;

  const [isHover, setIsHover] = useState(false);

  const handleHover = (show) => {
    setIsHover(show);
  };

  const handleClick = () => {
    onClick?.(row);
  };

  return (
    <g
      className="horizontal-chart-row"
      height={barHeight}
      width={width}
      onMouseOver={() => handleHover(true)}
      onMouseOut={() => handleHover(false)}
      onClick={handleClick}
      x={0}
      y={yPosition}
      style={{
        cursor: 'pointer',
      }}
    >
      <rect
        x={0}
        y={yPosition - 4}
        height={barHeight + 10}
        width={width}
        fill={isHover ? brand.lightBlue.tints[4] : 'transparent'}
      />
      <CenteredStackBar
        barHeight={barHeight}
        chartWidth={chartWidth}
        focused={focused}
        labelWidth={labelWidth}
        metric={metric}
        metricTotals={metricTotals}
        onClick={onClick}
        row={row}
        showStack={showStack}
        width={width}
        xScale={xScale}
        yPosition={yPosition}
      />
    </g>
  );
};

export default CenteredRow;
