import { Size } from '@/atoms/enums';
import MuiAvatar from '@mui/material/Avatar';
import { brand, core } from '@/lib/theme/tokens/palettes';
import AvatarSize from './AvatarSize';

const Avatar = ({
  children,
  onClick,
  name,
  showBorder,
  size = Size.MEDIUM,
  sizeProps,
  src,
}) => {
  const avatarSize = sizeProps ?? AvatarSize[size];

  return (
    <MuiAvatar
      src={src}
      alt={name}
      onClick={onClick}
      sx={{
        ...avatarSize,
        backgroundColor: brand.lightBlue.shades[3],
        boxShadow: showBorder ? `0 0 0 1px ${core.white}` : 'none',
      }}
    >
      {children}
    </MuiAvatar>
  );
};

export default Avatar;
