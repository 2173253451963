import 'chart.js/auto';
import { useRef } from 'react';
import {
  Bar,
  Bubble,
  Doughnut,
  PolarArea,
  getElementAtEvent,
} from 'react-chartjs-2';

const TYPES = {
  bar: Bar,
  doughnut: Doughnut,
  bubble: Bubble,
  polarArea: PolarArea,
};

const Chart = ({ type, onClick, ...props }) => {
  const chartRef = useRef();

  const handleClick = (event) => {
    if (!onClick) {
      return;
    }

    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    const element = getElementAtEvent(chart, event);

    if (!element.length) {
      return;
    }

    onClick(element[0]);
  };

  const Component = TYPES[type];
  return <Component ref={chartRef} onClick={handleClick} {...props} />;
};

export default Chart;
