import { percentageChange } from '@/shared/utils/calculations';
import { percent } from '@pkg/utils/numbers';

export default function tagPercentages(tag, totalHours, comparison) {
  const percentages = {
    tag: null,
    change: null,
  };

  if (!tag?.__aggregateHours) {
    return percentages;
  }

  percentages.tag = percent(tag.__aggregateHours, totalHours, 2);

  const comparisonTag = comparison?.tags?.get(tag.uuid);

  if (!comparisonTag?.__aggregateHours) {
    return percentages;
  }

  const comparisonPercentage = percent(
    comparisonTag.__aggregateHours,
    comparison.totalHours,
    2
  );

  return {
    ...percentages,
    change: percentageChange(comparisonPercentage, percentages.tag),
  };
}
