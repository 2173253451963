import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object} entity
 * @param {Object} keyed
 * @returns {Object|null}
 */
export default function getOwner(entity, keyed) {
  const entities = keyed.entities;

  if (entity.__type === DesignEntity.ACTIVITY) {
    const plural = DesignEntity.toPlural(entity.owner_type);
    return entities[plural][entity.owner_uuid] ?? null;
  }

  if (entity.__type === DesignEntity.ROLE) {
    return entities.groups[entity.group_uuid] ?? null;
  }

  return null;
}
