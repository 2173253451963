import { Size } from '@/atoms/enums';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import SwitchColors from './SwitchColors';
import SwitchSize from './SwitchSize';

const Switch = ({
  checked,
  color = 'primary',
  colorProps,
  label,
  labelPlacement = 'start',
  onClick,
  readOnly,
  size = Size.MEDIUM,
}) => {
  const colors = colorProps ?? SwitchColors[color];
  const sizeProps = SwitchSize[size];

  return (
    <FormControlLabel
      control={
        <MuiSwitch
          onChange={onClick}
          disabled={readOnly}
          checked={checked}
          size={size}
          sx={{
            '& .MuiSwitch-switchBase': {
              color: colors.normal.notchColor,
              '&.Mui-checked': {
                color: colors.selected.notchColor,
                '& + .MuiSwitch-track': {
                  backgroundColor: colors.selected.trackColor,
                },
              },
              '& + .MuiSwitch-track': {
                backgroundColor: colors.normal.trackColor,
              },
              '&.Mui-disabled': {
                color: colors.disabled.notchColor,
                '& + .MuiSwitch-track': {
                  backgroundColor: colors.disabled.trackColor,
                  opacity: 0.5,
                },
              },
            },
            '& + .MuiFormControlLabel-label': {
              '&.Mui-disabled': {
                color: 'inherit',
                opacity: 0.75,
              },
            },
          }}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      value={checked}
      sx={{
        ml: 0,
        '& .MuiFormControlLabel-label': {
          ...sizeProps,
          mr: 0.5,
          ml: 0.5,
        },
        color: colors.normal.color,
      }}
    />
  );
};

export default Switch;
