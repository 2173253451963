import Link from 'next/link';
import { cloneElement } from 'react';
import { Colors } from '@/atoms/buttons';
import { ButtonSize, IconButtonSize } from '@/atoms/buttons/utils';
import { Size } from '@/atoms/enums';
import { Orientation } from '@/atoms/enums';
import Stack from '@mui/material/Stack';
import Typography from '@/components/Typography';

const LinkButton = ({
  boldText,
  color = 'primary',
  colorProps,
  startIcon,
  label,
  orientation = Orientation.HORIZONTAL,
  path,
  iconSize,
  size = Size.MEDIUM,
  variant = 'contained',
}) => {
  const textProps = ButtonSize[size];
  const colors = colorProps ?? Colors.button[color][variant];
  const isVertical = orientation === Orientation.VERTICAL;
  const iconSizeProps = IconButtonSize[iconSize ?? size];

  return (
    <Link href={path}>
      <Stack
        direction={isVertical ? 'column' : 'row'}
        alignItems="center"
        sx={{
          ...colors.normal,
          borderRadius: '4px',
          px: 1,
          pt: isVertical ? 1 : 0,
          transition: 'color 300ms, background-color 300ms, boxShadow 300ms',
          '&:hover': {
            ...colors.hover,
          },
          '&:focus': {
            ...colors.focus,
          },
        }}
      >
        {startIcon &&
          cloneElement(startIcon, {
            sx: {
              width: iconSizeProps.fontSize,
              height: iconSizeProps.fontSize,
            },
          })}
        <Typography
          variant="span"
          sx={{
            ...textProps,
            fontWeight: boldText ? 500 : 400,
          }}
        >
          {label}
        </Typography>
      </Stack>
    </Link>
  );
};

export default LinkButton;
