import { Collections } from '@pkg/utils';
import { BeamibleTag } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {String[]} ids
 * @returns {Object}
 */
export default function prioritize(snapshot, ids) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [],
      },
      activities: {
        update: [],
      },
    },
  };

  const mappedRoles = Collections.mapById(entities.roles);
  const prioritizedRoles = new Set();

  ids.forEach((id) => {
    if (mappedRoles.has(id)) {
      prioritizedRoles.add(id);
    }
  });

  if (prioritizedRoles.size === 0) {
    return null;
  }

  const roleActivities = Collections.groupBy(entities.activities, 'owner_uuid');
  prioritizedRoles.forEach((id) => {
    const role = mappedRoles.get(id);
    const activities = roleActivities[id] ?? [];
    const totalActivities = activities.length;
    let isDifferent = false;

    activities.forEach((activity) => {
      const isImportant = activity.tags.some(
        (id) => id === BeamibleTag.STRATEGIC_IMPORTANCE
      );

      if (!isImportant && !activity.disabled_at) {
        isDifferent = true;
        mutation.entities.activities.update.push({
          uuid: activity.uuid,
          updated_at: now,
          order: totalActivities,
          disabled_at: now,
        });
      }
    });

    if (isDifferent) {
      mutation.entities.roles.update.push({ uuid: role.uuid, updated_at: now });
    }
  });

  return mutation;
}
