import { Base as BaseColor } from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const DividerColor = Object.freeze({
  primary: {
    borderColor: BaseColor.border.primary,
  },
  secondary: {
    borderColor: brand.lightBlue.tints[4],
  },
  white: {
    borderColor: core.white,
  },
});

export default DividerColor;
