import { DesignEntity } from '@/lib/enums';

export default class GraphQLError extends Error {
  /**
   * @param {object[]} response
   */
  constructor(response) {
    super('GraphQLError');
    this.name = 'GraphQLError';
    this.response = response;
    this.graphQLErrors = response;
    this.fieldErrors = this._mapFieldErrors(response);
  }

  hasErrors() {
    return Boolean(this.graphQLErrors?.length > 0);
  }

  isAuthenticationError() {
    return this._hasErrorCategory('authentication');
  }

  isAuthorizationError() {
    return this._hasErrorCategory('authorization');
  }

  getFieldErrors() {
    return this.fieldErrors;
  }

  getErrorMessages() {
    const mapped = {};
    this.response.map((error) => {
      let parts = error.message.split(';');
      const description = parts.pop();
      const fieldString = parts.pop();
      if (!fieldString || !description) {
        return;
      }
      parts = fieldString.split(' ');
      const field = parts.filter((part) => part.includes('value.'));
      mapped[this._friendlyName(field[0] ?? 'field')] = [description];
    }) ?? [];
    return mapped;
  }

  _mapFieldErrors(errors) {
    const mapped = {};

    if (!this.hasErrors()) {
      return mapped;
    }

    /** @todo refactor to support lighthouse 6 */
    errors.forEach((error) => {
      if (error.extensions && error.extensions.category === 'validation') {
        const spread = { ...mapped, ...error.extensions.validation };
        Object.entries(spread).map(([field, errors]) => {
          const key = this._friendlyName(field);
          const value = errors.map((error) =>
            error.replace(field, this._friendlyName(field))
          );
          mapped[key] = value;
        });
      }
    });

    return mapped;
  }

  _hasErrorCategory(category) {
    if (!this.hasErrors()) {
      return false;
    }

    return this.graphQLErrors.some((error) => {
      const cat = error.extensions?.category ?? '';
      return cat === category;
    });
  }

  _friendlyName(field) {
    const parts = field
      .replace(/^input\./, '')
      .replace(/^value\./, '')
      .split('.');
    if (parts.length < 3) {
      return field;
    }

    const entity = DesignEntity.fromPlural(parts[2]).toLowerCase();
    const property = parts.pop();

    return field.includes('mutation') ? `${entity} ${property}` : field;
  }
}
