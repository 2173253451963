import { useEffect, useRef, useState } from 'react';
import { EntityChip } from '@/atoms/chips';
import { MetricProperty, Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { TextMetricGroup } from '@/molecules/metrics';
import { EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';

const getMetric = ({ entity, metric }) => {
  switch (metric) {
    case EntityMetric.SPAN:
      if (
        entity.type === EntityType.MANAGER ||
        entity.type === EntityType.SPAN ||
        entity.type === EntityType.LAYER
      ) {
        return metric;
      }
      return EntityMetric.FTE;
    case EntityMetric.AVERAGE_SPAN:
      if (entity.type === EntityType.LAYER || entity.type === EntityType.SPAN) {
        return metric;
      }
      if (entity.type === EntityType.MANAGER) {
        return EntityMetric.SPAN;
      }
      return EntityMetric.FTE;
    default:
      return metric;
  }
};

const EntityTableRow = ({ entity, focusId, metric, onClick, visible }) => {
  const ref = useRef();
  const childCount = (entity?.id?.split(':')?.length ?? 1) - 1;
  const entityType = entity?.props?.isManager
    ? EntityType.MANAGER
    : entity.type;
  const rowMetric = getMetric({ entity, metric });
  const value = entity.data[rowMetric] ?? entity.data[EntityMetric.HOURS];
  const metricProperty = MetricProperty[rowMetric ?? EntityMetric.HOURS];
  const label = metricProperty.label.short
    ? metricProperty.label.short
    : value > 0
      ? metricProperty.label.plural
      : metricProperty.label.singular;
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    const isCurrentFocus = focusId === entity.id.split(':')[0];

    if (isCurrentFocus && !isFocused) {
      ref.current.scrollIntoView({ behaviour: 'smooth', block: 'nearest' });
      setIsFocused(true);
      return;
    }

    if (!isCurrentFocus && isFocused) {
      setIsFocused(false);
    }
  }, [focusId]);

  return (
    <Stack
      direction="row"
      alignItems="flex-end"
      key={entity.id}
      justifyContent="space-between"
      p={0.5}
      width="100%"
      onClick={() => onClick(entity)}
      ref={ref}
      sx={{
        backgroundColor:
          focusId === entity.id ? brand.lightBlue.tints[4] : 'inherit',
        '&:hover': {
          backgroundColor: brand.lightBlue.tints[2],
          cursor: 'pointer',
        },
      }}
    >
      <Box textAlign="left" pl={childCount > 0 ? childCount * 1 : 0}>
        <EntityChip type={entityType} size={Size.XX_SMALL} />
        <Paragraph size={Size.SMALL} overrideStyles={{ mb: 0 }}>
          {entity.label} {entity.data.count > 1 ? `(${entity.data.count})` : ''}
        </Paragraph>
      </Box>
      <TextMetricGroup
        metrics={[
          {
            label,
            value: rowMetric === EntityMetric.PERCENTAGE ? value / 100 : value,
            formatOptions: metricProperty.formatOptions,
          },
        ]}
        alignMetrics="center"
        size={Size.X_SMALL}
      />
    </Stack>
  );
};

export default EntityTableRow;
