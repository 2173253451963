export const core = {
  blue: '#3698F1',
  grey: '#444B51',
  navy: '#0D2E4D',
  pink: '#F66470',
  yellow: '#FACD04',
  black: '#000000',
  white: '#FFFFFF',
};

export const primary = {
  brand: {
    50: '#E6EBEF',
    100: '#CBD3DB',
    200: '#B0BCC7',
    300: '#95A4B2',
    400: '#7A8D9E',
    500: '#5E758A',
    600: '#435D76',
    700: '#284661',
    800: '#0D2E4D',
    900: '#092035',
  },
  grey: {
    100: '#F2F2F2',
    200: '#E1E2E4',
    300: '#C0C4C7',
    400: '#868C93',
    500: '#444B51',
  },
  greyNew: {
    50: '#FAFAFA',
    100: '#E0E1E2',
    200: '#C6C8CA',
    300: '#ACAFB2',
    400: '#929699',
    500: '#787D81',
    600: '#5E6469',
    700: '#444B51',
    800: '#3E444A',
    900: '#383E43',
    1000: '#333333',
  },
};

export const text = {
  dark: primary.greyNew[1000],
  light: primary.greyNew[50],
};

export const system = {
  destructive: {
    50: '#FFF6F7',
    75: '#FFDBDF',
    300: '#BD090C',
    400: '#8D0609',
  },
  interactive: {
    300: '#195994',
    400: '#123F69',
  },
  success: {
    50: '#EFFAF1',
    75: '#CAEDD0',
    300: '#008024',
    400: '#004D16',
  },
};
