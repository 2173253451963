import { Base as BaseColors } from '@/atoms/colors';
import { Colors as InputColors } from '@/atoms/inputs';
import Box from '@mui/material/Box';

const SelectItem = ({
  children,
  color = 'secondary',
  isFocus,
  onClick,
  onFocus,
  value,
}) => {
  const handleKeyDown = (event) => {
    if (event.key == 'Enter') {
      onClick?.(event, value);
    }
  };

  return (
    <Box
      onKeyDown={handleKeyDown}
      onClick={onClick}
      onFocus={onFocus}
      tabIndex={0}
      p={0.5}
      m={0}
      sx={{
        ...InputColors.Select.option[color].normal,
        boxShadow: `inset 0 -1px 0 0 ${InputColors.Select.option.primary.normal.borderColor}77`,
        backgroundColor: 'transparent',
        color: BaseColors.font.primary,
        fontSize: '0.875rem',
        '&:focus': {
          outline: 'none',
        },
        '&:last-child': {
          boxShadow: 'none',
        },
      }}
    >
      <Box
        pl={1}
        pr={2}
        py={0.5}
        sx={{
          backgroundColor: isFocus
            ? InputColors.Select.option[color].hover.backgroundColor
            : null,
          '&:hover, &:focus': {
            ...InputColors.Select.option[color].hover,
            cursor: 'pointer',
          },
          '&:focus': {
            outline: 'none',
            boxShadow: `inset 0 0 0 1px ${InputColors.Select.option[color].focus.borderColor}`,
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default SelectItem;
