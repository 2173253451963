import { useEffect, useState } from 'react';
import { useTaggedScenarioActivities } from '@/shared/hooks';

const useComparisonActivitiesByTag = () => {
  const { primaryTags, customTags, taggedActivityMap } =
    useTaggedScenarioActivities({
      useComparison: true,
    });

  const [comparisonTags, setComparisonTags] = useState();

  useEffect(() => {
    if (!primaryTags && !customTags) {
      return;
    }

    const tagMap = new Map();

    const tags = [...primaryTags, ...customTags];

    tags.forEach((tag) => {
      tagMap.set(tag.uuid, tag);
    });

    setComparisonTags(tagMap);
  }, [
    taggedActivityMap,
    JSON.stringify(primaryTags),
    JSON.stringify(customTags),
  ]);

  return {
    tags: comparisonTags,
    totalHours: taggedActivityMap?.__totalHours,
  };
};

export default useComparisonActivitiesByTag;
