import { SkeletonItem } from '@/atoms/skeletons';
import Stack from '@mui/material/Stack';

const SelectOptionsSkeleton = () => {
  const height = 28;

  return (
    <Stack spacing={1} px={1} my={1}>
      <SkeletonItem height={height} />
      <SkeletonItem height={height} />
      <SkeletonItem height={height} />
      <SkeletonItem height={height} />
      <SkeletonItem height={height} />
    </Stack>
  );
};

export default SelectOptionsSkeleton;
