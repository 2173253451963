import { useSnackbar } from 'notistack';
import { forwardRef, useCallback, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Obj } from '@pkg/utils';
import { color } from '@/lib/theme/tokens';
import Box from '@/components/Box';

const ErrorIcon = {
  error: ErrorOutlineIcon,
  warning: WarningAmberIcon,
};

const SnackbarContainer = styled(Box)({
  '& .MuiCardActions-root': {
    minWidth: '600px',
    backgroundColor: `${color.primary.brand[900]} !important`,
    padding: '4px 14px',
    color: 'white',
  },
  '& .MuiButtonBase-root': {
    color: 'white',
  },
});

const Actions = styled(CardActions)({
  display: 'flex',
  justifyContent: 'space-between',
});

const LoginButton = styled(Button)({
  textDecoration: 'underline',
});

const CollapseContainer = styled(Collapse)({
  padding: '24px',

  '& ul': {
    paddingLeft: 0,
  },
});

const Item = styled('li')({
  listStyleType: 'none',
});

const ErrorNotification = forwardRef((props, ref) => {
  const { message } = props;
  const { closeSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleRefresh = useCallback(() => {
    window.location.reload();
  }, []);

  const handleDismiss = useCallback(() => {
    closeSnackbar(props.id);
  }, [props.id, closeSnackbar]);

  const Icon = ErrorIcon[message.type];
  const showDetails = !Obj.isEmpty(message.details);

  return (
    <SnackbarContainer ref={ref}>
      <Card>
        <Actions>
          <Box display="flex" alignItems="center">
            <Icon />
            <Typography sx={{ ml: 1 }} variant="body2">
              {message.description}
            </Typography>
            {message.showRefresh && (
              <LoginButton onClick={handleRefresh}>
                Back to login page
              </LoginButton>
            )}
            {showDetails && (
              <IconButton
                aria-label="Show more"
                style={expanded ? { transform: 'rotate(180deg)' } : null}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon />
              </IconButton>
            )}
          </Box>
          <IconButton onClick={handleDismiss} sx={{ ml: 'auto' }}>
            <CloseIcon />
          </IconButton>
        </Actions>
        {showDetails && (
          <CollapseContainer in={expanded} timeout="auto" unmountOnExit>
            <ul>
              {Object.entries(message.details).map(([key, error], index) => (
                <Item key={index}>
                  <strong>{key}</strong>
                  {error.map((message, i) => (
                    <Typography variant="body2" key={i}>
                      {message}
                    </Typography>
                  ))}
                </Item>
              ))}
            </ul>
          </CollapseContainer>
        )}
      </Card>
    </SnackbarContainer>
  );
});

ErrorNotification.displayName = 'ErrorNotification';
export default ErrorNotification;
