import { createContext, useContext, useMemo } from 'react';
import { useFlowTemplates } from '@/shared/hooks/flows';

export const FlowTemplatesContext = createContext();
export const useFlowTemplatesContext = () => useContext(FlowTemplatesContext);

const FlowTemplatesProvider = ({ children }) => {
  const { flowTemplates, isLoading } = useFlowTemplates();

  const context = useMemo(() => {
    return {
      flowTemplates,
      isLoading,
    };
  }, [flowTemplates]);

  return (
    <FlowTemplatesContext.Provider value={context}>
      {children}
    </FlowTemplatesContext.Provider>
  );
};

export default FlowTemplatesProvider;
