import { useEffect, useState } from 'react';
import { FilterPresetMap } from '../utils';

const useFilterPresets = (api, dimension) => {
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();

  const load = (option) => {
    if (!option.id) {
      return;
    }

    const currentFilterState = api.getFilterModel();

    const preset = FilterPresetMap?.get(dimension)?.get(option.id)?.preset;

    if (preset) {
      api.setFilterModel({
        ...currentFilterState,
        ...preset,
      });
    } else {
      api.setFilterModel(null);
    }

    setSelected(option);
  };

  const savePreset = () => {
    // Get the list of presets and append this one with a id.
    const columnState = api.getColumnState();
    console.debug('Debug.columnState', columnState);
  };

  useEffect(() => {
    const preset = FilterPresetMap.get(dimension);

    if (!preset || !api) {
      setOptions([]);
      return;
    }

    const options = [...preset.entries()]?.map(
      ([id, { label, defaultOption }]) => {
        return {
          label,
          defaultOption,
          id,
        };
      }
    );

    setOptions(options);

    const selected =
      options.find(({ defaultOption }) => defaultOption) ?? options[0];
    load(selected);
    setSelected(selected);
  }, [dimension, api]);

  return {
    savePreset,
    load,
    options,
    selected,
  };
};

export default useFilterPresets;
