import { createContext, useContext, useMemo, useState } from 'react';
import { useScenario } from '@/shared/hooks';
import useComparison from '@/components/DesignContainer/hooks/useComparison';
import { formatScenario } from '../utils';

export const ScenarioContext = createContext({
  access: null,
  canPublish: null,
  publish: null,
  scenario: null,
  selectScenario: null,
  snapshotEntityMap: null,
});

export const useScenarioContext = () => useContext(ScenarioContext);

const ScenarioProvider = ({
  children,
  designId,
  existingScenario,
  level,
  storeType,
  comparisonMeta,
}) => {
  const [includeNested, setIncludeNested] = useState(true);

  const {
    access,
    canPublish,
    create,
    publish,
    scenario,
    selectScenario,
    snapshotEntityMap,
  } = useScenario({
    designId,
    existingScenario,
    level,
    storeType,
    includeNested,
  });

  const { activeComparison, loadComparison, cancelComparison } =
    useComparison(comparisonMeta);

  const comparisonScenario = useMemo(() => {
    const isHistorical = activeComparison?.revision.is_historical ?? false;
    return activeComparison?.design
      ? formatScenario({
          ...activeComparison,
          includeNested,
          isHistorical,
        })
      : null;
  }, [activeComparison, includeNested]);

  const contextState = useMemo(() => {
    return {
      access,
      canPublish,
      create,
      publish,
      scenario,
      selectScenario,
      snapshotEntityMap,
      includeNested,
      setIncludeNested,
      comparisonScenario,
      // would it be worth including the snapshotEntityMap in the formatScenario output?
      comparisonScenarioEntityMap: activeComparison?.snapshotEntityMap,
      loadComparison,
      cancelComparison,
    };
  }, [
    access,
    canPublish,
    includeNested,
    scenario,
    snapshotEntityMap,
    comparisonScenario,
  ]);

  return (
    <ScenarioContext.Provider value={contextState}>
      {children}
    </ScenarioContext.Provider>
  );
};

export default ScenarioProvider;
