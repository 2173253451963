import { createContext, useContext, useMemo } from 'react';
import { useUserFlows } from '@/shared/hooks';

export const FlowsContext = createContext();
export const useFlowsContext = () => useContext(FlowsContext);

const FlowsProvider = ({ children }) => {
  const { flows, retrieveRoleFlows, isLoading } = useUserFlows();

  const context = useMemo(() => {
    return {
      flows,
      isLoading,
      retrieveRoleFlows,
    };
  }, [flows, isLoading]);

  return (
    <FlowsContext.Provider value={context}>{children}</FlowsContext.Provider>
  );
};

export default FlowsProvider;
