import PropTypes from 'prop-types';
import { memo, useMemo, useRef } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { system } from '@/lib/theme/tokens/color';
import { grey } from '@/lib/theme/tokens/palettes';
import Group from './components/GroupLabel';
import Option from './components/Option';

const ITEM_HEIGHT = 32;
const ITEM_PADDING_TOP = 4;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_PADDING_TOP + ITEM_HEIGHT * 6.5,
      width: 250,
    },
  },
};

const Root = styled(FormControl)({
  position: 'relative',
});

const Label = styled(FormLabel)({
  color: `${grey.colors[700]} !important`,
});

const Clear = styled('span')({
  position: 'absolute',
  color: `${system.interactive[300]}`,
  cursor: 'pointer',
  fontSize: '0.675rem',
  lineHeight: 1,
  right: 2,
  top: 4,
  '&:hover': {
    color: `${system.interactive[400]}`,
    textDecoration: 'underline',
  },
});

const Input = styled(OutlinedInput)(({ value }) => ({
  '& .MuiOutlinedInput-input': {
    color: value ? grey.colors[700] : grey.colors[300],
    '&.Mui-disabled': {
      backgroundColor: grey.colors[50],
      color: 'inherit',
    },
  },
}));

const MultiBox = styled(Checkbox)({
  padding: 0,
  marginRight: 8,
});

const Error = styled('label')({
  color: system.destructive[300],
  fontSize: 12,
  fontWeight: 400,
});

const useGroupedOptions = (options, isGrouped) => {
  return useMemo(() => {
    if (!isGrouped) {
      return options;
    }

    const grouped = {
      __order: ['none'],
      none: {
        label: null,
        options: [],
      },
    };

    options.forEach(({ __group, ...option }) => {
      if (!__group) {
        return grouped.none.options.push(option);
      }

      if (!Object.hasOwn(grouped, __group)) {
        grouped.__order.push(__group);
        grouped[__group] = {
          label: __group,
          options: [],
        };
      }

      grouped[__group].options.push(option);
    });

    return grouped;
  }, [JSON.stringify(options)]);
};

const SelectInput = ({
  className,
  clearLabel = 'Clear',
  disabled,
  enableClear,
  endAdornment,
  error,
  fullWidth = false,
  label,
  grouped = false,
  name,
  onBlur,
  onChange,
  multiple = false,
  options = [],
  placeholder = 'Select option',
  readOnly,
  value: selected,
  ...props
}) => {
  const selectRef = useRef();
  const groupedOptions = useGroupedOptions(options, grouped);
  const selectedSet = Array.isArray(selected) && new Set(selected);

  /** @todo review disable/enable clearing */
  const displayClear = enableClear && !readOnly && !disabled && selected;
  delete props.disableClear;

  const handleBlur = (event) => {
    onBlur?.(event, event.target.value);
  };

  const handleChange = (event) => {
    onChange?.(event, event.target.value);
  };

  const handleClear = (event) => {
    event.target = selectRef.current.node;
    event.target.value = null;
    onChange?.(event, null);
  };

  const renderGroups = () => {
    return groupedOptions.__order.flatMap((key) => {
      const { label, options } = groupedOptions[key];
      const mapped = [];

      if (label) {
        mapped.push(<Group key={key}>{label}</Group>);
      }

      options.forEach(({ value, label }) => {
        mapped.push(
          <Option key={value} value={value}>
            {multiple && (
              <MultiBox checked={selected.includes(value)} size="small" />
            )}
            <span>{label}</span>
          </Option>
        );
      });

      return mapped;
    });
  };

  const renderOptions = () => {
    return options.map(({ value, label }) => (
      <Option key={value} value={value}>
        {multiple && (
          <MultiBox checked={selected.includes(value)} size="small" />
        )}
        <span>{label}</span>
      </Option>
    ));
  };

  return (
    <Root
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      tabIndex={0}
    >
      {Boolean(label) && <Label error={error}>{label}</Label>}
      {displayClear && <Clear onClick={handleClear}>{clearLabel}</Clear>}
      <Select
        displayEmpty
        MenuProps={MenuProps}
        {...props}
        error={error}
        input={<Input endAdornment={endAdornment} value={selected} />}
        inputProps={{ ref: selectRef }}
        multiple={multiple}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        options={options}
        renderValue={(selected) => {
          if (!selected) {
            return placeholder;
          }

          if (!multiple) {
            return options.find(({ value }) => selected === value)?.label;
          }

          if (selected.length === options.length) {
            return 'All';
          }

          return options
            .filter(({ value }) => selectedSet.has(value))
            .map(({ label }) => label)
            .join(', ');
        }}
        readOnly={readOnly}
        value={selected}
      >
        {grouped ? renderGroups() : renderOptions()}
      </Select>
      {error && <Error>{error}</Error>}
    </Root>
  );
};

SelectInput.propTypes = {
  className: PropTypes.string,
  clearLabel: PropTypes.string,
  enableClear: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  grouped: PropTypes.bool,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  readOnly: PropTypes.bool,
  value: PropTypes.any,
};

export default memo(SelectInput);
