import { closeSnackbar, SnackbarProvider } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { useClasses } from '@pkg/hooks';
import { color } from '@/lib/theme/tokens';

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
};

const styles = {
  root: {
    '& .notistack-MuiContent': {
      backgroundColor: `${color.primary.brand[900]} !important`,
      padding: '4px 8px',
      '& div:first-of-type': {
        fontSize: 12,
        fontWeight: 500,
      },
      '& div:nth-child(2)': {
        paddingLeft: 24,
        marginRight: 0,
      },
    },
  },
};

const DismissButton = styled(IconButton)({
  color: 'white',
});

const Provider = ({ children }) => {
  const classes = useClasses(styles);

  return (
    <SnackbarProvider
      anchorOrigin={anchorOrigin}
      classes={classes}
      data-testid="snackbar"
      disableWindowBlurListener
      variant="info"
      action={(key) => (
        <DismissButton
          data-testid="dismiss-snackbar-btn"
          onClick={() => closeSnackbar(key)}
          size="large"
        >
          <CloseIcon />
        </DismissButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Provider;
