import { Status } from '@/atoms/colors';
import { FlowStatus } from '@/shared/enums';
import { CampaignStatus } from '@/lib/enums';

const open = Status[CampaignStatus.OPEN];
const closed = Status[CampaignStatus.CLOSED];
const incomplete = Status[CampaignStatus.INCOMPLETE];
const canceled = Status[CampaignStatus.CANCELED];
const expired = Status[CampaignStatus.EXPIRED];
const inProgress = Status[FlowStatus.IN_PROGRESS];

const StatusChipColor = Object.freeze({
  [CampaignStatus.COMPLETE]: {
    normal: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    focus: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    hover: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
  },
  [CampaignStatus.OPEN]: {
    normal: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    focus: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
    hover: {
      ...open,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${open.borderColor}`,
    },
  },
  [CampaignStatus.CLOSED]: {
    normal: {
      ...closed,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${closed.borderColor}`,
    },
    focus: {
      ...closed,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${closed.borderColor}`,
    },
    hover: {
      ...closed,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${closed.borderColor}`,
    },
  },
  [CampaignStatus.INCOMPLETE]: {
    normal: {
      ...incomplete,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${incomplete.borderColor}`,
    },
    focus: {
      ...incomplete,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${incomplete.borderColor}`,
    },
    hover: {
      ...incomplete,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${incomplete.borderColor}`,
    },
  },
  [CampaignStatus.CANCELED]: {
    normal: {
      ...canceled,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${canceled.borderColor}`,
    },
    hover: {
      ...canceled,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${canceled.borderColor}`,
    },
    focus: {
      ...canceled,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${canceled.borderColor}`,
    },
  },
  [FlowStatus.IN_PROGRESS]: {
    normal: {
      ...inProgress,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${inProgress.borderColor}`,
    },
    focus: {
      ...inProgress,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${inProgress.borderColor}`,
    },
    hover: {
      ...inProgress,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${inProgress.borderColor}`,
    },
  },
  [CampaignStatus.EXPIRED]: {
    normal: {
      ...expired,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${expired.borderColor}`,
    },
    hover: {
      ...expired,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${expired.borderColor}`,
    },
    focus: {
      ...expired,
      backgroundColor: 'transparent',
      boxShadow: `0 0 0 1px ${expired.borderColor}`,
    },
  },
});

export default StatusChipColor;
