import { loadIntercom } from 'intercom-next';
import { cloneElement, useEffect } from 'react';
import env from '@beam-australia/react-env';
import * as Auth from '@pkg/auth';

const IntercomProvider = ({ children, ...props }) => {
  const { me } = Auth.useStore();

  useEffect(() => {
    const APP_ID = env('INTERCOM_APP_ID');

    if (APP_ID && Boolean(me?.intercom_identity)) {
      loadIntercom({
        appId: APP_ID,
        name: me.first_name,
        user_id: me.uuid,
        user_hash: me.intercom_identity,
        delay: 1000,
      });
    }
  }, [me]);

  return cloneElement(children, props);
};

export default IntercomProvider;
