import { Information, InformationParagraph } from '@/molecules/information';

const RolesByTeamChartInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        This chart presents Roles by person and team.
      </InformationParagraph>
      <InformationParagraph>
        Select from the 'Roles by' dropdown to present the data in the preferred
        format.
      </InformationParagraph>
      <InformationParagraph>
        Select the preferred 'Metric Type' from the dropdown to present the
        chart in Percentage (percentage of all roles), Relative Percentage
        (percentage of the role type) FTE or Budget (if permitted).
      </InformationParagraph>
      <InformationParagraph>
        Click on a bar in the chart to reveal further information about the
        Role.
      </InformationParagraph>
    </Information>
  );
};

export default RolesByTeamChartInformation;
