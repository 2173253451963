import * as Organisations from '../../organisations';
import manage from './manage';

/**
 * @param {Object} me
 * @param {Object} permissions
 * @param {Boolean} isScenario
 * @param {Boolean} isHistorical
 * @returns {Boolean}
 */
export default function restore(me, permissions, isScenario, isHistorical) {
  const canManageOrganisation = Organisations.can.manage(me, permissions);
  const canManage = isScenario
    ? manage(me, permissions)
    : canManageOrganisation;
  return isHistorical && canManage;
}
