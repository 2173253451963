export default function sortFloatValues(a, b) {
  const valueA = a?.value ?? a?.percent ?? a;
  const valueB = b?.value ?? b?.percent ?? b;

  if (valueA === valueB) {
    return 0;
  }

  return valueA > valueB ? 1 : -1;
}
