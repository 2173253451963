import { palettes } from '@/lib/theme/tokens';
import { Arr } from '..';

const SET = Object.entries(palettes)
  .filter(([key]) => key !== 'status')
  .map(([, { colors }]) => colors)
  .filter(Boolean);

const CACHE = new Map();

/**
 * @param {string} seed
 * @param {boolean} [shades]
 * @return {string}
 */
export default function seededColor(seed = 0) {
  const isCached = CACHE.has(seed);
  const color = isCached ? CACHE.get(seed) : Arr.seededShuffle(SET, seed)[0];

  if (!isCached) {
    CACHE.set(seed, color);
  }

  return color;
}
