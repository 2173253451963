import { useListen, useObservable } from '@pkg/broadcasting';
import getChannelName from './channelName';

export default function useNotify(designId, eventHandlers) {
  const channelType = 'PrivateChannel';
  const channelName = getChannelName(designId);

  const notify = useObservable(channelName, eventHandlers);

  const handleEvent = (event, data) => {
    notify(event, data);
  };

  useListen({ channelName, channelType }, handleEvent);
}
