import { Avatar } from '@/atoms/avatars';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const CircleMetric = ({ labelHeight, colors, person, value }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="center"
      width={labelHeight}
      height={labelHeight}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        width={labelHeight - 6}
        height={labelHeight - 6}
        sx={{
          borderRadius: labelHeight,
          boxShadow: colors.boxShadow,
          backgroundColor: colors.backgroundColor,
        }}
      >
        {person && (
          <Avatar
            src={person.avatar}
            name={`${person.first_name} ${person.last_name}`}
            sizeProps={{
              fontSize: '0.75rem',
              width: labelHeight - 6,
              height: labelHeight - 6,
            }}
          />
        )}
        {!person && (
          <Paragraph
            size={Size.X_SMALL}
            overrideStyles={{
              mb: 0,
              fontWeight: 700,
              color: colors.color,
            }}
          >
            {value}
          </Paragraph>
        )}
      </Stack>
    </Stack>
  );
};

export default CircleMetric;
