import { Arr, Collections } from '@pkg/utils';
import { ActivityOwner } from '@/lib/enums';
import reduceProperties from '../reduceProperties';

/**
 * @param {Object} snpashot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.library_uuid]
 * @param {Number} [input.hours]
 * @param {String[]} [input.tags]
 * @returns {Object}
 */
export default function update(snapshot, input) {
  console.debug('Snapshots.reducers.activities.update', input);
  const { uuid, library_uuid, hours, tags, properties } = input;
  const entities = snapshot.entities;
  const now = Date.now();

  const activity = Collections.findById(entities.activities, uuid);
  const activityInput = { uuid, updated_at: now };
  let isDifferent = false;

  if (library_uuid && library_uuid !== activity.library_uuid) {
    activityInput.library_uuid = library_uuid;
    isDifferent = true;
  }

  if (Number.isFinite(hours) && hours >= 0 && hours !== activity.hours) {
    activityInput.hours = hours;
    isDifferent = true;
  }

  if (Array.isArray(tags) && Arr.diff(activity.tags, tags).length > 0) {
    activityInput.tags = tags;
    isDifferent = true;
  }

  /** @todo convert to its own reducer and explicitly create/update/remove */
  if (Array.isArray(properties)) {
    const propertiesInput = reduceProperties(activity, properties);

    if (propertiesInput) {
      activityInput.properties = propertiesInput;
      isDifferent = true;
    }
  }

  if (!isDifferent) {
    return null;
  }

  const mutation = {
    created_at: now,
    entities: {
      activities: {
        update: [activityInput],
      },
    },
  };

  const update = { uuid: activity.owner_uuid, updated_at: now };

  if (activity.owner_type === ActivityOwner.GROUP) {
    mutation.entities.groups = { update: [update] };
  }

  if (activity.owner_type === ActivityOwner.ROLE) {
    mutation.entities.roles = { update: [update] };
  }

  return mutation;
}
