import { percent } from '@pkg/utils/numbers';

export default function sumPercentages(params) {
  const { column, pivotResultColumn, rowNode, values } = params;

  const sumPercent =
    params.context?.metrics?.aggData?.[column.colId]?.sumPercent ?? new Map();

  const parentRowNode = rowNode.parent;
  const parentRowKey = parentRowNode?.id;
  const rowKey = rowNode.id;

  // We calculate the current numerator by aggregating the raw cell
  // numerators.
  const numerator = values.reduce((previous, next) => {
    if (next === null || typeof next === 'undefined' || !next) {
      return next;
    }

    if (typeof next === 'number') {
      return (previous += next);
    } else {
      return (previous += next.numerator);
    }
  }, 0);

  if (!sumPercent.get(rowKey)) {
    // When in pivot mode, we get the current row node numerator to
    // use as the denominator because pivot column values are a function of
    // the row total.
    const rowValues = rowNode?.getValueFromValueService(column);
    const total = pivotResultColumn ? rowValues?.numerator : numerator;
    sumPercent.set(rowKey, {
      total,
      parent: parentRowKey,
    });
  }

  // The top most node is only ever iterated on in pivot mode but it
  // doesn't display the result.
  if (!parentRowNode) {
    const percentage = percent(numerator, numerator, 7);
    params.context.metrics = {
      ...params.metrics,
      aggData: {
        ...params.metrics?.aggData,
        sumPercent,
      },
    };

    return {
      numerator,
      denominator: numerator,
      percentage,
      toString: () => (percentage ? `${percentage.toFixed(2)}%` : '0%'),
    };
  }

  // Retrieve the denominator from the parent column.
  if (!sumPercent.get(parentRowKey)) {
    const parentValues = rowNode.getValueFromValueService(column);

    sumPercent.set(parentRowKey, {
      total: parentValues?.denominator ?? numerator,
    });
  }

  // If we're dealing with pivots, we always use the current row as the
  // basis for the denominator.
  const denominatorKey = pivotResultColumn ? rowKey : parentRowKey;
  const denominator = sumPercent.get(denominatorKey)?.total ?? 0;

  const percentage = percent(numerator, denominator, 7);

  // Update the ag-grid context object so we can maintain the state of
  // the row totals.
  params.context.metrics = {
    ...params.metrics,
    aggData: {
      ...params.metrics?.aggData,
      [column.colId]: {
        sumPercent: sumPercent,
      },
    },
  };

  return {
    numerator,
    denominator,
    percentage,
    toString: () => (percentage ? `${percentage.toFixed(2)}%` : '0%'),
  };
}
