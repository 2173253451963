import PropTypes from 'prop-types';
import { cloneElement } from 'react';
import config from '@/lib/config';

const BaseAvatar = ({ component, user, ...props }) => {
  let fallback = `${config.CDN}/statics/unassigned.png`;

  if (user) {
    const name = `${user?.first_name ?? ''} ${user?.last_name ?? ''}`;
    fallback = `https://api.dicebear.com/5.x/initials/png?seed=${name}`;
  }

  const alt = user?.first_name ?? 'User avatar';
  const src = user?.avatar ?? fallback;

  return cloneElement(component, { alt, src, ...props });
};

BaseAvatar.propTypes = {
  component: PropTypes.node,
  user: PropTypes.object,
};

export default BaseAvatar;
