import { Colors } from '@pkg/utils';
import { Lens } from '@/lib/enums';
import { color, palettes } from '@/lib/theme/tokens';
import { getFlattened, getKeyed } from '../utils';
import {
  ThresholdColor,
  ThresholdDay,
  getThresholdBucket,
  getThresholdScore,
} from './threshold';

const LensTemplate = Object.freeze({
  color: palettes.grey.colors[700],
  contrast: color.text[palettes.grey.contrast[700]],
  type: Lens.OUTDATED_ROLES,
  value: 'N/A',
});

/**
 * @param {Object} params.snapshot
 * @param {Object} params.lens
 * @param {Map} params.tags
 * @returns
 */
export default function outdatedRoles({ snapshot }) {
  const applied = getKeyed(snapshot);
  const groups = new Map();

  for (const role of snapshot.entities.roles) {
    const lens = structuredClone(LensTemplate);

    if (!role.__is_complete) {
      applied.entities.roles[role.uuid].__lens = lens;
      continue;
    }

    const now = new Date();
    const lastUpdate = new Date(role.updated_at);

    const dayInMilisecond = 24 * 60 * 60 * 1000;
    const diffTime = Math.abs(now - lastUpdate);
    const value = Math.round(diffTime / dayInMilisecond);

    const threshold = getThresholdBucket({
      thresholdRangeId: Lens.OUTDATED_ROLES,
      value,
    });

    lens.color = ThresholdColor[threshold].color;
    lens.contrast = ThresholdColor[threshold].contrast;
    lens.value = value;
    lens.threshold = threshold;
    applied.entities.roles[role.uuid].__lens = lens;

    if (!role.group_uuid) {
      continue;
    }

    if (role.group_uuid && !groups.has(role.group_uuid)) {
      groups.set(role.group_uuid, []);
    }

    groups.get(role.group_uuid).push(ThresholdDay[threshold]);
  }

  for (const group of snapshot.entities.groups) {
    const lens = structuredClone(LensTemplate);

    if (!groups.has(group.uuid)) {
      group.__lens = lens;
      continue;
    }

    const scores = groups.get(group.uuid);

    const sum = scores.reduce((sum, score) => sum + score, 0);
    const averageDays = sum / scores.length;

    const score = getThresholdScore({
      thresholdRangeId: Lens.OUTDATED_ROLES,
      value: averageDays,
    });

    const { color, contrast } = Colors.lens('status', score);
    lens.color = color;
    lens.contrast = contrast;
    lens.value = score;

    applied.entities.groups[group.uuid].__lens = lens;
  }

  return getFlattened(applied);
}
