import { useEffect, useState } from 'react';
import useHydrate, { DEFAULT_HYDRATED } from './useHydrate';
import useStore from './useStore';

const DEFAULT_STATE = {
  hydrated: DEFAULT_HYDRATED,
  isHydrating: true,
};

/**
 * Hydrate all designs with their latest revisions
 */
export default function useHydrated(designIds) {
  const { main, scenarios, isLoading } = useStore();
  const hydrate = useHydrate();

  const [state, setState] = useState(DEFAULT_STATE);

  useEffect(() => {
    if (!isLoading) {
      setState(DEFAULT_STATE);
      hydrate(designIds).then((hydrated) => {
        setState({ hydrated, isHydrating: !hydrated.main });
      });
    }
  }, [main, scenarios, isLoading]);

  return state;
}
