import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} input
 * @param {Object} input.from
 * @param {Object} input.from.snapshot
 * @param {String} input.from.uuid
 * @param {Object} input.to
 * @param {Object} input.to.snapshot
 * @param {String} input.to.title
 * @param {String} input.to.uuid
 * @param {String} [input.to.name]
 * @returns {Object}
 */
export default function publishToTeam({ from, to }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {},
      roles: {},
      activities: {
        create: [],
      },
    },
  };

  // handle group touch/creation
  const group = {
    uuid: to.uuid,
    updated_at: now,
  };

  if (to.uuid === 'new') {
    group.uuid = shortuuid.generate();
    group.name = to.name;
    group.created_at = now;
    mutation.entities.groups.create = [group];
  } else {
    mutation.entities.groups.update = [group];
  }

  // handle role creation
  const keyedFrom = Snapshots.utils.getKeyed(from.snapshot);
  const keyedTo = Snapshots.utils.getKeyed(to.snapshot);

  const role = structuredClone(keyedFrom.entities.roles[from.uuid]);
  role.group_uuid = group.uuid;

  // make sure budget is never null
  if (role.budget === null) {
    delete role.budget;
  }

  if (role.title !== to.title) {
    role.title = to.title;
  }

  if (Object.hasOwn(keyedTo.entities.roles, from.uuid)) {
    role.uuid = shortuuid.generate();
  }

  mutation.entities.roles.create = [role];

  // create activities
  const activities = Collections.where(
    from.snapshot.entities.activities,
    'owner_uuid',
    from.uuid
  );

  activities.forEach((source) => {
    const activity = structuredClone(source);
    activity.owner_uuid = role.uuid;
    if (Object.hasOwn(keyedTo.entities.activities, activity.uuid)) {
      activity.uuid = shortuuid.generate();
    }

    mutation.entities.activities.create.push(activity);
  });

  // clear empty mutations
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (!mutation.entities[type][action].length) {
        delete mutation.entities[type][action];
      }
    });
  });

  return { mutation, role };
}
