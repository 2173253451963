import { useDatabase } from '@pkg/database';
import useFetchRevision from '../../revisions/useFetch';

export default function useInvalidated() {
  const database = useDatabase();
  const fetch = useFetchRevision();

  const handleInvalidated = async ({ ids }) => {
    const isWildcard = ids.length === 1 && ids[0] === '*';
    const designIds = isWildcard
      ? await database.designs.toCollection().primaryKeys()
      : ids;

    const latestIds = new Set();
    for (const id of designIds) {
      const design = await database.designs.get(id);
      latestIds.add(design.latest.uuid);
      fetch(design.latest.uuid);
    }

    await database.designRevisions
      .where('design.uuid')
      .anyOf(designIds)
      .and(({ uuid }) => !latestIds.has(uuid))
      .delete();
  };

  return handleInvalidated;
}
