import { Collections, Num } from '@pkg/utils';
import { DesignEntity, Visibility } from '@/lib/enums';

const GROUP_TAG = Object.freeze({
  groups: 0,
  roles: 0,
  activities: 0,
  hours: 0,
  fte: 0,
  budget: 0,
});

const ROLE_TAG = Object.freeze({
  roles: 0,
  activities: 0,
  hours: 0,
  fte: 0,
  budget: 0,
});

const ACTIVITY_TAG = Object.freeze({
  activities: 0,
  hours: 0,
  fte: 0,
  budget: 0,
});

export default function deriveTags(snapshot) {
  if (!snapshot) {
    return null;
  }

  console.time('Snapshots.deriveTags');
  const derived = structuredClone(snapshot);
  const entities = derived.entities;

  derived.entities.roles = Collections.keyById(entities.roles);
  derived.entities.activities.forEach((activity) => {
    const owners = entities[DesignEntity.toPlural(activity.owner_type)];

    if (!Object.hasOwn(owners, activity.owner_uuid)) {
      return;
    }

    if (!Object.hasOwn(owners[activity.owner_uuid], '__tags')) {
      owners[activity.owner_uuid].__tags = {};
    }

    activity.tags?.forEach((id) => {
      if (!Object.hasOwn(owners[activity.owner_uuid].__tags, id)) {
        owners[activity.owner_uuid].__tags[id] = structuredClone(ACTIVITY_TAG);
      }

      if (!activity.disabled_at) {
        owners[activity.owner_uuid].__tags[id].activities += 1;
        owners[activity.owner_uuid].__tags[id].hours += activity.hours;
      }
    });
  });

  derived.entities.groups = Collections.keyById(derived.entities.groups);
  derived.entities.roles = Object.values(derived.entities.roles);
  derived.entities.roles.forEach((role) => {
    const groupId = role.group_uuid;
    const group = entities.groups[groupId];
    const hasGroup = Boolean(group);

    if (!Object.hasOwn(role, '__tags')) {
      role.__tags = {};
    }

    if (hasGroup && !Object.hasOwn(group, '__tags')) {
      entities.groups[groupId].__tags = {};
    }

    if (role.__visibility === Visibility.NONE) {
      return;
    }

    // update fte/budget for each tag on the role from activities
    Object.keys(role.__tags).forEach((id) => {
      const tagged = role.__tags[id].hours;
      const percentage = Num.percent(tagged, role.__total_metrics.hours);

      const fte = parseFloat(role.fte);
      role.__tags[id].fte = (fte / 100) * percentage;

      const budget = parseInt(role.budget ?? 0);
      role.__tags[id].budget = (budget / 100) * percentage;
    });

    // handle direct role tags, overriding tag metrics from activities
    role.tags?.forEach((id) => {
      role.__tags[id] = structuredClone(ACTIVITY_TAG);
      role.__tags[id].activities = role.__total_metrics.activities;
      role.__tags[id].hours = role.__total_metrics.hours;
      role.__tags[id].fte = parseFloat(role.fte);
      role.__tags[id].budget = parseInt(role.budget ?? 0);
    });

    // add all role tag metrics to group
    if (hasGroup) {
      Object.keys(role.__tags).forEach((id) => {
        if (!Object.hasOwn(entities.groups[groupId].__tags, id)) {
          entities.groups[groupId].__tags[id] = structuredClone(ROLE_TAG);
        }

        const totals = entities.groups[groupId].__tags[id];
        const metrics = role.__tags[id];

        totals.roles += 1;
        totals.fte += parseFloat(metrics.fte);
        totals.budget += parseInt(metrics.budget ?? 0);
        totals.activities += metrics.activities;
        totals.hours += metrics.hours;

        entities.groups[groupId].__tags[id] = totals;
      });
    }
  });

  derived.entities.groups = Object.values(derived.entities.groups);
  derived.entities.groups.forEach((group) => {
    if (!Object.hasOwn(group, '__tags')) {
      group.__tags = {};
    }

    if (!Object.hasOwn(derived, '__tags')) {
      derived.__tags = {};
    }

    if (group.__visibility === Visibility.NONE) {
      return;
    }

    // handle direct group tags, overriding tag metrics from roles
    group.tags?.forEach((id) => {
      group.__tags[id] = structuredClone(ROLE_TAG);
      group.__tags[id].roles = group.__total_metrics.roles;
      group.__tags[id].activities = group.__total_metrics.activities;
      group.__tags[id].hours = group.__total_metrics.hours;
      group.__tags[id].fte = parseFloat(group.fte);
      group.__tags[id].budget = parseInt(group.budget ?? 0);
    });

    // add group tag metrics to design totals
    Object.keys(group.__tags).forEach((id) => {
      if (!Object.hasOwn(derived.__tags, id)) {
        derived.__tags[id] = structuredClone(GROUP_TAG);
      }

      const totals = derived.__tags[id];
      const metrics = group.__total_metrics;

      totals.groups += 1;
      totals.roles += metrics.roles;
      totals.activities += metrics.activities;
      totals.hours += metrics.hours;
      totals.fte += parseFloat(metrics.fte);
      totals.budget += parseInt(metrics.budget ?? 0);

      derived.__tags[id] = totals;
    });
  });

  derived.entities = entities;
  console.timeEnd('Snapshots.deriveTags');

  return derived;
}
