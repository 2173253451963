import { useRouter } from 'next/router';
import { useQuery } from '@pkg/graphql';

const LIMITED_ACCESS_QUERY = /* GraphQL */ `
  query GetToken($token: String!) {
    limitedAccessToken(token: $token) {
      access_token
    }
  }
`;

/**
 * @param {String} token
 * @returns
 */

export default function useLimitedAccessToken() {
  const router = useRouter();
  const token = router?.query?.key;

  const query = [LIMITED_ACCESS_QUERY, { token }];

  const { data, isLoading } = useQuery({
    query,
    enabled: Boolean(token),
  });

  const accessToken = data?.limitedAccessToken?.access_token ?? null;

  return {
    isLoading,
    limitedAccessToken: accessToken,
  };
}
