import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Obj } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import parseLocation from './parseLocation';

const DEFAULT_STATE = {
  entities: [
    DesignEntity.GROUP,
    DesignEntity.ROLE,
    DesignEntity.ACTIVITY,
    DesignEntity.PERSON,
  ],
};

/**
 * @param {Location} location
 * @return {object}
 */
export default function useLocationFilters(location) {
  const router = useRouter();

  const [values, setValues] = useState({
    ...DEFAULT_STATE,
    ...parseLocation(location),
  });

  /**
   * @param {object} values
   * @param {object}
   */
  function update(values) {
    setValues((state) => {
      return { ...state, ...values };
    });
  }

  /**
   * @param {string} [key]
   */
  function clear(key) {
    if (key) {
      setValues((state) => {
        delete state[key];
        return { ...state };
      });
    } else {
      setValues(DEFAULT_STATE);
    }
  }

  useEffect(() => {
    const sanitised = Obj.omitEmpty(structuredClone(values));
    delete sanitised.entities;

    router.replace(
      {
        pathname: router.pathname,
        query: sanitised,
      },
      {
        pathname: location.pathname,
        query: sanitised,
      },
      {
        shallow: true,
      }
    );
  }, [values]);

  return { values, update, clear };
}
