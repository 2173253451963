import { get } from '@pkg/utils/objects';

/**
 * @param {string} property
 * @return {object[]}
 */
export default function bool(property, reverse = false) {
  return (a, b) => {
    const itemA = get(a, property);
    const itemB = get(b, property);

    if (itemA === itemB) {
      return 0;
    }

    if (reverse) {
      return itemA ? 1 : -1;
    } else {
      return itemA ? -1 : 1;
    }
  };
}
