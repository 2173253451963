import { Snapshots } from '@pkg/entities';
import { Collections, Sort } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.id
 * @param {String} [input.newId]
 * @param {String} input.groupId
 * @param {String} input.title
 * @param {String} input.tags
 * @returns {Object}
 */
export default function copy(snapshot, { id, newId, groupId, title, tags }) {
  const keyed = Snapshots.utils.getKeyed(snapshot).entities;
  const entities = structuredClone(snapshot.entities);
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      roles: {
        create: [],
      },
      activities: {
        create: [],
      },
    },
  };

  // sources
  const role = keyed.roles[id];
  const activities = Collections.where(entities.activities, 'owner_uuid', id);
  activities.sort(Sort.order());

  // targets
  const leadId = keyed.groups[groupId]?.lead_uuid ?? null;
  const siblings = entities.roles.filter((role) => {
    const leadId = keyed.groups[role.group_uuid]?.lead_uuid ?? null;
    return role.group_uuid === groupId && role.parent_uuid === leadId;
  });

  // copy role
  const nextId = newId ?? shortuuid.generate();
  const copied = {
    uuid: nextId,
    external_id: null,
    group_uuid: groupId,
    parent_uuid: leadId,
    user_uuid: role.user_uuid,
    title,
    fte: role.fte,
    properties: role.properties ?? [],
    skills: role.skills ?? [],
    tags: tags ? role.tags : [],
    order: siblings.length,
    created_at: now,
    updated_at: now,
  };

  if (Number.isFinite(role.budget)) {
    copied.budget = 0;
  }

  mutation.entities.roles.create.push(copied);

  // copy activities
  activities.forEach((activity, index) => {
    mutation.entities.activities.create.push({
      uuid: shortuuid.generate(),
      library_uuid: activity.library_uuid,
      owner_uuid: nextId,
      owner_type: activity.owner_type,
      source_uuid: activity.source_uuid,
      source_type: activity.source_type,
      hours: activity.hours,
      order: index,
      properties: activity.properties ?? [],
      tags: tags ? activity.tags : [],
      created_at: now,
      disabled_at: activity.disabled_at ? now : null,
      updated_at: now,
    });
  });

  // remove unused mutations
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (!mutation.entities[type][action].length) {
        delete mutation.entities[type][action];
      }
    });
  });

  return mutation;
}
