import { useMemo } from 'react';
import { TagCell, TagFilter } from '@/molecules/tableElements';
import {
  sortFloatValues,
  sumActivityCost,
  sumColumnValues,
  uniquePercentageValueColumn,
} from '@/organisms/tables/utils';
import {
  usePropertyContext,
  useSkillContext,
  useTagContext,
} from '@/shared/providers';
import { currency, percent } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';

const useActivityColumnDefs = (showBudget) => {
  const { tagMap, activityTagOptions: tagOptions } = useTagContext();
  const { propertyList } = usePropertyContext();
  const { skillMap } = useSkillContext();

  return useMemo(() => {
    return [
      {
        field: 'activity.id',
        filter: 'agMultiColumnFilter',
        hide: true,
      },
      {
        field: 'activity.library_uuid',
        filter: 'agMultiColumnFilter',
        headerName: 'Activity Library Id',
        hide: true,
      },
      {
        chartDataType: 'category',
        field: 'activity.description',
        filter: 'agMultiColumnFilter',
        width: 400,
      },
      {
        chartDataType: 'series',
        field: 'activity.hours',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Activity Hours',
        width: 150,
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        colId: 'activity.sumHours',
        comparator: sortFloatValues,
        filter: 'agNumberColumnFilter',
        headerName: 'Activity Hours',
        valueFormatter: ({ value }) => value?.toFixed(2) ?? null,
        valueGetter: (params) => {
          const { api, node } = params;
          const column = api.getColumn('activity.hours');
          const idColumn = api.getColumn('activity.id');

          return sumColumnValues({
            node,
            column,
            idColumn,
            idSet: new Set(),
            total: 0,
          });
        },
      },
      {
        field: 'activity.fte',
        filter: 'agNumberColumnFilter',
        headerName: 'Raw Activity FTE',
        hide: true,
        suppressColumnsToolPanel: true,
        suppressFiltersToolPanel: true,
      },
      {
        chartDataType: 'series',
        colId: 'activity.sumFte',
        comparator: sortFloatValues,
        filter: 'agNumberColumnFilter',
        headerName: 'Activity FTE',
        valueFormatter: ({ value }) => value?.toFixed(2) ?? null,
        valueGetter: (params) => {
          const { api, node } = params;
          const column = api.getColumn('activity.fte');
          const idColumn = api.getColumn('activity.id');

          return sumColumnValues({
            node,
            column,
            idColumn,
            idSet: new Set(),
            total: 0,
          });
        },
      },
      {
        chartDataType: 'series',
        colId: 'activity.hoursPercentage',
        comparator: sortFloatValues,
        defaultAggFunc: 'percentSum',
        filter: 'agNumberColumnFilter',
        headerName: '% of Hours',
        valueGetter: (params) => {
          const { api, node } = params;

          const column = api.getColumn('activity.sumHours');
          const numerator = node.getValueFromValueService(column);
          const denominator =
            node?.parent?.getValueFromValueService(column) ?? numerator;

          const value = percent(numerator, denominator, 7);

          return {
            percent: value,
            numerator,
            denominator,
            toString: () => (value ? `${value.toFixed(2)}%` : '0%'),
          };
        },
      },
      {
        chartDataType: 'series',
        colId: 'activity.percentRoleHours',
        comparator: sortFloatValues,
        defaultAggFunc: 'percentSum',
        filter: 'agNumberColumnFilter',
        headerName: '% of Role Hours',
        valueGetter: (params) => {
          return uniquePercentageValueColumn({
            params,
            numeratorColumnId: 'activity.sumHours',
            denominatorColumnId: 'role.sumActivityHours',
            uniqueColumnId: 'role.id',
          });
        },
      },
      {
        chartDataType: 'series',
        colId: 'activity.percentTeamHours',
        comparator: sortFloatValues,
        defaultAggFunc: 'percentSum',
        filter: 'agNumberColumnFilter',
        headerName: '% of Team Hours',
        valueGetter: (params) => {
          return uniquePercentageValueColumn({
            params,
            numeratorColumnId: 'activity.sumHours',
            denominatorColumnId: 'team.sumHours',
            uniqueColumnId: 'team.id',
          });
        },
      },
      {
        chartDataType: 'series',
        colId: 'activity.percentOrgHours',
        comparator: sortFloatValues,
        defaultAggFunc: 'percentSum',
        filter: 'agNumberColumnFilter',
        headerName: '% of Design Hours',
        valueGetter: (params) => {
          return uniquePercentageValueColumn({
            params,
            numeratorColumnId: 'activity.sumHours',
            denominatorColumnId: 'design.sumHours',
            uniqueColumnId: 'design.id',
          });
        },
      },
      showBudget && {
        chartDataType: 'series',
        colId: 'activity.cost',
        comparator: sortFloatValues,
        filter: 'agNumberColumnFilter',
        headerName: '$ Activity Cost P/A',
        valueFormatter: ({ value }) =>
          currency(value, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
        valueGetter: (params) => {
          const { api, node } = params;

          const columns = {
            roleBudget: api.getColumn('role.budget'),
            activityHours: api.getColumn('activity.hours'),
            roleHours: api.getColumn('role.activityHours'),
          };

          return sumActivityCost(node, columns, 0);
        },
      },
      {
        chartDataType: 'excluded',
        cellRenderer: TagCell,
        field: 'activity.tags',
        filter: TagFilter,
        filterParams: {
          title: 'Activity Tag filter',
          values: tagOptions,
        },
        keyCreator: ({ value }) => {
          return value?.map(({ name }) => name)?.join(', ') ?? 'No tags';
        },
        valueFormatter: ({ value }) => {
          if (!value) {
            return;
          }
          return value?.map(({ name }) => name)?.join(', ');
        },
        width: 400,
      },
      {
        colId: 'activity.properties',
        headerName: 'Activity Properties',
        children: propertyList
          .filter((property) => property.scope === DesignEntity.ACTIVITY)
          .map((property) => ({
            chartDataType: 'series',
            colId: property.uuid,
            filter: 'agMultiColumnFilter',
            headerName: property.name,
            valueGetter: (params) => {
              const value = params.data?.activity?.properties?.[property.uuid];
              return value ?? '';
            },
          })),
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'activity.created_at',
        filter: 'agDateColumnFilter',
        headerName: 'Activity Created',
        hide: true,
      },
      {
        cellDataType: 'date',
        chartDataType: 'date',
        field: 'activity.updated_at',
        filter: 'agDateColumnFilter',
        headerName: 'Activity Updated',
        hide: true,
      },
    ].filter((column) => column);
  }, [skillMap, tagMap, JSON.stringify(propertyList), tagOptions, showBudget]);
};

export default useActivityColumnDefs;
