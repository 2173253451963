import { DesignEntity } from '@/lib/enums';

/**
 *
 * @param {Object[]} actions
 * @param {Sting} level
 * @param {Object} design
 * @param {Function} lookup
 * @param {Object} relationships
 * @returns
 */
export default function checkActions(
  actions,
  level,
  design,
  lookup,
  relationships
) {
  const checks = {};
  const entities = design.latest.snapshot.entities;
  const actionsByType = actions.reduce((acc, action) => {
    if (!Object.keys(acc).includes(action.entityType)) {
      acc[action.entityType] = [];
    }
    acc[action.entityType].push(action);
    return acc;
  }, {});

  for (const entityType in actionsByType) {
    const actions = actionsByType[entityType];

    const plural = DesignEntity.toPlural(entityType);
    entities[plural].forEach((entity) => {
      const policies = [];

      actions.forEach((actionSet) => {
        const { property, action } = actionSet;
        policies.push({
          level,
          relationship: relationships[entity.uuid],
          entityType: entityType.toLowerCase(),
          property,
          action,
        });
      });

      checks[entity.uuid] = lookup(policies);
    });
  }

  return checks;
}
