import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountTreeSharpIcon from '@mui/icons-material/AccountTreeSharp';
import BusinessIcon from '@mui/icons-material/Business';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import { useAccess } from '@pkg/access/organisations';
import config from '@/lib/config';
import { Feature } from '@/lib/enums';

/**
 * @param {Boolean} isLoading
 * @param {Object} me
 * @param {Object} organisation
 * @returns {Object[]}
 */
export default function useQuickLinks(me) {
  if (!me) {
    return [];
  }

  const access = useAccess();
  const canManage = access.model.manage;
  const canDesign = access.features[Feature.ORGANISATION_DESIGN];

  const quickLinks = [];

  if (canDesign) {
    quickLinks.unshift(
      {
        label: 'Organisation',
        href: '/d/main/latest',
        icon: <AccountTreeSharpIcon />,
      },
      {
        label: 'Scenarios',
        href: config.PATHS.DESIGN.scenarios,
        icon: <SquareFootIcon />,
      }
    );
  }

  if (canManage) {
    quickLinks.push({
      label: 'Account',
      href: '/accounts',
      icon: <BusinessIcon />,
    });
  }

  quickLinks.push({
    label: 'My Profile',
    href: `/u/${me.uuid}`,
    icon: <AccountCircleIcon />,
  });

  return quickLinks;
}
