import { Obj } from '@pkg/utils';

export default function prepareErrorMessage(error) {
  const message = {
    description: 'An error has occurred.',
    type: 'error',
    showRefresh: false,
    details: {},
  };

  if (error.isAuthorizationError()) {
    message.description = "You don't have permission to complete this action.";
    return message;
  }

  if (error.isAuthenticationError()) {
    message.description = 'You are not currently logged in.';
    message.type = 'warning';
    message.showRefresh = true;
    return message;
  }

  if (!Obj.isEmpty(error.getFieldErrors())) {
    message.description =
      'There was a problem with some of the data you entered. See details below.';
    message.details = error.getFieldErrors();
    return message;
  }

  message.details = error.getErrorMessages();

  return message;
}
