import { Colors } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import MuiChip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import ChipSize from './ChipSize';

const Chip = ({
  id,
  fontWeight = 500,
  color = 'primary',
  colorProps,
  description,
  expanded = true,
  name,
  onClick,
  onDelete,
  size = Size.MEDIUM,
  tooltipPlacement = 'top',
  sx = {},
}) => {
  const sizeProps = ChipSize[size];
  const chipColor = colorProps ?? Colors.chip[color];

  const handleDelete = (event) => {
    onDelete?.(event, id);
  };

  const handleClick = (event) => {
    if (onClick) {
      onClick?.(event, id);
      return;
    }

    handleDelete(event);
  };

  const interactive = onDelete || onClick;

  return (
    <Tooltip arrow title={description} placement={tooltipPlacement}>
      <MuiChip
        deleteIcon={<ClearRoundedIcon color={chipColor.normal.color} />}
        label={expanded ? name : null}
        onClick={handleClick}
        onDelete={onDelete ? handleDelete : null}
        sx={{
          ...chipColor.normal,
          borderRadius: sizeProps.borderRadius,
          cursor: interactive ? 'pointer' : 'inherit',
          display: expanded ? 'inline-flex' : 'inline-block',
          fontSize: expanded ? sizeProps.fontSize : '0.75rem',
          fontWeight: fontWeight,
          height: expanded ? sizeProps.height : '0.75rem',
          lineHeight: expanded ? sizeProps.lineHeight : '0.75rem',
          padding: expanded ? sizeProps.padding : '0 8px',
          '& .MuiChip-label': {
            padding: 0,
          },
          '& .MuiChip-deleteIcon': {
            color: chipColor.normal.color,
            width: '1rem',
            fontSize: '0.95rem',
            marginRight: 0,
            marginLeft: 0.5,
          },
          '&:hover': interactive
            ? { ...chipColor.hover }
            : { ...chipColor.normal },
          '&:focus': interactive
            ? { ...chipColor.focus }
            : { ...chipColor.normal },
          ...sx,
        }}
      />
    </Tooltip>
  );
};

export default Chip;
