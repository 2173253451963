import { useCallback } from 'react';
import publishToRole from './reducers/roles/publishToRole';
import publishToTeam from './reducers/roles/publishToTeam';
import useMutate from './useMutate';

export default function usePublishRole() {
  const mutate = useMutate();

  /**
   * @param {String} designId
   * @param {Boolean} isTeam
   * @param {Object} input
   * @param {Object} input.from
   * @param {Object} input.from.snapshot
   * @param {String} input.from.uuid
   * @param {Object} input.to
   * @param {Object} input.to.snapshot
   * @param {String} input.to.title
   * @param {String} input.to.uuid
   * @returns {Promise}
   */
  async function publishRole(designId, isTeam, input) {
    const { mutation, role: published } = isTeam
      ? publishToTeam(input)
      : publishToRole(input);
    await mutate(designId, mutation);
    return published;
  }

  return useCallback(publishRole, [mutate]);
}
