import { useEffect, useState } from 'react';
import { useEcho } from '../echo';

const types = {
  Channel: 'channel',
  PresenceChannel: 'presenceChannel',
  PrivateChannel: 'privateChannel',
  EncryptedPrivateChannel: 'encryptedPrivateChannel',
};

/**
 * @param {object} options
 * @param {string} [options.channelName]
 * @param {string} [options.channelType]
 * @return {Channel|undefined}
 */
export default function useChannel({ channelName, channelType }) {
  const [connectedTo, setConnectedTo] = useState();
  const [channel, setChannel] = useState();
  const { echo } = useEcho();
  const isReady = Boolean(echo && channelName);

  function joinChannel() {
    try {
      const method = types[channelType];
      const connectedChannel = echo.connector[method](channelName);
      setChannel(connectedChannel);
      setConnectedTo(channelName);
    } catch (error) {
      setChannel(undefined);
      setConnectedTo(undefined);
    }
  }

  function leaveChannel() {
    if (echo && connectedTo) {
      echo.leave(connectedTo);
      setChannel(undefined);
      setConnectedTo(undefined);
    }
  }

  useEffect(() => {
    if (isReady && !channel) {
      joinChannel();
    }
  }, [isReady, channel]);

  useEffect(() => {
    if (channel && connectedTo !== channelName) {
      leaveChannel();
    }

    return leaveChannel;
  }, [connectedTo, channelName]);

  return channel;
}
