import FlexSearch from 'flexsearch';
import { produce } from 'immer';
import { create } from 'zustand';
import { Sort } from '@pkg/utils';
import { DesignType } from '@/lib/enums';

class SearchIndex extends FlexSearch.Document {
  constructor() {
    return new FlexSearch.Document({
      context: true,
      tokenize: 'forward',
      document: {
        id: 'uuid',
        index: ['name', 'goal', 'latest:name'],
      },
    });
  }
}

const INITIAL_STATE = {
  isLoading: true,
  main: null,
  mutations: {},
  scenarios: {
    index: new SearchIndex(),
    list: [],
    map: new Map(),
  },
};

const useStore = create((set, get) => ({
  ...INITIAL_STATE,

  /**
   * Add or update an existing design
   * @param {Object} design
   * @returns {void}
   */
  put: (design) => {
    console.debug('DesignsStore.put', design);
    return set(
      produce((state) => {
        if (design.type === DesignType.LIVE) {
          state.main = design;
          state.scenarios.index.update(design.uuid, {
            uuid: design.uuid,
            name: design.name,
            goal: design.goal,
            notes: design.notes,
            latest: {
              name: design.latest.name,
            },
          });
          return;
        }

        if (state.scenarios.map.has(design.uuid)) {
          const index = state.scenarios.list.findIndex(({ uuid }) => {
            return uuid === design.uuid;
          });

          state.scenarios.list[index] = design;
        } else {
          state.scenarios.list.unshift(design);
        }

        state.scenarios.map.set(design.uuid, design);
        state.scenarios.index.add({
          uuid: design.uuid,
          name: design.name,
          goal: design.goal,
          notes: design.notes,
          latest: {
            name: design.latest.name,
          },
        });
      })
    );
  },

  /**
   * Spread an update over an existing design
   * @param {String} designId
   * @param {Object} update
   * @returns {void}
   */
  update: (designId, update) => {
    console.debug('DesignsStore.update', { designId, update });
    set(
      produce((state) => {
        let design;

        if (designId === state.main?.uuid) {
          design = { ...state.main, ...update };
          state.main = design;
        }

        if (state.scenarios.map.has(designId)) {
          design = state.scenarios.map.get(designId);

          const index = state.scenarios.list.findIndex(({ uuid }) => {
            return uuid === designId;
          });

          state.scenarios.list[index] = design;
          state.scenarios.map.set(designId, design);
        }

        state.scenarios.index.update(design.uuid, {
          uuid: design.uuid,
          name: design.name,
          goal: design.goal,
          notes: design.notes,
          latest: {
            name: design.latest.name,
          },
        });
      })
    );
  },

  /**
   * Remove stored value
   * @returns {void}
   */
  remove: (id) => {
    console.debug('DesignsStore.remove', { id });
    return set(
      produce((state) => {
        const filtered = state.scenarios.list.filter(({ uuid }) => uuid !== id);
        state.scenarios.list = filtered;
        state.scenarios.map.delete(id);
      })
    );
  },
  /**
   * Clear all stored values
   * @returns {void}
   */
  reset: () => {
    console.debug('DesignsStore.reset', get());
    return set((current) => {
      const state = { ...current, ...INITIAL_STATE };
      state.scenarios = { ...state.scenarios, index: new SearchIndex() };
      return Object.freeze(state);
    });
  },

  /**
   * Set or replace all values in the store
   * @param {Object} input
   * @param {Object[]} [input.designs]
   * @param {Object} [input.mutations]
   * @param {Boolean} [input.isLoading]
   * @returns {void}
   */
  set: ({ designs, mutations, isLoading }) => {
    console.debug('DesignsStore.set', { designs, mutations, isLoading });
    return set(
      produce((state) => {
        if (typeof isLoading !== 'undefined') {
          state.isLoading = isLoading;
        }

        if (typeof mutations !== 'undefined') {
          state.mutations = mutations;
        }

        if (typeof designs === 'undefined') {
          return;
        }

        state.main = null;
        state.scenarios.list = [];
        state.scenarios.map = new Map();
        state.scenarios.index = new SearchIndex();

        designs.sort(Sort.date('updated_at')).forEach((design) => {
          if (design.type === DesignType.LIVE) {
            state.main = design;
            return;
          }

          state.scenarios.list.push(design);
          state.scenarios.map.set(design.uuid, design);
          state.scenarios.index.add({
            uuid: design.uuid,
            name: design.name,
            goal: design.goal,
            notes: design.notes,
            latest: {
              name: design.latest.name,
            },
          });
        });
      })
    );
  },

  /**
   * Get one or many designs by id
   * @param {String|String[]} ids
   */
  get: (ids) => {
    console.debug('DesignsStore.get', ids);

    if (!ids) {
      console.debug('DesignsStore.got.none');
      return null;
    }

    const { main, scenarios, isLoading } = get();
    const isList = Array.isArray(ids);

    if (isLoading) {
      console.debug('DesignsStore.got.loading');
      return isList ? [] : null;
    }

    const set = new Set(isList ? ids : [ids]);
    const results = [];

    if (set.has(main.uuid)) {
      if (isList) {
        results.push(main);
      } else {
        console.debug('DesignsStore.got.main', main);
        return main;
      }
    }

    for (const id of set) {
      if (scenarios.map.has(id)) {
        const scenario = scenarios.map.get(id);
        if (isList) {
          results.push(scenario);
        } else {
          console.debug('DesignsStore.got.scenario', scenario);
          return scenario;
        }
      }
    }

    console.debug('DesignsStore.got.list', results);
    return Object.freeze(results);
  },
}));

export default useStore;
