import { Collections, Obj } from '@pkg/utils';
import config from '@/lib/config';
import { AssociativeProperties } from '@/lib/enums';
import { trim } from '../designs/mutations/utils';
import diffAssociative from './diffAssociative';

const ENTITY_TYPES = ['groups', 'roles', 'activities'];

/**
 * @param {Object} a
 * @param {Object} b
 * @returns {Object|null}
 */
export default function diffLatest(a, b) {
  if (Obj.isEmpty(a) && Obj.isEmpty(b)) {
    return null;
  }

  if (Obj.isEmpty(a) || Obj.isEmpty(b)) {
    return null;
  }

  const snapshots = { a: Obj.omitDerived(a), b: Obj.omitDerived(b) };
  const diff = structuredClone(config.DEFAULT_MUTATION);
  let newest = null,
    oldest = null;

  if (snapshots.a.created_at >= snapshots.b.created_at) {
    diff.created_at = snapshots.a.created_at;
    newest = 'a';
    oldest = 'b';
  }

  if (snapshots.a.created_at <= snapshots.b.created_at) {
    diff.created_at = snapshots.b.created_at;
    newest = 'b';
    oldest = 'a';
  }

  if (snapshots.a.name !== snapshots.b.name) {
    diff.name = snapshots[newest].name;
  }

  if (snapshots.a.objective !== snapshots.b.objective) {
    diff.objective = snapshots[newest].objective;
  }

  if (!newest && !oldest) {
    return null;
  }

  ENTITY_TYPES.forEach((type) => {
    const newer = Collections.keyById(snapshots[newest].entities[type]);
    const older = Collections.keyById(snapshots[oldest].entities[type]);

    snapshots[newest].entities[type].forEach((newEntity) => {
      const oldEntity = older[newEntity.uuid];

      // created
      if (!oldEntity) {
        const item = structuredClone(newEntity);
        diff.entities[type].create.push(item);
        return;
      }

      // hasn't changed
      if (oldEntity.updated_at === newEntity.updated_at) {
        return;
      }

      // updating
      if (newEntity.updated_at > oldEntity.updated_at) {
        const entityDiff = {
          uuid: newEntity.uuid,
          updated_at: newEntity.updated_at,
        };

        for (const property in newEntity) {
          const oldProperty = oldEntity[property];
          const newProperty = newEntity[property];

          switch (true) {
            // nested mutation update
            case AssociativeProperties.has(property):
              const associativeDiff = diffAssociative(oldProperty, newProperty);
              if (associativeDiff) {
                entityDiff[property] = associativeDiff;
              }
              break;

            // primitive replacement
            default:
              const stringNew = JSON.stringify(newProperty);
              const stringOld = JSON.stringify(oldProperty);
              if (stringNew !== stringOld) {
                entityDiff[property] = newProperty;
              }
          }
        }

        diff.entities[type].update.push(entityDiff);
      }
    });

    snapshots[oldest].entities[type].forEach((oldEntity) => {
      const newEntity = newer[oldEntity.uuid];

      if (!newEntity) {
        diff.entities[type].remove.push(oldEntity.uuid);
      }
    });
  });

  return trim(diff);
}
