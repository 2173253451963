import { useMemo } from 'react';
import * as Library from '../library';
import deriveProps from './deriveProps';

/**
 * @param {Object|Object[]} revisions
 * @param {Object} [options]
 * @return {Object|Object[]}
 */
export default function useDerivedProps(revisions, options) {
  const library = Library.useLibrary();
  return useMemo(() => {
    if (Array.isArray(revisions)) {
      return revisions.map((item) => deriveProps(library, item, options));
    }

    return deriveProps(library, revisions, options);
  }, [library, revisions, options]);
}
