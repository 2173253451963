import { Chip } from '@/atoms/chips';
import { Colors } from '@/atoms/chips';
import { Size } from '@/atoms/enums';

const SkillCell = (props) => {
  const skills = props?.value;

  return (
    skills && (
      <>
        {skills.map((skill) => (
          <div
            key={skill.id}
            style={{ display: 'inline-block', marginRight: '4px' }}
          >
            <Chip
              colorProps={Colors.skill[skill.level]}
              id={skill.id}
              expanded={true}
              name={skill.name}
              size={Size.SMALL}
            />
          </div>
        ))}
      </>
    )
  );
};

export default SkillCell;
