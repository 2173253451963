import { useMemo, cloneElement } from 'react';
import { LinkButton } from '@/atoms/buttons';
import { FlowStatus, FlowType } from '@/shared/enums';
import { DesignsProvider } from '@/shared/providers';
import { ActiveDesignProvider } from '@/shared/providers';
import { useFlowContext } from '@/shared/providers/FlowProvider';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import { DesignLevel } from '@/lib/enums';
import AppLoading from '@/components/AppLoading';
import NotFound from '@/components/NotFound';
import RoleFlowLayout from './RoleFlowLayout';

const Layouts = {
  [FlowType.ROLE_WIZARD]: <RoleFlowLayout />,
};

const notFoundMessage = (status) => {
  const title =
    status === FlowStatus.EXPIRED
      ? 'This action has been canceled.'
      : 'Whoops, this page does not exist.';
  const message =
    status === FlowStatus.EXPIRED
      ? 'Contact the project manager for more information.'
      : "Sorry, we can't find what you were looking for.";

  return { title, message };
};

const FlowLayout = () => {
  const { flow, isLoading } = useFlowContext();
  const organisation = Auth.useStore((state) => state.organisation);
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const hasLimitedAccess = Auth.useStore((state) => state.hasLimitedAccess);

  const designId = useMemo(() => {
    return flow?.payload?.design_uuid;
  }, [flow?.payload?.design_uuid]);
  const level = useMemo(() => {
    return {
      type: DesignLevel.ROLE,
      uuid: flow?.payload?.entity?.uuid,
    };
  }, [flow?.payload]);
  const revisionId = 'latest';

  const notFound =
    !isLoading && (!flow?.uuid || flow?.status === FlowStatus.EXPIRED);

  const { title, message } = notFoundMessage(flow?.status);

  const isActive = !isLoading && !notFound && designId && level;

  return (
    <>
      {isLoading && <AppLoading />}
      {isActive && (
        <ActiveDesignProvider
          designId={designId}
          level={level}
          revisionId={revisionId}
        >
          <DesignsProvider designIds={[organisation.design?.uuid]}>
            {cloneElement(Layouts[flow?.type])}
          </DesignsProvider>
        </ActiveDesignProvider>
      )}
      {notFound && (
        <NotFound title={title} message={<span>{message}</span>}>
          {isAuthenticated && !hasLimitedAccess && (
            <Stack direction="row" justifyContent="center" mt={2}>
              <LinkButton
                size="large"
                path="/"
                label="Go back to your dashboard"
              />
            </Stack>
          )}
        </NotFound>
      )}
    </>
  );
};

export default FlowLayout;
