import { useRouter } from 'next/router';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { OutlineCard, TagListCard } from '@/organisms/cards';
import { FlowList } from '@/organisms/flows';
import { FlowType } from '@/shared/enums';
import { useFlowsContext } from '@/shared/providers/FlowsProvider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import * as Auth from '@pkg/auth';

const Completed = ({ allowedTagSet }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const router = useRouter();
  const { organisation, hasLimitedAccess } = Auth.useStore();
  const { flows } = useFlowsContext();
  const entityFlows = flows.get(FlowType.ROLE_WIZARD);

  const handleClick = () => {
    router.push('/');
  };

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} md={8}>
        <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
          Thanks for providing your perspective! It is so valuable in helping us
          create great work experiences at {organisation?.name}.
        </Paragraph>
        <Paragraph
          size={isLargeScreen ? Size.LARGE : Size.MEDIUM}
          overrideStyles={{ mt: 2 }}
        >
          Here are some fun facts to provide you with a new perspective on your
          role!
        </Paragraph>
        <Paragraph
          size={isLargeScreen ? Size.MEDIUM : Size.SMALL}
          overrideStyles={{ fontStyle: 'italic', mt: 2 }}
        >
          Tip: Click on the circles to see the work in each category.
        </Paragraph>
      </Grid>
      <Grid item xs={12}>
        <TagListCard
          title="How you've described your activities"
          isCondensedView={!isLargeScreen}
          combineTagCategories
          visibleTagSet={allowedTagSet}
        />
      </Grid>
      {entityFlows?.size > 0 && (
        <Grid item xs={12}>
          <FlowList />
        </Grid>
      )}
      {isLargeScreen && !hasLimitedAccess && (
        <Grid item xs={12}>
          <OutlineCard>
            <Box textAlign="center" my={4}>
              <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
                Want to see further insights for your role?
              </Paragraph>
              <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
                Your dashboard provides you with a way to explore your role
                further.
              </Paragraph>
              <Box mt={4}>
                <Button
                  dataTestid="onboard-dashboard"
                  label="View dashboard"
                  onClick={handleClick}
                  color="secondary"
                  size={Size.X_LARGE}
                />
              </Box>
            </Box>
          </OutlineCard>
        </Grid>
      )}
    </Grid>
  );
};

export default Completed;
