import { Obj, Sort } from '@pkg/utils';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import { deriveProps as deriveRevision } from '../revisions';
import DefaultSnapshotMetrics from '../snapshots/enums/DefaultSnapshotMetrics';

function getMetrics(total = {}) {
  return {
    groups: 0,
    roles: 0,
    activities: 0,
    hours: 0,
    fte: 0.0,
    budget: 0,
    ...total,
  };
}

function getVisibleMetrics(visible = {}) {
  return {
    groups: 0,
    roles: 0,
    activities: 0,
    hours: 0,
    fte: 0.0,
    budget: 0,
    ...visible,
  };
}

/**
 * @param {Object} library
 * @param {Object} design
 * @return {Object}
 */
export default function deriveProps(library, design) {
  if (Obj.isEmpty(design)) {
    return design;
  }

  const latest = deriveRevision(library, design.latest);
  const revisions = (design.revisions ?? [])
    .map((revision) => deriveRevision(library, revision))
    .sort(Sort.date('created_at'));

  return {
    ...design,
    latest,
    revisions,
    __type: design.is_scenario ? DesignEntity.SCENARIO : DesignEntity.DESIGN,
    __name: latest?.snapshot?.name,
    __pathname: `/d/${design.uuid}`,
    __visibility: latest?.__visibility ?? config.VISIBILITY.FULL,
    __metrics: latest?.__metrics ?? structuredClone(DefaultSnapshotMetrics),
    __percentage: latest?.__percentage ?? 100,

    /** @deprecated */
    __total_metrics: getMetrics(latest?.__total_metrics),
    __visible_metrics: getVisibleMetrics(latest?.__visible_metrics),
  };
}
