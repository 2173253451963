import { RowColors } from '@/organisms/charts';

const VerticalBar = ({
  barWidth,
  chartHeight,
  metric,
  minBarSize = 8,
  padding = 8,
  row,
  stackData,
  xPosition,
  yPosition,
}) => {
  const height = yPosition > minBarSize ? yPosition : minBarSize;
  const rowType = row.type;
  const colors = RowColors[rowType]?.bar;

  return (
    <g className="vertical-bar">
      <rect
        width={barWidth - padding}
        height={height - 2}
        rx={4}
        ry={4}
        x={xPosition}
        y={chartHeight - height + 4}
        fill={colors.borderColor}
      />
      <rect
        width={barWidth - padding}
        height={height}
        rx={3}
        ry={3}
        x={xPosition}
        y={chartHeight - height}
        fill={colors.backgroundColor}
      />
    </g>
  );
};

export default VerticalBar;
