import { useMemo } from 'react';
import * as Auth from '@pkg/auth';
import { useQuery } from '@pkg/graphql';
import * as People from '../people';
import useSupporterMutations from './useSupporterMutations';

const GROUP_SUPPORTERS = /* GraphQL */ `
  query GroupSupporters($groupId: UUID!, $organisationId: UUID!) {
    groupSupporters(group_uuid: $groupId, organisation_uuid: $organisationId) {
      uuid
      people
    }
  }
`;

/**
 * @param {String} groupId
 * @param {Boolean} hydrate
 * @return {Array}
 */
export default function useSupporters(groupId, hydrate = false) {
  const organisationId = Auth.useStore((state) => state.organisation?.uuid);
  const enabled = Boolean(organisationId);

  const variables = { groupId, organisationId };
  const query = [GROUP_SUPPORTERS, variables];
  const mutations = useSupporterMutations(query);
  const { data, isLoading } = useQuery({ query, enabled });

  const ids = data?.groupSupporters?.people ?? [];
  const results = People.useQueries(hydrate ? ids : []);

  return useMemo(() => {
    return Object.freeze({
      ids,
      isLoading: (hydrate && results.isLoading) || isLoading,
      list: results.list,
      map: results.map,
      mutations,
    });
  }, [hydrate, ids, mutations, results]);
}
