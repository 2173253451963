import Link from 'next/link';
import { useMemo } from 'react';
import { EntityChip } from '@/atoms/chips';
import { usePerson } from '@/shared/hooks';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/styles';
import { Routes } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';
import Typography from '@/components/Typography';
import { Static } from '@/components/UserAvatar';

const Avatar = styled(Static)(() => ({
  boxShadow: '0 0 0 2px rgba(255,255,255,1)',
}));

const AVATAR_SIZE = Object.freeze({
  small: '20px',
  medium: '40px',
  large: '60px',
});

const DEFAULT_COLORS = Object.freeze({
  main: {
    font: '#4d4d68',
    border: '#c5d9ec',
    highlight: '#FFF',
  },
  inverse: {
    font: '#FFFFFF',
    border: 'transparent',
    highlight: '#FFFFFF20',
  },
});

const TeamSummary = ({ group, chip, colors, size = 'medium', inverse }) => {
  const { scenario } = useScenarioContext();
  const { person } = usePerson(group);

  const path = useMemo(() => {
    if (!group) {
      return null;
    }

    return Routes.build.designParts({
      designId: scenario.details?.designId,
      level: {
        type: DesignEntity.GROUP,
        uuid: group.uuid,
      },
    });
  }, [scenario?.details?.designId, group?.uuid]);

  const linkColors = colors
    ? colors
    : DEFAULT_COLORS[inverse ? 'inverse' : 'main'];

  return (
    Boolean(group) && (
      <Link href={path}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="flex-start"
          spacing={1.5}
          sx={{
            color: linkColors.font,
            padding: 1,
            borderRadius: '4px',
            transition: 'background-color 300ms, box-shadow 300ms',
            '&: hover': {
              backgroundColor: linkColors.highlight,
              boxShadow: `inset 0 0 0 2px ${linkColors.border}`,
            },
          }}
        >
          <Box>
            <Avatar user={person} size={AVATAR_SIZE[size]} />
          </Box>
          <Stack>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 400,
                fontSize: '1rem',
                lineHeight: '1.3rem',
              }}
            >
              {group.name}
              {Boolean(chip) && (
                <EntityChip name="PARENT" sx={{ ml: 1 }} type={chip} />
              )}
            </Typography>
            {Boolean(group.lead_uuid) && (
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: 400,
                  fontSize: '0.85rem',
                  lineHeight: '1.25rem',
                }}
              >
                {person
                  ? `${person.first_name} ${person.last_name}`
                  : 'Unassigned'}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Link>
    )
  );
};

export default TeamSummary;
