import { DimensionType } from '@/molecules/tableElements';
import { ScopeType } from '@/shared/enums';

export default function defaultDimension(scope) {
  switch (scope) {
    case ScopeType.ACTIVITIES:
      return DimensionType.ACTIVITY;
    case ScopeType.ROLES:
      return DimensionType.ROLE;
    case ScopeType.GROUPS:
      return DimensionType.GROUP;
    case ScopeType.TAGS:
      return DimensionType.ACTIVITY_TAGS_ACTIVITY;
    case ScopeType.SKILLS:
      return DimensionType.ROLE_SKILLS;
    default:
      return DimensionType.ACTIVITY;
  }
}
