import { Snapshots } from '@pkg/entities';
import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.ids
 * @param {String} input.groupId
 * @param {String} [input.parentId]
 * @returns {Object}
 */
export default function move(snapshot, { ids, groupId, parentId, ...input }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
    },
  };

  const keyed = Snapshots.utils.getHierarchy(snapshot);
  const newGroupId = groupId && groupId !== 'null' ? groupId : null;
  const newLeadId = keyed.entities.groups[groupId]?.lead_uuid ?? null;
  const newParentId = parentId || newLeadId;
  const movedGroupIds = new Set();
  const roleIds = new Set();

  // only real ids
  ids.forEach((roleId) => {
    if (Object.hasOwn(keyed.entities.roles, roleId)) {
      roleIds.add(roleId);
    }
  });

  // iterate hierarchy
  const iterateHierarchy = (currentId, sourceId, parentId) => {
    const current = keyed.entities.roles[currentId];
    const source = keyed.entities.roles[sourceId];
    const isSourceGroup = current.group_uuid === source.group_uuid;
    const isSourceRole = currentId === sourceId;

    // short-circuit if is another source id
    if (sourceId !== currentId && roleIds.has(currentId)) {
      return;
    }

    // move roles within source group
    if (isSourceGroup) {
      const update = { uuid: currentId, updated_at: now };
      let isDifferent = false;

      if (current.group_uuid !== newGroupId) {
        update.group_uuid = newGroupId;
        isDifferent = true;
      }

      if (isSourceRole && current.parent_uuid !== parentId) {
        update.parent_uuid = parentId;
        isDifferent = true;
      }

      if (isSourceRole) {
        Object.keys(input).forEach((key) => {
          if (current[key] !== input[key]) {
            update[key] = input[key];
            isDifferent = true;
          }
        });
      }

      if (isDifferent) {
        mutation.entities.roles.update.push(update);
      }
    }

    // move immediately surrounded groups
    if (!isSourceGroup && !movedGroupIds.has(current.group_uuid)) {
      movedGroupIds.add(current.group_uuid);

      const update = { uuid: current.group_uuid, updated_at: now };
      let isDifferent = false;

      if (current.group_uuid !== newGroupId) {
        update.group_uuid = newGroupId;
        isDifferent = true;
      }

      if (isDifferent) {
        mutation.entities.groups.update.push(update);
      }
    }

    // iterate children if still within source group
    if (isSourceGroup) {
      const currentChildren = Array.from(current.__children.entries());

      currentChildren.forEach(([childId, type]) => {
        if (type === DesignEntity.ROLE) {
          iterateHierarchy(childId, sourceId, currentId);
        }
      });
    }
  };

  roleIds.forEach((roleId) => iterateHierarchy(roleId, roleId, newParentId));

  // remove unused mutations
  let isDifferent = false;
  Object.keys(mutation.entities).forEach((type) => {
    Object.keys(mutation.entities[type]).forEach((action) => {
      if (mutation.entities[type][action].length > 0) {
        isDifferent = true;
      } else {
        delete mutation.entities[type][action];
      }
    });
  });

  return isDifferent ? mutation : null;
}
