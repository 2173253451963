import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

export const CREATE_INVITE_LINKS = /* GraphQL */ `
  mutation CreateManyInviteLinks($input: [InviteUserInput!]!) {
    createManyInviteLinks(input: $input)
  }
`;

export default function useCreateManyLinks(query) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: CREATE_INVITE_LINKS,
    invalidateQuery: query,
  });

  /**
   * @param {String} design_uuid
   * @param {Object[]} people
   * @returns {Promise}
   */
  function createManyLinks(design_uuid, people) {
    enqueueSnackbar(
      `Generating a bulk invite list for your organisation, look out for an email!`
    );

    const input = people
      ? people.map((person) => ({
          uuid: person.uuid,
          design_uuid,
        }))
      : [];

    return mutateAsync(input);
  }

  return createManyLinks;
}
