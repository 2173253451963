import ky, { HTTPError } from 'ky';
import env from '@beam-australia/react-env';
import shortuuid from '@pkg/uuid';
import ClientError from './utils/ClientError';
import GraphQLError from './utils/GraphQLError';

class Client {
  api = null;

  /**
   * @param {string} host
   * @param {Object} [headers]
   */
  constructor(host, headers = {}, onError) {
    this.api = ky.create({
      timeout: false,
      prefixUrl: host,
      headers,
    });

    this.onError = onError;

    this.isAuthorized = headers?.Authorization?.startsWith('Bearer') ?? false;

    ['get', 'post', 'put', 'patch', 'head', 'delete'].forEach((method) => {
      this[method] = (input, json) => this.request(method, input, json);
    });
  }

  /**
   * @param {object} [options]
   */
  async graphql(options = {}) {
    console.debug('Client.graphql', { query: options.query });
    const response = await this.post('graphql', options);

    if (response.errors && env('PLATFORM_ENV') !== 'production') {
      if (this.is404equivalent(response.errors)) {
        return;
      }

      const graphqlError = new GraphQLError(response.errors);

      console.error('graphqlError', graphqlError);
      this.onError(graphqlError);
    }

    return response;
  }

  /**
   * @param {string} method
   * @param {string} input
   * @param {Object} payload
   *
   * @throws {ClientError}
   */
  async request(method = 'GET', input, payload) {
    const options = {};

    if (payload instanceof FormData) {
      options.body = payload;
    } else {
      options.json = payload;
    }

    options.headers = {
      ...options.headers,
      'X-Request-ID': shortuuid.generate(),
    };

    try {
      return await this.api[method](input, options).json();
    } catch (error) {
      if (error instanceof HTTPError) {
        const data = await error.response.json();
        throw new ClientError(data);
      }

      throw error;
    }
  }

  is404equivalent(errors) {
    const pathsToTreatAs404s = ['design'];

    return (
      pathsToTreatAs404s.includes(errors[0]?.path?.[0] ?? '') &&
      errors[0]?.debugMessage.includes('No query results')
    );
  }
}

export default Client;
