import { get } from '@pkg/utils/objects';

/**
 * @param {String} mutation
 * @param {Object} map
 * @param {Object} variables
 * @returns {Object}
 */
export default function mapMultipart(mutation, map, variables) {
  const data = new FormData();
  const operations = { query: mutation, variables };

  data.append('operations', JSON.stringify(operations));
  data.append('map', JSON.stringify(map));

  Object.keys(map).forEach((key) => {
    map[key].forEach((path) => {
      data.append(key, get(operations, path));
    });
  });

  return data;
}
