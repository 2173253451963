import { Size } from '@/atoms/enums';

const ToggleButtonSize = Object.freeze({
  [Size.X_SMALL]: {
    button: {
      borderRadius: '2px',
      padding: '0 6px',
      minHeight: '1.375rem',
    },
    svg: {
      height: '0.875rem',
      width: '0.875rem',
    },
  },
  [Size.SMALL]: {
    button: {
      borderRadius: '3px',
      padding: '0 8px',
      minHeight: '1.75rem',
    },
    svg: {
      height: '1.15rem',
      width: '1.15rem',
    },
  },
  [Size.MEDIUM]: {
    button: {
      padding: '0 8px',
      minHeight: '1.75rem',
    },
    svg: {
      height: '1.25rem',
      width: '1.25rem',
    },
  },
  [Size.LARGE]: {
    button: {
      padding: '0 8px',
      minHeight: '2.125rem',
    },
    svg: {
      height: '1.375rem',
      width: '1.375rem',
    },
  },
  [Size.X_LARGE]: {
    button: {
      borderRadius: '2px',
      minHeight: '2.5rem',
    },
    svg: {
      height: '1.625rem',
      width: '1.625rem',
    },
  },
});

export default ToggleButtonSize;
