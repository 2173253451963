import { useMemo } from 'react';
import { useState } from 'react';
import * as Auth from '@pkg/auth';
import { useQuery } from '@pkg/graphql';

const GROUP_SUPPORTER_MAP = /* GraphQL */ `
  query GroupSupporterMap($organisationId: UUID!) {
    allGroupSupporters(organisation_uuid: $organisationId) {
      uuid
      people
    }
  }
`;

/**
 * @return {Array}
 */
export default function useSupporterMap() {
  const organisation = Auth.useStore((state) => state.organisation);
  const [isLoading, setIsLoading] = useState(true);

  if (!organisation) {
    return {
      isLoading: false,
      supporterMap: new Map(),
    };
  }

  const query = [GROUP_SUPPORTER_MAP, { organisationId: organisation.uuid }];
  const { data, dataUpdatedAt } = useQuery({ query });

  const supporterMap = useMemo(() => {
    const map = new Map();

    data?.allGroupSupporters?.forEach(({ uuid, people }) => {
      map.set(uuid, new Set(people));
    });

    if (data) {
      setIsLoading(false);
    }

    return map;
  }, [dataUpdatedAt]);

  return {
    isLoading,
    supporterMap,
  };
}
