import { Skills } from '@pkg/entities/library';
import { Lens } from '@/lib/enums';
import { palettes } from '@/lib/theme/tokens';
import { getFlattened, getKeyed } from '../utils';

const LensTemplate = Object.freeze({
  type: Lens.GAP,
  color: null,
});

export default function gap({ snapshot, peopleSkills }) {
  const applied = getKeyed(snapshot);

  for (const roleId in applied.entities.roles) {
    const role = applied.entities.roles[roleId];
    role.__lens = structuredClone(LensTemplate);

    const skills = [];

    role.skills.forEach((skill) => {
      const updated = structuredClone(skill);
      const lens = structuredClone(LensTemplate);
      const personLevel = peopleSkills.get(skill.uuid)?.get(role.user_uuid);

      const hasSkill = Boolean(personLevel);
      const partial =
        hasSkill && !Skills.compare.meets(personLevel, skill.level);
      const gap = !hasSkill || partial;

      lens.color = partial
        ? palettes.brand.orange.tints[1]
        : gap
          ? palettes.brand.coral.tints[2]
          : null;

      updated.__lens = lens;
      skills.push(updated);
    });

    role.skills = skills;
  }

  return getFlattened(applied);
}
