import { Base as BaseColors } from '@/atoms/colors';
import { Colors as InputColors } from '@/atoms/inputs';
import { useDispatchContext } from '@/shared/providers';
import MuiMenuItem from '@mui/material/MenuItem';
import { red } from '@/lib/theme/tokens/palettes';
import DialogAction from '@/components/DialogAction';

const Item = ({ children, danger, disabled, onClick, color }) => {
  return (
    <MuiMenuItem
      disabled={disabled}
      onClick={onClick}
      sx={{
        fontSize: '0.875rem',
        color: danger ? red.colors[400] : BaseColors.font.primary,
        '&:hover, &:focus': {
          ...InputColors.Select.option[color].hover,
        },
      }}
    >
      {children}
    </MuiMenuItem>
  );
};

const MenuItem = ({
  action,
  children,
  color = 'secondary',
  confirmation = null,
  danger,
  disabled = false,
  onClick,
  onClose,
  properties,
}) => {
  const dispatch = useDispatchContext();

  const handleClick = (event) => {
    event?.stopPropagation();
    onClick?.(event);
    dispatch?.handleDispatch?.({ action, properties });
    onClose?.();
  };

  if (dispatch?.handleDispatch && !dispatch?.canEdit) {
    return null;
  }

  return confirmation ? (
    <DialogAction
      title={confirmation?.title}
      content={confirmation?.content}
      onConfirm={handleClick}
    >
      <Item color={color} danger={danger} disabled={disabled}>
        {children}
      </Item>
    </DialogAction>
  ) : (
    <Item
      color={color}
      danger={danger}
      disabled={disabled}
      onClick={handleClick}
    >
      {children}
    </Item>
  );
};

export default MenuItem;
