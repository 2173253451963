import { useFormikContext } from 'formik';
import FormStatus from '../FormStatus';

const FormikStatus = (props) => {
  const { status, submitCount, errors } = useFormikContext();

  return (
    <FormStatus
      {...props}
      status={status}
      submitCount={submitCount}
      errors={errors}
    />
  );
};

export default FormikStatus;
