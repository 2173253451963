/**
 * @param {String} type
 * @param {Object} draft
 * @param {String} uuid
 */
export default function remove(type, draft, uuid) {
  const index = draft[type].list.findIndex((item) => item.uuid === uuid);
  draft[type].list.splice(index, 1);
  draft[type].map.delete(uuid);
}
