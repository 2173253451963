import config from '@/lib/config';

const DICEBAR_PATH = 'https://api.dicebear.com/5.x/initials/png?seed=';
const UNASSIGNED_PATH = `${config.CDN}/statics/unassigned.png`;

/**
 * @param {Object} person
 *
 * @return {String}
 */
export default function avatarImage(person) {
  if (person?.avatar) {
    return person.avatar;
  }

  if (person) {
    return `${DICEBAR_PATH}${person?.first_name ?? ''}${
      person?.last_name ?? ''
    }`;
  }

  return UNASSIGNED_PATH;
}
