import { DesignType } from '../enums';
import { brand } from './tokens/palettes';

const ComparisonColors = Object.freeze({
  [DesignType.LIVE]: {
    fill: brand.green.main,
    text: brand.green.shades[1],
  },
  [DesignType.SCENARIO]: {
    fill: brand.yellow.main,
    text: brand.yellow.shades[2],
  },
});

export default ComparisonColors;
