import { useMemo } from 'react';
import getKeyed from './getKeyed';

/**
 * @param {Object} snapshot
 * @param {Number} [hash]
 * @returns {Object}
 */
export default function useKeyed(snapshot) {
  return useMemo(() => {
    return getKeyed(snapshot);
  }, [snapshot]);
}
