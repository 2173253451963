import { useMemo } from 'react';
import filters from './filters';

/**
 * @param {Object} snapshot
 * @param {Object} [options]
 * @return {Object}
 */
export default function useMetrics(snapshot, options = {}) {
  return useMemo(() => {
    return filters.metrics(snapshot, options);
  }, [JSON.stringify(snapshot), JSON.stringify(options)]);
}
