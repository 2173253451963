import { ChartTooltip } from '@/molecules/chartElements';

const VerticalSegment = ({
  chartHeight,
  color,
  height,
  metric,
  metricTotals,
  row,
  width,
  xPosition,
  yPosition,
}) => {
  return (
    height > 0 && (
      <g className="horizontal-bar">
        <rect
          width={width}
          height={6}
          rx={3}
          ry={3}
          x={xPosition}
          y={yPosition + height - 4}
          fill={color.borderColor}
        />
        <rect
          width={width}
          height={height}
          rx={3}
          ry={3}
          x={xPosition}
          y={yPosition}
          fill={color.backgroundColor}
        />
        <ChartTooltip
          item={row}
          maxWidth={500}
          metric={metric}
          metricTotals={metricTotals}
          minWidth={200}
          position="left"
        >
          <foreignObject
            x={xPosition}
            y={yPosition}
            width={width}
            height={height}
          />
        </ChartTooltip>
      </g>
    )
  );
};

export default VerticalSegment;
