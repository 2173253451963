import groupByDateResolution, { TIMEFRAME } from './groupByDateResolution';

/**
 * @param {String} key
 * @param {Number} count
 * @param {Number} revisionCount
 * @return {String}
 */
const formatRevisionLabel = (key, count, revisionCount) => {
  let label;
  if (revisionCount === 0) {
    return 'Latest';
  }
  switch (key) {
    case TIMEFRAME.DAYS:
      label = count > 1 ? `${count} days ago` : 'Yesterday';
      break;
    case TIMEFRAME.WEEKS:
      label = count > 1 ? `${count} weeks ago` : 'Last week';
      break;
    case TIMEFRAME.MONTHS:
      label = count > 1 ? `${count} months ago` : 'Last month';
      break;
    case TIMEFRAME.YEARS:
      label = count > 1 ? `${count} years ago` : 'Last year';
      break;
    default:
  }

  if (revisionCount === 1) {
    label = `Previous (${label})`;
  }

  return label;
};

/**
 * @param {Object} collection
 * @return {Array}
 */
export default function formatRevisions(collection) {
  const dateResolutions = groupByDateResolution(collection, 'created_at');

  const revisions = [];

  Object.entries(dateResolutions).forEach(([key, collections]) => {
    Object.entries(collections).forEach(([count, collection]) => {
      if (!count) {
        return;
      }
      revisions.push({
        label: formatRevisionLabel(Number(key), count, revisions.length),
        value: collection.uuid,
        __order: collection.order,
        __resolution: key,
      });
    });
  });

  revisions.sort((a, b) => a.__order - b.__order);

  return revisions;
}
