import * as Colors from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const ButtonColors = Object.freeze({
  primary: {
    contained: {
      normal: {
        backgroundColor: brand._navy.main,
        color: core.white,
      },
      hover: {
        backgroundColor: brand._navy.tints[4],
        color: core.white,
      },
      focus: {
        backgroundColor: brand._navy.tints[4],
        color: core.white,
      },
    },
    naked: {
      normal: {
        backgroundColor: 'transparent',
        color: brand._navy.main,
      },
      hover: {
        backgroundColor: brand._navy.tints[1],
        color: brand._navy.main,
      },
      focus: {
        backgroundColor: brand._navy.tints[1],
        color: brand._navy.main,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: brand._navy.main,
      },
      hover: {
        backgroundColor: brand._navy.tints[1],
        color: brand._navy.main,
      },
      focus: {
        backgroundColor: brand._navy.tints[1],
        color: brand._navy.main,
      },
    },
    outline: {
      normal: {
        border: 'none',
        boxShadow: `0 0 0 1px ${brand._navy.main}`,
        color: brand._navy.main,
      },
      hover: {
        backgroundColor: brand._navy.tints[1],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand._navy.main}`,
        color: brand._navy.main,
      },
      focus: {
        backgroundColor: brand._navy.tints[1],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand._navy.main}`,
        color: brand._navy.main,
      },
    },
  },
  secondary: {
    contained: {
      normal: {
        backgroundColor: brand.yellow.main,
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: brand.yellow.tints[4],
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.yellow.tints[4],
        color: Colors.Base.font.tertiary,
      },
      disabled: {
        backgroundColor: brand.yellow.tints[4],
        color: Colors.Base.font.tertiary,
      },
    },
    naked: {
      normal: {
        backgroundColor: 'transparent',
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: brand.yellow.tints[2],
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.yellow.tints[2],
        color: Colors.Base.font.tertiary,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: brand.yellow.tints[2],
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.yellow.tints[2],
        color: Colors.Base.font.tertiary,
      },
    },
    outline: {
      normal: {
        border: 'none',
        borderRadius: '8px',
        boxShadow: `0 0 0 1px ${brand.yellow.main}`,
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: brand.yellow.tints[4],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.yellow.main}`,
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.yellow.tints[4],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.yellow.main}`,
        color: Colors.Base.font.tertiary,
      },
    },
  },
  tertiary: {
    contained: {
      normal: {
        backgroundColor: brand.blue.main,
        color: core.white,
      },
      hover: {
        backgroundColor: brand.blue.shades[1],
        color: core.white,
      },
      focus: {
        backgroundColor: brand.blue.shades[1],
        color: core.white,
      },
    },
    naked: {
      normal: {
        backgroundColor: 'transparent',
        color: brand.purple.main,
      },
      hover: {
        backgroundColor: '#e5e5eb',
        color: brand.purple.main,
      },
      focus: {
        backgroundColor: '#e5e5eb',
        color: brand.purple.main,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: brand.purple.main,
      },
      hover: {
        backgroundColor: '#e5e5eb',
        color: brand.purple.main,
      },
      focus: {
        backgroundColor: '#e5e5eb',
        color: brand.purple.main,
      },
    },
    outline: {
      normal: {
        border: 'none',
        borderRadius: '8px',
        boxShadow: `0 0 0 1px ${brand.purple.main}`,
        color: brand.purple.main,
      },
      hover: {
        backgroundColor: brand.purple.tints[1],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.purple.main}`,
        color: brand.purple.main,
      },
      focus: {
        backgroundColor: brand.purple.tints[1],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.purple.main}`,
        color: brand.purple.main,
      },
    },
  },
  'light-blue': {
    contained: {
      normal: {
        backgroundColor: brand.lightBlue.tints[5],
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: brand.lightBlue.main,
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.lightBlue.main,
        color: Colors.Base.font.tertiary,
      },
    },
    naked: {
      normal: {
        backgroundColor: 'transparent',
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: brand.lightBlue.tints[4],
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.lightBlue.tints[4],
        color: Colors.Base.font.tertiary,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: 'transparent',
        boxShadow: `0 0 0 1px ${brand.lightBlue.main}`,
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: 'transparent',
        boxShadow: `0 0 0 1px ${brand.lightBlue.main}`,
        color: Colors.Base.font.tertiary,
      },
    },
    outline: {
      normal: {
        backgroundColor: 'transparent',
        border: 'none',
        borderRadius: '4px',
        boxShadow: `inset 0 0 0 1px ${brand.lightBlue.shades[0]}`,
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: `${brand.lightBlue.tints[4]}`,
        border: 'none',
        boxShadow: `inset 0 0 0 1px ${brand.lightBlue.main}`,
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: `${brand.lightBlue.tints[4]}`,
        border: 'none',
        boxShadow: `inset 0 0 0 1px ${brand.lightBlue.main}`,
        color: Colors.Base.font.tertiary,
      },
    },
  },
  white: {
    contained: {
      normal: {
        backgroundColor: core.white,
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: `${core.white}50`,
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: `${core.white}50`,
        color: Colors.Base.font.tertiary,
      },
    },
    naked: {
      normal: {
        backgroundColor: 'transparent',
        color: core.white,
      },
      hover: {
        backgroundColor: `${brand._navy.tints[7]}`,
        color: core.white,
      },
      focus: {
        backgroundColor: `${brand._navy.tints[7]}`,
        color: core.white,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: core.white,
      },
      hover: {
        backgroundColor: 'transparent',
        boxShadow: `0 0 0 1px ${core.white}`,
        color: core.white,
      },
      focus: {
        backgroundColor: 'transparent',
        boxShadow: `0 0 0 1px ${core.white}`,
        color: core.white,
      },
    },
    outline: {
      normal: {
        border: 'none',
        borderRadius: '2px',
        boxShadow: `0 0 0 1px ${core.white}`,
        color: core.white,
      },
      hover: {
        backgroundColor: `${brand._navy.tints[7]}`,
        border: 'none',
        boxShadow: `0 0 0 1px ${core.white}`,
        color: core.white,
      },
      focus: {
        backgroundColor: `${brand._navy.tints[7]}`,
        border: 'none',
        boxShadow: `0 0 0 1px ${core.white}`,
        color: core.white,
      },
    },
  },
  grey: {
    contained: {
      normal: {
        backgroundColor: brand.grey.shades[2],
        color: core.white,
      },
      hover: {
        backgroundColor: brand.grey.shades[4],
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: brand.grey.shades[4],
        color: Colors.Base.font.tertiary,
      },
    },
    naked: {
      normal: {
        backgroundColor: 'transparent',
        color: core.white,
      },
      hover: {
        backgroundColor: brand.grey.tints[3],
        color: core.white,
      },
      focus: {
        backgroundColor: brand.grey.tints[3],
        color: core.white,
      },
    },
    outline: {
      normal: {
        border: 'none',
        borderRadius: '2px',
        boxShadow: `0 0 0 1px ${brand.grey.tints[4]}`,
        color: Colors.Base.font.tertiary,
      },
      hover: {
        backgroundColor: 'transparent',
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.grey.tints[4]}`,
        color: Colors.Base.font.tertiary,
      },
      focus: {
        backgroundColor: `${brand.grey.tints[2]}`,
        border: 'none',
        boxShadow: `0 0 0 1px ${core.white}`,
        color: Colors.Base.font.tertiary,
      },
    },
  },
});

export default ButtonColors;
