import { useMemo } from 'react';
import { Action } from '@/lib/enums';

/**
 * @param {Object} matrix
 * @returns {Object}
 */
export default function useReadRestrictions(matrix) {
  return useMemo(() => {
    const restricted = [];

    if (!matrix) {
      return restricted;
    }

    console.time('Access.policies.readRestrictions');
    for (const level in matrix) {
      if (level === 'key') {
        continue;
      }

      for (const entityType in matrix[level]) {
        for (const relationship in matrix[level][entityType]) {
          for (const property in matrix[level][entityType][relationship]) {
            for (const action in matrix[level][entityType][relationship][
              property
            ]) {
              if (
                action === Action.READ &&
                matrix[level][entityType][relationship][property][action] ===
                  'false'
              ) {
                restricted.push({
                  level,
                  entityType,
                  relationship,
                  property,
                  action,
                });
              }
            }
          }
        }
      }
    }

    console.timeEnd('Access.policies.readRestrictions');
    return restricted;
  }, [JSON.stringify(matrix)]);
}
