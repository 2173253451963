import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import useFetchRevision from '../revisions/useFetch';

const PING_DESIGN_LATEST = /* GraphQL */ `
  query PingDesignLatest($designId: UUID!) {
    design(uuid: $designId) {
      latest {
        uuid
      }
    }
  }
`;

export default function useLatestPing() {
  const client = useClient();
  const database = useDatabase();
  const fetchRevision = useFetchRevision();

  const ping = async (designId) => {
    if (!designId) {
      return;
    }

    try {
      const body = {
        query: PING_DESIGN_LATEST,
        variables: { designId },
      };

      const [design, response] = await Promise.all([
        database.designs.get(designId),
        client.graphql(body),
      ]);

      if (!design || !response.data) {
        return;
      }

      const currentId = design.latest.uuid;
      const latestId = response.data.design.latest.uuid;
      const revision = await database.designRevisions.get(latestId);

      if (!revision) {
        await fetchRevision(latestId);
      }

      if (currentId !== latestId) {
        await database.designs.update(designId, { latest: { uuid: latestId } });
      }
    } catch {
      // swallow errors
    }
  };

  return ping;
}
