import { EntityType } from '@/shared/enums';
import TagCategory from '@/components/DashboardContainer/enums/TagCategory';

/**
 * Returns the tags from a given entity.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function getTagsFromEntity({
  activity,
  entityType,
  group,
  role,
}) {
  if (entityType === EntityType.ACTIVITY) {
    return activity?.tags?.length ? activity.tags : [TagCategory.NOT_TAGGED];
  }
  if (entityType === EntityType.ROLE) {
    return role?.tags?.length ? role.tags : [TagCategory.NOT_TAGGED];
  }
  if (entityType === EntityType.GROUP) {
    return group?.tags?.length ? group.tags : [TagCategory.NOT_TAGGED];
  }

  return [TagCategory.NOT_TAGGED];
}
