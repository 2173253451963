import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';
import { DesignEntity } from '@/lib/enums';

const INVITE_TO_EDIT_ROLES = /* GraphQL */ `
  mutation InviteToEditRoles($input: [InviteUserInput!]!) {
    createManyInvites(input: $input)
  }
`;

/**
 * @param {any[]} query
 * @return {Function}
 */
export default function useInviteToEditRoles(query) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: INVITE_TO_EDIT_ROLES,
    invalidateQuery: query,
  });

  /**
   * @param {Object} designId
   * @param {Object} flow
   * @param {String} [flow.campaignId]
   * @param {Object} [flow.templateId]
   * @param {Object[]} roles
   * @param {String} roles[].uuid
   * @param {String} roles[].user_uuid
   * @param {String} roles[].title
   * @returns {Promise}
   */
  function inviteToEditRoles(designId, flow, roles) {
    const input = roles
      .filter((role) => !!role.user_uuid)
      .map((role) => ({
        uuid: role.user_uuid,
        design_uuid: designId,
        entity: {
          uuid: role.uuid,
          name: role.title,
          type: DesignEntity.ROLE,
        },
        flow: {
          campaign_id: flow.campaignId,
          template_uuid: flow.uuid,
        },
      }));

    const length = input.length;
    const people = length > 1 ? 'people' : 'person';
    const entities = length > 1 ? 'roles' : 'role';
    enqueueSnackbar(`Inviting ${length} ${people} to edit their ${entities}`);
    return mutateAsync(input);
  }

  return inviteToEditRoles;
}
