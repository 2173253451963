import { useMemo, useState } from 'react';
import { DEFAULT_ACTIVITY_DESCRIPTION } from '@/shared/hooks/useDefaultActivity';
import { Activities } from '@pkg/entities/library';

/**
 * // Provides a search function to filter activities in the store.
 * @param {Number} limit
 *
 * @return {Object}
 */
const useActivitySearch = (limit = 10) => {
  const activityMap = Activities.useStore((state) => state.mapped);
  const activityList = Activities.useStore((state) => state.list);
  const activityIndex = Activities.useStore((state) => state.index);

  const [activities, setActivities] = useState([]);

  const searchActivities = (searchTerm) => {
    if (!searchTerm) {
      return activityList.slice(0, limit);
    }

    const existing = new Set();
    const results = [];
    let count = 0;

    activityIndex.search(searchTerm).forEach((uuid) => {
      const { description } = activityMap.get(uuid);

      if (description === DEFAULT_ACTIVITY_DESCRIPTION) {
        return;
      }

      existing.add(description);
      const option = {
        description,
        uuid,
      };

      if (count < limit) {
        results.push(option);
        count++;
      }
    });

    setActivities(results);

    return results.length > 0 ? results : [];
  };

  return useMemo(() => {
    return {
      activities,
      activityList,
      activityMap,
      searchActivities,
    };
  }, [activityList, activityMap, searchActivities]);
};

export default useActivitySearch;
