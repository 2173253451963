import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import ArrowDownIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowUpIcon from '@mui/icons-material/ArrowDropUpRounded';
import Stack from '@mui/material/Stack';
import Box from '@/components/Box';
import PercentageChangeTextSize from './PercentageChangeTextSize';

const PercentageChangeText = ({ color, value, size = Size.MEDIUM }) => {
  // We remove any negative symbols in favour of using icons.
  const formattedValue = Math.abs(value);

  const { container, icon, iconContainer, variant } =
    PercentageChangeTextSize[size];

  const isNotZero = value !== 0;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="stretch"
      color={color ?? 'inherit'}
    >
      {isNotZero && (
        <Box sx={{ position: 'relative', ...container }}>
          <Box sx={{ position: 'absolute', ...iconContainer }}>
            {value > 0 && (
              <ArrowUpIcon
                sx={{
                  ...icon,
                }}
              />
            )}
            {value < 0 && (
              <ArrowDownIcon
                sx={{
                  ...icon,
                }}
              />
            )}
          </Box>
        </Box>
      )}
      <Heading variant={variant} overrideStyles={{ fontWeight: 400 }}>
        {formattedValue}%
      </Heading>
    </Stack>
  );
};

export default PercentageChangeText;
