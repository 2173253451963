import { useState } from 'react';
import { OutlineCard } from '@/organisms/cards';
import {
  AggregateActivityDoughnutChart,
  AggregateActivityListChart,
} from '@/organisms/charts';
import useScenarioActivities from '@/shared/hooks/useScenarioActivities';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import { DesignEntity } from '@/lib/enums';

const INITIAL_ACTIVITY_COUNT = 5;

const Chart = styled('div')({
  marginLeft: 16,
  height: 200,
  maxHeight: 200,
  position: 'relative',
  width: 'calc(100% - 16px)',
  '& canvas': {
    left: '50%',
    position: 'absolute',
    transform: 'translateX(-50%)',
  },
});

const AggregateActivitiesCard = ({ entityType, title }) => {
  const { activities, metrics, unallocated } = useScenarioActivities();
  const [showAllActivities, setShowAllActivities] = useState(false);

  const personalisedTitle =
    entityType === DesignEntity.ROLE || entityType === DesignEntity.MANAGER
      ? 'Where you spend most of your time'
      : 'Where your team spends most of their time';

  const handleClick = () => {
    setShowAllActivities(!showAllActivities);
  };

  return (
    <OutlineCard
      title={title ?? personalisedTitle}
      actionText={`${showAllActivities ? 'Hide' : 'Show'} other activities`}
      onClick={handleClick}
    >
      <Grid container>
        <Grid item xs={6}>
          <AggregateActivityListChart
            activities={activities}
            initialActivityCount={INITIAL_ACTIVITY_COUNT}
            metrics={metrics}
            showAllActivities={showAllActivities}
            unallocated={unallocated}
          />
        </Grid>
        <Grid item xs={6}>
          <Chart>
            <AggregateActivityDoughnutChart
              initialActivityCount={INITIAL_ACTIVITY_COUNT}
              metrics={metrics}
              showAllActivities={showAllActivities}
              unallocated={unallocated}
            />
          </Chart>
        </Grid>
      </Grid>
    </OutlineCard>
  );
};

export default AggregateActivitiesCard;
