import { useEffect, useState } from 'react';
import { Skill as SkillColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { MultiCheckboxOptions } from '@/atoms/inputs';
import { Paragraph } from '@/atoms/typography';
import { FilterSearch } from '@/molecules/filterElements';
import { useSkillContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import { SkillLevel } from '@/lib/enums';

const sortSkillOptions = (list, selectedValues) => {
  if (!list.length) {
    return;
  }

  const levels = Object.keys(SkillLevel);
  const selected = new Set(
    selectedValues?.length ? selectedValues?.map(({ id }) => id) : []
  );

  const options = list.map(({ uuid, name }) => ({
    id: uuid,
    name,
    options: levels,
  }));

  if (selectedValues?.length) {
    options.sort((a, b) => {
      const aSelected = selected.has(a.id);
      const bSelected = selected.has(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });
  }

  return options;
};

const SkillSelect = ({ value, onChange }) => {
  const { search, skillList, skillMap } = useSkillContext();
  const [options, setOptions] = useState();

  const handleChange = (event, skills) => {
    const values = skills.map(({ id, value }) => ({
      id,
      label: skillMap?.get(id)?.name,
      value,
    }));

    onChange?.(event, values);
  };

  const handleSearch = (event, term) => {
    if (!term) {
      setOptions(sortSkillOptions(skillList, value));
      return;
    }

    const results = search({ searchTerm: term });

    if (!results?.length) {
      setOptions([]);
      return;
    }

    setOptions(sortSkillOptions(results, value));
  };

  useEffect(() => {
    if (!skillList?.length || options) {
      return;
    }

    setOptions(sortSkillOptions(skillList, value));
  }, [JSON.stringify(skillList, value)]);

  return options ? (
    <>
      <FilterSearch onSearch={handleSearch} placeholder="Search for skills" />
      <Box sx={{ maxHeight: 340, overflowY: 'auto' }}>
        {options?.length > 0 ? (
          <MultiCheckboxOptions
            colorProps={SkillColors}
            onChange={handleChange}
            options={options}
            optionLabel="name"
            initialSelected={value}
          />
        ) : (
          <Box p={2}>
            <Paragraph
              size={Size.SMALL}
              overrideStyles={{ textAlign: 'center' }}
            >
              No skills were found matching that search term
            </Paragraph>
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Box p={2}>
      <Paragraph size={Size.SMALL} overrideStyles={{ textAlign: 'center' }}>
        No skills have been added.
      </Paragraph>
    </Box>
  );
};

export default SkillSelect;
