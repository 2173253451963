import { EntityType } from '@/shared/enums';
import { Position } from '@/lib/enums';

const defaultEntitySets = () => ({
  roles: new Set(),
  groups: new Set(),
  activities: new Set(),
});

export default function createMatrixMap({ matrix, metrics, order }) {
  const first = matrix.items[0];
  const second = matrix.items[1];

  const matrixMap = new Map([
    [
      'NONE',
      {
        id: 'NONE',
        uuid: 'NONE',
        [order[0]]: new Map(),
        entitySets: defaultEntitySets(),
        metrics: {
          ...metrics,
          activities: 0,
          roles: 0,
          groups: 0,
        },
        props: {
          position: second ? Position.BOTTOM_LEFT : Position.RIGHT,
          id: 'NONE',
        },
        type: EntityType.TAG,
      },
    ],
  ]);

  if (first) {
    matrixMap.set(first.id, {
      id: first.id,
      uuid: first.id,
      [order[0]]: new Map(),
      entitySets: defaultEntitySets(),
      metrics: { ...metrics },
      props: {
        ...first,
        position: second ? Position.TOP_LEFT : Position.LEFT,
      },
      type: EntityType.TAG,
    });
  }

  if (second) {
    matrixMap.set(second.id, {
      id: second.id,
      uuid: second.id,
      [order[0]]: new Map(),
      entitySets: defaultEntitySets(),
      metrics: { ...metrics },
      props: {
        ...second,
        position: Position.BOTTOM_RIGHT,
      },
      type: EntityType.TAG,
    });
    matrixMap.set('ALL', {
      id: 'ALL',
      uuid: 'ALL',
      [order[0]]: new Map(),
      entitySets: defaultEntitySets(),
      metrics: { ...metrics },
      props: {
        position: Position.TOP_RIGHT,
      },
      type: EntityType.TAG,
    });
  }

  return {
    order,
    metrics: { ...metrics },
    MATRIX: matrixMap,
    entitySet: new Set(matrix.items.map(({ id }) => id)),
  };
}
