import {
  cumulateMetrics,
  defaultMetrics,
  updateEntityAction,
} from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function updateActivity({
  action,
  activity,
  role,
  activityType,
  comparisonScenario,
}) {
  const updateActivity = activityType[EntityType.ACTIVITY].get(
    activity.uuid
  ) ?? {
    [EntityType.ROLE]: new Set(role ? [role.uuid] : []),
    actions: new Map(),
    metrics: defaultMetrics(comparisonScenario),
  };

  updateActivity.actions.set(
    action.type,
    updateEntityAction({
      action,
      actions: updateActivity.actions,
      role,
      comparisonScenario,
    })
  );

  updateActivity.metrics[1] = cumulateMetrics(
    action,
    updateActivity.metrics[1]
  );

  return updateActivity;
}
