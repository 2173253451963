/**
 * @param {String} string
 * @param {Number} maxLength
 * @returns {String}
 */
export default function truncate(string, maxLength) {
  const trimmed = string.trim();

  if (trimmed?.length > maxLength) {
    const truncatedString = trimmed.slice(0, maxLength);
    return `${truncatedString}...`;
  }

  return trimmed;
}
