import { SkillLevel } from '@/lib/enums';
import { core } from '@/lib/theme/tokens/color';
import { brand } from '@/lib/theme/tokens/palettes';

const SkillColors = Object.freeze({
  [SkillLevel.BASIC]: {
    normal: {
      backgroundColor: brand.blue.tints[5],
      color: core.white,
    },
    focus: {
      backgroundColor: brand.blue.tints[5],
      color: core.white,
    },
    hover: {
      backgroundColor: brand.blue.tints[5],
      color: core.white,
    },
  },
  [SkillLevel.INTERMEDIATE]: {
    normal: {
      backgroundColor: brand.blue.shades[2],
      color: core.white,
    },
    focus: {
      backgroundColor: brand.blue.shades[2],
      color: core.white,
    },
    hover: {
      backgroundColor: brand.blue.shades[2],
      color: core.white,
    },
  },
  [SkillLevel.ADVANCED]: {
    normal: {
      backgroundColor: brand.blue.shades[4],
      color: core.white,
    },
    focus: {
      backgroundColor: brand.blue.shades[4],
      color: core.white,
    },
    hover: {
      backgroundColor: brand.blue.shades[4],
      color: core.white,
    },
  },
});

export default SkillColors;
