import ManageComparison from '@/organisms/comparison/ManageComparison';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InsightMetrics from '../InsightMetrics';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import ActivitySummary from './ActivitySummary';
import AlignmentSummary from './AlignmentSummary';
import PrioritisationSummary from './PrioritisationSummary';
import TagSummary from './TagSummary';

const SummaryInsights = () => {
  const { scenario, snapshotEntityMap, comparisonScenario } =
    useScenarioContext();
  const entityType = scenario?.entity?.__type;
  const { id, scope, scopeEntity } = useViewModeContext();

  const isActiveComparison = !!comparisonScenario;

  return (
    <Grid container spacing={3} pb={4}>
      <Grid item xs={12}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <InsightsTitle
            entityMap={snapshotEntityMap}
            id={id}
            scope={scope}
            scopeEntity={scopeEntity}
          />
          <ManageComparison />
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <InsightMetrics />
      </Grid>
      <Grid item xs={6}>
        <ActivitySummary
          entityType={entityType}
          entityId={id}
          showComparison={isActiveComparison}
        />
      </Grid>
      <Grid item xs={6}>
        <TagSummary
          entityType={entityType}
          entityId={id}
          showComparison={isActiveComparison}
        />
      </Grid>
      <Grid item xs={6}>
        <PrioritisationSummary />
      </Grid>
      {isActiveComparison && (
        <Grid item xs={6}>
          <PrioritisationSummary showComparison={isActiveComparison} />
        </Grid>
      )}
      <Grid item xs={6}>
        <AlignmentSummary />
      </Grid>
      {isActiveComparison && (
        <Grid item xs={6}>
          <AlignmentSummary showComparison={isActiveComparison} />
        </Grid>
      )}
    </Grid>
  );
};

export default SummaryInsights;
