import { useEffect } from 'react';
import { useAuth } from '@frontegg/nextjs';
import { useAccessToken } from '@pkg/auth/AccessTokenProvider';
import useLimitedAccessToken from './useLimitedAccessToken';

function emailFromToken(token) {
  if (!token) {
    return null;
  }

  const [, payload] = token.split('.');
  const { email } = JSON.parse(atob(payload));
  return email;
}

export default function useAuthentication() {
  const { isLoading: isFronteggLoading, isAuthenticated, user } = useAuth();
  const { isLoading: isLimitedTokenLoading, limitedAccessToken } =
    useLimitedAccessToken();
  const { setAccessToken } = useAccessToken();

  const isLoading = isFronteggLoading || isLimitedTokenLoading;

  useEffect(() => {
    if (isLoading) {
      return;
    }

    // logged in via frontegg
    if (isAuthenticated) {
      setAccessToken(user?.accessToken);
      return;
    }

    // logged in via limited access token
    if (limitedAccessToken) {
      setAccessToken(limitedAccessToken);
    }
  }, [isFronteggLoading, isLimitedTokenLoading, isAuthenticated]);

  return {
    isFronteggAuthenticated: isAuthenticated,
    isAuthenticated: isAuthenticated || Boolean(limitedAccessToken),
    isLoading,
    hasLimitedAccess: !isAuthenticated && Boolean(limitedAccessToken),
    email: user?.email?.toLowerCase() ?? emailFromToken(limitedAccessToken),
  };
}
