import { produce } from 'immer';
import { useCallback } from 'react';
import { useLocalState } from '@pkg/hooks';
import { Obj } from '@pkg/utils';
import * as Actions from './actions';

/**
 * @param {Object} initial
 * @returns {Array}
 */
export default function useTagState({ tags, categories }) {
  const [state, setState] = useLocalState({ tags, categories });

  /**
   * @param {Object} mutation
   * @returns {undefined}
   */
  const mutateState = (mutation) => {
    const product = produce(state, (draft) => {
      Object.keys(mutation).forEach((type) => {
        Object.keys(mutation[type]).forEach((action) => {
          mutation[type][action].forEach((input) => {
            Actions[action](type, draft, input);
          });
        });
      });
    });

    setState(product);
    return Obj.omitDerived(product);
  };

  return [state, useCallback(mutateState, [state, setState])];
}
