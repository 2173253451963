import { Size } from '@/atoms/enums';

const ListBulletSizeProps = Object.freeze({
  [Size.SMALL]: {
    borderRadius: '0.625rem',
    height: '0.625rem',
    minWidth: '0.625rem',
    width: '0.625rem',
  },
  [Size.MEDIUM]: {
    borderRadius: '0.75rem',
    height: '0.75rem',
    minWidth: '0.75rem',
    width: '0.75rem',
  },
  [Size.LARGE]: {
    borderRadius: '0.875rem',
    height: '0.875rem',
    minWidth: '0.875rem',
    width: '0.875rem',
  },
});

export default ListBulletSizeProps;
