import { core, text } from '@/lib/theme/tokens/color';
import {
  blue,
  cyan,
  green,
  grey,
  lilac,
  orange,
  pink,
  purple,
  red,
  yellow,
} from '@/lib/theme/tokens/palettes';

const TagColors = Object.freeze({
  blue: {
    regular: {
      active: {
        normal: {
          backgroundColor: blue.colors[600],
          color: text[blue.contrast[600]],
        },
        focus: {
          backgroundColor: blue.colors[300],
          color: text[blue.contrast[300]],
        },
        hover: {
          backgroundColor: blue.colors[300],
          color: text[blue.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: blue.colors[300],
          color: text[blue.contrast[300]],
        },
        hover: {
          backgroundColor: blue.colors[300],
          color: text[blue.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: blue.colors[300],
          color: text[blue.contrast[300]],
        },
        focus: {
          backgroundColor: blue.colors[100],
          color: text[blue.contrast[100]],
        },
        hover: {
          backgroundColor: blue.colors[100],
          color: text[blue.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: blue.colors[100],
          color: text[blue.contrast[100]],
        },
        hover: {
          backgroundColor: blue.colors[100],
          color: text[blue.contrast[100]],
        },
      },
    },
  },
  cyan: {
    regular: {
      active: {
        normal: {
          backgroundColor: cyan.colors[600],
          color: text[cyan.contrast[600]],
        },
        focus: {
          backgroundColor: cyan.colors[300],
          color: text[cyan.contrast[300]],
        },
        hover: {
          backgroundColor: cyan.colors[300],
          color: text[cyan.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: cyan.colors[300],
          color: text[cyan.contrast[300]],
        },
        hover: {
          backgroundColor: cyan.colors[300],
          color: text[cyan.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: cyan.colors[300],
          color: text[cyan.contrast[300]],
        },
        focus: {
          backgroundColor: cyan.colors[100],
          color: text[cyan.contrast[100]],
        },
        hover: {
          backgroundColor: cyan.colors[100],
          color: text[cyan.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: cyan.colors[100],
          color: text[cyan.contrast[100]],
        },
        hover: {
          backgroundColor: cyan.colors[100],
          color: text[cyan.contrast[100]],
        },
      },
    },
  },
  green: {
    regular: {
      active: {
        normal: {
          backgroundColor: green.colors[600],
          color: text[green.contrast[600]],
        },
        focus: {
          backgroundColor: green.colors[300],
          color: text[green.contrast[600]],
        },
        hover: {
          backgroundColor: green.colors[300],
          color: text[green.contrast[600]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: green.colors[300],
          color: text[green.contrast[300]],
        },
        hover: {
          backgroundColor: green.colors[300],
          color: text[green.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: green.colors[200],
          color: text[green.contrast[200]],
        },
        focus: {
          backgroundColor: green.colors[100],
          color: text[green.contrast[100]],
        },
        hover: {
          backgroundColor: green.colors[100],
          color: text[green.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: green.colors[100],
          color: text[green.contrast[100]],
        },
        hover: {
          backgroundColor: green.colors[100],
          color: text[green.contrast[100]],
        },
      },
    },
  },
  grey: {
    regular: {
      active: {
        normal: {
          backgroundColor: grey.colors[600],
          color: text[grey.contrast[600]],
        },
        focus: {
          backgroundColor: grey.colors[300],
          color: text[grey.contrast[300]],
        },
        hover: {
          backgroundColor: grey.colors[300],
          color: text[grey.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: grey.colors[300],
          color: text[grey.contrast[300]],
        },
        hover: {
          backgroundColor: grey.colors[300],
          color: text[grey.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: grey.colors[200],
          color: text[grey.contrast[200]],
        },
        focus: {
          backgroundColor: grey.colors[100],
          color: text[grey.contrast[100]],
        },
        hover: {
          backgroundColor: grey.colors[100],
          color: text[grey.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: grey.colors[100],
          color: text[grey.contrast[100]],
        },
        hover: {
          backgroundColor: grey.colors[100],
          color: text[grey.contrast[100]],
        },
      },
    },
  },
  lilac: {
    regular: {
      active: {
        normal: {
          backgroundColor: lilac.colors[600],
          color: text[lilac.contrast[600]],
        },
        focus: {
          backgroundColor: lilac.colors[300],
          color: text[lilac.contrast[300]],
        },
        hover: {
          backgroundColor: lilac.colors[300],
          color: text[lilac.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: lilac.colors[300],
          color: text[lilac.contrast[300]],
        },
        hover: {
          backgroundColor: lilac.colors[300],
          color: text[lilac.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: lilac.colors[300],
          color: text[lilac.contrast[300]],
        },
        focus: {
          backgroundColor: lilac.colors[100],
          color: text[lilac.contrast[100]],
        },
        hover: {
          backgroundColor: lilac.colors[100],
          color: text[lilac.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: lilac.colors[100],
          color: text[lilac.contrast[100]],
        },
        hover: {
          backgroundColor: lilac.colors[100],
          color: text[lilac.colors[100]],
        },
      },
    },
  },
  orange: {
    regular: {
      active: {
        normal: {
          backgroundColor: orange.colors[600],
          color: text[orange.contrast[600]],
        },
        focus: {
          backgroundColor: orange.colors[300],
          color: text[orange.contrast[300]],
        },
        hover: {
          backgroundColor: orange.colors[300],
          color: text[orange.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: orange.colors[300],
          color: text[orange.contrast[300]],
        },
        hover: {
          backgroundColor: orange.colors[300],
          color: text[orange.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: orange.colors[200],
          color: text[orange.contrast[200]],
        },
        focus: {
          backgroundColor: orange.colors[100],
          color: text[orange.contrast[100]],
        },
        hover: {
          backgroundColor: orange.colors[100],
          color: text[orange.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: orange.colors[100],
          color: text[orange.contrast[100]],
        },
        hover: {
          backgroundColor: orange.colors[100],
          color: text[orange.contrast[100]],
        },
      },
    },
  },
  pink: {
    regular: {
      active: {
        normal: {
          backgroundColor: pink.colors[600],
          color: text[pink.contrast[600]],
        },
        focus: {
          backgroundColor: pink.colors[300],
          color: text[pink.contrast[300]],
        },
        hover: {
          backgroundColor: pink.colors[300],
          color: text[pink.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: pink.colors[300],
          color: text[pink.contrast[300]],
        },
        hover: {
          backgroundColor: pink.colors[300],
          color: text[pink.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: pink.colors[300],
          color: text[pink.contrast[300]],
        },
        focus: {
          backgroundColor: pink.colors[100],
          color: text[pink.contrast[100]],
        },
        hover: {
          backgroundColor: pink.colors[100],
          color: text[pink.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: pink.colors[200],
          color: text[pink.contrast[200]],
        },
      },
    },
  },
  purple: {
    regular: {
      active: {
        normal: {
          backgroundColor: purple.colors[600],
          color: text[purple.contrast[600]],
        },
        focus: {
          backgroundColor: purple.colors[300],
          color: text[purple.contrast[300]],
        },
        hover: {
          backgroundColor: purple.colors[300],
          color: text[purple.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: purple.colors[300],
          color: text[purple.contrast[300]],
        },
        hover: {
          backgroundColor: purple.colors[300],
          color: text[purple.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: purple.colors[300],
          color: text[purple.contrast[300]],
        },
        focus: {
          backgroundColor: purple.colors[100],
          color: text[purple.contrast[100]],
        },
        hover: {
          backgroundColor: purple.colors[100],
          color: text[purple.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: purple.colors[100],
          color: text[purple.contrast[100]],
        },
        hover: {
          backgroundColor: purple.colors[100],
          color: text[purple.contrast[100]],
        },
      },
    },
  },
  red: {
    regular: {
      active: {
        normal: {
          backgroundColor: red.colors[600],
          color: text[red.contrast[600]],
        },
        focus: {
          backgroundColor: red.colors[300],
          color: text[red.contrast[300]],
        },
        hover: {
          backgroundColor: red.colors[300],
          color: text[red.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: red.colors[300],
          color: text[red.contrast[300]],
        },
        hover: {
          backgroundColor: red.colors[300],
          color: text[red.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: red.colors[300],
          color: text[red.contrast[300]],
        },
        focus: {
          backgroundColor: red.colors[100],
          color: text[red.contrast[100]],
        },
        hover: {
          backgroundColor: red.colors[100],
          color: text[red.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: red.colors[100],
          color: text[red.contrast[100]],
        },
        hover: {
          backgroundColor: red.colors[100],
          color: text[red.contrast[100]],
        },
      },
    },
  },
  yellow: {
    regular: {
      active: {
        normal: {
          backgroundColor: yellow.colors[600],
          color: text[yellow.contrast[600]],
        },
        focus: {
          backgroundColor: yellow.colors[300],
          color: text[yellow.contrast[300]],
        },
        hover: {
          backgroundColor: yellow.colors[300],
          color: text[yellow.contrast[300]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: yellow.colors[300],
          color: text[yellow.contrast[300]],
        },
        hover: {
          backgroundColor: yellow.colors[300],
          color: text[yellow.contrast[300]],
        },
      },
    },
    light: {
      active: {
        normal: {
          backgroundColor: yellow.colors[300],
          color: text[yellow.contrast[300]],
        },
        focus: {
          backgroundColor: yellow.colors[100],
          color: text[yellow.contrast[100]],
        },
        hover: {
          backgroundColor: yellow.colors[100],
          color: text[yellow.contrast[100]],
        },
      },
      inactive: {
        normal: {
          backgroundColor: core.white,
          color: text.dark,
        },
        focus: {
          backgroundColor: yellow.colors[100],
          color: text[yellow.contrast[100]],
        },
        hover: {
          backgroundColor: yellow.colors[100],
          color: text[yellow.contrast[100]],
        },
      },
    },
  },
});

export default TagColors;
