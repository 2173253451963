import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} input
 * @param {Object} snapshot
 * @returns {Array}
 */
export default function duplicate(snapshot, { uuid }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const role = Collections.findById(entities.roles, uuid);
  const activities = Array.from(entities.activities);

  const new_uuid = shortuuid.generate();
  const duplicate = {
    uuid: new_uuid,
    title: role.title,
    fte: role.fte,
    tags: role.tags,
    __enabled: true,
    __template_uuid: new_uuid,
  };

  const duplicateActivities = activities
    .filter(({ owner_uuid }) => owner_uuid === role.uuid)
    .map((activity) => ({
      uuid: shortuuid.generate(),
      library_uuid: activity.library_uuid,
      owner_uuid: duplicate.uuid,
      owner_type: activity.owner_type,
      order: activity.order,
      hours: activity.hours,
      tags: activity.tags,
      created_at: now,
      updated_at: now,
    }));

  return {
    type: 'ROLE',
    uuid: new_uuid,
    snapshot: {
      entities: {
        roles: [duplicate],
        activities: duplicateActivities,
      },
    },
  };
}
