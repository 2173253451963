import { useState, useEffect } from 'react';
import { FlowStyle, FlowType } from '@/shared/enums';
import { formatFlow } from '@/shared/utils/flows';
import * as Auth from '@pkg/auth';

const useUserFlows = () => {
  const { me } = Auth.useStore();

  const [flows, setFlows] = useState(new Map());

  useEffect(() => {
    if (!me?.current_flows) {
      return;
    }

    // Creating a map to differentiate between the behaviour of flows.
    const flows = new Map([[FlowType.ROLE_WIZARD, new Map()]]);

    me.current_flows.map((flow) => {
      // If we haven't been provided with a payload there isn't much we can
      // determine from this flow.
      if (!flow.payload || !flow.campaign?.flowTemplate) {
        return;
      }

      const { payload } = flow;

      // For now we're assuming flows with be entities but this will be adapted
      // in the future.
      if (!payload?.entity?.uuid || !payload?.design_uuid) {
        return;
      }

      const flowProperties = formatFlow(flow);

      if (!flowProperties?.type) {
        return;
      }

      const flowStyle = flow?.campaign?.flowTemplate?.is_onboarding
        ? FlowStyle.ONBOARDING
        : FlowStyle.PULSE;

      if (flows.get(flowProperties.type)?.has(payload.entity.uuid)) {
        const existingFlowMap = flows
          .get(flowProperties.type)
          .get(payload.entity.uuid)
          .get(flowStyle);

        if (existingFlowMap) {
          existingFlowMap.push(flowProperties);
          return;
        }

        flows
          .get(flowProperties.type)
          .get(payload.entity.uuid)
          .set(flowStyle, [flowProperties]);
      } else {
        const flowMap = new Map([[flowStyle, [flowProperties]]]);

        flows.get(flowProperties.type)?.set(payload.entity.uuid, flowMap);
      }
    });

    setFlows(flows);
  }, [JSON.stringify(me?.current_flows)]);

  return {
    flows,
  };
};

export default useUserFlows;
