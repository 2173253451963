import { brand, core } from '@/lib/theme/tokens/palettes';

const HelpfulTipColors = Object.freeze({
  primary: {
    // color: BaseColors.font.tertiary,
    color: core.white,
    backgroundColor: brand.blue.main,
    // boxShadow: `0 2px 0 ${brand.yellow.tints[5]}`,
    // boxShadow: `0 0 0 2px ${brand.yellow.tints[5]}`,
    // iconColor: brand.yellow.main,
    iconColor: core.white,
  },
});

export default HelpfulTipColors;
