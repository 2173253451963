import FlexSearch from 'flexsearch';
import { produce } from 'immer';
import { create } from 'zustand';
import { Collections, Obj, Sort } from '@pkg/utils';

const InitialIndex = new FlexSearch.Index({
  tokenize: 'forward',
  context: true,
});

/**
 * @param {Index} index
 * @param {Map} mapped
 * @returns {Index}
 */
const updateIndex = (index, map) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!map.has(key)) {
        index.remove(key);
      }
    }
  }

  map.forEach(({ name }, key) => {
    if (!index.register?.[key]) {
      index.add(key, name);
    }
  });

  return index;
};

/**
 * @param {Object[]} list
 * @param {Index} index
 * @returns {Object}
 */
export const storeSkills = (list, index = InitialIndex) => {
  const map = new Map();
  const names = {
    default: new Map(),
    lower: new Map(),
  };

  const sorted = structuredClone(list).sort(Sort.alpha('name'));
  sorted.forEach((item) => {
    const id = item.uuid;
    const name = item.name;

    map.set(id, item);
    names.default.set(name, id);
    names.lower.set(name.toLowerCase(), id);
  });

  return {
    index: updateIndex(index, map),
    list: sorted,
    map: map,
    names: names,
  };
};

/**
 * @type {Function}
 * @param {Function} [getter]
 * @returns {any}
 */
const useStore = create((set, get) => ({
  isLoading: true,
  index: InitialIndex,
  list: [],
  map: new Map(),
  names: new Map(),
  people: new Map(),
  mutations: {},
  setLoading: (isLoading) => set(() => ({ isLoading })),
  setMutations: (mutations) => set(() => ({ mutations })),
  setPeople: (people) => set(() => ({ people })),
  setSkills: (list) => set(({ index }) => storeSkills(list, index)),

  /**
   * @param {String} name
   * @param {Boolean} strict
   * @returns {String|null}
   */
  getExisting: (name, strict = false) => {
    const map = get().names[strict ? 'default' : 'lower'];
    const value = strict ? name : name?.toLowerCase();
    return map.get(value) ?? null;
  },

  /**
   * Spread an update over an existing skill
   * @param {Object} skill
   * @param {String} skill.uuid
   * @param {String} skill.name
   * @returns {void}
   */
  put: (skill) =>
    set(
      produce((state) => {
        state.map.set(skill.uuid, skill);

        const index = Collections.indexById(state.list, skill.uuid);
        if (index >= 0) {
          state.list.splice(index, 1, skill);
        } else {
          state.list.unshift(skill);
        }

        state.list.sort(Sort.alpha('name'));
        state.index.update(skill.uuid, skill.name);
      })
    ),

  /**
   * @param {String} id
   * @returns {void}
   */
  remove: (id) => {
    if (!get().map.has(id)) {
      return;
    }

    return set(
      produce((state) => {
        const index = Collections.indexById(state.list, id);

        state.index.remove(id);
        state.list.splice(index, 1);
        state.map.delete(id);
        state.people.delete(id);
      })
    );
  },

  /**
   * Spread an update over an existing skill
   * @param {String} id
   * @param {Object} update
   * @returns {void}
   */
  update: (id, update) =>
    set(
      produce((state) => {
        if (!state.map.has(id)) {
          return;
        }

        const skill = { ...state.map.get(id), ...update };
        const index = Collections.indexById(state.list, id);

        state.index.update(id, skill.name);
        state.list[index] = skill;
        state.map.set(id, skill);
      })
    ),
}));

export default useStore;
