import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';

const CREATE_ACTIVITY = /* GraphQL */ `
  mutation CreateLibraryActivity($input: [CreateLibraryActivityInput!]!) {
    createLibraryActivities(input: $input) {
      uuid
      description
    }
  }
`;

/**
 * @return {Function}
 */
export default function useCreate() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({
    mutation: CREATE_ACTIVITY,
  });

  /**
   * @param {Object[]} inputs
   * @param {String} inputs.uuid
   * @param {String} inputs.description
   * @returns {Promise}
   */
  function createActivities(inputs) {
    const input = inputs.map(({ uuid, description }) => ({
      uuid,
      description,
    }));

    database.activities.bulkPut(input); // @todo rollback
    return mutateAsync(input);
  }

  return useCallback(createActivities, [mutateAsync]);
}
