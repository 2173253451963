import * as Auth from '@pkg/auth';
import useDatabase from '@pkg/database/useDatabase';
import * as Designs from '@pkg/entities/designs';
import * as Activities from '@pkg/entities/library/activities';
import * as FlowTemplates from '@pkg/entities/library/flowTemplates';
import * as Properties from '@pkg/entities/library/properties';
import * as Skills from '@pkg/entities/library/skills';
import * as Organisations from '@pkg/entities/organisations';
import * as People from '@pkg/entities/people';
import * as Tags from '@pkg/entities/tags';

const Listener = () => {
  const database = useDatabase();

  const me = Auth.useStore((state) => state.me);
  const organisation = Auth.useStore((state) => state.organisation);
  const { logout, completeLogout } = Auth.useLogout();

  const handleArchived = Designs.listeners.useArchived();
  const handleInvalidated = Designs.listeners.useInvalidated();

  const { refresh: refreshActivities } = Activities.useRefresh();
  const { refresh: refreshAuth } = Auth.useRefresh();
  const { refresh: refreshFlowTemplates } = FlowTemplates.useRefresh();
  const { refresh: refreshProperties } = Properties.useRefresh();
  const { refresh: refreshSkills } = Skills.useRefresh();
  const { refresh: refreshTags } = Tags.useRefresh();

  const organisationListeners = [
    {
      event: '.design.archived',
      context: 'organisation',
      callback: handleArchived,
    },
    {
      event: '.design.invalidated',
      context: 'organisation',
      callback: handleInvalidated,
    },
    {
      event: '.library.activities.updated',
      context: 'organisation',
      callback: refreshActivities,
    },
    {
      event: '.library.flowTemplates.updated',
      context: 'organisation',
      callback: refreshFlowTemplates,
    },
    {
      event: '.library.properties.updated',
      context: 'organisation',
      callback: refreshProperties,
    },
    {
      event: '.library.skills.updated',
      context: 'organisation',
      callback: refreshSkills,
    },
    {
      event: '.library.tags.updated',
      context: 'organisation',
      callback: refreshTags,
    },
    {
      event: '.organisation.logout',
      context: 'organisation',
      callback: logout,
    },
    {
      event: '.organisation.updated',
      context: 'organisation',
      callback: refreshAuth,
    },
  ];

  const userListeners = [
    {
      event: '.user.updated',
      context: 'people',
      callback: refreshAuth,
    },
    {
      event: '.user.logout',
      context: 'people',
      callback: completeLogout,
    },
    {
      event: '.user.access.updated',
      context: 'people',
      callback: async () => {
        await database.reset();
        // we need a fresh frontegg JWT with new roles
        window.location.reload();
      },
    },
  ];

  Organisations.useNotify(organisation?.uuid, organisationListeners);
  People.useNotify(me?.uuid, userListeners);
};

export default Listener;
