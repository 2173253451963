import { Fragment, useEffect, useState } from 'react';
import { Checkbox } from '@/atoms/inputs';
import { SelectOption } from '@/atoms/inputs/Select';
import { Heading, Label } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const CheckboxOptions = ({
  categorised,
  color = 'primary',
  label,
  options,
  optionLabel,
  optionId = 'id',
  OptionComponent,
  onChange,
  initialSelectedIds,
}) => {
  const [selected, setSelected] = useState(
    initialSelectedIds?.length ? new Set(initialSelectedIds) : new Set()
  );

  const handleChange = (event, option) => {
    event.preventDefault();
    const updatedValue = new Set(selected);

    if (updatedValue.has(option[optionId])) {
      updatedValue.delete(option[optionId]);
    } else {
      updatedValue.add(option[optionId]);
    }

    setSelected(updatedValue);
    onChange(event, [...updatedValue]);
  };

  // Ensures we update selections when receiving updates from upstream.
  useEffect(() => {
    const selectedIds = [...selected];
    if (JSON.stringify(initialSelectedIds) !== JSON.stringify(selectedIds)) {
      setSelected(
        initialSelectedIds?.length ? new Set(initialSelectedIds) : new Set()
      );
    }
  }, [initialSelectedIds]);

  return (
    <Box>
      {label && <Label>{label}</Label>}
      {categorised
        ? options.map((category) => (
            <Fragment key={category[optionId]}>
              {category.categorise && (
                <Stack p={1}>
                  <Heading variant="h6">{category.name}</Heading>
                </Stack>
              )}
              {category?.options.map((option) => (
                <SelectOption
                  key={option[optionId]}
                  color={color}
                  onClick={(event) => handleChange(event, option)}
                  value={option}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div style={{ maxWidth: 'calc(100% - 30px)' }}>
                      {OptionComponent ? (
                        OptionComponent(option)
                      ) : (
                        <>{option[optionLabel]}</>
                      )}
                    </div>
                    <Checkbox
                      initialValue={selected.has(option[optionId])}
                      onChange={(event) => handleChange(event, option)}
                      color={color}
                    />
                  </Stack>
                </SelectOption>
              ))}
            </Fragment>
          ))
        : options.map((option) => (
            <SelectOption
              key={option[optionId]}
              color={color}
              onClick={(event) => handleChange(event, option)}
              value={option}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                {OptionComponent ? (
                  OptionComponent(option)
                ) : (
                  <>{option[optionLabel]}</>
                )}
                <Checkbox
                  initialValue={selected.has(option[optionId])}
                  onChange={(event) => handleChange(event, option)}
                  color={color}
                />
              </Stack>
            </SelectOption>
          ))}
    </Box>
  );
};

export default CheckboxOptions;
