import { BeamibleTag, Position } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';

export const MatrixType = Object.freeze({
  PRIORITY: 'PRIORITY',
  ALIGNMENT: 'ALIGNMENT',
});

const Matrix = Object.freeze({
  [MatrixType.PRIORITY]: {
    colors: {
      [Position.TOP_RIGHT]: brand.yellow.main,
      [Position.BOTTOM_RIGHT]: brand.blue.main,
      [Position.BOTTOM_LEFT]: brand.blue.main,
      [Position.TOP_LEFT]: brand.blue.main,
    },
    labels: {
      [Position.TOP_RIGHT]: {
        title: 'High importance / High energy',
      },
      [Position.BOTTOM_RIGHT]: {
        title: 'Low importance / High energy',
      },
      [Position.BOTTOM_LEFT]: {
        title: 'Low importance / Low energy',
      },
      [Position.TOP_LEFT]: {
        title: 'High importance / Low energy',
      },
    },
    tags: [BeamibleTag.STRATEGIC_IMPORTANCE, BeamibleTag.ENERGISING],
  },
  [MatrixType.ALIGNMENT]: {
    colors: {
      [Position.TOP_RIGHT]: brand.yellow.main,
      [Position.BOTTOM_RIGHT]: brand.blue.main,
      [Position.BOTTOM_LEFT]: brand.yellow.main,
      [Position.TOP_LEFT]: brand.blue.main,
    },
    labels: {
      [Position.TOP]: {
        subtitle: '(Leader perspective)',
      },
      [Position.TOP_RIGHT]: {
        title: 'Aligned',
        subtitle: 'This work is important',
      },
      [Position.RIGHT]: {
        subtitle: '(Team perspective)',
      },
      [Position.BOTTOM_RIGHT]: {
        title: 'Misaligned',
        subtitle:
          'Team believes this work is important but the leader does not',
      },
      [Position.BOTTOM]: {
        subtitle: '(Leader perspective)',
      },
      [Position.BOTTOM_LEFT]: {
        title: 'Aligned',
        subtitle: 'This work is not important',
      },
      [Position.LEFT]: {
        subtitle: '(Team perspective)',
      },
      [Position.TOP_LEFT]: {
        title: 'Misaligned',
        subtitle:
          'Leader believes this work is important but the team does not',
      },
    },
    tags: [BeamibleTag.CRITICAL_WORK, BeamibleTag.STRATEGIC_IMPORTANCE],
  },
});

export default Matrix;
