import { DesignEntity } from '@/lib/enums';
import ownerProperty from './ownerProperty';

/**
 * @param {Object} entity
 * @param {String} type
 * @param {Object} entities (keyed)
 * @return {Object}
 */
export default function getOwningEntities(entity, type, entities) {
  const owningEntities = {
    [DesignEntity.ROLE]: null,
    [DesignEntity.GROUP]: null,
  };

  if (type === DesignEntity.ACTIVITY) {
    const ownerType = entity.owner_type;
    const owner =
      entities[DesignEntity.toPlural(ownerType)][entity[ownerProperty(entity)]];

    owningEntities[ownerType] = {
      uuid: owner.uuid,
    };

    if (ownerType === DesignEntity.ROLE) {
      owningEntities[ownerType].user_uuid = owner.user_uuid;
    }

    type = ownerType;
    entity = owner;
  }

  if (type === DesignEntity.ROLE) {
    const group =
      entities[DesignEntity.toPlural(DesignEntity.GROUP)][entity.group_uuid];

    owningEntities[DesignEntity.GROUP] = {
      uuid: group.uuid,
    };
  }

  return owningEntities;
}
