import { useMutation } from '@pkg/graphql';

const UPDATE_PASSWORD = /* GraphQL */ `
  mutation UpdatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      uuid
    }
  }
`;

export default function useUpdatePassword(query) {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_PASSWORD,
    invalidateQuery: query,
  });

  /**
   * @param {String} uuid
   * @param {String} input.old_password
   * @param {String} input.new_password
   * @param {String} input.new_password_confirmation
   * @returns {Object|null}
   */
  async function updatePassword(uuid, input) {
    const { data } = await mutateAsync({ uuid, ...input });
    return data?.updatePassword;
  }

  return updatePassword;
}
