import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object} role
 * @param {String} role.uuid
 * @param {Object|null} user
 * @returns {Object}
 */
export default function assign(snapshot, { uuid, user }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const role = Collections.keyById(entities.roles, uuid);
  let calculate = false;

  if (role.user_uuid === (user?.uuid ?? null)) {
    return null;
  }

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [],
      },
      people: {
        create: [],
      },
    },
  };

  const input = {
    uuid,
    user_uuid: null,
    updated_at: now,
    __person: null,
  };

  if (user?.uuid) {
    calculate = true;
    const person = {
      uuid: user.uuid,
      first_name: user.first_name,
      last_name: user.last_name,
      avatar: user.avatar,
    };

    input.user_uuid = user.uuid;
    input.__person = person;

    mutation.entities.people.create.push(person);
  }

  mutation.entities.roles.update.push(input);

  if (calculate) {
    mutation.entities.roles.calculate = [uuid];
  }

  return mutation;
}
