import { useEffect, useState } from 'react';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { avatarImage } from '@/shared/utils/profile';
import { Routes } from '@pkg/utils';
import { DesignEntity } from '@/lib/enums';

const usePerson = (entity) => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const [person, setPerson] = useState();
  const [path, setPath] = useState();
  const [avatar, setAvatar] = useState();

  useEffect(() => {
    if (!entity || !scenario || !snapshotEntityMap.size) {
      return;
    }

    const person =
      entity.__person ??
      snapshotEntityMap
        .get(DesignEntity.PERSON)
        .get(entity.user_uuid ?? entity.lead_uuid);

    const path = Routes.build.designParts({
      designId: scenario.details?.designId,
      level: {
        type: entity.__type,
        uuid: entity.uuid,
      },
    });

    setPerson(person);
    setPath(path);
    setAvatar(avatarImage(person));
  }, [entity?.uuid]);

  return {
    avatar,
    person,
    path,
  };
};

export default usePerson;
