import { ScopeType, ViewMode } from '@/shared/enums';

const ScopeOption = Object.freeze({
  [ScopeType.ACTIVITIES]: {
    id: ScopeType.ACTIVITIES,
    label: 'Activities',
  },
  [ScopeType.ALIGNMENT]: {
    id: ScopeType.ALIGNMENT,
    label: 'Alignment',
  },
  [ScopeType.PRIORITISATION]: {
    id: ScopeType.PRIORITISATION,
    label: 'Prioritisation',
  },
  [ScopeType.ROLES]: {
    id: ScopeType.ROLES,
    label: 'Roles',
  },
  [ScopeType.SPANS_LAYERS]: {
    id: ScopeType.SPANS_LAYERS,
    label: 'Spans & Layers',
  },
  [ScopeType.SKILLS]: {
    id: ScopeType.SKILLS,
    label: 'Skills',
  },
  [ScopeType.SUMMARY]: {
    id: ScopeType.SUMMARY,
    label: 'Summary',
  },
  [ScopeType.TAGS]: {
    id: ScopeType.TAGS,
    label: 'Tags',
  },
  [ScopeType.GROUPS]: {
    id: ScopeType.GROUPS,
    label: 'Teams',
  },
});

const ScopeOptionsMap = new Map([
  [
    ViewMode.INSIGHTS,
    [
      ScopeOption[ScopeType.SUMMARY],
      ScopeOption[ScopeType.ROLES],
      ScopeOption[ScopeType.ACTIVITIES],
      ScopeOption[ScopeType.TAGS],
      ScopeOption[ScopeType.PRIORITISATION],
      ScopeOption[ScopeType.ALIGNMENT],
      ScopeOption[ScopeType.SPANS_LAYERS],
    ],
  ],
  [
    ViewMode.TABLE,
    [
      ScopeOption[ScopeType.ACTIVITIES],
      ScopeOption[ScopeType.TAGS],
      ScopeOption[ScopeType.ROLES],
      ScopeOption[ScopeType.GROUPS],
      ScopeOption[ScopeType.SKILLS],
    ],
  ],
]);

export default ScopeOptionsMap;
