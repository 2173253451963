import { core } from '@/lib/theme/tokens/palettes';
import BaseColor from './Base';

const Card = Object.freeze({
  outline: {
    backgroundColor: BaseColor.background.primary,
    borderColor: BaseColor.border.primary,
    color: BaseColor.font.primary,
  },
  fill: {
    backgroundColor: BaseColor.background.tertiary,
    color: core.white,
  },
});

export default Card;
