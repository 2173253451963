/**
 * @param {String} type
 * @param {Object} database
 * @param {Object} scenario
 * @param {String} uuid
 */
export default function remove(type, scenario, uuid) {
  const index = scenario.entities[type].findIndex((item) => item.uuid === uuid);

  if (index >= 0) {
    scenario.entities[type].splice(index, 1);
  }
}
