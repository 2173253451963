import {
  Information,
  InformationParagraph,
  InformationTitle,
} from '@/molecules/information';

const PrioritisationInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        Use this matrix to prioritise.
      </InformationParagraph>
      <InformationParagraph>
        It is a distribution of your activities and time, which shows you what
        your most important priorities are... and aren't!
      </InformationParagraph>
      <InformationTitle>Bottom Left</InformationTitle>
      <InformationParagraph>
        Stop or reduce this work (if possible)
      </InformationParagraph>
      <InformationTitle>Bottom Right</InformationTitle>
      <InformationParagraph>
        Reduce this work (if possible)
      </InformationParagraph>
      <InformationTitle>Top Left</InformationTitle>
      <InformationParagraph>
        Reallocate this work (if possible)
      </InformationParagraph>
      <InformationTitle>Top Right</InformationTitle>
      <InformationParagraph>
        Do this work - Aim to spend most of your time here, this is the highest
        value work
      </InformationParagraph>
    </Information>
  );
};

export default PrioritisationInformation;
