import { tool } from 'ai';
import { z } from 'zod';

const activityUpdate = tool({
  description: 'Update an existing activity in the organizational design',
  parameters: z.object({
    uuid: z.string().describe('The UUID of the activity to update'),
    payload: z
      .object({
        // library_uuid: z
        //   .string()
        //   .optional()
        //   .describe('The library UUID of the activity'),
        // owner_uuid: z
        //   .string()
        //   .optional()
        //   .describe('The UUID of the role that owns this activity'),
        hours: z
          .number()
          .optional()
          .describe('The number of hours allocated to this activity'),
        tags: z
          .array(z.string())
          .optional()
          .describe('Array of tag UUIDs associated with this activity'),
      })
      .describe('The update payload for the activity'),
    explanation: z
      .string()
      .describe(
        'An explanation of what is about to be updated. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ uuid, payload, explanation }) => ({
    uuid,
    payload: {
      ...payload,
      updated_at: Date.now() / 1000, // Current epoch time in seconds
    },
    explanation,
  }),
});

export default activityUpdate;
