import { tool } from 'ai';
import { z } from 'zod';
import openai from '../client';

const YAML = require('json-to-pretty-yaml');

/*
@todo - inject prompts people use often
*/

const filtersPrompt = async ({
  userText,
  activityLibrary,
  tags,
  lastMessages,
  properties,
}) => {
  let filterPrompt = `
      You are an organizational filter creation assistant. You help users create filter configurations to find specific data in their organization.
      You understand how to create complex filter conditions using various comparison operators and entity types.
`;

  filterPrompt += `
    Here is a list of tags and their uuids for the organisation Beamible. When using tags in filters. Use the uuid. Tags can be used in filters to filter activities, groups, and roles

    ${YAML.stringify(
      tags.tags.list.slice(0, 30).map((tag) => ({
        uuid: tag.uuid,
        name: tag.name,
        description: tag.description,
      }))
    )}
    `;

  filterPrompt += `Here is a list of properties and their uuids for the organisation Beamible. When using properties in filters. Use the uuid. Properties can be used in filters to filter activities, groups, and roles.
            
            When setting a property in a filter, use the format 'properties:CGWuJjJtnqf5murGQCRqj' where CGWuJjJtnqf5murGQCRqj is the uuid of the property.

            Text dropdown values:
            ${properties.list
              .slice(30)
              .map((p) => `Name: ${p.name} - Uuid: ${p.uuid}`)
              .join('\n')}

            Property Dropdown Values
           ${properties.list
             .slice(30)
             .map(
               (p) => `
                Name: ${p.name} - Uuid: ${p.uuid}
                - Options: ${
                  p.options
                    ?.slice(30)
                    .map((v) => `Option: ${v.value} - Uuid: ${v.uuid}`)
                    .join('\n') || 'No options'
                }
           `
             )
             .join('\n')}
            `;

  // filterPrompt += `Here is a list of activities and their uuids for the organisation Beamible. When using activities in filters. Use the uuid. Activities can be used in filters to filter activities, groups, and roles.

  //   You can use this filter format when someone is asking you to select from existing activities use the relative operator ENTITY_IS_ANY and the identifier library_uuid. Make sure you use the identifier library_uuid.

  //   If the user asks about an activity, look at the activity library and use the uuid of the activity.

  //   ${activityLibrary.list.slice(50).map(
  //     (activity) => `
  //       Description: ${activity.description} - Uuid: ${activity.uuid}
  //       - Options: ${activity.options?.map((v) => `Option: ${v.value} - Uuid: ${v.uuid}`).join('\n') || 'No options'}`
  //   )}
  //       `;

  filterPrompt += `
     This is how the codebase structures filters.

        const ComparisonOperator = Object.freeze({
  AFTER: 'AFTER',
  BEFORE: 'BEFORE',
  CONTAINS: 'CONTAINS',
  CONTAINS_ANY_OF: 'CONTAINS_ANY_OF',
  EQUALS: 'EQUALS',
  EXCLUDE_ANY: 'EXCLUDE_ANY',
  EXCLUDE_ANY_OBJECT: 'EXCLUDE_ANY_OBJECT',
  EXCLUDE_ALL: 'EXCLUDE_ALL',
  EXCLUDE_ALL_OBJECT: 'EXCLUDE_ALL_OBJECT',
  GREATER_THAN: 'GREATER_THAN',
  GREATER_THAN_OR_EQUAL: 'GREATER_THAN_OR_EQUAL',
  HAS_ITEM_ANY: 'HAS_ITEM_ANY',
  HAS_ITEM_ALL: 'HAS_ITEM_ALL',
  HAS_OBJECT_ANY: 'HAS_OBJECT_ANY',
  HAS_OBJECT_ALL: 'HAS_OBJECT_ALL',
  INCLUDE_ANY: 'INCLUDE_ANY',
  INCLUDE_ALL: 'INCLUDE_ALL',
  IS: 'IS',
  IS_NOT_ONE_OF: 'IS_NOT_ONE_OF',
  IS_ONE_OF: 'IS_ONE_OF',
  LESS_THAN: 'LESS_THAN',
  LESS_THAN_OR_EQUAL: 'LESS_THAN_OR_EQUAL',
  NOT_EQUAL_TO: 'NOT_EQUAL_TO',
  STARTS_WITH: 'STARTS_WITH',
});

Here is an example of defined filters.

{
    'filters': {
        'conditions': []
    }
}


Here are some more examples of filter configurations

{
    'filters': {
        'conditions': [
              {
          'entity': {
            'key': 'TAG',
            'label': 'Tag'
          },
          'property': {
            'key': 'ACTIVITY',
            'label': 'Activity'
          },
          'type': 'MULTI_SELECT',
          'propertyId': null,
          'comparator': {
            'key': 'INCLUDE_ANY',
            'label': 'Includes any'
          },
          'value': [
            {
              'id': 'BtMCuemqtmp4KGzaArqr1',
              'label': 'Strategic importance'
            },
            {
              'id': 'BtMCuemqtmp4KGzaArqr2',
              'label': 'Specialist capability'
            }
          ]
        },
        {
          'entity': {
            'key': 'TAG',
            'label': 'Tag'
          },
          'property': {
            'key': 'ACTIVITY',
            'label': 'Activity'
          },
          'type': 'MULTI_SELECT',
          'propertyId': null,
          'comparator': {
            'key': 'INCLUDE_ALL',
            'label': 'Includes all'
          },
          'value': [
            {
              'id': 'BtMCuemqtmp4KGzaArqr1',
              'label': 'Strategic importance'
            },
            {
              'id': 'BtMCuemqtmp4KGzaArqr2',
              'label': 'Specialist capability'
            }
          ]
        },
        {
          'entity': {
            'key': 'TAG',
            'label': 'Tag'
          },
          'property': {
            'key': 'ACTIVITY',
            'label': 'Activity'
          },
          'type': 'MULTI_SELECT',
          'propertyId': null,
          'comparator': {
            'key': 'EXCLUDE_ANY',
            'label': 'Excludes any'
          },
          'value': [
            {
              'id': 'BtMCuemqtmp4KGzaArqr1',
              'label': 'Strategic importance'
            },
            {
              'id': 'BtMCuemqtmp4KGzaArqr2',
              'label': 'Specialist capability'
            }
          ]
        },
        {
                entity: {
                    key: 'ROLE',
                    label: 'Role',
                },
                property: { key: 'title', label: 'Taaitle' },
                type: 'TEXT',
                propertyId: null,
                comparator: { key: 'STARTS_WITH', label: 'Starts with' },
                value: 'a',
            },

            {
                entity: 'ROLE',
                property: 'title',
                type: 'TEXT',
                propertyId: null,
                comparator: 'STARTS_WITH',
                value: 'a',
            }
        ]
      
    }
}

The shape of entities and the identifier you can apply are;

- activities: __description, hours, tags, library_uuid, created_at, updated_at
- groups: name, tags, fte, budget, hours, created_at, updated_at
- roles: title, tags, fte, budget, hours, created_at, updated_at

Rules: 
- When doing a string comparison on names, descriptions, titles use relative/operator CONTAINS over EQUALS. e.g. title contains 'welding'
- Make sure you use the identifiers from the shape of identifiers above     
- When comparing titles use CONTAINS
- When comparing tags use HAS_ITEM_ANY
- When comparing hours use GREATER_THAN or LESS_THAN
- When talking about time/hours convert them to a straight number e.g. 30 minutes is 0.5. 1 day is 24 hours
- When using budget or hours it is always just a straight number e.g. 1, 1000, 100000
- When returning numbers, return them as numbers not strings
- When comparing dates use AFTER or BEFORE
- If filtering by budget, hours and FTE on roles and teams you need to set metricProperty: 
'__total_metrics'
- If filtering by the nature of an activity use the listed activities and their uuids and use the identifier library_uuid and the comparator ENTITY_IS_ANY. MAKE SURE YOU USE the identifier library_uuid.
- if they ask about an activity, look at the activity library and use the uuid of the activity
- IF TALKING ABOUT THE NATURE OF ACTIVITIES USE THE LIBRARY_UUID comparison operator ENTITY_IS_ANY

    You return the filters as above based off what user requested.

           Last messages;
          ${lastMessages.map((msg) => `${msg.sender}: ${msg.message}`).join('\n')}

          The last messages are included to help you understand the context of the users request. BUT MAKE SURE TO IGNORE THEM IF THEY ARE NOT RELEVANT. Your response prioritizes the users request if previous mesasages aren't relevant.

      User request: ${userText}
    `;
  console.log({ filterPrompt });
  const prompt = {
    model: openai('gpt-4-turbo'),
    tools: {
      create_filter: tool({
        description: 'Create a filter configuration based on user requirements',
        parameters: z.object({
          filters: z
            .object({
              conditions: z
                .array(
                  z.object({
                    entity: z.object({
                      key: z
                        .string()
                        .describe('Entity key (e.g., TAG, ROLE, GROUP)'),
                      label: z
                        .string()
                        .describe('Human readable label for the entity'),
                    }),
                    property: z.object({
                      key: z.string().describe('Property key to filter on'),
                      label: z
                        .string()
                        .describe('Human readable label for the property'),
                    }),
                    type: z
                      .string()
                      .describe(
                        'Type of the filter (e.g., TEXT, MULTI_SELECT)'
                      ),
                    propertyId: z.nullable(z.string()),
                    comparator: z.object({
                      key: z.string().describe('Comparison operator key'),
                      label: z
                        .string()
                        .describe('Human readable label for the comparator'),
                    }),
                    value: z
                      .union([
                        z.number(),
                        z.string(),
                        z.array(
                          z.object({
                            id: z.string(),
                            label: z.string(),
                          })
                        ),
                      ])
                      .describe(
                        'Filter value - can be string or array of objects for multi-select'
                      ),
                  })
                )
                .describe('Array of filter conditions'),
            })
            .describe('Filter configuration object'),
          explanation: z
            .string()
            .describe('Short explanation of the created filter configuration'),
        }),
        execute: async ({ filters, explanation }) => ({
          filters,
          explanation,
        }),
      }),
    },
    prompt: filterPrompt,
  };

  return prompt;
};

export default filtersPrompt;
