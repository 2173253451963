import { memo } from 'react';
import { styled } from '@mui/material/styles';
import DateTime from '@/components/DateTime';
import Tooltip from '@/components/Tooltip';

const Text = styled('p')({
  fontSize: 10,
  fontWeight: 500,
  lineHeight: 1.25,
  margin: '0 0 8px',
  textAlign: 'left',
  textTransform: 'uppercase',
});

const Timestamp = ({ created, updated, ...props }) => {
  const hasCreated = Boolean(created);
  const hasUpdated = Boolean(updated);

  const hasBoth = hasCreated && hasUpdated;
  const hasEither = hasCreated || hasUpdated;

  if (!hasEither) {
    return null;
  }

  const timestamp = hasUpdated ? updated : created;
  let label = '';

  switch (true) {
    case hasBoth:
      label = created === updated ? 'Created' : 'Updated';
      break;

    case hasCreated:
      label = 'Created';
      break;

    case hasUpdated:
      label = 'Updated';
      break;
  }

  return (
    <Tooltip
      title={<DateTime.Format date={timestamp} format="yyyy-MM-dd 'at' p" />}
    >
      <Text {...props}>
        {label} <DateTime.Relative date={timestamp} />
      </Text>
    </Tooltip>
  );
};

export default memo(Timestamp);
