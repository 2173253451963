import { TagMatrixChart } from '@/organisms/charts';
import { EntityType } from '@/shared/enums';
import {
  useViewModeContext,
  useScenarioContext,
  useTagContext,
} from '@/shared/providers';
import Grid from '@mui/material/Grid';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import TaggedActivitiesBarChart from './TaggedActivitiesBarChart';

const TagInsights = () => {
  const { id, scope, scopeEntity, showBudget } = useViewModeContext();
  const { tagMap } = useTagContext();
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;

  // We use the selected tag to filters the tag insights with ALL showing every
  // tag.
  const tag = id && id !== 'ALL' ? tagMap?.get(id) : null;

  return (
    tagMap.size && (
      <Grid container spacing={2} pb={4}>
        <Grid item xs={12}>
          <InsightsTitle
            entityMap={snapshotEntityMap}
            id={id}
            scope={scope}
            scopeEntity={scopeEntity}
          />
        </Grid>
        {scenario && (
          <>
            <Grid item xs={12}>
              <TaggedActivitiesBarChart tag={tag} levelType={entityType} />
            </Grid>
            <Grid item xs={12}>
              <TagMatrixChart
                levelType={entityType}
                showTagSelector
                tag={tag}
                showBudget={showBudget}
              />
            </Grid>
          </>
        )}
      </Grid>
    )
  );
};

export default TagInsights;
