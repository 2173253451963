/**
 * Formats a comparison path.
 * @param {String} entityId
 * @param {String} designId
 * @param {String} comparisonDesignId
 * @return {String}
 */
export default function buildComparisonPath(
  entityId,
  designId,
  comparisonDesignId
) {
  if (!entityId || !designId || !comparisonDesignId) {
    return;
  }

  const path = `/role/${entityId}/${designId}/latest/compare/${entityId}/${comparisonDesignId}/latest`;

  return path;
}
