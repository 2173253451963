import { useState, useEffect } from 'react';
import { Activities } from '@pkg/entities/library';
import shortuuid from '@pkg/uuid';

export const DEFAULT_ACTIVITY_DESCRIPTION = 'New activity';

const useDefaultActivity = () => {
  const [defaultActivity, setDefaultActivity] = useState();
  const activityList = Activities.useStore((state) => state.list);
  const createLibraryActivity = Activities.mutations.useCreate();

  // Attempts to find the default activity in the activity library and creates
  // it if it doesn't exist.
  useEffect(() => {
    if (!activityList?.length || defaultActivity) {
      return;
    }

    const existing = activityList.find(
      ({ description }) => description === DEFAULT_ACTIVITY_DESCRIPTION
    );

    if (existing) {
      setDefaultActivity(existing);
      return;
    }

    // Creating the new library activity will automatically trigger this
    // useEffect to run and set the default activity because the activity
    // store has been updated.
    createLibraryActivity(shortuuid.generate(), DEFAULT_ACTIVITY_DESCRIPTION);
  }, [activityList]);

  return defaultActivity;
};

export default useDefaultActivity;
