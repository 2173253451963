import { Field } from 'formik';
import { createElement } from 'react';
import { get } from '@pkg/utils/objects';

const Input = ({ input, field, form, ...props }) => {
  const touched = get(form.touched, field.name);
  const error = (touched && get(form.errors, field.name)) || null;

  const handleBlur = (_, value) => {
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, value, true);
  };

  const handleChange = (_, value) => {
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, value);
  };

  return createElement(input, {
    ...field,
    ...props,
    error,
    onBlur: handleBlur,
    onChange: handleChange,
  });
};

const FormikInput = ({ ...props }) => {
  return <Field {...props} component={Input} />;
};

export default FormikInput;
