import { Obj } from '@pkg/utils';

export default function derivePropertyMap(snapshot) {
  const map = new Map();

  if (Obj.isEmpty(snapshot?.entities)) {
    return Object.freeze(map);
  }

  Object.keys(snapshot.entities).forEach((type) => {
    snapshot.entities[type].forEach((entity) => {
      entity.properties?.forEach(({ key, value }) => {
        const values = map.get(key) ?? new Set();
        values.add(value);
        map.set(key, values);
      });
    });
  });

  return Object.freeze(map);
}
