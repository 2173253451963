import { Arr } from '@pkg/utils';

/**
 * @param {Object} state
 * @param {Object[]} state.categories
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.name]
 * @param {String} [input.description]
 * @param {String} [input.color]
 * @returns {Array}
 */
export default function update({ categories }, input) {
  const { uuid, name, description, color, taggables } = input;
  const category = categories.map.get(uuid);

  let isDifferent = false;
  const update = {
    uuid,
  };

  if (name && category.name !== name) {
    update.name = name;
    isDifferent = true;
  }

  if (color && category.color !== color) {
    update.color = color;
    isDifferent = true;
  }

  if (description && category.description !== description) {
    update.description = description;
    isDifferent = true;
  }

  if (taggables && Boolean(Arr.diff(taggables, category.taggables).length)) {
    update.taggables = taggables;
    isDifferent = true;
  }

  if (!isDifferent) {
    return null;
  }

  return {
    categories: {
      update: [update],
    },
  };
}
