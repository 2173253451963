import getScenarioEntity from '@pkg/entities/designs/getScenarioEntity';
import { DesignScope } from '@/lib/enums';
import designParts from './designParts';

/**
 * @param {Object} scenario
 * @param {Object} [snapshot]
 * @return {String}
 */
export default function design({ uuid, scope, is_scenario }, snapshot) {
  const parts = { designId: is_scenario ? uuid : 'main' };

  switch (scope) {
    case DesignScope.GROUP:
    case DesignScope.MANAGER:
    case DesignScope.ROLE:
      const entity = getScenarioEntity({ scope, latest: { snapshot } });
      parts.level = entity && { type: scope, uuid: entity.uuid };
  }

  return designParts(parts);
}
