import { Size, Variant } from '@/atoms/enums';
import { ListNumber } from '@/atoms/lists';
import { PercentageBar } from '@/atoms/metrics';
import { Heading, Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { brand } from '@/lib/theme/tokens/palettes';
import ProgressPercentageColors from './utils/ProgressPercentageColors';

const ProgressPercentage = ({
  activeIndex,
  color = 'primary',
  items,
  size = Size.MEDIUM,
}) => {
  const title = items[activeIndex]?.label;
  const count = items?.length;

  const colors = ProgressPercentageColors[color];

  return (
    <Box sx={{ color: brand.blue.main }}>
      <Stack direction="row" alignItems="center" spacing={1}>
        <ListNumber
          index={activeIndex + 1}
          size={size}
          active={true}
          color={color}
        />
        <Heading variant="h3">{title}</Heading>
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2} sx={{ mt: 1 }}>
        <PercentageBar
          denominator={count}
          height="6px"
          numerator={activeIndex + 1}
          variant={Variant.OUTLINE}
          colors={colors}
        />
        <Box sx={{ flexShrink: 0 }}>
          <Paragraph overrideStyles={{ mb: 0, fontWeight: 500 }}>
            {activeIndex + 1} of {items?.length}
          </Paragraph>
        </Box>
      </Stack>
    </Box>
  );
};

export default ProgressPercentage;
