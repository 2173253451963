/**
 * @param {Object[]} collection
 * @param {Object} identity
 * @param {String} key
 *
 * @return {Number}
 */
export function indexBy(collection, identity, key) {
  return collection.findIndex((i) => {
    if (Object.hasOwn(i, key)) {
      return i[key].toString() === identity?.toString();
    }
    return false;
  });
}

/**
 * @param {Object[]} collection
 * @param {String} collection[].uuid
 *
 * @param {String} uuid
 *
 * @return {Number}
 */
export function indexById(collection, uuid) {
  return indexBy(collection, uuid, 'uuid');
}
