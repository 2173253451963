import { createContext } from 'react';
import useConnection from './useConnection';

export const EchoContext = createContext();

const Provider = ({ children, ...config }) => {
  const connection = useConnection(config);

  return (
    <EchoContext.Provider value={connection}>{children}</EchoContext.Provider>
  );
};

export default Provider;
