import { useMutation } from '@pkg/graphql';

export const REMOVE_INTEGRATION = /* GraphQL */ `
  mutation RemoveIntegration($input: RemoveIntegrationInput!) {
    removeIntegration(input: $input)
  }
`;

export default function useRemoveIntegration() {
  const { mutateAsync } = useMutation({
    mutation: REMOVE_INTEGRATION,
  });

  /**
   * @param {String} uuid
   * @param {String} organisation_uuid
   */
  function removeIntegration(uuid, organisation_uuid) {
    return mutateAsync({ integration_uuid: uuid, organisation_uuid });
  }

  return removeIntegration;
}
