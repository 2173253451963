import { memo } from 'react';
import { styled } from '@mui/material/styles';
import { color } from '@/lib/theme/tokens';

const Divider = styled('hr')({
  borderWidth: '0 0 1px 0',
  borderColor: color.primary.grey[200],
  margin: '16px 0',
  width: '100%',
});

export default memo(Divider);
