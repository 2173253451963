import isEmpty from './isEmpty';
import isObject from './isObject';

export default function isEqual(a, b, deep = true) {
  if (isEmpty(a) && isEmpty(b)) {
    return true;
  }

  if (isEmpty(a) || isEmpty(b)) {
    return a === b;
  }

  for (const key in a) {
    if (!(key in b)) {
      return false;
    }

    const isObjects = isObject(a[key]) && isObject(b[key]);
    if (deep && isObjects && !isEqual(a[key], b[key], deep)) {
      return false;
    }

    if ((!deep || !isObjects) && a[key] !== b[key]) {
      return false;
    }
  }

  for (const key in b) {
    if (!(key in a)) {
      return false;
    }

    const isObjects = isObject(a[key]) && isObject(b[key]);
    if (deep && isObjects && !isEqual(a[key], b[key], deep)) {
      return false;
    }

    if ((!deep || !isObjects) && a[key] !== b[key]) {
      return false;
    }
  }

  return true;
}
