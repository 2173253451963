import { Colors } from '@/atoms/buttons';
import { IconButtonSize } from '@/atoms/buttons/utils';
import { Size } from '@/atoms/enums';
import MuiIconButton from '@mui/material/IconButton';

const IconButton = ({
  children,
  color = 'secondary',
  disabled,
  onClick,
  size = Size.MEDIUM,
}) => {
  const sizeProps = IconButtonSize[size];
  return (
    <MuiIconButton
      disabled={disabled}
      onClick={onClick}
      sx={{
        p: 0.5,
        ...Colors.button[color].text.normal,
        '& .MuiSvgIcon-root': {
          fontSize: sizeProps.fontSize,
        },
      }}
    >
      {children}
    </MuiIconButton>
  );
};

export default IconButton;
