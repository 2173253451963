const deriveEntityFlows = (id, list, entity) => {
  if (!entity.name) {
    entity.name = list?.[0]?.payload?.entity?.name;
  }

  entity.flows.push(
    ...list.map((flow) => {
      return {
        date: flow.createdAt,
        label: flow.name,
        id: flow.uuid,
      };
    })
  );

  return {
    entity,
    count: entity?.flows?.length,
  };
};

/**
 * Filters the flow map based on entity id and flow style provided.
 */
export default function filterFlowList({ flowMap, entityId, flowStyle }) {
  const flowList = [];
  let flowCount = 0;

  flowMap.forEach((map, id) => {
    if ((entityId && entityId !== id) || !map?.size) {
      return;
    }

    const entity = {
      id,
      name: '',
      flows: [],
    };

    map.forEach((list, style) => {
      if (flowStyle && flowStyle !== style) {
        return;
      }

      deriveEntityFlows(id, list, entity);
    });

    flowList.push(entity);
    flowCount += entity?.flows?.length ?? 0;
  });

  return {
    flowList,
    flowCount,
  };
}
