import { useMemo } from 'react';
import { DesignScope } from '@/lib/enums';
import combinedSnapshots from '../get/combined';

export default function useCombinedSnapshots(snapshot, mainSnapshot, scope) {
  return useMemo(() => {
    if (!snapshot || !mainSnapshot) {
      return snapshot;
    }

    return scope === DesignScope.ORGANISATION
      ? snapshot
      : combinedSnapshots(snapshot, mainSnapshot);
  }, [snapshot?.__hash, mainSnapshot?.__hash, scope]);
}
