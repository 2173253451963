import { useDatabase } from '@pkg/database';

export default function useArchived() {
  const database = useDatabase();

  const handleArchived = async ({ id }) => {
    await database.designs.where('uuid').equals(id).delete();
    await database.designRevisions.where('design.uuid').equals(id).delete();
  };

  return handleArchived;
}
