import { Base as BaseColors } from '@/atoms/colors';
import { core } from '@/lib/theme/tokens/color';
import { brand } from '@/lib/theme/tokens/palettes';

const BadgeColor = Object.freeze({
  primary: {
    backgroundColor: brand.yellow.main,
    color: BaseColors.font.tertiary,
  },
  secondary: {
    backgroundColor: BaseColors.font.tertiary,
    color: core.white,
  },
  tertiary: {
    backgroundColor: brand.lightBlue.main,
    color: BaseColors.font.tertiary,
  },
});

export default BadgeColor;
