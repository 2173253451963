import circleXPosition from './circleXPosition';
import circleYPosition from './circleYPosition';

/**
 * Returns an array of circle sizes and coordinates to be rendered.
 *
 * @param {Object}
 *
 * @return {Array}
 */
export default function segmentCircleCoordinates({
  circleCount,
  height,
  width,
  xPosition,
  yPosition,
}) {
  const spacing = 4;
  const margin = 4;
  // Circle scale.
  const circleDiameter = height / 3 - 5;
  const circleRadius = circleDiameter / 2;
  const circleSquare = circleDiameter + spacing * 2;

  const maxColumns = Math.floor(width / circleSquare);
  const maxRows = maxColumns > 2 ? 3 : maxColumns;
  // Determines the maximum circles we can render within the available space.
  const columnCount =
    circleCount > maxColumns * maxRows ? maxColumns * maxRows : circleCount;
  // Determine the rows we should render based on the number of columns.
  const rows = Math.ceil(columnCount / maxColumns);
  const rowCount = rows > 3 ? 3 : rows;

  // Set an offset which is used to center aligne the circles.
  const offset = width - circleSquare * maxColumns;

  // Keep track of the row and column indexes to calculate the circle coordinates.
  let row = 1;
  let column = 1;

  return Array.from({ length: columnCount }).map((_, index) => {
    const cx = circleXPosition({
      circleSquare,
      column,
      maxColumns,
      offset,
      rowCount,
      row,
      spacing,
      xPosition,
      width,
      margin,
    });
    const cy = circleYPosition({ height, row, rowCount, yPosition });

    if (column + 1 > maxColumns) {
      row += 1;
      column = 1;
    } else {
      column += 1;
    }

    return {
      id: index,
      r: circleRadius,
      cx,
      cy,
    };
  });
}
