import { TagChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Popover } from '@/molecules/modals';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';

const TagHeading = ({ tag }) => {
  const { color, description, name } = tag.properties;

  return (
    tag && (
      <Stack
        direction="row"
        alignItems="center"
        mb={description ? 1 : 1.5}
        mt={description ? 0 : 0.5}
      >
        <TagChip
          color={color}
          expanded={true}
          id={tag.uuid}
          name={name}
          size={Size.LARGE}
        />
        {description && (
          <Popover position={Position.RIGHT}>
            <div style={{ padding: '8px 16px' }}>{description}</div>
          </Popover>
        )}
      </Stack>
    )
  );
};

export default TagHeading;
