import { MetricProperty, Size } from '@/atoms/enums';
import { TextMetric } from '@/atoms/metrics';
import { Heading } from '@/atoms/typography';
import { TextMetricGroup } from '@/molecules/metrics';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { brand } from '@/lib/theme/tokens/palettes';

const BulkEditChanges = ({
  originalValue,
  updatedValue,
  property,
  barChartWidth,
}) => {
  const isIncrease = updatedValue > originalValue;

  const metric = MetricProperty[property.value];

  const updatedValueChartWidth = isIncrease
    ? barChartWidth
    : (updatedValue / originalValue) * barChartWidth;

  const originalValueChartWidth = isIncrease
    ? (originalValue / updatedValue) * barChartWidth
    : barChartWidth;

  const changeLabel = isIncrease ? 'Increased' : 'Reduced';
  const changeValue = updatedValue - originalValue;

  const percentage = (updatedValue - originalValue) / originalValue;

  return (
    <>
      <Box
        mb={4}
        p={2}
        sx={{
          backgroundColor: brand.lightBlue.tints[2],
          borderRadius: '4px',
        }}
      >
        <Heading
          variant="h2"
          overrideStyles={{
            color: brand.blue.main,
            fontWeight: 600,
            textTransform: 'uppercase',
          }}
        >
          Changes
        </Heading>
      </Box>
      <Box
        px={2}
        pb={5}
        mb={6}
        sx={{ boxShadow: `0 2px 0 ${brand.lightBlue.tints[6]}` }}
      >
        <TextMetricGroup
          alignMetrics="center"
          justifyMetrics="center"
          dividerColor="secondary"
          leftPadding={1}
          rightPadding={1}
          metrics={[
            {
              label: `${changeLabel} total ${metric.label.plural}`,
              value: changeValue,
              formatOptions: metric.formatOptions,
            },
            {
              label: `Percent ${changeLabel} ${metric.label.plural}`,
              value: percentage,
              formatOptions: {
                style: 'percent',
              },
            },
          ]}
          size={Size.LARGE}
        />
      </Box>
      <Stack direction="row" spacing={1.5} mb={2} pl={2} alignItems="center">
        <Box width={140} sx={{ textAlign: 'right' }}>
          <TextMetric
            alignItems="flex-end"
            formatOptions={metric.formatOptions}
            label={`Updated ${property.label}`}
            removePadding
            size={Size.LARGE}
            value={updatedValue}
          />
        </Box>
        <Box
          mb={2}
          height={56}
          minWidth={8}
          width={updatedValueChartWidth}
          sx={{
            backgroundColor: brand.blue.main,
            borderRadius: '2px',
          }}
        />
      </Stack>
      <Stack direction="row" spacing={1.5} pl={2} alignItems="center">
        <Box width={140} sx={{ textAlign: 'right' }}>
          <TextMetric
            alignItems="flex-end"
            formatOptions={metric.formatOptions}
            label={`Original ${property.label}`}
            removePadding
            size={Size.LARGE}
            value={originalValue}
          />
        </Box>
        <Box
          height={56}
          width={originalValueChartWidth}
          mb={2}
          sx={{
            backgroundColor: brand.blue.tints[3],
            borderRadius: '2px',
          }}
        />
      </Stack>
    </>
  );
};

export default BulkEditChanges;
