import config from '@/lib/config';

export default function visibility(
  entities,
  visibility = config.VISIBILITY.FULL
) {
  if (!entities) {
    return {};
  }
  return entities.reduce((results, { uuid, __visibility }) => {
    results[uuid] = __visibility === visibility;
    return results;
  }, {});
}
