import { EntityType } from '@/shared/enums';
import updateRole from './updateRole';
import updateRoleType from './updateRoleType';

export default function updateMap({
  roleMap,
  action,
  activity,
  role,
  comparisonScenario,
}) {
  // Update the role type metrics
  roleMap.set(
    role.title,
    updateRoleType({
      action,
      role,
      roleMap,
      comparisonScenario,
    })
  );

  // Get the role type.
  const roleType = roleMap.get(role.title);

  // Update the role.
  roleType[EntityType.ROLE].set(
    role.uuid,
    updateRole({
      action,
      activity,
      role,
      roleType,
      comparisonScenario,
    })
  );

  return roleMap;
}
