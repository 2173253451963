import { useRef, useState } from 'react';
import { MatrixBox } from '@/organisms/charts';
import { isAlignmentMatrix } from '@/organisms/insights';
import { useScenarioContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';

const topPositions = [Position.TOP_LEFT, Position.TOP_RIGHT];
const bottomPositions = [Position.BOTTOM_LEFT, Position.BOTTOM_RIGHT];

const SummaryMatrix = ({
  entityType,
  chartData,
  height = 400,
  metricMax,
  metricTotals,
  metric,
  tags,
}) => {
  const ref = useRef();
  const [titleHeight, setTitleHeight] = useState(0);
  const { scenario } = useScenarioContext();
  const alignmentMatrix = isAlignmentMatrix(tags);

  const handleTitleHeight = (height) => {
    if (height > titleHeight) {
      setTitleHeight(height);
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box ref={ref}>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={1}
            mb={1}
          >
            {topPositions.map((position) => (
              <div key={position} style={{ width: '50%' }}>
                <MatrixBox
                  entityType={entityType}
                  height={height * 0.5}
                  isMuted={position === Position.TOP_LEFT}
                  isHighlighted={position === Position.TOP_RIGHT}
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  metric={metric}
                  onTitleHeightUpdate={handleTitleHeight}
                  position={position}
                  props={chartData.matrixMap.get(position)}
                  showTitles={false}
                  tags={tags}
                  titleHeight={titleHeight}
                  totals={chartData.metrics}
                />
              </div>
            ))}
          </Stack>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
            spacing={1}
            mb={1}
          >
            {bottomPositions.map((position) => (
              <div key={position} style={{ width: '50%' }}>
                <MatrixBox
                  entityType={entityType}
                  height={height * 0.5}
                  isMuted={
                    !alignmentMatrix || position === Position.BOTTOM_RIGHT
                  }
                  isHighlighted={
                    position === Position.BOTTOM_LEFT && alignmentMatrix
                  }
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  metric={metric}
                  onTitleHeightUpdate={handleTitleHeight}
                  position={position}
                  props={chartData.matrixMap.get(position)}
                  showTitles={false}
                  tags={tags}
                  titleHeight={titleHeight}
                  totals={chartData.metrics}
                />
              </div>
            ))}
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
};

export default SummaryMatrix;
