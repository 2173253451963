import { useEffect, useState } from 'react';
import { useDesignsContext, useScenarioContext } from '@/shared/providers';
import { DesignEntity, DesignType } from '@/lib/enums';

const sortOrder = {
  [DesignEntity.ROLE]: 0,
  [DesignEntity.GROUP]: 1,
  [DesignEntity.ORGANISATION]: 2,
};

/**
 * Creates a list of available designs based on the entity provided.
 *
 * @param {Object} entity
 * @param {Array} designs
 *
 * @return {Array}
 */
const designsByEntity = (entity, designs) => {
  const options = [];

  if (!entity || !designs?.size) {
    return options;
  }

  const entityId = entity.uuid ?? entity.__uuid;

  designs.forEach(({ design, snapshotEntityMap }) => {
    if (!design || !snapshotEntityMap?.size) {
      return;
    }

    const isScenario = design.type !== DesignType.LIVE;
    const designId = isScenario ? design.uuid : 'main';

    const hasEntity = snapshotEntityMap.get(entity.__type)?.has?.(entityId);
    const isOrganisationEntity =
      entity.__type === DesignEntity.ORGANISATION &&
      design.scope === DesignEntity.ORGANISATION;

    if (!hasEntity && !isOrganisationEntity) {
      return;
    }

    options.push({
      id: designId,
      created_at: new Date(design?.created_at),
      isScenario,
      label: design.name ?? design.latest?.name,
      level: {
        type: design.scope,
        uuid: entityId,
      },
      scenarioType: design?.scope,
    });
  });

  // Sorts the options by their entity type and creation date.
  const sortedOptions = options.sort((previous, next) => {
    if (!next.isScenario) {
      return 2;
    }

    const previousLevel = sortOrder[previous?.scenarioType];
    const nextLevel = sortOrder[next?.scenarioType];

    const categorySort = previousLevel - nextLevel;

    if (categorySort === 0) {
      return next.created_at - previous.created_at;
    }

    return previousLevel - nextLevel;
  });

  return sortedOptions;
};

/**
 * Generates a list of available designs relative to the scenario entity.
 */
const useDesignOptions = () => {
  const { scenario } = useScenarioContext();
  const designs = useDesignsContext();
  const [options, setOptions] = useState();
  const entityId = scenario?.entity?.uuid ?? scenario?.entity?.__uuid;

  // Updates the design options based on the scenario entity and availanble
  // designs.
  useEffect(() => {
    if (!entityId || !designs?.designs?.size) {
      return;
    }

    const options = designsByEntity(scenario.entity, designs?.designs);

    setOptions(options);
  }, [entityId, designs?.designs?.size]);

  return {
    options,
  };
};

export default useDesignOptions;
