import { useEffect, useState } from 'react';
import { useStore as useTagStore } from '@pkg/entities/tags';

/**
 * Returns the tags from their respective tag ids.
 * @param {Array} tagIds
 * @return {Array}
 */
const useTagList = (tagIds) => {
  const [tagList, setTagList] = useState([]);
  const { tags } = useTagStore();

  const retrieveTagList = (ids) => {
    return ids?.map((id) => {
      return tags?.map?.get(id);
    });
  };

  useEffect(() => {
    if (!tags || tagIds?.length === 0) {
      setTagList([]);
      return;
    }

    setTagList(retrieveTagList(tagIds));
  }, [JSON.stringify(tagIds)]);

  return {
    retrieveTagList,
    tagList,
  };
};

export default useTagList;
