import { Avatar, AvatarSize } from '@/atoms/avatars';
import { Size } from '@/atoms/enums';
import { avatarImage } from '@/shared/utils/profile';
import MuiAvatarGroup from '@mui/material/AvatarGroup';
import Stack from '@mui/material/Stack';

const AvatarGroup = ({
  onClick,
  onGroupClick,
  max = 5,
  people,
  size = Size.MEDIUM,
  startComponent,
}) => {
  const sizeProps = AvatarSize[size];

  return (
    <Stack
      alignItems="center"
      direction="row"
      onClick={onGroupClick}
      spacing={1}
      sx={{
        cursor: onGroupClick ? 'pointer' : 'inherit',
      }}
    >
      {startComponent}
      <MuiAvatarGroup
        max={max}
        sx={{
          '& .MuiAvatar-root': {
            borderWidth: '1px',
            ml: -0.5,
          },
          '& .MuiAvatarGroup-avatar': {
            ...sizeProps,
            backgroundColor: 'transparent',
            borderWidth: '1px',
            ml: -0.5,
          },
        }}
      >
        {people?.map((person) => (
          <Avatar
            key={person.uuid}
            name={`${person.first_name} ${person.last_name}`}
            onClick={onClick}
            size={size}
            src={avatarImage(person)}
          />
        ))}
      </MuiAvatarGroup>
    </Stack>
  );
};

export default AvatarGroup;
