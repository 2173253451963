import { Collections } from '@pkg/utils';

/**
 * @param {String} type
 * @param {Object} snapshot
 * @param {String} input
 */
export default function update(type, snapshot, { uuid, ...input }) {
  const index = snapshot.entities[type].findIndex((item) => item.uuid === uuid);

  const { properties, ...rest } = input;
  const existing = snapshot.entities[type][index];
  const mutated = { ...existing, ...rest };

  if (properties) {
    const mutating = Collections.keyBy(mutated.properties, 'key');

    Object.keys(properties).forEach((action) => {
      properties[action].forEach((property) => {
        switch (action) {
          case 'create':
          case 'update':
            mutating[property.key] = property;
            return;

          case 'remove':
            delete mutating[property];
            break;
        }
      });
    });

    mutated.properties = Object.values(mutating);
  }

  snapshot.entities[type][index] = mutated;
}
