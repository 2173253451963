import { Size } from '@/atoms/enums';
import useInput from '@/atoms/inputs/hooks/useInput';
import MuiCheckbox from '@mui/material/Checkbox';
import { CheckboxSizeProps, CheckboxColorProps } from './utils';

const Checkbox = ({
  color,
  colorProps,
  initialValue,
  inputProps,
  label,
  onChange,
  size = Size.MEDIUM,
}) => {
  const sizeProps = CheckboxSizeProps[size];
  const colors = colorProps ?? CheckboxColorProps[color];

  const { inputChange, value } = useInput({
    onChange,
    initialValue: Boolean(initialValue),
  });

  return (
    <MuiCheckbox
      checked={value}
      onChange={inputChange}
      inputProps={{
        ...inputProps,
        'aria-label': label,
      }}
      sx={{
        padding: 0,
        backgroundColor: value ? colors.normal.color : 'transparent',
        borderRadius: '2px',
        '&:hover': {
          color: colors.normal.backgroundColor,
          backgroundColor: value ? colors.normal.color : 'transparent',
        },
        '& .MuiSvgIcon-root': {
          ...sizeProps,
          color: colors.normal.backgroundColor,
          transform: 'scale(1.4)',
        },
      }}
    />
  );
};

export default Checkbox;
