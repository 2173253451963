import { styled } from '@mui/material/styles';
import DiagramSkeleton from '@/components/Design/Skeleton';

const DesignContainer = styled('div')({
  height: '100%',
  overflow: 'hidden',
  position: 'absolute',
  width: '100%',
});

const Design = styled('div')({
  display: 'flex',
  height: '100%',
  width: '100%',
});

const Diagram = styled('div')({
  flex: 1,
  position: 'relative',
});

const ContextBar = styled('div')({
  height: '100%',
  position: 'relative',
  width: '352px',
});

const DesignSkeleton = () => (
  <DesignContainer>
    <Design>
      <Diagram>
        <DiagramSkeleton />
      </Diagram>
      <ContextBar />
    </Design>
  </DesignContainer>
);

export default DesignSkeleton;
