import { useMemo } from 'react';
import * as Library from '../library';
import deriveProps from './deriveProps';

/**
 * @param {Object[]} snapshot
 * @return {Object}
 */
export default function useDerivedProps(snapshot) {
  const library = Library.useLibrary();
  return useMemo(() => deriveProps(library, snapshot), [snapshot]);
}
