/**
 * @param {Array} array
 * @param {Number} offset
 * @param {Number} size
 */
export default function page(array, offset = 0, size = 100) {
  const total = array.length;
  const pages = Math.ceil(total / size);
  const last = pages - 1;

  if (offset > last) {
    return [];
  }

  if (offset === last) {
    const remaining = total % size;
    const items = remaining > 0 ? -remaining : -size;
    return array.slice(items);
  }

  const index = offset * size;
  return array.slice(index, index + size);
}
