import { cloneElement, useRef, useEffect, useState } from 'react';
import { Divider } from '@/atoms/dividers';
import {
  ActivityDimensions,
  MatrixSelect,
  MatrixPresets,
  MetricTypeSelect,
} from '@/molecules/chartElements';
import { ChartCard } from '@/organisms/cards';
import { EmptyInsights } from '@/organisms/insights';
import { EntityType, MatrixPreset } from '@/shared/enums';
import { useInsightsContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { BeamibleTag } from '@/lib/enums';
import { Position } from '@/lib/enums';
import { EntityMetric } from '@/lib/enums';
import BinaryMatrix from './BinaryMatrix';
import ChartInformation from './ChartInformation';
import QuadrantMatrix from './QuadrantMatrix';
import { useMatrixChartData } from './hooks';

const defaultMatrixObject = ({ tag, matrixPreset }) => {
  const matrixObject = {
    type: EntityType.TAG,
    items: [],
  };

  if (matrixPreset) {
    const matrix = MatrixPresets[matrixPreset];
    return {
      ...matrixObject,
      items: matrix.ids.map((id) => {
        return { id };
      }),
      titles: matrix.titles,
      subtitles: matrix.subtitles,
    };
  }

  if (!tag) {
    matrixObject.items.push({ id: BeamibleTag.STRATEGIC_IMPORTANCE });
    return matrixObject;
  }

  matrixObject.items.push({ id: tag.uuid });
  return matrixObject;
};

const chartTitle = (matrixPreset) => {
  if (matrixPreset === MatrixPreset.ALIGNMENT) {
    return 'Identify aligned work';
  }
  if (matrixPreset === MatrixPreset.PRIORITISATION) {
    return 'Identify high value work';
  }

  return 'Compare different categories of work';
};

const TagMatrixChart = ({
  showBudget,
  showTagSelector,
  tag,
  matrixPreset,
  levelType,
}) => {
  const matrixChartRef = useRef();
  const { filter } = useInsightsContext();
  const [metric, setMetric] = useState('hours');
  const [tableHeight, setTableHeight] = useState(0);
  const [focusItem, setFocusItem] = useState();
  const [dimension, setDimension] = useState(
    ActivityDimensions[levelType]?.[0]
  );
  const entityType = dimension.order[0];
  const [matrixObject, setMatrixObject] = useState(
    defaultMatrixObject({ tag, matrixPreset })
  );
  const [sort, setSort] = useState({
    metric: 'hours',
    type: 'DESC',
  });

  const metricOptions = [
    EntityMetric.HOURS,
    EntityMetric.BUDGET,
    EntityMetric.FTE,
  ];

  const { chartData, metricMax, metricTotals, tags } = useMatrixChartData({
    dimension,
    entityType,
    matrix: matrixObject,
    metric,
    filter,
    sort,
  });

  const title = chartTitle(matrixPreset);
  const hasActivities = metricTotals?.activities > 0;

  const handleTagChange = (ids, presetTitles) => {
    setMatrixObject({
      ...matrixObject,
      items: ids.map((id) => ({ id })),
      titles: presetTitles?.titles,
      subtitles: presetTitles?.subtitles,
    });
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  // This effect resets the chart if it's showing metric data and budgets have
  // been switched off.
  useEffect(() => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      setMetric(EntityMetric.PERCENTAGE);
    }
  }, [showBudget]);

  return (
    <ChartCard
      title={title}
      ControlsComponent={
        hasActivities && (
          <Stack direction="row" alignItems="center" spacing={2}>
            {showTagSelector && (
              <>
                <MatrixSelect lockedOption={tag} onChange={handleTagChange} />
                <Divider orientation="vertical" />
              </>
            )}
            <MetricTypeSelect
              onChange={handleMetricTypeChange}
              options={metricOptions}
            />
          </Stack>
        )
      }
      InfoComponent={cloneElement(<ChartInformation />, { matrixPreset })}
    >
      <>
        {hasActivities ? (
          <>
            {chartData?.matrixMap?.has(Position.LEFT) && (
              <Box ref={matrixChartRef}>
                <BinaryMatrix
                  entityType={entityType}
                  chartData={chartData}
                  height={400}
                  metric={metric}
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  tags={tags}
                />
              </Box>
            )}
            {chartData?.matrixMap?.has(Position.TOP_LEFT) && (
              <Box ref={matrixChartRef}>
                <QuadrantMatrix
                  entityType={entityType}
                  chartData={chartData}
                  height={400}
                  matrixPreset={matrixPreset}
                  metric={metric}
                  metricMax={metricMax}
                  metricTotals={metricTotals}
                  tags={tags}
                />
              </Box>
            )}
          </>
        ) : (
          <EmptyInsights message="No activities were found." />
        )}
      </>
    </ChartCard>
  );
};

export default TagMatrixChart;
