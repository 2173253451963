import { Collections } from '@pkg/utils';
import { ActivityOwner } from '@/lib/enums';

/**
 * @param {Object} snpashot
 * @param {Object} input
 * @param {String[]} input.ids
 * @param {Object} input.change
 * @param {Array} [input.change.add]
 * @param {Array} [input.change.remove]
 * @returns {Object}
 */
export default function tagging(snapshot, { ids, change }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        update: [],
      },
    },
  };

  const keyed = Collections.keyById(entities.activities);
  const touchedGroups = new Set();
  const touchedRoles = new Set();

  ids.forEach((id) => {
    if (!Object.hasOwn(keyed, id)) {
      return;
    }

    const activity = keyed[id];
    const tags = new Set(activity.tags);
    change.add?.forEach((id) => tags.add(id));
    change.remove?.forEach((id) => tags.delete(id));

    if (JSON.stringify(Array.from(tags)) === JSON.stringify(activity.tags)) {
      return;
    }

    if (activity.owner_type === ActivityOwner.GROUP) {
      touchedGroups.add(activity.owner_uuid);
    } else {
      touchedRoles.add(activity.owner_uuid);
    }

    mutation.entities.activities.update.push({
      uuid: id,
      tags: Array.from(tags),
      updated_at: now,
    });
  });

  if (mutation.entities.activities.update.length === 0) {
    return null;
  }

  touchedRoles.forEach((uuid) => {
    mutation.entities.roles.update.push({ uuid, updated_at: now });
  });

  touchedGroups.forEach((uuid) => {
    mutation.entities.groups.update.push({ uuid, updated_at: now });
  });

  return mutation;
}
