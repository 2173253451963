import { Size } from '@/atoms/enums';

const ListNumberSizeProps = Object.freeze({
  [Size.SMALL]: {
    fontSize: '0.825rem',
    fontWeight: 500,
    width: '1.5rem',
    height: '1.5rem',
    borderRadius: '1.5rem',
    lineHeight: '1.425rem',
  },
  [Size.MEDIUM]: {
    fontSize: '0.875rem',
    fontWeight: 500,
    width: '1.625rem',
    height: '1.625rem',
    borderRadius: '1.625rem',
    lineHeight: '1.625rem',
  },
  [Size.LARGE]: {
    fontSize: '0.925rem',
    width: '2rem',
    height: '2rem',
    borderRadius: '2rem',
    lineHeight: '1.9rem',
  },
});

export default ListNumberSizeProps;
