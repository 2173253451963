import { useRef } from 'react';

/**
 * @param {Function} callback
 * @param {Number} delay
 * @returns
 */
export default function useThrottle(callback, delay) {
  const last = useRef(Date.now());

  const throttledCallback = (...args) => {
    if (last.current < Date.now() - delay) {
      last.current = Date.now();
      callback(...args);
    }
  };

  return throttledCallback;
}
