import { DesignEntity } from '@/lib/enums';

export default function isEntityRestricted({
  activity,
  group,
  entityType,
  entityMap,
  ids,
  options,
  role,
}) {
  if (!ids || ids.has('ALL')) {
    return;
  }

  if (entityType === DesignEntity.GROUP) {
    if (!group) {
      return true;
    }

    if (options?.hideNestedEntities) {
      return !ids.has(group?.uuid);
    }

    if (ids.has(group.uuid)) {
      return;
    }

    const groups = group.__above?.[DesignEntity.GROUP];
    if (!groups || ![...groups].some((id) => [...ids].includes(id))) {
      return true;
    }
  }

  if (entityType === DesignEntity.MANAGER && !ids.has(role?.uuid)) {
    if (!role) {
      return true;
    }

    if (options?.hideNestedEntities) {
      return !ids.has(role.__manager);
    }

    const managers = role?.__above?.[DesignEntity.MANAGER];
    if (managers && ![...managers].some((id) => [...ids].includes(id))) {
      return true;
    }
  }

  if (entityType === DesignEntity.ROLE) {
    if (!role) {
      return true;
    }
    return !ids.has(role?.uuid);
  }

  if (entityType === DesignEntity.ACTIVITY) {
    if (!activity) {
      return true;
    }
    return !ids.has(activity?.uuid);
  }
}
