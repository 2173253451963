import * as d3 from 'd3';
import { Position } from '@/lib/enums';
import renderCenterAxis from '../render/renderCenterAxis';
import renderCircle from '../render/renderCircle';
import renderGroup from '../render/renderGroup';
import renderQuadrantBox from '../render/renderQuadrantBox';
import renderQuadrantLabel from '../render/renderQuadrantLabel';
import renderRoot from '../render/renderRoot';
import renderSimulation from '../render/renderSimulation';
import renderSvg from '../render/renderSvg';
import renderTooltip from '../render/renderTooltip';
import ChartAlignment from './ChartAlignment';

const PADDING = 50;

/**
 *
 * @param {Object}
 * @returns {Array}
 */
const defineQuadrantContexts = ({
  canvasHeight,
  canvasWidth,
  canvasXOffset,
  canvasYOffset,
  classes,
  size,
  quadrants,
}) => {
  const defaultContext = {
    canvasWidth,
    canvasHeight,
    canvasXOffset,
    canvasYOffset,
    classes,
    height: size,
    width: size,
    padding: PADDING,
  };

  const contexts = [];

  const scale = d3
    .scaleRadial()
    .domain([0, 100])
    .range([0, 100 - PADDING / 2]);

  quadrants.forEach((quadrant, key) => {
    const x0 =
      key === Position.TOP_RIGHT || key === Position.BOTTOM_RIGHT
        ? canvasXOffset + defaultContext.width + PADDING * 0.75
        : canvasXOffset + PADDING / 4;
    const y0 =
      key === Position.BOTTOM_RIGHT || key === Position.BOTTOM_LEFT
        ? canvasYOffset + defaultContext.height + PADDING * 0.75
        : canvasYOffset + PADDING / 4;

    const data = quadrant.data.map((item) => {
      return {
        ...item,
        label: `${item.label}: ${item.value}%`,
        value: Math.abs(scale(item.value)),
      };
    });
    contexts.push({
      ...defaultContext,
      alignment: ChartAlignment[key],
      color: quadrant.color,
      position: key,
      label: {
        ...quadrant?.title,
        value: `${quadrant.percentage}%`,
      },
      data,
      x0,
      y0,
    });
  });

  return contexts;
};

/**
 * @reference https://d3-graph-gallery.com/graph/circularpacking_template.html
 * @param {d3.Selection} chart
 * @param {Object[]} data
 * @returns {void}
 */
export default function renderChart({
  axisLabels,
  chart,
  classes,
  data,
  id,
  selectQuadrant,
}) {
  // Canvas height and width gives us the total svg space to work with and
  // render text elements that exist outside the rendered packed circle chart.
  const canvasHeight = chart.node().offsetHeight;
  const canvasWidth = chart.node().offsetWidth;

  // Exit early if we don't have a canvas element.
  if (!canvasHeight || !canvasWidth) {
    return;
  }

  // Ensure we're working with a square area.
  const width = canvasHeight;
  // The canvas offset allows us to center the quadrant and calculate where
  // the chart space is versus the text rendered outside the chart.
  const canvasXOffset = (canvasWidth - width) / 2 + PADDING / 2;
  const canvasYOffset = PADDING / 2;
  // Size is the actual chart space that contains the packed circles.
  const size = canvasHeight / 2 - PADDING;

  renderRoot(chart, { classes });
  const tooltip = renderTooltip(chart, { classes });
  const svg = renderSvg(chart, {
    classes,
    id,
    height: canvasHeight,
    width: canvasWidth,
  });

  const contexts = defineQuadrantContexts({
    classes,
    size,
    quadrants: data,
    canvasHeight,
    canvasWidth,
    canvasXOffset,
    canvasYOffset,
  });

  renderCenterAxis(svg, {
    classes,
    height: canvasHeight,
    labels: axisLabels,
    position: Position.CENTER,
    width,
    x0: canvasXOffset - PADDING / 2,
  });

  contexts.forEach((context) => {
    if (context.data.length <= 0) {
      return;
    }

    const group = renderGroup(svg, context);

    const quadrantBox = renderQuadrantBox(id, group, context, selectQuadrant);

    renderQuadrantLabel(svg, context, quadrantBox.__events);

    const circle = renderCircle(
      group,
      { classes, color: context.color },
      tooltip.__events,
      quadrantBox.__events
    );

    renderSimulation(circle, context);
  });
}
