import * as Mutations from './mutations';

/**
 * @return {Object}
 */
export default function useMutations() {
  return {
    create: Mutations.useCreate(),
    remove: Mutations.useDelete(),
    update: Mutations.useUpdate(),
  };
}
