import { memo } from 'react';
import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { PercentageCircle } from '@/atoms/metrics';
import { PercentageChangeText } from '@/atoms/metrics';
import { Heading, Paragraph } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import Box from '@/components/Box';
import Tooltip from '@/components/Tooltip';

const DoughnutChart = ({
  color,
  description,
  direction = 'column',
  label,
  onClick,
  options,
  value,
  changeValue,
  size = Size.MEDIUM,
}) => {
  const roundedValue = Math.round(value > 0 && value < 1 ? 1 : value);

  const isVertical = direction === 'column';

  const doughnutSize = size === Size.SMALL ? 60 : 80;

  return (
    <Tooltip arrow placement="top" title={description}>
      <Stack
        onClick={onClick}
        alignItems="center"
        justifyContent={isVertical ? 'stretch' : 'flex-start'}
        sx={{
          minHeight: '100%',
          height: '100%',
          cursor: onClick ? 'pointer' : 'inherit',
        }}
        spacing={size === Size.SMALL ? 0.5 : 1}
        direction={direction}
      >
        <Box
          display={isVertical ? 'flex' : 'block'}
          justifyContent="center"
          width={doughnutSize}
          height={doughnutSize}
          maxHeight={doughnutSize}
          mx={isVertical ? 'auto' : 0}
          position="relative"
        >
          <PercentageCircle
            color={color}
            label={label}
            onClick={onClick}
            value={roundedValue}
            options={options}
          />
          <Box
            sx={{
              border: `2px solid ${color}60`,
              borderRadius: doughnutSize,
              position: 'absolute',
              width: doughnutSize * 0.75,
              height: doughnutSize * 0.75,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Heading
              variant={size === Size.SMALL ? 'h5' : 'h4'}
              color={Colors.Base.font.primary}
              overrideStyles={{ fontWeight: 500 }}
            >
              {roundedValue > 0 ? `${roundedValue}%` : 'N/A'}
            </Heading>
          </Box>
        </Box>
        <Box
          display="flex"
          justifyContent={isVertical ? 'center' : 'flex-start'}
          alignItems="center"
          sx={{
            textAlign: isVertical ? 'center' : 'left',
            flex: 1,
          }}
        >
          {isVertical ? (
            <Paragraph
              color={Colors.Base.font.primary}
              size="small"
              overrideStyles={{ m: 0 }}
            >
              {label}
            </Paragraph>
          ) : (
            <Stack sx={{ ml: 0.5 }}>
              {Boolean(changeValue) && (
                <PercentageChangeText value={changeValue} size={Size.MEDIUM} />
              )}
              <Heading color={Colors.Base.font.primary} variant="h6">
                {label}
              </Heading>
            </Stack>
          )}
        </Box>
      </Stack>
    </Tooltip>
  );
};

export default memo(DoughnutChart, (prev, next) => {
  return prev.value === next.value && prev.changeValue === next.changeValue;
});
