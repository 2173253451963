export default class Insight {
  name = 'insight';
  metrics = [];

  constructor(name, metrics) {
    this.name = name;
    this.metrics = metrics;
  }

  reset() {
    this.metrics.forEach((metric) => metric.reset());
  }

  apply(entityType, entityData) {
    const insightData = {};

    this.metrics.forEach((metric) => {
      insightData[metric.name] = metric.apply(entityType, entityData);
    });

    return insightData;
  }

  // combines metric results and compares to Beamible benchmarks to return a result
  benchmark() {}

  getEntityResults(
    entity,
    property = 'percent',
    metricsToShow = this.metrics.map((metric) => metric.name)
  ) {
    const entityResults = [];
    if (entity.__insights) {
      this.metrics.forEach((metric) => {
        if (metricsToShow.includes(metric.name)) {
          entityResults.push(
            entity.__insights[this.name][metric.name][property]
          );
        }
      });
    }
    return entityResults;
  }
}
