import { Budgets, ReadActions } from '../../config';

const DEFAULT_OPTIONS = Object.freeze({
  useLatest: false,
  useMain: false,
});

/**
 * @param {Object} model
 * @param {Function} checkActions
 * @param {Boolean} isHistorical
 * @return
 */
export default function useAct(model, checkActions, isHistorical) {
  /**
   * @param {Object[]} actions
   * @param {Object} actions[].entity
   * @param {String} actions[].property
   * @param {String} actions[].action
   * @param {Object} options
   * @param {Boolean} options.useLatest
   * @param {Boolean} options.useMain
   * @returns {Boolean}
   */
  const many = (actions, options) => {
    const { useLatest, useMain } = { ...DEFAULT_OPTIONS, ...options };

    const affectedProperties = actions.map((action) => action.property);
    const affectsBudgets =
      affectedProperties.filter((property) => Budgets.has(property)).length > 0;

    const isReadOnly = actions.every(({ action }) => ReadActions.has(action));

    const canEdit = isReadOnly || ((useLatest || !isHistorical) && model.edit);

    const canBudget = !affectsBudgets || model.budget;
    const validActions = checkActions(actions, useMain);

    return canBudget && canEdit && validActions;
  };

  /**
   * @param {Object} entity
   * @param {String} property
   * @param {String} action
   * @param {Object} options
   * @param {Boolean} options.useLatest
   * @param {Boolean} options.useMain
   * @returns {Boolean}
   */
  const one = (entity, property, action, options) => {
    return many([{ entity, property, action }], options);
  };

  return { many, one };
}
