import { useMemo } from 'react';
import { Snapshots } from '@pkg/entities';
import * as Use from '../use';
import derive from './derive';

/**
 * @param {Object} me
 * @param {Object} snapshot
 * @returns {Function}
 */
export default function useDeriveRelationship(me, snapshot) {
  const keyed = Snapshots.utils.useKeyed(snapshot);
  const relatedEntities = Use.related(keyed);
  const managedEntities = Use.managed(snapshot, relatedEntities, me?.uuid);
  const roleSet = Use.roles(me);

  return useMemo(() => {
    return derive(me, relatedEntities, managedEntities, roleSet);
  }, [snapshot?.__hash, roleSet]);
}
