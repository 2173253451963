import { Collections } from '@pkg/utils';

const Difference = Object.freeze({
  create: [],
  update: [],
  remove: [],
});

/**
 *
 * @param {Object[]} prev
 * @param {Object[]} next
 * @returns {Object|null}
 */
export default function diffAssociative(prev, next) {
  /** @todo validate if null is ever valid */
  if (prev === null || next === null) {
    return null;
  }

  /** @todo decide on consistent shape of associative properties */
  const key = Object.hasOwn(next, 'uuid') ? 'uuid' : 'key';

  const diff = structuredClone(Difference);
  const prevById = Collections.mapBy(prev, key);
  const nextById = Collections.mapBy(next, key);

  // determine removes
  prev.forEach((item) => {
    if (!nextById.has(item[key])) {
      diff.remove.push(item[key]);
    }
  });

  // determine creates and updates
  next.forEach((item) => {
    const prevItem = prevById.get(item[key]);

    if (!prevItem) {
      diff.create.push(item);
      return;
    }

    const update = { [key]: item[key] };
    let isDifferent = false;

    Object.keys(item).forEach((prop) => {
      if (item[prop] !== prevItem[prop]) {
        update[prop] = item[prop];
        isDifferent = true;
      }
    });

    if (isDifferent) {
      diff.update.push(update);
    }
  });

  // trim empty actions
  Object.keys(diff).forEach((action) => {
    if (!diff[action].length) {
      delete diff[action];
    }
  });

  return Object.keys(diff).length ? diff : null;
}
