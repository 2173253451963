import { OutlineCard } from '@/organisms/cards';
import EntityScore from './EntityScore';

const ScoreCard = ({ scoreType }) => {
  return (
    <OutlineCard>
      <EntityScore scoreType={scoreType} />
    </OutlineCard>
  );
};

export default ScoreCard;
