import { useRef, useState } from 'react';
import { Colors } from '@/atoms/inputs';
import { Label } from '@/atoms/typography';
import { TagChips } from '@/molecules/tags';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { core } from '@/lib/theme/tokens/palettes';

const ActivityTags = ({
  canEdit,
  expanded,
  isExpandable = true,
  label,
  tags,
}) => {
  const [isHover, setIsHover] = useState(null);
  const ref = useRef();

  const handleOnHover = () => {
    setIsHover(true);
  };

  const handleOffHover = () => {
    setIsHover(false);
  };

  return (
    <Box
      position="relative"
      onMouseEnter={handleOnHover}
      onMouseLeave={handleOffHover}
      onFocus={handleOnHover}
      tabIndex="0"
    >
      <Stack direction="row" alignItems="center" zIndex={0}>
        {label && tags?.length === 0 && (
          <Label
            overrideStyles={{
              mr: 0.5,
              textTransform: 'uppercase',
              lineHeight: '1.25rem',
            }}
          >
            {label}:
          </Label>
        )}
        <TagChips tags={tags} expanded={expanded} />
      </Stack>
      {!expanded && (
        <Fade in={isHover}>
          <Box
            tabIndex="0"
            ref={ref}
            onBlur={handleOffHover}
            sx={{
              borderRadius: '4px',
              cursor: isExpandable && canEdit ? 'pointer' : 'inherit',
              backgroundColor:
                isExpandable && canEdit
                  ? Colors.Input.primary.hover.backgroundColor
                  : core.white,
              boxShadow: `0 2px 5px 1px ${Colors.Input.primary.normal.borderColor}, 0 0 0 1px ${Colors.Input.primary.normal.borderColor}`,
              left: 0,
              padding: '4px',
              position: 'absolute',
              top: 0,
              width: '100%',
            }}
          >
            <TagChips tags={tags} expanded={true} />
          </Box>
        </Fade>
      )}
    </Box>
  );
};

export default ActivityTags;
