import {
  FilterType,
  FilterEntity,
  FilterProperty,
} from '@/organisms/filters/enums';

const getFilterByEntity = (entity, propertyId) => {
  switch (entity) {
    case FilterEntity.SKILL:
    case FilterEntity.TAG:
      return FilterType.MULTI_SELECT;
    case FilterEntity.PROPERTY:
      return propertyId ? FilterType.MULTI_SELECT : null;
    default:
  }
};

export default function getFilterType(entity, property, propertyId) {
  switch (property) {
    case FilterProperty.DESCRIPTION:
    case FilterProperty.NAME:
    case FilterProperty.TITLE:
      return FilterType.TEXT;

    case FilterProperty.IS_MANAGER:
      return FilterType.BOOLEAN;

    case FilterProperty.BUDGET:
    case FilterProperty.FTE:
    case FilterProperty.HOURS:
    case FilterProperty.LAYER:
    case FilterProperty.LAYERS:
    case FilterProperty.SPAN:
      return FilterType.NUMBER;

    case FilterProperty.CREATED_AT:
    case FilterProperty.UPDATED_AT:
      return FilterType.DATE;

    case FilterProperty.ACTIVITY:
    case FilterProperty.GROUP:
    case FilterProperty.PEOPLE:
    case FilterProperty.ROLE:
      return getFilterByEntity(entity, propertyId);

    case FilterProperty.ASSIGNEE:
    case FilterProperty.LEAD:
    case FilterProperty.PROPERTY:
    case FilterProperty.ROLE_UUID:
    case FilterProperty.SKILL:
    case FilterProperty.TAG:
      return FilterType.MULTI_SELECT;

    default:
      return null;
  }
}
