/**
 * @param {Object} user
 * @param {String} role
 * @returns {Boolean}
 */
export default function hasRole(user, role) {
  const roles = [
    ...(user?.access?.roles?.team ?? []),
    ...(user?.access?.roles?.organisation ?? []),
  ];

  return roles.includes(role);
}
