import ReactMarkdown from 'react-markdown';
import { Button } from '@/atoms/buttons';
import { Heading, Paragraph } from '@/atoms/typography';
import { useScenarioContext } from '@/shared/providers';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MarchingBlocks from '@/components/MarchingBlocks';
import { useSuggestion } from './hooks';

const ActivitySuggestions = ({ suggestMode, setSuggestMode }) => {
  const { scenario } = useScenarioContext();

  const designId = scenario?.details?.designId;
  const entityType = scenario?.entity?.__type;
  const entityId = scenario?.entity?.uuid;

  const { remove, isFetching, suggestion } = useSuggestion(
    {
      designId,
      entityType,
      entityId,
    },
    suggestMode
  );

  const handleCloseSuggestion = () => {
    remove();
    setSuggestMode(false);
  };

  return (
    <div>
      {isFetching ? (
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ ml: 2 }}
        >
          <MarchingBlocks width={84} height={84} />
          <Paragraph overrideStyles={{ mt: 2, textAlign: 'center' }}>
            Loading your suggestion from our AI engine. This might take 10-30
            seconds.
          </Paragraph>
        </Box>
      ) : (
        <ReactMarkdown
          components={{
            h1: ({ node, ...props }) => <Heading variant="h2" {...props} />,
            h2: ({ node, ...props }) => {
              return (
                <Heading
                  variant="h3"
                  overrideStyles={{ fontWeight: 600, mb: 1 }}
                  {...props}
                />
              );
            },
            h3: ({ node, ...props }) => <Heading variant="h5" {...props} />,
            li: ({ node, ...props }) => <Paragraph {...props} />,
            p: ({ node, ...props }) => <Paragraph {...props} />,
            ul: ({ node, ...props }) => (
              <ul
                style={{ listStyle: 'none', padding: 0, margin: 0 }}
                {...props}
              />
            ),
            strong: ({ node, ...props }) => (
              <Paragraph overrideStyles={{ fontWeight: 600 }} {...props} />
            ),
          }}
        >
          {suggestion}
        </ReactMarkdown>
      )}
      {!isFetching && (
        <Stack direction="row" justifyContent="center" mt={2}>
          <Button
            onClick={handleCloseSuggestion}
            color="secondary"
            size="small"
            label="Close suggestions"
            startIcon={<CloseIcon />}
          >
            Close suggestion
          </Button>
        </Stack>
      )}
    </div>
  );
};

export default ActivitySuggestions;
