import { Collections, Sort } from '@pkg/utils';
import findNearestParent from '../../findNearestParent';

/**
 * @todo refactor to support role hierarchy
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String[]} input.sourceIds
 * @param {String} input.targetId
 * @returns {Object}
 */
export default function merge(snapshot, { sourceIds, targetId }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
        remove: [],
      },
      roles: {
        update: [],
      },
      activities: {
        update: [],
      },
    },
  };

  const entities = structuredClone(snapshot.entities);
  const keyed = Collections.keyById(structuredClone(entities.groups));
  const parents = Collections.keyValueBy(
    entities.groups,
    'uuid',
    'parent_uuid'
  );

  const sources = sourceIds.map((id) => keyed[id]);
  const target = keyed[targetId];

  const tags = new Set(target.tags ?? []);
  sources.forEach((group) => group.tags?.forEach((id) => tags.add(id)));

  const mergedGroups = new Set(sourceIds);
  const movedRoles = new Set();

  entities.roles.forEach(({ uuid, group_uuid }, index) => {
    if (mergedGroups.has(group_uuid)) {
      entities.roles[index].group_uuid = targetId;
      movedRoles.add(uuid);
    }
  });

  const groupedRoles = Collections.groupBy(entities.roles, 'group_uuid');
  Object.keys(groupedRoles).forEach((id) => {
    groupedRoles[id].sort(Sort.order()).forEach((role, order) => {
      const update = { uuid: role.uuid, updated_at: now };
      let isDifferent = false;

      if (role.order !== order) {
        update.order = order;
        isDifferent = true;
      }

      if (movedRoles.has(role.uuid)) {
        update.group_uuid = targetId;
        isDifferent = true;
      }

      if (isDifferent) {
        mutation.entities.roles.update.push(update);
      }
    });
  });

  entities.activities.forEach(({ uuid, owner_uuid }) => {
    if (mergedGroups.has(owner_uuid)) {
      mutation.entities.activities.update.push({
        uuid,
        owner_uuid: targetId,
        updated_at: now,
      });
    }
  });

  mergedGroups.forEach((id) => {
    mutation.entities.groups.remove.push(id);
    delete keyed[id];
  });

  Object.values(keyed)
    .sort(Sort.order())
    .forEach((group, order) => {
      const update = { uuid: group.uuid, updated_at: now };
      let isDifferent = false;

      if (group.uuid === targetId) {
        isDifferent = true;
        update.tags = Array.from(tags);
      }

      if (group.order !== order) {
        isDifferent = true;
        update.order = order;
      }

      if (mergedGroups.has(group.parent_uuid)) {
        isDifferent = true;
        update.parent_uuid = findNearestParent(
          group.uuid,
          parents,
          mergedGroups
        );
      }

      if (isDifferent) {
        mutation.entities.groups.update.push(update);
      }
    });

  return mutation;
}
