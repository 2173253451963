import * as Auth from '@pkg/auth';
import { ClientError } from '@/lib/enums';
import useDesignStore from '../../hooks/useDesignStore';
import NotAuthorized from './NotAuthorized';
import NotFound from './NotFound';

const ERRORS = {
  [ClientError.NOT_AUTHORIZED]: NotAuthorized,
  [ClientError.NOT_FOUND]: NotFound,
};

const Error = () => {
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const error = useDesignStore((state) => state.error);
  const Component = ERRORS[error];
  return Component ? (
    <Component error={error} isAuthenticated={isAuthenticated} />
  ) : null;
};

export default Error;
