import { useRouter } from 'next/router';
import { useState, useCallback, memo } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { useFilterContext } from '@/shared/providers';
import roleAdd from '@pkg/entities/snapshots/reducers/roles/add';
import shortuuid from '@pkg/uuid';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';
import { useMessages } from '../context/MessagesContext';
import useCreatePrompt from '../hooks/useCreatePrompt';
import usePrompt from '../hooks/usePrompt';
import filtersPrompt from '../prompts/filters';
import mutationsPrompt from '../prompts/mutations';
import navigatePrompt from '../prompts/navigate';
import reportsPrompt from '../prompts/reports';
import createFilters from '../tools/createFilters/createFilters';
import createMutation from '../tools/createMutation/createMutation';
import generateReport from '../tools/generateReport/generateReport';
import navigateTo from '../tools/navigateTo/navigateTo';

export const ActionTypes = {
  generateReport: {
    label: 'Generate Report',
    prompt: reportsPrompt,
    tools: { generateReport },
    callback: async (response) => {
      if (response.generateReport) {
        await response.generateReport.execute(response.generateReport);
      }
    },
  },
  createMutation: {
    label: 'Create Mutation',
    prompt: mutationsPrompt,
    tools: { createMutation },
    callback: async (response) => {
      if (response.createMutation) {
        await response.createMutation.execute(response.createMutation);
      }
    },
  },
  applyMutation: {
    label: 'Apply Mutation',
  },
  navigateTo: {
    label: 'Navigate To',
    prompt: navigatePrompt,
    tools: { navigateTo },
    callback: async (response) => {
      if (response.navigateTo) {
        await response.navigateTo.execute(response.navigateTo);
      }
    },
  },
  createFilters: {
    label: 'Apply Filters',
    prompt: filtersPrompt,
    tools: { createFilters },
    callback: async (response) => {
      if (response.createFilters) {
        await response.createFilters.execute(response.createFilters);
      }
    },
  },
};

const toolReducerMap = {
  role_add: roleAdd,
};

function convertNumbers(obj) {
  // Check if the current item is an object or an array
  if (typeof obj === 'object' && obj !== null) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (typeof obj[key] === 'object') {
          // Recursively call the function on nested objects or arrays
          convertNumbers(obj[key]);
        } else if (typeof obj[key] === 'string' && !isNaN(obj[key])) {
          // Convert strings that are numeric into actual numbers
          obj[key] = Number(obj[key]);
        }
      }
    }
  }
  return obj;
}

const Action = memo(function Action({ action }) {
  const { addMessage, getMessages, messages, addStream } = useMessages();
  const router = useRouter();
  const snapshot = useDesignStore((state) => state.snapshot);
  const mutate = useDesignStore((state) => state.actions.mutate);
  const [isExecuting, setIsExecuting] = useState(false);
  const actionType = ActionTypes[action?.type];
  const label = actionType?.label || 'Unknown';
  const { changeFilter } = useFilterContext();

  const { error, isLoading, data, prompt, stream } = usePrompt({
    tools: actionType?.tools,
  });

  const createPrompt = useCreatePrompt();

  const handleAction = useCallback(async () => {
    if (isExecuting || isLoading || action.applied) {
      return;
    }

    setIsExecuting(true);

    if (!actionType.prompt) {
      setIsExecuting(true);
      if (action.type === 'applyMutation') {
        const tools = action.parameters.toolResults;
        tools.forEach((tool) => {
          const reducer = toolReducerMap[tool.toolName];
          const mutation = reducer(snapshot, tool.result.payload);
          mutate(mutation, 'ai');
        });
      }
      return;
    }

    try {
      const promptOptions = await createPrompt({
        basePrompt: actionType.prompt,
        userText: action.parameters.userQuestion,
      });
      const response = await prompt(promptOptions);
      if (response && actionType.callback) {
        await actionType.callback(response);
        action.applied = true;
      }

      const data = response;

      if (!data) return;

      if (action.type === 'generateReport') {
        addStream({ action, stream: response });
        return;
      }

      if (action.type === 'createFilters') {
        let filters = data?.toolResults[0]?.args?.filters.conditions || {};
        console.log('what server', filters);
        filters = convertNumbers(filters);
        // convert filters which is an array of objects into an array of maps
        const newConditions = filters.map(
          (condition) => new Map(Object.entries(condition))
        );
        console.log('what', { newConditions });
        changeFilter({ groups: [{ conditions: newConditions }] });
      }

      if (action.type === 'navigateTo') {
        const url = data?.toolResults[0]?.args?.url || '';
        console.log('Begin navigating!!', url, data.text);
        window.location.href = url;
        // router.push(url);
      }

      if (action.type === 'createMutation') {
        if (data?.toolResults?.length > 0) {
          addMessage({
            text: `The following mutations want to be applied: ${data.toolResults
              .map((tool) => tool.toolName)
              .join(', ')}`,
            action: 'applyMutation',
            parameters: {
              uuid: shortuuid.generate(),
              toolResults: data.toolResults,
            },
          });
        }
      }
    } catch (err) {
      console.error('Error executing action:', err);
    } finally {
      setIsExecuting(false);
    }
  }, [action, actionType, createPrompt, isExecuting, isLoading, prompt]);

  return (
    <span style={{ color: 'green', marginLeft: '5px' }}>
      <Button
        color="secondary"
        label={isLoading ? 'Processing...' : label}
        onClick={handleAction}
        size={Size.SMALL}
        disabled={isExecuting || isLoading || action.applied || error}
      />
      {error && (
        <span style={{ color: 'red', marginLeft: '5px', fontSize: '0.8em' }}>
          Error: {error.message}
        </span>
      )}
    </span>
  );
});

Action.displayName = 'Action';

export default Action;
