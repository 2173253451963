import { FillCard } from '@/organisms/cards';
import TeamList from './TeamList';

const TeamListCard = ({ allowLink = false }) => {
  return (
    <FillCard>
      <TeamList allowLink={allowLink} />
    </FillCard>
  );
};

export default TeamListCard;
