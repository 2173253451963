import { percent } from '@pkg/utils/numbers';

/**
 * @param {String} quadrant
 * @param {Number} totalHours
 *
 * @return {Object}
 */
export default function formatQuadrantActivities(quadrant, totalHours) {
  return Array.from(quadrant?.activities.entries()).map(([uuid, activity]) => {
    return {
      id: uuid,
      label: activity.__description,
      value: percent(activity.__aggregateHours, totalHours, 2),
    };
  });
}
