import { Base as BaseColor } from '@/atoms/colors';
import { brand } from '@/lib/theme/tokens/palettes';

const ModeSelectorColors = Object.freeze({
  normal: {
    color: BaseColor.font.tertiary,
    backgroundColor: 'transparent',
  },
  hover: {
    color: BaseColor.font.tertiary,
    backgroundColor: brand.yellow.tints[2],
  },
  selected: {
    color: brand._navy.main,
    backgroundColor: brand.yellow.main,
  },
});

export default ModeSelectorColors;
