const mapDesignOptions = (main, scenarios) => {
  const map = new Map();

  map.set(main.uuid, main);

  scenarios.forEach((scenario) => {
    map.set(scenario.uuid, scenario);
  });

  return map;
};

export default function mapDesigns(main, scenarios) {
  const sortedScenarios = scenarios
    .sort((a, b) => a.updated_at - b.updated_at)
    .sort((a, b) => a.scope - b.scope);
  return mapDesignOptions(main, sortedScenarios);
}
