import { DimensionType } from '@/molecules/tableElements/enums';
import { ScopeType } from '@/shared/enums';

// Skill options.
const skillOptions = () => {
  return [
    {
      label: DimensionType.PEOPLE_SKILLS,
      id: DimensionType.PEOPLE_SKILLS,
    },
    {
      label: DimensionType.ROLE_SKILLS,
      id: DimensionType.ROLE_SKILLS,
    },
  ];
};

// Tag options.
const tagOptions = [
  {
    label: DimensionType.ACTIVITY_TAGS_ACTIVITY,
    id: DimensionType.ACTIVITY_TAGS_ACTIVITY,
  },
  {
    label: DimensionType.ROLE_TAGS_ROLE,
    id: DimensionType.ROLE_TAGS_ROLE,
  },
  {
    label: DimensionType.ROLE_TAGS_ACTIVITY,
    id: DimensionType.ROLE_TAGS_ACTIVITY,
  },
  {
    label: DimensionType.GROUP_TAGS_GROUP,
    id: DimensionType.GROUP_TAGS_GROUP,
  },
  {
    label: DimensionType.GROUP_TAGS_ACTIVITY,
    id: DimensionType.GROUP_TAGS_ACTIVITY,
  },
];

export default function dimensionOptions(scope) {
  switch (scope) {
    case ScopeType.TAGS:
      return tagOptions;
    case ScopeType.SKILLS:
      return skillOptions;
    default:
      return tagOptions;
  }
}
