import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String[]} input.ids
 * @param {String} input.parentId
 * @returns {Object}
 */
export default function rebase(snapshot, { ids, parentId }) {
  const now = Date.now();
  const entities = snapshot.entities;

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
    },
  };

  const keyed = Collections.keyById(entities.groups);
  const movedGroups = new Set();

  ids.forEach((id) => {
    if (Object.hasOwn(keyed, id)) {
      movedGroups.add(id);
    }
  });

  entities.groups.forEach((group) => {
    const update = { uuid: group.uuid, updated_at: now };
    let isDifferent = false;

    if (movedGroups.has(group.uuid)) {
      if (movedGroups.has(group.parent_uuid)) {
        return;
      }

      update.parent_uuid = parentId;
      isDifferent = true;
    } else {
      if (movedGroups.has(group.parent_uuid)) {
        update.parent_uuid = null;
        isDifferent = true;
      }
    }

    if (isDifferent) {
      mutation.entities.groups.update.push(update);
    }
  });

  return mutation;
}
