import { useCallback, useEffect, useRef, useState } from 'react';
import { Obj } from '@pkg/utils';
import { Snapshots } from '..';
import mutate from './mutate';
import * as Reducers from './reducers';

/**
 * @param {Object} snapshot
 * @param {Function} onMutate
 * @returns {Array}
 */
export default function useSnapshot(snapshot, onMutate, isHistorical) {
  const [state, setState] = useState(snapshot);
  const ref = useRef();

  useEffect(() => {
    if (isHistorical) {
      setState(snapshot);
    }
  }, [isHistorical, snapshot?.__hash]);

  /**
   * Bind methods to ref so dispatch never changes
   */
  useEffect(() => {
    ref.current = { state, setState, onMutate };
  }, [state, setState, onMutate]);

  /**
   * Patch snapshot back in, keeping latest
   */
  useEffect(() => {
    if (isHistorical) {
      return;
    }

    if (!state) {
      return setState(snapshot);
    }

    const diff = Snapshots.diffLatest(state, snapshot);
    if (!diff) {
      return;
    }

    const mutated = mutate(state, diff);
    setState(mutated);
  }, [snapshot]);

  /**
   * @param {String} action
   * @param {Object} input
   * @returns {Promise}
   */
  const handler = async (action, input) => {
    const { state, setState, onMutate } = ref.current;

    const mutation = Obj.get(Reducers, action)(state, input);
    if (!mutation) {
      return;
    }

    // mutate state
    const mutated = mutate(state, mutation);
    delete mutation?.entities?.people;

    // attempt mutation
    setState(mutated);
    onMutate(mutation, action);
  };

  return [state, useCallback(handler, [])];
}
