import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import { Routes, Str } from '@pkg/utils';
import { ClientError, DesignEntity } from '@/lib/enums';
import { DesignFragment } from './fragments';
import useStore from './useStore';

const GET_DESIGN = /* GraphQL */ `
  query GetDesign($uuid: UUID!) {
    design(uuid: $uuid) {
      ...DesignFragment
    }
  }

  ${DesignFragment}
`;

export default function useFetch() {
  const client = useClient();
  const database = useDatabase();
  const { put } = useStore();

  /**
   * @param {String} uuid
   */
  const fetch = async (uuid) => {
    try {
      const result = await client.graphql({
        query: GET_DESIGN,
        variables: { uuid },
      });

      if (!result.data?.design) {
        return { error: ClientError.NOT_FOUND };
      }

      const { latest, ...design } = result.data.design;
      const snapshot = JSON.parse(latest.snapshotString);
      snapshot.__hash = Str.hash(latest.snapshotString);

      latest.snapshot = snapshot;
      latest.loaded_at = Date.now();
      delete latest.snapshotString;

      design.latest = { uuid: latest.uuid };
      design.__pathname = Routes.build.design(design, snapshot);
      design.__type = design.is_scenario
        ? DesignEntity.SCENARIO
        : DesignEntity.DESIGN;

      put(design);
      await Promise.all([
        database.designs.put(design),
        database.designRevisions.put(latest),
      ]);

      return { design, latest };
    } catch (error) {
      console.error(error);
      if (error.isAuthorizationError?.()) {
        return { error: ClientError.NOT_AUTHORIZED };
      } else {
        return { error: ClientError.NOT_FOUND };
      }
    }
  };

  return fetch;
}
