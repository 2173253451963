import ReactMarkdown from 'react-markdown';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { RoleSummary } from '@/molecules/summaries';
import { OutlineCard } from '@/organisms/cards';
import { EventName, EventProperty } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { brand } from '@/lib/theme/tokens/palettes';

const Welcome = ({
  activeStep,
  onNext,
  title,
  description,
  disclaimer,
  stepCount,
  stepIndex,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const { scenario } = useScenarioContext();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        {!isLargeScreen && (
          <Heading variant="h2" overrideStyles={{ fontWeight: 600, mb: 2 }}>
            {title},
          </Heading>
        )}
        <Paragraph size={isLargeScreen ? Size.LARGE : Size.MEDIUM}>
          {description}
        </Paragraph>
      </Grid>
      <Grid item xs={12} md={8} sx={{ mt: 1 }}>
        <OutlineCard>
          <RoleSummary entity={scenario?.entity} size={Size.SMALL} />
        </OutlineCard>
      </Grid>
      {disclaimer && (
        <Grid item xs={12} md={8} sx={{ mt: 1 }}>
          <Box
            p={2}
            sx={{
              backgroundColor: brand.lightBlue.tints[0],
              borderRadius: '8px',
            }}
          >
            <ReactMarkdown>{disclaimer}</ReactMarkdown>
          </Box>
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <Box mt={4}>
          <Button
            dataTestid="onboard-start"
            label="Let's get started"
            onClick={onNext}
            color="secondary"
            size={Size.X_LARGE}
            track={{
              name: EventName.SURVEY_STARTED,
              properties: {
                [EventProperty.ENTRY]: 'Survey',
                [EventProperty.STEP]: stepIndex,
                [EventProperty.STEP_COUNT]: stepCount - 1,
                [EventProperty.STEP_NAME]: 'Welcome',
              },
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default Welcome;
