import { color } from './tokens';

const primary = [
  {
    props: { variant: 'primary' },
    style: {
      color: color.core.white,
      '&:disabled': {
        backgroundColor: color.primary.brand[200],
        color: color.core.white,
      },
    },
  },
  {
    props: { variant: 'primary', color: 'default' },
    style: {
      backgroundColor: color.primary.brand[800],
      '&:hover': {
        backgroundColor: color.primary.brand[900],
      },
    },
  },
  {
    props: { variant: 'primary', color: 'destructive' },
    style: {
      backgroundColor: color.system.destructive[300],
      '&:disabled': {
        backgroundColor: color.system.destructive[75],
      },
      '&:hover': {
        backgroundColor: color.system.destructive[400],
      },
    },
  },
  {
    props: { variant: 'primary', color: 'success' },
    style: {
      backgroundColor: color.system.success[300],
      '&:hover': {
        backgroundColor: color.system.success[400],
      },
    },
  },
];

const secondary = [
  {
    props: { variant: 'secondary' },
    style: {
      backgroundColor: color.primary.grey[100],
      color: color.primary.grey[500],
      '&:disabled': {
        backgroundColor: color.primary.grey[100],
        color: color.primary.brand[200],
      },
      '&:hover': {
        backgroundColor: color.primary.grey[200],
      },
    },
  },
  {
    props: { variant: 'secondary', color: 'default' },
    style: {},
  },
  {
    props: { variant: 'secondary', color: 'destructive' },
    style: {
      color: color.system.destructive[300],
      '&:hover': {
        color: color.system.destructive[400],
      },
    },
  },
  {
    props: { variant: 'secondary', color: 'success' },
    style: {
      color: color.system.success[300],
      '&:hover': {
        color: color.system.success[400],
      },
    },
  },
];

const tertiary = [
  {
    props: { variant: 'tertiary' },
    style: {
      '&:disabled': {
        backgroundColor: color.core.grey[100],
        color: color.primary.brand[200],
      },
    },
  },
  {
    props: { variant: 'tertiary', color: 'default' },
    style: {
      backgroundColor: 'transparent',
      color: color.core.grey[500],
      '&:hover': {
        backgroundColor: color.core.grey[100],
      },
    },
  },
  {
    props: { variant: 'tertiary', color: 'destructive' },
    style: {
      backgroundColor: color.system.destructive[100],
      color: color.system.destructive[300],
      '&:hover': {
        backgroundColor: color.system.destructive[100],
        color: color.system.destructive[400],
      },
    },
  },
  {
    props: { variant: 'tertiary', color: 'success' },
    style: {
      backgroundColor: color.system.success[100],
      color: color.system.success[300],
      '&:hover': {
        backgroundColor: color.system.success[100],
        color: color.system.success[400],
      },
    },
  },
];

const naked = [
  {
    props: { variant: 'naked' },
    style: {
      backgroundColor: color.core.white,
      color: color.system.interactive[300],
      '&:disabled': {
        color: color.primary.brand[200],
      },
      '&:hover': {
        backgroundColor: color.primary.grey[100],
      },
    },
  },
];

const variants = [...primary, ...secondary, ...tertiary, ...naked];

export default {
  MuiButton: {
    defaultProps: {
      color: 'default',
      disableElevation: true,
      type: 'button',
    },
    styleOverrides: {
      root: {
        fontWeight: 500,
        textTransform: 'none',
      },
      sizeSmall: {
        fontSize: 12,
        lineHeight: '16px',
        padding: '7px 8px',
      },
      sizeMedium: {
        fontSize: 14,
        lineHeight: '20px',
        padding: '8px 12px',
      },
      sizeLarge: {
        fontSize: 16,
        lineHeight: '20px',
        padding: '12px 16px',
      },
      iconSizeSmall: {
        '& svg': {
          fontSize: 16,
        },
      },
      iconSizeMedium: {
        '& svg': {
          fontSize: 20,
        },
      },
      iconSizeLarge: {
        '& svg': {
          fontSize: 20,
        },
      },
      endIcon: {
        marginRight: 0,
      },
      startIcon: {
        marginLeft: 0,
      },
    },
    variants,
  },
  MuiIconButton: {
    defaultProps: {
      color: 'default',
      type: 'button',
    },
    styleOverrides: {
      root: {
        borderRadius: 4,
      },
      sizeSmall: {
        padding: 7,
        '& svg': {
          fontSize: 16,
        },
      },
      sizeMedium: {
        padding: 8,
        '& svg': {
          fontSize: 20,
        },
      },
      sizeLarge: {
        padding: 12,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    variants,
  },
};
