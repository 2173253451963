import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { IconHeading } from '@/molecules/headings';
import { List, TextListItem } from '@/molecules/lists';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import MoveDownIcon from '@mui/icons-material/MoveDownRounded';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import { DesignEntity } from '@/lib/enums';
import useScenarioList from './useScenarioList';

const ScenarioList = ({ entityType, redirectToComparison }) => {
  const { routeToScenario, scenarios } = useScenarioList(
    entityType,
    redirectToComparison
  );

  const { create } = useScenarioContext();
  const scenarioLabel = scenarios?.length > 1 ? 'scenarios' : 'scenario';
  const subtitle =
    scenarios?.length > 0
      ? `You have ${scenarios?.length} unpublished ${scenarioLabel}`
      : `You haven't created any scenarios`;

  const handleClick = (event, item) => {
    if (!item) {
      return;
    }

    const { scenario, designId } = item;

    if (redirectToComparison) {
      routeToScenario({
        entity: scenario.level,
        scenarioId: scenario.designId,
        designId,
      });
    } else {
      routeToScenario({ entity: scenario.level, designId: scenario.designId });
    }
  };

  const handleCreateScenario = async (event) => {
    const { entity, scenarioId, designId } = await create(event);

    if (redirectToComparison) {
      routeToScenario({ entity, scenarioId, designId });
    } else {
      routeToScenario({ entity, designId: scenarioId });
    }
  };

  return (
    scenarios && (
      <Grow in={true} timeout={500}>
        <div>
          <IconHeading
            color={entityType === DesignEntity.ROLE ? 'primary' : 'secondary'}
            icon={<MoveDownIcon />}
            size={Size.LARGE}
            subtitle={subtitle}
            title="Scenarios"
          />
          {scenarios?.length > 0 && (
            <Box mt={3}>
              <List
                items={scenarios}
                ListItem={<TextListItem />}
                onClick={handleClick}
              />
            </Box>
          )}
          <Box pl={scenarios?.length > 0 ? 0 : 9} pt={1}>
            <Button
              color="secondary"
              label="Create a scenario"
              onClick={handleCreateScenario}
              size={Size.SMALL}
            />
          </Box>
        </div>
      </Grow>
    )
  );
};

export default ScenarioList;
