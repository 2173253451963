import { createContext, useMemo } from 'react';
import Database from './Database';

export const DatabaseContext = createContext();

const Provider = ({ children }) => {
  const database = useMemo(() => new Database(), []);

  return (
    <DatabaseContext.Provider value={database}>
      {children}
    </DatabaseContext.Provider>
  );
};

export default Provider;
