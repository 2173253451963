import { Size } from '@/atoms/enums';

const SquareBackgroundIconSize = Object.freeze({
  [Size.X_SMALL]: {
    icon: {
      width: 16,
      height: 16,
      borderRadius: '2px',
    },
    background: {
      width: 20,
      height: 20,
      borderRadius: '2px',
    },
  },
  [Size.SMALL]: {
    icon: {
      width: 22,
      height: 22,
      borderRadius: '2px',
    },
    background: {
      width: 22,
      height: 22,
      borderRadius: '2px',
    },
  },
  [Size.MEDIUM]: {
    icon: {
      width: 28,
      height: 28,
      borderRadius: '2px',
    },
    background: {
      width: 48,
      height: 48,
      borderRadius: '4px',
    },
  },
  [Size.LARGE]: {
    icon: {
      width: 34,
      height: 34,
      borderRadius: '4px',
    },
    background: {
      width: 58,
      height: 58,
      borderRadius: '4px',
    },
  },
});

export default SquareBackgroundIconSize;
