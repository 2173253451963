const AFTER = 1;
const BEFORE = -1;

/**
 * @param {Object} grouped
 * @returns {Function}
 */
export default function tags(grouped) {
  return (a, b) => {
    if (a === 'primary') {
      return BEFORE;
    }

    if (b === 'primary') {
      return AFTER;
    }

    return grouped[a].order - grouped[b].order;
  };
}
