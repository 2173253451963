import { forwardRef } from 'react';
import { EntityType } from '@/shared/enums';
import { useEntityHierarchyContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import {
  TreeItem2GroupTransition,
  TreeItem2Root,
} from '@mui/x-tree-view/TreeItem2';
import { TreeItem2Provider } from '@mui/x-tree-view/TreeItem2Provider';
import { unstable_useTreeItem2 as useTreeItem2 } from '@mui/x-tree-view/useTreeItem2';
import { Colors } from '@pkg/utils';
import { brand } from '@/lib/theme/tokens/palettes';
import EntityHierarchyItemContent from './EntityHierarchyItemContent';

const setColor = (entityType) => {};

const EntityHierarchyItem = forwardRef(function HierarchyItem(props, ref) {
  const { entityMap, hierarchy } = useEntityHierarchyContext();
  const { activeId, id, itemId, label, disabled, children, onClose, ...other } =
    props;
  const [entityType, entityId] = props.itemId.split(':');
  const entity = entityMap.get(entityType)?.get(entityId);
  const colors = Colors.entity(
    entity.is_manager ? EntityType.MANAGER : entityType
  );
  const user = entityMap.get(EntityType.PERSON)?.get(entity.user_uuid);
  const isRoot = entityId === hierarchy[0]?.uuid;
  const isActive = activeId === entityId;

  const {
    getRootProps,
    getContentProps,
    getIconContainerProps,
    getGroupTransitionProps,
    status,
  } = useTreeItem2({ id, itemId, children, label, disabled, rootRef: ref });

  return (
    <TreeItem2Provider itemId={props.itemId}>
      <TreeItem2Root
        {...getRootProps(other)}
        sx={{
          '& .MuiCollapse-vertical > li': {
            '&::after': {
              content: '""',
              display: 'block',
              height: 'calc(100% - 22px)',
              top: 0,
              left: '11px',
              position: 'absolute',
              width: 2,
              backgroundColor: brand.lightBlue.shades[1],
            },
          },
          '& .MuiCollapse-vertical > li:last-child': {
            '&::after': {
              height: '32px',
            },
          },
        }}
      >
        <EntityHierarchyItemContent
          getContentProps={getContentProps}
          getIconContainerProps={getIconContainerProps}
          entityType={entity.is_manager ? EntityType.MANAGER : entityType}
          hasChildren={children?.length > 0}
          id={entityId}
          isActive={isActive}
          label={label}
          isRoot={isRoot}
          color={colors.main}
          onClose={onClose}
          status={status}
          user={user}
        />
        <Box position="relative">
          {children && (
            <TreeItem2GroupTransition
              {...getGroupTransitionProps()}
              sx={{
                pl: 3,
              }}
            />
          )}
        </Box>
      </TreeItem2Root>
    </TreeItem2Provider>
  );
});

export default EntityHierarchyItem;
