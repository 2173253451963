import { generateText, streamText } from 'ai';
import { useState, useCallback } from 'react';
import openai from '../client';

/*
  - trigger a general report when asking question with the tools, add a function 
    to call the report tool
*/

const usePrompt = ({ tools = {} }) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [streamData, setStreamData] = useState(null);

  const prompt = useCallback(
    async (promptOptions) => {
      const shouldStream = promptOptions?.stream;

      if (!promptOptions) {
        return;
      }

      setIsLoading(true);
      setError(null);
      setData(null);
      setStreamData(null);

      console.log('Sending prompt', promptOptions.prompt);

      try {
        if (shouldStream) {
          // Handle streaming response
          const streamResponse = await streamText({
            model: openai('gpt-3.5-turbo-16k'),
            ...promptOptions,
          });

          setStreamData(streamResponse);
          return streamResponse;
        } else {
          // Handle normal response
          const response = await generateText({
            model: openai('gpt-3.5-turbo-16k'),
            ...promptOptions,
          });

          setData(response);
          return response;
        }
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setIsLoading(false);
      }
    },
    [tools]
  );

  return {
    error,
    isLoading,
    data,
    stream: streamData,
    prompt,
  };
};

export default usePrompt;
