import Skeleton from '@mui/material/Skeleton';
import SkeletonColors from './SkeletonColors';

const SkeletonItem = ({ color = 'primary', height = 70 }) => {
  return (
    <Skeleton
      variant="rounded"
      sx={{
        transform: 'scale(1)',
        borderRadius: '4px',
        height: height,
        backgroundColor: SkeletonColors[color],
      }}
    />
  );
};

export default SkeletonItem;
