import MuiDialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Dialog = ({ children, ...props }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <MuiDialog
      fullScreen={fullScreen}
      TransitionComponent={Slide}
      TransitionProps={{ direction: 'down' }}
      {...props}
    >
      {children}
    </MuiDialog>
  );
};

export default Dialog;
