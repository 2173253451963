import { percent } from '@pkg/utils/numbers';

export default function uniquePercentageValueColumn({
  params,
  numeratorColumnId,
  denominatorColumnId,
  uniqueColumnId,
}) {
  const { api, node } = params;

  const numeratorColumn = api.getColumn(numeratorColumnId);
  const idColumn = api.getColumn(uniqueColumnId);
  const colId = node.getValueFromValueService(idColumn) ?? 'none';
  const denominatorColumn = api.getColumn(denominatorColumnId);
  const numerator = node.getValueFromValueService(numeratorColumn);
  const denominator = node.getValueFromValueService(denominatorColumn)?.value;

  const value = percent(numerator, denominator, 7);

  return {
    colId,
    percent: value,
    numerator,
    denominator,
    toString: () => (value ? `${value.toFixed(2)}%` : '0%'),
  };
}
