import { percent } from '@pkg/utils/numbers';

/**
 * Recursive calculation of activity costs with consideration for groups.
 *
 * @param {Object} node
 * @param {Object} columns
 * @param {Number} total
 *
 * @return {Number}
 */
const sumActivityCost = (node, columns, total) => {
  if (node.group) {
    node.childrenAfterFilter.forEach((childNode) => {
      total = sumActivityCost(childNode, columns, total);
    });
  }

  const roleBudget = node.getValueFromValueService(columns.roleBudget);
  const activityHours = node.getValueFromValueService(columns.activityHours);
  const roleHours = node.getValueFromValueService(columns.roleHours);

  if (isNaN(roleBudget) || isNaN(roleHours)) {
    return (total += 0);
  }

  const percentage = percent(activityHours, roleHours, 7);

  return (total += (percentage / 100) * roleBudget);
};

export default sumActivityCost;
