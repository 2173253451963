import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { Paragraph } from '@/atoms/typography';
import { PlanSummaryChange } from '@/organisms/plans';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

const PlanSummary = ({ metricList, metrics }) => {
  return (
    <Box
      sx={{
        position: 'reative',
      }}
    >
      {metricList.length > 0 ? (
        <Grid container spacing={2} p={2}>
          <Grid item xs={12}>
            <Heading
              variant="h6"
              overrideStyles={{ fontWeight: 600, textTransform: 'uppercase' }}
            >
              Scenario changes
            </Heading>
          </Grid>
          {metricList.map((metric) => (
            <Grid item xs={12} key={metric}>
              <PlanSummaryChange metric={metric} metrics={metrics} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack
          alignItems="center"
          justifyContent="center"
          height="calc(100vh - 200px)"
        >
          <Paragraph size={Size.MEDIUM}>
            No scenario changes have been made.
          </Paragraph>
        </Stack>
      )}
    </Box>
  );
};

export default PlanSummary;
