import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import { DesignEntity } from '@/lib/enums';
import useNextColor from '../../useNextColor';

/**
 * @param {Object} state
 * @param {Object[]} state.tags
 * @param {Object[]} state.categories
 * @param {Object} input
 * @param {String} input.category_uuid
 * @returns {Object}
 */
export default function add({ tags, categories }, { category_uuid }) {
  const categoryId =
    !category_uuid || category_uuid === 'primary' ? null : category_uuid;

  const category = categories.map.get(categoryId) ?? null;
  const categoryTags = Collections.groupBy(tags.list, 'category_uuid');

  const tag = {
    uuid: shortuuid.generate(),
    category_uuid: category?.uuid ?? null,
    name: 'New tag',
    description: '',
    color: category?.color ?? useNextColor(categoryTags[categoryId]),
    order: categoryTags[categoryId]?.length ?? 0,
    taggables: category?.taggables ?? [
      DesignEntity.GROUP,
      DesignEntity.ROLE,
      DesignEntity.ACTIVITY,
    ],
  };

  return {
    tags: {
      create: [tag],
    },
  };
}
