import { useState } from 'react';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import { TabSize, TabVariant } from './utils';

const Tabs = ({
  initialSelected,
  onClick,
  options,
  variant = 'folder',
  size = Size.MEDIUM,
}) => {
  const [selected, setSelected] = useState(initialSelected);
  const tabStyles = TabVariant[variant];
  const tabSize = TabSize[size];
  const growLast = variant === 'folder';

  const handleClick = (option) => {
    if (option.id === selected.id) {
      return;
    }
    setSelected(option);
    onClick?.(option);
  };

  return (
    <Stack direction="row" width="100%">
      {options.map((option, index) => (
        <Box
          key={option.id}
          onClick={() => handleClick(option)}
          py={1}
          px={2}
          sx={{
            ...tabStyles[option.id === selected?.id ? 'selected' : 'normal'],
            flexGrow: growLast && index === options.length - 1 ? 1 : 0,
            cursor: 'pointer',
          }}
        >
          <Heading
            variant="h4"
            overrideStyles={{
              ...tabSize,
            }}
          >
            {option.label}
          </Heading>
        </Box>
      ))}
    </Stack>
  );
};

export default Tabs;
