import { Label, TextInput } from '@/atoms/inputs';
import Box from '@mui/material/Box';

const TextField = ({
  color,
  debounce,
  disabled,
  endAdornment,
  fullWidth,
  initialValue,
  inputProps,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  optional,
  placeholder,
  size,
  variant,
  width,
}) => {
  return (
    <Box>
      <Label color={color}>
        {label}
        {optional && <span style={{ fontSize: '80%' }}> (optional)</span>}
      </Label>
      <TextInput
        color={color}
        debounce={debounce}
        disabled={disabled}
        endAdornment={endAdornment}
        fullWidth={fullWidth}
        initialValue={initialValue}
        inputProps={inputProps}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        size={size}
        variant={variant}
        width={width}
      />
    </Box>
  );
};

export default TextField;
