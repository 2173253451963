import { EntityType } from '@/shared/enums';
import { palettes } from '@/lib/theme/tokens';

const EntityColors = Object.freeze({
  [EntityType.ACTIVITY]: {
    color: palettes.brand.grey,
    primary: palettes.brand.grey.shades[1],
    secondary: palettes.brand.grey.tints[3],
    tertiary: palettes.brand.grey.tints[1],
  },
  [EntityType.GROUP]: {
    color: palettes.brand.blue,
    primary: palettes.brand.blue.main,
    secondary: palettes.brand.blue.tints[2],
    tertiary: palettes.brand.grey.tints[1],
  },
  [EntityType.LIVE]: {
    color: palettes.brand.green,
    primary: palettes.brand.green.main,
    secondary: palettes.brand.green.tints[1],
    tertiary: palettes.brand.green.tints[1],
  },
  [EntityType.MANAGER]: {
    color: palettes.brand.purple,
    primary: palettes.brand.purple.main,
    secondary: palettes.brand.purple.tints[1],
    tertiary: palettes.brand.purple.tints[1],
  },
  [EntityType.ORGANISATION]: {
    color: palettes.brand.coral,
    primary: palettes.brand.coral.main,
    secondary: palettes.brand.coral.tints[1],
    tertiary: palettes.brand.coral.tints[1],
  },
  [EntityType.ROLE]: {
    color: palettes.brand.teal,
    primary: palettes.brand.teal.main,
    secondary: '#BFECF0',
    tertiary: palettes.brand.grey.tints[1],
  },
  [EntityType.SCENARIO]: {
    color: palettes.brand.yellow,
    primary: palettes.brand.yellow.shades[3],
    secondary: palettes.brand.yellow.tints[1],
    tertiary: palettes.brand.yellow.tints[1],
  },
});

export default EntityColors;
