import { FilterPropertyMap } from '@/organisms/filters/utils';

export default function buildFilterValueLabel(propertyType, values) {
  const property = FilterPropertyMap?.get(propertyType)?.label?.toLowerCase();
  if (!values?.length) {
    return `Select ${property ?? 'value'}`;
  }

  let label = values[0]?.label ?? 'Select value';

  if (values.length > 1) {
    label += ` (+${values?.length - 1} more)`;
  }

  return label;
}
