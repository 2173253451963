import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { Modal } from '@/molecules/modals';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

const Dialog = ({
  children,
  minWidth = 'calc(25vw)',
  maxWidth = 'calc(75vw)',
  open,
  onClose,
  showClose,
  title,
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        minHeight="auto"
        minWidth={minWidth}
        maxWidth={maxWidth}
        padding={0}
      >
        <Grid container spacing={4}>
          {(showClose || title) && (
            <Grid item xs={12}>
              <Stack
                direction="row"
                justifyContent={title ? 'space-between' : 'flex-end'}
                mt={1}
                pl={4}
                pr={1}
              >
                {title && (
                  <Heading
                    variant="h3"
                    overrideStyles={{ fontWeight: 600, mt: 3 }}
                  >
                    {title}
                  </Heading>
                )}
                <Button
                  variant="naked"
                  color="light-blue"
                  label="Close"
                  size={Size.X_LARGE}
                  startIcon={<CloseIcon />}
                  onClick={onClose}
                />
              </Stack>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box px={4} mb={4}>
              {children}
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default Dialog;
