import { Collections } from '@pkg/utils';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.parentId
 * @returns {Object}
 */
export default function rebase(snapshot, { ids, parentId }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [],
      },
    },
  };

  const entities = snapshot.entities;
  const keyed = Collections.keyById(structuredClone(entities.roles));
  const rebasedRoles = new Set();

  ids.forEach((roleId) => {
    if (!Object.hasOwn(keyed, roleId)) {
      return;
    }

    if (parentId && keyed[roleId].parent_uuid === parentId) {
      return;
    }

    if (parentId === roleId) {
      return;
    }

    rebasedRoles.add(roleId);
  });

  if (rebasedRoles.size === 0) {
    return null;
  }

  rebasedRoles.forEach((roleId) => {
    mutation.entities.roles.update.push({
      uuid: roleId,
      parent_uuid: parentId,
      updated_at: now,
    });
  });

  return mutation;
}
