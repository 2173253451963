import { useMemo } from 'react';
import * as Mutations from './mutations';

/**
 * @return {Object}
 */
export default function useSupporterMutations(query) {
  const add = Mutations.useAddSupporter(query);
  const remove = Mutations.useRemoveSupporter(query);
  return useMemo(() => ({ add, remove }), [add, remove]);
}
