import { cloneElement, useEffect } from 'react';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import WizardNavigation from './WizardNavigation';

const StyledContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  minHeight: 'calc(100vh - 64px)',
  paddingTop: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(3),
  },
}));

const StyledBox = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}));

const WizardStep = ({
  activeStep,
  activeSubStep,
  children,
  isComplete,
  isLoadingNext,
  onNext,
  onPrevious,
  onComplete,
  nextLabel,
  showNavigation = true,
  stepCount,
  stepIndex,
  subSteps,
  title,
}) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const subStepProps = subSteps ? subSteps[activeSubStep] : null;
  const stepIdentifier = subStepProps
    ? `${title}: ${subStepProps.title}`
    : title;
  const currentStep = subStepProps ? activeStep + activeSubStep : activeStep;

  useEffect(() => {
    if (isComplete) {
      onComplete();
    }
  }, []);

  return (
    <Fade in={true}>
      <StyledContainer>
        <StyledBox>
          {isLargeScreen && (
            <Heading variant="h1" color="tertiary">
              {title}
            </Heading>
          )}
          {children && (
            <Box mt={isLargeScreen ? 4 : 2}>
              {cloneElement(children, {
                activeStep,
                title,
                isLoadingNext,
                onNext,
                stepCount,
                stepIndex,
                ...subStepProps,
              })}
            </Box>
          )}
          {showNavigation && (
            <Stack direction="row" sx={{ mt: 4 }}>
              <WizardNavigation
                activeStep={currentStep}
                isLoadingNext={isLoadingNext}
                onNext={onNext}
                onPrevious={activeStep > 0 ? onPrevious : null}
                onComplete={isComplete ? onComplete : null}
                nextLabel={nextLabel}
                stepCount={stepCount}
                stepIndex={stepIndex}
                title={stepIdentifier}
              />
            </Stack>
          )}
        </StyledBox>
      </StyledContainer>
    </Fade>
  );
};

export default WizardStep;
