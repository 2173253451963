import { Subtitle } from '@/atoms/typography';
import { OverlayChartBar } from '@/molecules/chartElements';
import useScenarioActivities from '@/shared/hooks/useScenarioActivities';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Stack from '@mui/material/Stack';
import { percent } from '@pkg/utils/numbers';
import { DesignEntity } from '@/lib/enums';
import Box from '@/components/Box';
import TagContent from '@/components/DashboardContainer/enums/TagContent';
import RelatedActivityEntities from '@/components/DashboardContainer/organisms/RelatedActivityEntities';
import TagSummary from './TagSummary';

const TaggedActivityList = ({ inverse = false, tagId, showAll = true }) => {
  const { scenario } = useScenarioContext();
  const { activities, metrics, tags } = useScenarioActivities({
    tagList: [tagId],
    inverse,
  });

  const percentage = percent(metrics?.taggedHours, metrics?.totalHours);

  const tagContent = TagContent[tagId][scenario?.entity?.__type];
  const subtitle = tagContent.subtitle;
  const summary = tagContent.summary(percentage);

  return (
    <Stack justifyContent="flex-start" alignItems="flex-start" spacing={2}>
      {Boolean(metrics) && (
        <TagSummary
          tag={tags.get(tagId)}
          metrics={metrics}
          summary={inverse ? summary.inverse : summary.main}
        />
      )}
      {Boolean(activities?.length > 0) && showAll && (
        <Box sx={{ mt: 3, width: '100%' }}>
          <Subtitle>{inverse ? subtitle.inverse : subtitle.main}</Subtitle>
          <Stack width="100%">
            {activities.map((activity) => (
              <Box key={activity.uuid} sx={{ mt: 1.5 }}>
                <RelatedActivityEntities
                  key={activity.uuid}
                  activity={activity}
                  interactive={scenario?.entity?.__type === DesignEntity.GROUP}
                >
                  <OverlayChartBar
                    numerator={activity.__aggregateHours}
                    denominator={metrics.totalHours}
                    interactive={
                      scenario?.entity?.__type === DesignEntity.GROUP
                    }
                    title={activity.__description}
                  />
                </RelatedActivityEntities>
              </Box>
            ))}
          </Stack>
        </Box>
      )}
    </Stack>
  );
};

export default TaggedActivityList;
