import { Sort } from '@pkg/utils';

export default function getSummarised(tags, entityTagIds) {
  if (tags.list.length === 0 || !entityTagIds) {
    return [];
  }

  const filtered = entityTagIds
    .map((uuid) => tags.map.get(uuid))
    .filter(Boolean)
    .filter((tag) => tag.is_disabled === false)
    .sort(Sort.order());

  filtered.sort(Sort.order());

  const summarised = filtered.reduce((acc, tag) => {
    acc[tag.uuid] = {
      name: tag.name,
      color: tag.color,
    };

    return acc;
  }, {});

  return Object.values(summarised);
}
