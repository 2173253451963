import { useEffect, useState } from 'react';
import { Chip } from '@/atoms/chips';
import { SelectOption } from '@/atoms/inputs/Select';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const AddOption = ({ onClick, isFocus, onFocus, optionLabel, value }) => {
  const [option, setOption] = useState({ uuid: 'new', [optionLabel]: '' });

  const handleClick = (event) => {
    onClick(event, option);
  };

  const handleFocus = (event) => {
    onFocus(event, option);
  };

  useEffect(() => {
    if (!value) {
      return;
    }

    setOption({
      ...option,
      [optionLabel]: value,
    });
  }, [value]);

  useEffect(() => {
    if (isFocus) {
      handleFocus();
    }
  }, [isFocus]);

  return (
    <SelectOption
      isFocus={isFocus}
      onClick={handleClick}
      onFocus={handleFocus}
      tabIndex={0}
      value={option}
    >
      <Stack direction="row" spacing={1}>
        <Box>
          <Chip color="secondary" id="add" name="Create" size="small" />
        </Box>
        <Box variant="span">{option[optionLabel]}</Box>
      </Stack>
    </SelectOption>
  );
};

export default AddOption;
