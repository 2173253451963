import { brand } from '@/lib/theme/tokens/palettes';
import BaseColor from './Base';

const FormColor = Object.freeze({
  primary: {
    normal: {
      borderColor: '#B1BCD0',
      color: BaseColor.font.primary,
    },
    hover: {
      borderColor: '#A3ADBF',
      color: BaseColor.font.primary,
    },
    focus: {
      borderColor: '#A3ADBF',
      color: BaseColor.font.primary,
    },
  },
  secondary: {
    normal: {
      borderColor: brand.grey.tints[3],
      color: BaseColor.font.primary,
    },
    hover: {
      borderColor: brand.grey.tints[5],
      color: BaseColor.font.primary,
    },
    focus: {
      borderColor: brand.grey.tints[5],
      color: BaseColor.font.primary,
    },
  },
});

export default FormColor;
