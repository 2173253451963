import { useMemo } from 'react';
import { DimensionType } from '@/molecules/tableElements';
import {
  getTagDimensionEntityType,
  formatActivityTableData,
  formatGroupTableData,
  formatRoleTableData,
  formatSkillTableData,
  formatTagActivityTableData,
  formatTagGroupTableData,
  formatTagRoleTableData,
} from '@/organisms/tables/utils';
import {
  usePropertyContext,
  useScenarioContext,
  useSkillContext,
  useTagContext,
} from '@/shared/providers';
import { useViewModeContext } from '@/shared/providers';
import { useAccess } from '@pkg/access/organisations';

const useRowData = (dimension) => {
  const scenario = useScenarioContext();
  const { peopleSkillMap, skillMap } = useSkillContext();
  const { tagMap } = useTagContext();
  const { propertyMap } = usePropertyContext();
  const access = useAccess();
  const { showBudget } = useViewModeContext();

  return useMemo(() => {
    if (!scenario?.snapshotEntityMap?.size || !tagMap?.size) {
      return;
    }

    if (dimension === DimensionType.ACTIVITY) {
      return formatActivityTableData({
        showBudget: showBudget,
        propertyMap,
        scenarioMaps: [scenario?.snapshotEntityMap],
        skillMap,
        tagMap,
      });
    }

    const tagDimensionEntityType = getTagDimensionEntityType(dimension);
    if (tagDimensionEntityType) {
      if (dimension === DimensionType.ROLE_TAGS_ROLE) {
        return formatTagRoleTableData({
          showBudget: showBudget,
          entityType: tagDimensionEntityType,
          propertyMap,
          scenarioMaps: [scenario?.snapshotEntityMap],
          skillMap,
          tagMap,
        });
      }

      if (dimension === DimensionType.GROUP_TAGS_GROUP) {
        return formatTagGroupTableData({
          showBudget: showBudget,
          entityType: tagDimensionEntityType,
          propertyMap,
          scenarioMaps: [scenario?.snapshotEntityMap],
          skillMap,
          tagMap,
        });
      }
      return formatTagActivityTableData({
        showBudget: showBudget,
        entityType: tagDimensionEntityType,
        propertyMap,
        scenarioMaps: [scenario?.snapshotEntityMap],
        skillMap,
        tagMap,
      });
    }

    if (
      dimension === DimensionType.PEOPLE_SKILLS ||
      dimension === DimensionType.ROLE_SKILLS
    ) {
      return formatSkillTableData({
        showBudget: showBudget,
        dimension,
        peopleSkillMap,
        propertyMap,
        scenarioMaps: [scenario?.snapshotEntityMap],
        skillMap,
        tagMap,
      });
    }
    if (dimension === DimensionType.ROLE) {
      return formatRoleTableData({
        showBudget: showBudget,
        propertyMap,
        scenarioMaps: [scenario?.snapshotEntityMap],
        skillMap,
        tagMap,
      });
    }
    if (dimension === DimensionType.GROUP) {
      return formatGroupTableData({
        showBudget: showBudget,
        propertyMap,
        scenarioMaps: [scenario?.snapshotEntityMap],
        skillMap,
        tagMap,
      });
    }
  }, [
    showBudget,
    scenario?.snapshotEntityMap,
    JSON.stringify(scenario?.entity?.__visible_metrics),
    peopleSkillMap,
    propertyMap,
    skillMap,
    tagMap,
    dimension,
  ]);
};

export default useRowData;
