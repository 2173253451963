import { SkillLevel } from '@/lib/enums';
import { core } from '@/lib/theme/tokens/color';
import { brand } from '@/lib/theme/tokens/palettes';

const SkillColor = Object.freeze({
  [SkillLevel.BASIC]: {
    normal: {
      color: brand.blue.shades[4],
      backgroundColor: brand.blue.tints[5],
    },
    hover: {
      color: core.white,
      backgroundColor: brand.blue.main,
    },
  },
  [SkillLevel.INTERMEDIATE]: {
    normal: {
      color: core.white,
      backgroundColor: brand.blue.shades[2],
    },
    hover: {
      color: core.white,
      backgroundColor: brand.blue.shades[4],
    },
  },
  [SkillLevel.ADVANCED]: {
    normal: {
      color: core.white,
      backgroundColor: brand.blue.shades[4],
    },
    hover: {
      color: core.white,
      backgroundColor: brand.blue.shades[6],
    },
  },
});

export default SkillColor;
