import { Chip, Utils } from '@/atoms/chips';
import { Size } from '@/atoms/enums';

const TagChip = ({ color, expanded = false, size = Size.SMALL, ...props }) => {
  const chipColor = Utils.tagColor({ color });

  return (
    <Chip colorProps={chipColor} expanded={expanded} size={size} {...props} />
  );
};

export default TagChip;
