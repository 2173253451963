import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { AddScenario } from '@/organisms/forms';
import { ViewMode } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const PlanSelection = ({ id }) => {
  return (
    <Stack direction="row" justifyContent="center" pt={12} width="100%">
      <Box width="50%" sx={{ textAlign: 'center' }}>
        <Heading variant="h2" overrideStyles={{ fontWeight: 700 }}>
          Create a scenario and view your changes
        </Heading>
        <Paragraph size={Size.LARGE} overrideStyles={{ mt: 6 }}>
          When you've created a scenario, the changes you've made will be shown
          here.
        </Paragraph>
        <Box pt={6}>
          <AddScenario
            color="secondary"
            buttonSize={Size.X_LARGE}
            boldButtonText={true}
            view={ViewMode.DIAGRAM}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default PlanSelection;
