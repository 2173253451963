import { useMemo } from 'react';
import { useAccess } from '@pkg/access/designs/bulk';
import { Sort } from '@pkg/utils';
import { Action, DesignEntity, Property } from '@/lib/enums';
import * as Designs from '../designs';

const deriveDesign = (design) => {
  const __group = design.is_scenario
    ? `${design.name} scenario`
    : 'Live design';

  return design.latest.snapshot.entities.groups.map((group) => ({
    value: `${design.uuid}:${group.uuid}`,
    label: group.name,
    __design_uuid: design.uuid,
    __group_uuid: group.uuid,
    __group,
  }));
};

/**
 * @param {Boolean} isAdmin
 * @param {Object} designId
 * @param {Object} groupId
 * @returns {Object[]|null}
 */
export default function useCopyToGroupTargets(isAdmin, designId, groupId) {
  const { hydrated, isHydrating } = Designs.useHydrated();

  const action = {
    entityType: DesignEntity.GROUP,
    property: Property.ROLES,
    action: Action.ADD,
  };
  const designs = isHydrating
    ? null
    : [hydrated.main, ...hydrated.scenarios.list];
  const permissions = useAccess(designs, [action]);

  return useMemo(() => {
    if (isHydrating) {
      return {
        options: [],
        isLoading: true,
      };
    }

    const { main, scenarios } = hydrated;
    const optionList = [deriveDesign(main), ...scenarios.list.map(deriveDesign)]
      .flat()
      .sort(Sort.alpha('label'));

    const options = optionList.filter((option) => {
      const isSameDesign = option.__design_uuid === designId;
      const isSameGroup = option.__group_uuid === groupId;
      if (isSameDesign && isSameGroup) {
        return false; // can't copy to current design/group
      }

      return (
        isAdmin ||
        (Boolean(permissions[option.__design_uuid]) &&
          permissions[option.__design_uuid][option.__group_uuid])
      );
    });

    return {
      options,
      isLoading: false,
    };
  }, [designId, groupId, hydrated, isHydrating]);
}
