import { useEffect, useState } from 'react';
import { StackChartBar } from '@/molecules/chartElements';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { productivity, workHealth } from '@pkg/insights/calculations';
import { BeamibleInsight, BeamibleTag, DesignEntity } from '@/lib/enums';
import BeamibleScoreColor from '@/lib/theme/beamibleScoreColor';
import Box from '@/components/Box';
import Typography from '@/components/Typography';

const Calculation = Object.freeze({
  [BeamibleInsight.PRODUCTIVITY]: productivity,
  [BeamibleInsight.WORK_HEALTH]: workHealth,
});

const getScoreColor = (score, tag) => {
  const colors = BeamibleScoreColor;

  if (tag) {
    switch (tag) {
      case BeamibleTag.INTERNAL_MEETINGS:
      case BeamibleTag.ADMINISTRATIVE:
        score = 100 - score;
        break;
      default:
    }
  }

  let color = colors.MEDIUM;
  if (score > 66 && score <= 100) {
    color = colors.HIGH;
  }
  if (score <= 33 || score > 120) {
    color = colors.LOW;
  }

  return color;
};

const getScoreTitle = (score, scoreType, entityType) => {
  let title;

  switch (entityType) {
    case DesignEntity.GROUP:
      title = `Your team's ${scoreType}`;
      break;
    case DesignEntity.DESIGN:
      title = `Your company ${scoreType}`;
      break;
    default:
      title = `Your ${scoreType}`;
  }

  if ((score >= 33 && score <= 66) || score > 100) {
    title += ' is OK!';
  }

  if (score < 33 || score > 120) {
    title += ' needs improvement!';
  }

  if (score > 66 && score <= 100) {
    title += ' is great!';
  }

  return title;
};

const ScoreList = ({ scoreType }) => {
  const { scenario } = useScenarioContext();
  const [scores, setScores] = useState();
  const [title, setTitle] = useState();
  const [scoreColor, setScoreColor] = useState();

  useEffect(() => {
    if (!scenario?.entity) {
      return;
    }

    let calculatedScores;
    if (scenario?.entity?.__type === DesignEntity.ROLE) {
      calculatedScores = Calculation[scoreType]([scenario?.entity]);
    } else {
      calculatedScores = Calculation[scoreType](
        Array.from(scenario?.relationships?.get(DesignEntity.ROLE).values())
      );
    }

    setScores(calculatedScores);
    setTitle(
      getScoreTitle(
        calculatedScores.score,
        BeamibleInsight.label(scoreType),
        scenario?.entity?.__type
      )
    );
    setScoreColor(getScoreColor(calculatedScores.score));
  }, [
    scenario?.details?.designId,
    JSON.stringify(scenario?.entity?.__visible_metrics),
  ]);

  return (
    Boolean(scores) && (
      <Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          sx={{
            pb: 1,
          }}
        >
          <Box
            sx={{
              backgroundColor: scoreColor?.foreground,
              borderRadius: '25px',
              boxShadow: `0 0 0 4px ${scoreColor?.foreground}38`,
              height: '25px',
              m: '8px',
              width: '25px',
            }}
          />
          <Typography
            variant="h5"
            sx={{
              fontSize: '1rem',
              lineHeight: '1.5rem',
              fontWeight: 400,
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Divider sx={{ mb: 3, opacity: 0.4 }} />
        <Box>
          {Object.entries(scores.tags).map(([key, tag], index) => (
            <Box
              key={`${key}:${tag.hoursPercentage}`}
              sx={{
                mt: index === 0 ? 0 : 1.5,
              }}
            >
              <StackChartBar
                colors={getScoreColor(tag.hoursPercentage, key)}
                title={tag.name}
                denominator={100}
                numerator={tag.hoursPercentage}
              />
            </Box>
          ))}
        </Box>
      </Box>
    )
  );
};

export default ScoreList;
