import { Avatar } from '@/atoms/avatars';
import { Button } from '@/atoms/buttons';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { core } from '@/lib/theme/tokens/palettes';

const HierarchyEntityCard = ({
  entityType,
  id,
  label,
  onClick,
  isActive,
  status,
  user,
}) => {
  const userName = user ? `${user.first_name} ${user.last_name}` : null;

  const handleOnClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    onClick?.();
  };

  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-bewteen"
      spacing={2}
      pl={status.expandable ? 0.5 : 2}
      sx={{
        position: 'relative',
      }}
    >
      <Stack alignItems="center" direction="row" spacing={1}>
        {user && (
          <Avatar
            src={user.avatar}
            name={userName}
            sizeProps={{
              fontSize: '1rem',
              width: '30px',
              height: '30px',
            }}
          />
        )}
        <Box>
          <Stack
            alignItems="center"
            direction="row"
            spacing={0.5}
            mt={user ? 0.25 : 0}
          >
            <EntityChip type={entityType} size={Size.XX_SMALL} />
            {user && (
              <Paragraph
                size={Size.MEDIUM}
                overrideStyles={{
                  color: isActive ? core.white : 'inherit',
                  textShadow: isActive ? '0 1px 1px rgba(0,0,0,0.2)' : 'none',
                  mb: 0,
                  fontSize: '0.875rem',
                  lineHeight: '0.925rem',
                }}
              >
                {label}
              </Paragraph>
            )}
          </Stack>
          <Paragraph
            size={Size.SMALL}
            overrideStyles={{
              color: isActive ? core.white : 'inherit',
              mb: 0,
              mt: user ? 0.5 : 0.55,
              fontSize: user ? '0.725rem' : '0.875rem',
              lineHeight: user ? '0.725rem' : '0.875rem',
              textShadow: isActive ? '0 1px 1px rgba(0,0,0,0.2)' : 'none',
            }}
          >
            {userName ?? label}
          </Paragraph>
        </Box>
      </Stack>
      {!isActive && (
        <Box
          className="view-button"
          position="absolute"
          top={2.5}
          right={0}
          sx={{
            transition: 'opacity 200ms',
            opacity: 0,
            pointerEvents: 'none',
          }}
        >
          <Button
            boldText
            label="View"
            color="secondary"
            onClick={handleOnClick}
            size={Size.X_SMALL}
          />
        </Box>
      )}
    </Stack>
  );
};

export default HierarchyEntityCard;
