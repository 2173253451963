import { useCallback, useEffect, useState } from 'react';
import env from '@beam-australia/react-env';
import { useDatabase } from '@pkg/database';

export default function useReset() {
  const database = useDatabase();
  const [isResetting, setResetting] = useState(false);

  const reset = useCallback(() => {
    if (!isResetting) {
      setResetting(true);
    }
  }, []);

  const handleReset = async () => {
    try {
      await database.reset();
    } catch (error) {
      console.error('Error resetting database: ', error);
      await database.hash.clear();
    }

    const buildId = env('PLATFORM_VER') ? env('PLATFORM_VER') : 'development';
    await database.build.put({
      key: 'build',
      id: buildId,
    });

    setResetting(false);
  };

  useEffect(() => {
    if (isResetting) {
      handleReset();
    }
  }, [isResetting]);

  return {
    reset,
    handleReset,
    isResetting,
  };
}
