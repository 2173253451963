import { useMemo } from 'react';
import * as Auth from '@pkg/auth';
import * as Get from './get';

const DEFAULT_STATE = Object.freeze({
  isRestricted: true,
  features: {},
  model: {
    budget: false, // can see budgets
    edit: false, // can modify organisation
    manage: false, // can manage organisation
  },
});

/**
 * @returns {Object}
 */
export default function useAccess() {
  const isAuthenticated = Auth.useStore((state) => state.isAuthenticated);
  const organisation = Auth.useStore((state) => state.organisation);
  const me = Auth.useStore((state) => state.me);
  const assignedFeatures = Auth.useStore((state) => state.features);

  return useMemo(() => {
    if (!isAuthenticated) {
      return DEFAULT_STATE;
    }

    const permissions = Get.permissions(organisation.uuid);
    const model = Get.model(me, permissions);
    const features = Get.features(assignedFeatures);

    return Object.freeze({
      isRestricted: organisation.is_restricted,
      features,
      model,
    });
  }, [me, organisation, isAuthenticated]);
}
