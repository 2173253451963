/**
 * @param {String} input
 * @param {String|Object} pattern
 * @returns {String}
 */
function sanitize(input = '', pattern) {
  if (!pattern) {
    return input;
  }

  try {
    const inputPattern =
      typeof pattern === 'object' ? pattern.toString() : pattern;
    const isExec = inputPattern.includes('{');
    const regexp = new RegExp(pattern, 'g');
    return isExec
      ? sanitizeExec(input, regexp)
      : sanitizeMatches(input, regexp);
  } catch (e) {
    return input;
  }
}

/**
 * @param {String} input
 * @param {Object} regexp
 * @returns {String}
 */
function sanitizeMatches(input, regexp) {
  const matches = input.match(regexp, '');
  return matches?.join('') ?? '';
}

/**
 * @param {String} input
 * @param {Object} regexp
 * @returns {String}
 */
function sanitizeExec(input, regexp) {
  const matches = regexp.exec(input);
  return matches[0] ?? '';
}

export default sanitize;
