import * as Organisations from '../../organisations';
import * as Can from '../can';

/**
 * @param {Object} me
 * @param {Object} permissions
 * @param {Boolean} isScenario
 * @param {Boolean} isHistorical
 * @returns {Object}
 */
export default function getModel(me, permissions, isScenario, isHistorical) {
  return {
    budget: Organisations.can.budget(me, permissions),
    edit: Can.edit(me, permissions),
    manage: Organisations.can.manage(me, permissions),
    publish: Can.publish(me, permissions, isScenario),
    restore: Can.restore(me, permissions, isScenario, isHistorical),
  };
}
