import { sanitize } from 'dompurify';
import Alert from '@mui/material/Alert';

const StatusMessage = ({
  ok = false,
  code = 0,
  errorMessage = '',
  successMessage = '',
  responseMessage = '',
}) => {
  if (ok === false && code >= 500) {
    return (
      <Alert data-testid="status-message" severity="error">
        We are currently down for maintenance. Try again later.
      </Alert>
    );
  }

  const overrideText = ok ? successMessage : errorMessage;

  const text = overrideText ? overrideText : responseMessage;

  const severity = ok ? 'success' : 'error';

  if (!text) {
    return null;
  }

  return (
    <Alert severity={severity}>
      <span
        data-testid="status-message"
        dangerouslySetInnerHTML={{ __html: sanitize(text) }}
      />
    </Alert>
  );
};

export default StatusMessage;
