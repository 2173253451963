import { core, text } from './tokens/color';
import {
  blue,
  cyan,
  green,
  grey,
  lilac,
  orange,
  pink,
  purple,
  red,
  yellow,
} from './tokens/palettes';

export default {
  blue: {
    regular: {
      active: {
        base: {
          iconFill: blue.colors[700],
          iconStroke: text[blue.contrast[700]],
          fill: blue.colors[600],
          outline: blue.colors[600],
          text: text[blue.contrast[600]],
        },
        focus: {
          fill: blue.colors[300],
          outline: blue.colors[600],
          text: text[blue.contrast[300]],
        },
        hover: {
          iconFill: blue.colors[900],
          iconStroke: text[blue.contrast[900]],
          fill: blue.colors[600],
          outline: blue.colors[600],
          text: text[blue.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: blue.colors[600],
          iconStroke: text[blue.contrast[600]],
          fill: core.white,
          outline: blue.colors[600],
          text: text.dark,
        },
        focus: {
          fill: blue.colors[300],
          outline: blue.colors[600],
          text: text[blue.contrast[300]],
        },
        hover: {
          iconFill: blue.colors[700],
          iconStroke: text[blue.contrast[700]],
          fill: blue.colors[400],
          outline: blue.colors[600],
          text: text[blue.contrast[400]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: blue.colors[500],
          iconStroke: text[blue.contrast[500]],
          fill: blue.colors[300],
          outline: blue.colors[300],
          text: text[blue.contrast[300]],
        },
        focus: {
          fill: blue.colors[100],
          outline: blue.colors[300],
          text: text[blue.contrast[100]],
        },
        hover: {
          iconFill: blue.colors[600],
          iconStroke: text[blue.contrast[600]],
          fill: blue.colors[300],
          focusText: text[blue.contrast[100]],
          outline: blue.colors[300],
          text: text[blue.contrast[300]],
        },
      },
      inactive: {
        base: {
          iconFill: blue.colors[300],
          iconStroke: text[blue.contrast[300]],
          fill: core.white,
          outline: blue.colors[300],
          text: text.dark,
        },
        focus: {
          fill: blue.colors[100],
          outline: blue.colors[300],
          text: text[blue.contrast[100]],
        },
        hover: {
          iconFill: blue.colors[400],
          iconStroke: text[blue.contrast[400]],
          fill: blue.colors[100],
          outline: blue.colors[300],
          text: text[blue.contrast[100]],
        },
      },
    },
  },
  cyan: {
    regular: {
      active: {
        base: {
          iconFill: cyan.colors[700],
          iconStroke: text[cyan.contrast[700]],
          fill: cyan.colors[600],
          outline: cyan.colors[600],
          text: text[cyan.contrast[600]],
        },
        focus: {
          fill: cyan.colors[300],
          outline: cyan.colors[600],
          text: text[cyan.contrast[300]],
        },
        hover: {
          iconFill: cyan.colors[900],
          iconStroke: text[cyan.contrast[900]],
          fill: cyan.colors[600],
          outline: cyan.colors[600],
          text: text[cyan.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: cyan.colors[600],
          iconStroke: text[cyan.contrast[600]],
          fill: core.white,
          outline: cyan.colors[600],
          text: text.dark,
        },
        focus: {
          fill: cyan.colors[300],
          outline: cyan.colors[600],
          text: text[cyan.contrast[300]],
        },
        hover: {
          iconFill: cyan.colors[700],
          iconStroke: text[cyan.contrast[700]],
          fill: cyan.colors[400],
          outline: cyan.colors[600],
          text: text[cyan.contrast[400]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: cyan.colors[500],
          iconStroke: text[cyan.contrast[500]],
          fill: cyan.colors[300],
          outline: cyan.colors[300],
          text: text[cyan.contrast[300]],
        },
        focus: {
          fill: cyan.colors[100],
          outline: cyan.colors[300],
          text: text[cyan.contrast[100]],
        },
        hover: {
          iconFill: cyan.colors[700],
          iconStroke: text[cyan.contrast[700]],
          fill: cyan.colors[200],
          outline: cyan.colors[200],
          text: text[cyan.contrast[200]],
        },
      },
      inactive: {
        base: {
          iconFill: cyan.colors[300],
          iconStroke: text[cyan.contrast[300]],
          fill: core.white,
          outline: cyan.colors[300],
          text: text.dark,
        },
        focus: {
          fill: cyan.colors[100],
          outline: cyan.colors[300],
          text: text[cyan.contrast[100]],
        },
        hover: {
          iconFill: cyan.colors[500],
          iconStroke: text[cyan.contrast[500]],
          fill: cyan.colors[100],
          outline: cyan.colors[300],
          text: text[cyan.contrast[100]],
        },
      },
    },
  },
  green: {
    regular: {
      active: {
        base: {
          iconFill: green.colors[700],
          iconStroke: text[green.contrast[700]],
          fill: green.colors[600],
          outline: green.colors[600],
          text: text[green.contrast[600]],
        },
        focus: {
          fill: green.colors[300],
          outline: green.colors[600],
          text: text[green.contrast[300]],
        },
        hover: {
          iconFill: green.colors[900],
          iconStroke: text[green.contrast[900]],
          fill: green.colors[600],
          outline: green.colors[600],
          text: text[green.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: green.colors[600],
          iconStroke: text[green.contrast[600]],
          fill: core.white,
          outline: green.colors[600],
          text: text.dark,
        },
        focus: {
          fill: green.colors[300],
          outline: green.colors[600],
          text: text[green.contrast[300]],
        },
        hover: {
          iconFill: green.colors[700],
          iconStroke: text[green.contrast[700]],
          fill: green.colors[500],
          outline: green.colors[600],
          text: text[green.contrast[500]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: green.colors[500],
          iconStroke: text[green.contrast[500]],
          fill: green.colors[200],
          outline: green.colors[200],
          text: text[green.contrast[200]],
        },
        focus: {
          fill: green.colors[100],
          outline: green.colors[200],
          text: text[green.contrast[100]],
        },
        hover: {
          iconFill: green.colors[700],
          iconStroke: text[green.contrast[700]],
          fill: green.colors[200],
          outline: green.colors[200],
          text: text[green.contrast[200]],
        },
      },
      inactive: {
        base: {
          iconFill: green.colors[200],
          iconStroke: text[green.contrast[200]],
          fill: core.white,
          outline: green.colors[200],
          text: text.dark,
        },
        focus: {
          fill: green.colors[100],
          outline: green.colors[200],
          text: text[green.contrast[100]],
        },
        hover: {
          iconFill: green.colors[300],
          iconStroke: text[green.contrast[300]],
          fill: green.colors[100],
          outline: green.colors[200],
          text: text[green.contrast[100]],
        },
      },
    },
  },
  grey: {
    regular: {
      active: {
        base: {
          iconFill: grey.colors[700],
          iconStroke: text[grey.contrast[700]],
          fill: grey.colors[600],
          outline: grey.colors[600],
          text: text[grey.contrast[600]],
        },
        focus: {
          fill: grey.colors[300],
          outline: grey.colors[600],
          text: text[grey.contrast[300]],
        },
        hover: {
          iconFill: grey.colors[900],
          iconStroke: text[grey.contrast[900]],
          fill: grey.colors[600],
          outline: grey.colors[600],
          text: text[grey.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: grey.colors[400],
          iconStroke: text[grey.contrast[400]],
          fill: core.white,
          outline: grey.colors[400],
          text: text.dark,
        },
        focus: {
          fill: grey.colors[300],
          outline: grey.colors[400],
          text: text[grey.contrast[300]],
        },
        hover: {
          iconFill: grey.colors[600],
          iconStroke: text[grey.contrast[600]],
          fill: grey.colors[300],
          outline: grey.colors[400],
          text: text[grey.contrast[300]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: grey.colors[400],
          iconStroke: text[grey.contrast[400]],
          fill: grey.colors[200],
          outline: grey.colors[200],
          text: text[grey.contrast[200]],
        },
        focus: {
          fill: grey.colors[100],
          outline: grey.colors[200],
          text: text[grey.contrast[100]],
        },
        hover: {
          iconFill: grey.colors[600],
          iconStroke: text[grey.contrast[600]],
          fill: grey.colors[200],
          outline: grey.colors[200],
          text: text[grey.contrast[200]],
        },
      },
      inactive: {
        base: {
          iconFill: grey.colors[200],
          iconStroke: text[grey.contrast[200]],
          fill: core.white,
          outline: grey.colors[200],
          text: text.dark,
        },
        focus: {
          fill: grey.colors[100],
          outline: grey.colors[200],
          text: text[grey.contrast[100]],
        },
        hover: {
          iconFill: grey.colors[300],
          iconStroke: text[grey.contrast[300]],
          fill: grey.colors[100],
          outline: grey.colors[200],
          text: text[grey.contrast[100]],
        },
      },
    },
  },
  lilac: {
    regular: {
      active: {
        base: {
          iconFill: lilac.colors[700],
          iconStroke: text[lilac.contrast[700]],
          fill: lilac.colors[600],
          outline: lilac.colors[600],
          text: text[lilac.contrast[600]],
        },
        focus: {
          fill: lilac.colors[300],
          outline: lilac.colors[600],
          text: text[lilac.contrast[300]],
        },
        hover: {
          iconFill: lilac.colors[900],
          iconStroke: text[lilac.contrast[900]],
          fill: lilac.colors[600],
          outline: lilac.colors[600],
          text: text[lilac.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: lilac.colors[400],
          iconStroke: text[lilac.contrast[400]],
          fill: core.white,
          outline: lilac.colors[400],
          text: text.dark,
        },
        focus: {
          fill: lilac.colors[300],
          outline: lilac.colors[400],
          text: text[lilac.contrast[300]],
        },
        hover: {
          iconFill: lilac.colors[500],
          iconStroke: text[lilac.contrast[500]],
          fill: lilac.colors[300],
          outline: lilac.colors[400],
          text: text[lilac.contrast[300]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: lilac.colors[500],
          iconStroke: text[lilac.contrast[500]],
          fill: lilac.colors[300],
          outline: lilac.colors[300],
          text: text[lilac.contrast[300]],
        },
        focus: {
          fill: lilac.colors[100],
          outline: lilac.colors[300],
          text: text[lilac.contrast[100]],
        },
        hover: {
          iconFill: lilac.colors[700],
          iconStroke: text[lilac.colors[700]],
          fill: lilac.colors[300],
          outline: lilac.colors[300],
          text: text[lilac.contrast[300]],
        },
      },
      inactive: {
        base: {
          iconFill: lilac.colors[200],
          iconStroke: text[lilac.colors[200]],
          fill: core.white,
          outline: lilac.colors[200],
          text: text.dark,
        },
        focus: {
          fill: lilac.colors[100],
          outline: lilac.colors[200],
          text: text[lilac.contrast[100]],
        },
        hover: {
          iconFill: lilac.colors[300],
          iconStroke: text[lilac.colors[300]],
          fill: lilac.colors[100],
          outline: lilac.colors[200],
          text: text[lilac.colors[100]],
        },
      },
    },
  },
  orange: {
    regular: {
      active: {
        base: {
          iconFill: orange.colors[700],
          iconStroke: text[orange.contrast[700]],
          fill: orange.colors[600],
          outline: orange.colors[600],
          text: text[orange.contrast[600]],
        },
        focus: {
          fill: orange.colors[300],
          outline: orange.colors[600],
          text: text[orange.contrast[300]],
        },
        hover: {
          iconFill: orange.colors[900],
          iconStroke: text[orange.contrast[900]],
          fill: orange.colors[600],
          outline: orange.colors[600],
          text: text[orange.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: orange.colors[600],
          iconStroke: text[orange.contrast[600]],
          fill: core.white,
          outline: orange.colors[600],
          text: text.dark,
        },
        focus: {
          fill: orange.colors[300],
          outline: orange.colors[600],
          text: text[orange.contrast[300]],
        },
        hover: {
          iconFill: orange.colors[700],
          iconStroke: text[orange.contrast[700]],
          fill: orange.colors[400],
          outline: orange.colors[600],
          text: text[orange.contrast[400]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: orange.colors[400],
          iconStroke: text[orange.colors[400]],
          fill: orange.colors[200],
          outline: orange.colors[200],
          text: text[orange.contrast[200]],
        },
        focus: {
          fill: orange.colors[100],
          outline: orange.colors[200],
          text: text[orange.contrast[100]],
        },
        hover: {
          iconFill: orange.colors[600],
          iconStroke: text[orange.contrast[600]],
          fill: orange.colors[200],
          outline: orange.colors[200],
          text: text[orange.contrast[200]],
        },
      },
      inactive: {
        base: {
          iconFill: orange.colors[400],
          iconStroke: text[orange.contrast[400]],
          fill: core.white,
          outline: orange.colors[400],
          text: text.dark,
        },
        focus: {
          fill: orange.colors[100],
          outline: orange.colors[400],
          text: text[orange.contrast[100]],
        },
        hover: {
          iconFill: orange.colors[500],
          iconStroke: text[orange.contrast[500]],
          fill: orange.colors[200],
          outline: orange.colors[400],
          text: text[orange.contrast[200]],
        },
      },
    },
  },
  pink: {
    regular: {
      active: {
        base: {
          iconFill: pink.colors[700],
          iconStroke: text[pink.contrast[700]],
          fill: pink.colors[600],
          outline: pink.colors[600],
          text: text[pink.contrast[600]],
        },
        focus: {
          fill: pink.colors[300],
          outline: pink.colors[600],
          text: text[pink.contrast[300]],
        },
        hover: {
          iconFill: pink.colors[900],
          iconStroke: text[pink.colors[900]],
          fill: pink.colors[600],
          outline: pink.colors[600],
          text: text[pink.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: pink.colors[600],
          iconStroke: text[pink.contrast[600]],
          fill: core.white,
          outline: pink.colors[600],
          text: text.dark,
        },
        focus: {
          fill: pink.colors[300],
          outline: pink.colors[600],
          text: text[pink.contrast[300]],
        },
        hover: {
          iconFill: pink.colors[700],
          iconStroke: text[pink.contrast[700]],
          fill: pink.colors[500],
          outline: pink.colors[600],
          text: text[pink.contrast[500]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: pink.colors[500],
          iconStroke: text[pink.contrast[500]],
          fill: pink.colors[300],
          outline: pink.colors[300],
          text: text[pink.contrast[300]],
        },
        focus: {
          fill: pink.colors[100],
          outline: pink.colors[300],
          text: text[pink.contrast[300]],
        },
        hover: {
          iconFill: pink.colors[600],
          iconStroke: text[pink.colors[600]],
          fill: pink.colors[200],
          outline: pink.colors[200],
          text: text[pink.contrast[200]],
        },
      },
      inactive: {
        base: {
          iconFill: pink.colors[400],
          iconStroke: text[pink.contrast[400]],
          fill: core.white,
          outline: pink.colors[400],
          text: text.dark,
        },
        focus: {
          fill: pink.colors[100],
          outline: pink.colors[400],
          text: text[pink.contrast[100]],
        },
        hover: {
          iconFill: pink.colors[500],
          iconStroke: text[pink.contrast[500]],
          fill: pink.colors[200],
          outline: pink.colors[400],
          text: text[pink.contrast[200]],
        },
      },
    },
  },
  purple: {
    regular: {
      active: {
        base: {
          iconFill: purple.colors[700],
          iconStroke: text[purple.contrast[700]],
          fill: purple.colors[600],
          outline: purple.colors[600],
          text: text[purple.contrast[600]],
        },
        focus: {
          fill: purple.colors[300],
          outline: purple.colors[600],
          text: text[purple.contrast[300]],
        },
        hover: {
          iconFill: purple.colors[900],
          iconStroke: text[purple.contrast[900]],
          fill: purple.colors[600],
          outline: purple.colors[600],
          text: text[purple.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: purple.colors[600],
          iconStroke: text[purple.contrast[600]],
          fill: core.white,
          outline: purple.colors[600],
          text: text.dark,
        },
        focus: {
          fill: purple.colors[300],
          outline: purple.colors[600],
          text: text[purple.contrast[300]],
        },
        hover: {
          iconFill: purple.colors[700],
          iconStroke: text[purple.contrast[700]],
          fill: purple.colors[400],
          outline: purple.colors[600],
          text: text[purple.contrast[400]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: purple.colors[500],
          iconStroke: text[purple.contrast[500]],
          fill: purple.colors[300],
          outline: purple.colors[300],
          text: text[purple.contrast[300]],
        },
        focus: {
          fill: purple.colors[100],
          outline: purple.colors[300],
          text: text[purple.contrast[100]],
        },
        hover: {
          iconFill: purple.colors[500],
          iconStroke: text[purple.colors[500]],
          fill: purple.colors[100],
          outline: purple.colors[100],
          text: text[purple.contrast[100]],
        },
      },
      inactive: {
        base: {
          iconFill: purple.colors[300],
          iconStroke: text[purple.contrast[300]],
          fill: core.white,
          outline: purple.colors[300],
          text: text.dark,
        },
        focus: {
          fill: purple.colors[100],
          outline: purple.colors[300],
          text: text[purple.contrast[100]],
        },
        hover: {
          iconFill: purple.colors[500],
          iconStroke: text[purple.contrast[500]],
          fill: purple.colors[100],
          outline: purple.colors[300],
          text: text[purple.contrast[100]],
        },
      },
    },
  },
  red: {
    regular: {
      active: {
        base: {
          iconFill: red.colors[700],
          iconStroke: text[red.contrast[700]],
          fill: red.colors[600],
          outline: red.colors[600],
          text: text[red.contrast[600]],
        },
        focus: {
          fill: red.colors[300],
          outline: red.colors[600],
          text: text[red.contrast[300]],
        },
        hover: {
          iconFill: red.colors[900],
          iconStroke: text[red.contrast[900]],
          fill: red.colors[600],
          outline: red.colors[600],
          text: text[red.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: red.colors[600],
          iconStroke: text[red.contrast[600]],
          fill: core.white,
          outline: red.colors[600],
          text: text.dark,
        },
        focus: {
          fill: red.colors[300],
          outline: red.colors[600],
          text: text[red.contrast[300]],
        },
        hover: {
          iconFill: red.colors[700],
          iconStroke: text[red.contrast[700]],
          fill: red.colors[400],
          outline: red.colors[600],
          text: text[red.contrast[500]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: red.colors[500],
          iconStroke: text[red.contrast[500]],
          fill: red.colors[300],
          outline: red.colors[300],
          text: text[red.contrast[300]],
        },
        focus: {
          fill: red.colors[100],
          outline: red.colors[300],
          text: text[red.contrast[100]],
        },
        hover: {
          iconFill: red.colors[700],
          iconStroke: text[red.contrast[700]],
          fill: red.colors[300],
          outline: red.colors[300],
          text: text[red.contrast[300]],
        },
      },
      inactive: {
        base: {
          iconFill: red.colors[300],
          iconStroke: text[red.contrast[300]],
          fill: core.white,
          outline: red.colors[300],
          text: text.dark,
        },
        focus: {
          fill: red.colors[100],
          outline: red.colors[300],
          text: text[red.contrast[100]],
        },
        hover: {
          iconFill: red.colors[500],
          iconStroke: text[red.contrast[500]],
          fill: red.colors[100],
          outline: red.colors[300],
          text: text[red.contrast[100]],
        },
      },
    },
  },
  yellow: {
    regular: {
      active: {
        base: {
          iconFill: yellow.colors[700],
          iconStroke: text[yellow.contrast[700]],
          fill: yellow.colors[600],
          outline: yellow.colors[600],
          text: text[yellow.contrast[600]],
        },
        focus: {
          fill: yellow.colors[300],
          outline: yellow.colors[600],
          text: text[yellow.contrast[300]],
        },
        hover: {
          iconFill: yellow.colors[900],
          iconStroke: text[yellow.contrast[900]],
          fill: yellow.colors[600],
          outline: yellow.colors[600],
          text: text[yellow.contrast[600]],
        },
      },
      inactive: {
        base: {
          iconFill: yellow.colors[700],
          iconStroke: text[yellow.contrast[700]],
          fill: core.white,
          outline: yellow.colors[700],
          text: text.dark,
        },
        focus: {
          fill: yellow.colors[300],
          outline: yellow.colors[700],
          text: text[yellow.contrast[300]],
        },
        hover: {
          iconFill: yellow.colors[800],
          iconStroke: text[yellow.contrast[800]],
          fill: yellow.colors[500],
          outline: yellow.colors[700],
          text: text[yellow.contrast[500]],
        },
      },
    },
    light: {
      active: {
        base: {
          iconFill: yellow.colors[500],
          iconStroke: text[yellow.colors[500]],
          fill: yellow.colors[300],
          outline: yellow.colors[300],
          text: text[yellow.contrast[300]],
        },
        focus: {
          fill: yellow.colors[100],
          outline: yellow.colors[300],
          text: text[yellow.contrast[100]],
        },
        hover: {
          iconFill: yellow.colors[600],
          iconStroke: text[yellow.contrast[600]],
          fill: yellow.colors[300],
          outline: yellow.colors[300],
          text: text[yellow.contrast[300]],
        },
      },
      inactive: {
        base: {
          iconFill: yellow.colors[400],
          iconStroke: text[yellow.contrast[400]],
          fill: core.white,
          outline: yellow.colors[400],
          text: text.dark,
        },
        focus: {
          fill: yellow.colors[100],
          outline: yellow.colors[400],
          text: text[yellow.contrast[100]],
        },
        hover: {
          iconFill: yellow.colors[500],
          iconStroke: text[yellow.contrast[500]],
          fill: yellow.colors[200],
          outline: yellow.colors[400],
          text: text[yellow.contrast[200]],
        },
      },
    },
  },
};
