import { useMutation } from '@pkg/graphql';

const UPDATE_ORGANISATION = /* GraphQL */ `
  mutation UpdateOrganisation($input: UpdateOrganisationInput!) {
    updateOrganisation(input: $input) {
      uuid
    }
  }
`;

/**
 * @param {any[]} query
 */
export default function useUpdate(query) {
  const { mutateAsync } = useMutation({
    mutation: UPDATE_ORGANISATION,
    invalidateQuery: query,
  });

  /**
   * @param {String} organisaton_uuid
   * @param {Object} input
   * @param {String} input.name
   * @param {Boolean} input.is_restricted
   * @returns {Object}
   */
  const update = (organisation_uuid, input) => {
    return mutateAsync({ organisation_uuid, ...input });
  };

  return update;
}
