import Head from 'next/head';
import { useState } from 'react';
import ReactCrop from 'react-image-crop';
import getCroppedImage from '../../lib/getCroppedImage';

const Crop = ({ src, aspect, onChange }) => {
  const [ref, setRef] = useState();
  const [crop, setCrop] = useState({
    x: 20,
    y: 20,
    width: 400,
    aspect,
  });

  const handleLoaded = (image) => {
    setRef(image);
  };

  const handleChange = (crop) => {
    setCrop(crop);
  };

  const handleCrop = async (crop) => {
    if (ref && crop.width && crop.height) {
      const blob = await getCroppedImage(ref, crop);
      onChange(blob);
    }
  };

  const handleComplete = (crop) => {
    handleCrop(crop);
  };

  return (
    <>
      <Head>
        <link
          rel="stylesheet"
          href="https://unpkg.com/react-image-crop/dist/ReactCrop.css"
        />
      </Head>
      <ReactCrop
        src={src}
        crop={crop}
        minWidth={100}
        onImageLoaded={handleLoaded}
        onChange={handleChange}
        onComplete={handleComplete}
      />
    </>
  );
};

export default Crop;
