import { useCallback } from 'react';
import reduceExisting from './reducers/groups/publishExisting';
import reduceNew from './reducers/groups/publishNew';
import useMutate from './useMutate';

/**
 * @return {Function}
 */
export default function usePublish() {
  const mutate = useMutate();

  /**
   * @param {String} design_uuid
   * @param {Boolean} isNew
   * @param {Object} input
   * @param {Object} input.fromSnapshot
   * @param {String} input.fromUuid
   * @param {Object} input.toSnapshot
   * @param {String} input.toUuid
   * @param {String} input.name
   * @returns {Promise}
   */
  async function publish(design_uuid, isNew, input) {
    const mutation = isNew ? reduceNew(input) : reduceExisting(input);
    return await mutate(design_uuid, mutation);
  }

  return useCallback(publish, [mutate]);
}
