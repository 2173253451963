import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const WIZARD_KEY = 'wizard-flow';

/** Identifies what number step we're beginning at 1.
 *
 * @param {Array} steps
 * @param {Number} activeStep
 * @param {Number} activeSubStep
 *
 * @return {Number}
 */
const getStepIndex = (steps, activeStep, activeSubStep) => {
  let stepIndex = 0;

  steps.forEach((step, index) => {
    // Skip counting steps if we're beyond the currently active step.
    if (index > activeStep) {
      return;
    }

    const subSteps = step.props.subSteps;
    let subStepCount = 0;

    // If we include substeps we reduce the count by one because the first
    // step and the first substep are one and the same.
    if (activeStep > index) {
      subStepCount = subSteps?.length ? subSteps.length - 1 : 0;
    }

    if (index === activeStep) {
      subStepCount = subSteps?.length ? activeSubStep : 0;
    }

    stepIndex += 1 + subStepCount;
  });

  return stepIndex;
};

const useWizardSteps = (steps) => {
  const { asPath: path } = useRouter();
  const [activeStep, setActiveStep] = useState(0);
  const [activeSubStep, setActiveSubStep] = useState(0);
  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    const flows = JSON.parse(localStorage.getItem(WIZARD_KEY));

    const existingSteps = flows?.[path];

    if (!existingSteps) {
      setActiveStep(0);
      setActiveSubStep(0);
      return;
    }

    setStepIndex(
      getStepIndex(steps, existingSteps.step, existingSteps.subStep)
    );
    setActiveStep(existingSteps.step);
    setActiveSubStep(existingSteps.subStep);
  }, []);

  const nextStep = async () => {
    if (activeStep === steps.length - 1) {
      return;
    }

    const subSteps = steps[activeStep]?.props?.subSteps;
    const onNext = steps[activeStep].props?.onNext;

    let step = activeStep;
    let subStep = activeSubStep;

    // Move to the next step if this step doesn't have sub-steps;
    const isParentStep = Boolean(subSteps);
    if (!isParentStep) {
      await onNext?.();
      step += 1;
      setActiveStep(step);

      const nextStep = steps[activeStep + 1];
      if (nextStep?.props?.subSteps) {
        setActiveSubStep(0);
        subStep = 0;
      }

      setStepIndex(getStepIndex(steps, step, subStep));
      localStorage.setItem(
        WIZARD_KEY,
        JSON.stringify({
          [path]: { step, subStep },
        })
      );
      return;
    }

    if (activeSubStep === subSteps.length - 1) {
      await onNext?.();
      step += 1;
      subStep = 0;
      setActiveStep(step);
      setActiveSubStep(subStep);

      setStepIndex(getStepIndex(steps, step, subStep));
      localStorage.setItem(
        WIZARD_KEY,
        JSON.stringify({
          [path]: { step, subStep },
        })
      );
      return;
    }

    await onNext?.();
    subStep += 1;

    setStepIndex(getStepIndex(steps, step, subStep));
    localStorage.setItem(
      WIZARD_KEY,
      JSON.stringify({
        [path]: { step, subStep },
      })
    );
    setActiveSubStep(subStep);
  };

  const previousStep = () => {
    if (activeStep === 0) {
      return;
    }

    const subSteps = steps[activeStep]?.props?.subSteps;
    // Move to the next step if this step doesn't have sub-steps;
    const isParentStep = Boolean(subSteps);

    let step = activeStep;
    let subStep = activeSubStep;

    if (!isParentStep) {
      step -= 1;
      setActiveStep(step);

      const previousStep = steps[step];
      const previousSubSteps = previousStep?.props?.subSteps;

      if (previousSubSteps) {
        subStep = previousSubSteps.length - 1;
        setActiveSubStep(subStep);
      }

      setStepIndex(getStepIndex(steps, step, subStep));
      localStorage.setItem(
        WIZARD_KEY,
        JSON.stringify({
          [path]: { step, subStep },
        })
      );
      return;
    }

    if (activeSubStep === 0) {
      step -= 1;
      setActiveStep(step);

      setStepIndex(getStepIndex(steps, step, subStep));
      localStorage.setItem(
        WIZARD_KEY,
        JSON.stringify({
          [path]: { step, subStep },
        })
      );
      return;
    }

    subStep -= 1;

    setStepIndex(getStepIndex(steps, step, subStep));
    localStorage.setItem(
      WIZARD_KEY,
      JSON.stringify({
        [path]: { step, subStep },
      })
    );
    setActiveSubStep(subStep);
  };

  const removeFromStorage = () => {
    const { [path]: _, ...updatedFlows } = JSON.parse(
      localStorage.getItem(WIZARD_KEY)
    );

    localStorage.setItem(WIZARD_KEY, JSON.stringify(updatedFlows));
  };

  return {
    activeStep,
    activeSubStep,
    nextStep,
    previousStep,
    removeFromStorage,
    stepIndex,
  };
};

export default useWizardSteps;
