import { useEffect, useState } from 'react';
import {
  DimensionSelect,
  MetricTypeSelect,
  TagDimensions,
} from '@/molecules/chartElements';
import { TagActivityBarChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { isPercentageMetric, BarChart } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useEntityNavigation } from '@/shared/hooks';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';

const TaggedActivitiesBarChart = ({ tag, levelType }) => {
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();
  const alwaysExpanded = tag ? new Set([tag.uuid]) : new Set();
  const [metric, setMetric] = useState(EntityMetric.ENTITY_PERCENTAGE);
  const [dimension, setDimension] = useState(TagDimensions[levelType][0]);
  const { navigateToEntity } = useEntityNavigation();
  const [sort, setSort] = useState({
    metric: 'hours',
    type: 'DESC',
  });
  const { chartData, metricTotals } = useAggregateChartData({
    dimension,
    filter,
    sort,
  });

  const hasActivities = metricTotals?.activities > 0;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: isPercentageMetric(metric.id) ? EntityMetric.HOURS : metric.id,
    });
  };

  // Handles double click navigation for person rows.
  const handleNavigate = (row) => {
    if (!row) {
      return;
    }

    navigateToEntity({
      type: EntityType.ROLE,
      uuid: row.props?.role_uuid,
    });
  };

  // This effect resets the chart if it's showing metric data and budgets have
  // been switched off.
  useEffect(() => {
    if (!showBudget && metric === EntityMetric.BUDGET) {
      setMetric(EntityMetric.ENTITY_PERCENTAGE);
    }
  }, [showBudget]);

  return (
    <ChartCard
      title="How work is categorised"
      ControlsComponent={
        hasActivities && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              label="Activity category by"
              onChange={handleDimensionChange}
              dimensions={TagDimensions[levelType]}
            />
            <MetricTypeSelect onChange={handleMetricTypeChange} />
          </Stack>
        )
      }
      InfoComponent={<TagActivityBarChartInformation />}
    >
      {hasActivities ? (
        <BarChart
          chartData={chartData}
          id="tag-activity-insights"
          metric={metric}
          metricTotals={metricTotals}
          onNavigate={handleNavigate}
          sort={sort}
          title="Activity category"
        />
      ) : (
        <EmptyInsights message="No tagged activities were found." />
      )}
    </ChartCard>
  );
};

export default TaggedActivitiesBarChart;
