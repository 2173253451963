import aggregateActivity from '@/shared/utils/aggregateActivity';
import { Colors } from '@pkg/utils';
import { DesignEntity, Property } from '@/lib/enums';

/**
 * @param {Object}
 * @return {Object}
 */
export default function mapAggregateActivities({
  design,
  filter,
  inverse,
  scenario,
  tagList,
}) {
  const entityColors = Colors.entity(
    scenario?.entity?.is_manager
      ? DesignEntity.MANAGER
      : scenario?.entity?.__type
  );
  const colors = [...entityColors.tints, ...entityColors.shades];
  const colorMaxIndex = 13;
  const colorMinIndex = 3;
  let activityColorIndex = colorMaxIndex;
  let groupColorIndex = colorMaxIndex;
  const groupFilter = filter?.[DesignEntity.GROUP];
  const roleFilter = filter?.[DesignEntity.ROLE];

  const activityList = scenario?.relationships?.get(DesignEntity.ACTIVITY);
  const aggregateData = {
    totalHours: 0,
    totalCount: 0,
    [Property.GROUPS]: new Map(),
    [Property.ACTIVITIES]: new Map(),
    [Property.UNALLOCATED]: new Map(),
  };

  let tags;

  if (tagList) {
    tags = new Set(tagList);
    aggregateData.taggedHours = 0;
    aggregateData.taggedCount = 0;
  }

  activityList.forEach((activity) => {
    const isActive = Boolean(!activity.disabled_at);
    const role =
      activity.owner_type === DesignEntity.ROLE
        ? design.get(DesignEntity.ROLE).get(`${activity.owner_uuid}`)
        : null;
    const group = role
      ? design.get(DesignEntity.GROUP).get(`${role.group_uuid}`)
      : null;

    if (groupFilter && groupFilter !== 'ALL' && groupFilter !== group?.uuid) {
      return;
    }

    if (roleFilter && roleFilter !== 'ALL' && roleFilter !== role?.uuid) {
      return;
    }

    const activityId =
      scenario?.entity?.__type === DesignEntity.ROLE
        ? activity.uuid
        : activity.library_uuid;

    const activityColors = {
      foreground: colors[activityColorIndex],
      background: colors[1],
      border: colors[1],
      highlight: {
        border: colors[3],
        background: colors[5],
        foreground: colors[16],
      },
    };
    const groupColors = {
      foreground: colors[groupColorIndex],
      background: colors[1],
      border: colors[1],
      highlight: {
        border: colors[3],
        background: colors[5],
        foreground: colors[16],
      },
    };

    if (isActive) {
      aggregateData.totalHours += activity.hours;
      aggregateData.totalCount += 1;
    }

    if (tagList) {
      if (inverse && activity.tags.some((tagId) => tags.has(tagId))) {
        return;
      }
      if (!inverse && !activity.tags.some((tagId) => tags.has(tagId))) {
        return;
      }

      if (isActive) {
        aggregateData.taggedHours += activity.hours;
        aggregateData.taggedCount += 1;
      }
    }

    const activityType =
      activity.owner_type === DesignEntity.ROLE
        ? Property.ACTIVITIES
        : Property.UNALLOCATED;

    const existingActivity = aggregateData[activityType].get(activityId);

    aggregateData[activityType].set(
      activityId,
      aggregateActivity({
        activity,
        colors: activityColors,
        entity: activity,
        existing: existingActivity,
        role,
        group,
        uuid: activityId,
        storeEntity: false,
      })
    );

    if (!existingActivity) {
      activityColorIndex =
        activityColorIndex > colorMinIndex
          ? activityColorIndex - 1
          : colorMaxIndex - (activityColorIndex - colorMinIndex);
    }

    const existingGroup = aggregateData[Property.GROUPS].get(group?.uuid);

    if (group) {
      aggregateData[Property.GROUPS].set(
        group.uuid,
        aggregateActivity({
          activity,
          colors: groupColors,
          entity: group,
          existing: existingGroup,
          group,
          role,
          uuid: group.uuid,
          storeEntity: false,
        })
      );
    }

    if (!existingGroup) {
      groupColorIndex =
        groupColorIndex > colorMinIndex
          ? groupColorIndex - 1
          : colorMaxIndex - (groupColorIndex - colorMinIndex);
    }
  });

  return aggregateData;
}
