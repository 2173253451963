import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { CircleMetric } from '@/molecules/chartElements';
import { RowColors } from '@/organisms/charts';
import { ChartRowType } from '@/shared/enums';
import Stack from '@mui/material/Stack';

const BarLabel = ({
  expanded,
  labelHeight,
  labelWidth,
  metric,
  metricTotals,
  row,
  xPosition,
  yPosition,
}) => {
  const rowType = row.props?.scope ?? row.type;
  const colors = RowColors[rowType];
  const labelColors = colors.label;
  const selectedColor = colors.group.backgroundColor;
  const value =
    row.type === ChartRowType.SPAN ? row.data.managers : row.data.count;
  const person =
    row.type === ChartRowType.PERSON
      ? {
          avatar: row.data.avatar,
          name: row.data.label,
        }
      : null;
  const showSelected =
    (row.type === ChartRowType.PROPERTY_VALUE ||
      row.type === ChartRowType.PROPERTY ||
      row.type === ChartRowType.ROLE ||
      row.type === ChartRowType.MANAGER ||
      row.type === ChartRowType.GROUP) &&
    row.expanded;

  const circleMetricWidth = 34;

  return (
    <>
      {showSelected && (
        <>
          <circle
            cx={xPosition + labelWidth / 2 - 2}
            cy={yPosition + circleMetricWidth / 2 + 4}
            r={circleMetricWidth / 2}
            fill={selectedColor}
          />
          <rect
            width={labelWidth * expanded}
            height={circleMetricWidth}
            x={xPosition + labelWidth / 2}
            y={yPosition + 4}
            fill={selectedColor}
          />
          <circle
            cx={xPosition + labelWidth * expanded + labelWidth / 2 - 2}
            cy={yPosition + circleMetricWidth / 2 + 4}
            r={circleMetricWidth / 2}
            fill={selectedColor}
          />
        </>
      )}
      <foreignObject
        width={labelHeight}
        height={labelHeight}
        x={xPosition + labelWidth - labelHeight}
        y={yPosition + circleMetricWidth}
        style={{
          position: 'relative',
        }}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-start"
          width={labelHeight}
          height={labelHeight}
          sx={{
            position: 'absolute',
            top: labelHeight / 4,
            right: -(labelHeight / 4) - 4,
            transform: `rotate(-45deg)`,
          }}
        >
          <Paragraph
            size={Size.SMALL}
            overrideStyles={{
              mb: 0,
              textAlign: 'right',
              width: labelHeight - 40,
              textWrap: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {row.label}
          </Paragraph>
        </Stack>
      </foreignObject>
      <foreignObject
        height={44}
        width={44}
        x={xPosition + labelWidth / 2 - 19}
        y={yPosition + 4}
      >
        <CircleMetric
          labelHeight={circleMetricWidth}
          colors={labelColors}
          person={person}
          value={value}
        />
      </foreignObject>
    </>
  );
};

const VerticalBarLabel = ({
  expanded,
  row,
  includeCounts = true,
  labelHeight,
  labelWidth,
  metric,
  metricTotals,
  width,
  xPosition,
  yPosition,
}) => {
  return (
    <g className="vertical-label">
      <BarLabel
        expanded={expanded}
        labelHeight={labelHeight}
        labelWidth={width}
        metric={metric}
        metricTotals={metricTotals}
        row={row}
        xPosition={xPosition}
        yPosition={yPosition}
      />
    </g>
  );
};

export default VerticalBarLabel;
