import { useEffect, useState } from 'react';
import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { BaseChartBar } from '@/molecules/chartElements';
import Stack from '@mui/material/Stack';
import { percent } from '@pkg/utils/numbers';
import Box from '@/components/Box';
import Tooltip from '@/components/Tooltip';
import Typography from '@/components/Typography';

const StackChartBar = ({
  colors,
  denominator,
  interactive = false,
  onClick,
  numerator,
  precision = 0,
  showNumerator,
  numeratorLabel,
  title,
}) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    if (numerator && denominator) {
      const percentage = percent(numerator, denominator, precision);

      setPercentage(percentage);
    }
  }, [numerator, denominator]);

  return (
    <Box
      width="100%"
      sx={{
        cursor: interactive ? 'pointer' : 'inherit',
        '&:hover': interactive
          ? {
              '& .title': {
                transform: 'translateX(10px)',
              },
              '& .chart-bar-fill': {
                backgroundColor: colors.highlight.foreground,
              },
              '& .chart-bar': {
                backgroundColor: colors.highlight.background,
              },
            }
          : null,
      }}
    >
      <Tooltip arrow placement="top" title={title}>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="flex-start"
          onClick={onClick ? onClick : null}
          spacing={2}
        >
          <Stack
            width={showNumerator ? `calc(100% - 80px)` : '100%'}
            spacing={0.5}
          >
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Box width="85%">
                <Typography
                  className="title"
                  variant="body2"
                  sx={{
                    color: Colors.Base.font.primary,
                    lineHeight: '1.15rem',
                    fontSize: '0.925rem',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    transform: 'translateX(0)',
                    transition: 'transform 300ms',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {title}
                </Typography>
              </Box>
              <Box width="15%">
                <Typography
                  variant="body2"
                  sx={{
                    color: Colors.Base.font.primary,
                    fontSize: '0.925rem',
                    fontWeight: 700,
                    lineHeight: '1.3rem',
                    textAlign: 'right',
                  }}
                >
                  {percentage}%
                </Typography>
              </Box>
            </Stack>
            <BaseChartBar
              color={{
                ...colors,
                highlight: {
                  ...colors.highlight,
                  border: 'transparent',
                },
              }}
              height="6px"
              percentage={percentage}
            />
          </Stack>
          {showNumerator && (
            <Stack justifyContent="center" alignItems="center">
              <Heading variant="h4" overrideStyles={{ fontWeight: 600 }}>
                {numerator}
              </Heading>
              <Paragraph
                overrideStyles={{ mb: 0, textTransform: 'uppercase' }}
                size={Size.SMALL}
              >
                {numeratorLabel}
              </Paragraph>
            </Stack>
          )}
        </Stack>
      </Tooltip>
    </Box>
  );
};

export default StackChartBar;
