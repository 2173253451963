import { DesignPermission } from '@/lib/enums';

/**
 * @param {String} designId
 * @returns {Object}
 */
export default function getPermissions(designId) {
  return {
    design: {
      manage: `${DesignPermission.MANAGE}.${designId}`,
      write: `${DesignPermission.WRITE}.${designId}`,
    },
  };
}
