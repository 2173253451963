import { Collections } from '@pkg/utils';
import { BulkOperators } from '@/lib/enums';

export default function bulk(snapshot, bulk) {
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
      roles: {
        update: [],
      },
      activities: {
        update: [],
      },
    },
  };

  if (!snapshot) {
    return mutation;
  }

  const { uuids, property, value, operator } = bulk;

  const updatedGroups = new Set([]);
  const updatedRoles = new Set([]);

  const convertValue = (operator, originalValue, newValue) => {
    let realValue = 0;
    switch (operator) {
      case BulkOperators.INCREASE:
        realValue = originalValue + newValue;
        break;
      case BulkOperators.INCREASE_PERCENTAGE:
        realValue = originalValue + originalValue * (newValue / 100);
        break;
      case BulkOperators.DECREASE:
        realValue = originalValue - newValue;
        break;
      case BulkOperators.DECREASE_PERCENTAGE:
        realValue = originalValue - originalValue * (newValue / 100);
        break;
      case BulkOperators.SET:
        realValue = newValue;
        break;
      default:
        realValue = newValue;
    }
    return realValue;
  };

  uuids.forEach((uuid) => {
    const entity = Collections.findById(snapshot.entities.activities, uuid);

    if (!entity) {
      return;
    }

    const originalValue = entity[property];
    let newValue = convertValue(operator, originalValue, value);

    if (property === 'hours') {
      newValue = Math.min(newValue, 999);
      newValue = Math.round(newValue * 4) / 4; // found to quarter decimal
    }

    mutation.entities.activities.update.push({
      uuid,
      [property]: newValue,
      updated_at: now,
    });

    const role = Collections.findById(
      snapshot.entities.roles,
      entity.owner_uuid
    );

    if (role) {
      updatedRoles.add(entity.owner_uuid);

      const group = Collections.findById(
        snapshot.entities.groups,
        role.group_uuid
      );

      if (group) {
        updatedGroups.add(group.uuid);
      }
    }
  });

  updatedRoles.forEach((roleUuid) => {
    mutation.entities.roles.update.push({
      uuid: roleUuid,
      updated_at: now,
    });
  });

  updatedGroups.forEach((groupUuid) => {
    mutation.entities.groups.update.push({
      uuid: groupUuid,
      updated_at: now,
    });
  });

  return mutation;
}
