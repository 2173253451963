import { cloneElement } from 'react';
import { Chip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { TagHeading } from '@/molecules/headings';
import Grid from '@mui/material/Grid';

const CategorisedActivityList = ({
  categoryTagMap,
  categoryTags,
  Component,
  settings,
}) => {
  return (
    <Grid container direction="row">
      {categoryTags.map((id, index) => (
        <Grid item xs={12} key={id}>
          <div style={{ marginLeft: '16px' }}>
            {cloneElement(Component, {
              excludesTags: categoryTags.slice(0, index),
              includesTags: [id],
              TitleComponent: <TagHeading tag={categoryTagMap.get(id)} />,
              settings: {
                ...settings,
                showTitle: false,
                canAddNew: false,
              },
            })}
          </div>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ mt: 1 }}>
        <div style={{ marginLeft: '16px' }}>
          {cloneElement(Component, {
            excludesTags: categoryTags,
            settings: {
              ...settings,
              showTitle: false,
            },
            TitleComponent: (
              <div style={{ marginBottom: '12px' }}>
                <Chip
                  id="other-activities"
                  color="tertiary"
                  name="Other work activities"
                  size={Size.LARGE}
                />
              </div>
            ),
          })}
        </div>
      </Grid>
    </Grid>
  );
};

export default CategorisedActivityList;
