import { Utils } from '@/atoms/chips';
import { brand } from '@/lib/theme/tokens/palettes';

export default function bubbleColor({ id, tags, isMuted, isHighlighted }) {
  if (isMuted) {
    return brand.grey.main;
  }
  if (isHighlighted || id === 'ALL') {
    return brand.yellow.main;
  }
  if (id === 'NONE') {
    return brand.grey.main;
  }

  const tag = tags.get(id);
  const color = Utils.tagColor({ color: tag.color });

  return color.normal.backgroundColor;
}
