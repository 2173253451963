import { DesignEntity, Property, Action } from '@/lib/enums';

const { ACTIVITIES, DESCRIPTION, DISABLED, FTE, HOURS, ORDER, TAGS } = Property;

const Permission = Object.freeze({
  'activities.add': {
    __type: DesignEntity.ROLE,
    property: ACTIVITIES,
    action: Action.ADD,
  },
  'activities.archive': {
    __type: DesignEntity.ROLE,
    property: ACTIVITIES,
    action: Action.REMOVE,
  },
  'activities.move': {
    __type: DesignEntity.ACTIVITY,
    property: ORDER,
    action: Action.EDIT,
  },
  'activities.toggle': {
    __type: DesignEntity.ACTIVITY,
    property: DISABLED,
    action: Action.EDIT,
  },
  'activities.update': {
    __type: DesignEntity.ACTIVITY,
    properties: new Map([
      [DESCRIPTION, DESCRIPTION],
      [HOURS, HOURS],
      [TAGS, TAGS],
    ]),
    action: Action.EDIT,
  },
  'roles.update': {
    __type: DesignEntity.ROLE,
    properties: new Map([[FTE, FTE]]),
    action: Action.EDIT,
  },
});

export default Permission;
