const getMatrixId = (matrixMap, activity) => {
  let id = 'NONE';
  matrixMap.entitySet.forEach((tagId) => {
    if (activity.__tags_set.has(tagId)) {
      id = id === 'NONE' ? tagId : 'ALL';
    }
  });
  return id;
};

export default function createMatrixItemMap({
  activity,
  entityMap,
  entityMetrics,
  group,
  order,
  role,
}) {
  const matrixId = getMatrixId(entityMap, activity);

  const matrixItem = entityMap['MATRIX'].get(matrixId);

  const addGroup =
    group?.uuid && !matrixItem.entitySets.groups.has(group?.uuid);
  const addRole =
    !role.isUnassigned && !matrixItem.entitySets.roles.has(role?.uuid);

  matrixItem.metrics.activities += 1;
  matrixItem.metrics.budget += entityMetrics.budget;
  matrixItem.metrics.count += 1;
  matrixItem.metrics.fte += entityMetrics.fte;
  matrixItem.metrics.hours += entityMetrics.hours;

  matrixItem.entitySets.activities.add(activity?.uuid);
  if (addRole) {
    matrixItem.metrics.roles += 1;
    matrixItem.entitySets.roles.add(role?.uuid);
  }
  if (addGroup) {
    matrixItem.metrics.groups += 1;
    matrixItem.entitySets.groups.add(group?.uuid);
  }

  return matrixItem[order[0]];
}
