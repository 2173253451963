/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.id
 * @param {Int} input.order
 * @returns {Object}
 */
export default function reorder(snapshot, { id, order }) {
  const groups = structuredClone(snapshot.entities.groups);
  const updated = groups.find((group) => group.uuid === id);
  const now = Date.now();

  groups.sort((a, b) => a.order - b.order);
  groups.splice(updated.order, 1);
  groups.splice(order, 0, updated);

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
    },
  };

  groups.forEach((group, index) => {
    if (group.order === index) {
      return;
    }

    mutation.entities.groups.update.push({
      uuid: group.uuid,
      order: index,
      updated_at: now,
    });
  });

  if (mutation.entities.groups.update.length === 0) {
    return null;
  }

  return mutation;
}
