import { format as dateFormat } from 'date-fns/format';

const Format = ({ date = '', format = 'HH:mm dd/MM/yyyy' }) => {
  if (!date) {
    return '';
  }
  return dateFormat(new Date(date), format);
};

export default Format;
