import HelpfulIcon from '@mui/icons-material/LightbulbCircleRounded';
import Box from '@mui/material/Box';
import HelpfulTipColors from './utils/HelpfulTipColors';

const HelpfulTip = ({ color = 'primary', children }) => {
  const colors = HelpfulTipColors[color];

  return (
    <>
      <Box
        pt={2}
        pb={3}
        px={2}
        sx={{
          color: colors.color,
          backgroundColor: colors.backgroundColor,
          boxShadow: colors.boxShadow,
          borderRadius: '4px',
          fontSize: '0.825rem',
          textAlign: 'center',
        }}
      >
        <Box sx={{ textAlign: 'center', color: colors.iconColor }} mb={1}>
          <HelpfulIcon sx={{ width: 44, height: 44 }} />
        </Box>
        {children}
      </Box>
    </>
  );
};

export default HelpfulTip;
