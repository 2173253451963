/**
 * Recursive function to return a parent entity and add it to the list.
 *
 * @param {Object}
 *
 * @return
 */
const getParentId = ({ id, entityType, entityMap, list }) => {
  const entity = entityMap.get(entityType)?.get(id);

  if (!entity) {
    return list;
  }

  list.push(`${entityType}:${id}`);

  if (!entity?.__parent) {
    return list;
  }

  const { uuid, type } = entity.__parent;

  return getParentId({
    id: uuid,
    entityType: type,
    entityMap,
    list,
  });
};

/**
 * Creates a list of all parent ids based on the provided entity id.
 *
 * @param {Object}
 *
 * @return {Array}
 */
export default function getParentIds({ id, entityType, entityMap, hierarchy }) {
  if (!id) {
    return [];
  }

  const entity = entityMap.get(entityType)?.get(id);
  if (!entity) {
    return [];
  }

  const ids = [`${hierarchy[0]?.type}:${hierarchy[0]?.uuid}`];

  if (hierarchy[0]?.uuid !== id) {
    ids.push(`${entityType}:${id}`);
  }

  const parentId = entity?.__parent ? entity.__parent.uuid : hierarchy[0]?.uuid;
  const parentType = entity?.__parent
    ? entity.__parent.type
    : hierarchy[0]?.type;

  return getParentId({
    id: parentId,
    entityType: parentType,
    entityMap,
    list: ids,
  });
}
