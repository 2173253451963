import { OutlineCard } from '@/organisms/cards';
import { FlowList } from '@/organisms/flows';
import { useFlowsContext } from '@/shared/providers/FlowsProvider';

const FlowListCard = ({ entityId, flowStyle }) => {
  const { flows } = useFlowsContext();

  return (
    flows && (
      <OutlineCard margin={3}>
        <FlowList entityId={entityId} flowStyle={flowStyle} />
      </OutlineCard>
    )
  );
};
export default FlowListCard;
