export default function defaultOtherSegment() {
  return {
    activities: 0,
    budget: 0,
    count: 0,
    fte: 0,
    groups: 0,
    hours: 0,
    managers: 0,
    percentage: 0,
    relativePercentage: 0,
    roles: 0,
    span: 0,
  };
}
