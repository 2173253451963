import { Collections } from '@pkg/utils';

/**
 * @param {Object} snpashot
 * @param {Object} input
 * @param {String[]} input.ids
 * @param {Object} input.change
 * @param {Array} [input.change.add]
 * @param {Array} [input.change.remove]
 * @returns {Object}
 */
export default function tagging(snapshot, { ids, change }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      groups: {
        update: [],
      },
    },
  };

  const keyed = Collections.keyById(entities.groups);

  ids.forEach((id) => {
    if (!Object.hasOwn(keyed, id)) {
      return;
    }

    const group = keyed[id];
    const tags = new Set(group.tags);
    change.add?.forEach((id) => tags.add(id));
    change.remove?.forEach((id) => tags.delete(id));

    if (JSON.stringify(Array.from(tags)) === JSON.stringify(group.tags)) {
      return;
    }

    mutation.entities.groups.update.push({
      uuid: id,
      tags: Array.from(tags),
      updated_at: now,
    });
  });

  if (mutation.entities.groups.update.length === 0) {
    return null;
  }

  return mutation;
}
