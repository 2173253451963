import { EntityType } from '@/shared/enums';
import aggregateEntity from './aggregateEntity';
import getEntityProps from './getEntityProps';
import mapPropertyEntityItems from './mapPropertyEntityItems';
import mapTagEntityItems from './mapTagEntityItems';

/**
 * Creates a nested map of entities using an entity order.
 *
 * @param {Object}
 *
 * @return {Map}
 */
export default function createNestedMap({
  activity,
  group,
  entities,
  entityMetrics,
  entityType,
  includesSpans,
  manager,
  map,
  person,
  properties,
  propertyMap,
  role,
  tags,
  tagMap,
  order,
}) {
  for (let i = 0; i < order.length; i++) {
    // We need the current and next entity types to assist with recursive
    // ordering.
    const orderEntity = order[i];
    const nextOrderEntity = order[i + 1];
    const remainingSet = new Set(order.slice(i + 1));

    // Handling for tag entities.
    if (orderEntity === EntityType.TAG) {
      map = mapTagEntityItems({
        activity,
        group,
        entityMetrics,
        entities,
        map,
        order,
        orderIndex: i,
        person,
        remainingSet,
        role,
        tags,
        tagMap,
      });
      return;
    }

    if (orderEntity === EntityType.PROPERTY) {
      map = mapPropertyEntityItems({
        activity,
        group,
        entityMetrics,
        entityType,
        entities,
        map,
        order,
        orderIndex: i,
        person,
        remainingSet,
        role,
        properties,
        propertyMap,
      });
      return;
    }

    const props = getEntityProps({
      activity,
      entityType: orderEntity,
      includesSpans,
      manager,
      group,
      role,
      person,
    });

    if (
      includesSpans &&
      orderEntity === EntityType.LAYER &&
      props.props?.layerNumber === 0
    ) {
      return;
    }

    if (props) {
      map = aggregateEntity({
        ...props,
        entities,
        existingMetrics: entityMetrics,
        map,
        nextEntity: nextOrderEntity,
        remainingSet,
        type: orderEntity,
      });
    }
  }

  return map;
}
