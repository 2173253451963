import {
  cumulateMetrics,
  defaultMetrics,
  updateEntityAction,
} from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function updateRole({
  action,
  activity,
  role,
  roleType,
  comparisonScenario,
}) {
  const updateRole = roleType[EntityType.ROLE].get(role.uuid) ?? {
    [EntityType.ACTIVITY]: new Set(activity ? [activity.uuid] : []),
    actions: new Map(),
    metrics: defaultMetrics(comparisonScenario),
  };

  updateRole.actions.set(
    action.type,
    updateEntityAction({
      action,
      actions: updateRole.actions,
      role,
      comparisonScenario,
    })
  );

  updateRole.metrics[1] = cumulateMetrics(action, updateRole.metrics[1]);

  return updateRole;
}
