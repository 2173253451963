import { useMemo } from 'react';
import filters from './filters';

/**
 * @param {object} snapshot
 * @param {object} conditions
 * @return {any}
 */
export default function useFilters(snapshot, conditions) {
  return useMemo(() => {
    return filters.apply(snapshot, conditions);
  }, [snapshot, conditions]);
}
