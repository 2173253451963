import { percent } from '@pkg/utils/numbers';

/**
 * Calculates the percentage change between two values.
 * @param {Number} primary
 * @param {Number} secondary
 *
 * @return {Number}
 */
export default function percentageChange(
  originalValue,
  changeValue,
  precision
) {
  if (isNaN(originalValue) || isNaN(changeValue)) {
    return;
  }

  if (originalValue === 0) {
    return changeValue;
  }

  return percent(changeValue - originalValue, originalValue, precision);
}
