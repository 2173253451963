import { Position } from '@/lib/enums';

export default function bubbleGravity(position) {
  switch (position) {
    case Position.TOP_LEFT:
      return Position.BOTTOM_RIGHT;
    case Position.TOP_RIGHT:
      return Position.BOTTOM_LEFT;
    case Position.BOTTOM_LEFT:
      return Position.TOP_RIGHT;
    case Position.BOTTOM_RIGHT:
      return Position.TOP_LEFT;
    case Position.RIGHT:
      return Position.LEFT;
    case Position.LEFT:
      return Position.RIGHT;
    default:
      return Position.CENTER;
  }
}
