import { useCallback } from 'react';
import {
  ComparisonOperator,
  FilterEntity,
  FilterProperty,
  filterEntityToPlural,
} from '@/organisms/filters/enums';
import {
  getFilterEntityType,
  getFilterPropertyType,
} from '@/organisms/filters/utils';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';

const getMetricProperty = (entity, property) => {
  const isRole = entity === FilterEntity.ROLE;
  const isGroup = entity === FilterEntity.GROUP;
  const metrics = '__total_metrics';

  switch (property) {
    case FilterProperty.HOURS:
      return isGroup || isRole ? metrics : null;
    case FilterProperty.BUDGET:
    case FilterProperty.FTE:
      return isGroup ? metrics : null;
    default:
      return null;
  }
};

const convertComparator = (comparator, property) => {
  switch (comparator) {
    case ComparisonOperator.EXCLUDE_ALL:
      if (property === FilterProperty.SKILL) {
        return ComparisonOperator.EXCLUDE_ALL_OBJECT;
      }
      return comparator;
    case ComparisonOperator.EXCLUDE_ANY:
      if (property === FilterProperty.SKILL) {
        return ComparisonOperator.EXCLUDE_ANY_OBJECT;
      }
      return comparator;
    case ComparisonOperator.INCLUDE_ANY:
      if (property === FilterProperty.SKILL) {
        return ComparisonOperator.HAS_OBJECT_ANY;
      }
      return ComparisonOperator.HAS_ITEM_ANY;

    case ComparisonOperator.INCLUDE_ALL:
      if (property === FilterProperty.SKILL) {
        return ComparisonOperator.HAS_OBJECT_ALL;
      }
      return ComparisonOperator.HAS_ITEM_ALL;

    case ComparisonOperator.AFTER:
      return ComparisonOperator.GREATER_THAN_OR_EQUAL;

    case ComparisonOperator.BEFORE:
      return ComparisonOperator.LESS_THAN_OR_EQUAL;

    default:
      return comparator;
  }
};

const formatIdentifier = (property, propertyId) => {
  if (property === FilterProperty.PROPERTY && propertyId) {
    return `${property}:${propertyId}`;
  }

  return property;
};

const formatRoleValue = (roles) => {
  const results = [];
  roles.forEach(({ ids }) => {
    const roleIds = [...ids];
    results.push(...roleIds);
  });
  return results;
};

const formatSkillValue = (skills) => {
  const results = [];
  skills.forEach(({ id, value }) => {
    value.map((level) => {
      results.push({ uuid: id, level });
    });
  });
  return results;
};

const formatAssigneeValue = (people) => {
  return people.map((person) => person.uuid);
};

const formatValue = (propertyType, value) => {
  if (propertyType === FilterProperty.SKILL) {
    return formatSkillValue(value);
  }

  if (propertyType === FilterProperty.ROLE_UUID) {
    return formatRoleValue(value);
  }

  if (propertyType === FilterProperty.ASSIGNEE) {
    return formatAssigneeValue(value);
  }

  if (Array.isArray(value)) {
    return value.map((item) => item?.id ?? item);
  }

  return value;
};

const formatFilterConditions = (filter) => {
  const conditions = {
    activities: [],
    groups: [],
    people: [],
    roles: [],
    skills: [],
  };

  filter?.groups?.forEach((group) => {
    group.conditions?.forEach((condition) => {
      const value = condition?.get('value');

      if (value === null || typeof value === 'undefined' || value === '') {
        return;
      }

      const entity = condition.get('entity')?.key;
      const property = condition.get('property')?.key;
      const propertyId = condition?.get('propertyId')?.uuid;
      const entityType = getFilterEntityType(entity, property);
      const propertyType = getFilterPropertyType(entity, property);

      const entityPlural = filterEntityToPlural(
        getFilterEntityType(entityType, propertyType)
      );

      const comparator = convertComparator(
        condition?.get('comparator')?.key,
        propertyType
      );

      const item = {
        entity: entityPlural,
        identifier: formatIdentifier(propertyType, propertyId),
        metricProperty: getMetricProperty(entityType, propertyType),
        relative: comparator,
        value: formatValue(propertyType, value),
      };

      conditions[entityPlural].push(item);
    });
  });

  return conditions;
};

const useFilter = () => {
  // Require the necessary stores for the filter.
  const setConditions = useDesignStore((state) => state.setConditions);

  // Takes a filter object and converts it to a filter condition.
  const applyFilter = (filter) => {
    const conditions = formatFilterConditions(filter);
    setConditions(conditions);
  };

  return useCallback(applyFilter, []);
};

export default useFilter;
