import useNextColor from '@pkg/entities/tags/useNextColor';
import shortuuid from '@pkg/uuid';
import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object} state
 * @param {Object[]} state.categories
 * @returns {Object}
 */
export default function add({ categories }) {
  const category = {
    uuid: shortuuid.generate(),
    name: 'New category',
    color: useNextColor(categories.list),
    order: categories.list.length,
    taggables: [DesignEntity.GROUP, DesignEntity.ROLE, DesignEntity.ACTIVITY],
  };

  return {
    categories: {
      create: [category],
    },
  };
}
