import { useListen } from '@pkg/broadcasting';
import useObservable from '@pkg/broadcasting/useObservable';

export function getChannelName(uuid) {
  return uuid ? `Organisation.notify.${uuid}` : null;
}

export default function useNotify(uuid, listeners = []) {
  const channelType = 'PrivateChannel';
  const channelName = getChannelName(uuid);

  const notify = useObservable(channelName, listeners);

  const handleEvent = (event, data) => {
    notify(event, data);
  };

  useListen({ channelName, channelType }, handleEvent);
}
