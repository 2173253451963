/**
 * @param {Object} user
 * @param {String} permission
 * @returns {Boolean}
 */
export default function can(user, permission) {
  const permissions = new Set([
    ...(user?.access?.permissions?.design ?? []),
    ...(user?.access?.permissions?.organisation ?? []),
  ]);

  return permissions.has(permission);
}
