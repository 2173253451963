import { DesignAction } from '@/lib/enums';

/**
 * Map mutation by id
 * @param {Object} mutation
 * @return {Object}
 */
export default function map(mutation) {
  if (!Object.hasOwn(mutation, 'entities')) {
    return mutation;
  }

  const mapped = structuredClone(mutation);
  for (const plural in mapped.entities) {
    mapped.entities[plural] = mapActions(mapped.entities[plural]);
  }

  return Object.freeze(mapped);
}

/**
 * @param {Object} actions
 * @param {Object[]} actions.create
 * @param {Object[]} actions.update
 * @param {String[]} actions.remove
 * @param {String} [key]
 * @returns
 */
export function mapActions(actions, key = 'uuid') {
  const mapped = {
    create: new Map(),
    update: new Map(),
    remove: new Set(),
  };

  for (const action in actions) {
    mapped[action] =
      DesignAction.from(action) === DesignAction.REMOVE
        ? new Set(actions[action])
        : new Map(actions[action].map((item) => [item[key], item]));
  }

  return mapped;
}
