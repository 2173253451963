import { EntitySummary } from '@/molecules/summaries';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import entityChipColors from '@/shared/utils/entityChipColors';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { DesignEntity } from '@/lib/enums';
import { CardColor } from '@/lib/theme/dashboardColors';
import ScenarioNavigationItem from './ScenarioNavigationItem';

const isSelected = (scenario, entityId, designId) => {
  if (!scenario || !designId || !entityId) {
    return;
  }
  if (scenario.entity?.__type === DesignEntity.ORGANISATION) {
    return scenario.entity.__uuid === designId;
  }

  return (
    scenario.details.designId === designId && scenario.entity.uuid === entityId
  );
};

const ScenarioNavigation = ({ entity, expanded, onClick }) => {
  const scenarios = Array.from(entity.__scenarios.values());

  const { scenario, selectScenario } = useScenarioContext();
  const entityType = entity?.is_manager ? DesignEntity.MANAGER : entity.__type;

  const handleScenarioClick = (selected) => {
    const selectedScenario = selected ?? scenarios[0];

    if (
      !selectedScenario ||
      (selectedScenario.__designId === scenario?.details?.designId && expanded)
    ) {
      return;
    }
    selectScenario(entity, selectedScenario.__designId);

    const uuid =
      entity?.__type === DesignEntity.ORGANISATION
        ? entity?.design?.uuid
        : (entity?.uuid ?? entity?.__uuid);

    onClick(uuid);
  };

  const entityColors = entityChipColors(entityType);

  return (
    <Accordion
      colors={entityColors}
      expanded={expanded}
      disableGutters={true}
      elevation={0}
      selected={expanded ? 'selected' : ''}
      sx={{
        border: '1px solid rgba(140, 152, 164, 0.2)',
        borderColor: expanded ? entityColors.border : CardColor.border,
        borderBottom: `4px solid ${entityColors.border}`,
        borderRadius: '4px !important',
        p: 0,
        my: 1,
        '&:before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        onClick={() => handleScenarioClick()}
        colors={entityColors}
        sx={{
          alignItems: 'flex-start',
          m: 0,
          p: 1.5,
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
          '& .MuiAccordionSummary-expandIconWrapper': {
            mt: 2,
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          width="100%"
        >
          <EntitySummary entity={entity} fontWeight={400} />
        </Stack>
      </AccordionSummary>
      {Boolean(scenarios.length > 0) && (
        <AccordionDetails
          sx={{
            padding: 0,
            overflow: 'hidden',
          }}
        >
          <Divider sx={{ borderColor: entityColors.border, opacity: 0.5 }} />
          {scenarios.map((item) => (
            <ScenarioNavigationItem
              key={item.__designId}
              entityType={entityType}
              onClick={handleScenarioClick}
              selected={isSelected(
                scenario,
                entity.uuid ?? entity.__uuid,
                item.__designId
              )}
              scenario={item}
            />
          ))}
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default ScenarioNavigation;
