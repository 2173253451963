import { styled } from '@mui/material/styles';
import excludeProps from '@/lib/theme/excludeProps';

const RootProps = ['height', 'width', 'rounded', 'uploadable'];
const Root = styled('div', {
  shouldForwardProp: excludeProps(RootProps),
})(({ height, width, rounded, uploadable }) => ({
  borderRadius: rounded ? '50%' : 0,
  position: 'relative',
  opacity: 1,
  overflow: 'hidden',
  transition: 'opacity 0.25s',
  height,
  width,
  '&:hover': uploadable
    ? {
        cursor: 'pointer',
        opacity: 0.8,
      }
    : {},
}));

const Img = styled('img')({
  display: 'block',
  position: 'relative',
  zIndex: 0,
});

const Children = styled('div')({
  cursor: 'default',
  cursorEvents: 'none',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 1,
});

const Image = ({ alt, src, children, classes, ...props }) => {
  const { width, height } = props;

  return (
    <Root {...props}>
      {src && (
        <Img
          alt={alt}
          className={classes?.image}
          src={src}
          style={{ width, height }}
        />
      )}
      <Children className={classes?.children}>{children}</Children>
    </Root>
  );
};

export default Image;
