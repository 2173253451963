import { cloneElement, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { Modal } from '@/molecules/modals';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';

const ConfirmationDialog = ({
  Component,
  title,
  content,
  onConfirm,
  confirmLabel,
  cancelLabel,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    event.stopPropagation();
    setOpen(true);
  };

  const handleClose = () => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleConfirm = async () => {
    await onConfirm();
    setLoading(false);
    handleClose();
  };

  const handleClick = () => {
    setLoading(true);
    handleConfirm();
  };

  return (
    <>
      {cloneElement(Component, { onClick: handleOpen })}
      <Modal
        open={open}
        onClose={handleClose}
        minHeight="auto"
        TransitionComponent={Slide}
        maxWidth="calc(50vw)"
      >
        {title && (
          <Heading variant="h3" overrideStyles={{ mb: 3, fontWeight: 600 }}>
            {title}
          </Heading>
        )}
        {content && <Paragraph>{content}</Paragraph>}
        <Stack direction="row" spacing={2} mt={4}>
          {onConfirm && (
            <Button
              color="secondary"
              label={confirmLabel ?? 'Confirm'}
              loading={loading}
              onClick={handleClick}
              size={Size.LARGE}
            />
          )}
          <Button
            color="light-blue"
            variant="naked"
            label={cancelLabel ?? 'Cancel'}
            onClick={handleClose}
            size={Size.LARGE}
          />
        </Stack>
      </Modal>
    </>
  );
};

export default ConfirmationDialog;
