import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';

const ENTITY_DEFAULTS = {
  [DesignEntity.GROUP]: config.DEFAULT_GROUP,
  [DesignEntity.ROLE]: config.DEFAULT_ROLE,
  [DesignEntity.ACTIVITY]: config.DEFAULT_ACTIVITY,
};

export default function make(input = {}) {
  const snapshot = structuredClone({
    ...config.DEFAULT_SNAPSHOT,
    ...input,
    entities: config.DEFAULT_SNAPSHOT.entities,
  });

  const entities = input.entities ?? {};
  Object.keys(input.entities ?? {}).forEach((plural) => {
    const type = DesignEntity.fromPlural(plural);
    const defaults = ENTITY_DEFAULTS[type];
    entities[plural].forEach((entity) => {
      snapshot.entities[plural].push({
        ...defaults,
        ...entity,
      });
    });
  });

  return snapshot;
}
