import { useEffect, useState } from 'react';
import * as Colors from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { Tabs } from '@/molecules/menus';
import { PlanInsights, PlanList, PlanSelection } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import PlanTabOptions from './PlanTabOptions';

const Plan = () => {
  const { loadComparison, scenario } = useScenarioContext();
  const { organisation } = Auth.useStore();
  const [selected, setSelected] = useState(PlanTabOptions[0]);

  const handleTabSelect = (option) => {
    if (option.id === selected.id) {
      return;
    }

    setSelected(option);
  };

  useEffect(() => {
    if (!scenario?.details?.isScenario || !organisation?.design) {
      return;
    }

    const entityType = scenario.entity.is_manager
      ? EntityType.MANAGER
      : scenario?.entity.__type;

    loadComparison('main', 'latest', {
      designId: organisation.design.uuid,
      uuid: scenario.entity.uuid,
      type: entityType,
    });
  }, [scenario?.details?.designId]);

  return (
    <Stack
      direction="row"
      height="calc(100vh - 87px)"
      sx={{
        color: Colors.Base.font.primary,
        overflow: 'hidden',
        backgroundColor: Colors.Base.background.secondary,
      }}
    >
      {!scenario?.details?.isScenario && <PlanSelection />}
      {scenario?.details.isScenario && (
        <>
          <Box width="65%">
            <Stack direction="row" p={2}>
              <Heading variant="h2" overrideStyles={{ fontWeight: 700 }}>
                {scenario.details.name}
              </Heading>
            </Stack>
            <Box
              px={2}
              pb="3px"
              sx={{
                boxShadow: `inset 0 -2px 0 0 #E7F0FF, inset 0 -3px 0 0 #DBE4F4`,
              }}
            >
              <Tabs
                options={PlanTabOptions}
                initialSelected={selected}
                variant="primary"
                size={Size.SMALL}
                onClick={handleTabSelect}
              />
            </Box>
            <Box
              px={2}
              py={4}
              height="calc(100vh - 198px)"
              sx={{
                overflowY: 'auto',
              }}
            >
              <PlanList id={selected.id} />
            </Box>
          </Box>
          <Box width="35%">
            <PlanInsights />
          </Box>
        </>
      )}
    </Stack>
  );
};

export default Plan;
