import { cloneElement } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColor } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const FilterOptions = ({
  children,
  FooterComponent,
  closeOnClick,
  showClose = true,
  onClear,
  onClose,
}) => {
  const showFooter = Boolean(FooterComponent) || showClose;

  const handleClick = (props, event, value) => {
    props?.onClick?.(event, value);

    if (closeOnClick) {
      onClose?.();
    }
  };

  return (
    <Box sx={{ width: 340 }}>
      {cloneElement(children, {
        onClick: (event, value) => handleClick(children.props, event, value),
      })}
      {showFooter && (
        <Box
          sx={{
            boxShadow: `0 -1px 0 ${BaseColor.border.grey}`,
          }}
          px={1}
          py={2}
        >
          {FooterComponent}
          <Stack direction="row" justifyContent="space-between">
            {onClose && (
              <Button
                color="grey"
                label="Close"
                size={Size.LARGE}
                variant="outline"
                onClick={onClose}
              />
            )}
            {onClear && (
              <Button
                color="grey"
                label="Clear"
                size={Size.LARGE}
                variant="outline"
                onClick={onClear}
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default FilterOptions;
