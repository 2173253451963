import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef } from 'react';
import { Analytics, Event } from '@pkg/analytics';
import { Designs } from '@pkg/entities';

const DELAY = 2000;
const MAX_DELAY = 10000;
const INITIAL_STATE = {
  designId: null,
  events: [],
  level: null,
  oldest: null,
  timeout: null,
};

/**
 * @param {String} designId
 * @param {DesignLevel} level
 * @returns {Object}
 */
export default function useMutate(designId, level) {
  const onMutate = Designs.mutations.useMutate();
  const bus = useRef(INITIAL_STATE);
  const router = useRouter();

  /**
   * Keep track of current design id and level
   */
  useEffect(() => {
    bus.current.designId = designId;
    bus.current.level = level;
  }, [designId, level]);

  /**
   * Handle mutation
   */
  function handle() {
    clearTimeout(bus.current.timeout);
    bus.current.oldest = null;
    bus.current.timeout = null;

    const events = Array.from(bus.current.events);
    bus.current.events = [];

    console.debug('Design.mutate.handle', { events });
    if (!events.length) {
      return;
    }

    const combined = Designs.mutations.utils.combine(events);
    console.debug('Design.mutate.handle', { combined });
    onMutate(bus.current.designId, combined);
  }

  /**
   * Emit mutation events
   */
  function emit(mutation, action) {
    clearTimeout(bus.current.timeout);
    console.debug('Design.mutate.emit', { action, mutation });

    // handle current events if excedes max delay
    const oldest = bus.current.oldest;
    const furthest = Date.now() + DELAY;
    if (oldest && furthest - oldest > MAX_DELAY) {
      handle();
    }

    // append events and timeout
    bus.current.events.push(mutation);
    bus.current.oldest = oldest ?? Date.now();
    bus.current.timeout = setTimeout(handle, DELAY);

    // track the event
    Analytics.track(Event.DESIGN_MUTATED, {
      action: action.replace('.', '_'),
      design_id: designId,
      level: bus.current.level,
    });
  }

  /**
   * Fire mutations immediately if changing route
   */
  useEffect(() => {
    router.events.on('routeChangeStart', handle);
    return () => router.events.off('routeChangeStart', handle);
  }, []);

  return useCallback(emit, []);
}
