import { useCallback, useMemo, useState } from 'react';
import { Collections } from '@pkg/utils';
import filterUsers from './filterUsers';

/**
 * @return {Object}
 */
export default function useUsers() {
  const [me, setMe] = useState(null);
  const [users, setPeople] = useState([]);

  /**
   * @param {Object} user
   * @returns {undefined}
   */
  const addHandler = (user) => {
    setPeople((users) => users.concat(user));
  };

  /**
   * @param {Object} user
   * @returns {undefined}
   */
  const updateHandler = (user) => {
    setPeople((users) => Collections.updateById(users, user));
  };

  /**
   * @param {Object} user
   * @returns {undefined}
   */
  const removeHandler = (user) => {
    setPeople((users) => users.filter(({ uuid }) => uuid !== user.uuid));
  };

  const add = useCallback(addHandler, [setPeople]);
  const update = useCallback(updateHandler, [setPeople]);
  const remove = useCallback(removeHandler, [setPeople]);

  return useMemo(() => {
    return {
      me,
      users: filterUsers(me, users),
      setPeople,
      setMe,
      add,
      update,
      remove,
    };
  }, [add, update, remove, me, users]);
}
