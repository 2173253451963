/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} [input.name]
 * @param {String} [input.objective]
 * @returns {Object}
 */
export default function update(snapshot, { name, objective }) {
  const now = Date.now();

  let isDifferent = false;
  const mutation = { created_at: now };

  if (name !== undefined && snapshot.name !== name) {
    mutation.name = name;
    isDifferent = true;
  }

  if (objective !== undefined && snapshot.objective !== objective) {
    mutation.objective = objective;
    isDifferent = true;
  }

  if (!isDifferent) {
    return null;
  }

  return mutation;
}
