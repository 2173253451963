import { GraphQLError, ClientError } from '@pkg/client';

class Sdk {
  static guestTokenKey = 'guest_token';

  /**
   * @return {string}
   */
  static getGuestToken() {
    try {
      const value = localStorage.getItem(Sdk.guestTokenKey);
      return value === 'null' ? null : value;
    } catch {
      return '';
    }
  }

  /**
   * @param {string} token
   * @return {void}
   */
  static storeGuestToken(token) {
    Sdk.clearTokens();
    try {
      localStorage.setItem(Sdk.guestTokenKey, token);
    } catch {
      return '';
    }
  }

  /**
   * @return {void}
   */
  static clearTokens() {
    try {
      localStorage.removeItem(Sdk.guestTokenKey);
    } catch {
      return '';
    }
  }

  /**
   * @return {string}
   */
  static fullPath() {
    return (
      window.location.pathname + window.location.search + window.location.hash
    );
  }

  /**
   * @param {string} path
   * @return {string}
   */
  static sanitizePath(path) {
    return path?.match(/^\/([/a-zA-Z0-9#=-_]+)/) ? path : '/';
  }

  /**
   * @param {string} accessToken
   * @return {object}
   */
  static getHeaders(accessToken) {
    const token = accessToken || Sdk.getGuestToken();
    return {
      Authorization: token ? `Bearer ${token}` : undefined,
    };
  }

  /**
   * @param {object} error
   * @param {object} obj
   * @param {function} obj.setStatus
   * @param {function} obj.setErrors
   * @return {void}
   */
  static setFormErrors(error, { setStatus, setErrors }) {
    if (error instanceof GraphQLError || error instanceof ClientError) {
      setStatus({
        ok: false,
        code: error.response.status,
        response: error.response,
      });

      setErrors(error.getFieldErrors());
    } else {
      throw error;
    }
  }
}

export default Sdk;
