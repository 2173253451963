import { Action, DesignEntity, Relationship } from '@/lib/enums';
import handleMultipleEntityActions from './utils/handleMultipleEntityActions';

/**
 * @param {String} level
 * @param {Boolean} isScenario
 * @param {Function} lookup
 * @param {Object} relationships
 * @returns {Function}
 */
export default function useCheckActions(
  level,
  isScenario,
  lookup,
  relationships
) {
  /**
   * @param {Object[]} actions
   * @param {Object} actions[].entity
   * @param {String} actions[].property
   * @param {String} actions[].action
   * @param {Boolean} [onlyMain] only check main design relationships
   * @returns {Boolean}
   */
  const checkActions = (actions, onlyMain = false) => {
    const policies = [];

    for (const { entity, property, action } of actions) {
      if (!entity?.uuid || !entity?.__type) {
        return false;
      }

      // use canCreate for creating new entities
      if (action === Action.CREATE) {
        throw new Error('Use canCreate() to check create entity actions');
      }

      const entityType =
        entity.__type === DesignEntity.DESIGN && isScenario && !onlyMain
          ? DesignEntity.SCENARIO.toLowerCase()
          : entity.__type.toLowerCase();

      const relationship =
        (onlyMain
          ? relationships?.main[entity.uuid]
          : relationships?.current[entity.uuid]) ?? Relationship.NONE;

      policies.push({
        level,
        relationship,
        entityType,
        property,
        action,
      });

      handleMultipleEntityActions(
        policies,
        relationships,
        level,
        entity,
        property,
        action
      );
    }

    return lookup(policies);
  };

  return checkActions;
}
