import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '@pkg/client';
import { onDispatch as defaultOnDispatch } from '../utils';
import mapMultipart from './mapMultipart';

/**
 * @param {object} params
 * @param {string} params.mutation
 * @param {function} [params.onDispatch]
 * @param {any[]} [params.invalidateQuery]
 *
 * @return {object}
 */
export default function useMultipartMutation({ mutation, map, ...options }) {
  const client = useClient();
  const queryClient = useQueryClient();

  const { onDispatch = defaultOnDispatch, invalidateQuery, ...rest } = options;

  function onSuccess(data, variables) {
    if (invalidateQuery) {
      queryClient.invalidateQueries(invalidateQuery);
    }

    return options.onSuccess?.(data, variables);
  }

  /**
   * @param {Object} input
   * @returns {Promise}
   */
  function mutator(input) {
    const variables = onDispatch({ input });

    return client.graphql(mapMultipart(mutation, map, variables));
  }

  return useMutation(mutator, {
    throwOnError: true,
    onSuccess,
    ...rest,
  });
}
