import { ScopeType } from '@/shared/enums';

export default function isRestrictedTag(activity, ids) {
  if (!ids || ids.has(ScopeType.ALL)) {
    return;
  }

  const isNotTagged = ids.has('not_tagged');

  if (isNotTagged && activity.__tags_set.size > 0) {
    return true;
  }

  if (ids.size && !isNotTagged) {
    const hasId = activity.tags?.some((id) => [...ids].includes(id));
    return !hasId;
  }
}
