import { DesignLevel } from '@/lib/enums';

/**
 * @param {String} path
 */
export default function getDesignParts(path) {
  const pattern = /\/d\/([0-9a-zA-Z]+)\/([0-9a-zA-Z]+)(\/.*)?/;
  const [, designId, revisionId, levelPart] = path.match(pattern) ?? [];

  if (!designId) {
    return null;
  }

  let level = null;
  if (levelPart) {
    const [, typePart, uuid] = levelPart.match(/\/([gmpr])\/([0-9a-zA-Z]+)/);
    level = { uuid };

    switch (typePart) {
      case 'g':
        level.type = DesignLevel.GROUP;
        break;
      case 'm':
        level.type = DesignLevel.MANAGER;
        break;
      case 'p':
        level.type = DesignLevel.PERSON;
        break;
      case 'r':
        level.type = DesignLevel.ROLE;
        break;
    }
  }

  return {
    designId,
    revisionId,
    level,
  };
}
