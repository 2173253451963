/**
 * Returns the height of circle area based on the expected row count.
 *
 * @param {Object}
 *
 * @return {Number}
 */
const getSegmentHeight = ({ rowCount, height }) => {
  if (rowCount === 1) {
    return height / rowCount;
  }

  return height / (rowCount + 1);
};

/**
 * Returns the vertical margin to add to the circle Y position.
 *
 * @param {Object}
 *
 * @return {Number}
 */
const getYMargin = ({ rowCount, segmentHeight }) => {
  if (rowCount === 1) {
    return 0;
  }

  return segmentHeight / 2;
};

/**
 * Returns a Y position of the segment circle.
 *
 * @param {Object}
 *
 * @return {Number}
 */
export default function circleYPosition({ height, row, rowCount, yPosition }) {
  const segmentHeight = getSegmentHeight({ rowCount, height });
  const yMargin = getYMargin({ rowCount, segmentHeight });

  return yPosition + segmentHeight * row - segmentHeight / 2 + yMargin;
}
