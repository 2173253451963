import { color, palettes } from '@/lib/theme/tokens';

export const DashboardColor = Object.freeze({
  background: palettes.brand.purple.tints[0],
  border: palettes.brand.purple.tints[1],
  text: '#646480',
});

export const CardColor = Object.freeze({
  outline: {
    background: color.core.white,
    border: DashboardColor.border,
    divider: DashboardColor.border,
    icon: DashboardColor.text,
  },
  fill: {
    background: palettes.brand.navy.colors[800],
    text: color.core.white,
  },
});

export const SubtitleColor = Object.freeze({
  text: DashboardColor.text,
});
