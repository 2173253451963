import { Snapshots } from '@pkg/entities';
import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import handlePropertiesInput from '../handlePropertiesInput';

/**
 * @param {Object} fromSnapshot
 * @param {String} fromUuid
 * @param {Object} toSnapshot
 * @param {String} toUuid
 * @param {String} name
 * @returns {Object}
 */
export default function publishTo({
  fromSnapshot,
  fromUuid,
  toSnapshot,
  toUuid,
  name,
}) {
  const now = Date.now();
  const uuidMap = { [fromUuid]: toUuid };

  const groupMutations = { update: [] };
  const roleMutations = { create: [], update: [], remove: [] };
  const activityMutations = { create: [], update: [], remove: [] };

  const fromEntities = fromSnapshot.entities;
  const toEntities = toSnapshot.entities;

  const toKeyed = Snapshots.utils.getKeyed(toSnapshot);
  const toSet = {
    groups: new Set(toEntities.groups.map(({ uuid }) => uuid)),
    roles: new Set(toEntities.roles.map(({ uuid }) => uuid)),
    activities: new Set(toEntities.activities.map(({ uuid }) => uuid)),
  };

  const toGroup = {};
  toGroup.group = toKeyed.entities.groups[toUuid];
  toGroup.roles = new Set(
    toEntities.roles
      .filter(({ group_uuid }) => group_uuid === toUuid)
      .map(({ uuid }) => uuid)
  );
  toGroup.activities = new Set(
    toEntities.activities
      .filter(
        ({ owner_uuid }) =>
          owner_uuid === toUuid || toGroup.roles.has(owner_uuid)
      )
      .map(({ uuid }) => uuid)
  );

  const group = Collections.findById(fromEntities.groups, fromUuid);
  groupMutations.update.push({
    uuid: toUuid,
    name,
    objective: group.objective,
    lead_uuid: group.lead_uuid,
    properties: handlePropertiesInput(
      group.properties,
      toGroup.group.properties
    ),
    tags: group.tags,
    updated_at: now,
  });

  if (!groupMutations.update[0].properties) {
    delete groupMutations.update[0].properties;
  }

  const roles = Collections.where(fromEntities.roles, 'group_uuid', group.uuid);
  const roleSet = new Set(roles.map(({ uuid }) => uuid));

  roles.forEach((role) => {
    const isExisting = toSet.roles.has(role.uuid);
    const isInGroup = isExisting && toGroup.roles.has(role.uuid);

    if (!isExisting) {
      roleMutations.create.push({
        ...role,
        budget: Number.isFinite(role.budget) ? role.budget : undefined,
        group_uuid: toUuid,
      });
    }

    if (isInGroup) {
      const target = toKeyed.entities.roles[role.uuid];
      const update = {
        uuid: role.uuid,
        external_id: role.external_id,
        group_uuid: toUuid,
        parent_uuid: role.parent_uuid,
        user_uuid: role.user_uuid,
        title: role.title,
        fte: role.fte,
        budget: Number.isFinite(role.budget) ? role.budget : undefined,
        properties: handlePropertiesInput(role.properties, target.properties),
        skills: role.skills,
        tags: role.tags,
        order: role.order,
        updated_at: now,
      };

      if (!update.properties) {
        delete update.properties;
      }

      roleMutations.update.push(update);
    }

    if (!isInGroup) {
      uuidMap[role.uuid] = shortuuid.generate();
      role.uuid = uuidMap[role.uuid];
      role.group_uuid = toUuid;
      roleMutations.create.push(role);
    }
  });

  roleMutations.create = roleMutations.create.map((role) => ({
    ...role,
    budget: Number.isFinite(role.budget) ? role.budget : undefined,
    parent_uuid: uuidMap[role.parent_uuid] ?? role.parent_uuid,
  }));

  roleMutations.update = roleMutations.update.map((role) => ({
    ...role,
    budget: Number.isFinite(role.budget) ? role.budget : undefined,
    parent_uuid: uuidMap[role.parent_uuid] ?? role.parent_uuid,
  }));

  const activitySet = new Set(
    fromEntities.activities
      .filter(
        ({ owner_uuid }) => owner_uuid === fromUuid || roleSet.has(owner_uuid)
      )
      .map((activity) => ({
        ...activity,
        owner_uuid: uuidMap[activity.owner_uuid] ?? activity.owner_uuid,
      }))
      .map((activity) => {
        const uuid = activity.uuid;
        const isExisting = toSet.activities.has(uuid);
        const isInGroup = isExisting && toGroup.activities.has(uuid);

        if (!isExisting) {
          activityMutations.create.push(activity);
        }

        if (isInGroup) {
          const target = toKeyed.entities.activities[uuid];
          const update = {
            uuid,
            library_uuid: activity.library_uuid,
            owner_uuid: activity.owner_uuid,
            owner_type: activity.owner_type,
            hours: activity.hours,
            properties: handlePropertiesInput(
              activity.properties,
              target.properties
            ),
            tags: activity.tags,
            order: activity.order,
            updated_at: now,
          };

          if (!update.properties) {
            delete update.properties;
          }

          activityMutations.update.push(update);
        }

        if (!isInGroup) {
          activityMutations.create.push({
            ...activity,
            uuid: shortuuid.generate(),
          });
        }

        return uuid;
      })
  );

  toGroup.roles.forEach((uuid) => {
    if (!roleSet.has(uuid)) {
      roleMutations.remove.push(uuid);
    }
  });

  toGroup.activities.forEach((uuid) => {
    if (!activitySet.has(uuid)) {
      activityMutations.remove.push(uuid);
    }
  });

  return {
    created_at: now,
    entities: {
      groups: groupMutations,
      roles: roleMutations,
      activities: activityMutations,
    },
  };
}
