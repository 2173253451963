import { Subtitle } from '@/atoms/typography';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Stack from '@mui/material/Stack';
import * as Auth from '@pkg/auth';
import Box from '@/components/Box';
import { Avatars } from '@/components/Presence';
import Typography from '@/components/Typography';
import { Static as Avatar } from '@/components/UserAvatar';

const ScenarioDetails = () => {
  const { scenario } = useScenarioContext();
  const { me } = Auth.useStore();

  const isOwner = me.uuid === scenario?.details?.owner?.uuid;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      width="100%"
    >
      <Box>
        <Box>
          <Subtitle margin="0 0 4px">Scenario</Subtitle>
        </Box>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {scenario?.details?.name}
        </Typography>
        {Boolean(scenario?.details?.goal) && (
          <>
            <Subtitle margin="0 0 4px">Goal</Subtitle>
            <Typography variant="body1" sx={{ mb: 2 }}>
              {scenario?.details?.goal}
            </Typography>
          </>
        )}
      </Box>
      {isOwner && Boolean(scenario?.details?.collaborators.length > 0) && (
        <Stack alignItems="center">
          <Avatars
            users={scenario.details.collaborators}
            sx={{ mb: 1 }}
            size="40px"
          />
          <Subtitle variant="body1" margin="8px 0 0">
            Shared with
          </Subtitle>
        </Stack>
      )}
      {!isOwner && (
        <Stack alignItems="center">
          <Avatar user={scenario.details?.owner} sx={{ mb: 1 }} size="40px" />
          <Subtitle variant="body1" margin="8px 0 0">
            Shared by
          </Subtitle>
        </Stack>
      )}
    </Stack>
  );
};

export default ScenarioDetails;
