import { useCallback, useEffect, useState } from 'react';
import env from '@beam-australia/react-env';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import { Library, Organisations, People } from '@pkg/entities';

const GET_PROFILE = /* GraphQL */ `
  query GetProfile {
    me {
      uuid
      first_name
      last_name
      email
      avatar
      is_owner
      is_role_only
      intercom_identity
      current_flows {
        uuid
        created_at
        campaign {
          flowTemplate {
            description
            is_onboarding
            name
            settings {
              tags
            }
            type
          }
        }
        payload {
          design_uuid
          entity {
            name
            uuid
          }
        }
        status
      }
      created_at
      updated_at
      registered_at
      seen_welcome_at
      access {
        permissions {
          design
          organisation
        }
        roles {
          design
          organisation
        }
      }
      features
      connections {
        uuid
        provider
      }
      scenarios {
        uuid
        type
      }
      sharedScenarios {
        uuid
        type
      }
    }
    organisation: me {
      data: organisation {
        uuid
        name
        is_disabled
        is_restricted
        design {
          uuid
          latest {
            uuid
          }
        }
        owner {
          uuid
          first_name
          last_name
          email
          avatar
        }
        integrations {
          uuid
          name
          type
          status
          latest_import {
            created_at
          }
          image_url
          created_at
        }
      }
    }
  }
`;

export default function useRefresh() {
  const library = Library.useLibrary();
  const client = useClient();
  const database = useDatabase();
  const [isRefreshing, setRefreshing] = useState(false);

  const refresh = useCallback(() => {
    if (!isRefreshing) {
      setRefreshing(true);
    }
  }, []);

  const handleRefresh = async () => {
    const [{ data }, current] = await Promise.all([
      await client.graphql({ query: GET_PROFILE }),
      await database.auth.get('me'),
    ]);

    // api returned a different user to the user in indexedDB
    if (current?.uuid && data?.me?.uuid !== current?.uuid) {
      try {
        await database.reset();
      } catch (error) {
        console.error('Error resetting database: ', error);
        await database.hash.clear();
      }
    }

    // api returned no user
    // user exists in frontegg but not Beamible or JWT expired
    /** @todo throw an error that is handled by an error boundary */
    if (!data?.me) {
      await database.transaction('rw', ['hash', 'auth'], () => {
        database.hash.put({ key: 'auth', updated_at: Date.now() });
        database.auth.put({ key: 'organisation' });
        return database.auth.put({ key: 'me' });
      });
      setRefreshing(false);
      return;
    }

    const me = People.deriveProps(library, data.me);
    const organisation = Organisations.deriveProps(
      library,
      data.organisation?.data
    );

    await database.transaction('rw', ['hash', 'build', 'auth'], () => {
      const buildId = env('PLATFORM_VER') ? env('PLATFORM_VER') : 'development';
      database.build.put({
        key: 'build',
        id: buildId,
      });
      database.hash.put({ key: 'auth', updated_at: Date.now() });
      return database.auth.bulkPut([
        { key: 'me', ...me },
        { key: 'organisation', ...organisation },
      ]);
    });

    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return {
    refresh,
    handleRefresh,
    isRefreshing,
  };
}
