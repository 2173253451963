import { useMemo } from 'react';
import { Snapshots } from '@pkg/entities';
import { Routes } from '@pkg/utils';
import config from '@/lib/config';
import { ClientError, DesignLevel, DesignScope } from '@/lib/enums';
import useDesignStore from '../../hooks/useDesignStore';

const CRUMB = {
  [DesignLevel.GROUP]: 'Group',
  [DesignLevel.MANAGER]: 'Manager',
  [DesignLevel.ORGANISATION]: 'Organisation',
  [DesignLevel.PERSON]: 'Person',
  [DesignLevel.ROLE]: 'Role',
  [DesignLevel.TEMPLATES]: 'Templates',
};

const OrganisationCrumb = new Set([
  DesignLevel.GROUP,
  DesignLevel.MANAGER,
  DesignLevel.ROLE,
]);

const GroupCrumb = new Set([
  DesignLevel.GROUP,
  DesignLevel.MANAGER,
  DesignLevel.ROLE,
]);

/**
 * @param {String} designId
 * @param {Boolean} isScenario
 * @param {String} level
 * @returns {Object}
 */
export default function useBreadcrumbs() {
  const design = useDesignStore((state) => state.design);
  const error = useDesignStore((state) => state.error);
  const isHistorical = useDesignStore((state) => state.isHistorical);
  const isLoading = useDesignStore((state) => state.isLoading);
  const isReady = useDesignStore((state) => state.isReady);
  const isScenario = useDesignStore((state) => state.isScenario);
  const level = useDesignStore((state) => state.level);
  const revision = useDesignStore((state) => state.revision);
  const snapshot = useDesignStore((state) => state.snapshot);
  const keyed = Snapshots.utils.useKeyed(snapshot);

  return useMemo(() => {
    if (!design || !revision) {
      return;
    }

    const breadcrumbs = {
      crumbs: [{ pathname: config.PATHS.DASHBOARD.index, label: 'Dashboard' }],
      current: CRUMB[level?.type],
    };

    if (isLoading || !isReady) {
      return breadcrumbs;
    }

    if (error === ClientError.NOT_AUTHORIZED) {
      breadcrumbs.current = 'Not authorized';
      return breadcrumbs;
    }

    if (error === ClientError.NOT_FOUND) {
      breadcrumbs.current = 'Not found';
      return breadcrumbs;
    }

    const designId = isScenario ? design.uuid : 'main';
    const revisionId = isHistorical ? revision.uuid : 'latest';

    if (isScenario) {
      breadcrumbs.crumbs.push({
        pathname: '/scenarios',
        label: 'Scenarios',
      });
    }

    if (level.type === design.scope) {
      return breadcrumbs;
    }

    if (OrganisationCrumb.has(level.type)) {
      if (design.scope === DesignScope.ORGANISATION) {
        breadcrumbs.crumbs.push({
          pathname: Routes.build.designParts({ designId, revisionId }),
          label: CRUMB[DesignLevel.ORGANISATION],
        });
      }
    }

    if (GroupCrumb.has(level.type)) {
      const plural = level.type === DesignLevel.GROUP ? 'groups' : 'roles';
      const entity = keyed.entities[plural][level.uuid];

      if (entity?.group_uuid) {
        breadcrumbs.crumbs.push({
          pathname: Routes.build.designParts({
            designId,
            revisionId,
            level: { type: DesignLevel.GROUP, uuid: entity.group_uuid },
          }),
          label: CRUMB[DesignLevel.GROUP],
        });
      }
    }

    return breadcrumbs;
  }, [
    design,
    error,
    keyed,
    isScenario,
    isHistorical,
    isReady,
    isLoading,
    level,
    revision,
  ]);
}
