import { useMemo } from 'react';
import { useQuery } from '@pkg/graphql';
import * as Library from '../library';
import deriveProps from './deriveProps';

export const GET_PEOPLE = /* GraphQL */ `
  query GetPeople($ids: [UUID!]!) {
    people(ids: $ids) {
      uuid
      name
      first_name
      last_name
      email
      registered_at
      is_role_only
    }
  }
`;

export default function usePeopleQuery(ids = []) {
  const library = Library.useLibrary();
  const query = [GET_PEOPLE, { ids }];
  const { data, isLoading } = useQuery({
    query,
    enabled: Boolean(ids.length),
  });

  return useMemo(() => {
    const map = new Map();
    const list = [];

    (data?.people ?? []).forEach((person) => {
      const derivedPerson = deriveProps(library, person);
      map.set(person.uuid, derivedPerson);
      list.push(derivedPerson);
    });

    return { map, list, isLoading };
  }, [JSON.stringify(ids), data]);
}
