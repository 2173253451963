import {
  ActivityInsights,
  AlignmentInsights,
  PrioritisationInsights,
  RoleInsights,
  SpansLayersInsights,
  SummaryInsights,
  TagInsights,
} from '@/organisms/insights';
import { ScopeType } from '@/shared/enums';

const InsightScope = ({ scope }) => {
  switch (scope) {
    case ScopeType.ROLES:
      return <RoleInsights />;
    case ScopeType.SPANS_LAYERS:
      return <SpansLayersInsights />;
    case ScopeType.TAGS:
      return <TagInsights />;
    case ScopeType.ACTIVITIES:
      return <ActivityInsights />;
    case ScopeType.PRIORITISATION:
      return <PrioritisationInsights />;
    case ScopeType.ALIGNMENT:
      return <AlignmentInsights />;
    default:
      return <SummaryInsights />;
  }
};

export default InsightScope;
