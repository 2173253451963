import { EntityType } from '@/shared/enums';

const ColorSpectrum = Object.freeze({
  [EntityType.ROLE]: [
    {
      backgroundColor: '#93F6FF',
      borderColor: '#6DDDE7',
    },
    {
      backgroundColor: '#5FF4FB',
      borderColor: '#41E2EA',
    },
    {
      backgroundColor: '#2EE6EE',
      borderColor: '#22D1D9',
    },
    {
      backgroundColor: '#08C6D3',
      borderColor: '#0DB1BA',
    },
    {
      backgroundColor: '#00AABA',
      borderColor: '#02909F',
    },
  ],
  [EntityType.GROUP]: [
    {
      backgroundColor: '#88C7FF',
      borderColor: '#55A4EA',
    },
    {
      backgroundColor: '#74BCFC',
      borderColor: '#4C9DE5',
    },
    {
      backgroundColor: '#4DAAFF',
      borderColor: '#3394EC',
    },
    {
      backgroundColor: '#3698F1',
      borderColor: '#1976CC',
    },
    {
      backgroundColor: '#007CED',
      borderColor: '#005FBA',
    },
  ],
  [EntityType.MANAGER]: [
    {
      backgroundColor: '#D0C4FF',
      borderColor: '#B8AAF2',
    },
    {
      backgroundColor: '#B19EFF',
      borderColor: '#9D87F6',
    },
    {
      backgroundColor: '#9982FF',
      borderColor: '#876EF1',
    },
    {
      backgroundColor: '#7D5FFF',
      borderColor: '#6748EC',
    },
    {
      backgroundColor: '#6949F6',
      borderColor: '#4728CD',
    },
  ],
  INACTIVE: [
    {
      backgroundColor: '#E2E7EF',
      borderColor: '#CCD7E9',
    },
    {
      backgroundColor: '#D6DEEA',
      borderColor: '#BCC8D9',
    },
    {
      backgroundColor: '#C0CADA',
      borderColor: '#A6B7D1',
    },
    {
      backgroundColor: '#A6B2C4',
      borderColor: '#8A9DB9',
    },
    {
      backgroundColor: '#909FB7',
      borderColor: '#6F83A2',
    },
  ],
});

export default ColorSpectrum;
