import { DesignEntity } from '@/lib/enums';

export default function deriveEntityProps(entity, type, design) {
  const designName = design.is_scenario ? design.name : 'Main design';
  const designId = design.uuid;

  if (type === DesignEntity.ORGANISATION) {
    return {
      ...entity.latest?.snapshot,
      __design: designName,
      __designId: designId,
      __type: type,
      __isScenario: design.is_scenario,
      __uuid: entity.uuid,
    };
  }

  return {
    ...entity,
    __design: designName,
    __designId: designId,
    __type: type,
    __isScenario: design.is_scenario,
  };
}
