import { Snapshots } from '@pkg/entities';
import { DesignEntity } from '@/lib/enums';
import * as Get from '../get';
import derive from './derive';

/**
 *
 * @param {Object} me
 * @param {Object} design
 * @param {String[]} requiredTypes
 * @returns {Object}
 */
export default function bulk(me, design, requiredTypes) {
  const relationships = {};

  const snapshot = design.latest.snapshot;
  const entities = snapshot.entities;

  const keyed = Snapshots.utils.getKeyed(snapshot);
  const relatedEntities = Get.related(keyed?.entities);
  const managedEntities = Get.managed(snapshot, relatedEntities, me?.uuid);
  const roleSet = Get.roles(me);

  const relate = derive(me, relatedEntities, managedEntities, roleSet);

  Object.keys(entities).forEach((entityType) => {
    const type = DesignEntity.fromPlural(entityType);
    if (!requiredTypes.includes(type)) {
      return;
    }

    entities[entityType].forEach((entity) => {
      relationships[entity.uuid] = relate(entity, type);
    });
  });

  const designType = design.is_scenario
    ? DesignEntity.SCENARIO
    : DesignEntity.DESIGN;
  relationships[design.uuid] = relate(design, designType);

  return relationships;
}
