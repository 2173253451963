import { ScopeType, ViewMode } from '@/shared/enums';

/**
 * Sets the default scope when switching view modes.
 *
 * @param {String} viewMode
 *
 * @return {String}
 */
export default function defaultScope(viewMode) {
  switch (viewMode) {
    case ViewMode.INSIGHTS:
      return ScopeType.SUMMARY;
    default:
      return ScopeType.ACTIVITIES;
  }
}
