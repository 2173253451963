import { cloneElement, Fragment } from 'react';
import { Divider } from '@/atoms/dividers';
import TextListItem from '@/molecules/lists/Text';
import MuiList from '@mui/material/List';

const List = ({
  ListItem = <TextListItem />,
  items,
  onClick,
  selected,
  showDividers,
}) => {
  return (
    items.length > 0 && (
      <MuiList sx={{ p: 0 }}>
        {items.map((item, index) => (
          <Fragment key={item.id}>
            {cloneElement(ListItem, {
              index,
              item,
              onClick,
              selected,
            })}
            {showDividers && index !== items.length - 1 && <Divider />}
          </Fragment>
        ))}
      </MuiList>
    )
  );
};

export default List;
