import { DesignEntity } from '@/lib/enums';

const TagListText = Object.freeze({
  [DesignEntity.ROLE]: {
    title: 'How you have tagged your activities',
    empty: `This is where you will find a breakdown of all tags that have been applied 
      to activities.`,
  },
  [DesignEntity.GROUP]: {
    title: 'How your team has tagged their activities',
    empty: `This is where you will find a breakdown of all tags that team members 
      have applied to their activities.`,
  },
  [DesignEntity.ORGANISATION]: {
    title: 'How your organisation has tagged their activities',
    empty: `This is where you will find a breakdown of all tags that organisation
      members have applied to their activities.`,
  },
});

export default TagListText;
