const FilterEntity = Object.freeze({
  TAG: 'TAG',
  GROUP: 'GROUP',
  ROLE: 'ROLE',
  ACTIVITY: 'ACTIVITY',
  SKILL: 'SKILL',
  PEOPLE: 'PEOPLE',
  PROPERTY: 'PROPERTY',
});

export const filterEntityToPlural = (entity) => {
  switch (entity?.toUpperCase()) {
    case FilterEntity.TAG:
      return 'tags';
    case FilterEntity.GROUP:
      return 'groups';
    case FilterEntity.ROLE:
      return 'roles';
    case FilterEntity.ACTIVITY:
      return 'activities';
    case FilterEntity.SKILL:
      return 'skills';
    case FilterEntity.PEOPLE:
      return 'people';
    case FilterEntity.PROPERTY:
      return 'properties';
    default:
      return null;
  }
};

export default FilterEntity;
