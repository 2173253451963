import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

export const CREATE_EXPORT = /* GraphQL */ `
  mutation Export($input: ExportRequestInput!) {
    export(input: $input) {
      uuid
    }
  }
`;

export default function useCreate(query) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: CREATE_EXPORT,
    invalidateQuery: query,
  });

  /**
   * @param {Object} exportables
   * @param {String} [exportables.designs]
   * @param {String[]} [exportables.libraries]
   */
  function create({ designs, libraries }) {
    enqueueSnackbar("We're processing your export, look out for an email!");
    return mutateAsync({ designs, libraries });
  }

  return create;
}
