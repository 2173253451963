import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { IconHeading } from '@/molecules/headings';
import { List, DateListItem } from '@/molecules/lists';
import { FlowType } from '@/shared/enums';
import { useFlowsContext } from '@/shared/providers/FlowsProvider';
import ActionIcon from '@mui/icons-material/EventAvailableRounded';
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Stack from '@mui/material/Stack';
import { DesignEntity } from '@/lib/enums';
import filterFlowList from './filterFlowList';

const FlowList = ({ entityId, flowStyle }) => {
  const { flows: userFlows } = useFlowsContext();
  const [flowCount, setFlowCount] = useState(0);

  const [flows, setFlows] = useState();

  const router = useRouter();

  const handleClick = (event, item) => {
    // get query string from router
    const { query } = router;
    const { key } = query;

    const parts = [`/f/${item.id}`];

    if (key) {
      parts.push(`?key=${key}`);
    }

    const url = parts.join('');

    router.push(url);
  };

  const showEntityTitles = flows?.length > 1 && !entityId;
  const subtitle = `You have ${flowCount} outstanding ${
    flowCount > 1 ? 'actions' : 'action'
  }`;
  const noFlowsSubtitle = `You don't have any outstanding actions`;

  useEffect(() => {
    if (!userFlows.size) {
      return;
    }

    const { flowList, flowCount } = filterFlowList({
      flowMap: userFlows.get(FlowType.ROLE_WIZARD),
      entityId,
      flowStyle,
    });

    setFlows(flowList);
    setFlowCount(flowCount);
  }, [userFlows]);

  return (
    <>
      <IconHeading
        color="tertiary"
        icon={<ActionIcon />}
        size={Size.LARGE}
        subtitle={flowCount > 0 ? subtitle : noFlowsSubtitle}
        title="Actions"
      />
      {flows?.map((flow) => (
        <Grow key={flow.id} in={true} timeout={500}>
          <Box mb={flows.length > 1 ? 2 : 0} mt={3}>
            {' '}
            {showEntityTitles && (
              <Stack
                direction="row"
                alignItems="center"
                spacing={1}
                mb={1}
                ml={0.5}
              >
                <EntityChip type={DesignEntity.ROLE} size={Size.X_SMALL} />
                <Heading variant="h4" overrideStyles={{ fontWeight: 500 }}>
                  {flow.name}
                </Heading>
              </Stack>
            )}
            <List
              items={flow.flows}
              ListItem={<DateListItem />}
              onClick={handleClick}
            />
          </Box>
        </Grow>
      ))}
    </>
  );
};
export default FlowList;
