import { createTheme } from '@mui/material/styles';
import buttons from './buttons';
import { color, typography } from './tokens';

const palette = {
  primary: {
    contrastText: color.core.white,
    main: color.primary.brand[800],
    dark: color.primary.brand[900],
  },
  secondary: {
    contrastText: '#ffffff',
    main: '#F66470',
    light: '#fc3595',
    dark: '#b00256',
  },
  tertiary: {
    contrastText: '#333333',
    main: '#facd04',
    light: '#ffdc3e',
    dark: '#edc50f',
  },
  default: {
    main: color.primary.brand[800],
    dark: color.primary.brand[900],
  },
  success: {
    main: color.system.success[300],
    dark: color.system.success[400],
  },
  destructive: {
    main: color.system.destructive[300],
    dark: color.system.destructive[400],
  },
  text: {
    primary: '#333333',
    secondary: '#606060',
    disabled: '#A6B1BB',
  },
  activities: {
    LILAC_REGULAR: '#c56cf0',
    PINK_REGULAR: '#ffb8b8',
    RED_REGULAR: '#ff3838',
    ORANGE_REGULAR: '#ff9f1a',
    YELLOW_REGULAR: '#fff200',
    GREEN_REGULAR: '#3ae374',
    CYAN_REGULAR: '#67e6dc',
    BLUE_REGULAR: '#17c0eb',
    PURPLE_REGULAR: '#7158e2',
    GREY_REGULAR: '#3d3d3d',
    LILAC_LIGHT: '#cd84f1',
    PINK_LIGHT: '#ffcccc',
    RED_LIGHT: '#ff4d4d',
    ORANGE_LIGHT: '#ffaf40',
    YELLOW_LIGHT: '#fffa65',
    GREEN_LIGHT: '#32ff7e',
    CYAN_LIGHT: '#7efff5',
    BLUE_LIGHT: '#18dcff',
    PURPLE_LIGHT: '#7d5fff',
    GREY_LIGHT: '#4b4b4b',
  },
};

export default function () {
  return createTheme({
    palette,
    typography,
    scrollbars: {
      '&::-webkit-scrollbar': {
        width: 8,
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#c1c1c1',
        display: 'block',
        height: 4,
        width: 4,
      },
      '&::-webkit-scrollbar-track': {
        background: '#eeeeee',
      },
    },
    components: {
      ...buttons,
      MuiCssBaseline: {
        styleOverrides: {
          a: {
            color: palette.primary.main,
            textDecoration: 'none',
          },
          '@global': {
            a: {
              color: palette.primary.main,
              textDecoration: 'none',
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: 16,
            '&:last-child': {
              paddingBottom: 16,
            },
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '& .MuiDataGrid-columnHeader:last-of-type .MuiDataGrid-columnSeparator':
              {
                display: 'none',
              },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            '& + .MuiFormControl-root': {
              marginTop: 12,
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: color.primary.grey[500],
            display: 'block',
            fontSize: 12,
            fontWeight: 500,
            marginBottom: 2,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            minWidth: 36,
          },
          positionEnd: {
            right: 0,
          },
          positionStart: {
            left: 0,
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: '0 !important',
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: color.primary.grey[300],
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: `${color.primary.brand[800]} !important`,
            },
            '&.Mui-error .MuiOutlinedInput-notchedOutline': {
              borderColor: `${color.system.destructive[300]} !important`,
            },
            '& .MuiInputAdornment-root': {
              margin: 0,
              position: 'absolute',
            },
          },
          input: {
            backgroundColor: color.core.white,
            boxSizing: 'border-box',
            color: color.primary.grey[500],
            fontSize: 14,
            fontWeight: 500,
            height: 36,
            lineHeight: '20px',
            padding: '8px 12px',
            width: '100%',
            '&:disabled': {
              backgroundColor: color.primary.grey[100],
              color: color.primary.brand[200],
            },
            '&[readonly]': {
              cursor: 'default',
            },
          },
          notchedOutline: {
            border: `2px solid ${color.primary.grey[200]}`,
            borderRadius: 4,
          },
          inputAdornedStart: {
            paddingLeft: 32,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: '1px solid rgba(144, 144, 144, 0.38)',
            },
          },
        },
      },
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            color: palette.primary.main,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 45,
          },
        },
      },
      MuiSkeleton: {
        styleOverrides: {
          root: {
            backgroundColor: 'rgba(0, 0, 0, 0.055)',
          },
          rounded: {
            borderRadius: '4px/6.7px',
            transform: 'scale(1, 0.7)',
          },
          text: {
            transform: 'scale(1, 0.7)',
          },
        },
      },
    },
    zIndex: {
      appBar: 1200,
      drawer: 1100,
    },
  });
}
