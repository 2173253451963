import throttle from 'lodash/throttle';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import useFetchRevision from '../../revisions/useFetch';
import { CollaboratorFragment } from '../fragments';
import useNotify from '../useNotify';

const GET_DESIGN_SHARING = /* GraphQL */ `
  query GetDesignSharing($designId: UUID!) {
    design(uuid: $designId) {
      is_public
      updated_at
      collaborators {
        ...CollaboratorFragment
      }
    }
  }

  ${CollaboratorFragment}
`;

const THROTTLE_TIMEOUT = 5000;

export default function useListen(designId) {
  const client = useClient();
  const database = useDatabase();
  const fetchRevision = useFetchRevision();

  const handleVersioned = async ({ id }) => {
    let revision = await database.designRevisions.get(id);
    if (!revision) {
      const result = await fetchRevision(id);
      revision = result.revision;
    }

    if (!revision) {
      return;
    }

    const design = await database.designs.get(designId);
    if (id > design.latest.uuid) {
      await database.designs.update(designId, { latest: { uuid: id } });
    }
  };

  const handleShared = async () => {
    const response = await client.graphql({
      query: GET_DESIGN_SHARING,
      variables: { designId },
    });

    const design = response.data?.design;
    if (design) {
      await database.designs.update(designId, design);
    }
  };

  const throttleVersioned = throttle(handleVersioned, THROTTLE_TIMEOUT);

  const eventHandlers = [
    {
      event: '.design.versioned',
      context: 'designs',
      callback: throttleVersioned,
    },
    { event: '.design.shared', context: 'designs', callback: handleShared },
  ];

  useNotify(designId, eventHandlers);
}
