import { LayerDimensions, SpanDimensions } from '@/molecules/chartElements';
import { EntityType } from '@/shared/enums';
import {
  useViewModeContext,
  useScenarioContext,
  usePropertyContext,
} from '@/shared/providers';
import Grid from '@mui/material/Grid';
import { EntityMetric } from '@/lib/enums';
import InsightsTitle from '../InsightsTitle/InsightsTitle';
import LayersDistribution from './LayersDistribution';
import ManagerSpans from './ManagerSpans';

const RoleInsights = () => {
  const { scenario, snapshotEntityMap } = useScenarioContext();
  const { id, scope, scopeEntity, showBudget } = useViewModeContext();
  const { propertyMap } = usePropertyContext();
  const entityType = scenario?.entity?.is_manager
    ? EntityType.MANAGER
    : scenario?.entity?.__type;

  const layerMetricOptions = [EntityMetric.FTE, EntityMetric.BUDGET];

  const spanMetricOptions = [EntityMetric.SPAN, EntityMetric.AVERAGE_SPAN];

  return (
    <Grid container spacing={2} pb={4}>
      <Grid item xs={12}>
        <InsightsTitle
          entityMap={snapshotEntityMap}
          id={id}
          scope={scope}
          scopeEntity={scopeEntity}
        />
      </Grid>
      {scenario && (
        <>
          <Grid item xs={12}>
            <ManagerSpans levelType={entityType} />
          </Grid>
          <Grid item xs={12}>
            <LayersDistribution
              metricOptions={spanMetricOptions}
              dimensions={SpanDimensions}
              dimensionLabel="Span by"
              levelType={entityType}
              title="How spans are distrbuted"
            />
          </Grid>
          <Grid item xs={12}>
            <LayersDistribution
              metricOptions={layerMetricOptions}
              dimensions={LayerDimensions}
              dimensionLabel="Layer by"
              levelType={entityType}
              title="How layers are distributed"
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default RoleInsights;
