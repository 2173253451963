import FlexSearch from 'flexsearch';

/**
 * @param {Object[]} [entities]
 * @param {Function} getSearchable
 * @param {String} q
 * @returns {Object}
 */
export default function text(entities, getSearchable, q) {
  if (!q) {
    return entities.reduce((entities, { uuid }) => {
      entities[uuid] = true;
      return entities;
    }, {});
  }

  const index = new FlexSearch.Index({ tokenize: 'forward' });
  entities.forEach((entity) => {
    index.add(entity.uuid, getSearchable(entity));
  });

  const results = index.search(q);

  return results.reduce((entities, uuid) => {
    entities[uuid] = true;
    return entities;
  }, {});
}
