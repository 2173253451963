import { create } from 'zustand';
import createActionsSlice, { INITIAL_ACTIONS_STATE } from './slices/actions';
import createDataSlice, { INITIAL_DATA_STATE } from './slices/data';
import createOptionsSlice, { INITIAL_OPTIONS_STATE } from './slices/options';

const INITIAL_STATE = {
  ...INITIAL_ACTIONS_STATE,
  ...INITIAL_DATA_STATE,
  ...INITIAL_OPTIONS_STATE,
};

const useDesignStore = create((set, get) => ({
  ...createActionsSlice(set),
  ...createDataSlice(set),
  ...createOptionsSlice(set),
  reset: (property = '*') => {
    console.debug(`DesignStore.reset.${property}`);
    if (property !== '*') {
      return set({ [property]: INITIAL_STATE[property] });
    }

    // Avoid resetting filter conditions.
    const conditions = get().conditions;

    return set({ ...INITIAL_STATE, conditions });
  },
  set: (state) => {
    console.debug('DesignStore.set', state);
    return set(state);
  },
}));

export default useDesignStore;
