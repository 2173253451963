import { Chip, EntityChip } from '@/atoms/chips';
import { Entity as EntityColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { TextMetric } from '@/atoms/metrics';
import { Heading } from '@/atoms/typography';
import { ChangeMetric } from '@/molecules/metrics';
import { OutlineCard } from '@/organisms/cards';
import { EntityType } from '@/shared/enums';
import { useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { core } from '@/lib/theme/tokens/palettes';
import {
  calculateEntityChanges,
  determineChartType,
  determineEntityChange,
  determineMetric,
} from './utils';

const PlanEntityTypeCard = ({
  actionType,
  entityType = EntityType.ROLE,
  metrics,
  onEdit,
  onRemove,
  entityMap,
  entityCount,
  title,
}) => {
  const { showBudget } = useViewModeContext();
  const entityColor = EntityColors[entityType];
  const metric = determineMetric({ metrics, showBudget });
  const chartType = determineChartType(metric);
  const entityChange = determineEntityChange({
    metrics,
    entityCount,
    entityType,
  });
  const changes = calculateEntityChanges(entityMap, entityType);

  return (
    <Stack direction="row" alignItems="center" width="100%">
      <Box width="136px" mr={1} flexShrink={0}>
        <ChangeMetric
          chartType={chartType}
          colors={{
            foreground: entityColor.primary,
            background: entityColor.secondary,
          }}
          entityType={entityType}
          metric={metric}
          metrics={metrics}
        />
      </Box>
      <Box flexGrow={1}>
        <OutlineCard padding={1.5} margin={1.5}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box>
              {actionType && (
                <Chip
                  id={actionType}
                  name={actionType.toUpperCase()}
                  colorProps={{
                    normal: {
                      backgroundColor: entityColor.primary,
                      color: core.white,
                    },
                  }}
                  size={Size.XX_SMALL}
                  fontWeight={600}
                  sx={{ height: 20, px: 1 }}
                />
              )}
              {!actionType && (
                <EntityChip
                  size={Size.XX_SMALL}
                  sx={{ height: 20, px: 1 }}
                  type={entityType}
                />
              )}
              <Box>
                <Heading
                  variant="h4"
                  overrideStyles={{
                    fontSize: '1.075rem',
                    mb: 0,
                    mt: 0.5,
                  }}
                >
                  {title}
                </Heading>
              </Box>
            </Box>
            <Stack alignItems="center" direction="row" spacing={2}>
              {changes.added > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Added"
                  size={Size.X_SMALL}
                  value={changes.added}
                />
              )}
              {changes.removed > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Removed"
                  size={Size.X_SMALL}
                  value={changes.removed}
                />
              )}
              {changes.moved > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Moved"
                  size={Size.X_SMALL}
                  value={changes.moved}
                />
              )}
              {changes.changed > 0 && (
                <TextMetric
                  alignItems="center"
                  label="Changed"
                  size={Size.X_SMALL}
                  value={changes.changed}
                />
              )}
            </Stack>
          </Stack>
        </OutlineCard>
      </Box>
    </Stack>
  );
};

export default PlanEntityTypeCard;
