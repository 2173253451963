import config from '@/lib/config';
import useMutate from './useMutate';

/**
 * @returns {Object}
 */
export default function useCreateGroup() {
  const mutate = useMutate();

  /**
   * @param {Object} design_uuid
   * @param {Object} group
   * @returns {Promise}
   */
  function createGroup(design_uuid, group) {
    const now = Date.now();

    group.name = config.DEFAULT_GROUP_NAME;
    group.objective = '';

    return mutate(design_uuid, {
      created_at: now,
      entities: {
        groups: {
          create: [
            {
              ...group,
              created_at: now,
              updated_at: now,
            },
          ],
        },
      },
    });
  }

  return createGroup;
}
