import { Size } from '@/atoms/enums';

const LabelSize = Object.freeze({
  [Size.SMALL]: {
    fontSize: '0.75rem',
    lineHeight: '1rem',
  },
  [Size.MEDIUM]: {
    fontSize: '0.825rem',
    lineHeight: '1.075rem',
  },
  [Size.LARGE]: {
    fontSize: '0.9rem',
    lineHeight: '1.15rem',
  },
});

export default LabelSize;
