import { DateTimePicker } from '@mui/x-date-pickers-pro';

const defineViews = (showTime) => {
  const view = ['year', 'month', 'day'];

  if (showTime) {
    view.push('hours', 'minutes');
  }

  return view;
};

const DateTime = ({
  initialValue,
  onChange,
  onOpen,
  onClose,
  open,
  slots,
  slotProps,
  showTime,
  value,
}) => {
  const views = defineViews(showTime);

  const handleChange = (value, validation) => {
    if (validation?.validationError) {
      return;
    }
    onChange?.(value);
  };

  return (
    <DateTimePicker
      initialValue={initialValue}
      onChange={handleChange}
      onClose={onClose}
      onOpen={onOpen}
      open={open}
      slotProps={slotProps}
      slots={slots}
      value={value}
      views={views}
    />
  );
};

export default DateTime;
