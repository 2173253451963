import { useEffect } from 'react';
import Sdk from '@pkg/sdk';

/**
 * @param {PresenceChannel} channel
 *
 * @param {Object} presence
 * @param {Function} presence.setPeople
 * @param {Function} presence.setMe
 *
 * @returns {undefined}
 */
export default function useArrive(channel, presence) {
  useEffect(() => {
    function handleArrive(payload) {
      if (payload.me.info.guest_token) {
        Sdk.storeGuestToken(payload.me.info.guest_token);
      }

      presence.setPeople(Object.values(payload.members));
      presence.setMe(payload.me.info);
    }

    if (channel) {
      channel.on('pusher:subscription_succeeded', handleArrive);
    }
  }, [channel]);
}
