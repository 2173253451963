import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@/components/Tooltip';
import * as UserAvatar from '@/components/UserAvatar';

const useStyles = makeStyles({
  avatar: {
    transition: 'all 0.25s',
    opacity: ({ isIdle }) => (isIdle ? 0.3 : 1),
    '&:hover': {
      cursor: 'pointer',
      transform: 'scale(1.3)',
      opacity: 1,
    },
  },
});

const PresenceAvatar = ({ user, className, style, ...props }) => {
  const classes = useStyles({ isIdle: user.isIdle });

  return (
    <motion.div
      key={user.uuid}
      initial={{ opacity: 0, x: -30 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -30 }}
      className={className}
      style={{ borderRadius: '50%', ...style }}
    >
      <Tooltip title={`${user.first_name} ${user.last_name}`} arrow>
        <div>
          <UserAvatar.Static
            data-testid="presence-avatar"
            size={34}
            user={user}
            className={classes.avatar}
            {...props}
          />
        </div>
      </Tooltip>
    </motion.div>
  );
};

PresenceAvatar.propTypes = {
  user: PropTypes.object,
  color: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default PresenceAvatar;
