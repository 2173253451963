import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';
import { DesignEntity } from '@/lib/enums';

const INVITE_TO_MANAGE_GROUPS = /* GraphQL */ `
  mutation InviteToManageGroups($input: [InviteUserInput!]!) {
    createManyInvites(input: $input)
  }
`;

/**
 * @param {any[]} query
 * @return {Function}
 */
export default function useInviteToManageGroups(query) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: INVITE_TO_MANAGE_GROUPS,
    invalidateQuery: query,
  });

  /**
   * @param {Object} designId
   * @param {Object[]} groups
   * @param {String} groups[].uuid
   * @param {String} groups[].lead_uuid
   * @param {String} groups[].name
   * @returns {Promise}
   */
  function inviteToManageGroups(designId, groups) {
    const input = groups
      .filter((group) => !!group.lead_uuid)
      .map((group) => ({
        uuid: group.lead_uuid,
        design_uuid: designId,
        entity: {
          uuid: group.uuid,
          name: group.name,
          type: DesignEntity.GROUP,
        },
      }));

    const length = input.length;
    const people = length > 1 ? 'people' : 'person';
    const entities = length > 1 ? 'teams' : 'team';
    enqueueSnackbar(`Inviting ${length} ${people} to manage their ${entities}`);
    return mutateAsync(input);
  }

  return inviteToManageGroups;
}
