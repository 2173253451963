import { useCallback, useMemo } from 'react';
import * as Auth from '@pkg/auth';
import { useDatabase } from '@pkg/database';
import { Obj } from '@pkg/utils';
import { get } from '@pkg/utils/objects';
import deriveProps from './deriveProps';
import getOrdered from './get/ordered';
import useMutateTagging from './mutations/useMutateTagging';
import * as Reducers from './reducers';
import useState from './useState';
import useStore from './useStore';

/**
 * @returns {Array}
 */
export default function useTags() {
  const { organisation } = Auth.useStore();
  const { tags, categories } = useStore();
  const database = useDatabase();

  const [state, mutateState] = useState({ tags, categories });
  const mutateTagging = useMutateTagging();

  const handler = (action, input) => {
    const mutation = get(Reducers, action)(state, input);
    if (!mutation) {
      return;
    }

    const { tags, categories } = mutateState(mutation);
    mutateTagging(organisation.uuid, mutation);

    database.transaction('rw', ['tags', 'tagCategories'], async () => {
      await database.tags.bulkPut(tags.list);
      database.tags.bulkDelete(mutation.tags?.remove ?? []);

      await database.tagCategories.bulkPut(categories.list);
      database.tagCategories.bulkDelete(mutation.categories?.remove ?? []);
    });
  };

  const onDispatch = useCallback(handler, [mutateState, mutateTagging, state]);
  return useMemo(() => {
    const raw = Obj.omitDerived({
      categories: state.categories.list,
      tags: state.tags.list,
    });

    const { tags, categories } = deriveProps(raw.tags, raw.categories);
    const ordered = getOrdered(tags, categories);
    return { onDispatch, ordered };
  }, [onDispatch, state]);
}
