import { useState, useEffect } from 'react';
import { calculateComparisonMetrics } from '@/shared/utils';
import { DesignEntity } from '@/lib/enums';

/**
 * Returns metrics comparing a primary scenario to a comparison scenario.
 *
 * @param {Object} primary
 * @param {Object} comparison
 * @return {Object}
 */
const useComparisonMetrics = (primary, comparison) => {
  const [metrics, setMetrics] = useState();
  const [activityMap, setActivityMap] = useState(new Map());

  useEffect(() => {
    if (!primary?.relationships?.size || !comparison?.relationships?.size) {
      return;
    }

    const metrics = calculateComparisonMetrics(primary, comparison);

    setMetrics(metrics);
    setActivityMap(
      new Map(comparison?.relationships?.get(DesignEntity.ACTIVITY))
    );
  }, [primary?.entity?.updated_at, comparison?.entity?.updated_at]);

  return {
    metrics,
    activityMap,
  };
};

export default useComparisonMetrics;
