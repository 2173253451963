import { EntityMetric } from '@/lib/enums';

export default function isPercentageMetric(metric) {
  switch (metric) {
    case EntityMetric.PERCENTAGE:
    case EntityMetric.RELATIVE_PERCENTAGE:
    case EntityMetric.ENTITY_PERCENTAGE:
      return true;
    default:
      return false;
  }
}
