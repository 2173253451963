import SnapshotMetricGroup from './SnapshotMetricGroup';

const DefaultEntityMetrics = Object.freeze({
  managed: {
    total: structuredClone(SnapshotMetricGroup),
    visible: structuredClone(SnapshotMetricGroup),
  },
  self: {
    total: structuredClone(SnapshotMetricGroup),
    visible: structuredClone(SnapshotMetricGroup),
  },
  span: {
    total: structuredClone(SnapshotMetricGroup),
    visible: structuredClone(SnapshotMetricGroup),
  },
});

export default DefaultEntityMetrics;
