const EventName = Object.freeze({
  OPENED_ENTITY_SELECTOR: 'Opened entity selector',
  OPENED_FILTERS: 'Opened filters',
  NAVIGATED_TO_ENTITY: 'Navigated to entity',
  SURVEY_STARTED: 'Survey started',
  SURVEY_NEXT: 'Survey progressed',
  SURVEY_COMPLETED: 'Survey completed',
  SWITCHED_VIEW_MODE: 'Switched view mode',
  SWITCHED_SCOPE: 'Switched scope',
});

export default EventName;
