import { useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import { TextField } from '@/molecules/formElements';
import { EntityName } from '@/shared/enums';
import { useScenarioContext } from '@/shared/providers';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import EntityChip from '@/components/EntityChip/EntityChip';

const AddScenarioForm = ({ onUpdate }) => {
  const { scenario, create } = useScenarioContext();
  const [scenarioName, setScenarioName] = useState();
  const [scenarioGoal, setScenarioGoal] = useState();
  const [isCreating, setIsCreating] = useState(false);

  const createScenario = async (event) => {
    const scenario = await create({
      scenarioName,
      scenarioGoal,
    });
    setIsCreating(false);
    onUpdate?.(event, scenario);
  };

  const handleCreateScenario = (event) => {
    setIsCreating(true);
    createScenario(event);
  };

  const handleNameChange = (event, value) => {
    setScenarioName(value);
  };

  const handleGoalChange = (event, value) => {
    setScenarioGoal(value);
  };

  const isScenario = scenario?.details?.isScenario;
  const entityName = scenario?.entity?.title ?? scenario?.entity?.name;
  const entityTypeName = EntityName[scenario?.entity?.__type]?.toLowerCase();

  return (
    <Grid container spacing={2} pt={4}>
      <Grid item xs={12}>
        <Heading variant="h3" overrideStyles={{ fontWeight: 600 }}>
          Create a new{' '}
          <EntityChip type={scenario?.entity.__type} size={Size.MEDIUM} />{' '}
          scenario
        </Heading>
      </Grid>
      <Grid item xs={12}>
        <Paragraph overrideStyles={{ mt: 1, mb: 2 }}>
          This will create a copy of the{' '}
          <span style={{ fontWeight: 600 }}>{entityName}</span> {entityTypeName}{' '}
          {isScenario ? (
            <>
              from the{' '}
              <span style={{ fontWeight: 600 }}>{scenario?.details?.name}</span>{' '}
              scenario.
            </>
          ) : (
            <>from the live design.</>
          )}
        </Paragraph>
      </Grid>
      <Grid item xs={12}>
        <TextField
          debounce={300}
          fullWidth
          name="scenarioName"
          label="Scenario name"
          onChange={handleNameChange}
          initialValue={scenarioName}
          size={Size.X_LARGE}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          optional
          debounce={300}
          name="scenarioGoal"
          label="Scenario goal"
          onChange={handleGoalChange}
          initialValue={scenarioGoal}
          size={Size.X_LARGE}
        />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={2} mt={3}>
          <Button
            disabled={!scenarioName}
            boldText={true}
            label="Create scenario"
            color="secondary"
            loading={isCreating}
            size={Size.LARGE}
            onClick={handleCreateScenario}
          />
          <Button
            label="Cancel"
            color="light-blue"
            size={Size.LARGE}
            onClick={onUpdate}
            variant="naked"
          />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AddScenarioForm;
