import { Collections } from '@pkg/utils';
import { BulkOperators } from '@/lib/enums';

export default function bulk(snapshot, bulk) {
  const now = Date.now();

  const mutation = {
    created_at: now,
    entities: {
      roles: {
        update: [],
      },
    },
  };

  if (!snapshot) {
    return mutation;
  }

  const { uuids, property, value, operator } = bulk;

  const convertValue = (operator, originalValue, newValue) => {
    let realValue = 0;
    switch (operator) {
      case BulkOperators.INCREASE:
        realValue = originalValue + newValue;
        break;
      case BulkOperators.INCREASE_PERCENTAGE:
        realValue = originalValue + originalValue * (newValue / 100);
        break;
      case BulkOperators.DECREASE:
        realValue = originalValue - newValue;
        break;
      case BulkOperators.DECREASE_PERCENTAGE:
        realValue = originalValue - originalValue * (newValue / 100);
        break;
      case BulkOperators.SET:
        realValue = newValue;
        break;
      default:
        realValue = newValue;
    }

    return realValue;
  };

  uuids.forEach((uuid) => {
    const entity = Collections.findById(snapshot.entities.roles, uuid);

    if (!entity) {
      return;
    }

    const originalValue = entity[property];
    let newValue = convertValue(operator, parseFloat(originalValue), value);

    if (property === 'budget') {
      newValue = Math.floor(Math.min(newValue, 999999999));
    }

    if (property === 'fte') {
      newValue = Math.min(newValue, 100);
    }

    mutation.entities.roles.update.push({
      uuid,
      [property]: newValue,
      updated_at: now,
    });
  });

  return mutation;
}
