/**
 * @param {Object} state
 * @param {Object} input
 * @param {String} input.uuid
 * @returns {Object}
 */
export default function remove(_, { uuid }) {
  return {
    tags: {
      remove: [uuid],
    },
  };
}
