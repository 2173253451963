import { useState } from 'react';
import { useQueries } from 'react-query';
import { useClient, GraphQLError } from '@pkg/client';

/**
 * @param {Object} params
 * @param {Array[]} [params.queries]
 * @return {Object}
 */
export default function useReactQueries({ queries, onError, ...options }) {
  const [isAuthError, setAuthError] = useState(false);
  const client = useClient();

  function handleErrors(error) {
    if (error instanceof GraphQLError && error.isAuthorizationError()) {
      setAuthError(true);
    }

    if (onError) {
      onError(error);
    }
  }

  const fetcherFactory = (query) => async () => {
    const [mutation, variables] = query;
    const response = await client.graphql({ query: mutation, variables });
    return response.data;
  };

  const defaultOptions = {
    refetchOnWindowFocus: false,
  };

  const queryObjects = queries.map((query) => ({
    queryKey: query,
    queryFn: fetcherFactory(query),
    onError: handleErrors,
    ...defaultOptions,
    ...options,
  }));

  return {
    isAuthError,
    results: useQueries(queryObjects),
  };
}
