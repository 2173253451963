const EventProperty = Object.freeze({
  ENTRY: 'Entry point',
  SCOPE: 'Scope',
  STEP_COUNT: 'Step count',
  STEP: 'Step number',
  STEP_NAME: 'Step name',
  VIEW_MODE: 'View mode',
});

export default EventProperty;
