const UnassignedRolesFilterPreset = {
  'role.title': {
    filterModels: [
      {
        filterType: 'text',
        type: 'blank',
      },
      null,
    ],
    filterType: 'multi',
  },
  'activity.description': {
    filterModels: [
      {
        filterType: 'text',
        type: 'notBlank',
      },
      null,
    ],
    filterType: 'multi',
  },
};

export default UnassignedRolesFilterPreset;
