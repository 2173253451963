import { useCallback, useEffect, useState } from 'react';
import * as Auth from '@pkg/auth';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';

const FLOW_TEMPLATES_QUERY = /* GraphQL */ `
  query GetFlowTemplates($uuid: UUID!) {
    flowTemplates(organisation_uuid: $uuid) {
      uuid
      description
      disclaimer
      created_at
      is_onboarding
      is_token_authenticated
      name
      settings {
        show_tags
        tags
      }
      type
    }
  }
`;

export default function useRefresh() {
  const { organisation, isAuthenticated } = Auth.useStore();
  const client = useClient();
  const database = useDatabase();
  const [isRefreshing, setRefreshing] = useState(false);

  const refresh = useCallback(() => {
    if (!isRefreshing) {
      setRefreshing(true);
    }
  }, []);

  const handleRefresh = async () => {
    const { data } = await client.graphql({
      query: FLOW_TEMPLATES_QUERY,
      variables: { uuid: organisation.uuid },
    });

    if (!data?.flowTemplates) {
      setRefreshing(false);
      return;
    }

    const newFlowTemplates = data.flowTemplates ?? [];
    const newFlowTemplatesSet = new Set(
      newFlowTemplates.map(({ uuid }) => uuid)
    );
    const flowTemplates = await database.flowTemplates.toArray();

    const removedFlowTemplates = flowTemplates
      .filter(({ uuid }) => !newFlowTemplatesSet.has(uuid))
      .map(({ uuid }) => uuid);

    const promises = [
      database.hash.put({ key: 'flowTemplates', updated_at: Date.now() }),
    ];

    if (removedFlowTemplates.length) {
      promises.push(database.flowTemplates.bulkDelete?.(removedFlowTemplates));
    }

    if (newFlowTemplates.length) {
      promises.push(database.flowTemplates.bulkPut?.(newFlowTemplates));
    }

    await Promise.all(promises);
    setRefreshing(false);
  };

  useEffect(() => {
    if (isRefreshing) {
      handleRefresh();
    }
  }, [isRefreshing]);

  return {
    refresh,
    isRefreshing,
  };
}
