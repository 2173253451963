import { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Position } from '@/lib/enums';
import { PackedCircleChart } from '@/components/Charts';
import ChartAlignment from '@/components/Charts/PackedCircle/QuadrantChart/ChartAlignment';
import formatQuadrantActivities from './formatQuadrantActivities';

const QuadrantItem = ({
  quadrant,
  axisLabels,
  position,
  totalActivityHours,
}) => {
  const [dataset, setDataset] = useState();

  useEffect(() => {
    if (!quadrant) {
      return;
    }

    setDataset(formatQuadrantActivities(quadrant, totalActivityHours));
  }, [quadrant]);

  return (
    Boolean(quadrant) &&
    Boolean(dataset) && (
      <Stack
        alignItems={
          position === Position.TOP_RIGHT || position === Position.BOTTOM_RIGHT
            ? 'flex-start'
            : 'flex-end'
        }
        justifyContent="center"
      >
        <PackedCircleChart
          axisLabels={axisLabels}
          data={dataset}
          color={quadrant.color}
          alignment={ChartAlignment[position]}
          sx={{
            height: 380,
            width: 380,
          }}
        />
      </Stack>
    )
  );
};

export default QuadrantItem;
