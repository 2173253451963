import { Base as BaseColors } from '@/atoms/colors';
import Box from '@mui/material/Box';
import MuiModal from '@mui/material/Modal';
import { core } from '@/lib/theme/tokens/palettes';
import ModalColors from './ModalColors';

const Modal = ({
  children,
  onClose,
  open,
  minHeight = '40vh',
  minWidth = '50vw',
  maxWidth,
  maxHeight = '90vh',
  padding = '48px 48px 32px',
}) => {
  const handleClose = (event) => {
    onClose?.(event);
  };

  return (
    <MuiModal
      open={open}
      onClose={handleClose}
      sx={{
        overflowY: 'auto',
        color: BaseColors.font.primary,
        '& .MuiModal-backdrop': {
          backgroundColor: ModalColors.backgroundColor,
        },
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: core.white,
          borderRadius: 2,
          border: `1px solid ${ModalColors.borderColor}`,
          boxShadow: `0 2px 4px 1px ${ModalColors.shadowColor}`,
          left: '50%',
          maxHeight,
          maxWidth: maxWidth,
          minHeight: minHeight,
          minWidth: minWidth,
          p: padding,
          position: 'absolute',
          top: '30%',
          transform: 'translate(-50%, -30%)',
          '&:focus-visible': {
            outline: 'none',
          },
        }}
      >
        {children}
      </Box>
    </MuiModal>
  );
};

export default Modal;
