import { useState } from 'react';
import { OutlineCard } from '@/organisms/cards';
import { DesignEntity } from '@/lib/enums';
import TagContent from '@/components/DashboardContainer/enums/TagContent';
import TaggedActivityList from './TaggedActivityList';
import TaggedTeamList from './TaggedTeamList';

const TaggedActivityListCard = ({
  entityType,
  inverse = false,
  tagId,
  breakdown = DesignEntity.ACTIVITY,
}) => {
  const { title } = TagContent[tagId][entityType];

  const breakdownText =
    breakdown === DesignEntity.GROUP ? 'teams' : 'activities';

  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };

  return (
    <OutlineCard
      title={inverse ? title.inverse : title.main}
      actionText={showAll ? `Hide ${breakdownText}` : `Show ${breakdownText}`}
      onClick={handleClick}
    >
      {breakdown === DesignEntity.ACTIVITY && (
        <TaggedActivityList inverse={inverse} tagId={tagId} showAll={showAll} />
      )}
      {breakdown === DesignEntity.GROUP && (
        <TaggedTeamList inverse={inverse} tagId={tagId} showAll={showAll} />
      )}
    </OutlineCard>
  );
};

export default TaggedActivityListCard;
