import PropTypes from 'prop-types';
import { memo, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import { Tags } from '@pkg/entities';
import Add from '@pkg/icons/Add';
import { palettes, typography } from '@/lib/theme/tokens';
import Button from '@/components/Button';
import Tag from '@/components/Tag';
import { InputType } from './Tags';

const AddMoreButton = styled(Button)({
  fontSize: '0.8rem',
  height: 23,
  lineHeight: 23,
  overflow: 'hidden',
  padding: '0 8px',
  '& .MuiButton-startIcon': {
    marginRight: 4,
  },
});

const HelperLabel = styled('p')({
  ...typography.caption,
  color: palettes.grey.colors[300],
  display: 'inline-block',
  fontWeight: 500,
  lineHeight: 1.2,
  margin: 4,
});

const Container = styled('div')({
  display: 'flex',
  flexFlow: 'row wrap',
  marginBottom: 8,
});

const Pill = styled(Tag)({
  marginBottom: 4,
  marginRight: 4,
});

const Pills = ({
  disabled,
  entityType,
  includeDisabled,
  onAddMore,
  onClick,
  readOnly,
  selected,
  sortBySelected,
  type,
}) => {
  const options = Tags.useStore((state) => state.options);
  const filtered = useMemo(() => {
    const sortList = [...selected];
    const optionsList = options[entityType].filter((option) => {
      if (!includeDisabled && option.is_disabled) {
        return false; // is disabled
      }

      if (type === InputType.REMOVEABLE && !selected.has(option.uuid)) {
        return false; // isn't selected
      }

      if (type === InputType.SELECTABLE && option.category_uuid) {
        return false; // isn't selected
      }

      return true;
    });

    // Sorts by the original selection order.
    if (sortBySelected) {
      return optionsList.sort((a, b) => {
        return sortList.indexOf(a.uuid) - sortList.indexOf(b.uuid);
      });
    }

    return optionsList;
  }, [options, entityType, includeDisabled, type, selected]);

  return (
    <Container>
      {filtered.map((tag) => (
        <Pill
          expanded
          key={tag.uuid}
          active={selected.has(tag.uuid)}
          onClick={!readOnly && !disabled ? onClick : null}
          tag={tag}
        />
      ))}
      {onAddMore && (
        <AddMoreButton
          onClick={onAddMore}
          startIcon={<Add style={{ fontSize: '0.8rem' }} />}
          variant="naked"
        >
          More tags
        </AddMoreButton>
      )}
      {type === InputType.SELECTABLE && !disabled && (
        <HelperLabel>Select all that apply</HelperLabel>
      )}
    </Container>
  );
};

Pills.propTypes = {
  disabled: PropTypes.bool,
  entityType: PropTypes.string,
  includeDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  readOnly: PropTypes.bool,
  selected: PropTypes.object,
  showAddMore: PropTypes.bool,
};

export default memo(Pills);
