import { tool } from 'ai';
import { z } from 'zod';

const roleAdd = tool({
  description: 'Add a role to the organisational design',
  parameters: z.object({
    total: z.number().describe('The number of this role to add'),
    payload: z
      .object({
        title: z.string().describe('The title of the entity'),
        fte: z.number().describe('The fte of the entity'),
        budget: z.number().describe('The budget of the entity'),
        hours: z.number().describe('The hours of the entity'),
      })
      .describe('The input that will get passed to add role'),
    explanation: z
      .string()
      .describe(
        'An explanation of what is about to mutated. This step will be asked to be confirmed. Phrase it accordingly.'
      ),
  }),
  execute: async ({ total, payload, explanation }) => ({
    total,
    payload,
    explanation,
  }),
});

export default roleAdd;
