import { useState } from 'react';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { EntityActivityDimensions } from '@/molecules/chartElements';
import { ActivityStackChartInformation } from '@/molecules/information';
import { ChartCard } from '@/organisms/cards';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';
import ActivityStackedBarChart from './ActivityStackedBarChart';

const metricOptions = [
  EntityMetric.RELATIVE_PERCENTAGE,
  EntityMetric.PERCENTAGE,
  EntityMetric.FTE,
  EntityMetric.HOURS,
  EntityMetric.BUDGET,
];

const ActivitySegments = ({ levelType }) => {
  const [metric, setMetric] = useState(EntityMetric.FTE);
  const [dimension, setDimension] = useState(
    EntityActivityDimensions[levelType]?.[0]
  );
  const [sort, setSort] = useState({
    metric: EntityMetric.FTE,
    type: 'DESC',
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();

  const { chartData, metricTotals } = useAggregateChartData({
    entityType: EntityType.ACTIVITY,
    dimension,
    filter,
    includeStack: true,
    sort,
  });

  const hasProperties = chartData?.length > 1;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  return (
    <ChartCard
      title="How work is distributed across roles"
      ControlsComponent={
        hasProperties && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={EntityActivityDimensions[levelType]}
              label="Activities by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              initialMetric={metric}
              onChange={handleMetricTypeChange}
              options={metricOptions}
            />
          </Stack>
        )
      }
      InfoComponent={<ActivityStackChartInformation />}
    >
      {hasProperties ? (
        <ActivityStackedBarChart
          chartData={chartData}
          metric={metric}
          metricTotals={metricTotals}
          sort={sort}
        />
      ) : (
        <EmptyInsights message="No activities were found." />
      )}
    </ChartCard>
  );
};

export default ActivitySegments;
