import { useLiveQuery } from 'dexie-react-hooks';
import { useDatabase } from '@pkg/database';
import useFetch from './useFetch';

/**
 * @returns {Object}
 */
export default function useMatrix() {
  const database = useDatabase();
  const { fetch, error, isError, isFetching } = useFetch();

  const query = async () => {
    const matrix = await database.auth.get({ key: 'policyMatrix' });

    if (!matrix && !isFetching) {
      fetch();
    }

    return matrix;
  };

  const matrix = useLiveQuery(query, [], null);
  const isLoading = !matrix;

  return { matrix, error, isError, isLoading };
}
