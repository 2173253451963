import { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useClient, GraphQLError } from '@pkg/client';

/**
 * @param {object} params
 * @param {any[]} [params.query]
 *
 * @return {object}
 */
export default function useGraphQLInfiniteQuery({
  query,
  onError,
  ...options
}) {
  const [isAuthError, setAuthError] = useState(false);
  const client = useClient();

  function handleErrors(error) {
    if (error instanceof GraphQLError && error.isAuthorizationError()) {
      setAuthError(true);
    }

    if (onError) {
      onError(error);
    }
  }

  async function fetcher({ pageParam = 1 }) {
    const [mutation, variables = {}] = query;
    variables.page = pageParam;
    delete variables.pageParam;

    const response = await client.graphql({ query: mutation, variables });
    return response.data;
  }

  const defaultOptions = {
    refetchOnWindowFocus: false,
  };

  const result = useInfiniteQuery(options.queryKey ?? query, fetcher, {
    onError: handleErrors,
    ...defaultOptions,
    ...options,
  });

  return {
    isAuthError,
    ...result,
  };
}
