import * as d3 from 'd3';
import { Position } from '@/lib/enums';
import renderCircle from '../render/renderCircle';
import renderCornerAxis from '../render/renderCornerAxis';
import renderGroup from '../render/renderGroup';
import renderRoot from '../render/renderRoot';
import renderSimulation from '../render/renderSimulation';
import renderSvg from '../render/renderSvg';
import renderTooltip from '../render/renderTooltip';

const PADDING = 30;

/**
 * @reference https://d3-graph-gallery.com/graph/circularpacking_template.html
 * @param {d3.Selection} chart
 * @param {Object[]} data
 * @returns {void}
 */
export default function renderChart({
  alignment,
  axisLabels,
  chart,
  classes,
  color,
  data,
}) {
  const canvasHeight = chart.node().offsetHeight;
  const canvasWidth = chart.node().offsetWidth;

  // Avoid rendering the chart if we don't have a canvas height or width.
  if (!canvasHeight || !canvasWidth) {
    return;
  }

  const canvasXOffset = PADDING / 2;
  const canvasYOffset = PADDING / 2;
  const width = canvasWidth - PADDING;
  const height = canvasHeight - PADDING;

  const scale = d3
    .scaleRadial()
    .domain([0, 100])
    .range([0, 100 - PADDING / 2]);

  const dataset = data.map(({ label, value }) => {
    return {
      label: `${label}: ${value}%`,
      value: Math.abs(scale(value)),
    };
  });

  const context = {
    alignment,
    classes,
    color,
    data: dataset,
    height,
    width,
    x0:
      alignment === Position.TOP_RIGHT || alignment === Position.BOTTOM_RIGHT
        ? canvasXOffset * -1
        : canvasXOffset,
    y0:
      alignment === Position.TOP_RIGHT || alignment === Position.TOP_LEFT
        ? canvasYOffset
        : canvasYOffset * -1,
  };

  renderRoot(chart, context);
  const tooltip = renderTooltip(chart, context);
  const svg = renderSvg(chart, context);
  const group = renderGroup(svg, context);
  const circle = renderCircle(group, context, tooltip.__events);

  renderCornerAxis(svg, {
    classes,
    labels: axisLabels,
    position: alignment,
    height,
    width,
    canvasWidth,
    canvasHeight,
  });

  renderSimulation(circle, context);
}
