import { Collections, Obj } from '@pkg/utils';

const ENTITY_TYPES = ['groups', 'roles', 'activities'];

export default function diff(a, b) {
  if (Obj.isEmpty(a) || Obj.isEmpty(b)) {
    return null;
  }

  const snapshotA = Obj.omitDerived(a);
  const snapshotB = Obj.omitDerived(b);

  const diff = { created_at: snapshotB.created_at, entities: {} };
  let isDifferent = false;

  if (snapshotA.name !== snapshotB.name) {
    isDifferent = true;
    diff.name = snapshotB.name;
  }

  if (snapshotA.objective !== snapshotB.objective) {
    isDifferent = true;
    diff.objective = snapshotB.objective;
  }

  ENTITY_TYPES.forEach((type) => {
    const entitiesA = Collections.keyById(snapshotA.entities[type]);
    const entitiesB = Collections.keyById(snapshotB.entities[type]);

    let typeIsDifferent = false;
    const entitiesDiff = {
      create: [],
      update: [],
      remove: [],
    };

    snapshotB.entities[type].forEach((entityB) => {
      const entityA = entitiesA[entityB.uuid];

      if (!entityA) {
        entitiesDiff.create.push(entityB);
        typeIsDifferent = true;
        return;
      }

      const entityDiffKeys = Obj.diff(entityA, entityB);
      if (entityDiffKeys.length > 0) {
        const entityDiff = entityDiffKeys.reduce(
          (acc, key) => {
            if (Object.hasOwn(entityB, key)) {
              acc[key] = entityB[key];
            } else {
              acc[key] = entityA[key];
            }

            return acc;
          },
          { uuid: entityB.uuid }
        );

        entitiesDiff.update.push(entityDiff);
        typeIsDifferent = true;
        return;
      }
    });

    snapshotA.entities[type].forEach(({ uuid }) => {
      if (!entitiesB[uuid]) {
        entitiesDiff.remove.push(uuid);
        typeIsDifferent = true;
      }
    });

    if (typeIsDifferent) {
      Object.keys(entitiesDiff).forEach((operation) => {
        if (entitiesDiff[operation].length === 0) {
          delete entitiesDiff[operation];
        }
      });

      diff.entities[type] = entitiesDiff;
      isDifferent = true;
    }
  });

  if (Object.keys(diff.entities).length === 0) {
    delete diff.entities;
  }

  return isDifferent ? diff : null;
}
