import { Num } from '@pkg/utils';

const pattern = /[^\d]+/g;

/**
 * @param {String} value
 * @param {Object} options
 * @param {Number} options.max
 * @returns {undefined}
 */
export function blur(value, options = {}) {
  value = parseInt(change(value));

  if (!Number.isFinite(value)) {
    return 0;
  }

  if (Number.isFinite(options.max)) {
    value = Math.min(value, options.max);
  }

  return value;
}

/**
 * @param {String} value
 * @returns {String}
 */
export function change(value) {
  if (!value) {
    return '';
  }

  return value.replace(pattern, '');
}

/**
 * @param {Number} value
 * @param {Boolean} compact
 * @returns {String}
 */
export function format(value, compact = false) {
  if (!value) {
    value = 0;
  }

  return Num.currency(value, { notation: compact ? 'compact' : 'standard' });
}
