import { useMemo } from 'react';
import useChannel from '../useChannel';
import * as Events from './Events';
import useUsers from './useUsers';

/**
 * @param {Object} options
 * @param {String} options.channelName
 * @return {Object}
 */
export default function usePresence({ channelName }) {
  const presence = useUsers();
  const channel = useChannel({
    channelType: 'PresenceChannel',
    channelName,
  });

  Events.useArrive(channel, presence);
  Events.useEnter(channel, presence);
  Events.useLeave(channel, presence);
  Events.useIdle(channel, presence);

  return useMemo(() => {
    return Object.freeze(presence);
  }, [presence, channel]);
}
