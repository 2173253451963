import * as d3 from 'd3';
import d3ForceLimit from 'd3-force-limit';
import { Position } from '@/lib/enums';

const PADDING = 8;

function setRadialCoordinates({ alignment, height, width, x0, y0 }) {
  switch (alignment) {
    case Position.BOTTOM_LEFT:
      return { x: x0 + PADDING, y: y0 + height - PADDING };
    case Position.BOTTOM_RIGHT:
      return { x: x0 + width - PADDING, y: y0 + height - PADDING };
    case Position.TOP_LEFT:
      return { x: x0 + PADDING, y: y0 + PADDING };
    case Position.TOP_RIGHT:
      return { x: x0 + width - PADDING, y: y0 + PADDING };
    default:
      return { x: x0 + width / 2, y: y0 + height / 2 };
  }
}

export default function renderSimulation(circle, meta) {
  const { alignment, data, height, width, x0 = 0, y0 = 0 } = meta;
  const maxRadius = Math.max(...data.map(({ value }) => value));
  const coordinates = setRadialCoordinates({
    alignment,
    height,
    width,
    x0,
    y0,
  });

  // Nodes are attracted each other if the value is > 0
  const charge = d3.forceManyBody().strength(0.4);

  // Force that avoids circle overlapping
  const collide = d3
    .forceCollide()
    .strength(2)
    .radius(({ value }) => value + 2)
    .iterations(3);

  // Nodes are drawn to the x and y coordinates we place in the respective
  // chart location.
  const forceX = d3.forceX().x(coordinates.x);

  // Nodes are drawn to the y coordinate.
  const forceY = d3.forceY().y(coordinates.y);

  // This defines the chart boundary to ensure circles are rendered inside
  // the chart area.
  const forceLimit = d3ForceLimit()
    .x0(x0 + maxRadius + PADDING)
    .x1(x0 + width - maxRadius - PADDING)
    .y0(y0 + maxRadius + PADDING)
    .y1(y0 + height - maxRadius - PADDING)
    .cushionWidth(PADDING * 2)
    .cushionStrength(0.1);

  // Simulates the position of the nodes based on the provided D3 force
  // functions.
  const simulation = d3
    .forceSimulation(data)
    .force('limit', forceLimit)
    .force('charge', charge)
    .force('x', forceX)
    .force('y', forceY)
    .force('collide', collide)
    .stop();

  // Manually invoke the default number of ticks so we don't render each tick
  // in the UI as it doesn't look great and it's not performant.
  simulation.tick(300);

  // Renders the nodes in the x & y locations that have been calculated.
  return circle
    .data(data)
    .attr('cx', ({ x }) => x)
    .attr('cy', ({ y }) => y);
}
