import { Sort } from '@pkg/utils';

/**
 * @param {Object} state
 * @param {Object[]} state.tags
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} input.order
 * @returns {Object}
 */
export default function reorder({ tags }, { uuid, order }) {
  const tag = tags.map.get(uuid);

  if (tag.is_system) {
    return null;
  }

  if (tag.order === order) {
    return null;
  }

  const event = {
    tags: {
      update: [{ uuid, order }],
    },
  };

  const reordered = structuredClone(tags.list)
    .filter((item) => !item.is_system)
    .filter((item) => item.category_uuid === tag.category_uuid)
    .sort(Sort.order());

  const index = reordered.findIndex((tag) => tag.uuid === uuid);
  reordered.splice(index, 1);
  reordered.splice(order, 0, tag);
  reordered.forEach(({ uuid, order }, index) => {
    if (tag.uuid !== uuid && order !== index) {
      event.tags.update.push({ uuid, order: index });
    }
  });

  return event;
}
