import { EntityName, EntityPluralName } from '@/shared/enums';

export default function entityLabel(entityType, plural = false) {
  if (!entityType) {
    return;
  }

  const label = plural ? EntityPluralName[entityType] : EntityName[entityType];

  return label ? label.toLowerCase() : '';
}
