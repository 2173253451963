import { TagChip } from '@/atoms/chips';
import { ChipType } from '@/atoms/chips/enums';
import { SelectOption } from '@/atoms/inputs/Select';

const ChipOption = ({ id, color, option, onClick, chipType }) => {
  return (
    <SelectOption
      key={option.id}
      color={color}
      onClick={(event) => onClick(event, option.id)}
      tabIndex={0}
      value={option.value}
    >
      {chipType === ChipType.TAG && (
        <TagChip
          id={id}
          expanded={true}
          name={option.name}
          tooltipPlacement="right"
          description={option.description}
          color={option.color}
        />
      )}
    </SelectOption>
  );
};

export default ChipOption;
