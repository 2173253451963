import config from '@/lib/config';

/**
 * @param {Object} snapshot
 * @returns {Object}
 */
export default function getKeyed(snapshot) {
  if (!snapshot) {
    return snapshot;
  }

  console.time('Snapshots.utils.getKeyed');
  const keyed = {
    ...snapshot,
    entities: structuredClone(config.DEFAULT_SNAPSHOT.entities),
    __keys: {},
  };

  for (const plural in snapshot.entities) {
    keyed.entities[plural] = {};
    keyed.__keys[plural] = new Set();

    for (const entity of snapshot.entities[plural]) {
      keyed.entities[plural][entity.uuid] = entity;
      keyed.__keys[plural].add(entity.uuid);
    }
  }

  console.timeEnd('Snapshots.utils.getKeyed');
  return Object.freeze(keyed);
}
