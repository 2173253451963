import * as Mutations from './mutations';

/**
 * @param {any[]} query
 */
export default function useMutations(query) {
  return {
    cancelInvite: Mutations.useCancelInvite(query),
    createMany: Mutations.useCreateMany(query),
    createManyLinks: Mutations.useCreateManyLinks(query),
    resendInvite: Mutations.useResendInvite(),
  };
}
