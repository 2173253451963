import { TagColor } from '@/lib/enums';
import tagColors from '@/lib/theme/tagColors';

export default function tagColor({
  color = TagColor.GREY_REGULAR,
  state = 'active',
  property = null,
} = {}) {
  const currentColor = color || TagColor.GREY_REGULAR;
  const [name, variant] = currentColor.toLowerCase().split('_');

  const properties = tagColors[name][variant][state];
  return property ? properties.base[property] : properties;
}
