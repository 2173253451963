import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import { EntityType, MatrixPreset, ScopeType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { ucfirst } from '@pkg/utils/strings';

const defineScopeTitle = (scope) => {
  switch (scope) {
    case MatrixPreset.ALIGNMENT:
    case MatrixPreset.PRIORITISATION:
      return `${ucfirst(scope, true)} matrix`;
    case ScopeType.SPANS_LAYERS:
      return 'Spans & Layers';
    default:
      return ucfirst(scope, true);
  }
};

const InsightsTitle = ({ entityMap, id, scope, scopeEntity }) => {
  const entityType =
    scopeEntity === EntityType.MANAGER ? EntityType.ROLE : scopeEntity;
  const entity = entityMap?.get(entityType)?.get(id);
  const scopeTitle = defineScopeTitle(scope);

  const title =
    id === 'ALL'
      ? 'ALL'
      : (entity?.name ?? entity?.title ?? entity?.__description ?? 'ALL');

  return (
    <Stack
      alignItems="flex-end"
      direction="row"
      spacing={1}
      justifyContent="space-between"
    >
      <Box>
        <Heading
          variant="h1"
          overrideStyles={{
            fontSize: '1.5rem',
            fontWeight: 600,
            lineHeight: '2.75rem',
          }}
        >
          {scopeTitle}
        </Heading>
        <Stack alignItems="flex-start" direction="row" spacing={1}>
          {title === 'ALL' && (
            <Heading variant="h5" overrideStyles={{}}>
              All
            </Heading>
          )}
          <EntityChip
            type={scopeEntity}
            size={Size.SMALL}
            plural={title === 'ALL'}
          />
          {title !== 'ALL' && (
            <Heading variant="h5" overrideStyles={{}}>
              {title}
            </Heading>
          )}
        </Stack>
      </Box>
    </Stack>
  );
};

export default InsightsTitle;
