import { useEffect, useState } from 'react';
import * as Auth from '@pkg/auth';

const useIsTeamLead = (entity) => {
  const { me } = Auth.useStore();

  const [isTeamLead, setIsTeamLead] = useState(null);

  useEffect(() => {
    if (!entity || !me) {
      return;
    }

    setIsTeamLead(entity.lead_uuid === me.uuid);
  }, [entity?.lead_uuid, me.uuid]);

  return isTeamLead;
};

export default useIsTeamLead;
