import { get } from '@pkg/utils/objects';
import { SortOrder } from '@/lib/enums';

/**
 * @param {string} property
 * @return {object[]}
 */
export default function date(property, sortOrder = SortOrder.DESC) {
  return (a, b) => {
    const sourceDate = new Date(
      get(sortOrder === SortOrder.ASC ? a : b, property)
    );
    const targetDate = new Date(
      get(sortOrder === SortOrder.ASC ? b : a, property)
    );

    return sourceDate - targetDate;
  };
}
