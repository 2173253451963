import {
  AlignmentInformation,
  MatrixInformation,
  PrioritisationInformation,
} from '@/molecules/information';
import { MatrixPreset } from '@/shared/enums';

const ChartInformation = ({ matrixPreset }) => {
  switch (matrixPreset) {
    case MatrixPreset.ALIGNMENT:
      return <AlignmentInformation />;
    case MatrixPreset.PRIORITISATION:
      return <PrioritisationInformation />;
    default:
      return <MatrixInformation />;
  }
};

export default ChartInformation;
