import { useEffect, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { Size } from '@/atoms/enums';
import { Heading, Paragraph } from '@/atoms/typography';
import SearchField from '@/molecules/formElements/SearchField';
import { PersonSummary } from '@/molecules/summaries';
import PeopleList from '@/organisms/people/PeopleList';
import { useCollaborators } from '@/shared/hooks';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import * as Auth from '@pkg/auth';
import { useSearch } from '@pkg/entities/people';
import ShareMenu from './ShareMenu';

const ShareForm = ({ filters = {} }) => {
  const { me } = Auth.useStore();
  const [search, setSearch] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [person, setPerson] = useState({ uuid: '', name: '' });
  const [adding, setAdding] = useState(false);
  const {
    collaboratorOptions,
    collaboratorOptionMap,
    collaborators,
    addCollaborator,
    permission,
    updateCollaborator,
  } = useCollaborators();
  const { scenario } = useScenarioContext();

  const [exclude, setExclude] = useState([]);

  const [option, setOption] = useState(collaboratorOptions[0]);

  const { results, isLoading } = useSearch(search, {
    enabled,
    exclude,
    filters,
  });

  const searchPeople = (search) => {
    if (isLoading) {
      return;
    }

    setEnabled(true);

    setSearch(search);
  };

  const handleChange = (event, person) => {
    if (!person) {
      return;
    }
    setPerson(person);
  };

  const handleSubmit = async () => {
    if (!person?.uuid || adding) {
      return;
    }

    setAdding(true);
    await addCollaborator(person, option);
    setPerson({ uuid: '', name: '' });
    setAdding(false);
  };

  const handleOptionSelect = (option) => {
    setOption(option);
  };

  const handleUpdateAccess = (person, option) => {
    if (!permission?.edit) {
      return;
    }
    updateCollaborator(person, option);
  };

  useEffect(() => {
    if (!option && collaboratorOptions?.length > 0) {
      setOption(collaboratorOptions[0]);
    }
  }, [collaboratorOptions]);

  useEffect(() => {
    setExclude([
      ...collaborators.map(({ uuid }) => uuid),
      scenario?.details?.owner?.uuid,
      me?.uuid,
    ]);
  }, [JSON.stringify(collaborators)]);

  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{
        color: BaseColors.font.color,
        position: 'relative',
      }}
    >
      {permission?.edit && (
        <>
          <Grid item xs={12} px={1}>
            <Heading variant="h4" overrideStyles={{ fontWeight: 500 }}>
              Share {`${permission.owner ? 'your' : 'this'}`} scenario
            </Heading>
          </Grid>
          <Grid item xs sx={{ position: 'relative', zIndex: 1 }}>
            <SearchField
              disableClearable={true}
              exclude={exclude}
              filterOptions={searchPeople}
              initialValue={person}
              onChange={handleChange}
              OptionComponent={(option) => (
                <PersonSummary
                  avatar={option.avatar}
                  person={option}
                  size="small"
                />
              )}
              options={results}
              optionLabel="name"
              placeholder="Search for people..."
              size={Size.LARGE}
            />
            <Box sx={{ position: 'absolute', top: '4px', right: '4px' }}>
              <ShareMenu
                selected={option}
                options={collaboratorOptions}
                onClick={handleOptionSelect}
              />
            </Box>
          </Grid>
          <Grid item sx={{ ml: 1 }}>
            <Button
              color="secondary"
              label="Send invite"
              onClick={handleSubmit}
              size={Size.MEDIUM}
            />
          </Grid>
        </>
      )}
      {permission?.view && collaborators?.length > 0 ? (
        <Grid item xs={12} px={1} mt={3}>
          <Heading variant="h6" overrideStyles={{ fontWeight: 500, mb: 2 }}>
            Only the people below can access{' '}
            {`${permission?.owner ? 'your' : 'this'}`} scenario
          </Heading>
          <PeopleList
            endComponent={(person) =>
              permission?.edit && (
                <ShareMenu
                  selected={person.accessType}
                  options={collaboratorOptions}
                  onClick={(option) => handleUpdateAccess(person, option)}
                  removeOption={collaboratorOptionMap.get('design.noAccess')}
                />
              )
            }
            people={collaborators}
            size="small"
          />
        </Grid>
      ) : (
        <Grid item xs={12} px={1} mt={3} sx={{ textAlign: 'center' }}>
          <Paragraph>
            When you share {`${permission?.owner ? 'your' : 'this'}`} scenario,
            the list of people you are collaborating with will be shown here.
          </Paragraph>
        </Grid>
      )}
    </Grid>
  );
};

export default ShareForm;
