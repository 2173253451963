import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans/utils';
import { EntityType } from '@/shared/enums';

export default function updatedFTE({ comparisonScenario, originalRole, role }) {
  const action = {
    type: ActionType.UPDATED_FTE,
    [EntityType.ROLE]: new Map([
      [
        role.uuid,
        {
          metrics: {
            fte: role.fte,
          },
        },
      ],
    ]),
    metrics: defaultMetrics(comparisonScenario),
  };

  const fte = role.fte - originalRole.fte;

  action.metrics[1].fte += fte;

  return action;
}
