import { tool } from 'ai';
import { z } from 'zod';

// add schemas that help out this tool

const createFilters = tool({
  description:
    'Will create a list of filters that can find what the user is looking for',
  parameters: z.object({
    filter_question: z
      .string()
      .describe(
        'Takes the users question and explains it in greater detail and asks the user to apply the answer if they like it.  This will be used to generate a list of filters'
      ),
    // make a zod array of strings
  }),
  execute: async ({ filter_question }) => ({
    filter_question,
  }),
});

export default createFilters;
