import { ActionType } from '@/organisms/plans';
import { defaultMetrics } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import { entityLabel } from '@/shared/utils';

export default function addedActivities({
  actionMap,
  activity,
  comparisonScenario,
  scenario,
}) {
  const role =
    activity.owner_type === EntityType.ROLE
      ? scenario.relationships.get(EntityType.ROLE)?.get(activity.owner_uuid)
      : null;
  const group = role
    ? scenario?.relationships.get(EntityType.GROUP)?.get(role.group_uuid)
    : scenario?.relationships.get(EntityType.GROUP)?.get(activity.owner_uuid);

  const metrics = {
    hours: activity.hours,
  };

  const existingAction = actionMap?.get(ActionType.ADD_ACTIVITIES);

  const action = existingAction ?? {
    entity: EntityType.ACTIVITY,
    metrics: defaultMetrics(comparisonScenario),
    type: ActionType.ADD_ACTIVITIES,
    [EntityType.ROLE]: new Set(),
    [EntityType.GROUP]: new Set(),
    [EntityType.ACTIVITY]: new Map(),
  };

  action[EntityType.ACTIVITY].set(activity.uuid, { metrics });
  if (role) {
    action[EntityType.ROLE].add(role.uuid);
  }
  if (group) {
    action[EntityType.GROUP].add(group.uuid);
  }

  action.metrics[1].activities += 1;
  action.metrics[1].hours += metrics.hours;

  const activityCount = action[EntityType.ACTIVITY].size;
  const roleCount = action[EntityType.ROLE].size;

  const roleLabel = entityLabel(EntityType.ROLE, roleCount !== 1);
  const activityLabel = entityLabel(EntityType.ACTIVITY, activityCount !== 1);
  const verb = activityCount === 1 ? 'was' : 'were';
  const adverb = roleCount === 1 ? 'to' : 'across';

  action.title = `${activityCount} ${activityLabel} ${verb} added ${adverb} ${roleCount} ${roleLabel}`;

  return action;
}
