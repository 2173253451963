import { useEffect, useState } from 'react';
import * as Layouts from '@/layouts';
import { StoreType } from '@/shared/enums';
import { useScenario } from '@/shared/hooks';
import {
  FlowsProvider,
  ScenarioProvider,
  TagProvider,
} from '@/shared/providers';
import { useFlowContext } from '@/shared/providers/FlowProvider';
import * as Auth from '@pkg/auth';
import { DesignEntity, DesignLevel } from '@/lib/enums';

const RoleFlowLayout = () => {
  const { flow } = useFlowContext();
  const { uuid, payload } = flow;
  const roleId = payload.entity.uuid;
  const designId = payload.design_uuid;
  const organisation = Auth.useStore((state) => state.organisation);
  const isRestricted = organisation.is_restricted;
  const [coreActivitySet, setCoreActivitySet] = useState(new Set());
  const level = {
    type: DesignLevel.ROLE,
    uuid: roleId,
  };

  const liveScenario = useScenario({
    designId: organisation?.design?.uuid,
    level,
    storeType: StoreType.HYDRATED,
  });

  const activities = liveScenario.scenario?.relationships?.get(
    DesignEntity.ACTIVITY
  );

  const coreActivities = activities?.size ? [...activities.keys()] : null;

  useEffect(() => {
    if (!isRestricted || !coreActivities?.length || coreActivitySet.size) {
      return;
    }

    setCoreActivitySet(new Set([...coreActivities]));
  }, [JSON.stringify(coreActivities)]);

  return (
    <ScenarioProvider
      designId={designId}
      level={level}
      storeType={StoreType.DESIGN}
    >
      <TagProvider>
        <FlowsProvider>
          <Layouts.RoleWizard
            flowId={uuid}
            level={level}
            coreActivitySet={coreActivitySet}
          />
        </FlowsProvider>
      </TagProvider>
    </ScenarioProvider>
  );
};

export default RoleFlowLayout;
