import { DoughnutChart } from '@/molecules/chartElements';
import { tag as tagColor } from '@pkg/utils/colors';
import tagPercentages from './utils/tagPercentages';

const TagDoughnut = ({
  comparison,
  direction,
  onClick,
  size,
  tag,
  totalHours,
}) => {
  const percentages = tagPercentages(tag, totalHours, comparison);
  const color = tagColor({ color: tag.color, property: 'fill' });
  const handleClick = () => {
    onClick?.(tag.categoryId, tag.uuid);
  };

  return (
    <DoughnutChart
      color={color}
      description={tag.description}
      direction={direction}
      label={tag.name}
      size={size}
      onClick={handleClick}
      value={percentages.tag}
      changeValue={percentages.change}
      options={{
        cutout: '80%',
        rotation: 270,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
      }}
    />
  );
};

export default TagDoughnut;
