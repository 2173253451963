import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Button, LinkButton } from '@/atoms/buttons';
import * as Colors from '@/atoms/colors';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { Switch } from '@/atoms/inputs';
import { AvatarGroup } from '@/molecules/avatars';
import { BarChart } from '@/molecules/chartElements';
import { Modal } from '@/molecules/modals';
import { FloatingBar } from '@/molecules/navigation';
import { ShareForm } from '@/organisms/forms';
import { FlowStatus, FlowType } from '@/shared/enums';
import { useCollaborators } from '@/shared/hooks';
import { useUpdateFlow } from '@/shared/hooks';
import { useFlowsContext } from '@/shared/providers/FlowsProvider';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import DialogAction from '@/components/DialogAction';
import formatRoleMetrics from './utils/formatRoleMetrics';

const RoleActionBar = ({ metrics, onCompare }) => {
  const [barChartData, setBarChartData] = useState();
  const { scenario, canPublish, publish } = useScenarioContext();
  const router = useRouter();
  const [showShareForm, setShowShareForm] = useState(false);
  const { permission } = useCollaborators();
  const { flows } = useFlowsContext();
  const [flowList, setFlowList] = useState();
  const updateFlow = useUpdateFlow();

  const handleOpenDialog = () => {
    setShowShareForm(true);
  };

  const handleCloseDialog = () => {
    setShowShareForm(false);
  };

  const handleCompareClick = (event, value) => {
    onCompare?.(value);
  };

  const handlePublish = async () => {
    const { details, entity } = scenario;

    if (!details || !entity) {
      return;
    }

    // Ensure flows aren't orphaned if scenarios are published.
    if (flowList?.length) {
      for (const uuid of flowList) {
        await updateFlow({ uuid, status: FlowStatus.COMPLETE });
      }
    }

    await publish({
      title: entity?.title,
      uuid: entity.uuid,
    });

    router.push('/dashboard');
  };

  useEffect(() => {
    if (
      !flows?.get(FlowType.ROLE_WIZARD)?.has(scenario?.entity?.uuid) ||
      !scenario
    ) {
      return;
    }

    const flowList = [];

    flows
      .get(FlowType.ROLE_WIZARD)
      .get(scenario.entity.uuid)
      .forEach((flows) => {
        flows.forEach(({ uuid, payload }) => {
          if (payload?.design_uuid === scenario?.details?.designId) {
            flowList.push(uuid);
          }
        });
      });

    setFlowList(flowList);
  }, [flows, scenario?.details?.designId]);

  useEffect(() => {
    if (!metrics) {
      return;
    }

    setBarChartData(formatRoleMetrics(metrics));
  }, [
    metrics?.activities?.count?.raw,
    metrics?.activities?.hours?.raw,
    metrics?.roles?.fte?.raw,
  ]);

  const showActivityCount =
    barChartData && barChartData?.activityCount?.raw !== 0;
  const showActivityHours =
    barChartData && barChartData?.activityHours?.raw !== 0;
  const showFTE = barChartData && barChartData?.fte?.raw !== 0;
  const showShare =
    permission?.edit ||
    (scenario?.details?.collaborators?.length > 0 && permission?.view);

  return (
    <FloatingBar>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          spacing={3}
          sx={{ ml: 1, color: Colors.Base.font.white }}
          justifyContent="center"
        >
          {showActivityCount && (
            <BarChart
              values={barChartData.activityCount.values}
              colors={barChartData.activityCount.colors}
              label={barChartData.activityCount.label}
              percentage={barChartData.activityCount.percentage}
              raw={barChartData.activityCount.raw}
            />
          )}
          {showActivityHours && (
            <BarChart
              values={barChartData.activityHours.values}
              colors={barChartData.activityHours.colors}
              label={barChartData.activityHours.label}
              percentage={barChartData.activityHours.percentage}
              raw={barChartData.activityHours.raw}
            />
          )}
          {showFTE && (
            <BarChart
              values={barChartData.fte.values}
              colors={barChartData.fte.colors}
              label={barChartData.fte.label}
              percentage={barChartData.fte.percentage}
              raw={barChartData.fte.raw}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={2} alignItems="center">
          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip
              arrow
              title="Compare this scenario with the live role"
              placement="top"
            >
              <Box mr={1.5}>
                <Switch
                  label="Compare"
                  size={Size.SMALL}
                  color="white"
                  onClick={handleCompareClick}
                />
              </Box>
            </Tooltip>
            {showShare && (
              <>
                <Divider
                  color="white"
                  orientation="vertical"
                  overrideStyles={{ opacity: 0.4 }}
                />
                <AvatarGroup
                  people={scenario?.details?.collaborators}
                  size={Size.X_SMALL}
                  max={3}
                  onGroupClick={handleOpenDialog}
                  startComponent={
                    <Button
                      color="white"
                      label={permission?.edit ? 'Share' : 'Shared with'}
                      variant="text"
                      onClick={handleOpenDialog}
                      size="medium"
                    />
                  }
                />
              </>
            )}
            <Divider
              color="white"
              orientation="vertical"
              overrideStyles={{ opacity: 0.4 }}
            />
            <Modal
              open={showShareForm}
              onClose={handleCloseDialog}
              maxWidth="calc(50vw)"
            >
              <Stack justifyContent="space-between" height="100%">
                <ShareForm />
                <Stack direction="row" sx={{ mt: 4 }}>
                  <Button
                    color="primary"
                    label="Finished"
                    onClick={handleCloseDialog}
                    variant="outline"
                    size="large"
                  />
                </Stack>
              </Stack>
            </Modal>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            {scenario?.details?.isScenario && canPublish && (
              <DialogAction
                title="Publish role scenario"
                content="When you publish your role scenario you will no longer
                be able to access it. Do you wish to continue?"
                onConfirm={handlePublish}
              >
                <div>
                  <Button
                    color="white"
                    label="Publish"
                    size="medium"
                    variant="text"
                  />
                </div>
              </DialogAction>
            )}
            <Tooltip
              arrow
              title={
                permission?.edit
                  ? 'Save and return to the dashboard'
                  : 'Return to the dashboard'
              }
              placement="top"
            >
              <div>
                <LinkButton
                  color="secondary"
                  label={permission?.edit ? 'Save' : 'Dashboard'}
                  path="/dashboard"
                  size="medium"
                />
              </div>
            </Tooltip>
          </Stack>
        </Stack>
      </Stack>
    </FloatingBar>
  );
};

export default RoleActionBar;
