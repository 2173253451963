/**
 * @param {Object[]} collection
 * @param {String} key
 * @param {any} values
 *
 * @return {Object}
 */
export function whereIn(collection, key, values) {
  return collection.filter((item) => values.includes(item[key]));
}

/**
 * @param {Object[]} collection
 * @param {String[]} ids
 *
 * @return {Object}
 */
export function whereInIds(collection, ids) {
  return collection.filter((item) => ids.includes(item.uuid));
}
