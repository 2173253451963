import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

const DiagramActions = styled('div')({
  alignItems: 'stretch',
  position: 'absolute',
  left: 12,
  top: 112,
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  columnGap: '8px',
});

const DiagramContainer = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const MetaBar = styled('div')({
  position: 'absolute',
  right: 12,
  top: 12,
});

const MetaBarActions = styled('div')({
  alignItems: 'stretch',
  columnGap: '8px',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  position: 'absolute',
  right: 12,
  top: 64,
});

const Organisation = styled(Skeleton)({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 200,
  margin: 'auto',
});

const Overview = styled(Skeleton)({
  position: 'absolute',
  top: 12,
  left: 12,
  transform: 'scale(1)',
});

const OverviewScroll = styled(Skeleton)({
  position: 'absolute',
  transform: 'scale(1)',
  top: 12,
  left: 150,
});

const DesignSkeleton = () => {
  return (
    <DiagramContainer>
      <Overview variant="rounded" width={128} height={96} />
      <OverviewScroll variant="rounded" width={4} height={96} />
      <DiagramActions>
        <Skeleton variant="rounded" width={36} height={42} />
        <Skeleton variant="rounded" width={36} height={42} />
        <Skeleton variant="rounded" width={36} height={42} />
      </DiagramActions>
      <MetaBar>
        <Skeleton variant="rounded" width={450} height={56} />
      </MetaBar>
      <MetaBarActions>
        <Skeleton variant="rounded" width={36} height={42} />
        <Skeleton variant="rounded" width={36} height={42} />
        <Skeleton variant="rounded" width={36} height={42} />
        <Skeleton variant="rounded" width={36} height={42} />
      </MetaBarActions>
      <Organisation variant="rounded" width={400} height={200} />
    </DiagramContainer>
  );
};

export default DesignSkeleton;
