import { useCallback, useEffect, useState } from 'react';
import { TagChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { CheckboxOptions } from '@/atoms/inputs';
import { Paragraph } from '@/atoms/typography';
import { FilterSearch } from '@/molecules/filterElements';
import { useTagContext } from '@/shared/providers';
import Box from '@mui/material/Box';

/** Builds a list of tag options respecting the allowed tags list and sorts
 * the results.
 *
 * @param {Map} tagOptions
 * @param {Set} selectedIds
 * @param {Set} allowedTags
 *
 * @return {Array}
 */
const sortTagOptions = ({ tagOptions, value, allowedTags }) => {
  const sortedOptions = [];
  const selected = new Set(value?.length ? value.map(({ id }) => id) : []);

  tagOptions.forEach((category) => {
    let options = category.options;

    if (allowedTags?.size > 0) {
      options = category.options.filter(({ id }) => allowedTags.has(id));
    }

    if (!options?.length) {
      return;
    }

    options.sort((a, b) => {
      const aSelected = selected.has(a.id);
      const bSelected = selected.has(b.id);
      return aSelected === bSelected ? 0 : aSelected ? -1 : 1;
    });

    sortedOptions.push({
      ...category,
      options,
    });
  });

  return sortedOptions;
};

const TagSelect = ({ entityType, value, categorised = true, onChange }) => {
  const { entityTagMap, search, tagMap } = useTagContext();
  const tagOptions = entityTagMap?.get(entityType);
  const [options, setOptions] = useState(null);
  const [allowedTags, setAllowedTags] = useState(new Set());

  const searchFilterFn = useCallback(
    () => (item) => item.__taggables_set.has(entityType),
    [entityType]
  );

  const handleChange = (event, ids) => {
    const values = ids.map((id) => ({
      id,
      label: tagMap?.get(id)?.name,
    }));

    onChange(event, values);
  };

  const handleSearch = (event, term) => {
    if (!term) {
      setOptions(sortTagOptions({ tagOptions, value }));
      return;
    }

    const results = search(term, searchFilterFn);

    if (!results?.length) {
      setOptions([]);
      return;
    }

    const allowed = new Set(
      results.map((result) => {
        return result.uuid;
      })
    );

    setAllowedTags(allowed);
  };

  // This effect sets up the default list of options sorted with checked tags
  // at the start of the list.
  useEffect(() => {
    if (!tagOptions || options) {
      return;
    }

    setOptions(sortTagOptions({ tagOptions, value }));
  }, [tagOptions, value]);

  // This effect updates the options when searches are performed.
  useEffect(() => {
    if (!tagOptions || !options) {
      return;
    }

    const sortedOptions = sortTagOptions({
      tagOptions,
      value,
      allowedTags,
    });

    setOptions(sortedOptions);
  }, [allowedTags]);

  return options ? (
    <>
      <FilterSearch onSearch={handleSearch} placeholder="Search for tags" />
      <Box sx={{ maxHeight: 340, overflowY: 'auto' }}>
        {options?.length > 0 ? (
          <CheckboxOptions
            categorised={categorised}
            onChange={handleChange}
            options={options}
            OptionComponent={({ id, properties }) => (
              <TagChip
                id={id}
                expanded={true}
                name={properties.name}
                tooltipPlacement="right"
                description={properties.description}
                color={properties.color}
                size={Size.SMALL}
              />
            )}
            initialSelectedIds={value?.map(({ id }) => id)}
          />
        ) : (
          <Box p={2}>
            <Paragraph
              size={Size.SMALL}
              overrideStyles={{ textAlign: 'center' }}
            >
              No tags were found matching that search term
            </Paragraph>
          </Box>
        )}
      </Box>
    </>
  ) : (
    <Box p={2}>
      <Paragraph size={Size.SMALL} overrideStyles={{ textAlign: 'center' }}>
        No tags have been added.
      </Paragraph>
    </Box>
  );
};

export default TagSelect;
