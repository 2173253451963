import { Skills } from '@pkg/entities/library';
import { Lens } from '@/lib/enums';
import { palettes } from '@/lib/theme/tokens';
import { getFlattened, getKeyed } from '../utils';

const LensTemplate = Object.freeze({
  type: Lens.UNUTILISED,
  color: null,
});

export default function unutilised({ snapshot }) {
  const applied = getKeyed(snapshot);

  for (const role of snapshot.entities.roles) {
    const lens = structuredClone(LensTemplate);

    if (!role.__is_complete) {
      applied.entities.roles[role.uuid].__lens = lens;
      continue;
    }

    applied.entities.roles[role.uuid].__lens = lens;
  }

  return getFlattened(applied);
}

export function apply(skills) {
  return skills.map((skill) => {
    const { personLevel, roleLevel } = skill.levels;
    const unutilised = !Skills.compare.meets(roleLevel, personLevel);
    const partial = unutilised && Boolean(roleLevel);

    const lensColor = partial
      ? palettes.brand.orange.tints[1]
      : unutilised
        ? palettes.brand.coral.tints[2]
        : null;

    return {
      ...skill,
      lensApplied: true,
      lensColor,
    };
  });
}
