import PropTypes from 'prop-types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';
import OutlinedInput from '@mui/material/OutlinedInput';
import makeStyles from '@mui/styles/makeStyles';
import { Tags } from '@pkg/entities';
import { useLocalState } from '@pkg/hooks';
import Cancel from '@pkg/icons/Cancel';
import { color, palettes } from '@/lib/theme/tokens';
import Button from '@/components/Button';
import Tag from '@/components/Tag';
import Pills from './Pills';

export const InputType = Object.freeze({
  SELECTABLE: 'SELECTABLE',
  REMOVEABLE: 'REMOVEABLE',
});

const useStyles = makeStyles({
  root: {
    flex: 1,
    width: '100%',
  },
  label: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '20px',
    color: palettes.grey.colors[700],
    margin: 0,
  },
  badge: {
    backgroundColor: color.primary.grey[200],
    borderRadius: 8,
    display: 'inline-block',
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.4,
    margin: 0,
    marginLeft: 4,
    padding: '0 4px',
    position: 'relative',
    textAlign: 'center',
    top: -2,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    borderRadius: 4,
    marginTop: 2,
  },
  footer: {
    marginTop: 8,
    textAlign: 'right',
  },
  tags: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginBottom: 8,
  },
  tag: {
    marginBottom: 4,
    marginRight: 4,
  },
  option: {
    cursor: 'pointer',
    padding: '0 12px 0 16px',
    '&:hover': {
      backgroundColor: color.primary.grey[100],
    },
    '&:last-child': {
      marginBottom: '8px',
    },
  },
  groupHeader: {
    backgroundColor: color.primary.grey[100],
    fontSize: 13,
    lineHeight: '2.5',
    margin: '12px 0 8px',
    '&:first-of-type': {
      marginTop: 0,
    },
  },
});

const TagsInput = ({
  children,
  disabled,
  disableAutocomplete = false,
  disableClear = false,
  entityType,
  includeDisabled = false,
  label = 'Tags',
  onAddMore,
  onChange,
  readOnly,
  sortBySelected,
  type = InputType.REMOVEABLE,
  value: initialValue,
}) => {
  const classes = useStyles();
  const { options, tags } = Tags.useStore();

  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useLocalState(initialValue ?? []);
  const selectedSet = useMemo(() => new Set(selected), [selected]);
  const canClear = Boolean(selected.length);

  useEffect(() => {
    const currentSet = new Set(selected);
    selected.forEach((uuid) => {
      const tag = tags.map.get(uuid);
      const isExisting = Boolean(tag);
      const isEnabled = isExisting && (includeDisabled || !tag.is_disabled);
      const isCompatible = isExisting && tag.__taggables_set.has(entityType);
      if (!isCompatible || !isEnabled) {
        currentSet.delete(uuid);
      }
    });

    if (selectedSet.size !== currentSet.size) {
      const selected = Array.from(currentSet.values());
      setSelected(selected);
      onChange?.(null, selected);
    }
  }, [entityType, selectedSet, tags]);

  const handleChange = (event, values, reason) => {
    if (reason === 'selectOption') {
      handleSelect(event, values[0]);
    }
  };

  const handleClear = (event) => {
    setSelected([]);
    onChange?.(event, []);
  };

  const handleInputValue = (_, value, reason) => {
    if (reason !== 'reset') {
      setInputValue(value);
    }
  };

  const handleSelect = (event, tag) => {
    event.stopPropagation();
    event.preventDefault();

    const active = selectedSet.has(tag.uuid);
    const changed = active
      ? selected.filter((uuid) => uuid !== tag.uuid)
      : [...selected, tag.uuid];

    setSelected(changed);
    requestAnimationFrame(async () => onChange?.(event, changed));
  };

  const onSelect = useCallback(handleSelect, [onChange, selected]);

  return (
    <FormControl className={classes.root}>
      <div className={classes.header}>
        <p className={classes.label}>
          {label}
          <span className={classes.badge}>{selected.length}</span>
        </p>
        {!readOnly && !disableClear && (
          <Button
            className={classes.clear}
            disabled={!canClear || disabled}
            onClick={handleClear}
            size="small"
            startIcon={<Cancel />}
            variant="text"
          >
            Clear
          </Button>
        )}
      </div>
      {(Boolean(selected.length) || !readOnly) && (
        <div className={classes.container}>
          <Pills
            disabled={disabled}
            entityType={entityType}
            includeDisabled={includeDisabled}
            onAddMore={onAddMore}
            onClick={onSelect}
            readOnly={readOnly}
            selected={selectedSet}
            sortBySelected={sortBySelected}
            type={type}
          >
            {children}
          </Pills>
          {!readOnly && !disableAutocomplete && (
            <Autocomplete
              data-testid="tags-filter"
              disabled={disabled}
              clearOnBlur
              disableCloseOnSelect
              filterOptions={(options) =>
                options.filter((option) => {
                  const isSelected = selectedSet.has(option.uuid);
                  const isEnabled = includeDisabled || !option.is_disabled;
                  if (isSelected || !isEnabled) {
                    return false;
                  }

                  const lowerName = option.name.toLowerCase();
                  const lowerValue = inputValue.toLowerCase();
                  return !inputValue || lowerName.indexOf(lowerValue) > -1;
                })
              }
              freeSolo
              getOptionLabel={({ uuid }) => uuid}
              inputValue={inputValue}
              multiple
              ListboxProps={{ 'data-testid': 'tags-filter-menu' }}
              onChange={handleChange}
              onInputChange={handleInputValue}
              options={options[entityType]}
              groupBy={({ __group }) => __group}
              renderGroup={({ key, group, children }) => (
                <div key={key}>
                  <ListSubheader className={classes.groupHeader}>
                    {group}
                  </ListSubheader>
                  {children}
                </div>
              )}
              renderOption={(props, tag) => (
                <li key={tag.uuid} {...props} className={classes.option}>
                  <Tag active expanded tag={tag} />
                </li>
              )}
              renderInput={({ InputProps, ...params }) => {
                delete InputProps.label;
                delete InputProps.className;
                delete params.InputLabelProps;
                delete params.inputProps.className;

                return (
                  <OutlinedInput
                    {...InputProps}
                    {...params}
                    placeholder="Select tags"
                  />
                );
              }}
              value={[]}
            />
          )}
        </div>
      )}
    </FormControl>
  );
};

TagsInput.propTypes = {
  disabled: PropTypes.bool,
  disableAutocomplete: PropTypes.bool,
  disableClear: PropTypes.bool,
  includeDisabled: PropTypes.bool,
  entityType: PropTypes.string,
  label: PropTypes.string,
  onAddMore: PropTypes.func,
  onChange: PropTypes.func,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
};

export default memo(TagsInput);
