import { useMemo } from 'react';
import config from '@/lib/config';
import { DesignEntity } from '@/lib/enums';
import { color } from '@/lib/theme/tokens';

const COLOR = {
  MAIN: {
    PRODUCTIVITY: color.core.blue,
    WORK_HEALTH: color.core.yellow,
  },
};

export default function useInsights(insights) {
  return useMemo(() => {
    if (!insights) {
      return null;
    }

    return insights.organisation.map(({ key, summary, breakdown }) => ({
      key,
      title: config.INSIGHTS.CATEGORIES[key],
      labels: summary.map(({ key }) => config.INSIGHTS.METRICS[key]),
      summary: [
        {
          color: COLOR.MAIN[key],
          data: summary.map(({ value }) => value),
          label: 'Organisation',
        },
      ],
      breakdown: breakdown?.map(({ uuid, metrics }) => ({
        uuid,
        type: DesignEntity.GROUP,
        data: metrics.map(({ value }) => value),
      })),
    }));
  }, [JSON.stringify(insights)]);
}
