import { Size } from '@/atoms/enums';

const ParagraphSizeProps = Object.freeze({
  [Size.XX_SMALL]: {
    fontSize: '0.725rem',
    lineHeight: '0.85rem',
  },
  [Size.X_SMALL]: {
    fontSize: '0.825rem',
    lineHeight: '0.95rem',
  },
  [Size.SMALL]: {
    fontSize: '0.875rem',
    lineHeight: '1rem',
  },
  [Size.MEDIUM]: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  [Size.LARGE]: {
    fontSize: '1.25rem',
    lineHeight: '1.75rem',
  },
  [Size.X_LARGE]: {
    fontSize: '1.5rem',
    lineHeight: '2.15rem',
  },
});

export default ParagraphSizeProps;
