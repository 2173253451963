import { useMemo } from 'react';
import { DesignEntity } from '@/lib/enums';
import * as Relationships from './relationships';
import * as Use from './use';

const relateAllEntities = (snapshot, relate) => {
  const relationships = {};

  for (const plural in snapshot.entities) {
    const type = DesignEntity.fromPlural(plural);
    snapshot.entities[plural].forEach((entity) => {
      relationships[entity.uuid] = relate(entity, type);
    });
  }

  return relationships;
};

/**
 * @param {Object} me
 * @param {String} current
 * @param {Object} main
 * @param {Object} snapshot
 * @returns {Object[]}
 */
export default function useRelationships(me, current, main, snapshot) {
  const mainSnapshot = main?.latest?.snapshot;
  const combinedSnapshot = Use.combined(snapshot, mainSnapshot, current?.scope);

  const relateCurrent = Relationships.useDerive(me, combinedSnapshot);
  const relateMain = Relationships.useDerive(me, mainSnapshot);

  return useMemo(() => {
    const related = {
      current: {},
      main: {},
    };

    if (!snapshot || !mainSnapshot) {
      return related;
    }

    console.time('Access.designs.relationships');

    // determine relationship for entites in snapshot
    related.current = relateAllEntities(snapshot, relateCurrent);

    // if scenario then also relate all entites in main design
    related.main = current?.is_scenario
      ? relateAllEntities(mainSnapshot, relateMain)
      : structuredClone(current);

    // relate all present scenarios
    function relateScenario(scenario) {
      const result = relateCurrent(scenario, DesignEntity.SCENARIO);
      related.current[scenario.uuid] = result;
    }

    me?.scenarios?.forEach(relateScenario);
    me?.sharedScenarios?.forEach(relateScenario);
    related.current[main.uuid] = relateCurrent(main, DesignEntity.DESIGN);

    console.timeEnd('Access.designs.relationships');
    return related;
  }, [me, relateCurrent, relateMain, mainSnapshot]);
}
