import { Children, cloneElement, useMemo, useRef, useState } from 'react';
import { Size } from '@/atoms/enums';
import { ProgressList, ProgressPercentage } from '@/molecules/progress';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { core } from '@/lib/theme/tokens/palettes';
import Logo from '@/components/Logo';
import useWizardSteps from './hooks/useWizardSteps';

const StyledNavigationBox = styled(Box)(({ theme }) => ({
  color: core.white,
  width: '100%',
  padding: theme.spacing(1),
  paddingBottom: 0,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: 0,
    paddingTop: theme.spacing(4),
    width: '240px',
  },
}));

const StyledStepBox = styled(Box)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: 'calc(100vw - 240px)',
    position: 'relative',
    overflowY: 'auto',
  },
}));

const StyledContentBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1),
  scrollBehavior: 'smooth',
  [theme.breakpoints.up('md')]: {
    backgroundColor: core.white,
    borderRadius: '16px',
    margin: theme.spacing(4),
  },
}));

const Wizard = ({ children, navigationComponent }) => {
  const { scenario } = useScenarioContext();
  const [stepLabels, setStepLabels] = useState([]);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const wizardRef = useRef();
  const [stepCount, setStepCount] = useState(0);

  const steps = useMemo(() => {
    const reactChildren = Children.toArray(children);
    let stepCount = 0;

    setStepLabels(
      reactChildren.map(({ props }) => {
        const subStepCount = props.subSteps?.length
          ? props.subSteps?.length - 1
          : 0;
        stepCount += 1 + subStepCount;
        const subItems = props.subSteps?.map(({ title }) => {
          return { label: title };
        });
        return {
          label: props.stepLabel,
          subItems,
        };
      })
    );

    setStepCount(stepCount);

    return Children.toArray(children);
  }, [children]);

  const {
    activeStep,
    activeSubStep,
    nextStep,
    previousStep,
    removeFromStorage,
    stepIndex,
  } = useWizardSteps(steps);

  const handleNext = (event) => {
    nextStep?.(event);
    wizardRef?.current?.scrollIntoView({ behaviour: 'auto', block: 'start' });
  };

  const handlePrevious = (event) => {
    previousStep?.(event);
    wizardRef?.current?.scrollIntoView({ behaviour: 'auto', block: 'start' });
  };

  return (
    scenario &&
    steps[activeStep] && (
      <>
        <StyledNavigationBox>
          <Stack
            justifyContent="space-between"
            alignItems="flex-start"
            height="100%"
            direction={isLargeScreen ? 'column' : 'row'}
            padding={isLargeScreen ? 0 : 1}
          >
            <Box>
              <Logo type="full" width={isLargeScreen ? '170px' : '120px'} />
            </Box>
            {isLargeScreen && (
              <Stack justifyContent="center" flexGrow={1}>
                <ProgressList
                  activeIndex={activeStep}
                  activeSubIndex={activeSubStep}
                  items={stepLabels}
                  size={Size.SMALL}
                />
              </Stack>
            )}
            {navigationComponent && <>{navigationComponent}</>}
          </Stack>
        </StyledNavigationBox>
        <StyledStepBox>
          <StyledContentBox ref={wizardRef}>
            {!isLargeScreen && (
              <Box
                px={2}
                pt={isLargeScreen ? 3 : 2}
                pb={isLargeScreen ? 0 : 2}
                sx={{ backgroundColor: core.white, borderRadius: 4 }}
              >
                <ProgressPercentage
                  activeIndex={activeStep}
                  items={stepLabels}
                  size={Size.SMALL}
                  color="secondary"
                />
              </Box>
            )}
            <Box
              mt={isLargeScreen ? 0 : 1}
              sx={{ backgroundColor: core.white, borderRadius: 4 }}
            >
              {cloneElement(steps[activeStep], {
                activeStep,
                activeSubStep,
                isComplete: activeStep === steps.length - 1,
                onNext: handleNext,
                onPrevious: handlePrevious,
                onComplete: removeFromStorage,
                stepCount,
                stepIndex,
              })}
            </Box>
          </StyledContentBox>
        </StyledStepBox>
      </>
    )
  );
};

export default Wizard;
