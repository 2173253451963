export default function renderGroup(svg, { classes, position, data }) {
  return svg
    .append('g')
    .attr('id', `packed-circle-group--${position}`)
    .attr('cx', 100)
    .classed('PackedCircleChart--group', true)
    .classed(classes.group.join(' '), true)
    .selectAll('circle')
    .data(data)
    .enter();
}
