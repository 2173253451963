import { useMutation } from '@pkg/graphql';
import useLogout from './useLogout';
import useStore from './useStore';

const DELETE_USER = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      uuid
    }
  }
`;

export default function useDeleteAccount() {
  const { mutateAsync } = useMutation({ mutation: DELETE_USER });
  const { me } = useStore();
  const { logout } = useLogout();

  async function deleteAccount() {
    const state = await mutateAsync({ uuid: me.uuid });
    logout();
    return state;
  }

  return deleteAccount;
}
