import { brand, core } from '@/lib/theme/tokens/palettes';

const SkeletonColors = Object.freeze({
  primary: brand.grey.tints[1],
  secondary: brand.lightBlue.tints[3],
  tertiary: core.white,
  dark: brand.lightBlue.tints[6],
});

export default SkeletonColors;
