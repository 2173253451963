import { cloneElement, useRef, useEffect, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';

const D3Chart = ({
  children,
  className,
  id,
  height,
  hideBorder,
  noScrollX,
  noScrollY,
  restrictHeight,
}) => {
  const chartRef = useRef();
  const [width, setWidth] = useState();

  // This use effect updates the width of the chart to assist in relative
  // sizing.
  useEffect(() => {
    if (chartRef?.current?.offsetWidth) {
      setWidth(chartRef.current.scrollWidth);
    }
  }, [chartRef?.current?.offsetWidth]);

  return (
    <div
      ref={chartRef}
      width="100%"
      style={{
        boxShadow: hideBorder
          ? 'none'
          : `0 0 0 1px ${BaseColors.border.primary}`,
        overflowX: noScrollX ? 'hidden' : 'auto',
        overflowY: noScrollY ? 'hidden' : 'auto',
        maxHeight: restrictHeight ? height : 'auto',
        maxWidth: '100%',
      }}
    >
      {width && (
        <svg
          className={className}
          id={id}
          width={width}
          height={height + 10}
          viewBox={`0 0 ${width} ${height}`}
          style={{ width: noScrollX ? '100%' : 'auto' }}
        >
          {cloneElement(children, { id, className, chartRef, width, height })}
        </svg>
      )}
    </div>
  );
};

export default D3Chart;
