const InvalidKeys = new Set(['e', 'E', '+']);

const validateNumberInput = (absolute, precision) => (event) => {
  const isDecimal = event.key === '.';
  const isNegative = event.key === '-';
  const isInvalid =
    InvalidKeys.has(event.key) ||
    (precision === 0 && isDecimal) ||
    (absolute && isNegative);

  if (isInvalid) {
    event.preventDefault();
  }
};

export default validateNumberInput;
