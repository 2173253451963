import { useRouter } from 'next/router';
import { useStore } from '@pkg/auth';
import Sdk from '@pkg/sdk';
import AccountDisabled from '@/components/AccountDisabled';
import NotRegistered from '@/components/NotRegistered';
import AppLoading from './AppLoading';

const FRONTEGG_BASE_ROUTE = '/account/';
const FRONTEGG_TOKEN_ROUTE = '?code=';

export default function Middleware({ children }) {
  const { isAuthenticated, isDisabled, isLoading, isRegistered } = useStore();
  const router = useRouter();

  if (isLoading) {
    return <AppLoading />;
  }

  if (isAuthenticated && !isRegistered) {
    return <NotRegistered />;
  }

  if (isAuthenticated && isDisabled) {
    return <AccountDisabled />;
  }

  if (!window.location.pathname.startsWith(FRONTEGG_BASE_ROUTE)) {
    const savedRoute = localStorage.getItem('_REDIRECT_AFTER_LOGIN_');
    if (savedRoute) {
      localStorage.removeItem('_REDIRECT_AFTER_LOGIN_');
      router.replace(Sdk.sanitizePath(savedRoute));
      return null;
    }
  }

  if (
    window.location.href.includes(FRONTEGG_TOKEN_ROUTE) &&
    window.location.href.includes('#layout=list')
  ) {
    localStorage.setItem('_ROLE_LIST_LAYOUT_', true);
  }

  return <>{children}</>;
}
