import { cloneElement, Fragment, useEffect, useState } from 'react';
import { SelectOption } from '@/atoms/inputs/Select';
import { Label } from '@/atoms/typography';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import TextOption from './TextOption';

const TextOptions = ({
  AddComponent,
  canAdd,
  categorised,
  color = 'secondary',
  label,
  options,
  optionLabel,
  optionId = 'uuid',
  OptionComponent,
  onClick,
  onFocus,
}) => {
  const [focusOption, setFocusOption] = useState();

  const handleFocus = (event, value) => {
    setFocusOption(value);
    onFocus?.(event, value);
  };

  useEffect(() => {
    if (!options?.length) {
      return;
    }

    if (options[0]?.[optionId] !== focusOption?.[optionId]) {
      // handleFocus(null, options[0]);
    }
  }, [options]);

  return (
    <Box>
      {label && <Label>{label}</Label>}
      {categorised
        ? options.map((category) => (
            <Fragment key={category.uuid}>
              {category.categorise && (
                <ListSubheader>{category.name}</ListSubheader>
              )}
              {category?.options.map((option) => (
                <TextOption
                  key={option?.[optionId]}
                  color={color}
                  focusOption={focusOption}
                  handleFocus={handleFocus}
                  option={option}
                  optionId={optionId}
                  optionLabel={optionLabel}
                  OptionComponent={OptionComponent}
                  onClick={(event) => onClick(event, option)}
                />
              ))}
            </Fragment>
          ))
        : options.map((option) => (
            <SelectOption
              key={option?.[optionId]}
              color={color}
              isFocus={
                option?.[optionId] === focusOption?.[optionId]
                  ? 'isFocus'
                  : null
              }
              onClick={(event) => onClick(event, option)}
              onFocus={(event) => handleFocus(event, option)}
              value={option}
            >
              {OptionComponent ? (
                OptionComponent(option)
              ) : (
                <>{option[optionLabel]}</>
              )}
            </SelectOption>
          ))}
      {AddComponent &&
        canAdd &&
        cloneElement(AddComponent, { onFocus: handleFocus })}
    </Box>
  );
};

export default TextOptions;
