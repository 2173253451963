import { Size } from '@/atoms/enums';
import { TextMetricGroup } from '@/molecules/metrics';
import { useInsightMetrics } from '@/organisms/insights';
import { ScopeType } from '@/shared/enums';
import { useInsightsContext, useScenarioContext } from '@/shared/providers';

const InsightMetrics = () => {
  const { filter } = useInsightsContext();
  const { comparisonScenario } = useScenarioContext();
  const ids = filter[ScopeType.ACTIVITIES]?.ids;

  const { metrics, isActiveComparison } = useInsightMetrics({
    filter,
    ids: ids ? [...ids] : [],
  });

  const showMetrics = metrics?.length > 0;

  return (
    showMetrics && (
      <TextMetricGroup
        alignGroup="center"
        leftPadding={4}
        rightPadding={2.5}
        metrics={metrics?.[0] || null}
        comparisonMetrics={metrics?.[1] || null}
        comparisonColor={comparisonScenario?.comparisonColor}
        showComparison={isActiveComparison}
        removePadding={true}
        showDividers={true}
        size={Size.X_LARGE}
        alignMetrics="flex-end"
      />
    )
  );
};

export default InsightMetrics;
