/**
 * @param {Array} values
 * @return {Object}
 */
export default function percentageDifference(primary, secondary) {
  const result = {
    raw: 0,
    percentage: 0,
  };

  const primaryClean = Number(primary);
  const secondaryClean = Number(secondary);

  if (isNaN(primaryClean) || isNaN(secondaryClean)) {
    return result;
  }

  const difference = primaryClean - secondaryClean;

  if (difference === 0) {
    return result;
  }

  result.raw = Math.round(difference * 100) / 100;
  result.percentage = Math.round((difference / secondaryClean) * 100);

  if (result.percentage === Infinity) {
    result.percentage = 100;
  }

  return result;
}
