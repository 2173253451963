import { People } from '@pkg/entities';
import { OrganisationPermission, PolicyLevel } from '@/lib/enums';

/**
 * @param {Object} me
 * @param {String} organisationId
 * @returns {String}
 */
export default function getPolicyLevel(me, organisationId) {
  switch (true) {
    default:
    case !me:
      return PolicyLevel.GUEST;
    case People.can(me, `${OrganisationPermission.MANAGE}.${organisationId}`):
      return PolicyLevel.ADMIN;
    case People.can(me, `${OrganisationPermission.READ}.${organisationId}`):
      return PolicyLevel.MEMBER;
  }
}
