import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { EntityType } from '@/shared/enums';
import { ScopeType } from '@/shared/enums';
import { useViewModeContext } from '@/shared/providers';
import useDesignStore from '@/components/DesignContainer/hooks/useDesignStore';

const buildFilter = (scope, scopeEntity, id, options) => {
  return {
    [ScopeType.ACTIVITIES]: {
      ids: new Set([id ?? 'ALL']),
      entity: scopeEntity,
      options,
    },
  };
};

export const InsightsContext = createContext();
export const useInsightsContext = () => useContext(InsightsContext);

const InsightsProvider = ({ uuid, children }) => {
  const { id, scope, scopeEntity } = useViewModeContext();
  const access = useDesignStore((state) => state.access);
  const accessBudget = access?.model?.budget;

  const [filter, setFilter] = useState(buildFilter(scope, scopeEntity, id));
  const [options, setOptions] = useState();

  const updateFilterOptions = (options) => {
    setFilter(buildFilter(scope, scopeEntity, id, options));
    setOptions(options);
  };

  useEffect(() => {
    const buildOptions = scopeEntity === EntityType.MANAGER ? options : null;
    setFilter(buildFilter(scope, scopeEntity, id, buildOptions));
  }, [scope, scopeEntity, id]);

  const context = useMemo(() => {
    return {
      accessBudget,
      filter,
      updateFilterOptions,
    };
  }, [accessBudget, filter, updateFilterOptions]);

  return (
    <InsightsContext.Provider value={context}>
      {children}
    </InsightsContext.Provider>
  );
};

export default InsightsProvider;
