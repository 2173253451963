import { Colors } from '@pkg/utils';
import { BeamibleTag, Lens } from '@/lib/enums';

/**
 * Threshold bucket definitions.
 */
export const ThresholdBucket = Object.freeze({
  UNDER_CAPACITY: 'UNDER_CAPACITY',
  HIGH: 'HIGH',
  INCOMPLETE: 'INCOMPLETE',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW',
});

export const ThresholdLabels = Object.freeze({
  [Lens.TAG]: {
    [ThresholdBucket.HIGH]: 'High',
    [ThresholdBucket.INCOMPLETE]: 'Incomplete',
    [ThresholdBucket.LOW]: 'Low',
    [ThresholdBucket.MEDIUM]: 'Medium',
    [ThresholdBucket.UNDER_CAPACITY]: 'Under capacity',
  },
  [Lens.WORKLOAD]: {
    [ThresholdBucket.HIGH]: 'Great',
    [ThresholdBucket.INCOMPLETE]: 'Incomplete',
    [ThresholdBucket.LOW]: 'Action needed',
    [ThresholdBucket.MEDIUM]: 'Ok',
    [ThresholdBucket.UNDER_CAPACITY]: 'Under capacity',
  },
  [Lens.OUTDATED_ROLES]: {
    [ThresholdBucket.HIGH]: 'Recent',
    [ThresholdBucket.MEDIUM]: 'Relatively recent',
    [ThresholdBucket.LOW]: 'Needs updating',
    [ThresholdBucket.INCOMPLETE]: 'Incomplete',
  },
});

export const ThresholdColor = Object.freeze({
  [ThresholdBucket.UNDER_CAPACITY]: Colors.lens('blue', 60),
  [ThresholdBucket.HIGH]: Colors.lens('status', 100),
  [ThresholdBucket.MEDIUM]: Colors.lens('status', 50),
  [ThresholdBucket.LOW]: Colors.lens('status', 20),
});

export const ThresholdScore = Object.freeze({
  [ThresholdBucket.UNDER_CAPACITY]: 100,
  [ThresholdBucket.LOW]: 20,
  [ThresholdBucket.MEDIUM]: 50,
  [ThresholdBucket.HIGH]: 100,
});

export const ThresholdDay = Object.freeze({
  [ThresholdBucket.HIGH]: 30,
  [ThresholdBucket.MEDIUM]: 60,
  [ThresholdBucket.LOW]: 90,
});

/**
 * Range functions mapped to thresholds for tags and insights.
 */
export const ThresholdRange = new Map([
  [
    BeamibleTag.ADMINISTRATIVE,
    {
      [ThresholdBucket.LOW]: (value) => value > 50,
      [ThresholdBucket.MEDIUM]: (value) => value > 40 && value <= 50,
      [ThresholdBucket.HIGH]: (value) => value <= 40,
    },
  ],
  [
    BeamibleTag.ENERGISING,
    {
      [ThresholdBucket.LOW]: (value) => value < 20,
      [ThresholdBucket.MEDIUM]: (value) => value >= 20 && value < 45,
      [ThresholdBucket.HIGH]: (value) => value >= 45,
    },
  ],
  [
    BeamibleTag.INTERNAL_MEETINGS,
    {
      [ThresholdBucket.LOW]: (value) => value > 50,
      [ThresholdBucket.MEDIUM]: (value) => value > 40 && value <= 50,
      [ThresholdBucket.HIGH]: (value) => value <= 40,
    },
  ],
  [
    Lens.OVER_CAPACITY,
    {
      [ThresholdBucket.LOW]: (value) => value > 60,
      [ThresholdBucket.MEDIUM]: (value) => value > 50 && value <= 60,
      [ThresholdBucket.HIGH]: (value) => value <= 50,
    },
  ],
  [
    BeamibleTag.STRATEGIC_IMPORTANCE,
    {
      [ThresholdBucket.LOW]: (value) => value < 20,
      [ThresholdBucket.MEDIUM]: (value) => value >= 20 && value < 30,
      [ThresholdBucket.HIGH]: (value) => value >= 30,
    },
  ],
  [
    Lens.WORKLOAD,
    {
      [ThresholdBucket.UNDER_CAPACITY]: (value) => value >= 80 && value < 90,
      [ThresholdBucket.LOW]: (value) => value > 130,
      [ThresholdBucket.MEDIUM]: (value) => value >= 110 && value <= 130,
      [ThresholdBucket.HIGH]: (value) => value >= 90 && value < 110,
    },
  ],
  [
    Lens.TAG,
    {
      [ThresholdBucket.LOW]: (value) => value < 33,
      [ThresholdBucket.MEDIUM]: (value) => value >= 33 && value < 66,
      [ThresholdBucket.HIGH]: (value) => value >= 66,
    },
  ],
  [
    Lens.OUTDATED_ROLES,
    {
      [ThresholdBucket.HIGH]: (value) =>
        value <= ThresholdDay[ThresholdBucket.HIGH],
      [ThresholdBucket.MEDIUM]: (value) =>
        value > ThresholdDay[ThresholdBucket.HIGH] &&
        value <= ThresholdDay[ThresholdBucket.LOW],
      [ThresholdBucket.LOW]: (value) =>
        value > ThresholdDay[ThresholdBucket.LOW],
    },
  ],
]);

/**
 * Returns a threshold bucket based on the value and specified range.
 * @param { Number } value
 * @param { String } thresholdRangeRangeId
 * @return {String}
 */
export const getThresholdBucket = ({ value, thresholdRangeId = null }) => {
  const rangeId = thresholdRangeId ?? Lens.TAG;
  const thresholdRange = ThresholdRange.get(rangeId);
  for (const bucket in thresholdRange) {
    if (thresholdRange[bucket](value)) {
      return bucket;
    }
  }
};

/**
 * Returns a threshold bucket based on the value and specified range.
 * @param { Number } value
 * @param { String } thresholdRangeId
 * @return {String}
 */
export const getThresholdScore = ({ value, thresholdRangeId = null }) => {
  const rangeId = thresholdRangeId ?? Lens.TAG;
  const thresholdRange = ThresholdRange.get(rangeId);
  for (const bucket in thresholdRange) {
    if (thresholdRange[bucket](value)) {
      return ThresholdScore[bucket];
    }
  }
};

export const getThresholdLabels = (thresholdRangeId) => {
  const rangeId = thresholdRangeId ?? Lens.TAG;
  return ThresholdLabels[rangeId] ?? ThresholdLabels[Lens.TAG];
};
