import env from '@beam-australia/react-env';
import AccessTokenProvider from '@pkg/auth/AccessTokenProvider';
import AuthObserver from '@pkg/auth/Observer';
import BroadcastProvider from '@pkg/broadcasting/echo/Provider';
import ClientProvider from '@pkg/client/Provider';
import DatabaseProvider from '@pkg/database/Provider';
import DesignsObserver from '@pkg/entities/designs/Observer';
import LibraryObserver from '@pkg/entities/library/Observer';
import TagsObserver from '@pkg/entities/tags/Observer';
import QueryProvider from '@pkg/graphql/Provider';
import config from '@/lib/config';
import StyleProvider from '@/lib/theme/Provider';
import Middleware from '../Middleware';
import AuthenticationProvider from './Authentication';
import IntercomProvider from './Intercom';
import Listener from './Listener';
import SnackbarProvider from './Snackbar';

const AppProviders = (props) => (
  <DatabaseProvider>
    <AuthenticationProvider>
      <StyleProvider>
        <AccessTokenProvider>
          <BroadcastProvider
            cluster={config.PUSHER_CLUSTER}
            apiKey={env('PUSHER_APP_KEY')}
            host={env('API_HOST')}
          >
            <SnackbarProvider>
              <ClientProvider host={env('API_HOST')}>
                <QueryProvider>
                  <AuthObserver />
                  <DesignsObserver />
                  <LibraryObserver />
                  <TagsObserver />
                  <Listener />
                  <Middleware>
                    <IntercomProvider {...props} />
                  </Middleware>
                </QueryProvider>
              </ClientProvider>
            </SnackbarProvider>
          </BroadcastProvider>
        </AccessTokenProvider>
      </StyleProvider>
    </AuthenticationProvider>
  </DatabaseProvider>
);

export default AppProviders;
