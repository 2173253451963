import { percent, roundFloat } from '@pkg/utils/numbers';

export default function formatChartData(
  activities,
  metrics,
  groupedActivitiesColor,
  initialActivityCount
) {
  if (!activities || !metrics) {
    return;
  }

  const labels = [];
  const data = [];
  const backgroundColor = [];
  let firstPercentage = 0;
  let countedPercentage = 0;

  activities.forEach((activity, index) => {
    const percentage = percent(
      activity.__aggregateHours,
      metrics.totalHours,
      2
    );

    if (index < initialActivityCount) {
      if (index === 0) {
        firstPercentage = percentage;
      }
      labels.push(`${percentage}% ${activity.__description}`);
      backgroundColor.push(activity.__color.foreground);
      data.push(percentage);
      countedPercentage += percentage;
    }
  });

  const remainingPercentage = roundFloat(100 - countedPercentage);

  const remainingActivitiesData =
    firstPercentage * 1.25 < remainingPercentage
      ? Math.round(firstPercentage * 1.25)
      : remainingPercentage;

  labels.push(`${remainingPercentage}% Other activities`);
  backgroundColor.push(groupedActivitiesColor);
  data.push(remainingActivitiesData);

  return {
    labels,
    datasets: [
      {
        borderWidth: 1,
        data,
        backgroundColor,
      },
    ],
  };
}
