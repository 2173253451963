import { FilterEntity, FilterProperty } from '@/organisms/filters/enums';

/**
 * Returns the filter property from the entity or property provided.
 * @param {String} entity
 * @param {String} property
 *
 * @return {String};
 */
export default function getFilterPropertyType(entity, property) {
  if (entity === FilterEntity.TAG || property === FilterProperty.TAG) {
    return FilterProperty.TAG;
  }
  if (entity === FilterEntity.SKILL || property === FilterProperty.SKILL) {
    return FilterProperty.SKILL;
  }
  if (
    entity === FilterEntity.PROPERTY ||
    property === FilterProperty.PROPERTY
  ) {
    return FilterProperty.PROPERTY;
  }

  return property;
}
