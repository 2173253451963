import FlexSearch from 'flexsearch';
import { useMemo } from 'react';
import { Obj } from '@pkg/utils';

/**
 * @param {FlexSearch.Index} index
 * @param {Map} mapped
 * @returns {FlexSearch.Index}
 */
const updateIndex = (index, mapped) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!mapped.has(key)) {
        index.remove(key);
      }
    }
  }

  mapped.forEach(({ name }, key) => {
    if (!index.register?.[key]) {
      index.add(key, name);
    }
  });

  return index;
};

const usePropertiesIndex = () => {
  const propertyMap = new Map();

  const updatePropertyIndex = (id, map) => {
    const existingProperty = propertyMap.get(id);

    if (!existingProperty) {
      const propertyIndex = new FlexSearch.Index({
        tokenize: 'forward',
        context: true,
      });

      propertyMap.set(id, {
        map,
        list: [...map.values()],
        index: updateIndex(propertyIndex, map),
      });

      return;
    }

    // Update the existing properties in the map.
    existingProperty.map = new Map([...existingProperty.map], [...map]);
    existingProperty.list = [...existingProperty.map.values()];
    existingProperty.index = updateIndex(existingProperty.index, map);
  };

  const getProperty = (id) => {
    return propertyMap.get(id);
  };

  return useMemo(
    () => ({
      updatePropertyIndex,
      getProperty,
    }),
    []
  );
};

export default usePropertiesIndex;
