import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import TooltipColors from './TooltipColors';

const Tooltip = ({
  children,
  color = 'primary',
  colorProps,
  enterDelay = 300,
  leaveDelay = 25,
  maxWidth,
  minWidth,
  title,
  position = 'top',
}) => {
  const colors = colorProps ?? TooltipColors[color];

  return (
    <MuiTooltip
      arrow
      enterDelay={enterDelay}
      leaveDelay={leaveDelay}
      title={title}
      placement={position}
      slotProps={{
        popper: {
          sx: {
            [`&.${tooltipClasses.popper} .${tooltipClasses.tooltip}`]: {
              ...colors,
              fontSize: '0.75rem',
              lineHeight: '1.25rem',
              maxWidth: maxWidth ?? 'auto',
              minWidth: minWidth ?? 'auto',
            },
            [`&.${tooltipClasses.popper} .${tooltipClasses.arrow}`]: {
              color: colors.backgroundColor,
            },
          },
        },
      }}
    >
      {children}
    </MuiTooltip>
  );
};

export default Tooltip;
