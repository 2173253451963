import { BeamibleTag, DesignEntity } from '@/lib/enums';

const TagContent = Object.freeze({
  [BeamibleTag.ENERGISING]: {
    [DesignEntity.ROLE]: {
      title: {
        main: 'Work that energises you',
        inverse: "Work you're not energised by",
      },
      subtitle: {
        main: `Your energising activities`,
        inverse: `Activities you're not energised by`,
      },
      summary: function (percentage) {
        return {
          main: `You're energised by ${percentage}% of the work you do`,
          inverse: `You're not energised by ${percentage}% of the work you do`,
        };
      },
    },
    [DesignEntity.GROUP]: {
      title: {
        main: 'Work that energises your team',
        inverse: "Work your team isn't energised by",
      },
      subtitle: {
        main: `Your team's energising activities`,
        inverse: `Activities your team isn't energised by`,
      },
      summary: function (percentage) {
        return {
          main: `Your team is energised by ${percentage}% of the work they do`,
          inverse: `Your team isn't energised by ${percentage}% of the work they do`,
        };
      },
    },
    [DesignEntity.ORGANISATION]: {
      title: {
        main: 'Work that energises your organisation',
        inverse: "Work your organisation isn't energised by",
      },
      subtitle: {
        main: `Teams energised by the work they do`,
        inverse: `Teams not energised by the work they do`,
      },
      summary: function (percentage) {
        return {
          main: `Your teams are energised by ${percentage}% of the work they do`,
          inverse: `Your teams aren't energised by ${percentage}% of the work they do`,
        };
      },
    },
  },
  [BeamibleTag.STRATEGIC_IMPORTANCE]: {
    [DesignEntity.ROLE]: {
      title: {
        main: `Work that's aligned to your goals`,
        inverse: `Work that's not aligned to your goals`,
      },
      subtitle: {
        main: `Your strategically important activities`,
        inverse: `Your activities that aren't strategically important`,
      },
      summary: function (percentage) {
        return {
          main: `You're spending ${percentage}% of your time on work that is aligned with your goals`,
          inverse: `You're spending ${percentage}% of your time on work that isn't aligned with your goals`,
        };
      },
    },
    [DesignEntity.GROUP]: {
      title: {
        main: `Work that's aligned to your team's goals`,
        inverse: `Work that's not aligned to your team's goals`,
      },
      subtitle: {
        main: `Your team's strategically important activities`,
        inverse: `Your team's activities that aren't strategically important`,
      },
      summary: function (percentage) {
        return {
          main: `Your team is spending ${percentage}% of their time on work that is aligned with their goals`,
          inverse: `Your team is spending ${percentage}% of their time on work that isn't aligned with their goals`,
        };
      },
    },
    [DesignEntity.ORGANISATION]: {
      title: {
        main: `Work that's aligned to your organisation's goals`,
        inverse: `Work that's not aligned to your organisation's goals`,
      },
      subtitle: {
        main: `Teams performing strategically important work`,
        inverse: `Teams performing work that isn't strategically important`,
      },
      summary: function (percentage) {
        return {
          main: `Your teams are spending ${percentage}% of their time on work that is aligned with their goals`,
          inverse: `Your teams are spending ${percentage}% of their time on work that isn't aligned with their goals`,
        };
      },
    },
  },
});

export default TagContent;
