/**
 * @param {Object[]} collection
 * @param {String} key
 * @returns {any[]}
 */
export function pluck(collection, key) {
  if (!collection) {
    return null;
  }

  const plucked = [];

  collection.forEach((item) => {
    if (Object.hasOwn(item, key)) {
      plucked.push(item[key]);
    }
  });

  return plucked;
}

/**
 * @param {Object[]} collection
 * @param {String} [key]
 * @returns {any[]}
 */
export function pluckId(collection, key = 'uuid') {
  return pluck(collection, key);
}
