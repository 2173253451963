import { useSnackbar } from 'notistack';
import { useMutation } from '@pkg/graphql';

const DELETE_USER = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      uuid
    }
  }
`;

export default function useDelete(query) {
  const { enqueueSnackbar } = useSnackbar();

  const { mutateAsync } = useMutation({
    mutation: DELETE_USER,
    invalidateQuery: query,
  });

  /**
   * @param {String} uuid
   * @param {String} name
   * @returns {Object|null}
   */
  async function deletePerson(uuid, name) {
    const state = await mutateAsync({ uuid });
    enqueueSnackbar(`Removed ${name} from your organisation`);
    return state;
  }

  return deletePerson;
}
