import { Subtitle } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const ActivityHeading = ({
  title = 'Activities',
  showDragDrop,
  showHours,
  totalHours,
}) => {
  return (
    <Stack
      direction="row"
      spacing={1}
      justifyContent="space-between"
      sx={{ pr: '32px' }}
    >
      <Subtitle overrideStyles={{ mt: 0, ml: showDragDrop ? 4 : 0 }}>
        {title}
      </Subtitle>
      {showHours && !isNaN(totalHours) && (
        <Subtitle overrideStyles={{ mt: 0, ml: showDragDrop ? 4 : 0 }}>
          {totalHours} hours
        </Subtitle>
      )}
    </Stack>
  );
};

export default ActivityHeading;
