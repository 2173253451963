export default class ClientError extends Error {
  constructor(response) {
    super('ClientError');
    this.name = 'ClientError';
    this.response = response;
  }

  getFieldErrors() {
    return this._mapFieldErrors(this.response);
  }

  _mapFieldErrors(response) {
    const mapped = {};
    if (response.errors) {
      for (const [key, value] of Object.entries(response.errors)) {
        if (Array.isArray(value)) {
          mapped[key] = value[0];
        }
      }
    }
    return mapped;
  }
}
