import { createContext, useContext, useState } from 'react';

export const AccessTokenContext = createContext();

export const useAccessToken = () => {
  return useContext(AccessTokenContext);
};

const Provider = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);

  // @todo - currently used for ai proxy, should use provider but needs to be functional
  if (typeof window !== 'undefined') {
    window.localStorage.setItem('accessToken', accessToken);
  }

  return (
    <AccessTokenContext.Provider value={{ accessToken, setAccessToken }}>
      {children}
    </AccessTokenContext.Provider>
  );
};

export default Provider;
