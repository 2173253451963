import { useDatabase } from '@pkg/database';
import useStore from './useStore';

export const DEFAULT_HYDRATED = Object.freeze({
  main: null,
  scenarios: {
    list: [],
    map: new Map(),
  },
});

/**
 * Hydrate all designs with their latest revisions
 */
export default function useHydrate() {
  const database = useDatabase();
  const { main, scenarios } = useStore();

  const revision = async (id) => {
    return await database.designRevisions.get(id);
  };

  /**
   * @param {String|String[]} [ids]
   */
  const hydrate = async (ids) => {
    const isRestricted = ids !== undefined;
    const set = new Set(Array.isArray(ids) ? ids : [ids]);
    const hydrated = structuredClone(DEFAULT_HYDRATED);

    if (main && (!isRestricted || set.has(main.uuid))) {
      hydrated.main = structuredClone(main);
      hydrated.main.latest = await revision(main.latest.uuid);
    }

    for (const item of scenarios.list) {
      if (isRestricted && !set.has(item.uuid)) {
        continue;
      }

      const scenario = structuredClone(item);
      scenario.latest = await revision(item.latest.uuid);
      hydrated.scenarios.list.push(scenario);
      hydrated.scenarios.map.set(scenario.uuid, scenario);
    }

    return hydrated;
  };

  return hydrate;
}
