import { useMultipartMutation } from '@pkg/graphql';

export const CREATE_FILE_IMPORT = /* GraphQL */ `
  mutation CreateFileImport($input: CreateFileImportInput!) {
    createFileImport(input: $input) {
      uuid
      status
    }
  }
`;

export default function useCreateFileImport() {
  const { mutateAsync } = useMultipartMutation({
    mutation: CREATE_FILE_IMPORT,
    map: {
      file: ['variables.input.file'],
    },
  });

  /**
   * @param {File} file
   * @param {Boolean} options.calculate_budgets
   */
  function createFileImport(file, options) {
    return mutateAsync({ file, options });
  }

  return createFileImport;
}
