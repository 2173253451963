import { Fragment, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Button from '@/components/Button';
import Typography from '@/components/Typography';
import Crop from '../Crop';
import FileInput from '../FileInput';

const Root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: 230,
  maxHeight: '100%',
  '@media (max-width: 960px)': {
    height: '100%',
  },
});

const Image = styled('img')({
  width: 'auto',
  maxWidth: '100%',
});

const AvatarDialog = ({ croppable, open, toggle, onSave, aspect, accept }) => {
  const [src, setSrc] = useState('');
  const [file, setFile] = useState(null);
  const readFile = (file) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => setSrc(reader.result));
    reader.readAsDataURL(file);
    setFile(file);
  };
  const fullScreen = useMediaQuery('(max-width:960px)');
  const save = () => {
    onSave(file);
    toggle();
    setSrc('');
  };
  const close = () => {
    toggle();
    setSrc('');
  };
  return (
    <Dialog
      open={open}
      onClose={close}
      fullScreen={fullScreen}
      maxWidth="sm"
      fullWidth
    >
      <DialogContent>
        <Root>
          {!src && (
            <FileInput accept={accept} onChange={(file) => readFile(file)} />
          )}
          {croppable && src && (
            <Fragment>
              <Crop
                src={src}
                aspect={aspect}
                onChange={(blob) => setFile(blob)}
              />
              <Typography variant="h6" color="primary">
                Drag to crop your image and then save.
              </Typography>
            </Fragment>
          )}
          {!croppable && src && <Image src={src} alt="Selected image" />}
        </Root>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} variant="outlined" fullWidth>
          Cancel
        </Button>
        <Button
          disabled={!file}
          onClick={save}
          color="primary"
          variant="outlined"
          fullWidth
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarDialog;
