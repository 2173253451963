import { produce } from 'immer';
import { Obj, Str } from '@pkg/utils';
import * as Actions from './actions';

/**
 * @param {Object} snapshot
 * @param {Object} mutation
 * @returns
 */
export default function mutate(snapshot, mutation) {
  return produce(snapshot, (draft) => {
    const { entities, ...update } = mutation;

    Object.keys(update).forEach((key) => {
      draft[key] = update[key];
    });

    if (entities) {
      Object.keys(entities).forEach((type) => {
        Object.keys(entities[type]).forEach((action) => {
          entities[type][action].forEach((input) => {
            Actions[action](type, draft, input);
          });
        });
      });
    }

    const omitted = Obj.omitDerived(draft);
    draft.__hash = Str.hash(omitted);
  });
}
