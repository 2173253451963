import * as Mutations from '../mutations';

/**
 * @param {any[]} query
 */
export default function useMutations() {
  return {
    useCreateFileImport: Mutations.useCreateFileImport(),
    useCreateIntegrationImport: Mutations.useCreateIntegrationImport(),
  };
}
