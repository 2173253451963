import { Size } from '@/atoms/enums';

const CircleBackgroundIconSize = Object.freeze({
  [Size.X_SMALL]: {
    icon: {
      width: 16,
      height: 16,
      borderRadius: '20px',
    },
    background: {
      width: 20,
      height: 20,
      borderRadius: '20px',
    },
  },
  [Size.SMALL]: {
    icon: {
      width: 16,
      height: 16,
      borderRadius: '24px',
    },
    background: {
      width: 24,
      height: 24,
      borderRadius: '24px',
    },
  },
  [Size.MEDIUM]: {
    icon: {
      width: 28,
      height: 28,
      borderRadius: '28px',
    },
    background: {
      width: 48,
      height: 48,
      borderRadius: '48px',
    },
  },
  [Size.LARGE]: {
    icon: {
      width: 34,
      height: 34,
      borderRadius: '34px',
    },
    background: {
      width: 58,
      height: 58,
      borderRadius: '58px',
    },
  },
});

export default CircleBackgroundIconSize;
