import { useEffect } from 'react';

/**
 * @param {PresenceChannel} channel
 *
 * @param {Object} presence
 * @param {Function} presence.setPeople
 *
 * @returns {undefined}
 */
export default function useLeave(channel, presence) {
  useEffect(() => {
    function handleLeaving(user) {
      presence.setPeople((users) => {
        return users.filter(({ uuid }) => uuid !== user.uuid);
      });
    }

    if (channel) {
      channel.leaving(handleLeaving);
    }
  }, [channel]);
}
