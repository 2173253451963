import { stackColor } from '@/organisms/charts';
import { EntityPluralName } from '@/shared/enums';
import { EntityType } from '@/shared/enums';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';
import aggregateOtherSegment from './aggregateOtherSegment';
import defaultOtherSegment from './defaultOtherSegment';

// The default margin between bars.
const SEGMENT_MARGIN = 8;

/**
 * Calculates the size of the stack segments and returns the array of segments.
 *
 * @param {Object}
 *
 * @return {Array}
 */
export default function buildStackSegments({
  fullStackChart,
  maxBarValue,
  metric,
  metricTotals,
  row,
  yScale,
  chartHeight,
}) {
  // We want the bottom of the bar to be at the base of the chart so we set the
  // y position to the chart height.
  let yPosition = chartHeight;
  // We want to use the percentage metric for rendering relative percentage bars
  // to keep the correct scale of the segments and bars that are expanded.
  const scaleMetric =
    metric === EntityMetric.RELATIVE_PERCENTAGE
      ? EntityMetric.PERCENTAGE
      : metric;
  const total = row.data[scaleMetric];
  // The multiplier is used to scale the stack bars to the full height of the
  // chart on a 100% stack chart.
  const multiplier = fullStackChart && total ? maxBarValue / total : 1;
  // The total row height is used with calculations for an "other" bar which
  // groups very small segments together into a single segment.
  const rowHeight = yScale(multiplier * (total > 0 ? total : maxBarValue));
  // We keep track of the total bar height so it can be used to determine the
  // remaining space available for the "other" bar.
  let totalBarHeight = 0;
  // For now we're setting property bars to the role color.
  const barColorEntity =
    row.type === EntityType.PROPERTY ? EntityType.ROLE : row.type;

  // A default other bar is created in case we have segments that don't reach
  // the minimum scale and then we group those segments into this bar.
  const otherSegment = {
    color: stackColor(1, barColorEntity),
    data: defaultOtherSegment(),
    height: 0,
    id: `${row.id}:Others`,
    isStackChild: true,
    label: `Other ${EntityPluralName[row.stackData?.[0]?.type]?.toLowerCase()}`,
    parentData: row.data,
    type: row.stackData?.[0]?.type,
    itemCount: 0,
  };

  const segmentData = [];

  row.stackData.forEach((item, index) => {
    if (!item?.data) {
      return;
    }
    const value = item.data?.[scaleMetric] ?? 0;
    const percentage = percent(value, total);
    const color = stackColor(percentage, barColorEntity);
    const barHeight = yScale(value * multiplier);
    const segmentMargin = index > 0 ? SEGMENT_MARGIN / 2 : 0;

    if (value === 0 || barHeight - SEGMENT_MARGIN / 2 < 4) {
      otherSegment.data = aggregateOtherSegment({
        bar: otherSegment,
        item,
        metric,
        metricTotals,
        row,
      });

      if (otherSegment.itemCount === 0) {
        const height = rowHeight - totalBarHeight - segmentMargin;

        otherSegment.height = height;
        otherSegment.yPosition = chartHeight - rowHeight;
      }

      otherSegment.itemCount += 1;
      return;
    }

    totalBarHeight += barHeight;
    yPosition = yPosition - barHeight;

    segmentData.push({
      ...item,
      height: barHeight - segmentMargin,
      color,
      yPosition,
    });
  });

  if (otherSegment.itemCount > 0) {
    otherSegment.label = `${otherSegment.label} (${otherSegment.data.count})`;
    segmentData.push(otherSegment);
  }

  return segmentData;
}
