import { DesignEntity } from '@/lib/enums';

const FilterProperty = Object.freeze({
  ACTIVITY: DesignEntity.ACTIVITY,
  ASSIGNEE: 'user_uuid',
  BUDGET: 'budget',
  CREATED_AT: 'created_at',
  DESCRIPTION: '__description',
  FTE: 'fte',
  GROUP: DesignEntity.GROUP,
  HOURS: 'hours',
  IS_MANAGER: 'is_manager',
  LAYER: '__layer',
  LAYERS: '__layers',
  LEAD: 'lead_uuid',
  NAME: 'name',
  PEOPLE: 'people',
  PROPERTY: 'properties',
  ROLE: DesignEntity.ROLE,
  SKILL: 'skills',
  SPAN: '__metrics.span.total.roles',
  TITLE: 'title',
  ROLE_UUID: 'uuid',
  TAG: 'tags',
  UPDATED_AT: 'updated_at',
});

export default FilterProperty;
