import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';

const Option = styled(MenuItem)({
  fontSize: 14,
  fontWeight: 500,
  lineHeight: '1.25rem',
  padding: '6px 10px',
});

export default Option;
