import { useEffect, useState } from 'react';
import { Designs } from '@pkg/entities';
import { HistoricalResolution } from '@/lib/enums';
import formatRevisions from '../utils/formatRevisions';

const REVISION_COUNT = 100;

const useSelectRevisions = (level, designId) => {
  const { revisions, hasMore, isLoading, loadMore, refetch } =
    Designs.useHistory({
      designId,
      level,
      resolution: HistoricalResolution.DAY,
      count: REVISION_COUNT,
    });

  const [isLoadingInitial, setIsLoadingInitial] = useState(true);
  const [revisionList, setRevisionList] = useState();

  useEffect(() => {
    if (!isLoading) {
      setRevisionList(formatRevisions(revisions));
      setIsLoadingInitial(false);
    }
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (!isLoading) {
      setIsLoadingInitial(true);
      setRevisionList();
      refetch();
    }
  }, [designId, level?.uuid]);

  return {
    hasMore,
    isUpdating: isLoading,
    isLoading: isLoadingInitial,
    loadMore,
    revisionList,
  };
};

export default useSelectRevisions;
