import { useRouter } from 'next/router';
import { Base as BaseColor } from '@/atoms/colors';
import { PrimaryNavigationBar } from '@/organisms/navigation';
import { EntityHierarchyProvider, DesignsProvider } from '@/shared/providers';
import { restrictNavigation } from './utils';

const AppLayout = ({ children }) => {
  const router = useRouter();
  const current = router.asPath;
  const showNavigation = !restrictNavigation(current);

  return (
    <div style={{ width: '100%' }}>
      {showNavigation && (
        <DesignsProvider>
          <EntityHierarchyProvider>
            <PrimaryNavigationBar />
          </EntityHierarchyProvider>
        </DesignsProvider>
      )}
      <div
        style={{
          backgroundColor: BaseColor.background.secondary,
          height: showNavigation ? 'calc(100vh - 45px)' : 'calc(100vh)',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
