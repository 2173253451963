import { useEffect } from 'react';

const subscribers = {};

const subscribe = (channel, { event, context, callback }) => {
  if (!subscribers?.[channel]) {
    subscribers[channel] = [];
  }

  subscribers[channel] = subscribers[channel].filter(
    (subscriber) => subscriber.event !== event || subscriber.context !== context
  );

  subscribers[channel].push({
    event,
    context,
    callback,
  });
};

export const notify = (channel) => (event, data) => {
  subscribers[channel].forEach((subscriber) => {
    if (subscriber.event === event) {
      subscriber.callback(data);
    }
  });
};

export const clearAllSubscribers = () => {
  for (const channel in subscribers) {
    delete subscribers[channel];
  }
};

/**
 * @param {String} channel
 * @param {Object[]} subscribers
 * @param {String} subscribers[].event
 * @param {String} subscribers[].context
 * @param {Function} subscribers[].callback
 * @returns {Function}
 */
function useObservable(channel, subscribers) {
  useEffect(() => {
    if (channel) {
      for (const subscriber of subscribers) {
        subscribe(channel, subscriber);
      }
    }
  }, [channel, subscribers]);

  return notify(channel);
}

export default useObservable;
