import { memo } from 'react';
import { styled } from '@mui/material/styles';

const Container = styled('div')({
  height: 'calc(100% - 41px)',
  overflowY: 'auto',
  padding: 16,
  paddingBottom: 96,
});

export default memo(Container);
