import { Collections, Sort } from '@pkg/utils';
import shortuuid from '@pkg/uuid';
import { ActivityOwner } from '@/lib/enums';

const DEFAULT_STEP = 0.25;

function roundHours(num) {
  return Math.round(num * (1 / DEFAULT_STEP)) / (1 / DEFAULT_STEP);
}

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String} input.uuid
 * @param {String} [input.new_uuid]
 * @returns {Object}
 */
export default function split(snapshot, { uuid, new_uuid }) {
  const now = Date.now();
  const mutation = {
    created_at: now,
    entities: {
      activities: {
        create: [],
        update: [],
      },
    },
  };

  const entities = snapshot.entities;
  const activities = structuredClone(entities.activities);
  const activity = Collections.findById(activities, uuid);

  const ownerId = activity.owner_uuid;
  const siblings = Collections.where(activities, 'owner_uuid', ownerId);
  siblings.sort(Sort.order());

  const originalHours = roundHours(activity.hours / 2);
  const splitHours = roundHours(activity.hours - originalHours);

  const original = {
    uuid: activity.uuid,
    hours: originalHours,
    updated_at: now,
  };

  const split = {
    uuid: new_uuid ?? shortuuid.generate(),
    library_uuid: activity.library_uuid,
    owner_uuid: activity.owner_uuid,
    owner_type: activity.owner_type,
    order: activity.order + 1,
    hours: splitHours || 0,
    source_uuid: activity.source_uuid,
    source_type: activity.source_type,
    properties: activity.properties ?? [],
    tags: activity.tags ?? [],
    created_at: now,
    updated_at: now,
  };

  mutation.entities.activities.create.push(split);
  mutation.entities.activities.update.push(original);

  const index = siblings.findIndex(({ uuid }) => uuid === original.uuid);
  siblings.splice(index + 1, 0, split);
  siblings.forEach((activity, order) => {
    if (activity.order !== order) {
      mutation.entities.activities.update.push({
        uuid: activity.uuid,
        order,
        updated_at: now,
      });

      activity.order = order;
      activity.updated_at = now;
    }
  });

  if (activity.owner_type === ActivityOwner.GROUP) {
    mutation.entities.groups = { update: [{ uuid: ownerId, updated_at: now }] };
  }

  if (activity.owner_type === ActivityOwner.ROLE) {
    mutation.entities.roles = { update: [{ uuid: ownerId, updated_at: now }] };
  }

  return mutation;
}
