/**
 * @param {Array} array
 * @param {Number} fromIndex
 * @param {Number} toIndex
 * @returns {undefined}
 */
export function mutate(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;
    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

/**
 * @param {Array} array
 * @param {Number} fromIndex
 * @param {Number} toIndex
 * @returns {Array}
 */
export default function move(array, fromIndex, toIndex) {
  const immutable = Array.from(array);
  mutate(immutable, fromIndex, toIndex);
  return immutable;
}
