import { useEffect, useState } from 'react';
import { useClient } from '@pkg/client';
import { useDatabase } from '@pkg/database';
import { Designs } from '@pkg/entities';
import { Collections, Sort } from '@pkg/utils';
import { HistoricalResolution } from '@/lib/enums';
import useFetchRevision from '../revisions/useFetch';

const INITIAL_STATE = {
  hasMore: null,
  revisions: [],
};

const GET_DESIGN_HISTORY = /* GraphQL */ `
  query GetDesignHistory(
    $designId: UUID!
    $level: DesignLevelInput
    $resolution: HistoricalResolution
    $first: Int
    $page: Int!
  ) {
    design(uuid: $designId) {
      revisions(
        level: $level
        page: $page
        resolution: $resolution
        first: $first
      ) {
        paginatorInfo {
          hasMorePages
        }
        data {
          uuid
          is_latest
          created_at
        }
      }
    }
  }
`;

export default function useHistory({
  designId,
  level,
  resolution = HistoricalResolution.NONE,
  count,
}) {
  const client = useClient();
  const database = useDatabase();
  const fetchRevision = useFetchRevision();

  const [isLoading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [state, setState] = useState(INITIAL_STATE);

  const fetch = async (page) => {
    if (!designId) {
      return {};
    }

    const response = await client.graphql({
      query: GET_DESIGN_HISTORY,
      variables: {
        designId,
        first: count,
        level,
        page,
        resolution,
      },
    });

    return response.data.design.revisions;
  };

  const handleRefresh = async () => {
    const results = structuredClone(state);

    for (let i = 0; i < page; i++) {
      const result = await fetch(i + 1);
      results.hasMore = Boolean(result?.paginatorInfo?.hasMorePages);

      const appended = results.revisions.concat(result?.data ?? []);
      const keyed = Collections.keyById(appended);
      results.revisions = Object.values(keyed);
      results.revisions.sort(Sort.date('created_at'));
    }

    setState(results);
    setLoading(false);
  };

  const loadMore = () => {
    if (state.hasMore && !isLoading) {
      setPage(page + 1);
      setLoading(true);
    }
  };

  const refetch = () => {
    setState(INITIAL_STATE);
    setPage(1);
    setLoading(true);
  };

  const refresh = () => {
    if (!isLoading) {
      setLoading(true);
    }
  };

  const select = async (revisionId) => {
    const revision = await database.designRevisions.get(revisionId);

    if (!revision) {
      await fetchRevision(revisionId);
    }

    return true;
  };

  useEffect(() => {
    if (isLoading) {
      handleRefresh();
    }
  }, [isLoading]);

  Designs.listeners.useSubscriber(
    designId,
    '.design.versioned',
    refresh,
    'history'
  );

  return {
    ...state,
    isLoading,
    loadMore,
    refresh,
    refetch,
    select,
  };
}
