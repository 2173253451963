import { ScopeType } from '@/shared/enums';
import { DesignEntity, EntityMetric, Visibility } from '@/lib/enums';
import isEntityRestricted from './isEntityRestricted';
import isTagRestricted from './isTagRestricted';

// Checks if the groupId exists in the parent entities.
const isParentGroup = (entity, groupId) => {
  return entity?.__above?.[DesignEntity.GROUP]?.has(groupId);
};

/**
 * Calculates the insight metrics for activities.
 *
 * @param {Object}
 *
 * @return {Object}
 */
export default function calculateActivityInsightMetrics({
  filter,
  scenario,
  snapshotEntityMap,
}) {
  const groupSet = new Set();
  const roleSet = new Set();
  const activitySet = new Set();
  let hours = 0;
  let budget = 0;
  let fte = 0;
  const entity = scenario?.entity;
  const entityType = entity?.__type;

  // We need to traverse groups to include them in the metrics event if they
  // don't have any activities or roles.
  const groupMap = scenario.relationships?.get(DesignEntity.GROUP);

  if (groupMap?.size) {
    groupMap?.forEach((group) => {
      if (!group || group.__visibility === Visibility.NONE) {
        return;
      }

      // If this group is a parent of the scenario entity we don't want to add
      // it to the metrics.
      if (isParentGroup(entity, group.uuid)) {
        return;
      }

      // Filter the group by the relevant entity type if we have provided
      // it to the activity scope filter.
      if (filter?.[ScopeType.ACTIVITIES]) {
        const isRestricted = isEntityRestricted({
          ids: filter[ScopeType.ACTIVITIES].ids,
          entityType: filter[ScopeType.ACTIVITIES].entity,
          options: filter[ScopeType.ACTIVITIES].options,
          entityMap: snapshotEntityMap,
          group,
        });

        if (isRestricted) {
          return;
        }
      }

      groupSet.add(group.uuid);
    });
  }

  // We need to traverse roles to include them in the metrics because not all
  // roles in the organisation have activities.
  snapshotEntityMap.get(DesignEntity.ROLE).forEach((role) => {
    // If the role isn't visible we're going to prevent it from being added.
    if (role.__visibility === Visibility.NONE) {
      return;
    }

    const group = snapshotEntityMap
      ?.get(DesignEntity.GROUP)
      ?.get(role.group_uuid);

    // Filter the role by the relevant entity type if we have provided it
    // to the activity scope filter.
    let filterGroup = false;
    if (filter?.[ScopeType.ACTIVITIES]) {
      const filterScopeEntity = filter[ScopeType.ACTIVITIES].entity;

      const isRestricted = isEntityRestricted({
        ids: filter[ScopeType.ACTIVITIES].ids,
        entityType: filter[ScopeType.ACTIVITIES].entity,
        group,
        options: filter[ScopeType.ACTIVITIES].options,
        entityMap: snapshotEntityMap,
        role,
      });

      // Exclude any parent groups of the manager from being included in the
      // metrics.
      if (filterScopeEntity === DesignEntity.MANAGER) {
        [...filter[ScopeType.ACTIVITIES].ids].forEach((id) => {
          const managerRole = snapshotEntityMap.get(DesignEntity.ROLE)?.get(id);
          if (managerRole?.__above?.[DesignEntity.GROUP]?.has(group?.uuid)) {
            filterGroup = true;
          }
        });
      }

      if (isRestricted) {
        return true;
      }
    }

    // Add the role and the group if is isn't filtered out.
    if (role.__visibility !== Visibility.NONE) {
      roleSet.add(role.uuid);
    }

    // If this group is a parent of the scenario entity we don't want to add
    // it to the metrics.
    const groupIsParent = isParentGroup(entity, group?.uuid);

    // Add the group to the set to be used for a count.
    if (
      group &&
      !groupIsParent &&
      !filterGroup &&
      group.__visibility !== Visibility.NONE
    ) {
      groupSet.add(group.uuid);
    }

    // If the role has full visibility we're going to add it's budget data
    // to the metrics. If it has partial visibility the budget data will be
    // added during the activity metric building.
    if (role.__visibility === Visibility.FULL) {
      const roleMetrics = role.__metrics?.self?.visible;
      budget += roleMetrics.budget ?? 0;
      fte += roleMetrics.fte ?? 0;
    }
  });

  scenario.relationships?.get(DesignEntity.ACTIVITY).forEach((activity) => {
    // We don't want to include filtered or unallocated activities.
    if (
      activity.__visibility === Visibility.NONE ||
      activity.owner_type === DesignEntity.GROUP
    ) {
      return;
    }

    // Get the role and add it to the set to be used for the count.
    const role = snapshotEntityMap
      ?.get(DesignEntity.ROLE)
      ?.get(activity.owner_uuid);

    const group = snapshotEntityMap
      ?.get(DesignEntity.GROUP)
      ?.get(role.group_uuid);

    // Filter the activities by the tag id if we have provided a tag filter
    // scope.
    if (filter?.[ScopeType.TAGS]) {
      const isRestricted = isTagRestricted(
        activity,
        filter[ScopeType.TAGS]?.ids
      );

      if (isRestricted) {
        return true;
      }
    }

    // Filter the role by the relevant entity type if we have provided it
    // to the activity scope filter.
    let filterGroup = false;
    if (filter?.[ScopeType.ACTIVITIES]) {
      const filterScopeEntity = filter[ScopeType.ACTIVITIES].entity;

      const isRestricted = isEntityRestricted({
        activity,
        ids: filter[ScopeType.ACTIVITIES].ids,
        entityType: filter[ScopeType.ACTIVITIES].entity,
        group,
        options: filter[ScopeType.ACTIVITIES].options,
        entityMap: snapshotEntityMap,
        role,
      });

      // Exclude any parent groups of the manager from being included in the
      // metrics.
      if (filterScopeEntity === DesignEntity.MANAGER) {
        [...filter[ScopeType.ACTIVITIES].ids].forEach((id) => {
          const managerRole = snapshotEntityMap.get(DesignEntity.ROLE)?.get(id);
          if (managerRole?.__above?.[DesignEntity.GROUP]?.has(group?.uuid)) {
            filterGroup = true;
          }
        });
      }

      if (isRestricted) {
        return true;
      }
    }

    // Avoid adding activities with null values polluting the data.
    if (activity.hours === null) {
      return;
    }

    // Add the activity
    activitySet.add(activity.uuid);

    // No need to continue if the activity hours are zero.
    if (!activity.hours) {
      return;
    }

    // Add the hours
    hours += activity.hours;

    // If this group is a parent of the scenario entity we don't want to add
    // it to the metrics.
    const groupIsParent = isParentGroup(entity, group?.uuid);

    // Add the group to the set to be used for a count.
    if (
      group &&
      !groupIsParent &&
      !filterGroup &&
      group.__visibility !== Visibility.NONE
    ) {
      groupSet.add(group.uuid);
    }

    const roleMetrics = role.__metrics?.self?.visible;

    // If we are filtering by activities, none of the roles or groups would
    // have been added during the role and group checks. We need to add them
    // here.
    if (!roleSet.has(role.uuid) && role.__visibility !== Visibility.NONE) {
      roleSet.add(role.uuid);

      if (role.__visibility === Visibility.FULL) {
        budget += roleMetrics.budget ?? 0;
        fte += roleMetrics.fte ?? 0;
        return;
      }
    }

    // From this point we only want to add budget and FTE data for roles that
    // have partial visibility because we didn't add their entire budget or
    // fte when adding their role to the metrics.
    if (role.__visibility !== Visibility.PARTIAL) {
      return;
    }

    // Calculate the percentage so it can be used for the budget and fte
    // values.
    const percentage = roleMetrics.hours
      ? activity.hours / roleMetrics?.hours
      : 0;
    budget += roleMetrics.budget * percentage;
    fte += roleMetrics.fte * percentage;
  });

  const metrics = {
    [EntityMetric.GROUPS]: groupSet.size,
    [EntityMetric.ROLES]: roleSet.size,
    [EntityMetric.ACTIVITIES]: activitySet.size,
    [EntityMetric.BUDGET]: budget,
    [EntityMetric.HOURS]: hours,
    [EntityMetric.FTE]: fte,
  };

  return metrics;
}
