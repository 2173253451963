import { styled } from '@mui/material/styles';
import Box from '@/components/Box';
import Image from '@/components/Image';
import Typography from '@/components/Typography';

const Graphic = styled(Image)({
  maxWidth: 320,
  display: 'block',
});

const DesignNotAuthorized = () => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
  >
    <Graphic src="/infographics/busy.png" alt="access denied image" />
    <Typography color="primary" variant="h4" align="center" gutterBottom>
      You are not authorised to view this design.
    </Typography>
    <Typography variant="subtitle1" align="center" gutterBottom>
      Please contact the owner to grant permission.
    </Typography>
  </Box>
);

export default DesignNotAuthorized;
