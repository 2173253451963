import {
  Information,
  InformationParagraph,
  InformationTitle,
} from '@/molecules/information';

const MatrixInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        Use this matrix to compare tagged activities.
      </InformationParagraph>
      <InformationTitle>Compare one tag</InformationTitle>
      <InformationParagraph>
        Select one tag from the list and tagged activities will be displayed in
        the left half, while untagged activities will be listed in the right
        half of the diagram below.
      </InformationParagraph>
      <InformationParagraph>
        Select each half to see and explore the activities listed in the table.
      </InformationParagraph>
      <InformationTitle>Compare two tags</InformationTitle>
      <InformationParagraph>
        Select two tags from the list and tagged activities will be listed in
        the diagram below:
      </InformationParagraph>
      <InformationTitle>Top left</InformationTitle>
      <InformationParagraph>
        Activities that are tagged by Tag 1 and not Tag 2
      </InformationParagraph>
      <InformationTitle>Top right</InformationTitle>
      <InformationParagraph>
        Activities that are both tagged Tag 1 and Tag 2
      </InformationParagraph>
      <InformationTitle>Bottom right</InformationTitle>
      <InformationParagraph>
        Activities that are tagged by Tag 2 and not Tag 1
      </InformationParagraph>
      <InformationTitle>Bottom left</InformationTitle>
      <InformationParagraph>
        Activities that are not tagged Tag 1 or Tag 2
      </InformationParagraph>
      <InformationParagraph>
        Select each quadrant to see and explore the activities listed in the
        table.
      </InformationParagraph>
    </Information>
  );
};

export default MatrixInformation;
