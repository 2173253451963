const AssignedRolesFilterPreset = {
  'role.title': {
    filterType: 'multi',
    filterModels: [
      {
        filterType: 'text',
        type: 'notBlank',
      },
      null,
    ],
  },
};

export default AssignedRolesFilterPreset;
