import { Fragment } from 'react';
import { Divider } from '@/atoms/dividers';
import { TextMetric } from '@/atoms/metrics';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const leftMetricSpacing = (alignGroup, firstIndex, leftPadding) => {
  switch (true) {
    case alignGroup === 'flex-start' && firstIndex:
      return 0.5;
    default:
      return leftPadding;
  }
};

const rightMetricSpacing = (
  alignGroup,
  lastIndex,
  showDividers,
  rightPadding
) => {
  switch (true) {
    case alignGroup === 'flex-start' && !lastIndex:
      return rightPadding;
    case alignGroup === 'flex-end' && !lastIndex:
      return showDividers ? rightPadding - 1 : 0;
    default:
      return rightPadding;
  }
};

const TextMetricGroup = ({
  alignGroup = 'center',
  boldText,
  dividerColor,
  endDivider,
  showDividers = true,
  metrics,
  comparisonMetrics,
  comparisonColor,
  metricDirection,
  showComparison,
  removePadding,
  leftPadding = 2,
  rightPadding = 2,
  size,
  justifyMetrics = 'center',
  alignMetrics = 'flex-start',
}) => {
  const isCenterAligned = alignGroup === 'center';

  return (
    <Stack
      direction="row"
      justifyContent={alignGroup}
      spacing={isCenterAligned ? 1 : 0}
      alignItems="top"
    >
      {metrics?.length > 0 &&
        metrics?.map((metric, index) => {
          const comparisonValue = showComparison
            ? comparisonMetrics?.[index]?.value
            : null;

          return (
            <Fragment key={`${metric.label}-${metric.value}`}>
              <Box
                pl={leftMetricSpacing(alignGroup, index === 0, leftPadding)}
                pr={rightMetricSpacing(
                  alignGroup,
                  index === metrics[0].length - 1,
                  showDividers,
                  rightPadding
                )}
              >
                <TextMetric
                  alignItems={alignMetrics}
                  justifyContent={justifyMetrics}
                  boldText={boldText}
                  direction={metricDirection}
                  formatOptions={metric.formatOptions}
                  label={metric.label}
                  removePadding={removePadding}
                  size={size}
                  value={metric.value}
                  comparisonValue={comparisonValue}
                  comparisonColor={comparisonColor}
                />
              </Box>
              {showDividers &&
                (index < metrics[0].length - 1 || endDivider) && (
                  <Divider color={dividerColor} orientation="vertical" />
                )}
            </Fragment>
          );
        })}
    </Stack>
  );
};

export default TextMetricGroup;
