import { IconButton } from '@/atoms/buttons';
import { Size } from '@/atoms/enums';
import { CircleBackgroundIcon } from '@/atoms/icons';
import CloseIcon from '@mui/icons-material/CloseRounded';

const CancelButton = ({ onClick }) => {
  return (
    <IconButton
      color="light-blue"
      fullWidth={false}
      onClick={onClick}
      size={Size.LARGE}
    >
      <CircleBackgroundIcon
        color="light-blue"
        size={Size.X_SMALL}
        icon={<CloseIcon />}
      />
    </IconButton>
  );
};

export default CancelButton;
