import { Size } from '@/atoms/enums';

const LabelSize = Object.freeze({
  [Size.SMALL]: {
    fontSize: '0.725rem',
  },
  [Size.MEDIUM]: {
    fontSize: '0.775rem',
  },
  [Size.LARGE]: {
    fontSize: '0.825rem',
  },
  [Size.X_LARGE]: {
    fontSize: '0.875rem',
  },
});

export default LabelSize;
