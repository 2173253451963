import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';

const MERGE = /* GraphQL */ `
  mutation MergeLibraryActivity($input: MergeLibraryActivityInput!) {
    mergeLibraryActivity(input: $input)
  }
`;

/**
 * @return {Function}
 */
export default function useMerge() {
  const database = useDatabase();
  const { mutateAsync } = useMutation({ mutation: MERGE });
  const { enqueueSnackbar } = useSnackbar();

  /**
   * @param {String} source_uuid
   * @param {String} target_uuid
   * @returns {Promise}
   */
  function mergeActivity(source_uuid, target_uuid) {
    enqueueSnackbar('Your activities are being merged.');
    database.activities.delete(source_uuid); // @todo rollback
    database.designs.clear();

    return mutateAsync({ source_uuid, target_uuid });
  }

  return useCallback(mergeActivity, [mutateAsync]);
}
