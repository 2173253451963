import { Tooltip } from '@/molecules/modals';
import Stack from '@mui/material/Stack';
import { ucfirst } from '@pkg/utils/strings';
import ModeSelectorColors from './utils/ModeSelectorColors';

const ModeOption = ({ selected, mode, onClick }) => {
  const isSelected = selected === mode.id;
  const label = ucfirst(mode.label, true);
  const colors = isSelected
    ? ModeSelectorColors.selected
    : ModeSelectorColors.normal;

  const handleClick = (event) => {
    onClick?.(event, mode.id);
  };

  return (
    <Tooltip title={label} position="bottom">
      <Stack
        width={38}
        height={28}
        alignItems="center"
        justifyContent="center"
        onClick={handleClick}
        sx={{
          ...colors,
          cursor: 'pointer',
          borderRadius: '2px',
          transition: 'background-color 200ms',
          '&:hover': !isSelected && {
            ...ModeSelectorColors.hover,
          },
          position: 'relative',
        }}
      >
        {mode.icon}
      </Stack>
    </Tooltip>
  );
};

export default ModeOption;
