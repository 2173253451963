import { useEffect, useState } from 'react';
import { currency } from '@pkg/utils/inputs';
import config from '@/lib/config';
import Text from './Text';

const SalaryInput = ({ onBlur, onChange, ...props }) => {
  const [value, setValue] = useState(currency.format(props.value));

  const handleBlur = (event, value) => {
    const blurred = currency.blur(value, { max: config.MAX_SALARY });
    setValue(currency.format(blurred));
    onBlur?.(event, blurred);
  };

  const handleChange = (event, value) => {
    const changed = currency.change(value);
    setValue(changed === '' ? changed : currency.format(changed));
    onChange?.(event, changed);
  };

  useEffect(() => {
    setValue(currency.format(props.value));
  }, [props.value]);

  return (
    <Text
      {...props}
      disableClear
      initialValue={currency.format(props.value)}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
    />
  );
};

SalaryInput.propTypes = Text.propTypes;

export default SalaryInput;
