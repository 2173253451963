import { brand, core } from '@/lib/theme/tokens/palettes';

const IconColors = Object.freeze({
  primary: {
    contained: {
      normal: {
        backgroundColor: brand._navy.main,
        color: core.white,
      },
      hover: {
        backgroundColor: brand._navy.tints[4],
        color: core.white,
      },
      focus: {
        backgroundColor: brand._navy.tints[4],
        color: core.white,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: brand._navy.main,
      },
      hover: {
        backgroundColor: brand._navy.tints[2],
        color: brand._navy.main,
      },
      focus: {
        backgroundColor: brand._navy.tints[2],
        color: brand._navy.main,
      },
    },
    outlined: {
      normal: {
        border: 'none',
        boxShadow: `0 0 0 1px ${brand._navy.main}`,
        color: brand._navy.main,
      },
      hover: {
        backgroundColor: brand._navy.tints[2],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand._navy.main}`,
        color: brand._navy.tints[4],
      },
      focus: {
        backgroundColor: brand._navy.tints[2],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand._navy.main}`,
        color: brand._navy.tints[4],
      },
    },
  },
  secondary: {
    contained: {
      normal: {
        backgroundColor: brand.yellow.main,
        color: core.white,
      },
      hover: {
        backgroundColor: brand.yellow.shades[2],
        color: core.white,
      },
      focus: {
        backgroundColor: brand.yellow.shades[2],
        color: core.white,
      },
    },
    text: {
      normal: {
        backgroundColor: 'transparent',
        color: brand.yellow.main,
      },
      hover: {
        backgroundColor: brand.yellow.tints[2],
        color: brand.yellow.main,
      },
      focus: {
        backgroundColor: brand.yellow.tints[2],
        color: brand.yellow.main,
      },
    },
    outlined: {
      normal: {
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.yellow.main}`,
        color: brand.yellow.main,
      },
      hover: {
        backgroundColor: brand.yellow.tints[2],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.yellow.main}`,
        color: brand.yellow.main,
      },
      focus: {
        backgroundColor: brand.yellow.tints[2],
        border: 'none',
        boxShadow: `0 0 0 1px ${brand.yellow.tints[2]}`,
        color: brand.yellow.main,
      },
    },
  },
});

export default IconColors;
