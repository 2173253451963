import { useEffect, useState } from 'react';
import { metricListFromObject } from '@/organisms/insights';
import { calculateActivityInsightMetrics } from '@/organisms/insights';
import { useScenarioContext, useViewModeContext } from '@/shared/providers';

const useInsightMetrics = ({ metricList, filter, ids }) => {
  const {
    scenario,
    snapshotEntityMap,
    comparisonScenario,
    comparisonScenarioEntityMap,
  } = useScenarioContext();
  const { showBudget } = useViewModeContext();
  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    if (!scenario) {
      return;
    }

    const update = [];

    const metricValues = calculateActivityInsightMetrics({
      filter,
      scenario,
      snapshotEntityMap,
    });
    update.push(metricListFromObject(metricValues, metricList, showBudget));

    if (comparisonScenario) {
      const metricValues = calculateActivityInsightMetrics({
        filter,
        scenario: comparisonScenario,
        snapshotEntityMap: comparisonScenarioEntityMap,
      });
      update.push(metricListFromObject(metricValues, metricList, showBudget));
    }

    setMetrics(update);
  }, [
    scenario?.entity?.__uuid,
    scenario?.entity?.uuid,
    scenario?.entity?.__visible_metrics,
    comparisonScenario,
    comparisonScenario?.entity?.__uuid,
    comparisonScenario?.entity?.uuid,
    showBudget,
    JSON.stringify(ids),
    JSON.stringify(filter),
  ]);

  const isActiveComparison = metrics.length > 1;
  return { metrics, isActiveComparison };
};

export default useInsightMetrics;
