import { useEffect, useRef, useState } from 'react';
import { Button } from '@/atoms/buttons';
import { Base as BaseColors } from '@/atoms/colors';
import { Variant } from '@/atoms/enums';
import { Colors } from '@/atoms/inputs';
import { TextInput } from '@/atoms/inputs';
import { Label } from '@/atoms/typography';
import CloseIcon from '@mui/icons-material/CloseRounded';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/styles';
import { core } from '@/lib/theme/tokens/palettes';
import AddOption from './AddOption';
import SelectOptions from './TextOptions';

const StyledSelectBox = styled(Box)(() => ({
  borderRadius: 4,
  backgroundColor: core.white,
  color: BaseColors.font.primary,
}));

const TextSelect = ({
  autoFocus = false,
  addOption = false,
  disableClearable,
  categorised = false,
  color = 'secondary',
  canAddNew = true,
  filterOptions,
  initialValue,
  label,
  maxLength,
  maxHeight,
  noOptionsText,
  onBlur,
  onFocus,
  OptionComponent,
  optionLabel,
  options,
  onChange,
  onClose,
  onKeyDown,
  placeholder,
  showOptions = false,
  showCloseButton,
  size,
  variant = Variant.FLAT,
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(initialValue[optionLabel]);
  const [previous, setPrevious] = useState(initialValue);
  const isFlat = variant === Variant.FLAT;

  const canAdd =
    Boolean(inputValue.length) &&
    canAddNew &&
    inputValue !== previous[optionLabel];

  const [scrollHeight, setScrollHeight] = useState();

  /**
   * Handles adding a new value via keyboard or mouse events.
   * @ToDo: Discuss if we want to automatically select the first option if it exists when hitting the enter key.
   */
  const handleAdd = (event) => {
    if (!inputValue && disableClearable) {
      handleSelect(event, previous);
      return;
    }

    // Ensures we use existing items if they match the entered text.
    const nearestOption = options[0]?.[optionLabel];
    if (nearestOption?.toLowerCase() === inputValue.toLowerCase()) {
      handleSelect(event, options[0]);
      return;
    }

    // @ToDo: Discuss if we always want 'new' to be set for the uuid of
    // new values.
    handleSelect(event, {
      uuid: 'new',
      [optionLabel]: inputValue,
    });
  };

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  // A catch-all for triggering upstream change events and setting the input
  // value from the various events.
  const handleSelect = (event, value) => {
    setPrevious(value);
    setInputValue(value[optionLabel]);
    onChange?.(event, value);
  };

  // @ToDo: Discuss if we want new values to be set when tabbing or clicking
  // away from the text input. This has repercussions when tabbing between
  // the input field and the select options.
  const handleBlur = (event) => {
    if (!inputValue && disableClearable) {
      setInputValue(previous[optionLabel]);
    }
    onBlur?.(event, inputValue);
  };

  const handleClose = (event) => {
    handleBlur(event);
    onClose?.(event);
  };

  const handleFocus = (event) => {
    onFocus?.(event);
  };

  const handleKeyDown = (event, value) => {
    if (canAdd && event.key === 'Enter') {
      handleAdd(event);
    }
    onKeyDown?.(event, value);
  };

  // Handles the option filtering.
  useEffect(() => {
    const filterValue =
      inputValue === initialValue[optionLabel] ? '' : inputValue;
    filterOptions?.(filterValue);
  }, [inputValue]);

  // Handles live updating of the select field when receiving upstream changes.
  useEffect(() => {
    if (initialValue?.uuid !== previous?.uuid) {
      setPrevious(initialValue);
      setInputValue(initialValue[optionLabel]);
    }
  }, [initialValue]);

  // Updates the scrollable height if a maxHeight is set.
  useEffect(() => {
    if (!inputRef?.current || !maxHeight) {
      return;
    }

    const height = maxHeight - inputRef.current.clientHeight;

    setScrollHeight(height);
  }, [inputRef?.current, maxHeight]);

  return (
    <StyledSelectBox width="100%" color={color} position="relative">
      <Box
        p={0.5}
        ref={inputRef}
        sx={{
          backgroundColor:
            variant === Variant.OUTLINE
              ? 'transparent'
              : Colors.Input[color].hover.backgroundColor,
          boxShadow: isFlat
            ? `inset 0 -1px 0 ${Colors.Input.primary.normal.shadowColor}`
            : 'none',
        }}
      >
        <Stack direction="row">
          <TextInput
            autoFocus={autoFocus}
            fullWidth={true}
            initialValue={initialValue[optionLabel]}
            maxLength={maxLength}
            onBlur={handleBlur}
            onChange={handleInputChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            size={size}
            variant={variant}
          />
          {showCloseButton && (
            <Box>
              <Button
                color="primary"
                endIcon={<CloseIcon />}
                label="Close"
                onClick={handleClose}
                variant="naked"
              />
            </Box>
          )}
        </Stack>
      </Box>
      {showOptions && (
        <Box
          position={isFlat ? 'relative' : 'absolute'}
          sx={{
            width: isFlat ? '100%' : 'calc(100% - 16px)',
            backgroundColor: core.white,
            mx: 'auto',
            left: isFlat ? 'inherit' : '8px',
            boxShadow: isFlat
              ? 'none'
              : `0 0 0 1px ${Colors.Input[color].normal.borderColor}, 
                 0 2px 3px 1px ${Colors.Input[color].normal.shadowColor}75`,
            borderRadius: 1,
            overflow: isFlat ? 'visible' : 'hidden',
          }}
        >
          <Box
            maxHeight={scrollHeight}
            width="100%"
            paddingBottom={canAdd ? 5 : 0}
            sx={{ overflowY: 'auto' }}
            zIndex={0}
          >
            <Box>
              {label && (
                <Box p={1}>
                  <Label>{label}</Label>
                </Box>
              )}
              <SelectOptions
                AddComponent={
                  addOption && (
                    <AddOption
                      isFocus={!options.length}
                      onClick={handleAdd}
                      tabIndex={0}
                      value={inputValue}
                    />
                  )
                }
                categorised={categorised}
                OptionComponent={OptionComponent}
                options={options}
                optionLabel={optionLabel}
                canAdd={canAdd}
                onClick={handleSelect}
              />
              {options.length === 0 && noOptionsText && (
                <Box textAlign="center" py={2}>
                  <Label>{noOptionsText}</Label>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </StyledSelectBox>
  );
};

export default TextSelect;
