import { DesignEntity } from '@/lib/enums';

/**
 * @param {Object} entity
 * @param {Object} level
 * @return {Boolean}
 */
const excludeEntity = (entity, level, includeNested) => {
  if (level?.type !== DesignEntity.GROUP) {
    return false;
  }

  switch (entity.__type) {
    case DesignEntity.ROLE:
      // exclude if not in path
      if (includeNested) {
        return !entity.__path.has(level.uuid);
      }

      // exlude if not directly in group
      return entity.group_uuid !== level.uuid;

    case DesignEntity.ACTIVITY:
      // exclude if owner nested
      return !includeNested && entity.__group !== level.uuid;

    case DesignEntity.GROUP:
      // include the level
      if (entity.uuid === level.uuid) {
        return false;
      }

      // include immediately surround
      if (entity.__span[DesignEntity.GROUP].has(level.uuid)) {
        return false;
      }

      // include all sub groups
      if (entity.__path.has(level.uuid)) {
        return false;
      }

      return true;

    default:
      return false;
  }
};

const mapEntityOptions = (entities, level, includeNested) => {
  const map = new Map();

  entities?.forEach((entity) => {
    if (excludeEntity(entity, level, includeNested)) {
      return;
    }

    map.set(entity.uuid, entity);
  });

  return map;
};

export default function mapDesign(design, snapshot, level, includeNested) {
  if (!snapshot) {
    return null;
  }

  return new Map([
    [DesignEntity.ORGANISATION, design],
    [
      DesignEntity.PERSON,
      mapEntityOptions(snapshot.entities.people, level, includeNested),
    ],
    [
      DesignEntity.GROUP,
      mapEntityOptions(snapshot.entities.groups, level, includeNested),
    ],
    [
      DesignEntity.ROLE,
      mapEntityOptions(snapshot.entities.roles, level, includeNested),
    ],
    [
      DesignEntity.MANAGER,
      mapEntityOptions(snapshot.entities.roles, level, includeNested),
    ],
    [
      DesignEntity.ACTIVITY,
      mapEntityOptions(snapshot.entities.activities, level, includeNested),
    ],
  ]);
}
