import { useState, useEffect } from 'react';
import { MetricProperty } from '@/atoms/enums';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import { useAccess } from '@pkg/access/organisations';

/**
 * @param {Object} entity
 * @param {Map} relationships
 * @return {Object}
 */
const useScenarioMetrics = (metricList, filter, useComparison = false) => {
  const { scenario: primary, comparisonScenario } = useScenarioContext();
  const scenario = useComparison ? comparisonScenario : primary;
  const access = useAccess();
  const canAccessBudget = access.model.budget;

  const [metrics, setMetrics] = useState([]);

  useEffect(() => {
    if (!scenario?.entity || !scenario?.entity?.__visible_metrics) {
      return;
    }

    const metrics = [];

    metricList.forEach((metric) => {
      if (metric === 'budget' && !canAccessBudget) {
        return;
      }

      if (isNaN(scenario.entity.__visible_metrics[metric])) {
        return;
      }

      const value = scenario.entity.__visible_metrics[metric];
      const label =
        value === 1
          ? MetricProperty[metric].label.singular
          : MetricProperty[metric].label.plural;

      metrics.push({
        label,
        value,
        formatOptions: MetricProperty[metric].formatOptions,
      });
    });

    setMetrics(metrics);
  }, [
    scenario?.entity?.uuid,
    scenario?.entity?.updated_at,
    JSON.stringify(scenario?.entity?.__visible_metrics),
    filter?.id,
  ]);

  return metrics;
};

export default useScenarioMetrics;
