import { color, palettes } from '@/lib/theme/tokens';

const WEIGHTS = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 900];

export default function lens(paletteKey, value) {
  const key = paletteKey.split('_')[0];
  const weightIndex = Math.floor(value / 10);
  const weight = WEIGHTS[weightIndex];
  const palette = palettes[key.toLowerCase()];
  return {
    color: palette.colors[weight],
    contrast: color.text[palette.contrast[weight]],
  };
}
