import { useSnackbar } from 'notistack';
import { useDatabase } from '@pkg/database';
import { useMutation } from '@pkg/graphql';

const MERGE_TAGS = /* GraphQL */ `
  mutation MergeTags($input: MergeTagsInput!) {
    mergeTags(input: $input)
  }
`;

/**
 * @param {any[]} query
 * @returns {Function}
 */
export default function useMutateTagging(query) {
  const database = useDatabase();
  const { enqueueSnackbar } = useSnackbar();
  const { mutateAsync } = useMutation({
    mutation: MERGE_TAGS,
    invalidateQuery: query,
  });

  /**
   * @param {String} organisation_uuid
   * @param {String} source_uuid
   * @param {String} destination_uuid
   * @returns {Promise}
   */
  async function mutate(organisation_uuid, source_uuid, destination_uuid) {
    enqueueSnackbar(
      'Merging tags... Please allow a few minutes before modifying any tags in your design.'
    );

    await database.tags.delete(source_uuid);
    return await mutateAsync({
      organisation_uuid,
      source_uuid,
      destination_uuid,
    });
  }

  return mutate;
}
