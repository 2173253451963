import { useEffect } from 'react';
import * as Auth from '@pkg/auth';
import { Database } from '@pkg/database';
import useMutations from './useMutations';
import useRefresh from './useRefresh';
import useStore from './useStore';

export default function useLive() {
  const { isAuthenticated, isLoading } = Auth.useStore();
  const { refresh, isRefreshing } = useRefresh();
  const { setProperties, setLoading, setMutations } = useStore();
  const mutations = useMutations();

  const isStale = Database.useStaleHash('properties');
  const properties = Database.useLive('properties');

  useEffect(() => {
    if (isLoading || isRefreshing) {
      return;
    }

    if (!isAuthenticated) {
      setProperties([]);
      setLoading(false);
      return;
    }

    if (isStale) {
      refresh();
      return;
    }

    if (!properties) {
      return;
    }

    setProperties(properties);
    setLoading(false);
  }, [properties, isAuthenticated, isLoading, isRefreshing, isStale]);

  useEffect(() => {
    setMutations(isAuthenticated ? mutations : {});
  }, [isAuthenticated, mutations]);
}
