import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import { ucfirst } from '@pkg/utils/strings';
import { SkillLevel } from '@/lib/enums';
import { color } from '@/lib/theme/tokens';
import Box from '@/components/Box';
import Button from '@/components/Button';
import Select from '@/components/Inputs/Select';
import Skill from '@/components/Skill';

const Root = styled(Box)({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  gap: '8px',
});

const Option = styled('li')({
  cursor: 'pointer',
  padding: '0 12px 0 16px',
  '&:hover': {
    backgroundColor: color.primary.grey[100],
  },
  '&:last-child': {
    marginBottom: '8px',
  },
});

const LevelSelect = styled(Select)({
  flex: 1,
});

export default function AddSkill({ library, selected, onAdd }) {
  const [inputValue, setInputValue] = useState('');
  const [uuid, setUuid] = useState(null);
  const [level, setLevel] = useState(SkillLevel.BASIC);

  const levels = Object.entries(SkillLevel)
    .filter(([_, value]) => typeof value === 'string')
    .map(([key, value]) => ({
      value: key,
      label: ucfirst(value, true),
    }));

  const handleChange = (event, value, reason) => {
    if (['selectOption', 'clear'].includes(reason)) {
      setUuid(value?.uuid ?? null);
      setInputValue(value?.name ?? '');
    }
  };

  const handleLevelChange = (event) => {
    setLevel(event.target.value);
  };

  const handleInputValue = (_, value, reason) => {
    if (reason !== 'reset') {
      setInputValue(value);
    }
  };

  const handleAdd = (event) => {
    onAdd(event, { uuid, level });
    setUuid(null);
    setInputValue('');
  };

  useEffect(() => {
    if (selected) {
      setUuid(selected.uuid);
      setLevel(selected.level);
      setInputValue(library.find(({ uuid }) => uuid === selected.uuid).name);
    }
  }, [selected]);

  return (
    <Root>
      <Autocomplete
        data-testid="skills-filter"
        filterOptions={(options) =>
          options.filter(({ name }) =>
            name.toLowerCase().includes(inputValue.toLowerCase())
          )
        }
        freeSolo
        fullWidth
        getOptionLabel={({ uuid }) => uuid}
        inputValue={inputValue}
        onChange={handleChange}
        onInputChange={handleInputValue}
        options={library}
        renderOption={(props, skill) => (
          <Option key={skill.uuid} {...props}>
            <Skill
              name={skill.name}
              skill={{ ...skill, level: SkillLevel.INTERMEDIATE }}
            />
          </Option>
        )}
        renderInput={({ InputProps, ...params }) => {
          delete InputProps.className;
          delete params.InputLabelProps;
          delete params.inputProps.className;

          return (
            <OutlinedInput
              {...InputProps}
              {...params}
              placeholder="Select skill"
            />
          );
        }}
      />
      <LevelSelect
        onChange={handleLevelChange}
        options={levels}
        value={level}
        placeholder={'Select level'}
      />
      <Button onClick={handleAdd} variant="primary" disabled={!level || !uuid}>
        + Add
      </Button>
    </Root>
  );
}

AddSkill.propTypes = {
  library: PropTypes.array,
  selected: PropTypes.array,
  onAdd: PropTypes.func,
};
