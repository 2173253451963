import { BadgeColor, NumberBadgeSize } from '@/atoms/badges';
import { Size } from '@/atoms/enums';
import { Label } from '@/atoms/typography';
import Stack from '@mui/material/Stack';

const NumberBadge = ({ color = 'secondary', number, size = Size.X_SMALL }) => {
  const colors = BadgeColor[color];
  const sizeProps = NumberBadgeSize[size];

  return (
    <Stack
      sx={{ ...sizeProps, ...colors }}
      justifyContent="center"
      alignItems="center"
    >
      <Label
        overrideStyles={{
          color: colors.color,
          fontSize: sizeProps.fontSize,
          fontWeight: sizeProps.fontWeight,
        }}
      >
        {number}
      </Label>
    </Stack>
  );
};

export default NumberBadge;
