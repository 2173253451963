import { Collections } from '@pkg/utils';

/**
 * Convert properties array into mutation shape for updates
 * @param {Object[]} [from]
 * @param {Object[]} [to]
 * @returns {Object|null}
 */
export default function handlePropertiesInput(from, to) {
  if (!from || !to) {
    return null;
  }

  const fromKeyed = Collections.keyBy(from);
  const toKeyed = Collections.keyBy(to);
  const input = {
    create: [],
    update: [],
    remove: [],
  };

  from.forEach(({ key, value }) => {
    const exists = Object.hasOwn(toKeyed, key);

    // create
    if (!exists) {
      return input.create.push({ key, value });
    }

    // update
    if (exists && toKeyed[key].value !== value) {
      return input.update.push({ key, value });
    }
  });

  to.forEach(({ key }) => {
    // remove
    if (!Object.hasOwn(fromKeyed, key)) {
      return input.remove.push(key);
    }
  });

  Object.keys(input).forEach((action) => {
    if (!input[action].length) {
      delete input[action];
    }
  });

  return Object.keys(input).length ? input : null;
}
