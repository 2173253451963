import { Size } from '@/atoms/enums';
import { ListColors, ListNumberSizeProps } from '@/atoms/lists';
import { Paragraph } from '@/atoms/typography';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import Box from '@mui/material/Box';

const ListNumber = ({
  active,
  color = 'primary',
  completed,
  index,
  size = Size.MEDIUM,
}) => {
  const colorProps =
    ListColors[color][active ? 'active' : completed ? 'completed' : 'normal'];

  const sizeProps = ListNumberSizeProps[size];

  return (
    <Box
      sx={{
        ...colorProps,
        ...sizeProps,
        flexShrink: 0,
        textAlign: 'center',
        borderWidth: '1px',
        borderStyle: 'solid',
        transition: 'all 300ms',
      }}
    >
      {completed ? (
        <Box>
          <DoneRoundedIcon
            sx={{
              mt: `calc(${sizeProps.height} * 0.075)`,
              fontSize: `calc(${sizeProps.fontSize} * 1.5)`,
              width: `calc(${sizeProps.width} * 0.8)`,
              height: `calc(${sizeProps.height} * 0.8)`,
            }}
          />
        </Box>
      ) : (
        <Paragraph
          size={size}
          overrideStyles={{
            fontWeight: sizeProps.fontWeight,
            lineHeight: sizeProps.lineHeight,
            mb: 0,
          }}
        >
          {index}
        </Paragraph>
      )}
    </Box>
  );
};

export default ListNumber;
