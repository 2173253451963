import { useEffect, useState } from 'react';
import { useAccessToken } from '@pkg/auth/AccessTokenProvider';
import Sdk from '@pkg/sdk';

/**
 * @param {object} config
 * @param {string} config.apiKey
 * @param {string} config.cluster
 * @param {string} config.host
 *
 * @return {Object}
 */
export default function useConnection(config) {
  const [echo, setEcho] = useState();
  const [socketId, setSocketId] = useState();
  const { accessToken } = useAccessToken();

  async function createInstance() {
    const { apiKey, host, cluster } = config;

    const Echo = await import('laravel-echo');
    const Pusher = await import('pusher-js');

    const client = new Pusher.default(apiKey, {
      cluster,
      authEndpoint: `${host}/broadcasting/auth`,
      auth: {
        headers: Sdk.getHeaders(accessToken),
      },
    });

    client.connection.bind('connected', ({ socket_id }) => {
      setSocketId(socket_id);
    });

    const instance = new Echo.default({
      broadcaster: 'pusher',
      client,
    });

    setEcho(instance);
  }

  useEffect(() => {
    if (!echo && accessToken) {
      createInstance();
    }
  }, [echo, accessToken]);

  return { echo, socketId };
}
