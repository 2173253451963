import { memo, useState, useEffect } from 'react';
import { PercentageChangeText } from '@/atoms/metrics';
import { Heading, Label } from '@/atoms/typography';
import { OutlineCard } from '@/organisms/cards';
import { percentageChange } from '@/shared/utils/calculations';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';

const DifferenceCard = ({ isNew, label, value, comparisonValue }) => {
  const [percentage, setPercentage] = useState(0);
  const [displayValue, setDisplaValue] = useState(0);
  const difference = value - comparisonValue;
  const title =
    difference > 1 || difference < -1 ? label.plural : label.singular;

  useEffect(() => {
    const percentage = percentageChange(comparisonValue, value);
    setPercentage(percentage);

    setDisplaValue(
      format(difference, {
        signDisplay: 'exceptZero',
      })
    );
  }, [value, comparisonValue]);

  return (
    <OutlineCard padding={0} margin={0}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: '100%' }}
      >
        {isNew && <Label>New activity</Label>}
        {difference === 0 && !isNew && <Label>No changes</Label>}
        {difference !== 0 && !isNew && (
          <>
            <PercentageChangeText size="large" value={percentage} />
            <Heading variant="h5">{`(${displayValue} ${title})`}</Heading>
          </>
        )}
      </Stack>
    </OutlineCard>
  );
};

export default memo(DifferenceCard, (prev, next) => {
  return (
    prev.value === next.value &&
    prev.comparisonValue === next.comparisonValue &&
    prev.isNew === next.isNew
  );
});
