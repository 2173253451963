import { Avatar } from '@/atoms/avatars';
import { EntityChip } from '@/atoms/chips';
import { Size } from '@/atoms/enums';
import { Heading } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { DesignEntity } from '@/lib/enums';
import { ChipType } from '@/lib/enums';

const RoleSummary = ({
  avatar,
  entity,
  person,
  showChip = true,
  isTeamLead,
  isSupporter,
  isScenario,
  size = Size.MEDIUM,
}) => {
  const chipType = entity?.is_manager ? DesignEntity.MANAGER : entity?.__type;
  return (
    entity && (
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: '100%' }}
      >
        <Box>
          {showChip && (
            <Stack direction="row" spacing={1}>
              <EntityChip type={chipType} size={size} />
              {isTeamLead && <EntityChip type={ChipType.LEAD} size={size} />}
              {isSupporter && (
                <EntityChip type={ChipType.SUPPORTER} size={size} />
              )}
              {isScenario && (
                <EntityChip type={ChipType.SCENARIO} size={size} />
              )}
            </Stack>
          )}
          <Heading
            variant="h3"
            overrideStyles={{ my: 0.5, fontSize: '1.15rem' }}
          >
            {entity.title ?? entity.name}
          </Heading>
        </Box>
        {person && (
          <Stack spacing={0.5} alignItems="center">
            {avatar && (
              <Avatar
                src={avatar}
                alt={`${person?.first_name} ${person?.last_name}`}
                size={Size.X_SMALL}
              />
            )}
            <Heading variant="h4">
              {person.first_name} {person.last_name}
            </Heading>
          </Stack>
        )}
      </Stack>
    )
  );
};

export default RoleSummary;
