import { Utils } from '@/atoms/chips';
import { EntityName } from '@/shared/enums';

/**
 * Groups the actions by entity type.
 *
 * @param {Array} actions
 *
 * @return {Object}
 */
export default function groupActions(actions) {
  const actionGroups = {};

  actions.forEach((action) => {
    if (actionGroups[action.entity]) {
      actionGroups[action.entity].actions.push(action);
    } else {
      const chipColor = Utils.entityColor(action.entity);
      actionGroups[action.entity] = {
        entityType: action.entity,
        label: `${EntityName[action.entity]} ACTIONS`,
        colorProps: chipColor,
        actions: [action],
      };
    }
  });

  return actionGroups;
}
