import bs58 from 'bs58';
import { UlidMonotonic, Uuid } from 'id128';

export function generate(short = true) {
  const bytes = UlidMonotonic.generate().bytes;
  return short
    ? bs58.encode(bytes)
    : Uuid.construct(bytes).toCanonical().toLowerCase();
}

export function lengthen(id) {
  const decoded = bs58.decode(id);
  return Uuid.construct(decoded).toCanonical().toLowerCase();
}

export function shorten(id) {
  const decoded = Uuid.fromCanonical(id).bytes;
  return bs58.encode(decoded);
}

const shortuuid = Object.freeze({ generate, lengthen, shorten });
export default shortuuid;
