import { EntityType } from '@/shared/enums';
import SnapshotMetricGroup from '@pkg/entities/snapshots/enums/SnapshotMetricGroup';

export default function aggregateMetrics(entity) {
  if (!entity) {
    return { ...SnapshotMetricGroup };
  }

  if (entity.__type === EntityType.ORGANISATION) {
    return entity.__visible_metrics;
  }
  if (entity.__type === EntityType.ROLE && entity.is_manager) {
    const metrics = {};
    Object.entries(entity.__metrics.managed.visible).forEach(([key, value]) => {
      metrics[key] = value + (entity.__metrics.self.visible[key] || 0);
    });
    return metrics;
  }
  if (entity.__type === EntityType.ROLE) {
    return entity.__metrics.self.visible;
  }

  if (entity.__type === EntityType.GROUP) {
    // We use the self activities to include unallocated activities for the
    // scenario group.
    return {
      ...entity.__metrics.managed.visible,
      activities: entity.__metrics.self.visible.activities,
    };
  }
}
