import FlexSearch from 'flexsearch';
import { produce } from 'immer';
import { create } from 'zustand';
import { Obj, Sort } from '@pkg/utils';

const InitialIndex = new FlexSearch.Index({
  tokenize: 'forward',
  context: true,
});

/**
 * @param {Index} index
 * @param {Map} mapped
 * @returns {Index}
 */
const updateIndex = (index, mapped) => {
  if (!Obj.isEmpty(index.register)) {
    for (const key in index.register) {
      if (!mapped.has(key)) {
        index.remove(key);
      }
    }
  }

  mapped.forEach(({ description }, key) => {
    if (!index.register?.[key]) {
      index.add(key, description);
    }
  });

  return index;
};

/**
 * @param {Object[]} list
 * @param {Index} index
 * @returns {Object}
 */
export const storeActivities = (list, index = InitialIndex) => {
  const mapped = new Map();
  const descriptions = {
    default: new Map(),
    lower: new Map(),
  };

  const sorted = list.sort(Sort.alpha('description'));
  sorted.forEach((item) => {
    const id = item.uuid;
    const description = item.description;

    mapped.set(id, item);
    descriptions.default.set(description, id);
    descriptions.lower.set(description.toLowerCase(), id);
  });

  return {
    descriptions,
    index: updateIndex(index, mapped),
    list: sorted,
    mapped,
  };
};

/**
 * @type {Function}
 * @param {Function} [getter]
 * @returns {any}
 */
const useStore = create((set, get) => ({
  descriptions: {
    default: new Map(),
    lower: new Map(),
  },
  list: [],
  index: InitialIndex,
  isLoading: true,
  mapped: new Map(),
  mutations: {},
  setLoading: (isLoading) => set(() => ({ isLoading })),
  setMutations: (mutations) => set(() => ({ mutations })),
  setActivities: (list) => set(({ index }) => storeActivities(list, index)),

  /**
   * @param {Object} activity
   * @param {String} activity.uuid
   * @param {String} activity.description
   * @returns {void}
   */
  add: ({ uuid, description }) =>
    set(
      produce((state) => {
        state.descriptions.default.set(uuid, description);
        state.descriptions.lower.set(uuid, description.toLowerCase());
        state.index.add(uuid, description);
        state.list.push({ uuid, description });
        state.list.sort(Sort.alpha('description'));
        state.mapped.set(uuid, { uuid, description });
      })
    ),

  /**
   * @param {String} description
   * @param {Boolean} strict
   * @returns {String|null}
   */
  getExisting: (description, strict = false) => {
    const map = get().descriptions[strict ? 'default' : 'lower'];
    const value = strict ? description : description.toLowerCase();
    return map.get(value) ?? null;
  },
}));

export default useStore;
