import { ActionType } from '@/organisms/plans';
import { EntityType } from '@/shared/enums';
import addActionByActivity from './addActionByActivity';
import updateMap from './updateMap';

/**
 * Creates a map of changed activities.
 *
 * @param {Object}
 *
 * @return {Map}
 */
export default function mapAggregateActivities({
  scenario,
  comparisonScenario,
}) {
  if (!comparisonScenario) {
    return;
  }

  const activityMap = new Map();

  // Keep a record of the original entities.
  const originalActivityList = new Set(
    comparisonScenario.relationships?.get(EntityType.ACTIVITY)?.keys()
  );

  // Loop through the activities in the scenario to create the activity map.
  [...scenario.relationships.get(EntityType.ACTIVITY).values()].forEach(
    (activity) => {
      const originalActivity = comparisonScenario.relationships
        ?.get(EntityType.ACTIVITY)
        ?.get(activity.uuid);

      const role =
        activity.owner_type === EntityType.ROLE
          ? scenario.relationships
              .get(EntityType.ROLE)
              ?.get(activity.owner_uuid)
          : null;

      originalActivityList.delete(originalActivity?.uuid);

      const actions = [];

      // Add activity actions.
      actions.push(
        ...addActionByActivity({
          activity,
          originalActivity,
          scenario,
          comparisonScenario,
        })
      );

      let added = false;
      let removed = false;

      for (let i = 0; i < actions?.length; i++) {
        const action = actions[i];

        added = added || action.type === ActionType.ADDED_ACTIVITY;
        removed = removed || action.type === ActionType.REMOVED_ACTIVITY;

        if (added && removed) {
          action.metrics[1].moved += 1;
          added = false;
          removed = false;
        }

        // Updates the activity map with the activity metrics and action.
        updateMap({ action, activity, activityMap, comparisonScenario, role });
      }
    }
  );

  // Loop through the activities that didn't exist in the scenario.
  [...originalActivityList].forEach((id) => {
    const activity = comparisonScenario.relationships
      .get(EntityType.ACTIVITY)
      ?.get(id);

    if (!activity) {
      return;
    }

    const role =
      activity.owner_type === EntityType.ROLE
        ? comparisonScenario.relationships
            .get(EntityType.ROLE)
            ?.get(activity.owner_uuid)
        : null;

    originalActivityList.delete(activity?.uuid);

    const actions = [];

    // Add activity actions.
    actions.push(
      ...addActionByActivity({
        originalActivity: activity,
        scenario,
        comparisonScenario,
      })
    );

    for (let i = 0; i < actions?.length; i++) {
      const action = actions[i];

      // Updates the activity map with the activity metrics and action.
      updateMap({ action, activity, activityMap, comparisonScenario, role });
    }
  });

  return activityMap;
}
