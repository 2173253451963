import { Utils } from '@/atoms/chips';
import { Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';

const TagDescription = ({ tag }) => {
  const tagColor = Utils.tagColor({ color: tag?.color });

  return (
    tag && (
      <Box
        sx={{
          boxShadow: `inset 6px 0 0 ${tagColor?.normal?.backgroundColor}`,
          p: 2,
        }}
      >
        <Paragraph overrideStyles={{ m: 0 }}>
          <span style={{ fontWeight: 600 }}>
            {tag.name}
            {tag.description ? ': ' : ''}
          </span>
          {tag.description}
        </Paragraph>
      </Box>
    )
  );
};

export default TagDescription;
