import { Base as BaseColors } from '@/atoms/colors';
import { brand } from '@/lib/theme/tokens/palettes';

const DialogColors = Object.freeze({
  color: BaseColors.font.tertiary,
  backgroundColor: `${brand._navy.shades[6]}91`,
  shadowColor: `${brand._navy.shades[6]}96`,
});

export default DialogColors;
