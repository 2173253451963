import { Collections } from '@pkg/utils';
import archive from './archive';

/**
 * @param {Object} snapshot
 * @param {Object} input
 * @param {String[]} input.sourceIds
 * @param {String} input.targetId
 * @returns {Object}
 */
export default function merge(snapshot, { sourceIds, targetId }) {
  const entities = snapshot.entities;
  const now = Date.now();

  const keyed = Collections.keyById(entities.activities);
  const sources = sourceIds.map((id) => keyed[id]);

  const target = structuredClone(keyed[targetId]);
  const tags = new Set(target.tags ?? []);

  sources.forEach((activity) => {
    target.hours += activity.hours;
    activity.tags?.forEach((id) => tags.add(id));
  });

  const mutation = archive(snapshot, sourceIds);
  const updates = mutation.entities.activities.update;
  const index = updates.findIndex(({ uuid }) => uuid === targetId);

  if (index >= 0) {
    mutation.entities.activities.update[index].hours = target.hours;
    mutation.entities.activities.update[index].tags = Array.from(tags);
    return mutation;
  }

  mutation.entities.activities.update.push({
    uuid: targetId,
    hours: target.hours,
    tags: Array.from(tags),
    updated_at: now,
  });

  return mutation;
}
