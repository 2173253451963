import { TeamSummary } from '@/molecules/summaries';
import { useScenarioContext } from '@/shared/providers/ScenarioProvider';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { ChipType, DesignEntity } from '@/lib/enums';
import OutlineCard from './OutlineCard';

const ConnectedTeams = () => {
  const { scenario } = useScenarioContext();
  const all = scenario?.relationships?.get(DesignEntity.GROUP);

  const current = scenario.entity;
  const surroundId = current.group_uuid;

  const connected = structuredClone(all);
  connected.delete(current.uuid);

  return (
    <OutlineCard title="Connected teams" margin={0} padding={0}>
      {Boolean(scenario?.relationships) && (
        <Stack
          justifyContent="flex-start"
          alignItems="flex-start"
          sx={{ m: 1 }}
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={1}
          >
            {Array.from(connected.values()).map((group) => {
              const chip = group.uuid === surroundId ? ChipType.GROUP : null;
              return (
                <Grid item xs={4} key={group.uuid}>
                  <TeamSummary key={group.uuid} chip={chip} group={group} />
                </Grid>
              );
            })}
          </Grid>
        </Stack>
      )}
    </OutlineCard>
  );
};

export default ConnectedTeams;
