/**
 * @param {Object[]} collection
 * @param {String} key
 * @param {any} value
 *
 * @return {Object}
 */
export function whereNot(collection, key, value) {
  return collection.filter((item) => item[key] !== value);
}
