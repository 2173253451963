import { useEffect, useState } from 'react';
import { BaseChartBar } from '@/molecules/chartElements';
import Stack from '@mui/material/Stack';
import { percent } from '@pkg/utils/numbers';
import { palettes } from '@/lib/theme/tokens';
import Box from '@/components/Box';
import Tooltip from '@/components/Tooltip';
import Typography from '@/components/Typography';

const DEFAULT_COLORS = Object.freeze({
  background: palettes.brand.blue.tints[0],
  foreground: palettes.brand.blue.tints[2],
  font: palettes.brand.blue.shades[6],
  highlight: {
    background: palettes.brand.blue.tints[2],
    foreground: palettes.brand.blue.tints[4],
    border: palettes.brand.blue.tints[5],
  },
});

const OverlayChartBar = ({
  colors,
  denominator,
  interactive,
  numerator,
  title,
}) => {
  const [percentage, setPercentage] = useState(0);
  const barColors = colors ?? DEFAULT_COLORS;

  useEffect(() => {
    if (numerator && denominator) {
      setPercentage(percent(numerator, denominator));
    }
  }, [numerator, denominator]);

  return (
    <Box width="100%">
      <BaseChartBar
        color={barColors}
        height={40}
        interactive={interactive}
        percentage={percentage}
      >
        <Box sx={{ position: 'relative', zIndex: 2 }}>
          <Tooltip arrow placement="top" title={title}>
            <Stack
              direction="row"
              height={40}
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                px: 1,
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  color: barColors.font,
                  fontWeight: 500,
                  fontSize: '0.925rem',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  pr: 2,
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: '#4d4d68',
                  fontWeight: 600,
                }}
              >
                {percentage}%
              </Typography>
            </Stack>
          </Tooltip>
        </Box>
      </BaseChartBar>
    </Box>
  );
};

export default OverlayChartBar;
