import * as Colors from '@/atoms/colors';
import { brand } from '@/lib/theme/tokens/palettes';

const CircleBackgroundIconColors = Object.freeze({
  primary: {
    color: brand.blue.shades[2],
    backgroundColor: brand.blue.tints[1],
  },
  'light-blue': {
    color: Colors.Base.font.tertiary,
    backgroundColor: brand.lightBlue.tints[5],
  },
});

export default CircleBackgroundIconColors;
