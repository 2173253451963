import useObservable from '@pkg/broadcasting/useObservable';
import { getChannelName } from './useNotify';

export default function useSubscriber(
  uuid,
  event,
  callback,
  context = 'organisation'
) {
  const subscriber = {
    event,
    context,
    callback,
  };

  useObservable(getChannelName(uuid), [subscriber]);
}
