import { useCallback, useState } from 'react';
import { useAccess } from '@pkg/access/organisations';

const useBudgetVisibility = () => {
  const access = useAccess();
  const canAccessBudget = access.model.budget;
  const [showBudget, setShowBudget] = useState(false);

  const updateBudgetVisibility = useCallback(() => {
    if (!canAccessBudget) {
      return;
    }

    setShowBudget(!showBudget);
  }, [showBudget]);

  return {
    canAccessBudget,
    showBudget,
    updateBudgetVisibility,
  };
};

export default useBudgetVisibility;
