import {
  Information,
  InformationParagraph,
  InformationTitle,
} from '@/molecules/information';

const AlignmentInformation = () => {
  return (
    <Information>
      <InformationParagraph>
        Use this matrix to align on priorities.
      </InformationParagraph>
      <InformationParagraph>
        It is a distribution of your activities and time which shows you where
        there is a alignment between a leader and the team members on what is
        (and isn't!) the most important work.
      </InformationParagraph>
      <InformationTitle>Bottom Left</InformationTitle>
      <InformationParagraph>
        Aligned - This work is not important
      </InformationParagraph>
      <InformationTitle>Bottom Right</InformationTitle>
      <InformationParagraph>
        Misaligned - Team believes this work is important but the Leader does
        not
      </InformationParagraph>
      <InformationTitle>Top Left</InformationTitle>
      <InformationParagraph>
        Aligned - This work is important
      </InformationParagraph>
      <InformationTitle>Top Right</InformationTitle>
      <InformationParagraph>
        Misaligned - Leader believes this work is important but the team does
        not
      </InformationParagraph>
    </Information>
  );
};

export default AlignmentInformation;
