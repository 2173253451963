import { Size } from '@/atoms/enums';
import { TextMetric } from '@/atoms/metrics';
import { OutlineCard } from '@/organisms/cards';
import { useScenarioMetrics } from '@/shared/hooks';
import Grid from '@mui/material/Grid';

const EntityMetricCards = ({ metricList }) => {
  const metrics = useScenarioMetrics(metricList);

  return (
    Boolean(metrics) && (
      <Grid container spacing={2} sx={{ color: 'rgb(100, 100, 128)' }}>
        {metrics.map((metric) => (
          <Grid item xs={12 / metrics.length} key={metric.label}>
            <OutlineCard sx={{ width: '25%' }}>
              <TextMetric
                alignItems="center"
                formatOptions={metric.formatOptions}
                label={metric.label}
                size={Size.MEDIUM}
                value={metric.value}
              />
            </OutlineCard>
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default EntityMetricCards;
