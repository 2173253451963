import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import config from '@/lib/config';
import Typography from '@/components/Typography';

const Link = () => {
  return (
    <a href={config.LINKS.PRIVACY} target="_blank" rel="noreferrer">
      Privacy Policy
    </a>
  );
};

const ConsentDialog = ({ ...props }) => {
  return (
    <Dialog {...props}>
      <DialogTitle>
        <Typography variant="h6" color="primary">
          Privacy Collection Notice
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography paragraph>
          Beam Australia Operations Pty Ltd (ABN 71 615 709 067) (referred to as
          "<strong>Beam Australia</strong>", "<strong>we</strong>", "
          <strong>us</strong>" or "<strong>our</strong>") is collecting your
          personal information to enable you to create an account so that you
          may access the services on the BeamiblePlatform.
        </Typography>
        <Typography paragraph>
          When you use the Platform, we may collect the following information:
          your name, contact details, your job title and description, task level
          work analysis and time spent on particular tasks. We may also collect
          data about your level of engagement with your work should you wish to
          provide it to us.
        </Typography>
        <Typography paragraph>
          Failure to provide your personal information may prevent you from
          being able to create an account and use the services provided via the
          Platform.
        </Typography>
        <Typography paragraph>
          We may disclose your personal information to third parties including
          your employer, as well as service providers we have engaged to provide
          services on our behalf, such as our Australian-based web and software
          design team. Aggregated data (which does not identify you) may also be
          provided to your employer to provide insights about your workplace
          and/or to third parties to assist with benchmarking and to provide
          insights across industries.
        </Typography>
        <Typography paragraph>
          We may use your personal information for the purposes of marketing our
          products and services, for example, to send newsletters and
          promotional offers about our services.
        </Typography>
        <Typography paragraph>
          Further information about our privacy practices and procedures is
          contained in our <Link />. If you would like to access or correct your
          personal information, or make a privacy complaint, please refer to our{' '}
          <Link /> for further details.
        </Typography>
        <Typography paragraph>
          <strong>Last Updated:</strong> 9th June, 2017
        </Typography>
      </DialogContent>
    </Dialog>
  );
};

export default ConsentDialog;
