import { Select } from '@/atoms/inputs';
import Stack from '@mui/material/Stack';
import { useDimensionPresets, useFilterPresets } from './hooks';

const TablePresets = ({ dimension, gridRef }) => {
  const {
    load: loadDimension,
    options: dimensionOptions,
    selected: selectedDimension,
  } = useDimensionPresets(gridRef?.api, dimension);

  const {
    load: loadFilter,
    options: filterOptions,
    selected: selectedFilter,
  } = useFilterPresets(gridRef?.api, dimension, 1);

  const showDimensionPresets = gridRef && dimensionOptions?.length > 0;
  const showFilterPresets = gridRef && filterOptions?.length > 0;

  const handleDimensionChange = (option) => {
    loadDimension(option);
  };

  const handleFilterChange = (option) => {
    loadFilter(option);
  };

  return (
    <Stack spacing={2} direction="row" alignItems="center">
      {showDimensionPresets && selectedDimension && (
        <Select
          label="Group by"
          initialValue={selectedDimension}
          options={dimensionOptions}
          optionLabel="label"
          onChange={handleDimensionChange}
        />
      )}
      {showFilterPresets && selectedFilter && (
        <Select
          label="Filter by"
          initialValue={selectedFilter}
          minWidth={260}
          options={filterOptions}
          optionLabel="label"
          onChange={handleFilterChange}
        />
      )}
    </Stack>
  );
};

export default TablePresets;
