import { useMemo } from 'react';
import { managed } from '../get';

/**
 * @param {Object} snapshot
 * @param {Object} relations
 * @param {String} userId
 * @returns {Object}
 */
export default function useManagedEntities(snapshot, relations, userId) {
  return useMemo(() => {
    return managed(snapshot, relations, userId);
  }, [snapshot?.__hash, userId]);
}
