import env from '@beam-australia/react-env';
import { FronteggProviderNoSSR } from '@frontegg/nextjs/no-ssr';

const CONTEXT_OPTIONS = {
  baseUrl: env('FRONTEGG_BASE_URL'),
  clientId: env('FRONTEGG_CLIENT_ID'),
};

const Provider = ({ children }) => {
  return (
    <FronteggProviderNoSSR
      customLoader={true}
      contextOptions={CONTEXT_OPTIONS}
      authOptions={{
        disableSilentRefresh: false,
        keepSessionAlive: true,
      }}
    >
      {children}
    </FronteggProviderNoSSR>
  );
};

export default Provider;
