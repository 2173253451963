import { useMutation, useQueryClient } from 'react-query';
import { useClient } from '@pkg/client';
import { onDispatch as defaultOnDispatch } from '../utils';

/**
 * @param {object} params
 * @param {string} params.mutation
 * @param {function} [params.onDispatch]
 * @param {any[]} [params.invalidateQuery]
 *
 * @return {object}
 */
export default function useGraphQLMutation({ mutation, ...options }) {
  const client = useClient();
  const queryClient = useQueryClient();

  const {
    onDispatch = defaultOnDispatch,
    invalidateQuery,
    invalidateQueries,
    ...rest
  } = options;

  function onSuccess(data, variables) {
    if (invalidateQuery) {
      queryClient.invalidateQueries(invalidateQuery);
    }

    if (invalidateQueries) {
      invalidateQueries.forEach((query) => {
        queryClient.invalidateQueries(query);
      });
    }

    return options.onSuccess?.(data, variables);
  }

  /**
   * @param {Object} variables
   * @returns {Promise}
   */
  function mutator(variables) {
    return client.graphql({
      query: mutation,
      variables: onDispatch({ input: variables }),
    });
  }

  return useMutation(mutator, {
    throwOnError: true,
    onSuccess,
    ...rest,
  });
}
