import * as d3 from 'd3';

const renderTooltipContent = ({ label }, { classes }) => {
  const root = document.createElement('p');
  root.classList.add('PackedCircleChartTooltip--content');
  root.classList.add(...classes.tooltip.content);
  root.innerHTML = `${label}`;
  return root.outerHTML.toString();
};

export default function renderTooltip(chart, { classes }) {
  const tooltip = chart
    .append('div')
    .attr('class', '')
    .style('max-width', '300px')
    .classed('PackedCircleChartTooltip--root', true)
    .classed(classes.tooltip.root.join(' '), true);

  tooltip.__events = {};
  tooltip.__events.onMouseOver = () => {
    tooltip
      .classed('PackedCircleChartTooltip--visible', true)
      .classed(classes.tooltip.visible.join(' '), true);
  };

  tooltip.__events.onMouseMove = (event, datum) => {
    tooltip.html(renderTooltipContent(datum, { classes }));
    const el = tooltip.node()?.getBoundingClientRect();
    const pointer = d3.pointer(event, this);
    const center = pointer[0] - el.width / 2;
    const top = pointer[1] + 16;
    tooltip.style('left', `${center}px`).style('top', `${top}px`);
  };

  tooltip.__events.onMouseLeave = () => {
    tooltip
      .classed('PackedCircleChartTooltip--visible', false)
      .classed(classes.tooltip.visible.join(' '), false);
  };

  return tooltip;
}
