import { Base as BaseColor } from '@/atoms/colors';
import { brand, core } from '@/lib/theme/tokens/palettes';

const DateListItem = Object.freeze({
  primary: {
    normal: {
      backgroundColor: BaseColor.background.primary,
      boxShadow: `0 0 0 1px ${BaseColor.border.primary}, 0 1px 4px 1px ${BaseColor.border.primary}70`,
    },
    hover: {
      boxShadow: `0 0 0 1px ${brand.blue.tints[3]}, 0 1px 4px 1px ${brand.blue.tints[3]}70`,
    },
    focus: {
      boxShadow: `0 0 0 1px ${brand.blue.tints[3]}, 0 1px 4px 1px ${brand.blue.tints[3]}70`,
    },
  },
  secondary: {
    normal: {
      backgroundColor: core.white,
    },
    hover: {
      backgroundColor: brand.purple.tints[0],
    },
    focus: {
      backgroundColor: brand.purple.tints[0],
      borderColor: brand.purple.tints[2],
    },
  },
});

export default DateListItem;
