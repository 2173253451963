import { EntityType } from '@/shared/enums';

const activityDimension = {
  id: `${EntityType.ACTIVITY}`,
  label: 'Activity',
  order: [EntityType.ACTIVITY],
};

const organisationDimensions = [
  {
    id: `${EntityType.ROLE}${EntityType.MANAGER}:${EntityType.GROUP}`,
    label: 'Role > Manager > Team',
    order: [
      EntityType.LAYER,
      EntityType.ROLE,
      EntityType.MANAGER,
      EntityType.GROUP,
    ],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.PERSON}:${EntityType.MANAGER}:${EntityType.GROUP}`,
    label: 'Role > Person > Manager > Team',
    order: [
      EntityType.LAYER,
      EntityType.ROLE,
      EntityType.PERSON,
      EntityType.MANAGER,
      EntityType.GROUP,
    ],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.PERSON}:${EntityType.GROUP}:${EntityType.MANAGER}`,
    label: 'Role > Person > Team > Manager',
    order: [
      EntityType.LAYER,
      EntityType.ROLE,
      EntityType.PERSON,
      EntityType.GROUP,
      EntityType.MANAGER,
    ],
  },
  {
    id: `${EntityType.MANAGER}:${EntityType.ROLE}:${EntityType.PERSON}:${EntityType.GROUP}`,
    label: 'Manager > Role > Person > Team',
    order: [
      EntityType.LAYER,
      EntityType.MANAGER,
      EntityType.ROLE,
      EntityType.PERSON,
      EntityType.GROUP,
    ],
  },
];

const roleDimensions = [
  {
    id: `${EntityType.ROLE}`,
    label: 'Role',
    order: [EntityType.LAYER, EntityType.ROLE],
  },
];

const LayerDimensions = {
  [EntityType.ACTIVITY]: activityDimension,
  [EntityType.GROUP]: organisationDimensions,
  [EntityType.MANAGER]: organisationDimensions,
  [EntityType.ORGANISATION]: organisationDimensions,
  [EntityType.ROLE]: roleDimensions,
};

export default LayerDimensions;
