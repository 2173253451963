import { BeamibleTag } from '@/lib/enums';

/**
 * Checks if the tags provided are the same used for the prioritisation matrix.
 *
 * @param {Map} tags
 *
 * @return {Boolean}
 */
export default function isPrioritisationMatrix(tags) {
  return (
    tags?.has(BeamibleTag.STRATEGIC_IMPORTANCE) &&
    tags?.has(BeamibleTag.ENERGISING)
  );
}
