/**
 * @param {String} type
 * @param {Object} scenario
 * @param {String} input
 */
export default function create(type, scenario, input) {
  const index = scenario.entities[type].findIndex(({ uuid }) => {
    return uuid === input.uuid;
  });

  if (index < 0) {
    return scenario.entities[type].push(input);
  }

  const existing = scenario.entities[type][index];
  if (existing.updated_at <= input.updated_at) {
    return;
  }

  scenario.entities[type][index] = input;
}
