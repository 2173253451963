import { EntityType } from '@/shared/enums';

const activityDimension = {
  id: `${EntityType.ACTIVITY}`,
  label: 'Activity',
  order: [EntityType.ACTIVITY],
};

const organisationDimensions = [
  {
    id: `${EntityType.ROLE}:${EntityType.GROUP}:${EntityType.PERSON}`,
    label: 'Property > Role > Team > Person',
    order: [
      EntityType.PROPERTY,
      EntityType.PROPERTY_VALUE,
      EntityType.ROLE,
      EntityType.GROUP,
      EntityType.PERSON,
    ],
  },
  {
    id: `${EntityType.ROLE}:${EntityType.PERSON}:${EntityType.GROUP}`,
    label: 'Property > Role > Person > Team',
    order: [
      EntityType.PROPERTY,
      EntityType.PROPERTY_VALUE,
      EntityType.ROLE,
      EntityType.PERSON,
      EntityType.GROUP,
    ],
  },
  {
    id: `${EntityType.GROUP}:${EntityType.ROLE}:${EntityType.PERSON}`,
    label: 'Property > Team > Role > Person',
    order: [
      EntityType.PROPERTY,
      EntityType.PROPERTY_VALUE,
      EntityType.GROUP,
      EntityType.ROLE,
      EntityType.PERSON,
    ],
  },
];

const roleDimensions = [
  {
    id: `${EntityType.ROLE}`,
    label: 'Role',
    order: [EntityType.PROPERTY, EntityType.PROPERTY_VALUE, EntityType.ROLE],
  },
];

const PropertyDimensions = {
  [EntityType.ACTIVITY]: activityDimension,
  [EntityType.GROUP]: organisationDimensions,
  [EntityType.MANAGER]: organisationDimensions,
  [EntityType.ORGANISATION]: organisationDimensions,
  [EntityType.ROLE]: roleDimensions,
};

export default PropertyDimensions;
