import { useMemo } from 'react';
import * as Mutations from './mutations';

/**
 * @return {Object}
 */
export default function useMutations() {
  const create = Mutations.useCreate();
  const remove = Mutations.useRemove();
  const update = Mutations.useUpdate();

  return useMemo(
    () => ({
      create,
      remove,
      update,
    }),
    [create, remove, update]
  );
}
