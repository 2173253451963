import { useMemo } from 'react';
import { css } from '@emotion/css';

export default function useClasses(styles = {}) {
  return useMemo(() => {
    const classes = {};

    Object.keys(styles).forEach((key) => {
      classes[key] = css(styles[key]);
    });

    return classes;
  }, [JSON.stringify(styles)]);
}
