import { Collections } from '@pkg/utils';
import shortuuid from '@pkg/uuid';

/**
 * @param {Object} fromSnapshot
 * @param {String} fromUuid
 * @param {Object} toSnapshot
 * @param {String} toUuid
 * @param {String} name
 * @returns {Object}
 */
export default function publishNew({
  fromSnapshot,
  fromUuid,
  toSnapshot,
  toUuid,
  name,
}) {
  const now = Date.now();
  const groupMutations = {};
  const roleMutations = {};
  const activityMutations = {};

  const fromEntities = fromSnapshot.entities;
  const toEntities = toSnapshot.entities;
  const toSet = {
    groups: new Set(toEntities.groups.map(({ uuid }) => uuid)),
    roles: new Set(toEntities.roles.map(({ uuid }) => uuid)),
    activities: new Set(toEntities.activities.map(({ uuid }) => uuid)),
  };

  const idMap = { [fromUuid]: toUuid };
  const group = Collections.findById(fromEntities.groups, fromUuid);
  groupMutations.create = [{ ...group, uuid: toUuid, name }];

  const roles = Collections.where(fromEntities.roles, 'group_uuid', group.uuid);
  const roleSet = new Set(roles.map(({ uuid }) => uuid));

  roleMutations.create = roles.map((source) => {
    const role = structuredClone(source);
    if (toSet.roles.has(role.uuid)) {
      idMap[role.uuid] = shortuuid.generate();
      role.uuid = idMap[role.uuid];
    }

    role.group_uuid = toUuid;
    role.budget = Number.isFinite(role.budget) ? role.budget : undefined;

    return role;
  });

  roleMutations.create = roleMutations.create.map((role) => ({
    ...role,
    parent_uuid: idMap[role.parent_uuid] ?? role.parent_uuid,
  }));

  activityMutations.create = fromEntities.activities
    .filter(
      ({ owner_uuid }) => owner_uuid === fromUuid || roleSet.has(owner_uuid)
    )
    .map((activity) => {
      if (toSet.activities.has(activity.uuid)) {
        activity.uuid = shortuuid.generate();
      }

      activity.owner_uuid = idMap[activity.owner_uuid] ?? activity.owner_uuid;
      return activity;
    });

  return {
    created_at: now,
    entities: {
      groups: groupMutations,
      roles: roleMutations,
      activities: activityMutations,
    },
  };
}
