import { Fragment } from 'react';
import { Size } from '@/atoms/enums';
import {
  ListBulletPoint,
  ListColors,
  ListNumber,
  ListNumberSizeProps,
} from '@/atoms/lists';
import { Paragraph } from '@/atoms/typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

const ProgressList = ({
  activeIndex,
  activeSubIndex,
  color = 'primary',
  items,
  onClick,
  size = Size.MEDIUM,
}) => {
  const colorProps = ListColors[color];
  const sizeProps = ListNumberSizeProps[size];

  return (
    <Stack spacing={2} sx={{ position: 'relative' }}>
      {items.map((item, index) => {
        return (
          <Fragment key={`${item.label}-${index}`}>
            <Stack
              direction="row"
              spacing={2}
              sx={{ color: colorProps.normal.color, zIndex: 3 }}
              alignItems="center"
              onClick={onClick}
            >
              <ListNumber
                index={index + 1}
                active={index === activeIndex}
                completed={index < activeIndex}
                color={color}
                size={size}
              />
              <Paragraph size={size}>{item.label}</Paragraph>
            </Stack>
            {item.subItems?.map((subItem, subIndex) => (
              <Stack
                direction="row"
                key={`${subItem.label}-${subIndex}`}
                spacing={3}
                sx={{
                  color: colorProps.normal.color,
                  zIndex: 3,
                  pl: '0.425rem',
                }}
                alignItems="center"
                onClick={onClick}
              >
                <ListBulletPoint
                  index={subIndex + 1}
                  active={subIndex === activeSubIndex}
                  completed={index < activeIndex || subIndex < activeSubIndex}
                  color={color}
                  size={size}
                />
                <Paragraph size={Size.X_SMALL}>{subItem.label}</Paragraph>
              </Stack>
            ))}
          </Fragment>
        );
      })}
      <Box
        sx={{
          backgroundColor: colorProps.normal.borderColor,
          position: 'absolute',
          width: '2px',
          height: `calc(100% - ${sizeProps.height})`,
          left: `calc((${sizeProps.width} / 2) - 1px)`,
          top: `calc((${sizeProps.height} / 2) - 16px)`,
        }}
      />
      <Box
        sx={{
          backgroundColor: colorProps.completed.borderColor,
          position: 'absolute',
          borderRadius: '2px',
          width: '2px',
          height: `calc((${sizeProps.height} + 16px) * ${
            activeIndex > 0 ? activeIndex - 1 : 0
          })`,
          left: `calc((${sizeProps.width} / 2) - 1px)`,
          top: `calc((${sizeProps.height} / 2) - 16px)`,
          zIndex: 2,
          transition: 'height 300ms',
        }}
      />
    </Stack>
  );
};

export default ProgressList;
