import { CircleBackgroundIcon } from '@/atoms/icons';
import { Heading } from '@/atoms/typography';
import Stack from '@mui/material/Stack';
import IconHeadingColors from './IconHeadingColors';

const IconHeading = ({ color, title, icon, size, subtitle }) => {
  const colors = IconHeadingColors[color];
  return (
    <>
      <Stack direction="row" spacing={2} alignItems="center">
        {icon && (
          <CircleBackgroundIcon
            icon={icon}
            colorProps={colors.icon}
            size={size}
          />
        )}
        <Stack>
          <Heading
            variant="h3"
            overrideStyles={{
              fontWeight: 500,
              lineHeight: '1.5rem',
            }}
          >
            {title}
          </Heading>
          {subtitle && (
            <Heading
              variant="h5"
              overrideStyles={{ color: colors.text, lineHeight: '1.25rem' }}
            >
              {subtitle}
            </Heading>
          )}
        </Stack>
      </Stack>
    </>
  );
};

export default IconHeading;
